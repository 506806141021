import cx from 'classnames';
import styles from './ThemeSwitch.module.scss';
import { MoonIcon, SunLightIcon } from 'src/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { isFunction } from 'lodash';

const ThemeSwitch = (props) => {
  const { isThemeDarkMode, applyDarkMode, applyLightMode } = props;

  const onToggle = async () => {
    if (isThemeDarkMode) {
      if (isFunction(applyLightMode)) {
        applyLightMode();
      }
    } else if (isFunction(applyDarkMode)) {
      applyDarkMode();
    }
  };

  return (
    <div className={cx(styles.flex_row_xy, styles.theme_switch)}>
      <div
        className={cx(styles.flex_row_xy, styles.track, {
          [styles.track_light]: !isThemeDarkMode,
          [styles.track_dark]: isThemeDarkMode,
        })}
        onClick={onToggle}
      >
        <div
          className={cx(styles.mode, styles.flex_row_xy, {
            [styles.moon_dark]: isThemeDarkMode,
            [styles.moon_light]: !isThemeDarkMode,
          })}
        >
          <MoonIcon height={16} width={16} />
        </div>
        <div
          className={cx(styles.mode, styles.flex_row_xy, {
            [styles.sun_dark]: isThemeDarkMode,
            [styles.sun_light]: !isThemeDarkMode,
          })}
        >
          <SunLightIcon height={14} width={14} />
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(ThemeSwitch);
