const UnderlineIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9C13 11.8 10.8 14 8 14C5.2 14 3 11.8 3 9V3C3 2.4 3.4 2 4 2C4.6 2 5 2.4 5 3V9C5 10.7 6.3 12 8 12C9.7 12 11 10.7 11 9V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V9ZM2 15.5C2 15.2 2.2 15 2.5 15H13.5C13.8 15 14 15.2 14 15.5C14 15.8 13.8 16 13.5 16H2.5C2.2 16 2 15.8 2 15.5Z"
        fill="#718096"
      />
    </svg>
  );
};

export default UnderlineIcon;
