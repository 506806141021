import cx from 'classnames';
import styles from './CreateTag.module.scss';
import i18n from 'src/locales';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Button, Spinner, TextInputField } from 'evergreen-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isFunction, isString, size, toString } from 'lodash';
import { withModalPromptSettings } from 'src/managers/modal';
import {
  createNewPersonalTagRequest,
  createNewSpaceTagRequest,
} from 'src/managers/api/tags';
import { useAuth } from 'src/hooks';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { useToast } from '@chakra-ui/react';

const CreateTag = (props) => {
  const {
    isThemeDarkMode,
    tagSpaceId,
    closeModal,
    addPersonalTag,
    addSpaceTag,
    blockClosingOfModal,
    unBlockClosingOfModal,
  } = props;
  const [tag, setTag] = useState('');
  const [tagErrorMessage, setTagErrorMessage] = useState('');

  const [submitted, setSubmitted] = useState(false);
  const disabled = useMemo(() => size(tag) < 2, [tag]);

  const onTagInput = (evt) => {
    setTag(toString(evt?.target?.value || ''));
  };
  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({ id: 'chakraToast', position: 'top' });

  const submit = useCallback(async () => {
    try {
      if (submitted || disabled) {
        return;
      }

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      const headers = getAuthenticatedHeaders();
      setSubmitted(true);

      if (tagSpaceId && tagSpaceId !== 'personal') {
        // create space tag

        const { success, tagInfo, errorMessage, networkError } =
          await createNewSpaceTagRequest(tag, tagSpaceId, headers);

        if (success) {
          setTagErrorMessage('');

          if (isFunction(addSpaceTag)) {
            addSpaceTag({ ...tagInfo, name: tag }, tagSpaceId);
          }

          if (isFunction(closeModal)) {
            closeModal(true);
          }

          toast({
            status: 'success',
            title: 'Successfully created!',
            duration: 1_500,
            isClosable: true,
          });
          return;
        }

        if (networkError) {
          setTagErrorMessage('Check your network connection.');
          return;
        }

        if (errorMessage) {
          if (errorMessage?.includes('taken')) {
            setTagErrorMessage('Tag name already created.');
          }
        }
      } else {
        // create personal tag
        const { success, errorMessage, tagInfo, networkError } =
          await createNewPersonalTagRequest(tag, headers);

        if (success) {
          setTagErrorMessage('');

          if (isFunction(addPersonalTag)) {
            addPersonalTag({ ...tagInfo, name: tag });
          }

          if (isFunction(closeModal)) {
            closeModal(true);
          }

          toast({
            status: 'success',
            title: 'Successfully created!',
            duration: 1_500,
            isClosable: true,
          });

          return;
        }

        if (networkError) {
          setTagErrorMessage('Check your network connection.');
          return;
        }

        if (errorMessage) {
          if (errorMessage?.includes('taken')) {
            setTagErrorMessage('Tag name already created.');
          }
        }
      }

      setTagErrorMessage('Something went wrong. Try again.');
    } catch {
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  }, [
    submitted,
    tag,
    addSpaceTag,
    addPersonalTag,
    disabled,
    tagSpaceId,
    closeModal,
    toast,
    blockClosingOfModal,
    unBlockClosingOfModal,
    getAuthenticatedHeaders,
  ]);

  useEffect(() => {
    const onKeyDown = (evt) => {
      if (!evt || submitted) {
        return;
      }

      if (
        !isEmpty(tag) &&
        (evt.keyCode === 27 || evt.keyCode === 13) &&
        isFunction(submit)
      ) {
        submit();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [submitted, tag, disabled, submit]);

  return (
    <div className={styles.create_tag}>
      <div
        className={cx(styles.label, {
          [styles.label_dark]: isThemeDarkMode,
        })}
      >
        <h4>{i18n('user_create_tag_label')}</h4>
      </div>
      <div
        className={cx(styles.tag_input, {
          [styles.tag_input_dark]: isThemeDarkMode,
        })}
      >
        <TextInputField
          label={'Tag input'}
          placeholder={'Your new tag here'}
          value={tag}
          onChange={onTagInput}
          autoComplete="false"
          spellCheck={false}
          maxLength={40}
          validationMessage={
            isString(tagErrorMessage) && !isEmpty(tagErrorMessage)
              ? tagErrorMessage
              : false
          }
        />
      </div>
      <div className={styles.actions_wrap}>
        <div>
          {!submitted && (
            <Button
              appearance="minimal"
              className={styles.confirm}
              disabled={tag.length < 1}
              onClick={submit}
            >
              <h5>{i18n('user_create_tag_confirm')}</h5>
            </Button>
          )}
          {submitted && (
            <div
              className={cx(styles.spinner_wrap, styles.flex_row_xy, {
                [styles.spinner_wrap_dark]: isThemeDarkMode,
              })}
            >
              <div className={styles.spinner_wrap_raw}>
                <Spinner size={20} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(CreateTag))
);
