import {
  filter,
  isEmpty,
  isObject,
  isString,
  map,
  pick,
  toLower,
  trim,
} from 'lodash';
import {
  getPublicApiPathname,
  getSpacesApiPathname,
  getTaskApiPathname,
  getUsersApiPathname,
} from './urls';
import { isNetworkError } from '.';
import { axiosDeleteRequest, axiosGetRequest, axiosPostRequest } from './axios';
import {
  getStringifyCleanTiptapContent,
  isFileAllowed,
} from 'src/helpers/utils';
import axios from 'axios';

export async function getNoteRemindersRequest(noteRefId = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let reminders = [];
  let enabled = false;

  try {
    const url = getTaskApiPathname() + `/reminders?noteRefId=${noteRefId}`;
    const res = await axiosGetRequest(url, headers);
    const resData = await res?.data;

    if ((res?.status === 200 || res?.status === 201) && !isEmpty(resData)) {
      reminders = resData?.reminders || [];
      enabled = Boolean(resData?.enabled);
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    enabled,
    reminders,
    networkError,
    errorMessage,
  };
}

export async function setNoteRemindersRequest(
  noteRefId = '',
  reminders = [],
  enable = false,
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  if (!noteRefId || isEmpty(noteRefId)) {
    return { errorMessage, networkError, success };
  }

  try {
    const url = getTaskApiPathname() + '/reminders/update';
    const res = await axiosPostRequest(url, headers, {
      enable,
      reminders: reminders || [],
      noteRefId: trim(noteRefId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { errorMessage, networkError, success };
}

export async function getNoteDataAdvancedOptionsRequest(
  noteRefId = '',
  noteId = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let allowComments = false;
  let allowSubscribersEdit = false;

  try {
    const url =
      getTaskApiPathname() +
      `/advanced-options/${trim(noteId)}?noteRefId=${noteRefId}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && resData?.noteRefId) {
      allowComments = resData?.allowComments;
      allowSubscribersEdit = resData?.allowSubscribersEdit;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { allowComments, allowSubscribersEdit, networkError, errorMessage };
}

export async function saveNoteAdvancedOptionsRequest(
  noteRefId = '',
  noteId = '',
  opts = { allowComments: undefined, allowSubscribersEdit: undefined },
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let success = false;

  try {
    const url = getTaskApiPathname() + '/advanced-options';
    const props = {
      noteId: trim(noteId),
      noteRefId: trim(noteRefId),
      opts: {
        ...pick(opts, ['allowComments', 'allowSubscribersEdit']),
      },
    };
    const res = await axiosPostRequest(url, headers, props);
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function getUserNoteDraftRequest(headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let draft = null;
  let noteId = '';

  try {
    const url = getTaskApiPathname() + '/last-draft';
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.draft) {
      draft = resData?.draft;
      noteId = resData?.newNoteId || draft?.noteId;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { draft, noteId, networkError, errorMessage };
}

export async function saveUserNoteDraftRequest(
  title = '',
  description = '',
  priority = 2,
  exposure = 1,
  exposurePasscode = '',
  headers = {}
) {
  // title: { type: 'string', maxLength: 80 },
  // description: { type: 'string', maxLength: 600_000 },
  // newSubscribers: { type: 'array', maxItems: 100 },
  // removeSubscribers: { type: 'array', maxItems: 100 },
  // priority: { type: 'number', maximum: 3 },
  // exposure: { type: 'number', maximum: 3 },
  // exposurePasscode: { type: 'string', maxLength: 40 },
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    if (isObject(description) && !isEmpty(description)) {
      try {
        description = JSON.parse(description);
      } catch {
        description = null;
      }
    }

    if (!isString(description)) {
      description = getStringifyCleanTiptapContent();
    }

    const url = getTaskApiPathname() + '/save-draft';
    const res = await axiosPostRequest(url, headers, {
      title,
      description,
      priority,
      exposure,
      exposurePasscode,
    });
    const resData = res?.data;

    if (resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
  };
}

export async function getNotesListByPageRequest(
  pageNumber = 1,
  state = '',
  spaceId = '',
  filterId = '',
  priority = '',
  headers = {}
) {
  let list = [];
  let networkError = false;
  let errorMessage = '';
  let url = '';
  let hasNext = false;
  let totalCount = 0;

  if (!isEmpty(spaceId) && spaceId !== 'personal') {
    url =
      getSpacesApiPathname() +
      `/notes/list/${pageNumber}?spaceId=${spaceId}&state=${state}&tags=${trim(
        filterId
      )}&priority=${priority}`;
  } else {
    url =
      getUsersApiPathname() +
      `/tasks/list/${pageNumber}?state=${state}&tags=${trim(
        filterId
      )}&priority=${priority}`;
  }

  try {
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.value) {
      list = resData?.value;
      hasNext = resData?.hasNext;
      totalCount = resData?.totalCount;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, hasNext, list, totalCount };
}

export async function getNoteDataRequest(
  noteRefId = '',
  noteId = '',
  passcode = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let noteData = null;
  let passcodeRequired = false;
  let notFound = false;

  try {
    const url =
      getPublicApiPathname() +
      `/note/${toLower(noteId)}?noteRefId=${noteRefId}${
        !isEmpty(passcode) && isString(passcode) ? `&passcode=${passcode}` : ''
      }`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;
    const resDataMessage = toLower(resData?.message);

    if (resData?.passcodeRequired) {
      passcodeRequired = true;
    } else if (
      res?.status === 404 ||
      resData?.private ||
      (resDataMessage?.includes('found') && resDataMessage?.includes('found'))
    ) {
      notFound = true;
    } else if ((res?.status === 200 || res?.status === 201) && resData?.note) {
      // noteId,
      // noteRefId,
      // canEdit,
      // canDelete,
      // canUnsubscribe,
      // ownerUserId,
      // isGettingStartedNote
      // isUserSpaceMember,
      // authorInfo,
      // spaceInfo,
      // personalTags,
      // spaceTags,
      // note: noteData
      noteData = {
        ...resData?.note,
        ...pick(resData, [
          'last_updated',
          'readOnly',
          'isUserOwner',
          'authorIsPremium',
          'noteId',
          'noteRefId',
          'canEdit',
          'canDelete',
          'canUnsubscribe',
          'isGettingStartedNote',
          'isUserSpaceMember',
          'ownerUserId',
          'authorInfo',
          'spaceInfo',
          'personalTags',
          'spaceTags',
        ]),
      };
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { notFound, passcodeRequired, noteData, networkError, errorMessage };
}

export async function updateNoteStateRequest(
  noteRefId = '',
  noteState = '',
  headers = {}
) {
  let success = false;
  let errorMessage = '';
  let networkError = false;

  try {
    if (noteState !== 'active' && noteState !== 'inactive') {
      throw new Error('Invalid state');
    }

    const url = getTaskApiPathname() + '/set-state';
    const res = await axiosPostRequest(url, headers, {
      noteRefId: trim(noteRefId),
      noteState: trim(noteState),
    });
    const resData = res?.data;

    if (resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function deleteNoteDataRequest(
  noteRefId = '',
  noteId = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let success = false;

  try {
    const data = {
      noteId: trim(noteId),
      noteRefId: trim(noteRefId),
    };
    const url = getUsersApiPathname() + '/note/delete';
    const res = await axiosDeleteRequest(url, headers, data);
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && !isEmpty(resData)) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, networkError, errorMessage };
}

export async function confirmCreateNewNoteRequest(
  title = '',
  description = '',
  priority = 2,
  exposure = 1,
  exposurePasscode = '',
  subscribers = [],
  spaceId = '',
  personalTags = [],
  spaceTags = [],
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let noteData = null;
  let success = false;

  try {
    if (isObject(description) && !isEmpty(description)) {
      try {
        description = JSON.parse(description);
      } catch {
        description = null;
      }
    }

    if (!isString(description)) {
      description = getStringifyCleanTiptapContent();
    }

    // const {
    //   title,
    //   description = '',
    //   priority = 2,
    //   exposure = 1,
    //   exposurePasscode = '',
    //   personalTags = [],
    //   spaceTags = [],
    //   spaceId = '',
    //   subscribers = []
    // } = request.body;
    const url = getUsersApiPathname() + '/note/new';
    const res = await axiosPostRequest(url, headers, {
      priority,
      exposure,
      exposurePasscode,
      subscribers,
      personalTags,
      spaceTags,
      description,
      spaceId: trim(spaceId),
      title: trim(title),
    });
    const resData = res?.data;

    if (
      (res?.status === 200 || res?.status === 201) &&
      resData?.note &&
      resData?.success
    ) {
      noteData = {
        ...resData?.note,
        noteId: resData?.note?.noteId || resData?.noteId,
        noteRefId: resData?.note?.noteRefId || resData?.noteRefId,
      };
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, errorMessage, networkError, noteData };
}

export async function updateNoteStatePropertyRequest(
  noteState = '',
  noteRefId = '',
  headers
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  if (noteState === 'created') {
    return;
  }

  try {
    const url = getTaskApiPathname() + '/set-state';
    const res = await axiosPostRequest(url, headers, { noteRefId, noteState });
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { errorMessage, networkError, success };
}

export async function updateNoteDataPropertiesRequest(
  noteId = '',
  noteRefId = '',
  title = '',
  priority,
  exposure,
  exposurePasscode,
  description,
  removeSubscribers = [],
  newSubscribers = [],
  headers = {}
) {
  // const {
  //   noteState,
  //   noteId: unsanitizedNoteId,
  //   noteRefId,
  //   title,
  //   priority,
  //   exposure,
  //   exposurePasscode,
  //   description = '',
  //   removeSubscribers: unsanitizedRemoveSubscribers = [],
  //   newSubscribers: unsanitizedNewSubscribers = []
  // } = request.body;
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    if (isObject(description) && !isEmpty(description)) {
      try {
        description = JSON.parse(description);
      } catch {
        description = null;
      }
    }

    if (!isString(description) || !description) {
      description = getStringifyCleanTiptapContent();
    }

    const url = getUsersApiPathname() + '/note/update';
    const res = await axiosPostRequest(url, headers, {
      noteId,
      noteRefId,
      priority,
      exposure,
      exposurePasscode,
      description,
      title: trim(title),
      removeSubscribers: filter(
        removeSubscribers,
        (id) => isString(id) && !isEmpty(id)
      ),
      newSubscribers: filter(
        newSubscribers,
        (id) => isString(id) && !isEmpty(id)
      ),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function unsubscribeNoteRequest(noteRefId = '', headers = {}) {
  let success = false;
  let networkError = false;
  let errorMessage = '';

  try {
    const url = getTaskApiPathname() + '/remove';
    const res = await axiosPostRequest(url, headers, {
      noteRefId: trim(noteRefId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
      errorMessage = '';
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function getNoteSubscribersByPageRequest(
  pageNumber = 1,
  noteRefId = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let subscribers = [];
  let hasNext = false;

  try {
    const url =
      getTaskApiPathname() +
      `/subscribers/${pageNumber}?noteRefId=${trim(noteRefId)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
      subscribers = resData?.value;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { hasNext, errorMessage, subscribers, networkError };
}

export async function getNoteActivitiesByPageRequest(
  pageNumber = 1,
  noteRefId = '',
  headers = {}
) {
  let activities = [];
  let networkError = false;
  let errorMessage = '';
  let hasNext = false;

  try {
    const url =
      getTaskApiPathname() +
      `/activities/${pageNumber}?noteRefId=${trim(noteRefId)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.value) {
      activities = resData?.value || [];
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { hasNext, activities, errorMessage, networkError };
}

export async function attachTagsNoteRequest(
  noteRefId = '',
  tags = [],
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  // spaceTags', 'noteRefId' , 'personalTags',

  try {
    const isPersonal = !spaceId || spaceId === 'personal';
    const url =
      getTaskApiPathname() + `${isPersonal ? '' : '/space'}/attach-tags`;
    const sanitizedTags = filter(
      map(tags, (tagInfo) => tagInfo?.id || ''),
      (tagId) => isString(tagId) && !isEmpty(tagId)
    );
    const res = await axiosPostRequest(url, headers, {
      noteRefId: trim(noteRefId),
      ...(isPersonal
        ? {
            personalTags: sanitizedTags,
          }
        : { spaceTags: sanitizedTags }),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
  };
}

export async function removeTagsNoteRequest(
  noteRefId = '',
  tags = [],
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  // spaceTags', 'noteRefId' , 'personalTags',

  try {
    const isPersonal = !spaceId || spaceId === 'personal';
    const url =
      getTaskApiPathname() + `${isPersonal ? '' : '/space'}/remove-tags`;
    const sanitizedTags = filter(
      map(tags, (tagInfo) => tagInfo?.id || ''),
      (tagId) => isString(tagId) && !isEmpty(tagId)
    );
    const res = await axiosPostRequest(url, headers, {
      noteRefId: trim(noteRefId),
      ...(isPersonal
        ? {
            personalTags: sanitizedTags,
          }
        : { spaceTags: sanitizedTags }),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
  };
}

export async function uploadFileRemoveIntentRequest(
  refId = '',
  noteRefId = '',
  headers = {}
) {
  let success = false;
  let errorMessage = '';
  let networkError = false;

  try {
    const url = getTaskApiPathname() + '/file/upload-remove-intent';
    const res = await axiosPostRequest(url, headers, { refId, noteRefId });
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
  };
}

export async function uploadFileInNoteRequest(
  file = null,
  fileName = '',
  noteRefId = '',
  contentType = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let fileUrl = '';
  let props = null;
  let success = false;
  let maxSize = false;
  let notAllowedFile = false;
  let notAllowedPlan = false;

  if (!file) {
    return {
      errorMessage: 'Invalid file.',
    };
  }

  if (!isFileAllowed(fileName)) {
    notAllowedFile = true;

    return { notAllowedFile, errorMessage: 'Not allowed file' };
  }

  try {
    const sanitizedNoteRefId = trim(noteRefId);
    const sanitizedFileName = trim(fileName);
    const url = getTaskApiPathname() + '/file/upload';
    const res = await axiosPostRequest(url, headers, {
      contentType,
      noteRefId: sanitizedNoteRefId,
      fileName: sanitizedFileName,
      inline: true,
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.url) {
      const signedUrl = resData?.url;
      const refId = resData?.intentRefId || resData?.refId;
      let uploadFailed = false;

      try {
        await axios({
          method: 'PUT',
          url: signedUrl,
          headers: {
            'Content-Type': contentType,
          },
          data: file,
        });
      } catch (err) {
        errorMessage = err?.message;
        uploadFailed = true;

        try {
          await uploadFileRemoveIntentRequest(
            refId,
            sanitizedNoteRefId,
            headers
          );
        } catch {}
      }

      if (!uploadFailed) {
        const res = await axiosPostRequest(
          getTaskApiPathname() + '/file/upload-complete',
          headers,
          {
            refId,
            noteRefId: sanitizedNoteRefId,
          }
        );
        const resData = res?.data;

        if (
          (res?.status === 200 || res?.status === 201) &&
          (resData?.url || resData?.publicUrl)
        ) {
          success = true;
          props = resData?.props;
          fileUrl = resData?.publicUrl || resData?.url;
        } else if (resData?.message) {
          // message: 'Not allowed with plan'
          // message: 'Max file size reached'

          errorMessage = resData?.message || '';

          const sanitizedErrorMessage = toLower(errorMessage);

          if (toLower(errorMessage).includes('max file size')) {
            maxSize = true;
          }

          if (sanitizedErrorMessage?.includes('not allowed with plan')) {
            notAllowedPlan = true;
          }
        } else {
          throw new Error(`status: ${res?.status} ${res?.statusText}`);
        }
      }
    }

    if (errorMessage) {
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    maxSize,
    notAllowedPlan,
    notAllowedFile,
    props,
    success,
    errorMessage,
    fileUrl,
    networkError,
  };
}
