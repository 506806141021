const CodeBlockIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M14.125 7.05333L15.3034 5.875L19.4284 10L15.3034 14.125L14.125 12.9467L17.0709 10L14.125 7.05333ZM5.87503 7.05333L2.9292 10L5.87503 12.9467L4.6967 14.125L0.571701 10L4.6967 5.875L5.87503 7.05333Z"
        fill="#718096"
      />
    </svg>
  );
};

export default CodeBlockIcon;
