import cx from 'classnames';
import styles from './ForgotPassword.module.scss';
import Button from 'src/components/button';
import LogoIcon from '../logo-icon';
import { TextInputField, TickCircleIcon } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { isEmpty, isFunction, trim } from 'lodash';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';
import { sendUserForgotPasswordRequest } from 'src/managers/api';
import { withModalPromptSettings } from 'src/managers/modal';
import { isMobileView, timeout } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { useWindowScroll } from 'src/hooks';
import { Link } from 'react-router-dom';

const ForgotPassword = (props) => {
  const {
    isThemeDarkMode,
    blockClosingOfModal,
    unBlockClosingOfModal,
    closeModal,
  } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const { scrollToTop } = useWindowScroll();

  const submit = async () => {
    try {
      if (submitted) {
        return;
      }

      setSubmitted(true);

      if (!isCorrectEmailFormat(email) || !email) {
        setEmailError('Invalid email');
        return;
      }

      if (isFunction(blockClosingOfModal) && !isMobileView()) {
        blockClosingOfModal();
      }

      const { success } = await sendUserForgotPasswordRequest(trim(email));

      if (success) {
        setSuccess(true);

        await timeout(1_000);

        if (isFunction(closeModal)) {
          closeModal(true);
        }
      } else {
        if (isFunction(unBlockClosingOfModal) && !isMobileView()) {
          unBlockClosingOfModal();
        }
      }
    } catch {
      if (isFunction(unBlockClosingOfModal) && !isMobileView()) {
        unBlockClosingOfModal();
      }
    } finally {
      setSubmitted(false);
    }
  };

  useEffect(() => {
    scrollToTop();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={cx(styles.flex_center_all, styles.forgot_password)}>
      <div className={styles.content}>
        {success && (
          <div className={cx(styles.flex_row_xy, styles.success)}>
            <TickCircleIcon color="success" />
            <p>Sent!</p>
          </div>
        )}

        {!success && (
          <>
            <div className={cx(styles.logo, styles.flex_row_xy)}>
              <LogoIcon darkMode={isThemeDarkMode} />
            </div>
            <div
              className={cx(styles.message, {
                [styles.message_dark]: isThemeDarkMode,
              })}
            >
              <h2>{"Forgot your password? We'll send you a reset link."} </h2>
            </div>
            <div
              className={cx(styles.email, styles.flex_row_xy, {
                [styles.email_dark]: isThemeDarkMode,
              })}
            >
              <TextInputField
                placeholder={'youremail@domain.com'}
                label={'E-mail'}
                onChange={(evt) => {
                  setEmail(evt?.target?.value || '');
                  setEmailError('');
                }}
                value={email}
                validationMessage={
                  isEmpty(emailError) || !emailError ? false : emailError
                }
              />
            </div>

            <div className={styles.cta}>
              <Button
                isThemeDarkMode={isThemeDarkMode}
                label={'Submit'}
                variant={'primaryPurple'}
                onClick={submit}
                loading={submitted}
                disabled={submitted}
                className={cx(styles.flex_center_all, styles.confirm)}
              />

              {isMobileView() && (
                <div className={cx(styles.flex_row_xy, styles.to_login)}>
                  <Link to={'/login'}>Log in instead</Link>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(ForgotPassword)
);
