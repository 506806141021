import { useEffect } from 'react';
import cx from 'classnames';
import styles from './Emojis.module.scss';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
  isString,
  isEmpty,
  isFunction,
  toString,
  isMobileView,
} from 'src/helpers/utils';

const Emojis = ({
  className = '',
  isThemeDarkMode = false,
  Quill,
  v4Id = '',
  close = null,
}) => {
  const uniqueClassname = `emoji-comment-${v4Id}`;

  useEffect(() => {
    const onDocumentClick = (evt) => {
      const target = evt?.target;

      if (
        (target?.classList?.length &&
          target?.classList.contains('emojiButton')) ||
        (target?.parentElement?.classList?.length &&
          target?.parentElement?.classList.contains('emojiButton')) ||
        target?.nodeName === 'input' ||
        toString(target?.nodeName) === 'path' ||
        toString(target?.nodeName) === 'svg' ||
        toString(target?.nodeName || '')
          .toLowerCase()
          .includes('-emoji-picker')
      ) {
        return;
      } else if (
        !(
          target &&
          target.classList &&
          target.classList?.length > 0 &&
          target.classList?.contains &&
          target.classList.contains(uniqueClassname)
        )
      ) {
        if (isFunction(close)) {
          close();
        }
      } else if (isFunction(close)) {
        close();
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [uniqueClassname, close]);

  const insertEmoji = (e) => {
    if (!Quill || !e?.native) {
      return;
    }

    const range = Quill.get().getSelection(true);
    const Delta = window.Quill.import('delta');
    const update = new Delta().retain(range.index).delete(range.length);
    const newRange = range.index + 3;
    const QuillJs = window.Quill;

    Quill.get().updateContents(
      update.insert(` ${window.decodeURI(e?.native)} `),
      QuillJs.sources.USER
    );
    Quill.get().setSelection(newRange);

    if (isFunction(close)) {
      close();
    }
  };
  const isMobileScreen = isMobileView();

  return (
    <div
      className={cx(styles.emojis, uniqueClassname, {
        [styles.emojis_dark]: isThemeDarkMode,
        [className]: isString(className) && !isEmpty(className),
      })}
    >
      <Picker
        searchPosition={isMobileScreen ? 'none' : 'sticky'}
        perLine={isMobileScreen ? 7 : 9}
        data={emojiData}
        onEmojiSelect={(e) => insertEmoji(e)}
        theme={isThemeDarkMode ? 'medium' : 'light'}
      />
    </div>
  );
};

export default Emojis;
