import cx from 'classnames';
import styles from './HeaderMobile.module.scss';
import LogoIcon from 'src/components/logo-icon';
import SpaceMembers from 'src/modals/spaces/members';
import { Button } from 'evergreen-ui';
import { NotificationBellIcon } from 'src/icons';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { isIosMobileApp } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { isEmpty, isFunction } from 'lodash';
import { withModalPromptSettings } from 'src/managers/modal';

const HeaderMobile = (props) => {
  const {
    isThemeDarkMode,
    profileLoaded,
    selectedSpace,
    selectedSpaceId,
    setActiveModalDom,
    closeModal,
  } = props;
  const spaceName = useMemo(
    () => (profileLoaded ? selectedSpace?.name : ''),
    [profileLoaded, selectedSpace]
  );

  const onSpaceNameClick = () => {
    if (
      !isEmpty(selectedSpace) &&
      selectedSpaceId !== 'personal' &&
      spaceName
    ) {
      if (isFunction(setActiveModalDom)) {
        setActiveModalDom(
          <SpaceMembers
            close={() => {
              if (isFunction(closeModal)) {
                closeModal();
              }
            }}
          />
        );
      }
    }
  };

  return (
    <div
      className={cx(styles.flex_row_xy, styles.header_mobile, {
        [styles.header_mobile_ios_app]: isIosMobileApp(),
      })}
    >
      <div className={cx(styles.left, styles.flex_row_xy)}>
        <div className={styles.flex_row_xy}>
          <LogoIcon height={30} width={30} darkMode={isThemeDarkMode} />
          <div
            className={cx(styles.flex_row_xy, styles.cover, styles.logo_link)}
          >
            <Link to={'/'} />
          </div>
        </div>
      </div>
      <div className={styles.space_info} onClick={onSpaceNameClick}>
        <p>{spaceName || 'Personal Space'}</p>
      </div>
      <div className={cx(styles.flex_row_xy, styles.right)}>
        <Button
          appearance="minimal"
          className={cx(styles.notif, { [styles.notif_dark]: isThemeDarkMode })}
        >
          <NotificationBellIcon height={22} width={22} />
          <div className={cx(styles.cover, styles.link)}>
            <Link to={'/user/notifications'} />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withSpacesAndUserSettings(HeaderMobile))
);
