import k from 'src/constants/k';
import { forwardRef, createContext, Component } from 'react';
import { NoteViewMode, NoteViewModalType } from 'src/types';
import { setupTwitterWidget } from './extensions/TwitterExtension';
import {
  applyUIOverflow,
  getAuthenticatedHeaders,
  getStringifyCleanTiptapContent,
  getUserDisplayName,
  getUserIdFromObject,
  getUserInitials,
  getUserProfileIdFromObject,
  isMobileView,
  noUIOverflow,
  setMetaTitle,
  timeout,
} from 'src/helpers/utils';
import {
  head,
  filter,
  toLower,
  toString,
  toUpper,
  trim,
  isArray,
  isBoolean,
  isEmpty,
  isFunction,
  isNil,
  isNumber,
  isString,
  isObject,
  size,
  includes,
  map,
  forEach,
  ceil,
  last,
} from 'lodash';
import { withRouter } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withNetworkSettings } from 'src/managers/network';
import { withNoteDataSettings } from 'src/managers/notes';
import {
  confirmCreateNewNoteRequest,
  deleteNoteDataRequest,
  getNoteActivitiesByPageRequest,
  getNoteDataAdvancedOptionsRequest,
  getNoteRemindersRequest,
  getNoteSubscribersByPageRequest,
  saveNoteAdvancedOptionsRequest,
  updateNoteDataPropertiesRequest,
  saveUserNoteDraftRequest,
  updateNoteStateRequest,
} from 'src/managers/api/notes';
import { getUserInfoByIdOrUsernameOrEmailRequest } from 'src/managers/api';
import { getQueryStringInJsonFormat } from 'src/helpers/urls';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { getNoteCommentsListByPageRequest } from 'src/managers/api/noteComments';

const { Consumer, Provider } = createContext();

export function withNoteViewContext(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class NoteViewContext extends Component {
  saveDraftTimeoutId = null;
  saveUpdatedNoteTimeoutId = null;
  savedUsersPageFetch = 1;
  removeSubscriberTimeoutId = null;
  addSubscriberTimeoutId = null;
  commentsRealtimeIntervalId = null;
  advancedOptionsTimeoutId = null; // for debounce

  state = {
    canDelete: false,
    canUnsubscribe: false,
    isUserSpaceMember: false,
    authorIsPremium: false,
    authorId: '',
    authorFullName: '',
    taskId: '',
    uRef: '',
    isOwner: false,
    updatedDateTime: 0,
    creating: false,
    allowedToToggleModify: true,
    isForGettingStartedNote: false,
    description: { type: 'doc', content: [{ type: 'paragraph' }] },
    descriptionInString: '',
    descriptionUpdated: false,
    mode: NoteViewMode.view, // view by default
    loading: true,
    mounted: false,
    views: 0,
    spaceId: '',
    spaceInfo: null,
    Editor: null,
    showModal: '',
    pdf: '',
    exposureWithPasscode: false,
    // required
    title: '',
    exposure: 1,
    priority: 2,
    taskState: '',
    exposurePasscode: '',
    spaceTags: [],
    personalTags: [],
    // end required
    requirePasscode: false,
    saving: false,
    savingQueue: false,
    subscriberEditing: false,
    subscribers: [
      // { email: 'test@gmail.com', nonUser: true }
    ],
    removeSubscribers: [],
    newSubscribers: [],
    connectedUsers: [], // can be mentioned
    deletingNote: false,
    author: { firstName: '', lastName: '', image: '' },
    showSavedChanges: false,
    showSavingInProgress: false,
    showNetworkError: false,
    showReadOnly: false,
    showImageViewer: false,
    showImageViewerSrc: '',
    showFilesView: false,
    showNotFound: false,
    showDragAndDropFile: false,
    uploadingFiles: [
      // {
      //   name: 'TestPDFfile.pdf',
      //   refId: 'asd0asdtestrefId',
      //   size: 1000,
      //   file: {
      //     name: 'TestPDFfile.pdf',
      //     refId: 'asd0asdtestrefId',
      //     size: 1000,
      //   },
      //   id: uuidV4(),
      // },
      // {
      //   name: 'TestPDFfile.pdf',
      //   refId: 'asd0asdtestrefIda',
      //   size: 1000,
      //   id: uuidV4(),
      // },
    ],
    advancedOptions: {
      allowComments: true,
      allowSubsEdit: false,
    },
    fetchingAdvancedOptions: true,
    fetchingRemindersInfo: true,
    remindersInfo: { reminders: [], enabled: false },
    readOnly: false,
    updatingState: false,
    totalComments: 0,
    commentSubmitting: false,
    commentsHasNext: false,
    commentsBlocked: false,
    commentsView: true,
    commentsCurrentPages: [],
    commentsCurrentPagesWithComments: [],
    commentsCurrentPage: 1,
    commentsRealtimeFetchBlock: false,
    commentsFetching: false,
    commentsFetchingRealtime: false,
    commentsQueuedPages: [],
    activitiesFetching: false,
    activitiesCurrentPage: 1,
    activities: [],
  };

  reset = () => {
    try {
      setMetaTitle('CHAMU | Sharing files and notes made easy.');
    } catch {}

    this.setState({
      canDelete: false,
      canUnsubscribe: false,
      isUserSpaceMember: false,
      taskId: '',
      uRef: '',
      isOwner: false,
      authorIsPremium: false,
      allowedToToggleModify: true,
      isForGettingStartedNote: false,
      description: { type: 'doc', content: [{ type: 'paragraph' }] },
      descriptionInString: '',
      descriptionUpdated: false,
      mode: NoteViewMode.view, // view by default
      loading: true,

      spaceId: '',
      spaceInfo: null,
      Editor: null,
      showModal: '',
      pdf: '',
      exposureWithPasscode: false,

      // required
      title: '',
      exposure: 1,
      priority: 2,
      taskState: '',
      exposurePasscode: '',
      spaceTags: [],
      personalTags: [],

      requirePasscode: false,
      saving: false,
      savingQueue: false,
      subscriberEditing: false,
      subscribers: [],
      removeSubscribers: [],
      newSubscribers: [],
      uploadingFiles: [],
      advancedOptions: {
        allowComments: true,
        allowSubsEdit: false,
      },
      authorInfo: null,
      authorId: '',
      author: { firstName: '', lastName: '', image: '' },
      fetchingAdvancedOptions: true,
      fetchingRemindersInfo: true,
      remindersInfo: { reminders: [], enabled: false },
      readOnly: false,

      showSavedChanges: false,
      showSavingInProgress: false,
      showNetworkError: false,
      showReadOnly: false,
      showImageViewer: false,
      showImageViewerSrc: '',
      showFilesView: false,
      showDragAndDropFile: false,
      showNotFound: false,
      updatingState: false,
      deletingNote: false,

      // comments & activities below
      totalComments: 0,
      commentSubmitting: false,
      commentsHasNext: false,
      commentsBlocked: false,
      commentsView: true,
      commentsCurrentPages: [],
      commentsCurrentPagesWithComments: [],
      commentsCurrentPage: 1,
      commentsFetching: false,
      commentsRealtimeFetchBlock: false,
      commentsFetchingRealtime: false,
      commentsQueuedPages: [],
      activitiesFetching: false,
      activitiesCurrentPage: 1,
      activities: [],
    });

    clearInterval(this.commentsRealtimeIntervalId);
  };

  clearConnectedUsers = () => {
    this.setState({
      connectedUsers: [],
    });
  };

  constructor() {
    super();

    this.setStateAsync = (obj) =>
      new Promise((resolve) => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true,
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    clearInterval(this.commentsRealtimeIntervalId);
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, targetNoteData = null } = this.props;
    const { connectedUsers, taskId, uRef } = this.state;
    const pathname = toString(toLower(window?.location?.pathname || ''));
    const isNoteView = pathname?.startsWith('/note/');

    /**
     * on logout
     */
    if (prevProps?.isLoggedIn && !isLoggedIn && connectedUsers?.length) {
      this.setState({
        connectedUsers: [],
        subscribers: [],
      });

      return;
    }

    if (
      !isEmpty(taskId) &&
      !isEmpty(uRef) &&
      !isEmpty(targetNoteData?.noteRefId) &&
      isNoteView
    ) {
      // update note contents
      this.onNoteContents(targetNoteData, true);
    }
  }

  onMount = () => {
    setupTwitterWidget();
  };

  onRealtimeInfo = async (info) => {
    // todo realtime
    const {
      loading,
      mounted,
      taskId,
      saving,
      Editor,
      mode,
      advancedOptions,
      commentsBlocked,
    } = this.state;
    const isViewMode = mode === NoteViewMode.view;
    const pathname = window?.location?.pathname;

    if (
      !info ||
      loading ||
      !mounted ||
      saving ||
      !isViewMode ||
      !Editor?.commands ||
      !pathname?.includes('/note') ||
      document?.hidden
    ) {
      return;
    }

    if (info?.taskInfo) {
      const {
        description,
        taskId: targetTaskId,
        advancedOptions: latestAdvancedOptions,
      } = info.taskInfo;

      if (
        !isNil(latestAdvancedOptions) &&
        isObject(latestAdvancedOptions) &&
        (latestAdvancedOptions?.allow_comments !==
          advancedOptions?.allowComments ||
          latestAdvancedOptions?.allow_subs_edit !==
            advancedOptions?.allowSubsEdit)
      ) {
        this.setState({
          allowComments: latestAdvancedOptions?.allow_comments,
          allowSubsEdit: latestAdvancedOptions?.allow_subs_edit,
        });
      }

      if (commentsBlocked && latestAdvancedOptions?.allow_comments) {
        this.setState({
          commentsBlocked: false,
          commentsView: true,
        });
      }

      if (toLower(targetTaskId) === toLower(taskId)) {
        try {
          const parsed = JSON.parse(description);

          if (parsed) {
            Editor.commands.setContent(parsed);
          }
        } catch {}
      }
    }
  };

  toggleMode = () => {
    const {
      mode,
      Editor,
      isForGettingStartedNote,
      allowedToToggleModify,
      uploadingFiles,
      uRef,
    } = this.state;
    const { fetchingNotesData } = this.props;

    if (!allowedToToggleModify || isForGettingStartedNote) {
      return;
    }

    if (mode !== NoteViewMode.create) {
      const newMode =
        mode === NoteViewMode.view ? NoteViewMode.edit : NoteViewMode.view;
      const newModeIsView = newMode === NoteViewMode.view;

      if (!newModeIsView && !isEmpty(uploadingFiles)) {
        // no toggle when uploading
        return;
      }

      if (includes(fetchingNotesData, uRef) && newMode === NoteViewMode.edit) {
        return;
      }

      if (newModeIsView) {
        this.setState(
          {
            activitiesCurrentPage: 1,
          },
          () => this.fetchActivities(true)
        );
      }

      this.setState({ mode: newMode });
      const canModify = newMode === NoteViewMode.edit;

      if (Editor) {
        Editor.setEditable(canModify);
      }
    }
  };

  storeEditorInstance = (Editor = null) => {
    const { mode } = this.state;

    this.setState({
      Editor,
      mode: toString(mode),
    });
  };

  checkIfCanModifyEditor = () => {
    const { mode } = this.state;
    const isEditMode = mode === NoteViewMode.edit;
    const isCreateMode = mode === NoteViewMode.create;
    return isEditMode || isCreateMode;
  };

  showModalType = (type = '') => {
    if (type) {
      noUIOverflow();
    }

    this.setState({
      showModal: type,
    });
  };

  closeModal = () => {
    this.setState(
      {
        showModal: '',
        pdf: '',
      },
      () => {
        applyUIOverflow();
      }
    );
  };

  expandPdf = (link = '') => {
    if (!link) {
      return;
    }

    this.setState(
      {
        pdf: link,
      },
      () => this.showModalType(NoteViewModalType.pdfViewer)
    );
  };

  setTargetSpace = (spaceId = '') => {
    try {
      const { userSpaces = [], selectSpace } = this.props;

      if (!spaceId || spaceId === 'personal') {
        this.setState({ spaceId: '', spaceInfo: null });
      } else {
        const targetSpaceInfo = head(
          filter(userSpaces, (spaceInfo) => spaceInfo?.id === spaceId)
        );

        if (!targetSpaceInfo || isEmpty(targetSpaceInfo)) {
          return;
        }

        this.setState(
          {
            spaceId,
            spaceInfo: { ...targetSpaceInfo },
          },
          () => {
            if (isFunction(selectSpace)) {
              selectSpace(spaceId);
            }
          }
        );
      }
    } catch {}
  };

  startNoteCreateMode = async () => {
    // get last draft
    // make sure it has uRef value
    const { userNoteDraftProperties, user, selectedSpace } = this.props;
    const { Editor, mode, loading } = this.state;
    const isEditMode = mode === NoteViewMode.edit;

    if (
      isEditMode ||
      !Editor ||
      !loading ||
      userNoteDraftProperties?.fetching
    ) {
      return;
    }

    if (
      !isEmpty(userNoteDraftProperties) &&
      !userNoteDraftProperties?.fetching
    ) {
      const {
        title = '',
        description = '',
        noteId: taskId = '',
        noteRefId: uRef = '',
        exposure = 1,
        exposurePasscode = '',
        priority = 2,
      } = userNoteDraftProperties;
      const titleDom = document.querySelector('title');
      const spaceInfo = !isEmpty(selectedSpace?.id) ? selectedSpace : null;
      const spaceId = spaceInfo?.id || '';

      let sanitizedDescription = '';

      if (!isEmpty(description)) {
        try {
          if (isString(description)) {
            sanitizedDescription = JSON.parse(description);
          } else {
            sanitizedDescription = description;
          }

          const firstContent = head(sanitizedDescription?.content);
          // If only escape characters exists make sure to remove the first text content
          // - this will ensure that the placeholder text will show up on top of the editor component
          if (
            size(sanitizedDescription?.content) === 1 &&
            firstContent?.type === 'paragraph' &&
            isEmpty(trim(head(firstContent?.content)?.text))
          ) {
            sanitizedDescription = {
              type: 'doc',
              content: [
                {
                  type: 'paragraph',
                },
              ],
            };
          }
        } catch {
          sanitizedDescription = { type: 'doc', content: [] };
        }
      }

      if (!isEmpty(title) && titleDom) {
        setMetaTitle(title);
      }

      this.setState(
        {
          uRef,
          taskId,
          title,
          spaceInfo,
          spaceId,
          description: sanitizedDescription,
          exposurePasscode: isString(exposurePasscode) ? exposurePasscode : '',
          exposure: isNumber(exposure) && exposure > 0 ? exposure : 1,
          priority: isNumber(priority) && priority > 0 ? priority : 2,
          authorId: getUserIdFromObject(user),
          mode: NoteViewMode.create,
          loading: false,
          allowedToToggleModify: true,
          // reset
          spaceTags: [],
          personalTags: [],
          requirePasscode: false,
          saving: false,
          savingQueue: false,
          subscriberEditing: false,
          subscribers: [],
          removeSubscribers: [],
          newSubscribers: [],
          uploadingFiles: [],
        },
        () => {
          const { description } = this.state;

          Editor.setEditable(true);
          Editor.commands.setContent(description);
          Editor.commands.focus();
          this.fetchRemindersInfo();
          this.fetchSubscribers();
          this.fetchAuthorInfo();
          this.fetchAdvancedOptions();
        }
      );
    }
  };

  onNoteContents = (noteData = {}, updating = false) => {
    try {
      const { loading, uRef, taskId } = this.state;
      const {
        canEdit,
        canDelete,
        canUnsubscribe,
        ownerUserId,
        readOnly,
        authorInfo,
        title,
        priority,
        noteId,
        task_id,
        noteRefId,
        exposure,
        exposurePasscode,
        descData,
        isUserSpaceMember,
        isGettingStartedNote,
        isUserOwner,
        views = 0,
        spaceInfo = null,
        personalTags = [],
        spaceTags = [],
        authorIsPremium,
      } = noteData;

      if (loading && isEmpty(noteData?.title)) {
        this.setState({
          showNotFound: true,
          loading: false,
        });

        return;
      }

      let description = descData?.desc || '';

      if (isString(description)) {
        try {
          description = JSON.parse(description);
        } catch (err) {
          description = null;
        }
      }

      if (isEmpty(description)) {
        description = JSON.parse(getStringifyCleanTiptapContent());
      }

      if (!isEmpty(title)) {
        setMetaTitle(title);
      }

      const spaceId = spaceInfo?.id || spaceInfo?.space_id;
      const authorFullName = getUserDisplayName(authorInfo);
      const authorId = ownerUserId || getUserIdFromObject(authorInfo);
      const taskState = noteData?.noteState || noteData?.task_state;
      const updatedDateTime = noteData?.created || noteData?.last_updated;
      this.setState(
        {
          title,
          description,
          priority,
          exposure,
          exposurePasscode,
          authorFullName,
          authorIsPremium,
          spaceId,
          spaceInfo,
          personalTags,
          spaceTags,
          views,
          readOnly,
          canDelete,
          canUnsubscribe,
          isUserSpaceMember,
          taskState,
          updatedDateTime,
          requirePasscode: false,
          allowedToToggleModify: canEdit || isUserOwner,
          subscriberEditing: !isUserOwner && canEdit,
          isOwner: isUserOwner,
          isForGettingStartedNote: isGettingStartedNote,
          taskId: taskId || noteId || task_id,
          uRef: noteRefId || uRef,
          ...(!isEmpty(authorId) && {
            authorInfo,
            authorId,
          }),
        },
        () => {
          const {
            Editor,
            description: parsedDescription,
            isForGettingStartedNote,
          } = this.state;

          if (loading && isMobileView()) {
            window.scrollTo(0, 0);
          }

          if (Editor?.commands) {
            Editor.commands.setContent(parsedDescription);

            if (isForGettingStartedNote && loading) {
              Editor.setEditable(false);
            }
          }

          if (loading && !updating) {
            // initiate fetch once only
            this.setState(
              {
                loading: false,
              },
              () => {
                this.fetchAuthorInfo();
                this.fetchAdvancedOptions();
                this.fetchRemindersInfo();
                this.fetchSubscribers();
                this.fetchActivities(true);
                this.fetchComments();
                this.startRealtimeCommentsFetch();
              }
            );
          }
        }
      );
    } catch (err) {
      console.log(`onNoteContents err ${err?.message}`);
    }
  };

  startNoteEditMode = async () => {
    const pathname = toString(window.location?.pathname || '');
    const query = getQueryStringInJsonFormat();
    const uRef = query?.u;

    if (
      !isEmpty(pathname) &&
      (pathname.startsWith('/user/note') || pathname.startsWith('/note')) &&
      !isEmpty(uRef)
    ) {
      const pathArr = trim(pathname).split('/');

      if (size(head(pathArr)) < 1) {
        pathArr.shift();
      }

      const taskId = toLower(last(pathArr));
      const {
        getStoredNoteData,
        getLatestNoteEnrichedData,
        user,
        // isUserNetworkOffline,
      } = this.props;
      const { showNotFound } = this.state;

      if (!isEmpty(taskId) && !isEmpty(uRef)) {
        // send inspect
        await this.setStateAsync({
          uRef,
          taskId: toLower(taskId),
          ...(showNotFound && { loading: true, showNotFound: false }),
        });
        const storedRes = await getStoredNoteData(taskId);
        const storedNoteData = storedRes?.noteData || null;
        let usingCache = false;
        let noteData = null;

        if (
          storedRes?.canUseNoteData &&
          // make sure it's correct format
          // todo
          isObject(storedRes?.noteData) &&
          !isEmpty(storedRes?.noteData?.title) &&
          !storedRes?.noteData?.passcodeRequired &&
          (storedRes?.noteData?.noteId === taskId ||
            storedRes?.noteData?.taskId === taskId)
        ) {
          if (
            // only load if user is owner
            storedNoteData?.exposure === k.USER_TASK_PRIVACY_PUBLIC_NO_PW ||
            storedNoteData?.owner === getUserIdFromObject(user) ||
            storedNoteData?.owner === getUserProfileIdFromObject(user)
          ) {
            this.onNoteContents(storedRes?.noteData);
            usingCache = true;
          }
        }

        const latestNoteDataRes = await getLatestNoteEnrichedData(taskId, uRef);

        if (latestNoteDataRes?.passcodeRequired) {
          this.setState({
            requirePasscode: true,
          });

          return;
        }

        if (
          latestNoteDataRes?.networkError ||
          latestNoteDataRes?.notFound ||
          isEmpty(latestNoteDataRes) ||
          !latestNoteDataRes
        ) {
          this.setStateAsync({
            loading: false,
            showNotFound: true,
          });
        } else if (
          !latestNoteDataRes?.errorMessage &&
          !latestNoteDataRes?.error &&
          !isEmpty(latestNoteDataRes?.title) &&
          latestNoteDataRes?.noteRefId
        ) {
          noteData = { ...latestNoteDataRes };
        } else if (!usingCache) {
          // check since we use the same cached data
          if (storedRes?.noteData) {
            noteData = { ...storedRes?.noteData };
          } else {
            this.setState({ showNetworkError: true });
            return;
          }
        }

        if (!isEmpty(noteData)) {
          this.onNoteContents(noteData, usingCache);
        }
      } else {
        await this.setStateAsync({
          taskId,
          uRef,
          loading: true,
          showNotFound: false,
        });
      }
    }
  };

  setTitle = (title = '') => {
    this.setState(
      {
        title,
      },
      () => {
        const { mode, loading } = this.state;
        const isEditMode = mode === NoteViewMode.edit;
        const isCreateMode = mode === NoteViewMode.create;

        if (loading) {
          return;
        } else if (isEditMode) {
          this.saveUpdatedNote();
        } else if (isCreateMode) {
          this.saveDraft();
        }
      }
    );
  };

  setPriority = (priority = 0) => {
    const { priority: currentPriority } = this.state;

    if (!priority || priority === currentPriority) {
      return;
    }

    this.setState(
      {
        priority,
      },
      () => {
        const { mode, loading } = this.state;
        const isCreateMode = mode === NoteViewMode.create;
        const isEditMode = mode === NoteViewMode.edit;

        if (loading) {
          return;
        }

        if (isEditMode) {
          this.saveUpdatedNote();
        } else if (isCreateMode) {
          this.saveDraft();
        }
      }
    );
  };

  setPrivacy = (exposure = 0, exposurePasscode = '') => {
    if (!exposure || !isNumber(exposure)) {
      return;
    }

    this.setState(
      {
        exposure,
        exposurePasscode:
          isNumber(exposure) && exposure > 2 ? exposurePasscode : '',
        exposureWithPasscode: !isEmpty(exposurePasscode) && exposure > 2,
      },
      () => {
        const { mode, loading } = this.state;
        const isCreateMode = mode === NoteViewMode.create;
        const isEditMode = mode === NoteViewMode.edit;

        if (loading) {
          return;
        }

        if (isEditMode) {
          this.saveUpdatedNote();
        } else if (isCreateMode) {
          this.saveDraft();
        }
      }
    );
  };

  setPersonalTags = (tags = []) => {
    if (isArray(tags)) {
      this.setState({
        personalTags: tags,
      });
    }
  };

  setSpaceTags = (tags = []) => {
    if (isArray(tags)) {
      tags = filter(tags);

      this.setState(
        {
          spaceTags: tags,
        },
        () => {
          const { Editor, mode, deletingNote, taskId, uRef } = this.state;
          const { user, storeNoteData } = this.props;
          const isEditMode = mode === NoteViewMode.edit;

          if (
            !Editor ||
            !user ||
            !isEditMode ||
            deletingNote ||
            !taskId ||
            !uRef
          ) {
            return;
          }

          // update local copy
          if (isFunction(storeNoteData)) {
            storeNoteData(toLower(taskId), { spaceTags: tags });
          }
        }
      );
    }
  };

  setShowReadOnly = (val) => {
    if (isBoolean(val)) {
      this.setState({
        showReadOnly: val,
      });
    }
  };

  setShowDragAndDropFile = (val) => {
    if (isBoolean(val)) {
      this.setState({
        showDragAndDropFile: val,
      });
    }
  };

  saveUpdatedNote = (
    forceSave = false,
    withDebounce = false,
    debounceDelay = 300
  ) => {
    const {
      uRef,
      taskId,
      loading,
      saving,
      priority,
      exposure,
      exposurePasscode,
      title,
      creating,
      mode,
      deletingNote,
      Editor,
      // subscribers,
      // removeSubscribers,
    } = this.state;
    const { user, storeNoteData } = this.props;
    const isCreateMode = mode === NoteViewMode.create;
    const isEditMode = mode === NoteViewMode.edit;
    const headers = getAuthenticatedHeaders(user);

    if (creating || !Editor || isCreateMode || !isEditMode || deletingNote) {
      return;
    }

    let description = Editor.getJSON();

    const save = async () => {
      const {
        deletingNote: latestDeletingNote,
        Editor: updatedEditorInstance,
        subscribers,
        removeSubscribers,
        // personalTags,
        // taskState,
      } = this.state;
      // const { updateNoteData } = this.props;

      if (updatedEditorInstance?.getJSON) {
        description = updatedEditorInstance.getJSON();
      }

      try {
        const descriptionInString = JSON.stringify(description);

        if (latestDeletingNote) {
          return;
        }

        await this.setStateAsync({
          description,
          saving: true,
        });

        // real-time endpoint
        // const newSpaceTags = [];
        // const removeSpaceTags = [];
        // const personalTagIds = filter(
        //   personalTags.map((tagInfo) => tagInfo?.id)
        // );

        const newSubscribers = subscribers
          // storedSubscriberInDb -> means this user info is already stored in database,
          // hence no need to perform save (again)
          .filter((info) => info && !info?.storedSubscriberInDb)
          .map((info) => {
            const userId = getUserIdFromObject(info);
            const userProfileId = getUserProfileIdFromObject(info);

            if (info?.nonUser) {
              return info?.email || '';
            }

            return userId || userProfileId;
          });

        const removeSubscribersSanitized = [];
        const exposureSanitized =
          exposure > 2 && !exposurePasscode ? 1 : exposure;

        forEach(removeSubscribers, (info) => {
          if (info?.profile_id) {
            removeSubscribersSanitized.push(info?.profile_id);
          }

          if (info?.nonUser && info?.email) {
            removeSubscribersSanitized.push(info?.email);
          }

          if (info?.userId) {
            removeSubscribersSanitized.push(info?.userId);
          }

          if (info?.id) {
            removeSubscribersSanitized.push(info?.id);
          }
        });

        if (isFunction(storeNoteData)) {
          storeNoteData(toLower(taskId), {
            title,
            priority,
            exposurePasscode: exposureSanitized > 2 ? exposurePasscode : '',
            exposure: exposureSanitized,
            descData: { desc: descriptionInString },
          });
        }

        const { success: editSuccess, networkError } =
          await updateNoteDataPropertiesRequest(
            taskId,
            uRef,
            title,
            priority,
            exposureSanitized,
            exposureSanitized > 2 ? exposurePasscode : '',
            descriptionInString,
            removeSubscribersSanitized,
            newSubscribers,
            headers
          );

        if (networkError) {
          await this.setStateAsync({
            showNetworkError: true,
          });
        }

        if (editSuccess) {
          const { removeSubscribers: removeSubscribersUpdated, savingQueue } =
            this.state;

          // if edit was successful make sure to set flag 'storedSubscriberInDb' to true
          // since it's already updated and stored in db
          this.setState({
            removeSubscribers: filter(
              removeSubscribersUpdated,
              (info) =>
                !removeSubscribersSanitized?.includes(
                  getUserProfileIdFromObject(info)
                ) &&
                !removeSubscribersSanitized?.includes(
                  getUserIdFromObject(info) || info?.id
                ) &&
                !removeSubscribersSanitized?.includes(info?.email)
            ),
            subscribers: subscribers.map((info) => {
              const userId = getUserIdFromObject(info);
              const userProfileId = getUserProfileIdFromObject(info);
              const storedSubscriberInDb =
                info?.storedSubscriberInDb ||
                includes(newSubscribers, userId) ||
                includes(newSubscribers, userProfileId) ||
                includes(newSubscribers, info?.email);

              return {
                ...info,
                storedSubscriberInDb,
              };
            }),
          });

          if (savingQueue) {
            this.setState(
              {
                savingQueue: false,
              },
              this.saveUpdatedNote
            );
          }
        }
      } catch (err) {
        console.log(err?.message);
      } finally {
        await this.setStateAsync({ saving: false });
      }
    };

    if (loading || (saving && !forceSave)) {
      if (saving && !loading) {
        this.setState({
          savingQueue: true,
        });
      }

      return;
    } else {
      clearTimeout(this.saveUpdatedNoteTimeoutId);
    }

    if (forceSave) {
      if (saving) {
        const intervalId = setInterval(() => {
          const { saving: updatedSavingVal } = this.state;

          if (!updatedSavingVal) {
            clearInterval(intervalId);
            save();
          }
        }, 300);
      } else {
        save();
      }

      return;
    }

    if (withDebounce && isNumber(debounceDelay)) {
      this.saveUpdatedNoteTimeoutId = setTimeout(() => {
        save();
        clearTimeout(this.saveUpdatedNoteTimeoutId);
      }, debounceDelay);
    } else {
      save();
    }
  };

  saveDraft = (
    forceSave = false,
    withDebounce = false,
    debounceDelay = 200
  ) => {
    const {
      Editor,
      saving,
      creating,
      exposure,
      exposurePasscode,
      priority,
      mode,
    } = this.state;
    const isCreateMode = mode === NoteViewMode.create;
    const { user, isLoggedIn, storeUserNoteDraft } = this.props;

    if (!isCreateMode || !user || !isLoggedIn || !Editor?.getJSON) {
      return;
    }

    let description = Editor.getJSON();

    const save = async () => {
      try {
        const { title, Editor: updatedEditor } = this.state;
        const headers = getAuthenticatedHeaders(user);
        const timestampNow = Date.now();
        const timestampNowSeconds = ceil(timestampNow / 1000);

        if (updatedEditor?.getJSON) {
          description = updatedEditor.getJSON();
        }

        const descriptionInString = JSON.stringify(description);

        await this.setStateAsync({
          description,
          saving: true,
        });

        if (isFunction(storeUserNoteDraft)) {
          await storeUserNoteDraft({
            title,
            priority,
            exposure,
            exposurePasscode,
            description: descriptionInString,
            modifiedSeconds: timestampNowSeconds,
          });
        }

        await saveUserNoteDraftRequest(
          title,
          descriptionInString,
          priority,
          exposure,
          exposurePasscode,
          headers
        );

        await this.setStateAsync({
          saving: false,
        });
      } catch (err) {
        console.log('save draft err:', err?.message);
      }
    };

    if (creating || (saving && !forceSave)) {
      return;
    } else {
      clearTimeout(this.saveDraftTimeoutId);
    }

    if (withDebounce && isNumber(debounceDelay)) {
      this.saveDraftTimeoutId = setTimeout(() => {
        save();
        clearTimeout(this.saveDraftTimeoutId);
      }, debounceDelay);
    } else {
      save();
    }
  };

  updateUploadingFilesList = (uploadingFiles = []) => {
    this.setState({
      uploadingFiles: isArray(uploadingFiles) ? uploadingFiles : [],
    });
  };

  updateRemindersInfo = (remindersInfo = null) => {
    if (!isNil(remindersInfo)) {
      this.setState({
        remindersInfo: { ...remindersInfo },
      });
    }
  };

  createNewNote = async () => {
    try {
      const {
        title,
        Editor,
        personalTags,
        priority,
        exposure,
        exposurePasscode,
        subscribers,
        uRef,
        spaceId,
        creating,
        mode,
        spaceTags,
      } = this.state;
      const { user } = this.props;
      const isEditMode = mode === NoteViewMode.edit;

      if (creating || isEditMode || !title) {
        return;
      } else {
        await this.setStateAsync({
          creating: true,
        });
      }

      const description = Editor.getJSON();
      const descriptionInString = JSON.stringify(description);
      const personalTagIds = personalTags
        .map((tagInfo) => tagInfo?.id || '')
        .filter((tagId) => !isEmpty(tagId));
      const exposureSanitized =
        exposure > 2 && (!exposurePasscode || exposurePasscode?.length < 5)
          ? 1
          : exposure;
      const spaceTagsSanitized = spaceTags
        .map((tagInfo) => tagInfo?.id || '')
        .filter((tagId) => !isEmpty(tagId));
      const sanitizedSubscribers = filter(
        subscribers.map((info) => {
          if (info?.nonUser) {
            return info?.email || '';
          }

          return getUserIdFromObject(info) || getUserProfileIdFromObject(info);
        }),
        (id) => !isEmpty(id)
      );
      const headers = getAuthenticatedHeaders(user);
      const sanitizedSpaceId =
        !spaceId || spaceId === 'personal' ? '' : spaceId;

      Editor.setEditable(false);
      const { noteData, success } = await confirmCreateNewNoteRequest(
        title,
        descriptionInString,
        priority,
        exposureSanitized,
        exposureSanitized > 2 ? exposurePasscode || '' : '',
        sanitizedSubscribers,
        sanitizedSpaceId,
        personalTagIds,
        spaceTagsSanitized,
        headers
      );

      if (success) {
        const { noteId: taskId, noteRefId: updatedURef } = noteData;
        const {
          history,
          storeUserNoteDraft,
          getUserNoteDraft,
          isUserNetworkOffline,
          resetPersonalNotestList,
        } = this.props;
        const { advancedOptions: updatedAdvancedOptions } = this.state;
        const noteUrl = `/note/${toUpper(taskId)}?u=${updatedURef || uRef}`;

        if (isFunction(resetPersonalNotestList)) {
          await resetPersonalNotestList({ ...noteData });
        }

        await this.setStateAsync({
          // subscribers stored now in db
          subscribers: map(subscribers, (info) => {
            return { ...info, storedSubscriberInDb: true };
          }),
          showModal: NoteViewModalType.savedChanges,
        });
        // clear draft
        await storeUserNoteDraft({
          title: '',
          priority: 2,
          exposure: 1,
          exposurePasscode: '',
          noteId: '',
          noteRefId: '',
          description: getStringifyCleanTiptapContent(),
        });

        if (isFunction(getUserNoteDraft) && !isUserNetworkOffline) {
          // get user new draft after creating
          await getUserNoteDraft(true);
        } else {
          await timeout(500);
        }

        await this.setStateAsync({
          showModal: '',
          description,
          taskId,
          newSubscribers: [],
          isForGettingStartedNote: false,
          isOwner: true,
          subscriberEditing: false,
          allowedToToggleModify: true,
          loading: false,
          readOnly: false,
          taskState: k.TASK_STATE_ACTIVE,
          exposure: exposureSanitized,
          exposurePasscode:
            exposureSanitized !== exposure ? '' : exposurePasscode,
          uRef: updatedURef,
          mode: NoteViewMode.edit,
        });
        const lastestPathname = toString(window?.location?.pathname || '');

        if (includes(lastestPathname, '/note')) {
          history.push(noteUrl);
          this.startRealtimeCommentsFetch();
          this.fetchActivities(true);
        }

        await saveNoteAdvancedOptionsRequest(
          uRef,
          toLower(taskId),
          {
            allowComments: updatedAdvancedOptions?.allowComments,
            allowSubscribersEdit: updatedAdvancedOptions?.allowSubsEdit,
          },
          headers
        );
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      const { Editor } = this.state;
      await this.setStateAsync({
        creating: false,
        showModal: '',
      });
      Editor.setEditable(true);
    }
  };

  fetchAuthorInfo = async () => {
    const { user } = this.props;
    const { mode, authorId, authorInfo } = this.state;
    const isCreateMode = mode === NoteViewMode.create;
    const userId = getUserIdFromObject(user);
    const isUserAuthor =
      isCreateMode || user?.userProfileId === authorId || userId === authorId;

    if (isUserAuthor) {
      const fullName = getUserDisplayName(user);
      const author = {
        userId,
        fullName,
        initials: getUserInitials(user),
        id: userId,
        image: user?.image || '',
        profile_id: user?.userProfileId || userId,
      };
      this.setState({ author, authorFullName: fullName });
      this.updateConnectedUsers([author]);
    } else {
      // fetch user info

      let targetUser = null;

      if (!isEmpty(authorInfo)) {
        targetUser = { ...authorInfo };
      } else {
        const res = await getUserInfoByIdOrUsernameOrEmailRequest(authorId);

        if (res?.user) {
          targetUser = res?.user;
        }
      }

      if (!isEmpty(targetUser)) {
        const fullName = getUserDisplayName(targetUser);
        const author = {
          fullName,
          userId: targetUser?.userId,
          initials: getUserInitials(targetUser),
          id: targetUser?.userId || authorId,
          image: targetUser?.image || '',
          profile_id: targetUser?.userProfileId || targetUser?.userId,
        };
        this.setState({ author, authorFullName: fullName });
        this.updateConnectedUsers([author]);
      }
    }
  };

  fetchRemindersInfo = async () => {
    try {
      const { user, isLoggedIn } = this.props;
      const { uRef } = this.state;

      if (!isLoggedIn) {
        return;
      }

      const headers = getAuthenticatedHeaders(user);

      await this.setStateAsync({
        fetchingRemindersInfo: true,
      });

      const { reminders, networkError, errorMessage, enabled } =
        await getNoteRemindersRequest(uRef, headers);

      if (!networkError && !errorMessage) {
        await this.setStateAsync({
          remindersInfo: { reminders, enabled },
        });
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      await this.setStateAsync({
        fetchingRemindersInfo: false,
      });
    }
  };

  /** Subscribers and connected/saved users  */
  updateConnectedUsers = (users = []) => {
    const { connectedUsers } = this.state;
    const storedUserIds = [];

    for (let i = 0; i < size(connectedUsers); i++) {
      const connectedUser = connectedUsers[i];

      if (!isEmpty(connectedUser)) {
        const userProfileId =
          connectedUser?.userProfileId || connectedUser?.profile_id;
        const userId = connectedUser?.userId;

        if (userProfileId) {
          storedUserIds.push(userProfileId);
        }

        if (userId) {
          storedUserIds.push(userId);
        }
      }
    }

    const sanitizedSavedUsers = filter(users, (info) => {
      const userId = info?.userId;
      const userProfileId = info?.userProfileId || info?.profile_id;
      return (
        !includes(storedUserIds, userId) &&
        !includes(storedUserIds, userProfileId) &&
        !isEmpty(info)
      );
    });
    const updated = [...connectedUsers, ...sanitizedSavedUsers];

    this.setState({
      connectedUsers: updated,
    });
  };

  onConnectedUsers = async () => {
    const { mounted } = this.state;
    const { savedUsers = [] } = this.props;

    if (!mounted) {
      return;
    }

    const savedUsersSanitized = (savedUsers || [])
      .filter((userInfo) => !isNil(userInfo))
      .map((userInfo) => {
        return { ...userInfo, saved: true };
      });

    this.updateConnectedUsers(savedUsersSanitized);
  };

  // onCommentsList = async ({
  //   valid = false,
  //   value = [],
  //   has_next = false,
  //   not_allowed = false,
  //   block_write = false,
  //   limit_reached = false,
  //   page = 1,
  // }) => {
  //   const {
  //     commentsCurrentPages,
  //     commentsCurrentPagesWithComments,
  //     commentsQueuedPages,
  //     taskId,
  //   } = this.state;
  //   const { isLoggedIn } = this.props;
  //   const { pathname } = window.location;
  //   const commentsBlocked =
  //     block_write ||
  //     not_allowed ||
  //     (limit_reached && (not_allowed || block_write)) ||
  //     !isLoggedIn;
  //   const commentsView = !not_allowed;

  //   if (!pathname || !pathname?.includes('/note') || !taskId) {
  //     return;
  //   }

  //   await this.setStateAsync({
  //     commentsBlocked,
  //     commentsView,
  //   });

  //   if (valid) {
  //     if (!commentsCurrentPages.includes(page)) {
  //       await this.setStateAsync({
  //         commentsCurrentPages: [...commentsCurrentPages, page],
  //       });
  //     }

  //     commentsCurrentPagesWithComments[page - 1] = value;

  //     await this.setStateAsync({
  //       commentsCurrentPagesWithComments: [...commentsCurrentPagesWithComments],
  //       commentsHasNext: Boolean(has_next),
  //     });

  //     if (!isEmpty(commentsQueuedPages)) {
  //       const targetPage = commentsQueuedPages.shift();
  //       this.fetchComments(false, targetPage);
  //     }
  //   }
  // };

  fetchComments = async (increasePage = false, targetPage = 0) => {
    const {
      commentsCurrentPage,
      commentsQueuedPages,
      commentsFetching,
      uRef,
      mode,
      commentsHasNext,
    } = this.state;
    const { user } = this.props;
    const isEditMode = mode === NoteViewMode.edit;
    const isCreateMode = mode === NoteViewMode.create;

    if (isEditMode || isCreateMode || (increasePage && !commentsHasNext)) {
      return;
    }

    if (!targetPage) {
      targetPage = commentsCurrentPage;
    }

    if (increasePage) {
      targetPage += 1;
    }

    if (commentsFetching) {
      if (!commentsQueuedPages.includes(targetPage)) {
        await this.setStateAsync({
          commentsQueuedPages: [...commentsQueuedPages, targetPage],
        });
      }

      return;
    }

    try {
      await this.setStateAsync({
        commentsFetching: true,
        commentsCurrentPage: targetPage,
      });
      const headers = getAuthenticatedHeaders(user);
      const { comments, hasNext, allowComments, errorMessage, networkError } =
        await getNoteCommentsListByPageRequest(targetPage, uRef, headers);

      if (!errorMessage && !networkError) {
        const {
          commentsCurrentPagesWithComments,
          advancedOptions,
          commentsCurrentPages,
        } = this.state;
        commentsCurrentPagesWithComments[targetPage - 1] = comments;

        await this.setStateAsync({
          commentsFetching: false,
          commentsCurrentPages: [
            ...filter(commentsCurrentPages, (p) => p !== targetPage),
            targetPage,
          ],
          advancedOptions: { ...advancedOptions, allowComments },
          commentsCurrentPagesWithComments: [
            ...commentsCurrentPagesWithComments,
          ],
          commentsHasNext: Boolean(hasNext),
        });

        if (!isEmpty(commentsQueuedPages)) {
          const targetPage = commentsQueuedPages.shift();
          this.setState(
            { commentsQueuedPages, commentsFetching: false },
            () => {
              this.fetchComments(false, targetPage);
            }
          );
        }
      } else {
        this.setState({
          commentsFetching: false,
        });
      }
    } catch (err) {
      console.log(err?.message);
      this.setState({
        commentsFetching: false,
      });
    }
  };

  fetchCommentsByPage = async (page = 0, storePage = false) => {
    const {
      commentSubmitting,
      uRef,
      mode,
      commentsFetchingRealtime,
      commentsRealtimeFetchBlock,
    } = this.state;
    const { user, markUserIsOnline, isUserNetworkOffline, markUserIsOffline } =
      this.props;
    const isEditMode = mode === NoteViewMode.edit;
    const isCreateMode = mode === NoteViewMode.create;
    const headers = getAuthenticatedHeaders(user);

    if (
      commentSubmitting ||
      isCreateMode ||
      isEditMode ||
      commentsFetchingRealtime ||
      commentsRealtimeFetchBlock
    ) {
      return;
    }

    try {
      await this.setStateAsync({
        commentsFetchingRealtime: true,
      });

      const { comments, allowComments, errorMessage, networkError } =
        await getNoteCommentsListByPageRequest(page, uRef, headers);

      if (!errorMessage && !networkError) {
        const {
          commentsCurrentPagesWithComments,
          advancedOptions,
          commentsCurrentPages,
        } = this.state;

        commentsCurrentPagesWithComments[page - 1] = comments;

        await this.setStateAsync({
          commentsFetchingRealtime: false,
          commentsCurrentPages: [
            ...filter(commentsCurrentPages, (p) => p !== page),
            page,
          ],
          advancedOptions: { ...advancedOptions, allowComments },
          commentsCurrentPagesWithComments: [
            ...commentsCurrentPagesWithComments,
          ],
        });

        if (storePage) {
          await this.setStateAsync({
            commentsCurrentPage: page,
          });
        }

        if (isFunction(markUserIsOnline) && isUserNetworkOffline) {
          markUserIsOnline();
        }
      }

      if (networkError) {
        markUserIsOffline();
      }
    } catch (err) {
      console.log(`fetchCommentsByPage err:${err?.message}`);
    } finally {
      await this.setStateAsync({
        commentsFetchingRealtime: false,
      });
    }
  };

  markCommentSubmitting = (val) => {
    if (isBoolean(val)) {
      this.setState({
        commentSubmitting: val,
      });
    }
  };

  markBlockCommentsFetchRealtime = (val) => {
    if (isBoolean(val)) {
      this.setState({
        commentsRealtimeFetchBlock: val,
      });
    }
  };

  startRealtimeCommentsFetch = () => {
    clearInterval(this.commentsRealtimeIntervalId);

    this.commentsRealtimeIntervalId = setInterval(() => {
      const { commentsFetchingRealtime, mode, isForGettingStartedNote } =
        this.state;
      const isEditMode = mode === NoteViewMode.edit;
      const isCreateMode = mode === NoteViewMode.create;

      if (
        isEditMode ||
        isCreateMode ||
        commentsFetchingRealtime ||
        isForGettingStartedNote ||
        document?.hidden ||
        !isEmpty(document?.body?.getAttribute('isHidden'))
      ) {
        return;
      }

      this.fetchCommentsByPage(1);
    }, 5_500);
  };

  updateComment = (refId = '', params = {}) => {
    const { commentsCurrentPagesWithComments } = this.state;
    const totalPages = commentsCurrentPagesWithComments?.length;
    let hasChange = false;

    for (let i = 0; i < totalPages; i++) {
      const comments = commentsCurrentPagesWithComments[i];

      if (isArray(comments) && comments?.length) {
        for (let y = 0; y < comments.length; y++) {
          const targetComment = commentsCurrentPagesWithComments[i][y];

          if (
            targetComment &&
            (targetComment?.ref_id === refId || targetComment?.refId === refId)
          ) {
            commentsCurrentPagesWithComments[i][y] = {
              ...targetComment,
              ...params,
            };

            hasChange = true;
            break;
          }
        }
      }
    }

    if (hasChange) {
      this.setState({
        commentsCurrentPagesWithComments: [...commentsCurrentPagesWithComments],
      });
    }
  };

  fetchActivities = async (goNextPage = false) => {
    const {
      mode,
      uRef,
      activitiesCurrentPage,
      activities,
      isForGettingStartedNote,
    } = this.state;
    const isCreateMode = mode === NoteViewMode.create;

    if (isCreateMode || isForGettingStartedNote) {
      return;
    } else {
      await this.setStateAsync({
        activitiesFetching: true,
      });
    }
    const { user } = this.props;
    const headers = getAuthenticatedHeaders(user || {});
    const {
      activities: latestList,
      errorMessage,
      networkError,
      hasNext,
    } = await getNoteActivitiesByPageRequest(
      activitiesCurrentPage,
      uRef,
      headers
    );

    if (errorMessage || networkError) {
      return;
    }

    const updatedActivities = [
      ...(activitiesCurrentPage === 1 ? [] : activities),
      ...latestList,
    ];

    this.setState({
      activities: filter(updatedActivities, (activity) => !isNil(activity)),
    });

    if (goNextPage && hasNext && activitiesCurrentPage < 3) {
      // only fetch 3 pages
      this.fetchActivities(true);
      await this.setStateAsync({
        activitiesFetching: false,
        activitiesCurrentPage: activitiesCurrentPage + 1,
      });
    } else {
      await this.setStateAsync({
        activitiesFetching: false,
      });
    }
  };

  fetchSubscribers = async () => {
    try {
      const { mode, uRef } = this.state;
      const { user, isLoggedIn } = this.props;
      const isCreateMode = mode === NoteViewMode.create;
      const headers = getAuthenticatedHeaders(user);

      if (!isLoggedIn) {
        return;
      }

      if (!isCreateMode) {
        const { subscribers, hasNext, errorMessage, networkError } =
          // fetches 2 pages for now
          // limit to 50-100 subscribers for now
          await getNoteSubscribersByPageRequest(1, uRef, headers);

        let accSubscribers = [];

        if (!errorMessage && !networkError) {
          if (!isEmpty(subscribers)) {
            if (hasNext) {
              const { subscribers: secondPageSubscribers = [] } =
                await getNoteSubscribersByPageRequest(2, uRef, headers);
              accSubscribers = [...subscribers, ...secondPageSubscribers];
            } else {
              accSubscribers = [...subscribers];
            }

            accSubscribers = map(accSubscribers, (info) => {
              return { ...info, storedSubscriberInDb: true };
            });

            this.setState({ subscribers: filter(accSubscribers) });
            this.updateConnectedUsers(
              filter(accSubscribers, (info) => info && !info?.nonUser)
            );
          }
        }
      } else {
        this.setState({ subscribers: [] });
      }
    } catch (err) {
      console.log(`fetchSubscribers err: ${err?.message}`);
    }
  };

  /** Subscribers and connected/saved users  */
  markCompleteOrActive = async () => {
    const { user, isUserNetworkOffline } = this.props;
    const { loading, mounted, saving, taskState, uRef } = this.state;

    if (loading || !mounted || saving) {
      return;
    }

    const toActive = taskState === k.TASK_STATE_INACTIVE;
    const toInactive = taskState === k.TASK_STATE_ACTIVE;
    let newState = '';

    if (toActive) {
      newState = k.TASK_STATE_ACTIVE;
    } else if (toInactive) {
      newState = k.TASK_STATE_INACTIVE;
    }

    if (!newState) {
      this.setState({
        updatingState: false,
      });

      return;
    }

    if (isUserNetworkOffline) {
      // user is offline
      // cannot set state
      return;
    }

    try {
      this.setState({
        updatingState: true,
      });
      const headers = getAuthenticatedHeaders(user);
      const { success } = await updateNoteStateRequest(uRef, newState, headers);

      if (success) {
        // change state
        this.setState({
          taskState: newState,
        });
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      this.setState({
        updatingState: false,
      });
    }
  };

  addSubscriber = (targetUser = null) => {
    clearTimeout(this.addSubscriberTimeoutId);

    const add = () => {
      const { subscribers, mode, removeSubscribers } = this.state;
      const targetUserId = getUserIdFromObject(targetUser);
      const find = head(
        filter(
          subscribers,
          (userInfo) =>
            userInfo?.email === targetUser?.email ||
            (targetUserId &&
              (getUserIdFromObject(userInfo) === targetUserId ||
                getUserProfileIdFromObject(userInfo) === targetUserId))
        )
      );
      const targetStoredForRemoval = head(
        filter(removeSubscribers, (userInfo) => {
          return (
            userInfo?.email === targetUser?.email ||
            getUserIdFromObject(userInfo) === targetUserId
          );
        })
      );
      const isEditMode = mode === NoteViewMode.edit;
      const canStoreNewSubscriber =
        (targetUserId || targetUser?.email) && (!find || isEmpty(find));

      if (canStoreNewSubscriber) {
        // add new
        this.setState(
          {
            subscribers: [
              ...subscribers,
              { ...targetUser, storedSubscriberInDb: false },
            ],
            ...(!isEmpty(targetStoredForRemoval) && {
              removeSubscribers: filter(
                removeSubscribers,
                (userInfo) =>
                  !isEmpty(userInfo) &&
                  userInfo?.email !== targetUser?.email &&
                  getUserIdFromObject(userInfo) !== targetUserId
              ),
            }),
          },
          () => {
            if (isEditMode) {
              // send request
              this.saveUpdatedNote();
            }
          }
        );
      }
    };

    this.addSubscriberTimeoutId = setTimeout(() => {
      add();
      clearTimeout(this.addSubscriberTimeoutId);
    }, 300);
  };

  removeSubscriber = (userIdOrEmail = '') => {
    // to debounce
    const { mode } = this.state;
    const { user } = this.props;
    const isCreateMode = mode === NoteViewMode.create;
    const userId = getUserIdFromObject(user);
    const userProfileId = getUserProfileIdFromObject(user);

    if (userId === userIdOrEmail || userProfileId === userIdOrEmail) {
      // cannot remove own self
      return;
    }

    clearTimeout(this.removeSubscriberTimeoutId);

    const remove = () => {
      const { subscribers, mode, removeSubscribers } = this.state;
      const find = head(
        subscribers.filter(
          (user) =>
            user &&
            userIdOrEmail &&
            (user?.email === userIdOrEmail ||
              getUserIdFromObject(user) === userIdOrEmail ||
              getUserProfileIdFromObject(user) === userIdOrEmail)
        )
      );
      const filtered = filter(subscribers, (userInfo) => {
        return (
          !isEmpty(userInfo) &&
          userInfo?.email !== userIdOrEmail &&
          getUserIdFromObject(userInfo) !== userIdOrEmail &&
          getUserProfileIdFromObject(userInfo) !== userIdOrEmail
        );
      });
      const hasChange = !isEmpty(find) || size(subscribers) !== size(filtered);
      const isEditMode = mode === NoteViewMode.edit;

      this.setState(
        {
          removeSubscribers: !isEmpty(find)
            ? [
                ...removeSubscribers.filter(
                  (user) =>
                    getUserIdFromObject(user) !== userIdOrEmail &&
                    getUserProfileIdFromObject(user) !== userIdOrEmail &&
                    user?.email !== userIdOrEmail
                ),
                find,
              ]
            : removeSubscribers,
          subscribers: filtered,
        },

        () => {
          if (hasChange && isEditMode) {
            // send request
            this.saveUpdatedNote();
          }
        }
      );
    };

    this.removeSubscriberTimeoutId = setTimeout(
      () => {
        remove();
        clearTimeout(this.removeSubscriberTimeoutId);
      },
      isCreateMode ? 50 : 150
    );
  };

  removeSavedUser = (userId = '') => {
    const { connectedUsers } = this.state;
    const find = connectedUsers.filter(
      (user) => user?.id === userId || user?.profile_id === userId
    );

    if (!isEmpty(find)) {
      this.setState(
        {
          connectedUsers: connectedUsers.filter(
            (user) => user && user?.id !== userId && user?.profile_id !== userId
          ),
        },
        () => {
          // ProfileAPI.USER_TASKS.unsaveUser(userId)
        }
      );
    }
  };

  updateTotalComments = (totalComments) => {
    if (isNumber(totalComments)) {
      this.setState({
        totalComments,
      });
    }
  };

  openImageViewer = (image = '') => {
    this.setState({
      showImageViewer: true,
      showImageViewerSrc: image || '',
    });
  };

  closeImageViewer = () => {
    this.setState({
      showImageViewer: false,
      showImageViewerSrc: '',
    });
  };

  setShowFilesView = (val) => {
    if (isBoolean(val)) {
      this.setState({
        showFilesView: val,
      });
    }
  };

  setMode = (mode) => {
    const { isUserNetworkOffline, fetchingNotesData } = this.props;
    const { uRef } = this.state;

    if (
      !isEmpty(mode) &&
      (mode === NoteViewMode.create ||
        mode === NoteViewMode.edit ||
        mode === NoteViewMode.view)
    ) {
      if (isUserNetworkOffline && mode === NoteViewMode.edit) {
        // do not allow edit toggle
        // if user's offline
        return;
      }

      if (includes(fetchingNotesData, uRef) && mode === NoteViewMode.edit) {
        return;
      }

      this.setState({
        mode,
      });
    }
  };

  deleteNote = async () => {
    const { taskId, uRef, mode, canUnsubscribe, canDelete } = this.state;
    const { history, user, markDeletedNote } = this.props;
    const isCreateMode = mode === NoteViewMode.create;
    const headers = getAuthenticatedHeaders(user);

    if (isCreateMode) {
      return;
    } else {
      if (canUnsubscribe) {
        // unsubscribe only

        this.setState({
          showFilesView: false,
          deletingNote: true,
        });
      } else if (canDelete) {
        this.setState({
          showFilesView: false,
          deletingNote: true,
        });
        const { success } = await deleteNoteDataRequest(
          uRef,
          toLower(taskId),
          headers
        );
        this.closeModal();

        if (success) {
          if (history?.push) {
            history.push('/user');
          }

          if (isFunction(markDeletedNote)) {
            markDeletedNote(taskId, uRef);
          }
        } else {
          await this.setStateAsync({
            saving: false,
            deletingNote: false,
          });
        }
      }
    }
  };

  fetchAdvancedOptions = async () => {
    const { taskId, uRef, mode, isForGettingStartedNote } = this.state;
    const { user } = this.props;
    const isCreateMode = mode === NoteViewMode.create;

    if (isCreateMode || isForGettingStartedNote) {
      this.setState({
        fetchingAdvancedOptions: false,
      });

      return;
    }

    this.setState({ fetchingAdvancedOptions: true });

    try {
      const headers = getAuthenticatedHeaders(user);
      const {
        networkError,
        errorMessage,
        allowComments,
        allowSubscribersEdit,
      } = await getNoteDataAdvancedOptionsRequest(uRef, taskId, headers);

      if (!networkError && !errorMessage) {
        const advancedOptions = {
          allowComments,
          allowSubsEdit: allowSubscribersEdit,
        };

        this.setState({
          advancedOptions,
        });
      }
    } catch (err) {
      console.log(`fetchAdvancedOptions err: ${err?.message}`);
    } finally {
      this.setState({
        fetchingAdvancedOptions: false,
      });
    }
  };

  updateAdvancedOptions = (params) => {
    const { advancedOptions } = this.state;
    const { allowComments, allowSubsEdit } = params;
    const props = {
      ...(isBoolean(allowComments) && { allowComments }),
      ...(isBoolean(allowSubsEdit) && { allowSubsEdit }),
    };
    this.setState(
      {
        advancedOptions: { ...advancedOptions, ...props },
      },
      async () => {
        clearTimeout(this.advancedOptionsTimeoutId);

        this.advancedOptionsTimeoutId = setTimeout(() => {
          const save = async () => {
            const {
              advancedOptions: updatedAdvancedOptions,
              taskId,
              uRef,
            } = this.state;
            const { user } = this.props;
            const headers = getAuthenticatedHeaders(user);

            await saveNoteAdvancedOptionsRequest(
              uRef,
              taskId,
              {
                allowSubscribersEdit: updatedAdvancedOptions?.allowSubsEdit,
                allowComments: updatedAdvancedOptions?.allowComments,
              },
              headers
            );
          };

          save();
          clearTimeout(this.advancedOptionsTimeoutId);
        }, 500);
      }
    );
  };

  render() {
    const { children } = this.props;
    const {
      mode,
      isForGettingStartedNote,
      title,
      exposure,
      exposurePasscode,
      priority,
      // description,
      loading,
      spaceId,
      Editor,
      pdf,
      showModal,
      taskId,
      uRef,
      views,
      showNetworkError,
      showSavedChanges,
      showSavingInProgress,
      showFilesView,
      showNotFound,
      showReadOnly,
      showDragAndDropFile,
      allowedToToggleModify,
      uploadingFiles,
      creating,
      author,
      authorId,
      requirePasscode,
      personalTags,
      spaceTags,
      remindersInfo,
      readOnly,
      subscribers,
      connectedUsers,
      updatingState,
      updatedDateTime,
      saving,
      taskState,
      showImageViewer,
      showImageViewerSrc,
      deletingNote,
      authorIsPremium,
      activities,
      activitiesCurrentPage,
      activitiesFetching,
      commentsBlocked,
      commentsView,
      commentsHasNext,
      commentsCurrentPage,
      commentsCurrentPages,
      commentsCurrentPagesWithComments,
      commentsFetching,
      commentsQueuedPages,
      advancedOptions,
      descriptionUpdated,
      fetchingAdvancedOptions,
      subscriberEditing,
      isOwner,
      spaceInfo,
      authorInfo,
      canUnsubscribe,
      canDelete,
    } = this.state;
    const isEditMode = mode === NoteViewMode.edit;
    const isCreateMode = mode === NoteViewMode.create;
    const isViewMode = mode === NoteViewMode.view || !mode;

    const propsValue = {
      canUnsubscribe,
      canDelete,
      authorInfo,
      spaceInfo,
      authorIsPremium,
      authorId,
      title,
      priority,
      exposure,
      exposurePasscode,
      loading,
      isCreateMode,
      isEditMode,
      spaceId,
      pdf,
      showModal,
      isForGettingStartedNote,
      taskId,
      uRef,
      showNetworkError,
      showSavedChanges,
      showSavingInProgress,
      showNotFound,
      showReadOnly,
      showDragAndDropFile,
      deletingNote,
      allowedToToggleModify,
      uploadingFiles,
      creating,
      author,
      views,
      requirePasscode,
      personalTags,
      spaceTags,
      remindersInfo,
      readOnly,
      subscribers,
      connectedUsers,
      updatingState,
      updatedDateTime,
      saving,
      taskState,
      showImageViewer,
      showImageViewerSrc,
      activities,
      activitiesCurrentPage,
      activitiesFetching,

      commentsBlocked,
      commentsView,
      commentsHasNext,
      commentsCurrentPage,
      commentsCurrentPages,
      commentsCurrentPagesWithComments,
      commentsFetching,
      commentsQueuedPages,
      isViewMode,
      showFilesView,
      advancedOptions,
      descriptionUpdated,
      fetchingAdvancedOptions,
      subscriberEditing,
      isOwner,
      EditorInstance: Editor,
      clearConnectedUsers: this.clearConnectedUsers,
      setShowDragAndDropFile: this.setShowDragAndDropFile,
      setShowReadOnly: this.setShowReadOnly,
      updateAdvancedOptions: this.updateAdvancedOptions,
      setMode: this.setMode,
      onNoteContents: this.onNoteContents,
      deleteNote: this.deleteNote,
      setShowFilesView: this.setShowFilesView,
      updateComment: this.updateComment,
      markBlockCommentsFetchRealtime: this.markBlockCommentsFetchRealtime,
      fetchCommentsByPage: this.fetchCommentsByPage,
      markCommentSubmitting: this.markCommentSubmitting,
      openImageViewer: this.openImageViewer,
      closeImageViewer: this.closeImageViewer,
      updateTotalComments: this.updateTotalComments,
      removeSubscriber: this.removeSubscriber,
      addSubscriber: this.addSubscriber,
      removeSavedUser: this.removeSavedUser,
      updateConnectedUsers: this.updateConnectedUsers,
      updateRemindersInfo: this.updateRemindersInfo,
      setPersonalTags: this.setPersonalTags,
      setSpaceTags: this.setSpaceTags,
      setPrivacy: this.setPrivacy,
      setPriority: this.setPriority,
      markCompleteOrActive: this.markCompleteOrActive,
      createNewNote: this.createNewNote,
      updateUploadingFilesList: this.updateUploadingFilesList,
      setTargetSpace: this.setTargetSpace,
      reset: this.reset,
      saveDraft: this.saveDraft,
      saveUpdatedNote: this.saveUpdatedNote,
      startNoteEditMode: this.startNoteEditMode,
      startNoteCreateMode: this.startNoteCreateMode,
      expandPdf: this.expandPdf,
      closeModal: this.closeModal,
      showModalType: this.showModalType,
      setTitle: this.setTitle,
      checkIfCanModifyEditor: this.checkIfCanModifyEditor,
      storeEditorInstance: this.storeEditorInstance,
      toggleMode: this.toggleMode,
    };

    return <Provider value={propsValue}>{children} </Provider>;
  }
}

export default withNetworkSettings(
  withUserDataAndProfileSettings(
    withSpacesAndUserSettings(withNoteDataSettings(withRouter(NoteViewContext)))
  )
);
