import cx from 'classnames';
import styles from './Button.module.scss';
import { isString, isFunction, isIosMobileApp } from 'src/helpers/utils';
import { Button, Spinner } from 'evergreen-ui';

/**
 * Custom text button
 * @param {*} param0
 * @returns
 */
const CustomButtonWrapper = ({
  variant = '',
  label = '',
  loading = false,
  disabled = false,
  className = '',
  uniqueClassName = '',
  children,
  onClick,
  icon = null,
  isThemeDarkMode = false,
}) => {
  // TODO: need to customize for other variants
  const isLoading = loading;
  const isPrimaryBlue = variant === 'primaryBlue';
  const isPrimaryPurple = variant === 'primaryPurple';
  const isPrimaryDiscard = variant === 'primaryDiscard';
  const isSecondaryLight = variant === 'secondaryLight';
  const isSecondaryButton = variant === 'secondary';
  const isDangerConfirm = variant === 'primaryDanger';

  return (
    <Button
      className={cx(styles.custom_button, styles.flex_row_xy, {
        [`${className}`]: isString(className) && className,
        [styles.custom_button_loading]:
          (isLoading || disabled) && !isIosMobileApp(),
        [styles.custom_button_disabled]: disabled && !isIosMobileApp(),
        [styles.custom_button_primaryPurple]: isPrimaryPurple,
        [styles.custom_button_primaryDiscard]: isPrimaryDiscard,
        [styles.custom_button_primaryPurple_dark]:
          isThemeDarkMode && isPrimaryPurple,
        [styles.custom_button_primaryDanger]: isDangerConfirm,
        [styles.custom_button_primaryBlue]: isPrimaryBlue,
        [styles.custom_button_secondaryLight]: isSecondaryLight,
        [styles.custom_button_secondary]: isSecondaryButton,
        [uniqueClassName]: isString(uniqueClassName) && uniqueClassName,
      })}
      appearance="minimal"
      onClick={(...args) => {
        if (isFunction(onClick) && !isLoading && !disabled) {
          onClick(...args);
        }
      }}
    >
      {children ? children : <></>}
      {!isLoading && icon ? (
        <div className={cx(styles.flex_row_xy, styles.icon)}>
          {icon || null}
        </div>
      ) : (
        <></>
      )}
      {!children && !isLoading ? (
        <p
          className={cx(styles.text, {
            [styles.text_primaryPurple]: isPrimaryPurple,
            [styles.text_primaryDiscard]: isPrimaryDiscard,
            [styles.text_primaryBlue]: isPrimaryBlue,
            [uniqueClassName]: isString(uniqueClassName) && uniqueClassName,
          })}
        >
          {label}
        </p>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div
          className={cx(styles.loading, {
            [styles.loading_primaryPurple]: isPrimaryPurple || isPrimaryDiscard,
            [uniqueClassName]: isString(uniqueClassName) && uniqueClassName,
          })}
        >
          <Spinner />
        </div>
      ) : (
        <></>
      )}
    </Button>
  );
};

export default CustomButtonWrapper;
