import cx from 'classnames';
import styles from './Search.module.scss';
import NoResults from './NoResults';
import SearchUnit from './SearchUnit';
import SavedSearchUnit from './SavedSearchUnit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth, useDebounce } from 'src/hooks';
import { SearchIcon, Spinner } from 'evergreen-ui';
import {
  getDataRefIdValue,
  isIosMobileApp,
  isMobileView,
} from 'src/helpers/utils';
import {
  toLower,
  toString,
  filter,
  isEmpty,
  isFunction,
  isArray,
  map,
} from 'lodash';
import {
  // disableBodyScroll,
  enableBodyScroll,
} from 'src/lib/bodyScrollLock.min';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withModalPromptSettings } from 'src/managers/modal';
import {
  saveRecenSearchRequest,
  searchUserNotesRequest,
} from 'src/managers/api';

const Search = (props) => {
  const modalWrapperUniqueClassname = 'SearchModalWrapperUniqueClassname';
  const savedSearchUnitUniqueClassname = 'savedSearchUnitUniqueClassname';
  const { closeModal, isThemeDarkMode, userSpaces: spaces } = props;

  const initDone = useRef(false);
  const searchDomRef = useRef();
  const searchInputRef = useRef();
  const savedSearchWrapRef = useRef();
  const searchResultsWrap = useRef();
  const [searching, setSearching] = useState(!isEmpty(props?.query));
  const [searchValue, setSearchValue] = useState(toString(props?.query));
  const [searchResults, setSearchResults] = useState([]);
  const [savedSearch, setSavedSearch] = useState({
    accessed: [],
    searched: [],
  });

  const filteredSpaces = useMemo(() => {
    return filter(spaces, (spaceInfo) => {
      const spaceName = toString(spaceInfo?.name);

      if (
        searchValue &&
        !isEmpty(spaceName) &&
        spaceName.includes(toLower(searchValue))
      ) {
        return true;
      }

      return false;
    }).map((spaceInfo) => {
      return {
        image: spaceInfo?.image,
        id: spaceInfo?.id || spaceInfo?.space_id,
        type: 'space',
        title: spaceInfo?.name,
        totalMembers: spaceInfo.membersTotal,
        space: spaceInfo,
      };
    });
  }, [spaces, searchValue]);

  const close = useCallback(() => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  }, [closeModal]);

  const { getAuthenticatedHeaders } = useAuth(props);

  const hitSearch = useDebounce(async (key) => {
    try {
      setSearching(true);
      const headers = getAuthenticatedHeaders();
      const searchRes = await searchUserNotesRequest(key, headers);
      const sanitizedResults = isArray(searchRes?.notes)
        ? map(searchRes.notes, (note) => ({ ...note, type: 'task' }))
        : [];
      setSearchResults(filter(sanitizedResults));
      setSearching(false);

      const accessed = sanitizedResults.slice(0, 1);
      const searched = key;

      setSavedSearch({
        accessed: [...accessed],
        searched: [
          searched,
          ...filter(savedSearch?.searched, (key) => key !== searched),
        ],
      });

      await saveRecenSearchRequest(
        key,
        sanitizedResults.slice(0, 1) || [],
        headers
      );
    } catch {
      setSearching(false);
    }
  }, 500);

  /**
   * Auto focus input
   */
  useEffect(() => {
    const initialQuery = props?.query;

    const init = async () => {
      try {
        initDone.current = true;

        setSearching(true);
        const { accessed, searched } = {};

        // await ProfileAPI.SEARCH.getSavedSearch();

        if (accessed) {
          setSavedSearch({ accessed, searched });
        }

        if (initialQuery?.length) {
          setSearching(true);
          hitSearch(initialQuery);
        } else {
          setSearching(false);
        }
      } catch {
        initDone.current = false;
        setSearching(false);
        setSavedSearch({ accessed: [], searched: [] });
      }
    };

    if (!initDone.current) {
      init();
    }
  }, [hitSearch, props?.query]);

  /**
   * Focus input for desktop view
   */
  useEffect(() => {
    const dom = searchInputRef?.current;

    if (!isMobileView() && dom) {
      dom?.focus();
    }
  }, []);

  /**
   * Close search modal if outer dom was clicked
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (!evt?.target) {
        return;
      }

      if (evt?.target?.classList?.contains(modalWrapperUniqueClassname)) {
        close();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [close]);

  /**
   * If past search key was clicked, set it as search value
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (!evt?.target || !savedSearch?.searched?.length || searching) {
        return;
      }

      if (evt?.target?.classList?.contains(savedSearchUnitUniqueClassname)) {
        const targetSearchKey = evt?.target?.dataset?.searchkey;

        if (targetSearchKey && targetSearchKey !== searchValue) {
          setSearchValue(targetSearchKey);
          setSearching(true);
          hitSearch(targetSearchKey);
        }
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };

    // eslint-disable-next-line
  }, [savedSearch?.searched, searching, searchValue, hitSearch]);

  /**
   * Free scroll lock on unmount
   */
  useEffect(() => {
    const dom = searchResultsWrap?.current;

    return () => {
      if (dom) {
        enableBodyScroll(dom);
      }
    };
  }, []);

  const onSearchInput = (evt) => {
    if (!evt?.target) {
      return;
    }

    const value = `${evt?.target?.value}`;

    if (!searching) {
      setSearching(true);
    }

    setSearchValue(value);
    hitSearch(value);
  };

  const mostRecentAccessed = useMemo(
    () => savedSearch?.accessed || [],
    [savedSearch]
  );

  return (
    <div
      className={cx(
        styles.flex_row_xy,
        styles.search_modal,
        {
          [styles.search_modal_ios_app]: isIosMobileApp(),
        },
        modalWrapperUniqueClassname
      )}
      ref={searchDomRef}
    >
      <div
        className={cx(styles.search_modal_content, {
          [styles.search_modal_content_ios_app]: isIosMobileApp(),
        })}
      >
        <div
          className={cx(styles.flex_row_xy, styles.search, {
            [styles.search_light]: !isThemeDarkMode,
            [styles.search_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.flex_row_xy, styles.icon, {
              [styles.icon_dark]: isThemeDarkMode,
            })}
          >
            <SearchIcon />
          </div>
          <div
            className={cx(styles.flex_row_xy, styles.input, {
              [styles.input_dark]: isThemeDarkMode,
            })}
          >
            <input
              ref={searchInputRef}
              value={searchValue}
              onChange={onSearchInput}
              type="text"
              maxLength={50}
              placeholder={'Search notes, tags, and spaces'}
            />
          </div>
        </div>

        <div
          className={cx(styles.saved_search, {
            [styles.hide_element]: !savedSearch?.searched?.length,
          })}
          ref={savedSearchWrapRef}
        >
          <ul>
            {savedSearch.searched.map((searchKey) => {
              return (
                <li key={`savedSearch-${searchKey}`}>
                  <SavedSearchUnit searchKey={searchKey} />
                </li>
              );
            })}
          </ul>

          <ul
            className={cx({
              [styles.hide_element]: searchResults?.length || searchValue,
            })}
          >
            {mostRecentAccessed.map((res) => {
              return (
                <li key={`mostRecentAccessed${res?.uRef || res?.noteRefId}`}>
                  <SearchUnit searchUnit={res} />
                </li>
              );
            })}
          </ul>
        </div>

        <div
          className={cx(styles.search_results, {
            [styles.hide_element]:
              (searching && !filteredSpaces?.length) ||
              (searchValue?.length &&
                !filteredSpaces?.length &&
                !searchResults?.length),
            [styles.search_results_dark]: isThemeDarkMode,
          })}
          ref={searchResultsWrap}
        >
          <ul>
            {filteredSpaces.map((res) => {
              return (
                <li key={`searchResultSpace${res?.id}`}>
                  <SearchUnit searchUnit={res} />
                </li>
              );
            })}

            {searchResults.map((res) => {
              const key = `searchResult${
                getDataRefIdValue(res) ||
                res?.universal_ref_map ||
                res?.noteId ||
                res?.task_id ||
                res?.id
              }${`${res?.type === 'space' ? 'space' : ''}`}`;

              return (
                <li key={key}>
                  <SearchUnit searchUnit={res} />
                </li>
              );
            })}
          </ul>
        </div>
        {!searchResults?.length &&
        !filteredSpaces?.length &&
        !searching &&
        searchValue?.length ? (
          <NoResults searchKey={searchValue} />
        ) : (
          <></>
        )}

        <div
          className={cx(styles.loading, styles.flex_row_xy, {
            [styles.hide_element]: !searching,
            [styles.loading_dark]: isThemeDarkMode,
          })}
        >
          <Spinner height={20} width={20} />
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(Search))
);
