import cx from 'classnames';
import styles from './Notifications.module.scss';
import i18n from 'src/locales';
import UserNotificationEntity from './Notification';
import { Button, Spinner } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { isEmpty, isFunction, isString, size } from 'lodash';
import { useWindowScroll } from 'src/hooks';
import { useEffect, useRef } from 'react';
import { getDataRefIdValue, isMobileView } from 'src/helpers/utils';
import { Skeleton } from '@chakra-ui/react';

const Notifications = (props) => {
  const {
    userNotifications,
    userNotificationsHasNext,
    userNotificationsFetching,
    isThemeDarkMode,
    getNextListOfNotifications,
    markNotificationsAsRead,
  } = props;
  const markedAsRead = useRef(false);
  const { scrollY } = useWindowScroll();
  const loadNext = () => {
    if (!userNotificationsHasNext) {
      return;
    }

    if (isFunction(getNextListOfNotifications)) {
      getNextListOfNotifications();
    }
  };

  useEffect(() => {
    if ((isMobileView() || scrollY > 40) && !markedAsRead?.current) {
      // mark notifications as read

      markedAsRead.current = true;
      const ids = [];

      for (let i = 0; i < size(userNotifications); i++) {
        const notif = userNotifications[i];
        const notifId = notif?.id || getDataRefIdValue(notif);

        if (notif?.unread && !isEmpty(notifId) && isString(notifId)) {
          ids.push(notifId);
        }

        if (size(ids) > 10) {
          break;
        }
      }

      if (!isEmpty(ids) && isFunction(markNotificationsAsRead)) {
        markNotificationsAsRead(ids);
      }
    }
  }, [scrollY, markNotificationsAsRead, userNotifications]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div
      className={cx(styles.flex_column_x, styles.notifications_wrap, {
        [styles.notifications_wrap_dark]: isThemeDarkMode,
      })}
    >
      <div className={cx(styles.flex_column_x, styles.notifications_wrap_raw)}>
        <div
          className={cx(styles.flex_row_y, styles.notifications_header, {
            [styles.notifications_header_dark]: isThemeDarkMode,
          })}
        >
          <div className={styles.title}>
            <h4>{i18n('common_notifications')}</h4>
          </div>
        </div>

        <div
          className={cx(styles.notifications_list, {
            [styles.hide_element]: isEmpty(userNotifications || []),
            [styles.notifications_list_dark]: isThemeDarkMode,
          })}
        >
          <ul>
            {userNotifications.map((n, idx) => {
              return (
                <li key={`user-notifications-${n?.id || idx}`}>
                  <UserNotificationEntity notification={n} />
                </li>
              );
            })}
          </ul>
        </div>

        {userNotificationsFetching && isEmpty(userNotifications) && (
          <div
            className={cx(styles.flex_row_xy, styles.loading, {
              [styles.loading_dark]: isThemeDarkMode,
            })}
          >
            {' '}
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'110px'}
                marginTop={'36px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
              />
            </div>
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'220px'}
                marginTop={'36px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
              />{' '}
            </div>
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'101%'}
                borderRadius={'4px'}
                marginTop={'36px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
              />{' '}
            </div>
          </div>
        )}

        <div
          className={cx(styles.spinner_wrap, styles.flex_row_xy, {
            [styles.hide_element]:
              !userNotificationsFetching || isEmpty(userNotifications),
            [styles.spinner_wrap_dark]: isThemeDarkMode,
          })}
        >
          <div className={styles.raw}>
            <Spinner size={30} />
          </div>
        </div>

        <div
          className={cx(styles.flex_row_xy, styles.notifications_empty, {
            [styles.hide_element]:
              userNotificationsFetching || !isEmpty(userNotifications),
          })}
        >
          <div className={styles.msg}>
            <h4>{i18n('user_notifications_notifs_empty')}</h4>
          </div>
        </div>
        <div
          className={cx(styles.flex_row_xy, styles.navigation_wrap, {
            [styles.hide_element]:
              userNotificationsFetching || !userNotificationsHasNext,
          })}
        >
          <Button
            appearance="minimal"
            className={cx(styles.flex_row_xy, styles.load_button, {
              [styles.load_button_dark]: isThemeDarkMode,
            })}
            onClick={loadNext}
          >
            <h4>{i18n('user_notifications_load_more')}</h4>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(Notifications);
