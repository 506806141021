import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ChakraUIWrapper from './ChakraUIWrapper';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { isCapacitorMobileApp } from './helpers/utils';

const renderReactDom = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Router>
      <ChakraUIWrapper />
    </Router>
  );
};

if (window?.cordova || isCapacitorMobileApp()) {
  document.addEventListener(
    'deviceready',
    () => {
      renderReactDom();
    },
    false
  );
} else {
  renderReactDom();
  registerServiceWorkerForOfflineMode();
}

function registerServiceWorkerForOfflineMode() {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register();
}
