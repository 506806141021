import cx from 'classnames';
import styles from './LogoIconWord.module.scss';
import {
  CHAMU_ICON_WHITE,
  CHAMU_ICON_WORD_WHITE,
  CHAMU_ICON_BLACK,
  CHAMU_ICON_WORD_BLACK,
} from 'src/constants/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { useMemo } from 'react';

const LogoIconWord = (props) => {
  const { isThemeDarkMode: settingsIsThemeDarkMode, useLightMode } = props;
  const isThemeDarkMode = useMemo(
    () => settingsIsThemeDarkMode && !useLightMode,
    [useLightMode, settingsIsThemeDarkMode]
  );
  const size = props?.size;
  const isSmall = size === 'small';
  const isMedium = size === 'medium';

  return (
    <div className={cx(styles.logo_icon_word, styles.flex_row_xy)}>
      <div
        className={cx(styles.logo, styles.flex_row_xy, {
          [styles.logo_medium]: isMedium,
          [styles.logo_small]: isSmall,
        })}
      >
        <img
          src={isThemeDarkMode ? CHAMU_ICON_WHITE : CHAMU_ICON_BLACK}
          alt={'Logo icon'}
        />
      </div>
      <div
        className={cx(styles.word, styles.flex_row_xy, {
          [styles.word_medium]: isMedium,
          [styles.word_small]: isSmall,
        })}
      >
        <img
          className={cx({ [styles.img_word_dark]: isThemeDarkMode })}
          src={isThemeDarkMode ? CHAMU_ICON_WORD_WHITE : CHAMU_ICON_WORD_BLACK}
          alt={'Logo icon'}
        />
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(LogoIconWord);
