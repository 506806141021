import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Terms.module.scss';
import { paragraphUnpacker } from '../helpers';

class Content {
  static Registration = 2;
  static DelinquentAccounts = 8;
  static Modifications = 9;
  static GoverningLaw = 10;
  static Last = -2;
}

const contentToHtml = (from, to, key = '') => {
  let contentKeys = [];
  if (to === Content.Last) {
    contentKeys = Object.keys(contents.content).slice(from);
  } else {
    contentKeys = Object.keys(contents.content).slice(from, to);
  }

  const content = [];

  for (const contentKey of contentKeys) {
    const contentKeyFormat = `contentToHtmlTitle${key}${contentKey}`;
    content.push(
      <h4 key={contentKeyFormat} className={innerStyles.title}>
        {contents.content[contentKey][0]}
      </h4>
    );
    content.push(<br key={`${contentKeyFormat}br1`} />);
    const restOfContents = contents.content[contentKey].slice(1);

    for (let i = 0; i < restOfContents.length; i++) {
      const text = restOfContents[i];
      const baseKey = `contentToHtmlTitle${key}${contentKey}${i}`;

      if (!text) {
        content.push(<br />);
      } else {
        if (restOfContents?.length > 1) {
          content.push(<br key={`${baseKey}br2`} />);
        }

        content.push(<p key={baseKey}>{text}</p>);
      }
    }

    if (to === Content.Last) {
      break;
    }

    content.push(<br key={`${contentKeyFormat}br2`} />);
    content.push(<br key={`${contentKeyFormat}br3`} />);
  }

  return content;
};

const Terms = (props) => {
  const header = paragraphUnpacker(contents.content.header, 'header');
  const disclaimerTitle = contents.content.disclaimer[0];
  const disclaimerList0 = contents.content.disclaimer[1];
  const disclaimerList1 = contents.content.disclaimer[2];
  const indemnityList0 = contents.content.indemnity2[0];
  const indemnityList1 = contents.content.indemnity2[1];
  const indemnityList2 = contents.content.indemnity2[2];
  const indemnityList3 = contents.content.indemnity2[3];
  const indemnityParag2 = contents.content.indemnity2[4];

  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <p className={innerStyles.last_updated}>
          {contents.content.last_updated}
        </p>
        <br />
        {header}
        <br />
        <br />
        {contentToHtml(2, 15, 'first')}
        <h4 className={innerStyles.title}>{disclaimerTitle}</h4>
        <br />
        <ul className={innerStyles.unordered_list}>
          <li>{disclaimerList0}</li>
          <br />
          <li>{disclaimerList1}</li>
        </ul>
        <br />
        <br />
        {contentToHtml(16, 17, 'second')}
        <ul className={innerStyles.unordered_list}>
          <li>{indemnityList0}</li>
          <br />
          <li>{indemnityList1}</li>
          <br />
          <li>{indemnityList2}</li>
          <br />
          <li>{indemnityList3}</li>
        </ul>
        <br />
        <p> {indemnityParag2}</p>
        <br />
        <br />
        {contentToHtml(18, 25, 'third')}
      </div>
    </div>
  );
};

export default Terms;
