export function getUsersApiPathname() {
  return `/v1/user`;
}

export function getSpacesApiPathname() {
  return `/v1/spaces`;
}

export function getAdminApiPathname() {
  return '/v1/admin';
}

export function getPublicApiPathname() {
  return '/v1/public';
}

export function getPaymentApiPathname() {
  return '/v1/payment';
}

export function getFileApiPathname() {
  return '/v1/files';
}

export function getTaskApiPathname() {
  return '/v1/task';
}
