const DarkThemeOptionIcon = (p) => {
  return (
    <svg
      width='127'
      height='82'
      viewBox='0 0 127 82'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...p}
    >
      <g clipPath='url(#clip0_1247_22605)'>
        <rect x='1' y='1' width='125' height='80' fill='#13171C' />
        <rect x='5' y='21' width='4' height='4' rx='2' fill='#7088DD' />
        <rect x='11' y='21' width='19' height='4' rx='2' fill='#394350' />
        <rect x='5' y='13' width='25' height='4' rx='2' fill='#394350' />
        <rect x='5' y='5' width='4' height='4' rx='2' fill='white' />
        <rect
          x='26'
          y='5'
          width='4'
          height='4'
          rx='2'
          fill='#C3D0E5'
          fillOpacity='0.8'
        />
        <rect
          x='20'
          y='5'
          width='4'
          height='4'
          rx='2'
          fill='#C3D0E5'
          fillOpacity='0.8'
        />
        <g clipPath='url(#clip1_1247_22605)'>
          <path
            d='M6.29766 31.3333L6.36766 30.6667H5.66699V30.3333H6.40283L6.49033 29.5H6.82549L6.73799 30.3333H7.40283L7.49033 29.5H7.82549L7.73799 30.3333H8.33366V30.6667H7.70299L7.63299 31.3333H8.33366V31.6667H7.59783L7.51033 32.5H7.17516L7.26266 31.6667H6.59783L6.51033 32.5H6.17516L6.26266 31.6667H5.66699V31.3333H6.29766ZM6.63283 31.3333H7.29783L7.36783 30.6667H6.70283L6.63283 31.3333Z'
            fill='white'
          />
        </g>
        <rect x='11' y='29' width='19' height='4' rx='2' fill='#394350' />
        <g clipPath='url(#clip2_1247_22605)'>
          <path
            d='M6.29766 39.3333L6.36766 38.6667H5.66699V38.3333H6.40283L6.49033 37.5H6.82549L6.73799 38.3333H7.40283L7.49033 37.5H7.82549L7.73799 38.3333H8.33366V38.6667H7.70299L7.63299 39.3333H8.33366V39.6667H7.59783L7.51033 40.5H7.17516L7.26266 39.6667H6.59783L6.51033 40.5H6.17516L6.26266 39.6667H5.66699V39.3333H6.29766ZM6.63283 39.3333H7.29783L7.36783 38.6667H6.70283L6.63283 39.3333Z'
            fill='white'
          />
        </g>
        <rect x='11' y='37' width='19' height='4' rx='2' fill='#394350' />
        <g clipPath='url(#clip3_1247_22605)'>
          <path
            d='M6.29766 47.3333L6.36766 46.6667H5.66699V46.3333H6.40283L6.49033 45.5H6.82549L6.73799 46.3333H7.40283L7.49033 45.5H7.82549L7.73799 46.3333H8.33366V46.6667H7.70299L7.63299 47.3333H8.33366V47.6667H7.59783L7.51033 48.5H7.17516L7.26266 47.6667H6.59783L6.51033 48.5H6.17516L6.26266 47.6667H5.66699V47.3333H6.29766ZM6.63283 47.3333H7.29783L7.36783 46.6667H6.70283L6.63283 47.3333Z'
            fill='white'
          />
        </g>
        <rect x='11' y='45' width='19' height='4' rx='2' fill='#394350' />
        <path
          d='M33 1V81H35V1H33Z'
          fill='#28303B'
          mask='url(#path-3-inside-1_1247_22605)'
        />
        <rect x='34' y='1' width='92' height='24' fill='#222A34' />
        <rect
          x='37.5'
          y='18.5'
          width='15'
          height='15'
          rx='1.5'
          fill='#7088DD'
        />
        <rect
          x='37.5'
          y='18.5'
          width='15'
          height='15'
          rx='1.5'
          stroke='#13171C'
        />
        <rect x='38' y='37' width='25' height='4' rx='2' fill='#2D3748' />
        <rect x='38' y='44' width='84' height='4' rx='2' fill='#456EFF' />
        <rect x='38' y='53' width='84' height='4' rx='2' fill='#394350' />
        <rect x='38' y='61' width='84' height='4' rx='2' fill='#394350' />
        <rect x='38' y='69' width='84' height='4' rx='2' fill='#394350' />
      </g>
      <rect x='0.5' y='0.5' width='126' height='81' stroke='#F4F7FB' />
      <defs>
        <clipPath id='clip0_1247_22605'>
          <rect x='1' y='1' width='125' height='80' fill='white' />
        </clipPath>
        <clipPath id='clip1_1247_22605'>
          <rect width='4' height='4' fill='white' transform='translate(5 29)' />
        </clipPath>
        <clipPath id='clip2_1247_22605'>
          <rect width='4' height='4' fill='white' transform='translate(5 37)' />
        </clipPath>
        <clipPath id='clip3_1247_22605'>
          <rect width='4' height='4' fill='white' transform='translate(5 45)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DarkThemeOptionIcon;
