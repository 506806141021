import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Intro.module.scss';

const Intro = (props) => {
  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <br />
        <h2>{contents.content[0]}</h2>
        <br />
        <p>
          Think of a <strong>space</strong> as a binder notebook. Each space has
          their own set of pages called <strong>notes</strong>, and these notes
          are organized
        </p>
        <p>
          using dividers called <strong>tags</strong>.
        </p>
        <br />
        <p>{contents.content[3]}</p>
        <br />
        <p>{contents.content[4]}</p>
        <br />
        <br />
        <br />
        <img alt={'Intro illustration'} src={'/docs-images/intro-img.png'} />
      </div>
    </div>
  );
};

export default Intro;
