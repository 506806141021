import { isNetworkError } from '.';
import { getPaymentApiPathname, getUsersApiPathname } from './urls';
import { axiosGetRequest, axiosPostRequest } from './axios';
import { isEmpty, last, size, trim } from 'lodash';

export async function verifyUserLifetimeCode(code = '', headers = {}) {
  let success = false;
  let networkError = false;
  let errorMessage = '';
  let updatedUser = null;

  try {
    const url = getUsersApiPathname() + `/lifetime-license?code=${trim(code)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (!isEmpty(resData?.user)) {
      updatedUser = resData?.user;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { updatedUser, networkError, errorMessage, success };
}

export async function createCheckoutSessionRequest(product = '', headers = {}) {
  let success = false;
  let networkError = false;
  let errorMessage = '';
  let checkoutUrl = '';

  try {
    let origin = window.location.origin;
    if (last(origin) === '/') {
      origin = origin.substring(0, size(origin) - 1);
    }
    const url = getPaymentApiPathname() + '/create-session';
    const res = await axiosPostRequest(url, headers, {
      origin,
      product: trim(product),
    });
    const resData = res?.data;

    if (resData?.success && resData?.url) {
      // success: true,
      // id: createSessionRes?.id,
      // sessionId: createSessionRes?.sessionId,
      // refId: createSessionRes?.refId,
      // url: createSessionRes?.url,
      // successUrl: createSessionRes?.successUrl
      success = true;
      checkoutUrl = resData?.url;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, networkError, checkoutUrl, errorMessage };
}

export async function getUserBillingHistoryByPageRequest(
  page = 1,
  headers = {}
) {
  let invoices = [];
  let networkError = false;
  let hasNext = false;
  let errorMessage = '';

  try {
    // /billing-history/

    const url = getUsersApiPathname() + `/billing-history/${page}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.value) {
      hasNext = resData?.hasNext;
      invoices = resData?.value;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { hasNext, errorMessage, networkError, invoices };
}
