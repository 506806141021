import cx from 'classnames';
import styles from './NoResults.module.scss';
import { PensiveFaceIcon } from 'src/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const NoResults = (props) => {
  const { isThemeDarkMode } = props;

  return (
    <div
      className={cx(styles.flex_column_xy, styles.no_results, {
        [styles.no_results_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(styles.pensive, {
          [styles.pensive_dark]: isThemeDarkMode,
        })}
      >
        <PensiveFaceIcon />
      </div>
      <h4>{'No results found'}</h4>
      <p>{`A note, file, or space with the keyword “${props?.searchKey}” does not exist.`}</p>
    </div>
  );
};

export default withUserDataAndProfileSettings(NoResults);
