import cx from 'classnames';
import styles from './Subscribers.module.scss';
import { withNoteViewContext } from '../Context';
import {
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
} from 'src/helpers/utils';
import { isFunction, isArray, isEmpty } from 'lodash';
import { Button, CrossIcon } from 'evergreen-ui';
import { useEffect, useMemo } from 'react';
import { Avatar } from '@chakra-ui/react';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const Subscribers = (props) => {
  const {
    subscribers = [],
    isEditMode,
    isCreateMode,
    isThemeDarkMode,
    closeModal,
    removeSubscriber,
  } = props;

  const canModifyEditor = useMemo(
    () => isEditMode || isCreateMode,
    [isCreateMode, isEditMode]
  );

  const preview = useMemo(() => {
    const val = [];
    if (isArray(subscribers)) {
      for (let i = 0; i < subscribers.length; i++) {
        const subscriberInfo = subscribers[i];
        if (!isEmpty(subscriberInfo)) {
          val.push(subscribers[i]);
        }
      }
    }

    return val;
  }, [subscribers]);

  const onClose = () => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  };

  /**
   * Close on outside click
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (
        evt?.target?.classList?.contains(styles.subscribers_modal) &&
        isFunction(closeModal)
      ) {
        closeModal();
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [closeModal]);

  const onRemoveSubscriber = (userId = '') => {
    if (isFunction(removeSubscriber)) {
      removeSubscriber(userId);
    }
  };

  return (
    <div className={cx(styles.flex_row_xy, styles.subscribers_modal)}>
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        {!isEmpty(preview) && (
          <ul className={styles.flex_column_xy}>
            {preview.map((userInfo) => {
              const { image, email, nonUser } = userInfo;
              const userId =
                getUserIdFromObject(userInfo) ||
                getUserProfileIdFromObject(userInfo);
              const subscriberDisplayName = nonUser
                ? email
                : getUserDisplayName(userInfo);
              const key = nonUser
                ? `subscriberModalPreviewUserIdNonUser${email}`
                : `subscriberModalPreviewUserId${userId}`;

              return (
                <li className={styles.flex_row_xy} key={key}>
                  <div
                    className={cx(styles.flex_row_xy, styles.avatar, {
                      [styles.avatar_with_actions]: canModifyEditor,
                    })}
                  >
                    <Avatar
                      height={'30px'}
                      width={'30px'}
                      src={nonUser ? '' : image}
                      name={subscriberDisplayName}
                      background={'#000'}
                      backgroundColor={'#000'}
                    />
                    <p>{`${subscriberDisplayName}${
                      nonUser ? ' (external)' : ''
                    }`}</p>
                  </div>
                  {canModifyEditor && (
                    <div className={cx(styles.flex_row_xy, styles.actions)}>
                      <Button
                        appearance="minimal"
                        className={cx(styles.remove, styles.flex_row_xy, {
                          [styles.remove_dark]: isThemeDarkMode,
                        })}
                        onClick={() => onRemoveSubscriber(userId)}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        <div
          className={cx(styles.flex_row_xy, styles.close, {
            [styles.close_dark]: isThemeDarkMode,
          })}
        >
          <Button
            onClick={onClose}
            appearance="minimal"
            className={styles.flex_row_xy}
          >
            <CrossIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Subscribers));
