import { renderToStaticMarkup } from 'react-dom/server';
import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core';
import { isValidObject, toString, timeout } from 'src/helpers/utils';
import { Spinner } from 'evergreen-ui';
import {
  loadScript,
  isValidTwitterStatusUrl,
  TWITTER_STATUS_REGEX_GLOBAL,
  appendHttpsToUrl,
} from 'src/helpers/urls';

export async function setupTwitterWidget() {
  if (!window.twitter) {
    window.twitter = async () => {
      if (!window?.twttr) {
        await loadScript('//platform.twitter.com/widgets.js');
        await timeout(300);
        window.twttr.widgets.load();
      } else {
        try {
          window.twttr.widgets.load();
        } catch {}
      }
    };
  }
}

const TwitterExtension = Node.create({
  name: 'twitter',

  addOptions() {
    return {
      nested: false,
      draggable: false,
      addPasteHandler: true,
      width: '100%',
      HTMLAttributes: {},
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-type': 'twitter' }, HTMLAttributes),
      ['iframe'],
    ];
  },

  addCommands() {
    return {
      setTwitterStatus:
        (options) =>
        ({ commands }) => {
          const src = options?.src || options?.url;

          if (!isValidTwitterStatusUrl(src)) {
            return false;
          }

          if (commands) {
            return commands.insertContent({
              type: this.name,
              attrs: { url: src, type: 'twitter' },
            });
          }

          return false;
        },
    };
  },

  addAttributes() {
    return {
      type: {
        default: '',
        parseHTML: () => 'twitter',
        renderHTML: () => ({
          'data-type': 'twitter',
        }),
      },
      url: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-url') || '',
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="twitter"]`,
      },
    ];
  },

  draggable: false,

  group: 'block',

  inline: false,

  atom: true,

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: TWITTER_STATUS_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          const url = toString(match?.input);
          let urlSanitized = url.substring(
            0,
            url.includes('?') ? url.indexOf('?') : url.length
          );
          urlSanitized = appendHttpsToUrl(urlSanitized);

          return { url: urlSanitized, type: 'twitter' };
        },
      }),
    ];
  },

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const divTwitter = document.createElement('div');
      const divLoading = document.createElement('div');
      const url = HTMLAttributes['data-url'];
      const divWidget = document.createElement('div');

      setupTwitterWidget();
      divLoading.innerHTML = renderToStaticMarkup(
        <Spinner height={20} width={20} />
      );
      divLoading.setAttribute('data-twitterloading', 'twitterloading');
      divWidget.innerHTML = `<blockquote class="twitter-tweet"><div><a href="${url}">${
        url || 'Twitter'
      }</a></div></blockquote>`;
      divTwitter.append(divLoading);
      divTwitter.append(divWidget);

      if (isValidObject(HTMLAttributes)) {
        Object.entries(HTMLAttributes).forEach(([key, value]) => {
          divTwitter.setAttribute(key, value);
        });
      }

      if (isValidObject(this.options.HTMLAttributes)) {
        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          divTwitter.setAttribute(key, value);
        });
      }

      timeout(500).then(() => {
        window.twitter();
        divLoading.remove();
      });

      return {
        dom: divTwitter,
      };
    };
  },
});

export default TwitterExtension;
