import styles from './ImageViewer.module.scss';
import cx from 'classnames';
import Viewer from 'viewerjs';
import { withNoteViewContext } from '../Context';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useWindowResize } from 'src/hooks';
import {
  applyUIOverflow,
  isFunction,
  isString,
  isValidObject,
  noUIOverflow,
} from 'src/helpers/utils';

const ImageViewer = (props) => {
  const { closeImageViewer, EditorInstance, showImageViewerSrc } = props;
  const [imagesProcessed, setImagesProcessed] = useState(false);
  const [images, setImages] = useState([]);
  const [initialViewIndex, setInitialViewIndex] = useState(0);
  const wrapperRef = useRef(null);
  const { availableHeight } = useWindowResize();

  useEffect(() => {
    window.scrollTo(0, 0);
    noUIOverflow();
  }, []);

  useEffect(() => applyUIOverflow(), []);

  /**
   * Blur editor
   */
  useEffect(() => {
    if (EditorInstance?.commands) {
      EditorInstance.commands.blur();
    }
  }, [EditorInstance]);

  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef.current.style.height = `${availableHeight}px`;
    }
  }, [availableHeight]);

  const getImageType = useCallback(
    (content = []) => {
      const images = [];

      for (let i = 0; i < content.length; i++) {
        const node = content[i];

        if (
          node?.attrs?.src &&
          isString(node?.attrs?.src) &&
          node?.type === 'image'
        ) {
          images.push(node.attrs.src);

          if (node?.attrs?.src === showImageViewerSrc) {
            setInitialViewIndex(images?.length - 1);
          }
        }

        if (node && isValidObject(node) && node?.content?.length) {
          const childImages = getImageType([...node?.content]);

          if (childImages?.length) {
            for (let y = 0; y < childImages.length; y++) {
              const link = childImages[y];

              if (link && isString(link)) {
                images.push(link);

                if (link === showImageViewerSrc) {
                  setInitialViewIndex(images?.length - 1);
                }
              }
            }
          }
        }
      }

      return images;
    },
    // eslint-disable-next-line
    [
      EditorInstance,
      imagesProcessed,
      showImageViewerSrc,
      initialViewIndex,
      setInitialViewIndex,
    ]
  );

  /**
   * Store images
   */
  useEffect(() => {
    if (EditorInstance && !imagesProcessed) {
      try {
        const content = EditorInstance.getJSON()?.content;
        const targetImages = getImageType(content);
        setImages(targetImages || []);
        setImagesProcessed(true);
      } catch {}
    }
  }, [EditorInstance, imagesProcessed, getImageType]);

  return (
    <div
      ref={wrapperRef}
      className={cx(styles.image_viewer, styles.flex_row_xy)}
    >
      <div className={styles.content}>
        <div className={styles.images}>
          <ul id="ImageViewerImages">
            {images.map((imageUrl, index) => {
              return (
                <li key={`ImageViewerImages${index + 1}`}>
                  <img src={imageUrl} alt={`ImageViewerImages${index + 1}`} />
                </li>
              );
            })}
          </ul>
        </div>
        {images?.length && imagesProcessed && (
          <ViewerHelperComponent
            initialViewIndex={initialViewIndex}
            close={closeImageViewer}
          />
        )}
      </div>
    </div>
  );
};

const ViewerHelperComponent = (props) => {
  const iniRef = useRef(false);
  const [gallery, setGallery] = useState(null);
  const { close, initialViewIndex = 0 } = props;

  useEffect(() => {
    if (!gallery && !iniRef?.current) {
      iniRef.current = true;
      const galleryInstance = new Viewer(
        document.getElementById('ImageViewerImages'),
        {
          initialViewIndex,
          hidden() {
            if (isFunction(close)) {
              close();
            }
          },
        }
      );

      setGallery(galleryInstance);
    }

    // eslint-disable-next-line
  }, [gallery, close]);

  useEffect(() => {
    if (gallery?.show) {
      gallery.show();
    }
  }, [gallery]);

  return <Fragment />;
};

export default withNoteViewContext(ImageViewer);
