import cx from 'classnames';
import styles from './TagsEmpty.module.scss';
import i18n from 'src/locales';
import { SortIcon } from 'evergreen-ui';
import { isEmpty, isString } from 'lodash';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const TagsEmpty = (props) => {
  const { isThemeDarkMode } = props;

  return (
    <div
      className={cx(styles.tags_list_empty, {
        [styles.tags_list_empty_dark]: isThemeDarkMode,
        [props.className]:
          !isEmpty(props.className) && isString(props.className),
      })}
    >
      <div className={styles.flex_row_xy}>
        <SortIcon />
      </div>
      <div className={styles.flex_row_xy}>
        <h4>{i18n('user_dashboard_panel_tags_empty_title')}</h4>
        <h5>{i18n('user_dashboard_panel_tags_empty_message')}</h5>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(TagsEmpty);
