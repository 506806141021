import styles from './FilesView.module.scss';
import cx from 'classnames';

const FilesView = () => {
  return (
    <div className={styles.files_view}>
      <div className={cx(styles.flex_column_xy, styles.content)}></div>
    </div>
  );
};

export default FilesView;
