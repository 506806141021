const QouteIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M3.81917 14.4345C2.96083 13.5229 2.5 12.5004 2.5 10.8429C2.5 7.9262 4.5475 5.31203 7.525 4.01953L8.26917 5.16786C5.49 6.6712 4.94667 8.62203 4.73 9.85203C5.1775 9.62036 5.76333 9.53953 6.3375 9.59286C7.84083 9.73203 9.02583 10.9662 9.02583 12.5004C9.02583 13.2739 8.71854 14.0158 8.17156 14.5628C7.62458 15.1097 6.88271 15.417 6.10917 15.417C5.215 15.417 4.36 15.0087 3.81917 14.4345ZM12.1525 14.4345C11.2942 13.5229 10.8333 12.5004 10.8333 10.8429C10.8333 7.9262 12.8808 5.31203 15.8583 4.01953L16.6025 5.16786C13.8233 6.6712 13.28 8.62203 13.0633 9.85203C13.5108 9.62036 14.0967 9.53953 14.6708 9.59286C16.1742 9.73203 17.3592 10.9662 17.3592 12.5004C17.3592 13.2739 17.0519 14.0158 16.5049 14.5628C15.9579 15.1097 15.216 15.417 14.4425 15.417C13.5483 15.417 12.6933 15.0087 12.1525 14.4345Z"
        fill="#718096"
      />
    </svg>
  );
};

export default QouteIcon;
