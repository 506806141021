import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './About.module.scss';
import cx from 'classnames';

const About = (props) => {
  const quote = [];
  const paragraphs = [];
  const lastParagraph = [];
  const paragraphsContents = contents.content['main'].slice(3, 5);
  const lastParagraphContents = contents.content['main'].slice(5);
  const qouteContents = contents.content['main'].slice(0, 3);

  for (let i = 0; i < paragraphsContents.length; i++) {
    const content = paragraphsContents[i];

    paragraphs.push(<p key={`paragraphs${i}`}>{content}</p>);
  }

  for (let i = 0; i < lastParagraphContents.length; i++) {
    const content = lastParagraphContents[i];

    lastParagraph.push(<p key={`lastParagraph${i}`}>{content}</p>);
  }

  for (let i = 0; i < qouteContents.length; i++) {
    const content = qouteContents[i];

    quote.push(<p key={`qouteContents${i}`}>{content}</p>);
  }

  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <br />
        <br />
        <div className={innerStyles.quote_wrapper}>{quote}</div>
        <br />
        <br />
        {paragraphs}
        <br />
        {lastParagraph}
        <div className={cx(styles.flex_row_xy, innerStyles.footer_container)}>
          <div
            className={cx(styles.flex_column_xy, innerStyles.footer_wrapper)}
          >
            <img
              className={innerStyles.footer_wrapper_first}
              src={'/docs-images/lightbulb.png'}
              alt={'Ideate icon'}
            />
            <h4>{contents.content['footer'].ideate[0]}</h4>
            <p>{contents.content['footer'].ideate[1]}</p>
            <p>{contents.content['footer'].ideate[2]}</p>
          </div>
          <div
            className={cx(styles.flex_column_xy, innerStyles.footer_wrapper)}
          >
            <img
              className={innerStyles.footer_wrapper_first}
              src={'/docs-images/padlock.png'}
              alt={'Secure icon'}
            />
            <h4>{contents.content['footer'].secure[0]}</h4>
            <p>{contents.content['footer'].secure[1]}</p>
            <p>{contents.content['footer'].secure[2]}</p>
          </div>
          <div
            className={cx(styles.flex_column_xy, innerStyles.footer_wrapper)}
          >
            <img
              className={innerStyles.footer_wrapper_second}
              src={'/docs-images/chart.png'}
              alt={'Accessible icon'}
            />
            <h4>{contents.content['footer'].accessible[0]}</h4>
            <p>{contents.content['footer'].accessible[1]}</p>
            <p>{contents.content['footer'].accessible[2]}</p>
          </div>
          <div
            className={cx(styles.flex_column_xy, innerStyles.footer_wrapper)}
          >
            <img
              className={innerStyles.footer_wrapper_second}
              src={'/docs-images/apps.png'}
              alt={'Organized icon'}
            />
            <h4>{contents.content['footer'].organized[0]}</h4>
            <p>{contents.content['footer'].organized[1]}</p>
            <p>{contents.content['footer'].organized[2]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
