import cx from 'classnames';
import styles from './Subscribers.module.scss';
import AddSubscribers from './AddSubscribers';
import SubscribersPreview from './SubscribersPreview';
import { withNoteViewContext } from './Context';
import { useMemo } from 'react';
import { SubscriberSettingsIcon } from 'src/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const Subscribers = (props) => {
  const { isEditMode, isCreateMode, isThemeDarkMode } = props;

  const canModify = useMemo(
    () => isEditMode || isCreateMode,
    [isCreateMode, isEditMode]
  );

  return (
    <div
      className={cx(styles.flex_column_xy, styles.subscribers, {
        [styles.subscribers_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(styles.flex_row_xy, styles.title, {
          [styles.title_dark]: isThemeDarkMode,
        })}
      >
        <SubscriberSettingsIcon />
        <p>Add Subscribers</p>
      </div>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        <div className={cx(styles.flex_row_xy, styles.input)}>
          {canModify && <AddSubscribers />}
        </div>
        <div className={cx(styles.flex_row_xy, styles.preview)}>
          <SubscribersPreview canModify={canModify} />
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Subscribers));
