import cx from 'classnames';
import styles from './PasswordProtect.module.scss';
import CustomButton from 'src/components/button';
import k from 'src/constants/k';
import { withNoteViewContext } from './Context';
import { EyeIcon, EyeCloseIcon, WarningIcon, ArrowUpIcon } from 'src/icons';
import { IconButton, TextInput, CrossIcon } from 'evergreen-ui';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWindowResize } from 'src/hooks';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withNoteDataSettings } from 'src/managers/notes';
import { isEmpty, isFunction, toLower, toString } from 'lodash';
import { withNetworkSettings } from 'src/managers/network';

const PasswordProtect = (props) => {
  const {
    taskId,
    onNoteContents,
    getLatestNoteEnrichedData,
    uRef,
    isThemeDarkMode,
    markUserIsOffline,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [tries, setTries] = useState(k.USER_TASK_PASSWORD_MAX_TRIES);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const domWrap = useRef(null);
  const { availableHeight } = useWindowResize();

  useEffect(() => {
    if (domWrap?.current && availableHeight) {
      domWrap.current.style.height = `${availableHeight - 64}px`;
    }
  }, [availableHeight]);

  const onPasswordInput = (evt) => {
    if (submitting) {
      return;
    }

    setPasswordInput(toString(evt?.target?.value));
  };

  const submit = async () => {
    try {
      if (submitting) {
        return;
      }

      setSubmitting(true);
      setTries(tries - 1);

      const latestNoteDataRes = await getLatestNoteEnrichedData(
        toLower(taskId),
        uRef,
        passwordInput
      );

      // notFound, errorMessage, passcodeRequired

      if (
        latestNoteDataRes?.passcodeRequired ||
        latestNoteDataRes?.networkError ||
        latestNoteDataRes?.notFound ||
        isEmpty(latestNoteDataRes) ||
        !latestNoteDataRes
      ) {
        if (latestNoteDataRes?.networkError && isFunction(markUserIsOffline)) {
          // mark user is offline
          markUserIsOffline();
        }

        setShowError(true);
        setSubmitting(false);
      } else if (!isEmpty(latestNoteDataRes)) {
        if (isFunction(onNoteContents)) {
          onNoteContents(latestNoteDataRes);
        }
      }
    } catch (err) {
      setShowError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      ref={domWrap}
      className={cx(styles.flex_row_xy, styles.password_protect, {
        [styles.password_protect_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        <h1> {'This note is password protected.'}</h1>
        <p>
          {'Please ask the author or the sender of this note for the password.'}
        </p>
        {showError ? (
          <div className={cx(styles.error, styles.flex_row_xy)}>
            <div className={cx(styles.flex_row_xy, styles.icon)}>
              <WarningIcon height={24} width={24} />
            </div>
            <div className={styles.texts}>
              <h3>{'Wrong password'}</h3>
              <p>{`You have ${tries}/10 tries remaining.`}</p>
            </div>
            <div
              className={cx(styles.flex_row_xy, styles.error_close, {
                [styles.error_close_dark]: isThemeDarkMode,
              })}
              onClick={() => setShowError(false)}
            >
              <IconButton icon={CrossIcon} appearance="minimal" />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(styles.flex_row_xy, styles.input, {
            [styles.hide_element]: tries < 1,
            [styles.input_dark]: isThemeDarkMode,
          })}
        >
          <TextInput
            placeholder="Password here"
            type={showPassword ? 'text' : 'password'}
            onChange={onPasswordInput}
            value={passwordInput}
          />
          {!showPassword && (
            <IconButton
              className={cx(styles.flex_row_xy, styles.toggle_show, {
                [styles.toggle_show_dark]: isThemeDarkMode,
              })}
              appearance="minimal"
              icon={EyeIcon}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
          {showPassword && (
            <IconButton
              className={cx(styles.flex_row_xy, styles.toggle_show, {
                [styles.toggle_show_dark]: isThemeDarkMode,
              })}
              appearance="minimal"
              icon={EyeCloseIcon}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
        <div
          className={cx(styles.confirm, {
            [styles.hide_element]: tries < 1,
          })}
        >
          <CustomButton
            isThemeDarkMode={isThemeDarkMode}
            label={'View Note'}
            loading={submitting}
            variant={'primaryPurple'}
            className={styles.view_note}
            onClick={submit}
          />
        </div>
        <div
          className={cx(styles.back_to_home, {
            [styles.hide_element]: submitting,
          })}
        >
          <Link to={'/'}>
            {'Back to Home'}
            <ArrowUpIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withNetworkSettings(
  withUserDataAndProfileSettings(
    withNoteDataSettings(withNoteViewContext(PasswordProtect))
  )
);
