const CalendarIcon = (p) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_33753)">
        <path
          d="M6.75 0.75V2.25H11.25V0.75H12.75V2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H5.25V0.75H6.75ZM15 7.5H3V14.25H15V7.5ZM11.277 8.352L12.3375 9.4125L8.625 13.125L5.973 10.473L7.035 9.4125L8.62575 11.004L11.2777 8.352H11.277ZM5.25 3.75H3V6H15V3.75H12.75V4.5H11.25V3.75H6.75V4.5H5.25V3.75Z"
          fill="#2D3748"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_33753">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
