const SmileIcon = (p) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M8.99999 16.8334C4.39749 16.8334 0.666656 13.1025 0.666656 8.50002C0.666656 3.89752 4.39749 0.166687 8.99999 0.166687C13.6025 0.166687 17.3333 3.89752 17.3333 8.50002C17.3333 13.1025 13.6025 16.8334 8.99999 16.8334ZM8.99999 15.1667C10.7681 15.1667 12.4638 14.4643 13.714 13.2141C14.9643 11.9638 15.6667 10.2681 15.6667 8.50002C15.6667 6.73191 14.9643 5.03622 13.714 3.78597C12.4638 2.53573 10.7681 1.83335 8.99999 1.83335C7.23188 1.83335 5.53619 2.53573 4.28594 3.78597C3.0357 5.03622 2.33332 6.73191 2.33332 8.50002C2.33332 10.2681 3.0357 11.9638 4.28594 13.2141C5.53619 14.4643 7.23188 15.1667 8.99999 15.1667ZM4.83332 8.50002H6.49999C6.49999 9.16306 6.76338 9.79895 7.23222 10.2678C7.70106 10.7366 8.33695 11 8.99999 11C9.66303 11 10.2989 10.7366 10.7678 10.2678C11.2366 9.79895 11.5 9.16306 11.5 8.50002H13.1667C13.1667 9.60509 12.7277 10.6649 11.9463 11.4463C11.1649 12.2277 10.1051 12.6667 8.99999 12.6667C7.89492 12.6667 6.83511 12.2277 6.05371 11.4463C5.27231 10.6649 4.83332 9.60509 4.83332 8.50002Z"
        fill="#718096"
      />
    </svg>
  );
};

export default SmileIcon;
