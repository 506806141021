import cx from 'classnames';
import styles from './Header.module.scss';
import LogoIcon from 'src/components/logo-icon';
import ViewAndEditSwitch from './ViewAndEditSwitch';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import HeaderUserOptions from './HeaderUserOptions';
import MessageHover from 'src/components/message-hover';
import k from 'src/constants/k';
import ThemeSwitch from './ThemeSwitch';
import {
  isMobileView,
  isIosMobileApp,
  timeout,
  copyTextToClipboard,
  getUserDisplayName,
} from 'src/helpers/utils';
import { isFunction, isEmpty, toUpper, head, filter } from 'lodash';
import { withNoteViewContext } from './Context';
import { NoteViewModalType } from 'src/types';
import {
  ActivityIcon,
  LinkIcon,
  NoteViewPropertiesIcon,
  RedoIcon,
  UndoIcon,
  MoreDotIcon,
  ChatIcon,
  ShareBoxIcon,
  CheckIcon,
  ArrowUpIcon,
} from 'src/icons';
import { Button, CaretDownIcon } from 'evergreen-ui';
import { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { Avatar } from '@chakra-ui/react';
import { withModalPromptSettings } from 'src/managers/modal';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';

const Header = (props) => {
  const HeaderUserMobileMoreOptionsClassname =
    'HeaderUserMobileMoreOptionsClassname';
  const {
    isLoggedIn,
    profileLoaded,
    user,
    isEditMode,
    isCreateMode,
    spaceId,
    spaceInfo,
    EditorInstance,
    loading,
    isForGettingStartedNote,
    taskId,
    uRef,
    allowedToToggleModify,
    uploadingFiles,
    creating,
    createNewNote,
    title,
    showModalType,
    requirePasscode,
    markCompleteOrActive,
    updatingState,
    saving,
    taskState,
    deletingNote,
    commentsView,
    isThemeDarkMode,
    userSpaces,
    showSignupPrompt,
    showLoginPrompt,
    selectSpace,
  } = props;
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showMobileMore, setShowMobileMore] = useState(false);
  const fullName = useMemo(() => getUserDisplayName(user), [user]);
  const userImage = useMemo(() => user?.image, [user]);
  const canModifyEditor = isEditMode || isCreateMode;

  const space = useMemo(() => {
    const targetSpace = filter(
      userSpaces,
      (spaceInfo) => spaceInfo?.id === spaceId
    );
    return head(targetSpace || null);
  }, [userSpaces, spaceId]);

  const spaceName = useMemo(() => {
    return spaceInfo?.name || space?.name;
  }, [spaceInfo, space]);

  useEffect(() => {
    const onClick = (evt) => {
      if (
        evt?.target?.classList?.contains(HeaderUserMobileMoreOptionsClassname)
      ) {
        return;
      } else if (showMobileMore) {
        setShowMobileMore(false);
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [showMobileMore]);

  const onUndo = () => {
    if (EditorInstance) {
      EditorInstance.chain().focus().undo().run();
    }
  };

  const onRedo = () => {
    if (EditorInstance) {
      EditorInstance.chain().focus().redo().run();
    }
  };

  const onCopyLink = async () => {
    try {
      const { origin } = window.location;
      const originSanitized =
        origin.charAt(origin.length - 1) === '/'
          ? origin.substring(0, origin.length - 1)
          : origin;
      const link = `${originSanitized}/note/${toUpper(taskId)}?u=${uRef}`;
      setLinkCopied(true);

      await copyTextToClipboard(link);
      await timeout(400);
    } catch (err) {
      console.log(err?.message);
    } finally {
      setLinkCopied(false);
    }
  };

  /**
   * Select note's space (if available) when returning to dashboard
   */
  const onReturn = async () => {
    try {
      // select note's space if part of user's spaces todo
      if (spaceInfo && spaceInfo?.id) {
        if (isFunction(selectSpace)) {
          selectSpace(spaceInfo?.id);
        }
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      if (isFunction(props?.history?.push)) {
        if (profileLoaded && !isLoggedIn) {
          props.history.push('/login');
        } else {
          props.history.push('/user');
        }
      }
    }
  };

  const showProperties = () => {
    if (isFunction(showModalType)) {
      showModalType(NoteViewModalType.properties);
    }
  };

  const onLogin = () => {
    if (props?.history && isMobileView()) {
      props.history.push('/login');
      return;
    }

    if (isFunction(showLoginPrompt)) {
      showLoginPrompt();
    }
  };

  const onSignUp = () => {
    if (props?.history && isMobileView()) {
      props.history.push('/sign-up');
      return;
    }

    if (isFunction(showSignupPrompt)) {
      showSignupPrompt();
    }
  };

  return (
    <div
      className={cx(styles.flex_row_xy, styles.header, {
        [styles.header_dark]: isThemeDarkMode,
        [styles.header_ios_app]: isIosMobileApp(),
      })}
    >
      <div className={cx(styles.flex_row_xy, styles.left)}>
        <Button
          appearance="minimal"
          className={cx(styles.flex_row_xy, styles.logo, {
            [styles.logo_dark]: isThemeDarkMode,
          })}
          onClick={onReturn}
        >
          {window?.innerWidth > 600 || !isLoggedIn || !profileLoaded ? (
            <>
              <LogoIcon darkMode={isThemeDarkMode} height={30} width={30} />
              <div
                className={cx(styles.logo_texts, {
                  [styles.hide_element]: profileLoaded && !isLoggedIn,
                })}
              >
                <h5>{'Back to'}</h5>
                <h5>{space ? spaceName : 'Personal Space'}</h5>
              </div>
            </>
          ) : (
            <></>
          )}
          {isLoggedIn && profileLoaded && window?.innerWidth < 600 ? (
            <div
              className={cx(styles.flex_row_xy, styles.arrow_back, {
                [styles.arrow_back_light]: !isThemeDarkMode,
                [styles.arrow_back_dark]: isThemeDarkMode,
              })}
            >
              <ArrowUpIcon />
            </div>
          ) : (
            <></>
          )}
        </Button>
      </div>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        {loading || requirePasscode ? (
          <></>
        ) : (
          <>
            <div
              className={cx(styles.flex_row_xy, styles.content_left, {
                [styles.content_left_editmode]: isEditMode,
              })}
            >
              {isCreateMode || deletingNote || !isLoggedIn ? (
                <></>
              ) : (
                <ViewAndEditSwitch />
              )}
              {!isLoggedIn ? <ThemeSwitch /> : <></>}
              {!isMobileView() && (
                <Button
                  appearance="minimal"
                  className={cx(
                    styles.flex_row_xy,
                    styles.common_button,
                    styles.undo,
                    {
                      [styles.common_button_dark]: isThemeDarkMode,
                      [styles.hide_element]: !canModifyEditor,
                    }
                  )}
                  onClick={onUndo}
                >
                  <UndoIcon />
                  <p>{'Undo'}</p>
                </Button>
              )}
              {!isMobileView() && (
                <Button
                  appearance="minimal"
                  className={cx(
                    styles.flex_row_xy,
                    styles.common_button,
                    styles.redo,
                    {
                      [styles.common_button_dark]: isThemeDarkMode,
                      [styles.hide_element]: !canModifyEditor,
                    }
                  )}
                  onClick={onRedo}
                >
                  <RedoIcon />
                  <p>{'Redo'}</p>
                </Button>
              )}
            </div>
            <div className={cx(styles.flex_row_xy, styles.content_actions)}>
              <Button
                appearance="minimal"
                className={cx(
                  styles.flex_row_xy,
                  styles.common_button,
                  styles.copy_link,
                  {
                    [styles.common_button_dark]: isThemeDarkMode,
                    [styles.hide_element]:
                      isCreateMode ||
                      // so no overflow
                      (canModifyEditor &&
                        isMobileView() &&
                        window?.innerWidth < 400 &&
                        taskState !== k.TASK_STATE_ACTIVE),
                  }
                )}
                onClick={onCopyLink}
              >
                <LinkIcon height={16} width={16} />
                {isMobileView() ? <></> : <p>{'Copy Link'}</p>}
              </Button>

              <Button
                appearance="minimal"
                className={cx(
                  styles.flex_row_xy,
                  styles.common_button,
                  styles.share_mobile,
                  HeaderUserMobileMoreOptionsClassname,
                  {
                    [styles.common_button_dark]: isThemeDarkMode,
                    [styles.share_mobile_light]: !isThemeDarkMode,
                    [styles.share_mobile_dark]: isThemeDarkMode,
                    [styles.hide_element]:
                      canModifyEditor ||
                      !window?.navigator ||
                      !navigator?.share,
                  }
                )}
                onClick={async () => {
                  try {
                    if (navigator?.share) {
                      navigator.share({
                        title: title,
                        text: title,
                        url: `${window.location.href}`,
                      });
                    }
                  } catch {}
                }}
              >
                <ShareBoxIcon height={16} width={16} />
                <div
                  className={cx(
                    styles.cover,
                    HeaderUserMobileMoreOptionsClassname
                  )}
                ></div>
              </Button>

              <Button
                appearance="minimal"
                className={cx(
                  styles.flex_row_xy,
                  styles.common_button,
                  styles.more_mobile,
                  HeaderUserMobileMoreOptionsClassname,
                  {
                    [styles.common_button_dark]: isThemeDarkMode,
                    [styles.hide_element]: canModifyEditor,
                  }
                )}
                onClick={() => setShowMobileMore(!showMobileMore)}
              >
                <MoreDotIcon />
                <div
                  className={cx(
                    styles.cover,
                    HeaderUserMobileMoreOptionsClassname
                  )}
                ></div>
              </Button>

              <Button
                appearance="minimal"
                className={cx(
                  styles.flex_row_xy,
                  styles.common_button,
                  styles.properties,
                  {
                    [styles.common_button_dark]: isThemeDarkMode,
                    [styles.hide_element]: !canModifyEditor || deletingNote,
                  }
                )}
                onClick={showProperties}
              >
                <NoteViewPropertiesIcon />
                <p>{'Properties'}</p>
              </Button>

              <CustomButton
                isThemeDarkMode={isThemeDarkMode}
                onClick={() => {
                  if (creating || !isEmpty(uploadingFiles) || !title) {
                    return;
                  }

                  if (isFunction(createNewNote)) {
                    createNewNote();
                  }
                }}
                loading={!isEmpty(uploadingFiles) || creating}
                disabled={!title}
                label={i18n('common_create')}
                variant={'primaryPurple'}
                className={cx(styles.create, {
                  [styles.hide_element]: !isCreateMode,
                })}
              />
              <CustomButton
                isThemeDarkMode={isThemeDarkMode}
                loading={!isEmpty(uploadingFiles) || updatingState || saving}
                icon={
                  taskState === k.TASK_STATE_ACTIVE ? (
                    <CheckIcon height={16} width={16} />
                  ) : null
                }
                label={
                  taskState === k.TASK_STATE_ACTIVE
                    ? 'Mark Done'
                    : 'Mark Active'
                }
                variant={'primaryPurple'}
                className={cx(styles.done, {
                  [styles.hide_element]:
                    isCreateMode ||
                    !isEditMode ||
                    isForGettingStartedNote ||
                    !allowedToToggleModify,
                  [styles.done_active]: taskState === k.TASK_STATE_ACTIVE,
                })}
                onClick={() => {
                  if (isFunction(markCompleteOrActive)) {
                    markCompleteOrActive();
                  }
                }}
              />

              {linkCopied ? (
                <MessageHover
                  message={'Copied to clipboard'}
                  className={styles.link_copied}
                />
              ) : (
                <></>
              )}
              {showMobileMore ? (
                <div
                  className={cx(
                    styles.more_mobile_options,
                    {
                      [styles.more_mobile_options_dark]: isThemeDarkMode,
                    },
                    HeaderUserMobileMoreOptionsClassname
                  )}
                >
                  <div
                    className={cx(
                      styles.flex_column_xy,
                      styles.more_mobile_options_content,
                      HeaderUserMobileMoreOptionsClassname
                    )}
                  >
                    <div
                      className={cx(
                        styles.item,
                        { [styles.item_dark]: isThemeDarkMode },
                        HeaderUserMobileMoreOptionsClassname
                      )}
                    >
                      <Button
                        className={cx(
                          styles.flex_row_xy,
                          HeaderUserMobileMoreOptionsClassname
                        )}
                        appearance="minimal"
                        onClick={() => {
                          if (isFunction(showModalType)) {
                            showModalType(NoteViewModalType.activities);
                          }

                          setShowMobileMore(false);
                        }}
                      >
                        <ActivityIcon />
                        <p>{'Activity Log'}</p>
                        <div
                          className={cx(
                            styles.cover,
                            HeaderUserMobileMoreOptionsClassname
                          )}
                        ></div>
                      </Button>
                    </div>

                    <div
                      className={cx(
                        styles.item,
                        {
                          [styles.item_dark]: isThemeDarkMode,
                          [styles.hide_element]:
                            !isMobileView() || canModifyEditor || !commentsView,
                        },
                        HeaderUserMobileMoreOptionsClassname
                      )}
                    >
                      <Button
                        className={cx(
                          styles.flex_row_xy,
                          HeaderUserMobileMoreOptionsClassname
                        )}
                        appearance="minimal"
                        onClick={() => {
                          if (isFunction(showModalType)) {
                            showModalType(NoteViewModalType.comments);
                          }
                        }}
                      >
                        <ChatIcon height={16} width={16} />
                        <p>{'Comments'}</p>
                        <div
                          className={cx(
                            styles.cover,
                            HeaderUserMobileMoreOptionsClassname
                          )}
                        ></div>
                      </Button>
                    </div>

                    {canModifyEditor ? (
                      <div
                        className={cx(
                          styles.item,
                          {
                            [styles.item_dark]: isThemeDarkMode,
                          },
                          HeaderUserMobileMoreOptionsClassname
                        )}
                      >
                        <Button
                          className={cx(
                            styles.flex_row_xy,
                            HeaderUserMobileMoreOptionsClassname
                          )}
                          appearance="minimal"
                        >
                          <NoteViewPropertiesIcon />
                          <p>{'Properties'}</p>
                          <div
                            className={cx(
                              styles.cover,
                              HeaderUserMobileMoreOptionsClassname
                            )}
                          ></div>
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
        <div
          className={cx(styles.content_loading, styles.flex_row_xy, {
            [styles.hide_element]: !loading,
          })}
        >
          <div
            className={cx(styles.skeleton, {
              [styles.skeleton_dark]: isThemeDarkMode,
            })}
          ></div>
        </div>
      </div>
      <div className={cx(styles.flex_row_xy, styles.right)}>
        {isLoggedIn ? (
          <div
            className={cx(styles.flex_row_xy, styles.user)}
            onClick={() => setShowUserOptions(!showUserOptions)}
          >
            <div className={cx(styles.flex_row_xy, styles.avatar)}>
              {!isEmpty(user) && (
                <Avatar
                  height={'40px'}
                  width={'40px'}
                  backgroundColor={'#000'}
                  src={userImage}
                  name={fullName}
                />
              )}
            </div>
            <div
              className={cx(styles.name, {
                [styles.name_dark]: isThemeDarkMode,
              })}
            >
              <p>{fullName}</p>
            </div>
            <div
              className={cx(styles.arrow, styles.flex_row_xy, {
                [styles.arrow_active]: showUserOptions,
                [styles.arrow_dark]: isThemeDarkMode,
              })}
            >
              <CaretDownIcon />
            </div>
            <div
              className={cx(styles.cover, 'HeaderUserOptionsClassname')}
            ></div>
          </div>
        ) : (
          <div className={cx(styles.flex_row_xy, styles.cta)}>
            <CustomButton
              isThemeDarkMode={isThemeDarkMode}
              label={i18n('common_login_verb')}
              variant={'secondary'}
              className={cx(styles.login, {
                [styles.login_dark]: isThemeDarkMode,
              })}
              onClick={onLogin}
            />
            <CustomButton
              isThemeDarkMode={isThemeDarkMode}
              label={i18n('common_signup_verb')}
              variant={'primaryPurple'}
              className={styles.signup}
              onClick={onSignUp}
            />
          </div>
        )}

        {showUserOptions && (
          <HeaderUserOptions close={() => setShowUserOptions(false)} />
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(
    withModalPromptSettings(
      withMobileToastSettings(withNoteViewContext(withRouter(Header)))
    )
  )
);
