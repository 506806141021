import { head, isEmpty, isNumber, pick, trim } from 'lodash';
import { isNetworkError } from '.';
import { getFileApiPathname, getSpacesApiPathname } from './urls';
import { axiosGetRequest, axiosPostRequest, axiosPutRequest } from './axios';

export async function getUserJoinedSpacesByPageRequest(
  pageNumber = 1,
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let spaces = [];
  let hasNext = false;
  let userIsPremium = false;

  try {
    if (!pageNumber || !isNumber(pageNumber)) {
      pageNumber = 1;
    }

    const url = getSpacesApiPathname() + `/joined-list/${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData)) {
      spaces = resData?.spacesList;
      hasNext = resData?.hasNext;
      userIsPremium = resData?.userIsPremium;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return { hasNext, userIsPremium, spaces, errorMessage, networkError };
}

export async function getSpaceTagsByPageRequest(
  pageNumber = 1,
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let tags = [];
  let hasNext = false;

  if (!pageNumber || pageNumber < 1) {
    pageNumber = 1;
  }

  try {
    const url =
      getSpacesApiPathname() + `/tags/${pageNumber}?id=${trim(spaceId)}`;

    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData)) {
      tags = resData?.value;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { tags, errorMessage, networkError, hasNext };
}

export async function getSpaceInfoByIdRequest(spaceId = '', headers = {}) {}

export async function getSpaceMembersByPageRequest(
  pageNumber = 1,
  spaceId = '',
  headers = {}
) {
  let members = [];
  let errorMessage = '';
  let networkError = false;
  let hasNext = false;
  let totalMembers = 0;

  try {
    const url =
      getSpacesApiPathname() + `/members/${pageNumber}?id=${trim(spaceId)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.value) {
      members = resData?.value;
      hasNext = resData?.hasNext;
      totalMembers = resData?.totalMembers;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { totalMembers, hasNext, members, networkError, errorMessage };
}

export async function editSpaceInfoRequest(
  spaceId = '',
  props = {},
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let spaceInfo = null;
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/edit';
    const res = await axiosPutRequest(url, headers, {
      id: trim(spaceId),
      ...pick(props, ['name', 'bio', 'title']),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.space) {
      spaceInfo = resData?.space;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { networkError, success, errorMessage, spaceInfo };
}
export async function removeSpaceMembersRequest(
  userIdOrProfileId = '',
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/members/remove';
    const res = await axiosPostRequest(url, headers, {
      membersId: [userIdOrProfileId],
      id: trim(spaceId),
    });
    const resData = res?.data;

    if (
      (res?.status === 200 || res?.status === 201) &&
      resData?.removedMembers &&
      resData?.success
    ) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function leaveSpaceRequest(spaceId = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/leave';
    const res = await axiosPostRequest(url, headers, { id: trim(spaceId) });
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function addNewSpaceMemberRequest(
  userId = '',
  spaceId = '',
  headers = {}
) {
  // instance.post(
  //   '/members/new',
  //   { schema: addSpaceMemberSchema },
  //   addMembersSpaceHandler
  // );

  let networkError = false;
  let errorMessage = '';
  let success = false;
  let memberInfo = null;

  // newMembers

  try {
    const url = getSpacesApiPathname() + '/members/new';
    const res = await axiosPostRequest(url, headers, {
      membersId: [userId],
      id: trim(spaceId),
    });
    const resData = res?.data;

    if (resData?.status === 200 && resData?.success) {
      success = true;
      memberInfo = head(resData?.newMembers || []);
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { memberInfo, errorMessage, networkError, success };
}

export async function archiveCreatedSapce(id = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/created/archive';
    const res = await axiosPostRequest(url, headers, { id: trim(id) });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { errorMessage, networkError, success };
}

export async function createNewSpaceRequest(name = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let spaceInfo = null;
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/create';
    const res = await axiosPostRequest(url, headers, { name: trim(name) });
    const resData = res?.data;

    if (res?.status === 200 && resData?.space) {
      spaceInfo = resData?.space;
      success = true;
    } else if (resData) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return { networkError, success, errorMessage, spaceInfo };
}

export async function uploadSpaceImageFile(
  file = null,
  fileName = '',
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let image = '';

  try {
    const url =
      // /upload/image/space
      getFileApiPathname() +
      `/upload/image/space?fileName=${trim(fileName)}&spaceId=${trim(spaceId)}`;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const res = await axiosPostRequest(
      url,
      { ...headers, 'content-type': 'multipart/form-data' },
      formData
    );
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.image) {
      image = resData?.image || resData?.url || '';
    } else if (resData) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    image,
  };
}
