import cx from 'classnames';
import styles from './Header.module.scss';
import LogoIcon from 'src/components/logo-icon';
import CustomButtonWrapper from 'src/components/button';
import Navigation from 'src/pages/documentation/modals/Navigation';

import { isFunction } from 'lodash';
import { MoreDotIcon, MenuIcon } from 'src/icons';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';
import { withModalPromptSettings } from 'src/managers/modal';
import {
  getUserDisplayName,
  isCapacitorMobileApp,
  isIosMobile,
  isMobileView,
} from 'src/helpers/utils';
import { Avatar } from '@chakra-ui/react';

const Header = (props) => {
  const {
    user,
    profileLoaded,
    setActiveModalDom,
    isLoggedIn,
    showLoginPrompt,
    showSignupPrompt,
    history,
  } = props;
  const loggoWrapper = cx(styles.logo, styles.flex_row_xy, styles.col_16);
  const loginSignupWrapper = cx(
    styles.col_16,
    styles.flex_row_xy,
    styles.login_signup_wrapper
  );
  const loginStyles = cx(styles.buttons, styles.login);

  const onLoginClick = () => {
    if (isMobileView() && history?.push) {
      history.push('/login');
      return;
    }

    if (isFunction(showLoginPrompt)) {
      showLoginPrompt();
    }
  };

  const onSignUpClick = () => {
    if (
      (isMobileView() || isIosMobile() || isCapacitorMobileApp()) &&
      history?.push
    ) {
      history.push('/sign-up');
      return;
    }

    if (isFunction(showSignupPrompt)) {
      showSignupPrompt();
    }
  };

  const showMobileNav = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<Navigation />);
    }
  };

  return (
    <div className={styles.docs_header}>
      <Link to={'/documentation'} className={loggoWrapper}>
        <LogoIcon height={30} width={30} />
        <h2 className={styles.title}>{'Docs'}</h2>
      </Link>
      <div className={loginSignupWrapper}>
        {!isLoggedIn ? (
          <>
            <CustomButtonWrapper
              className={loginStyles}
              variant="secondaryLight"
              label="Login"
              onClick={onLoginClick}
            />
            <CustomButtonWrapper
              className={styles.buttons}
              variant="primaryBlue"
              label="Sign Up"
              onClick={onSignUpClick}
            />
          </>
        ) : (
          <></>
        )}
        {isLoggedIn ? (
          <div className={cx(styles.flex_row_xy, styles.avatar)}>
            <Link to={'/user'}>
              {profileLoaded ? (
                <Avatar
                  height={'40px'}
                  width={'40px'}
                  src={user?.image}
                  background={'#000'}
                  backgroundColor={'#000'}
                  name={getUserDisplayName(user)}
                />
              ) : (
                <div className={cx(styles.flex_row_xy, styles.avatar_default)}>
                  <MoreDotIcon />
                </div>
              )}{' '}
            </Link>
          </div>
        ) : (
          <></>
        )}

        <Button
          appearance="minimal"
          className={cx(styles.flex_row_xy, styles.more_button)}
          onClick={showMobileNav}
        >
          <MenuIcon height={24} width={24} />
        </Button>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withMobileToastSettings(withRouter(Header)))
);
