const SettingsIcon = (p) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_570_15498)">
        <path
          d="M8.68588 3.99988L11.2929 1.39288C11.4804 1.20541 11.7347 1.1001 11.9999 1.1001C12.265 1.1001 12.5194 1.20541 12.7069 1.39288L15.3139 3.99988H18.9999C19.2651 3.99988 19.5195 4.10524 19.707 4.29278C19.8945 4.48031 19.9999 4.73467 19.9999 4.99988V8.68588L22.6069 11.2929C22.7944 11.4804 22.8997 11.7347 22.8997 11.9999C22.8997 12.265 22.7944 12.5194 22.6069 12.7069L19.9999 15.3139V18.9999C19.9999 19.2651 19.8945 19.5195 19.707 19.707C19.5195 19.8945 19.2651 19.9999 18.9999 19.9999H15.3139L12.7069 22.6069C12.5194 22.7944 12.265 22.8997 11.9999 22.8997C11.7347 22.8997 11.4804 22.7944 11.2929 22.6069L8.68588 19.9999H4.99988C4.73467 19.9999 4.48031 19.8945 4.29278 19.707C4.10524 19.5195 3.99988 19.2651 3.99988 18.9999V15.3139L1.39288 12.7069C1.20541 12.5194 1.1001 12.265 1.1001 11.9999C1.1001 11.7347 1.20541 11.4804 1.39288 11.2929L3.99988 8.68588V4.99988C3.99988 4.73467 4.10524 4.48031 4.29278 4.29278C4.48031 4.10524 4.73467 3.99988 4.99988 3.99988H8.68588ZM5.99988 5.99988V9.51488L3.51488 11.9999L5.99988 14.4849V17.9999H9.51488L11.9999 20.4849L14.4849 17.9999H17.9999V14.4849L20.4849 11.9999L17.9999 9.51488V5.99988H14.4849L11.9999 3.51488L9.51488 5.99988H5.99988ZM11.9999 15.9999C10.939 15.9999 9.9216 15.5785 9.17146 14.8283C8.42131 14.0782 7.99988 13.0607 7.99988 11.9999C7.99988 10.939 8.42131 9.9216 9.17146 9.17146C9.9216 8.42131 10.939 7.99988 11.9999 7.99988C13.0607 7.99988 14.0782 8.42131 14.8283 9.17146C15.5785 9.9216 15.9999 10.939 15.9999 11.9999C15.9999 13.0607 15.5785 14.0782 14.8283 14.8283C14.0782 15.5785 13.0607 15.9999 11.9999 15.9999ZM11.9999 13.9999C12.5303 13.9999 13.039 13.7892 13.4141 13.4141C13.7892 13.039 13.9999 12.5303 13.9999 11.9999C13.9999 11.4695 13.7892 10.9607 13.4141 10.5857C13.039 10.2106 12.5303 9.99988 11.9999 9.99988C11.4695 9.99988 10.9607 10.2106 10.5857 10.5857C10.2106 10.9607 9.99988 11.4695 9.99988 11.9999C9.99988 12.5303 10.2106 13.039 10.5857 13.4141C10.9607 13.7892 11.4695 13.9999 11.9999 13.9999Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_570_15498">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SettingsIcon;
