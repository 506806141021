import { useEffect, useRef, useMemo } from 'react';
import cx from 'classnames';
import styles from './Spaces.module.scss';
import i18n from 'src/locales';
import CreateSpace from 'src/modals/create-space';
import {
  filter,
  toUpper,
  head,
  isEmpty,
  last,
  toString,
  isFunction,
} from 'lodash';
import { TickCircleIcon, Button, PlusIcon } from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'src/lib/bodyScrollLock.min';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withModalPromptSettings } from 'src/managers/modal';
import { isMobileViewScreen } from '..';

const Spaces = (props) => {
  const createSpaceButtonRef = useRef(null);
  const listRef = useRef(null);
  const wrapRef = useRef(null);
  const spaceSelectedUnique = 'mobile-opts-space-selected';

  const {
    modalActive,
    profileLoaded,
    isThemeDarkMode,
    selectSpace,
    user,
    isPersonalSpaceSelected,
    setActiveModalDom,
    userIsPremium: isPremiumTeams,
    userSpaces: availableSpaces = [],
    selectedSpace: spaceSelected = null,
  } = props;
  const userPlan = useMemo(() => user?.plan || {}, [user]);

  /**
   * Apply selected space
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (!evt) {
        return;
      }

      const target = evt.target || evt.srcElement;

      if (
        !isEmpty(target?.classList) &&
        target.classList.contains(spaceSelectedUnique)
      ) {
        const spaceId = toString(last(target.classList) || '');

        if (
          (spaceId === 'personal' && isPersonalSpaceSelected) ||
          !isPremiumTeams
        ) {
          return;
        }

        if (spaceId === 'personal') {
          selectSpace('personal');
          return;
        }

        const find = head(
          filter(
            availableSpaces,
            (spaceInfo) =>
              spaceInfo?.id === spaceId || spaceInfo?.space_id === spaceId
          )
        );

        if (!isEmpty(find)) {
          selectSpace(spaceId);
        }
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [
    selectSpace,
    userPlan,
    isPremiumTeams,
    isPersonalSpaceSelected,
    profileLoaded,
    spaceSelected,
    availableSpaces,
  ]);

  useEffect(() => {
    const dom = listRef.current;

    if (dom) {
      disableBodyScroll(dom);
    }

    return () => enableBodyScroll(dom);
  }, [availableSpaces]);

  useEffect(() => {
    const dom = listRef.current;

    return () => enableBodyScroll(dom);
  });

  useEffect(() => {
    const onResize = () => {
      const newH = `${
        (window?.visualViewport?.height
          ? window.visualViewport.height
          : window.innerHeight) -
        (80 + 60)
      }px`;

      if (wrapRef?.current) {
        wrapRef.current.style.height = newH;
      }
    };

    onResize();

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', onResize, false);
    } else {
      window.addEventListener('resize', onResize, false);
    }

    return () => {
      if (window?.visualViewport) {
        window?.visualViewport.removeEventListener('resize', onResize, false);
      } else {
        window.removeEventListener('resize', onResize, false);
      }
    };
  }, [availableSpaces]);

  const createNewSpace = (evt) => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<CreateSpace />);
    }

    if (evt?.target?.blur) {
      evt?.target.blur();
    } else if (evt?.target?.parentElement?.blur) {
      evt?.target?.parentElement.blur();
    }
  };

  return (
    <div
      ref={wrapRef}
      className={cx(styles.spaces_dashboard_view, {
        [styles.spaces_dashboard_view_dark]: isThemeDarkMode,
        [styles.spaces_dashboard_view_mobile_modal]:
          isMobileViewScreen() && modalActive,
      })}
    >
      <div
        ref={listRef}
        className={cx(styles.spaces_dashboard_view_list, {
          [styles.spaces_dashboard_view_list_dark]: isThemeDarkMode,
        })}
      >
        {isPremiumTeams && (
          <div
            className={cx(styles.create_new_space, {
              [styles.create_new_space_dark]: isThemeDarkMode,
            })}
          >
            <Button
              ref={createSpaceButtonRef}
              iconBefore={PlusIcon}
              appearance="minimal"
              onClick={createNewSpace}
            >
              <h5>{i18n('user_dashboard_create_space')}</h5>
            </Button>
          </div>
        )}
        <ul>
          <li className={styles.flex_row_xy}>
            <div className={cx(styles.flex_row_xy, styles.space_avatar)}>
              <h5 className={styles.flex_row_xy}>P</h5>
            </div>
            <div
              className={cx(styles.space_info, {
                [styles.space_info_dark]: isThemeDarkMode,
              })}
            >
              <h4>{i18n('user_dashboard_chamu_personal_space')}</h4>
              {isPersonalSpaceSelected ? (
                <div className={cx(styles.flex_row_xy, styles.selected)}>
                  <TickCircleIcon color="success" />{' '}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={cx(styles.cover, spaceSelectedUnique, 'personal')}
            ></div>
          </li>
          {availableSpaces.map((currentSpace) => {
            const spaceId = currentSpace?.id || currentSpace.space_id;
            const spaceName = currentSpace?.name;
            const spaceBio = currentSpace?.bio;
            const spaceImage = currentSpace.image;

            return (
              <li className={styles.flex_row_xy} key={spaceId}>
                <div className={cx(styles.flex_row_xy, styles.space_avatar)}>
                  {spaceImage ? (
                    <img src={spaceImage} alt={`${spaceName} space avatar`} />
                  ) : (
                    <h5 className={styles.flex_row_xy}>
                      {toUpper(spaceName.charAt(0))}
                    </h5>
                  )}
                </div>
                <div
                  className={cx(styles.space_info, {
                    [styles.space_info_dark]: isThemeDarkMode,
                  })}
                >
                  <h4>
                    {`${spaceName}${
                      currentSpace?.archived ? ' (archived)' : ''
                    }`}
                  </h4>
                  {spaceBio && <h5>{spaceBio} </h5>}
                  {spaceSelected?.id === spaceId ? (
                    <div className={cx(styles.flex_row_xy, styles.selected)}>
                      <TickCircleIcon color="success" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className={cx(styles.cover, spaceSelectedUnique, spaceId)}
                ></div>
              </li>
            );
          })}
        </ul>{' '}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(Spaces))
);
