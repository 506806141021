import cx from 'classnames';
import styles from './RadioButton.module.scss';
import { isBoolean, isFunction, isString } from 'src/helpers/utils';
import { useEffect, useState } from 'react';

const RadioButton = (props) => {
  const { value, onChange, label, isThemeDarkMode, disabled } = props;
  const validValue = isBoolean(value);
  const hasLabel = !!label;
  const [active, setActive] = useState((validValue ? value : false) || true);

  useEffect(() => {
    if (isBoolean(value)) {
      setActive(value);
    }
  }, [value]);

  const onClick = (evt) => {
    if (disabled) {
      return;
    }

    if (isFunction(onChange)) {
      onChange(evt);

      if (!validValue) {
        setActive(!active);
      }
    }
  };

  return (
    <div
      onClick={onClick}
      className={cx(styles.flex_row_xy, styles.radio_button, {
        [styles.radio_button_with_label]: hasLabel,
        [styles.radio_button_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(styles.flex_row_xy, styles.icon, {
          [styles.icon_active]: active,
          [styles.icon_inactive]: !active,
          [styles.icon_dark]: isThemeDarkMode,
          [styles.icon_dark_inactive]: isThemeDarkMode && !active,
          [styles.icon_dark_active]: isThemeDarkMode && active,
        })}
      >
        <div
          className={cx(styles.inner, {
            [styles.inner_active]: active,
            [styles.inner_inactive]: !active,
          })}
        ></div>
      </div>{' '}
      {hasLabel ? (
        isString(label) ? (
          <p onClick={onClick}> {label}</p>
        ) : (
          <h5 onClick={onClick}>{label}</h5>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default RadioButton;
