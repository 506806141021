import { useState } from 'react';
import styles from './NetworkError.module.scss';
import cx from 'classnames';
import i18n from 'src/locales';
import { CHAMU_ICON_BLACK, CHAMU_ICON_WORD_BLACK } from 'src/constants/icons';
import { Button } from 'evergreen-ui';
import { includes, isUserNetworkOnline } from 'src/helpers/utils';
import { Redirect, withRouter } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const NetworkError = (props) => {
  const [toRedirect, setRedirect] = useState(false);
  const pathname = window?.location?.pathname;

  const onRetry = async () => {
    const isOnline = await isUserNetworkOnline();

    if (!isOnline) {
      return;
    } else {
      setRedirect(true);
    }
  };

  if (toRedirect) {
    if (!includes(pathname, '/user') && !includes(pathname, '/view/')) {
      return <Redirect to={'/user'} />;
    }

    return <Redirect to={pathname} />;
  }

  return (
    <div className={cx(styles.network_error, styles.flex_row_xy)}>
      <div className={styles.network_error_background}>
        <img src={'404background.png'} alt={'network error page background'} />
      </div>

      <div className={styles.content}>
        <div className={styles.logo}>
          <div className={cx(styles.flex_row_xy, styles.logo_raw)}>
            <img src={CHAMU_ICON_BLACK} alt={'chamu logo icon'} />{' '}
            <img src={CHAMU_ICON_WORD_BLACK} alt={'chamu logo word'} />
          </div>
        </div>
        <div className={styles.texts}>
          <h2>No internet connection</h2>
          <section>
            <p>
              To fix this error, make sure your device is connected to any of
              the following:
            </p>
            <ul>
              <li>WiFi</li>
              <li>Data</li>
              <li>Hotspot</li>
              <li>Modem through an ethernet cable</li>
            </ul>
            <p>After connecting your device, please retry again.</p>
          </section>
        </div>
        <div className={styles.cta}>
          <Button appearance="minimal" onClick={onRetry}>
            <p>{i18n('common_retry')} </p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withRouter(NetworkError));
