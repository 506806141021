import cx from 'classnames';
import k from 'src/constants/k';
import CommentEntity from 'src/pages/note-view/comment-entity';
import styles from './Notification.module.scss';
import i18n, { Markdown } from 'src/locales';
import { daysToSeconds, getHHMMAMPMFormat } from 'src/helpers/utils';
import { Link } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

function getFormattedDate(utc) {
  const timestampNow = Date.now();
  const todayUTC = timestampNow;
  const utcSeconds = Math.floor(utc / 1000);
  const utcDate = new Date(utc);
  const todayUTCSeconds = Math.floor(todayUTC) / 1000;
  const todayUTCDate = new Date(todayUTC);
  const diffSeconds = todayUTCSeconds - utcSeconds;
  const oneDaySeconds = daysToSeconds(1);
  const is_negative = !!(diffSeconds < 0);
  const is_same_year = utcDate.getFullYear() === todayUTCDate.getFullYear();

  if (is_negative) {
    /**
     * Just provide date since we expect that utc created is always less than utc present
     */
    return `${
      utcDate.getMonth() + 1
    }/${utcDate.getDate()}/${utcDate.getFullYear()}`;
  } else if (
    diffSeconds < oneDaySeconds &&
    diffSeconds >= 3600 &&
    is_same_year
  ) {
    /**
     * Less than a day
     */

    return `${Math.ceil(diffSeconds * (1 / 60) * (1 / 60))} hr.`;
  } else if (
    !is_negative &&
    utcDate.getMonth() === todayUTCDate.getMonth() &&
    utcDate.getDate() === todayUTCDate.getDate()
  ) {
    /**
     * HH:MM AM/PM in local time of user
     */
    return getHHMMAMPMFormat(utcDate.getHours(), utcDate.getMinutes());
  } else if (is_same_year && !is_negative) {
    return `${k.MONTHS_INDEX[utcDate.getMonth()].abv} ${utcDate.getDate()}`;
  } else {
    const fullYR = `${utcDate.getFullYear()}`;

    return `${utcDate.getMonth() + 1}/${utcDate.getDate()}/${fullYR
      .split('')
      .slice(fullYR.length - 2, fullYR.length)
      .join('')}`;
  }
}

export const UserNotificationEntity = (props) => {
  const { isThemeDarkMode, notification } = props;
  const timestampNow = Date.now();

  function getFullNotifMessage(n) {
    const isi18n = n.isi18n;
    const txt = n.text;

    if (!isi18n) {
      return `${txt}`;
    }

    if (typeof n.opts === 'object') {
      return Markdown(txt, { ...n.opts });
    }

    return i18n(txt);
  }

  const notificationPreview = useMemo(
    () => notification?.preview,
    [notification]
  );

  const authorUserId = useMemo(
    () =>
      notificationPreview?.userId ||
      notificationPreview?.userProfileId ||
      notificationPreview?.profile_id,
    [notificationPreview]
  );
  const authorProfileId = useMemo(
    () => notificationPreview?.userProfileId || notificationPreview?.profile_id,
    [notificationPreview]
  );

  return (
    <div className={cx(styles.user_notification_entity)}>
      <div
        className={cx(styles.message, {
          [styles.message_dark]: isThemeDarkMode,
        })}
      >
        {!props.noMark && (
          <div
            className={cx(styles.user_notification_entity_unread, {
              [styles.hide_element]:
                !props.notification || !props.notification.unread,
            })}
          ></div>
        )}
        {getFullNotifMessage(props.notification)}
      </div>
      <div className={cx(styles.flex_row_xy, styles.date)}>
        <h5>
          {getFormattedDate(
            props.notification.created
              ? props.notification.inSeconds
                ? props.notification.created * 1000
                : props.notification.created
              : timestampNow
          )}
        </h5>
      </div>

      {!isEmpty(notificationPreview) && (
        <div className={styles.preview}>
          <CommentEntity
            hideEditButton
            authorInfo={notificationPreview}
            authorUserId={authorUserId}
            authorProfileId={authorProfileId}
            text={notificationPreview?.comment}
            gif={notificationPreview?.gif}
            actionableCommentID={''}
            opts={false}
            toEdit={false}
            loadAction={false}
            created={notificationPreview?.created}
          />
        </div>
      )}
      <div
        className={cx(styles.cover, styles.user_notification_entity_link, {
          [styles.hide_element]: !props.notification.isLink,
        })}
      >
        <Link
          to={
            typeof props.notification.link === 'string'
              ? props.notification.link
              : '#'
          }
        ></Link>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(UserNotificationEntity);
