const ShieldKeyHoleIcon = (p) => {
  return (
    <svg 
      width="18" 
      height="18" 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path 
        d="M7 0.75L13.1627 2.1195C13.3293 2.15652 13.4782 2.24922 13.585 2.3823C13.6918 2.51538 13.75 2.68089 13.75 2.8515V10.3417C13.7499 11.0826 13.567 11.8119 13.2174 12.465C12.8678 13.1181 12.3624 13.6749 11.746 14.0858L7 17.25L2.254 14.0858C1.63771 13.6749 1.13235 13.1183 0.782761 12.4653C0.433177 11.8124 0.250177 11.0832 0.25 10.3425V2.8515C0.250029 2.68089 0.308228 2.51538 0.414992 2.3823C0.521756 2.24922 0.670703 2.15652 0.83725 2.1195L7 0.75ZM7 2.28675L1.75 3.453V10.3417C1.75001 10.8356 1.87193 11.3218 2.10495 11.7572C2.33796 12.1926 2.67486 12.5638 3.08575 12.8378L7 15.4478L10.9142 12.8378C11.325 12.5639 11.6619 12.1928 11.8949 11.7575C12.1279 11.3223 12.2499 10.8362 12.25 10.3425V3.453L7 2.28675ZM7 5.25C7.33025 5.24985 7.65133 5.35869 7.9134 5.55965C8.17548 5.7606 8.36392 6.04243 8.44947 6.3614C8.53503 6.68038 8.51293 7.01868 8.38659 7.32381C8.26025 7.62895 8.03675 7.88386 7.75075 8.049L7.75 11.25H6.25V8.049C5.96406 7.88389 5.74058 7.62905 5.61423 7.32399C5.48788 7.01893 5.46572 6.6807 5.55118 6.36177C5.63664 6.04283 5.82495 5.761 6.0869 5.55999C6.34885 5.35898 6.66981 5.25001 7 5.25Z" 
        fill="#718096"
      />
    </svg>
  )
}

export default ShieldKeyHoleIcon;
