export const paragraphUnpacker = (toUnPack, key = '') => {
  const paragraphs = [];

  for (let i = 0; i < toUnPack.length; i++) {
    const content = toUnPack[i];
    paragraphs.push(<p key={`paragraphUnpacker${key}${i}`}>{content}</p>);
  }

  return paragraphs;
};
