import cx from 'classnames';
import LogoIconWord from '../logo-icon-word';
import styles from './LogoLoadV2.module.scss';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const LogoLoadV2 = (props) => {
  const { isThemeDarkMode } = props;

  return (
    <div
      className={cx(styles.logo_loadv2, styles.flex_row_xy, {
        [styles.logo_loadv2_dark]: isThemeDarkMode,
      })}
    >
      <LogoIconWord />
    </div>
  );
};

export default withUserDataAndProfileSettings(LogoLoadV2);
