const PensiveFaceIcon = (p) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M15.0001 26.4284C21.3119 26.4284 26.4287 21.3117 26.4287 14.9999C26.4287 8.68805 21.3119 3.57129 15.0001 3.57129C8.68828 3.57129 3.57153 8.68805 3.57153 14.9999C3.57153 21.3117 8.68828 26.4284 15.0001 26.4284Z"
        fill="url(#paint0_radial_306_10814)"
      />
      <path
        opacity="0.5"
        d="M15.0001 26.4284C21.3119 26.4284 26.4287 21.3117 26.4287 14.9999C26.4287 8.68805 21.3119 3.57129 15.0001 3.57129C8.68828 3.57129 3.57153 8.68805 3.57153 14.9999C3.57153 21.3117 8.68828 26.4284 15.0001 26.4284Z"
        fill="url(#paint1_radial_306_10814)"
      />
      <path
        d="M7.29297 11.9361C7.35008 12.3932 7.87868 12.6147 8.72153 12.5004C9.44295 12.4075 10.9644 11.8147 11.8715 10.2718C12.0358 9.98611 11.7144 9.8861 11.5358 10.079C10.943 10.729 9.24297 11.6861 7.82865 11.7147C7.26439 11.7361 7.29297 11.9361 7.29297 11.9361Z"
        fill="url(#paint2_linear_306_10814)"
      />
      <path
        d="M10.4 16.4147C10.4 16.4147 8.49282 16.3076 7.89995 14.8933C7.86425 14.8075 7.8428 14.7076 7.83567 14.6147C7.82851 14.4218 7.9928 14.2147 8.34279 14.3861C10.4785 15.4361 11.6571 14.8504 12.5357 14.429C12.8785 14.2647 13.1999 14.6075 13.1142 14.9218C13.0857 15.0147 13.0714 15.1076 13.0285 15.1933C12.3071 16.529 10.4 16.4147 10.4 16.4147Z"
        fill="url(#paint3_radial_306_10814)"
      />
      <path
        d="M13.1214 14.8792C13.1785 14.572 12.8642 14.2649 12.5357 14.422C11.6571 14.8363 10.4785 15.4292 8.34279 14.3792C7.9928 14.2078 7.82851 14.422 7.83567 14.6077C7.83567 14.6149 7.83567 14.6292 7.83567 14.6363C8.30709 14.772 9.29994 15.3577 10.4214 15.3577C11.7357 15.3649 12.5714 14.7292 13.1214 14.8792Z"
        fill="url(#paint4_linear_306_10814)"
      />
      <path
        d="M22.7072 11.943C22.65 12.4001 22.1215 12.6215 21.2786 12.5072C20.5572 12.4144 19.0357 11.8215 18.1286 10.2787C17.9643 9.99295 18.2857 9.89294 18.4643 10.0858C19.0572 10.7358 20.7572 11.6929 22.1715 11.7215C22.7358 11.743 22.7072 11.943 22.7072 11.943Z"
        fill="url(#paint5_linear_306_10814)"
      />
      <path
        d="M19.6072 16.4147C19.6072 16.4147 21.5144 16.3076 22.1072 14.8933C22.143 14.8075 22.1644 14.7076 22.1715 14.6147C22.1787 14.4218 22.0144 14.2147 21.6644 14.3861C19.5286 15.4361 18.3501 14.8504 17.4715 14.429C17.1286 14.2647 16.8072 14.6075 16.8929 14.9218C16.9215 15.0147 16.9358 15.1076 16.9786 15.1933C17.7001 16.529 19.6072 16.4147 19.6072 16.4147Z"
        fill="url(#paint6_radial_306_10814)"
      />
      <path
        d="M16.8857 14.886C16.8285 14.5789 17.1428 14.2717 17.4714 14.4289C18.3499 14.8432 19.5285 15.436 21.6642 14.386C22.0142 14.2146 22.1785 14.4289 22.1714 14.6146C22.1714 14.6217 22.1714 14.636 22.1714 14.6431C21.7 14.7789 20.7071 15.3646 19.5857 15.3646C18.2714 15.3717 17.4357 14.736 16.8857 14.886Z"
        fill="url(#paint7_linear_306_10814)"
      />
      <path
        d="M15.0002 22.678C16.7636 22.678 18.1931 22.3358 18.1931 21.9137C18.1931 21.4916 16.7636 21.1494 15.0002 21.1494C13.2369 21.1494 11.8074 21.4916 11.8074 21.9137C11.8074 22.3358 13.2369 22.678 15.0002 22.678Z"
        fill="url(#paint8_radial_306_10814)"
      />
      <path
        d="M15.0002 21.478C16.9645 21.478 17.8359 21.6208 18.1931 21.9351C18.1931 21.928 18.1931 21.9208 18.1931 21.9137C18.1931 21.4923 17.7145 21.1494 15.0002 21.1494C12.2859 21.1494 11.8074 21.4923 11.8074 21.9137C11.8074 21.9208 11.8074 21.928 11.8074 21.9351C12.1645 21.6208 13.0359 21.478 15.0002 21.478Z"
        fill="url(#paint9_linear_306_10814)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_306_10814"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.7516 10.3706) scale(13.7871 13.7871)"
        >
          <stop stopColor="#FFE030" />
          <stop offset="1" stopColor="#FFB92E" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_306_10814"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.7537 10.3706) scale(10.8469 10.8469)"
        >
          <stop stopColor="#FFEA5F" />
          <stop offset="1" stopColor="#FFBC47" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_306_10814"
          x1="9.7914"
          y1="13.0036"
          x2="9.64392"
          y2="11.4507"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#7A4400" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_306_10814"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.458 15.3356) rotate(-176.694) scale(2.05401 0.990991)"
        >
          <stop offset="0.00132565" stopColor="#7A4400" />
          <stop offset="1" stopColor="#643800" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_306_10814"
          x1="7.83695"
          y1="14.8434"
          x2="13.1284"
          y2="14.8434"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_306_10814"
          x1="20.2133"
          y1="13.0072"
          x2="20.3608"
          y2="11.4543"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#7A4400" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_306_10814"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.5466 15.3394) rotate(-3.30615) scale(2.05401 0.990991)"
        >
          <stop offset="0.00132565" stopColor="#7A4400" />
          <stop offset="1" stopColor="#643800" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_306_10814"
          x1="22.1673"
          y1="14.8469"
          x2="16.8758"
          y2="14.8469"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_306_10814"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.9987 21.911) rotate(-3.30619) scale(2.31989 1.23582)"
        >
          <stop offset="0.00132565" stopColor="#7A4400" />
          <stop offset="1" stopColor="#643800" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_306_10814"
          x1="15.0002"
          y1="21.1932"
          x2="15.0002"
          y2="24.0988"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PensiveFaceIcon;
