import cx from 'classnames';
import styles from './Login.module.scss';
import LogoIcon from '../logo-icon';
import ComponentButton from 'src/components/button';
import Checkbox from 'src/components/checkbox';
import i18n from 'src/locales';
import ButtonClick from 'src/components/button-click';
import { EyeCloseIcon, EyeIcon } from 'src/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { TextInputField, Button } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { isEmpty, isFunction, size, toString } from 'lodash';
import { withModalPromptSettings } from 'src/managers/modal';
import ForgotPassword from '../forgot-password';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';
import { useDebounce } from 'src/hooks';
import { withRouter } from 'react-router-dom';
import { isMobileView } from 'src/helpers/utils';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';

const Login = (props) => {
  const {
    toggleRememberLogin,
    setActiveModalDom,
    rememberLogin,
    showSignupPrompt,
    loginUser,
    closeModal,
    isThemeDarkMode,
    blockClosingOfModal,
    unBlockClosingOfModal,
  } = props;
  const [passwordError, setPasswordError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);

  const onSubmit = useDebounce(async () => {
    if (submitted) {
      return;
    }

    if (!password) {
      setPasswordError('Invalid password');

      if (size(usernameOrEmail) >= 4) {
        return;
      }
    }

    if (!usernameOrEmail || size(usernameOrEmail) < 5) {
      setUsernameError('Invalid username');
      return;
    }

    setErrorMessage('');
    setSubmitted(true);

    try {
      if (isFunction(blockClosingOfModal) && !isMobileView()) {
        blockClosingOfModal();
      }

      if (isFunction(loginUser)) {
        await loginUser(
          usernameOrEmail,
          password,
          () => {
            const pathname = toString(window.location?.pathname || '');

            if (isFunction(closeModal)) {
              closeModal(true);
            }

            if (
              (isMobileView() ||
                // redirect if user is on this page
                pathname?.startsWith('/not-found') ||
                pathname?.startsWith('/documentation')) &&
              isFunction(props?.history?.push)
            ) {
              props.history.push('/user');
            }
          },
          () => {
            if (isCorrectEmailFormat(usernameOrEmail)) {
              setErrorMessage('Invalid email or password!');
            } else {
              setErrorMessage('Invalid username or password!');
            }
          }
        );
      }

      if (isFunction(unBlockClosingOfModal) && !isMobileView()) {
        unBlockClosingOfModal();
      }
    } catch {
    } finally {
      setSubmitted(false);
    }
  }, 200);

  const clearErrors = () => {
    setErrorMessage('');
    setPasswordError('');
    setUsernameError('');
  };

  const onSignup = () => {
    if (isMobileView() && isFunction(props?.history?.push)) {
      props.history.push('/sign-up');
      return;
    }

    if (isFunction(showSignupPrompt)) {
      showSignupPrompt();
    }
  };

  useEffect(() => {
    const onKeyDown = (evt) => {
      if (!evt || submitted) {
        return;
      }

      if (
        !isEmpty(usernameOrEmail) &&
        !isEmpty(password) &&
        (evt.keyCode === 27 || evt.keyCode === 13) &&
        isFunction(onSubmit)
      ) {
        onSubmit();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onSubmit, submitted, usernameOrEmail, password]);

  return (
    <div className={cx(styles.flex_center_all, styles.login)}>
      <div className={styles.content}>
        <div className={cx(styles.logo, styles.flex_row_xy)}>
          <LogoIcon darkMode={isThemeDarkMode} />
        </div>
        <div
          className={cx(styles.message, {
            [styles.message_dark]: isThemeDarkMode,
          })}
        >
          <h2>{i18n('sign_in_message')} </h2>
        </div>
        <div
          className={cx(styles.username, styles.flex_row_xy, {
            [styles.username_dark]: isThemeDarkMode,
          })}
        >
          <TextInputField
            placeholder={i18n('common_username_or_email_placeholder')}
            label={i18n('sign_in_email_or_username')}
            onChange={(evt) => {
              setUsernameOrEmail(evt?.target?.value || '');
              clearErrors();
            }}
            value={usernameOrEmail}
            validationMessage={
              isEmpty(usernameError) || !usernameError ? false : usernameError
            }
          />
        </div>{' '}
        <div
          className={cx(styles.password, styles.flex_row_xy, {
            [styles.password_dark]: isThemeDarkMode,
          })}
        >
          <TextInputField
            type={passwordShow ? 'text' : 'password'}
            placeholder={i18n('sign_in_input_pw_placeholder')}
            label={i18n('password_title')}
            onChange={(evt) => {
              setPassword(evt?.target?.value || '');
              clearErrors();
            }}
            value={password}
            validationMessage={
              isEmpty(passwordError) || !passwordError ? false : passwordError
            }
          />

          <div className={cx(styles.flex_row_xy, styles.password_show)}>
            <ButtonClick
              onClick={() => {
                setPasswordShow(!passwordShow);
              }}
            >
              {passwordShow && <EyeCloseIcon />} {!passwordShow && <EyeIcon />}
            </ButtonClick>
          </div>
        </div>
        <div className={styles.actions}>
          <div
            className={cx(styles.remember_login, {
              [styles.remember_login_light]: !isThemeDarkMode,
              [styles.remember_login_dark]: isThemeDarkMode && !rememberLogin,
              [styles.remember_login_active_dark]:
                isThemeDarkMode && rememberLogin,
            })}
          >
            <Checkbox
              isThemeDarkMode={isThemeDarkMode}
              label={i18n('sign_in_remember_login')}
              value={rememberLogin}
              onChange={() => {
                if (isFunction(toggleRememberLogin)) {
                  toggleRememberLogin();
                }
              }}
            />
          </div>

          {!isEmpty(errorMessage) && (
            <div className={cx(styles.status)}>
              <div>
                <h5>{errorMessage}</h5>
              </div>
            </div>
          )}

          <ComponentButton
            isThemeDarkMode={isThemeDarkMode}
            label={i18n('sign_in_confirm_button')}
            variant={'primaryPurple'}
            onClick={onSubmit}
            loading={submitted}
            disabled={submitted}
            className={cx(styles.flex_center_all, styles.confirm)}
          />
          <div
            className={cx(styles.flex_row_xy, styles.forgot_password, {
              [styles.forgot_password_dark]: isThemeDarkMode,
            })}
          >
            <Button
              onClick={() => {
                if (isMobileView() && isFunction(props?.history?.push)) {
                  props.history.push('/forgot-password');
                  return;
                }

                if (isFunction(setActiveModalDom)) {
                  setActiveModalDom(<ForgotPassword />);
                }
              }}
              appearance="minimal"
            >
              Forgot Password?
            </Button>
          </div>
          <div
            className={cx(styles.flex_row_xy, styles.redirect_signup, {
              [styles.redirect_signup_dark]: isThemeDarkMode,
            })}
          >
            <p>
              Don't have an account? <span onClick={onSignup}> Sign Up</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withMobileToastSettings(withRouter(Login)))
);
