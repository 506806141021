import styles from './Landing.module.scss';
import cx from 'classnames';
import Header from '../layout/header';
import Footer from '../layout/footer';
import k from 'src/constants/k';
import i18n from 'src/locales';
import Features from './Features';
import FasterSearch from './FasterSearch';
import { isMobileView, isCapacitorMobileApp } from 'src/helpers/utils';
import { AdjustableAbsolutWrap } from '../layout/commons';
import { useEffect, useMemo, useRef } from 'react';
import { Button, ArrowRightIcon } from 'evergreen-ui';
import { clearAllBodyScrollLocks } from 'src/lib/bodyScrollLock.min';
import { Link, Redirect } from 'react-router-dom';
import ProductHunt from './ProductHunt';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withModalPromptSettings } from 'src/managers/modal';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';
import { useWindowScroll } from 'src/hooks';

const Landing = (props) => {
  const landingRef = useRef(null);
  const introRef = useRef(null);
  const { isLoggedIn, profileLoaded } = props;
  const userIsLoggedIn = useMemo(
    () => profileLoaded && isLoggedIn,
    [isLoggedIn, profileLoaded]
  );
  const { scrollToTop } = useWindowScroll();

  useEffect(() => {
    clearAllBodyScrollLocks();

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    scrollToTop();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hasViewport = !!window?.visualViewport;
    const onResize = () => {
      if (!introRef?.current) {
        return;
      }

      if (!isMobileView()) {
        return;
      }

      const h = hasViewport ? window.visualViewport.height : window.innerHeight;

      if (h < 540) {
        introRef.current.style.minHeight = '600px';
      } else {
        introRef.current.style.minHeight = `${h}px`;
      }
    };

    onResize();
    if (hasViewport) {
      window.visualViewport.addEventListener('resize', onResize, false);
    } else {
      window.addEventListener('resize', onResize, false);
    }

    return () => {
      if (hasViewport) {
        window.visualViewport.removeEventListener('resize', onResize, false);
      } else {
        window.removeEventListener('resize', onResize, false);
      }
    };
  }, []);

  if (isCapacitorMobileApp()) {
    return <Redirect to={'/user'} />;
  }

  return (
    <AdjustableAbsolutWrap>
      <div className={styles.wrapper}>
        <Header />
        <div ref={landingRef} className={styles.landing}>
          <div ref={introRef} className={cx(styles.flex_row_xy, styles.intro)}>
            <div className={styles.intro_background}>
              <img
                src={k.CHAMU_HOME_GRADIENT_MIN}
                alt={'Gradient background homepage'}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.intro_texts}>
                <h2>Sharing files and notes made easy.</h2>
              </div>
              <div className={cx(styles.flex_row_xy, styles.cta)}>
                <Button
                  appearance="minimal"
                  className={cx(styles.flex_row_xy, styles.create_account)}
                >
                  <p>
                    {isLoggedIn
                      ? i18n('home_get_started_dashboard')
                      : i18n('home_get_started_title')}
                  </p>
                  {!userIsLoggedIn && <Link to={'/pricing'} />}
                  {userIsLoggedIn && <Link to={'/user'} />}
                </Button>
                <div className={cx(styles.flex_row_xy, styles.watch_demo)}>
                  <a
                    className={styles.flex_row_xy}
                    href="https://getchamu.com/note/A003709?u=PvwL8nxMtvCdz97hMaxA" // "https://www.loom.com/share/d4406964ec174cb8af057b07ad294330"
                  >
                    Watch our demo
                    <ArrowRightIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ProductHunt />
          <FasterSearch />
          <Features />

          <div className={cx(styles.flex_row_xy, styles.bye_complicated)}>
            <div className={styles.bye_complicated_texts}>
              <h2>Say goodbye to complicated setups.</h2>
              <p>Start creating and sharing immediately.</p>
            </div>
            <Button
              className={cx(styles.bye_complicated_create_account, {
                [styles.hide_element]: isLoggedIn,
              })}
              appearance="minimal"
            >
              <p>{i18n('home_get_started_title')} </p> <Link to={'/sign-up'} />
            </Button>
            <div className={styles.illustration}>
              <img src={k.CHAMU_HOME_BEAR_SLACK} alt={'chamu bear'} />
            </div>
          </div>
        </div>
        <Footer> </Footer>
      </div>
    </AdjustableAbsolutWrap>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withMobileToastSettings(Landing))
);
