import cx from 'classnames';
import styles from './SidePanel.module.scss';
import LogoIcon from 'src/components/logo-icon';
import Search from 'src/modals/search';
import Tag from './Tag';
import AddTag from './AddTag';
import AvatarPremium from 'src/components/avatar-premium';
import TagsEmpty from 'src/components/tags-empty';
import HeaderUserOptions, {
  HeaderUserOptionsClassname,
} from 'src/pages/note-view/HeaderUserOptions';
import {
  SidePanelMenuIcon,
  SidePanelMenuExpandIcon,
  NotificationBellIcon,
  MoreDotIcon,
  ChevronArrowDownIcon,
} from 'src/icons';
import { Button, TextInput, SearchIcon, PlusIcon } from 'evergreen-ui';
import { getUserDisplayName } from 'src/helpers/utils';
import { toString, isFunction, head, isEmpty, last, filter } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar, Skeleton, Tooltip } from '@chakra-ui/react';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withNoteDataSettings } from 'src/managers/notes';
import { withModalPromptSettings } from 'src/managers/modal';
import k from 'src/constants/k';
import CreateSpace from 'src/modals/create-space';
import { isAllowedToCreateTags, isAllowedToEditTags } from 'src/helpers/spaces';

function isMobileView() {
  return window.innerWidth <= 800;
}

const SidePanel = (props) => {
  const sidePanelSpacesListClassName = 'sidePanelSpacesListClassNameUnique';
  const tagItemSelectClassname = 'tagItemSelectClassnameUnique';
  const {
    user,
    expand,
    togglePanel,
    profileLoaded,
    isThemeDarkMode,
    setActiveModalDom,
    userPersonalTags,

    clearTagsApplied,
    applyTagFilter,
    tagsFilterApplied,

    selectedSpace,
    selectSpace,
    userPersonalTagsFetching,
    userHasNewNotifications: newNotifs,
    userSpaces: spaces,
    userIsPremium: userPremiumSpaces,
    selectedSpaceId: spaceId,
  } = props;
  const spaceImgAvatarRef = useRef(null);
  const tagsListRef = useRef(null);
  const fullName = useMemo(() => getUserDisplayName(user), [user]);

  const space = useMemo(
    () => (!isEmpty(spaceId) && spaceId !== 'personal' ? selectedSpace : null),
    [spaceId, selectedSpace]
  );
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showUserSpacesList, setShowUserSpacesList] = useState(false);

  const isPersonalSpace = useMemo(
    () => !spaceId || spaceId === 'personal',
    [spaceId]
  );

  const spaceTags = useMemo(
    () => space?.tags || [],
    // eslint-disable-next-line
    [space, space?.tags]
  );

  const tags = useMemo(
    () => (isPersonalSpace ? userPersonalTags : spaceTags),
    [isPersonalSpace, userPersonalTags, spaceTags]
  );

  const allowedToAddTags = useMemo(() => {
    if (!space || spaceId === 'personal') {
      return true;
    }

    if (userPremiumSpaces) {
      return (
        !space?.archived &&
        (isAllowedToCreateTags(space) || isAllowedToEditTags(space))
      );
    }

    return false;
  }, [space, spaceId, userPremiumSpaces]);

  const allowedToEditTags = useMemo(() => {
    if (!space || spaceId === 'personal') {
      return true;
    }

    if (userPremiumSpaces) {
      const role = space?.role || [];
      return (
        role.includes(k.SPACES_ROLES_ENUM.editTag) ||
        role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin)
      );
    }

    return false;
  }, [space, spaceId, userPremiumSpaces]);

  const toggleExpand = () => {
    if (isFunction(togglePanel)) {
      togglePanel();
    }
  };

  const openSearch = () => {
    if (isFunction(setActiveModalDom)) {
      // setActiveModalDom();
      // open search modal
      setActiveModalDom(<Search />);
    }
  };

  /**
   * Update selected space icon
   */
  useEffect(() => {
    if (spaceId && space?.image) {
      if (spaceImgAvatarRef.current) {
        spaceImgAvatarRef.current.classList.remove(styles.hide_element);
      }

      spaceImgAvatarRef.current.src = space.image;
    } else {
      if (spaceImgAvatarRef.current) {
        spaceImgAvatarRef.current.classList.add(styles.hide_element);
      }
    }
  }, [spaceId, space?.image]);

  /**
   * Select space
   * Close spaces list on outside click
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (
        !isEmpty(evt?.target?.classList) &&
        evt?.target?.classList?.contains(sidePanelSpacesListClassName)
      ) {
        const targetSpaceId = toString(last(evt.target.classList));

        if (
          !isEmpty(targetSpaceId) &&
          targetSpaceId !== sidePanelSpacesListClassName
        ) {
          if (isFunction(selectSpace)) {
            selectSpace(targetSpaceId);
          }

          setShowUserSpacesList(false);
        }

        return;
      }

      if (showUserSpacesList) {
        setShowUserSpacesList(false);
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [spaceId, spaces, selectSpace, showUserSpacesList]);

  /**
   * Show spaces list
   * @returns
   */
  const toggleSpaceList = () => {
    if (!profileLoaded) {
      return;
    }

    if (!userPremiumSpaces) {
      if (showUserSpacesList) {
        setShowUserSpacesList(false);
      }

      return;
    }

    setShowUserSpacesList(!showUserSpacesList);
  };

  /**
   * On toggle/select filter
   */
  useEffect(() => {
    const dom = tagsListRef?.current;

    const onTagsListClick = async (evt) => {
      const target = evt?.target;

      if (
        !isEmpty(target?.classList) &&
        target?.classList?.contains(tagItemSelectClassname)
      ) {
        const targetTagId = last(target.classList);
        const tags = !isPersonalSpace ? spaceTags : userPersonalTags;

        if (!targetTagId || targetTagId === tagItemSelectClassname) {
          return;
        }

        if (!isEmpty(tags)) {
          const find = head(
            filter(tags, (tagInfo) => tagInfo?.id === targetTagId)
          );
          const toApplyTagId = find?.id;

          if (tagsFilterApplied.includes(targetTagId)) {
            if (isFunction(clearTagsApplied)) {
              clearTagsApplied();
            }
          } else if (!isEmpty(toApplyTagId)) {
            if (isFunction(applyTagFilter)) {
              applyTagFilter(toApplyTagId);
            }

            // fetch new list here new tag applied
          }
        } else {
          if (isFunction(clearTagsApplied)) {
            clearTagsApplied();
          }
        }
      }
    };

    if (dom) {
      dom.addEventListener('click', onTagsListClick, false);
    }

    return () => {
      if (dom) {
        dom.removeEventListener('click', onTagsListClick, false);
      }
    };
  }, [
    isPersonalSpace,
    spaceTags,
    userPersonalTags,
    spaces,
    spaceId,
    applyTagFilter,
    tagsFilterApplied,
    clearTagsApplied,
  ]);

  const promptCreateNewSpace = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<CreateSpace />);
    }
  };

  return (
    <div
      className={cx(styles.sidepanel, styles.flex_column_xy, {
        [styles.sidepanel_dark]: isThemeDarkMode,
      })}
    >
      <div className={cx(styles.flex_row_xy, styles.header)}>
        <div
          className={cx(styles.flex_row_xy, styles.icon, {
            [styles.hide_element]: !expand,
          })}
        >
          <LogoIcon darkMode={isThemeDarkMode} height={30} width={30} />
          <div
            className={cx(styles.flex_row_xy, styles.icon_link, styles.cover)}
          >
            <Link to={'/'} />
          </div>
        </div>
        <div
          className={cx(styles.flex_row_xy, styles.right, {
            [styles.right_shrink]: !expand,
          })}
        >
          <div>
            <Tooltip label="Notifications" className={styles.custom_tooltip}>
              <Button
                appearance="minimal"
                className={cx(styles.notif_bell, {
                  [styles.hide_element]: !expand,
                  [styles.notif_bell_dark]: isThemeDarkMode,
                })}
              >
                <NotificationBellIcon height={20} width={20} />
                {newNotifs && expand && (
                  <div
                    className={cx(styles.flex_row_xy, styles.notif_bell_new)}
                  >
                    <p>{'1'}</p>
                  </div>
                )}
                <div className={cx(styles.cover, styles.link)}>
                  <Link to={'/user/notifications'} />
                </div>
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip label="Minimize sidebar" className={styles.custom_tooltip}>
              <Button
                onClick={toggleExpand}
                appearance="minimal"
                className={cx(styles.toggle_expand, {
                  [styles.toggle_expand_dark]: isThemeDarkMode,
                })}
              >
                {expand ? (
                  <SidePanelMenuIcon height={20} width={20} />
                ) : (
                  <SidePanelMenuExpandIcon height={20} width={20} />
                )}
              </Button>
            </Tooltip>
          </div>

          <Button
            appearance="minimal"
            className={cx(styles.notif_bell, {
              [styles.notif_bell_dark]: isThemeDarkMode,
              [styles.hide_element]: expand,
            })}
          >
            <NotificationBellIcon height={20} width={20} />
            {newNotifs && !expand && (
              <div className={cx(styles.flex_row_xy, styles.notif_bell_new)}>
                <p>{'1'}</p>
              </div>
            )}

            <div className={cx(styles.cover, styles.link)}>
              <Link to={'/user/notifications'} />
            </div>
          </Button>
        </div>
      </div>
      <div
        className={cx(styles.search, {
          [styles.search_dark]: isThemeDarkMode,
          [styles.hide_element]: !expand,
        })}
      >
        <TextInput placeholder="Search" />
        <div
          className={cx(styles.flex_row_xy, styles.search_icon, {
            [styles.search_icon_dark]: isThemeDarkMode,
          })}
        >
          <SearchIcon height={20} width={20} />
        </div>
        <Button
          className={cx(styles.cover, styles.search_button, {
            [styles.search_button_dark]: isThemeDarkMode,
            [styles.search_button_light]: !isThemeDarkMode,
          })}
          appearance="minimal"
          onClick={openSearch}
        ></Button>
      </div>

      <div
        className={cx(styles.selected_space, {
          [styles.hide_element]: !expand,
          [styles.selected_space_dark]: isThemeDarkMode,
        })}
      >
        <Button
          appearance="minimal"
          className={cx(styles.flex_row_xy, sidePanelSpacesListClassName)}
          onClick={toggleSpaceList}
        >
          <div
            className={cx(
              styles.flex_row_xy,
              styles.space_icon,
              sidePanelSpacesListClassName
            )}
          >
            <img
              ref={spaceImgAvatarRef}
              className={styles.space_avatar}
              alt={'Selected space avatar'}
            />
            {(!space?.image || !spaceId) && (
              <div
                className={cx(styles.space_avatar_default, styles.flex_row_xy)}
              >
                <h5>{head(toString(space?.name || 'Personal Space'))}</h5>
              </div>
            )}
          </div>
          <div
            className={cx(styles.flex_row_xy, styles.space_name, {
              [styles.space_name_dark]: isThemeDarkMode,
              [styles.space_name_with_arrow]:
                userPremiumSpaces && profileLoaded,
              [styles.space_name_no_arrow]:
                !userPremiumSpaces || !profileLoaded,
            })}
          >
            <p>{`${space?.name || 'Personal Space'}${
              space?.archived ? ' (Archived)' : ''
            }`}</p>
            {userPremiumSpaces && profileLoaded ? (
              <div
                className={cx(styles.space_name_arrow, styles.flex_row_xy, {
                  [styles.space_name_arrow_dark]: isThemeDarkMode,
                  [styles.space_name_arrow_up]: showUserSpacesList,
                })}
              >
                <ChevronArrowDownIcon height={20} width={20} />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={cx(styles.cover, sidePanelSpacesListClassName)}></div>
        </Button>
        <div
          className={cx(
            styles.space_options,
            {
              [styles.space_options_dark]: isThemeDarkMode,
              [styles.hide_element]:
                !showUserSpacesList || !userPremiumSpaces || !user,
            },
            sidePanelSpacesListClassName
          )}
        >
          <Button
            className={cx(styles.flex_row_xy, styles.create_space, {
              [styles.create_space_dark]: isThemeDarkMode,
            })}
            onClick={promptCreateNewSpace}
            appearance="minimal"
          >
            <div className={styles.flex_row_xy}>
              <PlusIcon />
            </div>
            <p>Create new space </p>
          </Button>
          <ul className={sidePanelSpacesListClassName}>
            <li
              className={cx(
                styles.flex_row_xy,
                sidePanelSpacesListClassName,
                'personal'
              )}
              key={`sidePanelSpace${'personal'}`}
            >
              <Button className={styles.flex_row_xy} appearance="minimal">
                <div
                  className={cx(
                    styles.flex_row_xy,
                    styles.space_option_avatar,
                    styles.space_option_avatar_default
                  )}
                >
                  <p> {'P'} </p>
                </div>
                <p>{'Personal Space'}</p>
                <div
                  className={cx(
                    styles.cover,
                    sidePanelSpacesListClassName,
                    'personal'
                  )}
                ></div>
              </Button>
            </li>

            {spaces
              .filter((currentSpace) => !isEmpty(currentSpace?.name))
              .map((currentSpace) => {
                const currentSpaceName = toString(currentSpace?.name);
                const currentSpaceId =
                  currentSpace?.id || currentSpace?.space_id;
                const currentSpaceImage = currentSpace?.image;
                const currentSpaceInitial = head(currentSpaceName);

                return (
                  <li
                    className={cx(
                      styles.flex_row_xy,
                      sidePanelSpacesListClassName,
                      currentSpaceId
                    )}
                    key={`sidePanelSpace${currentSpaceId}`}
                  >
                    <Button className={styles.flex_row_xy} appearance="minimal">
                      <div
                        className={cx(
                          styles.flex_row_xy,
                          styles.space_option_avatar,
                          {
                            [styles.space_option_avatar_default]:
                              !currentSpaceImage,
                          }
                        )}
                      >
                        {!currentSpaceImage && <p> {currentSpaceInitial} </p>}
                        {currentSpaceImage && (
                          <img
                            src={currentSpaceImage}
                            alt={'space option avatar'}
                          />
                        )}
                      </div>
                      <p>{`${currentSpaceName}${
                        currentSpace?.archived ? ' (Archived)' : ''
                      }`}</p>
                      <div
                        className={cx(
                          styles.cover,
                          sidePanelSpacesListClassName,
                          currentSpaceId
                        )}
                      ></div>
                    </Button>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div
        className={cx(
          styles.tags,
          {
            [styles.tags_light]: !isThemeDarkMode,
            [styles.tags_dark]: isThemeDarkMode,
            [styles.hide_element]: !expand,
          },
          tagItemSelectClassname
        )}
      >
        <ul ref={tagsListRef}>
          {tags
            .filter((tagInfo) => !isEmpty(tagInfo?.id))
            .map((tagInfo) => {
              const tagId = tagInfo?.id;
              const selectedTagId = head(tagsFilterApplied);
              const isSelected = selectedTagId === tagId && !isEmpty(tagId);

              return (
                <li
                  className={cx(
                    styles.flex_row_xy,
                    tagItemSelectClassname,
                    tagId
                  )}
                  key={`sidePanelTag${tagId}`}
                >
                  <Tag
                    allowedToEditTags={allowedToEditTags}
                    isSelected={isSelected}
                    tagInfo={tagInfo}
                    tagSpaceId={spaceId}
                    space={space}
                    clearTagsApplied={() => {
                      if (isFunction(clearTagsApplied)) {
                        clearTagsApplied();
                      }
                    }}
                  />
                </li>
              );
            })}
        </ul>
        {isEmpty(tags) && allowedToAddTags && !userPersonalTagsFetching ? (
          <TagsEmpty />
        ) : (
          <></>
        )}
        {userPersonalTagsFetching && isEmpty(tags) && (
          <div
            className={cx(styles.flex_row_xy, styles.loading, {
              [styles.loading_dark]: isThemeDarkMode,
            })}
          >
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'170px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
              />{' '}
            </div>
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'100%'}
                marginTop={'36px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
              />{' '}
            </div>
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'60%'}
                marginTop={'36px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
              />
            </div>
          </div>
        )}
        {allowedToAddTags && (!isEmpty(tags) || !userPersonalTagsFetching) && (
          <AddTag spaceId={spaceId} space={space} />
        )}
      </div>
      <div
        className={cx(styles.flex_row_xy, styles.user_options, {
          [styles.user_options_shrink]: !expand,
        })}
      >
        <div
          className={cx(styles.user_avatar, styles.flex_row_xy, {
            [styles.hide_element]: !expand,
          })}
        >
          {profileLoaded && fullName ? (
            <>
              <div className={cx(styles.flex_row_xy)}>
                <Avatar
                  height={'36px'}
                  width={'36px'}
                  src={user?.image}
                  name={fullName}
                  backgroundColor={'#000'}
                />
                {userPremiumSpaces ? <AvatarPremium /> : <></>}
              </div>
              <p>{fullName}</p>
            </>
          ) : (
            <div className={cx(styles.flex_row_xy, styles.user_avatar_loading)}>
              <p>{'. . .'}</p>
            </div>
          )}
        </div>
        <div className={styles.more}>
          <Button
            appearance="minimal"
            className={cx(
              styles.flex_row_xy,
              styles.more_button,
              { [styles.more_button_dark]: isThemeDarkMode },
              HeaderUserOptionsClassname
            )}
            onClick={() => {
              if (isMobileView()) {
                return;
              }

              setShowUserOptions(!showUserOptions);
            }}
          >
            <MoreDotIcon />
            <div className={cx(styles.cover, HeaderUserOptionsClassname)}></div>
          </Button>
          {showUserOptions && (
            <HeaderUserOptions
              className={cx(styles.more_options, {
                [styles.more_options_shrink]: !expand,
              })}
              close={() => {
                setShowUserOptions(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(
    withNoteDataSettings(withSpacesAndUserSettings(withRouter(SidePanel)))
  )
);
