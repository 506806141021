import cx from 'classnames';
import styles from './TagUnit.module.scss';
import { isEmpty, toString } from 'lodash';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { CheckIcon } from 'src/icons';

const TagUnit = (props) => {
  const { tag, isActive, isThemeDarkMode } = props;
  const tagName = toString(tag?.name || tag?.tag);
  const spaceName = tag?.spaceName;

  return (
    <div
      className={cx(styles.tag_unit, styles.flex_row_xy, {
        [styles.tag_unit_active]: isActive,
        [styles.tag_unit_dark]: isThemeDarkMode,
        [styles.tag_unit_dark_active]: isThemeDarkMode && isActive,
      })}
    >
      {isActive && (
        <div className={styles.flex_row_xy}>
          <CheckIcon height={16} width={16} />
        </div>
      )}
      <p> {`${tagName}${!isEmpty(spaceName) ? ` (${spaceName})` : ''}`}</p>
    </div>
  );
};

export default withUserDataAndProfileSettings(TagUnit);
