import cx from 'classnames';
import styles from './ModalWrapper.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { isFunction, isNil, isString } from 'lodash';
import { withUserDataAndProfileSettings } from '../profile';

const ModalWrapper = (props) => {
  const {
    dom,
    domAction,
    children,
    domTitle,
    modalActive,
    closeModal,
    blockClosing,
    profileLoaded,
    isThemeDarkMode,
  } = props;
  const isOpen = profileLoaded && (modalActive || blockClosing);

  const onClose = () => {
    if (blockClosing) {
      return;
    }

    isFunction(closeModal) && closeModal();
  };

  return (
    <>
      {children}
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        {...(blockClosing && { closeOnOverlayClick: false })}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={'520px'}
          {...(isThemeDarkMode && { background: '#13171c' })}
          {...(window.innerWidth < 900 && {
            width: window.innerWidth > 550 ? '520px' : '95vw',
            maxWidth: window.innerWidth > 550 ? '520px' : '95vw',
          })}
        >
          <ModalHeader className={styles.modal_header}>
            {isString(domTitle) ? <h5>{domTitle} </h5> : domTitle || <></>}
          </ModalHeader>

          <ModalCloseButton
            className={cx({ [styles.modal_close_dark]: isThemeDarkMode })}
          />
          <ModalBody>{dom || <></>} </ModalBody>
          <ModalFooter>{!isNil(domAction) ? domAction : <></>}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default withUserDataAndProfileSettings(ModalWrapper);
