import { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './commons.module.scss';
import { fromEvent } from 'rxjs';

export const AdjustableAbsolutWrap = (props) => {
  return (
    <div
      style={{
        padding: `${props.paddingBottom > 0 ? props.paddingBottom : 0}px`,
      }}
      className={cx(styles.adjustable_absolute)}
    >
      {props.children}
    </div>
  );
};

export const WrapFitWithMinHeight = (props) => {
  const wrapREF = useRef(null);

  useEffect(() => {
    let localWindowResizeSubscriber = null;

    if (wrapREF && wrapREF.current) {
      if (window.screen.availHeight <= props.minHeight) {
        wrapREF.current.style.minHeight = `${props.minHeight}px`;
      } else {
        wrapREF.current.style.minHeight = `${window.screen.availHeight - 90}px`; // subtract header height
      }

      localWindowResizeSubscriber = fromEvent(window, 'resize').subscribe(
        () => {
          const { screen } = window;
          const { availHeight } = screen;
          if (wrapREF && wrapREF.current) {
            if (availHeight <= props.minHeight) {
              wrapREF.current.style.minHeight = `${props.minHeight}px`;
            } else {
              wrapREF.current.style.minHeight = `${availHeight - 90}px`; // subtract header height
            }
          }
        }
      );
    }

    return () => {
      if (localWindowResizeSubscriber) {
        localWindowResizeSubscriber.unsubscribe();
      }
    };

    //eslint-disable-next-line
  }, [wrapREF]);

  return (
    <div
      ref={wrapREF}
      className={cx({ [props.className]: !!props.className })}
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      {props.children}
    </div>
  );
};
