import cx from 'classnames';
import styles from './SpaceSelected.module.scss';
import ButtonClick from 'src/components/button-click';
import i18n from 'src/locales';
import k from 'src/constants/k';
import CustomButton from 'src/components/button';
import SpaceMembers from 'src/modals/spaces/members';
// import SpaceSettings from 'src/modals/spaces/settings';
import { UserAddIcon, SettingsIcon } from 'src/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withNoteViewContext } from 'src/pages/note-view/Context';
import { head, isEmpty, isFunction, size, toLower, toString } from 'lodash';
import { withRouter } from 'react-router-dom';
import { getSpaceNameInitials } from 'src/helpers/utils';
import { PencilEditIcon as EditIcon } from 'src/icons';
import {
  Button,
  TextInput,
  IconButton,
  ExportIcon,
  TextInputField,
} from 'evergreen-ui';
import { withModalPromptSettings } from 'src/managers/modal';
import {
  isAllowedToEditSpaceInfo,
  isMemberAdmin,
  isUserCreator,
} from 'src/helpers/spaces';
import { useToast } from '@chakra-ui/react';
import {
  getImageBase64,
  isTypeJpeg,
  isTypePng,
  scaleImageToJpeg,
} from 'src/helpers/images';
import { base64ToBlob } from 'src/lib/ImageFile';
import {
  editSpaceInfoRequest,
  uploadSpaceImageFile,
} from 'src/managers/api/spaces';
import { useAuth } from 'src/hooks';

const SpaceSelected = (props) => {
  const spaceAvatarFileInputId = 'spaceAvatarFileInput1';

  const {
    selectedSpace,
    updateSpaceInfoProps,
    isThemeDarkMode,
    setActiveModalDom,
    selectedSpaceId,
  } = props;

  const withSpace = useMemo(
    () => !isEmpty(selectedSpace) && selectedSpaceId !== 'personal',
    [selectedSpaceId, selectedSpace]
  );
  const spaceBioRef = useRef(null);
  const avatarImagePlaceholderRef = useRef(null);
  const spaceImageRef = useRef(null);
  const spaceTitleRef = useRef(null);
  const [editingSpaceTitle, setEditingSpaceTitle] = useState(false);
  const [editingSpaceBio, setEditingSpaceBio] = useState(false);
  const [spaceTitleInput, setSpaceTitleInput] = useState(
    selectedSpace?.name || ''
  );
  const [uploadingImage, setUploadingImage] = useState(false);
  const [spaceBioInput, setSpaceBioInput] = useState(selectedSpace?.bio || '');
  const [spaceTitleInputError, setSpaceTitleInputError] = useState('');

  const space = useMemo(() => selectedSpace, [selectedSpace]);
  // const spaceRefId = useMemo(() => getDataRefIdValue(space), [space]);
  const spaceId = useMemo(() => selectedSpaceId, [selectedSpaceId]);
  const spaceName = useMemo(() => space?.name || '', [space]);
  const spaceBio = useMemo(
    () => (!space?.bio || isEmpty(space?.bio) ? '' : space?.bio),
    [space]
  );

  const isUserSpaceCreator = useMemo(() => isUserCreator(space), [space]);

  const isUserSpaceAdmin = useMemo(() => isMemberAdmin(space), [space]);

  const canEditSpaceInfo = useMemo(
    () =>
      !isEmpty(selectedSpace) &&
      !selectedSpace?.archived &&
      (isAllowedToEditSpaceInfo(selectedSpace) || isUserSpaceCreator),
    [selectedSpace, isUserSpaceCreator]
  );

  const spaceImage = useMemo(() => space?.image, [space]);

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({ position: 'top', id: 'chakraToast' });

  const onSpaceInfoEdit = (type) => {
    if (type === 'title') {
      setEditingSpaceTitle(true);
      setEditingSpaceBio(false);
    } else if (type === 'bio') {
      setEditingSpaceBio(true);
      setEditingSpaceTitle(false);
    }
  };

  useEffect(() => {
    if (editingSpaceTitle) {
      if (spaceTitleRef?.current) {
        const domInput = spaceTitleRef?.current.querySelector('input');

        if (domInput?.focus) {
          domInput.focus();
        }
      }
    }
  }, [editingSpaceTitle]);

  useEffect(() => {
    if (editingSpaceBio) {
      if (spaceBioRef?.current) {
        const domInput = spaceBioRef?.current.querySelector('input');

        if (domInput?.focus) {
          domInput.focus();
        }
      }
    }
  }, [editingSpaceBio]);

  const onSpaceTitleInput = (evt) => {
    setSpaceTitleInput(toString(evt?.target?.value || ''));
  };

  const onSpaceBioInput = (evt) => {
    setSpaceBioInput(toString(evt?.target?.value || ''));
  };

  const createNewNote = () => {
    if (isFunction(props?.history?.push)) {
      props?.history.push('/note');
    }
  };

  // update space image/avatar
  const sendUpload = async (file) => {
    try {
      if (uploadingImage) {
        toast({
          title: 'Still uploading, please wait.',
          status: 'info',
          duration: 1_500,
        });

        return;
      }

      setUploadingImage(true);
      const fileName = file?.name || '';
      const fileSize = file?.size || '';
      const fileType = file?.type || '';
      let blob = file || null;

      if (
        fileSize > 9_240_000 ||
        // convert file to jpeg if not yet a jpeg
        (!isTypeJpeg(fileType) && !isTypePng(fileType))
      ) {
        const b64 = await getImageBase64(file);
        const { value: newBase64Value, err: scaleError } =
          await scaleImageToJpeg(b64, 'image/jpeg', 0.9);

        if (scaleError) {
          toast({
            title: 'Invalid image. Try again.',
            status: 'warning',
            position: 'top',
            duration: 1_500,
          });
          setUploadingImage(false);
          return;
        }

        if (newBase64Value && !scaleError) {
          blob = base64ToBlob(newBase64Value, 'image/jpeg');
        }
      }

      const headers = getAuthenticatedHeaders();

      toast({
        status: 'info',
        title: 'Uploading..',
        duration: 1_500,
        isClosable: true,
      });

      const { errorMessage, image, networkError } = await uploadSpaceImageFile(
        blob,
        fileName,
        spaceId,
        headers
      );

      const sanitizedErrorMessage = toLower(toString(errorMessage));
      const dom = spaceImageRef?.current;

      if (image) {
        toast({
          title: 'Successfully updated image.',
          status: 'success',
          duration: 1_500,
        });

        if (isFunction(updateSpaceInfoProps)) {
          updateSpaceInfoProps(spaceId, { image });

          if (dom?.setAttribute) {
            dom.setAttribute('src', image);
          }
        }

        return;
      }

      if (
        sanitizedErrorMessage?.includes('max') &&
        sanitizedErrorMessage?.includes('size')
      ) {
        toast({
          status: 'error',
          title: 'File size exceeds limit.',
          isClosable: true,
          duration: 2_500,
        });
      } else if (networkError) {
        toast({
          status: 'error',
          title: 'Check your network connection.',
          isClosable: true,
          duration: 2_500,
        });
      } else {
        toast({
          status: 'error',
          title: 'Something went wrong. Try again.',
          isClosable: true,
          duration: 2_500,
        });
      }
    } catch (err) {
      console.log('sendupload space image err', err?.message);
    } finally {
      setUploadingImage(false);
    }
  };

  const onSpaceAvatarUpload = () => {
    let input = document.createElement('input');
    const prevInput = document.getElementById(spaceAvatarFileInputId);

    if (prevInput) {
      prevInput.remove();
    }

    const onImageSelectHandler = function () {
      const files = this.files;
      const file = head(files);
      const fileType = file?.type;
      const fileSize = file?.size;

      if (!fileType) {
        toast({
          status: 'error',
          title: 'Invalid image file.',
          isClosable: true,
          duration: 2_500,
        });

        return;
      }

      if (fileSize > 12_000_000) {
        toast({
          status: 'error',
          title: 'File size exceeds limit.',
          isClosable: true,
          duration: 2_500,
        });

        return;
      }

      return sendUpload(file);
    };

    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/avif'
    );

    input.onchange = onImageSelectHandler;
    input.setAttribute('id', spaceAvatarFileInputId);
    input.setAttribute('multiple', true);
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'file');
    document.body.appendChild(input);
    input.click();
  };

  /**
   * Set space image on change
   */
  useEffect(() => {
    const dom = spaceImageRef?.current;

    if (dom && !isEmpty(spaceImage)) {
      dom.setAttribute('src', spaceImage);
    }
  }, [spaceImage]);

  return (
    <div
      className={cx(styles.space_selected, styles.flex_row_xy, {
        [styles.hide_element]: !withSpace,
      })}
    >
      <div className={cx(styles.flex_row_xy, styles.space_info)}>
        <div className={styles.space_avatar}>
          {!space.image && (
            <div className={styles.initials}>
              <h4>{getSpaceNameInitials(toString(space?.name || ''))}</h4>
            </div>
          )}
          {space?.image && (
            <div className={cx(styles.space_avatar_image, styles.flex_row_xy)}>
              <div
                className={cx(
                  styles.space_avatar_image_placeholder,
                  styles.flex_row_xy
                )}
                ref={avatarImagePlaceholderRef}
              >
                <p>{'...'}</p>{' '}
              </div>
              <img alt="space avatar" ref={spaceImageRef} />
            </div>
          )}
          <div
            className={cx(styles.upload_avatar, styles.flex_row_xy, {
              [styles.hide_element]: !canEditSpaceInfo,
            })}
          >
            <ButtonClick
              className={cx(styles.flex_row_xy, styles.upload_ic)}
              onClick={() => onSpaceAvatarUpload()}
            >
              <ExportIcon />
            </ButtonClick>
          </div>
        </div>
        <div className={styles.space_info_texts}>
          <div
            className={cx(styles.space_name, {
              [styles.space_name_dark]: isThemeDarkMode,
            })}
          >
            {!editingSpaceTitle && (
              <div
                className={cx(styles.to_edit, {
                  [styles.to_edit_dark]: isThemeDarkMode,
                })}
              >
                <TextInput
                  className={styles.read_only}
                  readOnly
                  value={spaceTitleInput || spaceName}
                />
                <div
                  className={cx(styles.action, {
                    [styles.hide_element]: !canEditSpaceInfo,
                  })}
                >
                  <IconButton
                    icon={EditIcon}
                    appearance={'minimal'}
                    className={styles.action_button}
                    onClick={() => {
                      onSpaceInfoEdit('title');
                    }}
                  />
                </div>
              </div>
            )}

            <div
              className={cx(styles.editing, {
                [styles.editing_hidden]: !editingSpaceTitle,
              })}
              ref={spaceTitleRef}
            >
              <TextInputField
                label={''}
                className={styles.not_read_only}
                value={spaceTitleInput}
                onChange={onSpaceTitleInput}
                onBlur={async () => {
                  // save space title

                  if (!editingSpaceTitle) {
                    return;
                  }

                  if (!space) {
                    setSpaceTitleInputError('');
                    setSpaceTitleInput('');
                    setEditingSpaceTitle(false);
                  } else if (
                    size(spaceTitleInput) <
                      k.SPACE_TITLE_PROPERTIES.minLength ||
                    spaceTitleInputError
                  ) {
                    setSpaceTitleInputError('');
                    setSpaceTitleInput(space?.name || '');
                    setEditingSpaceTitle(false);
                  }

                  const oldName = toString(space?.name || '');

                  if (oldName === spaceTitleInput) {
                    setSpaceTitleInputError('');
                    setEditingSpaceTitle(false);

                    return;
                  } else {
                    // update space props
                    setEditingSpaceTitle(false);
                  }

                  if (isFunction(updateSpaceInfoProps)) {
                    updateSpaceInfoProps(spaceId, { name: spaceTitleInput });
                  }

                  await editSpaceInfoRequest(
                    spaceId,
                    { name: spaceTitleInput },
                    getAuthenticatedHeaders()
                  );
                }}
                validationMessage={
                  isEmpty(spaceTitleInputError) || !spaceTitleInputError
                    ? false
                    : spaceTitleInputError
                }
                maxLength={k.SPACE_TITLE_PROPERTIES.maxLength}
              />
            </div>
          </div>
          <div
            className={cx(styles.space_bio, {
              [styles.space_bio_dark]: isThemeDarkMode,
            })}
          >
            {!editingSpaceBio && (
              <div
                className={cx(styles.to_edit, {
                  [styles.to_edit_dark]: isThemeDarkMode,
                })}
              >
                <TextInput
                  className={styles.read_only}
                  readOnly
                  value={size(spaceBioInput) > 2 ? spaceBioInput : spaceBio}
                  placeholder={i18n('user_dashboard_chamu_space_edit_bio')}
                />
                <div
                  className={cx(styles.action, {
                    [styles.hide_element]: !canEditSpaceInfo,
                  })}
                >
                  <IconButton
                    icon={EditIcon}
                    appearance={'minimal'}
                    className={styles.action_button}
                    onClick={() => {
                      onSpaceInfoEdit('bio');
                    }}
                  />
                </div>
                {/* <MessageHover
                  className={styles.to_edit_tip}
                  message={`${space.bio || ''}`}
                /> */}
              </div>
            )}

            <div
              className={cx(styles.editing, {
                [styles.editing_hidden]: !editingSpaceBio,
              })}
              ref={spaceBioRef}
            >
              <TextInputField
                label={''}
                className={styles.not_read_only}
                value={spaceBioInput}
                onChange={onSpaceBioInput}
                placeholder={i18n('user_dashboard_chamu_space_edit_bio')}
                onBlur={async () => {
                  const oldBio = toString(space?.bio || '');

                  if (!editingSpaceBio) {
                    return;
                  }

                  if (!space) {
                    setSpaceBioInput('');
                    setEditingSpaceBio(false);
                    return;
                  }

                  if (oldBio === spaceBioInput) {
                    setEditingSpaceBio(false);
                    return;
                  } else {
                    // update space bio props

                    space.bio = spaceBioInput;
                  }

                  const newProps = { bio: spaceBioInput };

                  if (isFunction(updateSpaceInfoProps)) {
                    updateSpaceInfoProps(spaceId, newProps);
                  }

                  await editSpaceInfoRequest(
                    spaceId,
                    newProps,
                    getAuthenticatedHeaders()
                  );
                }}
                maxLength={k.SPACE_BIO_PROPERTIES.maxLength}
              />
            </div>
          </div>
        </div>
        <div className={cx(styles.flex_row_xy, styles.space_info_actions)}>
          <div
            className={cx(styles.members, {
              [styles.members_dark]: isThemeDarkMode,
            })}
          >
            <Button
              appearance="minimal"
              onClick={() => {
                if (isFunction(setActiveModalDom)) {
                  setActiveModalDom(<SpaceMembers />);
                }
              }}
            >
              <UserAddIcon height={20} width={20} />
            </Button>
          </div>
          <div
            className={cx(styles.settings, {
              [styles.hide_element]:
                (!isUserSpaceAdmin && !isUserSpaceCreator) ||
                true ||
                selectedSpace?.archived, // hide settings button for now
              [styles.settings_dark]: isThemeDarkMode,
            })}
          >
            <Button
              appearance="minimal"
              onClick={() => {
                // if (isFunction(setActiveModalDom)) {
                //   setActiveModalDom(<SpaceSettings />);
                // }
              }}
            >
              <SettingsIcon height={20} width={20} />
            </Button>
          </div>
          <div className={styles.create}>
            <CustomButton
              className={styles.create_button}
              label={i18n('common_create')}
              variant={'primaryBlue'}
              onClick={createNewNote}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(
    withSpacesAndUserSettings(withNoteViewContext(withRouter(SpaceSelected)))
  )
);
