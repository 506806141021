import styles from '../Docs.module.scss';
import innerStyles from './ChamuApi.module.scss';

const ChamuApi = () => {
  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{'Chamu API'}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br /> <br /> <br />
        <p>
          See more of our progress from our
          <a
            href="https://github.com/officialrobert/chamu-js"
            target="_blank"
            rel="noreferrer"
          >
            Github page.
          </a>
        </p>
      </div>
    </div>
  );
};

export default ChamuApi;
