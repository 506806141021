import cx from 'classnames';
import styles from './Toast.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty, isFunction } from 'lodash';
import { IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { clearAllBodyScrollLocks } from 'src/lib/bodyScrollLock.min';
import { isIosMobile, timeout } from 'src/helpers/utils';
import { useWindowResize } from 'src/hooks';
import { withUserDataAndProfileSettings } from '../profile';

const Toast = (props) => {
  const { close, dom, isThemeDarkMode, blockClosingMobileToast } = props;
  const containerDom = useRef(null);
  const [show, setShow] = useState(true);

  const onClose = useCallback(async () => {
    if (blockClosingMobileToast) {
      return;
    }

    setShow(false);
    await timeout(250);

    if (isFunction(close)) {
      close();
    }
  }, [close, blockClosingMobileToast]);

  const { availableHeight } = useWindowResize(props);

  useEffect(() => {
    clearAllBodyScrollLocks();

    return clearAllBodyScrollLocks;
  }, []);

  /**
   * Apply container height
   */
  useEffect(() => {
    const dom = containerDom?.current;

    if (dom) {
      const newH = `${availableHeight}px`;
      dom.style.height = newH;

      if (isIosMobile()) {
        if (window?.scrollY) {
          window.scrollTo(0, 0);
        }
      }
    }
  }, [availableHeight]);

  /**
   * Close on outside click/press
   */
  useEffect(() => {
    const onClick = (evt) => {
      const target = evt?.target;

      if (
        !isEmpty(target?.classList) &&
        target?.classList?.contains(styles.toast)
      ) {
        onClose();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [show, close, onClose]);

  return (
    <div ref={containerDom} className={cx(styles.toast, styles.flex_row_xy)}>
      <div
        className={cx(styles.content, {
          [styles.show]: show,
          [styles.hide]: !show,
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        <div className={cx(styles.flex_row_xy, styles.header)}>
          <IconButton
            className={cx(styles.close, {
              [styles.close_dark]: isThemeDarkMode,
            })}
            variant="solid"
            background="transparent"
            aria-label="Close toast"
            icon={<CloseIcon height={3} width={3} />}
            onClick={() => onClose()}
          />
        </div>
        <div
          className={cx(styles.content_dom, {
            [styles.content_dom_dark]: isThemeDarkMode,
          })}
        >
          {dom || null}
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(Toast);
