import { useState } from 'react';
import cx from 'classnames';
import styles from './GifPreview.module.scss';
import i18n from 'src/locales';
import { isFunction } from 'src/helpers/utils';
import { IconButton, CrossIcon, MediaIcon } from 'evergreen-ui';

const GifPreview = ({
  gif = '',
  isThemeDarkMode = false,
  remove = true,
  id = '',
  onRemove,
}) => {
  const [loadError, setLoadError] = useState(false);

  const onLoadError = () => {
    setLoadError(true);
  };

  return (
    <div className={styles.gif_preview}>
      {gif && (
        <img
          className={cx({ [styles.invi]: loadError })}
          src={gif}
          alt={`gif from comment input:${id}`}
          onError={onLoadError}
        />
      )}
      {loadError && (
        <div
          className={cx(styles.error, styles.flex_row_xy, {
            [styles.error_dark]: isThemeDarkMode,
          })}
        >
          <div>
            <MediaIcon />
          </div>
          <h5>{i18n('task_view_comments_error_gif_preview')}</h5>
        </div>
      )}
      {remove && (
        <IconButton
          icon={CrossIcon}
          appearance="minimal"
          className={styles.remove_gif}
          onClick={() => {
            if (isFunction(onRemove)) {
              onRemove();
            }
          }}
        />
      )}
    </div>
  );
};

export default GifPreview;
