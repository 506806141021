import cx from 'classnames';
import styles from './Features.module.scss';
import k from 'src/constants/k';

const Features = () => {
  return (
    <div className={cx(styles.flex_row_xy, styles.landing_features)}>
      <div className={cx(styles.flex_row_xy, styles.landing_features_raw)}>
        <div className={cx(styles.flex_row_xy, styles.features_title)}>
          <h3>Features</h3>
        </div>
        <div className={styles.content}>
          <ul className={cx(styles.flex_row_xy, styles.first)}>
            <li>
              <div className={styles.illustration}>
                <img
                  src={k.HOMEPAGE_FEATURES_LINK_ILLUSTRATION.createNotes}
                  alt={'feature create notes'}
                />
                <div className={styles.cover}></div>
              </div>
              <h3 className={styles.title}>Create notes and tasks</h3>
              <p className={styles.message}>
                Our simple and easy to use editor allows versatility in what you
                create. Make drafts, notes, and lists (or all of them in one),
                and we got you covered.
              </p>
            </li>
            <li>
              <div className={styles.illustration}>
                <img
                  src={k.HOMEPAGE_FEATURES_LINK_ILLUSTRATION.tags}
                  alt={'feature tags'}
                />
                <div className={styles.cover}></div>
              </div>
              <h3 className={styles.title}>Organize through tags</h3>
              <p className={styles.message}>
                Make it easy to prioritize and categorize using tags. Add tags
                in order to easily navigate from 10 to 1000 notes and tasks.
              </p>
            </li>
            <li>
              <div className={styles.illustration}>
                <img
                  src={k.HOMEPAGE_FEATURES_LINK_ILLUSTRATION.files}
                  alt={'feature upload files'}
                />
                <div className={styles.cover}></div>
              </div>
              <h3 className={styles.title}>Upload files and documents</h3>
              <p className={styles.message}>
                Upload an unlimited number multimedia files and documents to
                support and give more context to your notes and tasks.{' '}
              </p>
            </li>
          </ul>
          <ul className={cx(styles.flex_row_xy, styles.second)}>
            <li>
              <div className={styles.illustration}>
                {' '}
                <img
                  src={k.HOMEPAGE_FEATURES_LINK_ILLUSTRATION.comments}
                  alt={'feature comments'}
                />{' '}
                <div className={styles.cover}></div>
              </div>
              <h3 className={styles.title}>Real time comments</h3>
              <p className={styles.message}>
                Have in depth discussions right in the comment section due to
                our real-time comments.
              </p>
            </li>
            <li>
              <div className={styles.illustration}>
                <img
                  src={k.HOMEPAGE_FEATURES_LINK_ILLUSTRATION.search}
                  alt={'feature search'}
                />{' '}
                <div className={styles.cover}></div>
              </div>
              <h3 className={styles.title}>Swift and powerful search</h3>
              <p className={styles.message}>
                Search through a great deal of notes and tasks in a few seconds.
              </p>
            </li>
            <li>
              <div className={styles.illustration}>
                <img
                  src={k.HOMEPAGE_FEATURES_LINK_ILLUSTRATION.share}
                  alt={'feature share notes'}
                />{' '}
                <div className={styles.cover}></div>
              </div>
              <h3 className={styles.title}>Share instantly, anywhere</h3>
              <p className={styles.message}>
                Share notes easily for better planning and collaboration.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Features;
