import cx from 'classnames';
import styles from './BottomBar.module.scss';
import Search from 'src/modals/search';
import AvatarPremium from 'src/components/avatar-premium';
import { withNoteViewContext } from 'src/pages/note-view/Context';
import { useMemo, useRef } from 'react';
import { getUserDisplayName } from 'src/helpers/utils';
import { isFunction } from 'lodash';
import { MobileDashboardView } from 'src/types';
import { SpacesRocketIcon, SearchIcon, HomeIcon, MoreDotIcon } from 'src/icons';
import { PlusIcon, Button, IconButton } from 'evergreen-ui';
import { Link, withRouter } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar } from '@chakra-ui/react';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withModalPromptSettings } from 'src/managers/modal';

const BottomBar = (props) => {
  const {
    user,
    profileLoaded,
    mobileDashboardView,
    isThemeDarkMode,
    setMobileDashboardView,
    setActiveModalDom,
    userIsPremium: userPremiumSpaces,
  } = props;
  const userImage = useMemo(() => user?.image, [user]);
  const homeViewButtonRef = useRef(null);
  const spacesViewButtonRef = useRef(null);

  const switchToHomeView = () => {
    if (isFunction(setMobileDashboardView)) {
      setMobileDashboardView(MobileDashboardView.home);
    }
  };

  const switchToSpacesView = () => {
    if (isFunction(setMobileDashboardView)) {
      setMobileDashboardView(MobileDashboardView.spaces);
    }
  };

  const openSearch = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<Search />);
    }
  };

  return (
    <div
      className={cx(styles.bottom_bar, styles.flex_row_xy, {
        [styles.bottom_bar_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(styles.home, {
          [styles.home_inactive]:
            mobileDashboardView !== MobileDashboardView.home &&
            !isThemeDarkMode,
          [styles.home_inactive_dark]:
            mobileDashboardView !== MobileDashboardView.home && isThemeDarkMode,
          [styles.home_active]:
            mobileDashboardView === MobileDashboardView.home &&
            !isThemeDarkMode,
          [styles.home_active_dark]:
            mobileDashboardView === MobileDashboardView.home && isThemeDarkMode,
        })}
      >
        <IconButton
          ref={homeViewButtonRef}
          onClick={switchToHomeView}
          icon={HomeIcon}
          appearance="minimal"
        />
        <p>Home</p>
      </div>
      <div
        className={cx(styles.spaces, {
          [styles.spaces_inactive]:
            mobileDashboardView !== MobileDashboardView.spaces &&
            !isThemeDarkMode,
          [styles.spaces_inactive_dark]:
            mobileDashboardView !== MobileDashboardView.spaces &&
            isThemeDarkMode,
          [styles.spaces_active]:
            mobileDashboardView === MobileDashboardView.spaces &&
            !isThemeDarkMode,
          [styles.spaces_active_dark]:
            mobileDashboardView === MobileDashboardView.spaces &&
            isThemeDarkMode,
        })}
      >
        <IconButton
          ref={spacesViewButtonRef}
          onClick={switchToSpacesView}
          icon={SpacesRocketIcon}
          appearance="minimal"
        />
        <p>Spaces</p>
      </div>

      <div
        className={cx(styles.search, styles.flex_row_xy, {
          [styles.search_inactive]: !isThemeDarkMode,
          [styles.search_inactive_dark]: isThemeDarkMode,
        })}
      >
        <IconButton
          onClick={openSearch}
          icon={SearchIcon}
          appearance="minimal"
        />
        <p>Search</p>
      </div>

      <div
        className={cx(styles.account, styles.flex_row_xy, {
          [styles.account_inactive]: !isThemeDarkMode,
          [styles.account_inactive_dark]: isThemeDarkMode,
        })}
      >
        <div className={cx(styles.flex_row_xy, styles.avatar)}>
          {profileLoaded && (
            <Avatar
              height={'32px'}
              width={'32px'}
              src={userImage}
              name={getUserDisplayName(user)}
              color={'#fff'}
              background={'#000'}
            />
          )}

          {!profileLoaded && (
            <div className={cx(styles.flex_row_xy, styles.avatar_loading)}>
              <MoreDotIcon />
            </div>
          )}
          {profileLoaded && userPremiumSpaces ? (
            <AvatarPremium className={styles.avatar_premium} />
          ) : (
            <></>
          )}
        </div>
        <p>Account</p>
        <div className={cx(styles.cover, styles.link)}>
          <Link to={'/user/account'} />
        </div>
      </div>

      <div className={cx(styles.flex_row_xy, styles.create)}>
        <Button className={styles.flex_row_xy} appearance="minimal">
          <PlusIcon />
          <div className={cx(styles.cover, styles.link)}>
            <Link to={'/note'} />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(
    withModalPromptSettings(withNoteViewContext(withRouter(BottomBar)))
  )
);
