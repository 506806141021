const HomeIcon = (p) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_27555)">
        <path
          d="M22.9663 24.4997H6.63298C6.32356 24.4997 6.02681 24.3768 5.80802 24.158C5.58923 23.9392 5.46631 23.6425 5.46631 23.3331V12.8331H1.96631L14.0145 1.88041C14.2293 1.68497 14.5092 1.57666 14.7996 1.57666C15.09 1.57666 15.37 1.68497 15.5848 1.88041L27.633 12.8331H24.133V23.3331C24.133 23.6425 24.0101 23.9392 23.7913 24.158C23.5725 24.3768 23.2757 24.4997 22.9663 24.4997ZM7.79964 22.1664H21.7996V10.6829L14.7996 4.31991L7.79964 10.6829V22.1664Z"
          fill="#2D3748"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_27555">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.799805)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeIcon;
