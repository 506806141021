import { useEffect, useState } from 'react';
import { Button } from 'evergreen-ui';
import { Link, withRouter } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

import cx from 'classnames';
import styles from './Header.module.scss';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import LogoIconWord from 'src/components/logo-icon-word';
import HeaderUserOptions from 'src/pages/note-view/HeaderUserOptions';
import { Avatar } from '@chakra-ui/react';
import { getUserDisplayName, isMobileView } from 'src/helpers/utils';
import { withModalPromptSettings } from 'src/managers/modal';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';
import { isFunction } from 'lodash';
import { useWindowScroll } from 'src/hooks';
import { AppRootContainerId } from 'src/pages';

const Header = (props) => {
  const {
    isLoggedIn,
    profileLoaded,
    user,
    showLoginPrompt,
    showSignupPrompt,
    history,
  } = props;

  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const { scrollY } = useWindowScroll();

  const onLoginClick = () => {
    if (isMobileView() && isFunction(history?.push)) {
      history.push('/login');
      return;
    }

    if (isFunction(showLoginPrompt)) {
      showLoginPrompt();
    }
  };

  const onSignUpClick = () => {
    if (isMobileView() && isFunction(history?.push)) {
      history.push('/sign-up');
      return;
    }

    if (isFunction(showSignupPrompt)) {
      showSignupPrompt();
    }
  };

  const closeUserOptions = () => setShowUserOptions(false);

  useEffect(() => {
    const borderBoundary = 120;

    if (isMobileView()) {
      return;
    }

    if (scrollY > borderBoundary && !showBorder) {
      setShowBorder(true);
    } else if (scrollY < borderBoundary && showBorder) {
      setShowBorder(false);
    }
  }, [scrollY, showBorder]);

  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);
    const onScroll = (evt) => {
      if (!isMobileView()) {
        return;
      }

      if (dom.scrollTop > 100) {
        setShowBorder(true);
      } else {
        setShowBorder(false);
      }
    };

    if (dom) {
      dom.addEventListener('scroll', onScroll, false);
    }

    return () => {
      if (dom) {
        dom.removeEventListener('scroll', onScroll, false);
      }
    };
  }, [scrollY]);

  return (
    <div
      className={cx(styles.header_wrap, {
        [styles.header_wrap_scrolling]: showBorder,
      })}
    >
      <div className={styles.header_raw}>
        <div className={styles.header_raw_content}>
          <div className={styles.logo_wrap}>
            <LogoIconWord />
            <div className={cx(styles.link, styles.cover)}>
              <Link to={'/'}></Link>
            </div>
          </div>
          <div className={cx(styles.flex_row_y, styles.actions_wrap)}>
            <div
              className={cx(styles.flex_row_xy, styles.pricing, {
                [styles.hide_element]: isLoggedIn,
              })}
            >
              <Button className={styles.pricing_button_wrap}>
                <h2>{i18n('home_get_pricing_title')}</h2>
                <Link to={'/pricing'}></Link>
              </Button>
            </div>
            <div
              className={cx(styles.flex_row_xy, styles.login, {
                [styles.hide_element]: isLoggedIn,
              })}
            >
              <Button
                className={styles.login_button_wrap}
                onClick={onLoginClick}
              >
                <h2>{i18n('home_login_title')}</h2>
              </Button>
            </div>
            <div
              className={cx(styles.flex_row_xy, styles.getting_started, {
                [styles.hide_element]: isLoggedIn,
              })}
            >
              <CustomButton
                className={cx(
                  styles.flex_row_xy,
                  styles.getting_started_button
                )}
                label={i18n('home_get_started_title')}
                variant={'primaryBlue'}
                onClick={onSignUpClick}
              />
            </div>

            {isLoggedIn && (
              <div
                onClick={() => setShowUserOptions(!showUserOptions)}
                appearance="minimal"
                className={cx(styles.flex_row_xy, styles.user_avatar)}
              >
                {profileLoaded && isLoggedIn && (
                  <Avatar
                    src={user?.image}
                    color={'#fff'}
                    name={getUserDisplayName(user) || 'N A'}
                    backgroundColor={'#000'}
                  />
                )}

                {!profileLoaded && (
                  <Avatar backgroundColor={'#000'} name={'. . . '} />
                )}

                <div
                  className={cx(styles.cover, 'HeaderUserOptionsClassname')}
                ></div>

                {showUserOptions && (
                  <HeaderUserOptions close={closeUserOptions} />
                )}
              </div>
            )}
          </div>
          <div
            className={cx(styles.flex_row_y, styles.actions_wrap_mobile, {
              [styles.hide_element]: isLoggedIn,
            })}
          >
            <div className={cx(styles.flex_row_xy, styles.login_mobile)}>
              <Button
                className={styles.login_mobile_button_wrap}
                onClick={onLoginClick}
              >
                <h2>{i18n('home_login_title')}</h2>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withMobileToastSettings(withRouter(Header)))
);
