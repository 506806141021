import styles from './DragAndDropFile.module.scss';
import cx from 'classnames';
import i18n from 'src/locales';
import k from 'src/constants/k';
import { withNoteViewContext } from './Context';
import { UploadIcon } from 'evergreen-ui';
import { useEffect } from 'react';
import { filter, isFileAllowed, isFunction } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const DragAndDropFile = (props) => {
  const {
    uploadFiles,
    showDragAndDropFile,
    setShowDragAndDropFile,
    getEditorContainerDom,
    isEditMode,
    isCreateMode,
    isThemeDarkMode,
  } = props;
  useEffect(() => {
    let dom = null;
    let counter = 0;

    if (isFunction(getEditorContainerDom)) {
      dom = getEditorContainerDom();
    }

    const onClick = () => {
      if (isFunction(setShowDragAndDropFile)) {
        setShowDragAndDropFile(false);
      }
    };

    const onPreventDefault = (evt) => {
      try {
        if (evt?.preventDefault) {
          evt.preventDefault();
          evt.stopPropagation();
        }
      } catch {}
    };

    const onDragLeave = () => {
      counter--;

      if (
        counter === 0 &&
        showDragAndDropFile &&
        isFunction(setShowDragAndDropFile)
      ) {
        setShowDragAndDropFile(false);
        counter = 0;
      }
    };

    const onDragOver = () => {
      counter++;
      setShowDragAndDropFile(true);
    };

    const onDrop = (evt) => {
      setShowDragAndDropFile(false);

      if (evt) {
        if (!isCreateMode && !isEditMode) {
          counter = 0;
          return;
        }

        const dt = evt.dataTransfer;
        const files = dt.files;
        const filtered = filter(files, (fileInfo) =>
          isFileAllowed(fileInfo?.name)
        );

        if (isFunction(uploadFiles)) {
          uploadFiles(filtered);
        }
      }
    };

    const onWindowBlur = () => {
      setShowDragAndDropFile(false);
      counter = 0;
    };

    if (dom) {
      for (const typeEvent of k.DRAG_EVENTS) {
        if (typeEvent && dom?.addEventListener) {
          dom.addEventListener(typeEvent, onPreventDefault);
        }
      }

      if (dom?.addEventListener) {
        dom.addEventListener('dragleave', onDragLeave, false);
        dom.addEventListener('click', onClick, false);
        dom.addEventListener('dragenter', onDragOver, false);
        dom.addEventListener('dragover', onDragOver, false);
        dom.addEventListener('drop', onDrop, false);
      }

      window.addEventListener('blur', onWindowBlur, false);
    }

    return () => {
      if (dom) {
        for (const typeEvent of k.DRAG_EVENTS) {
          if (typeEvent && dom?.removeEventListener) {
            dom.removeEventListener(typeEvent, onPreventDefault);
          }
        }

        dom.removeEventListener('click', onClick, false);
        dom.removeEventListener('dragleave', onDragLeave, false);
        dom.removeEventListener('dragenter', onDragOver, false);
        dom.removeEventListener('dragover', onDragOver, false);
        dom.removeEventListener('drop', onDrop, false);
        window.removeEventListener('blur', onWindowBlur, false);
      }
    };
  }, [
    showDragAndDropFile,
    setShowDragAndDropFile,
    getEditorContainerDom,
    isThemeDarkMode,
    uploadFiles,
    isCreateMode,
    isEditMode,
  ]);

  return (
    <div
      className={cx(styles.drag_file_container, {
        [styles.hide_element]:
          !showDragAndDropFile || (!isCreateMode && !isEditMode),
      })}
    >
      <div
        className={cx(styles.drag_file, {
          [styles.drag_file_dark]: isThemeDarkMode,
        })}
      >
        <div
          className={cx(styles.flex_column_xy, styles.content, {
            [styles.content_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.flex_row_xy, styles.icon, {
              [styles.icon_dark]: isThemeDarkMode,
            })}
          >
            <UploadIcon height={36} width={36} />
          </div>
          <h1>{i18n('user_create_task_drag_file_here')}</h1>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(DragAndDropFile)
);
