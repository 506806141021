import { includes, toLower } from 'lodash';

export const isNetworkError = (err) => {
  try {
    const sanitizedErrorMessage = toLower(toString(err?.message || ''));

    if (
      includes(sanitizedErrorMessage, 'network error') ||
      includes(sanitizedErrorMessage, 'error network') ||
      (includes(sanitizedErrorMessage, 'network') &&
        includes(sanitizedErrorMessage, 'error'))
    ) {
      // mark user is offline
      return true;
    }
  } catch {}

  return false;
};

export * from './users';
