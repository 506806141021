import cx from 'classnames';
import styles from './TagsPreview.module.scss';
import TagUnit from './TagUnit';
import { withNoteViewContext } from './Context';
import { Button } from 'evergreen-ui';
import { NoteViewModalType } from 'src/types';
import { isFunction } from 'lodash';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const TagsPreview = (props) => {
  const {
    isEditMode,
    isCreateMode,
    showModalType,
    personalTags = [],
    spaceTags = [],
    isThemeDarkMode,
  } = props;
  const canModifyEditor = isEditMode || isCreateMode;

  const showModifyTags = () => {
    if (isFunction(showModalType)) {
      showModalType(NoteViewModalType.tags);
    }
  };

  return (
    <div
      className={cx(styles.tags_preview, {
        [styles.tags_preview_dark]: isThemeDarkMode,
      })}
    >
      <ul>
        {personalTags.map((tagInfo) => {
          const tagId = tagInfo?.id;

          return (
            <li key={`noteViewTagPreviewTag${tagId}`}>
              <TagUnit tag={tagInfo} />
            </li>
          );
        })}
        {spaceTags.map((tagInfo) => {
          const tagId = tagInfo?.id;

          return (
            <li key={`noteViewTagPreviewTag${tagId}`}>
              <TagUnit tag={tagInfo} />
            </li>
          );
        })}

        {canModifyEditor && (
          <li>
            <Button
              onClick={showModifyTags}
              appearance="minimal"
              className={cx(styles.flex_row_xy, styles.add_tag, {
                [styles.add_tag_dark]: isThemeDarkMode,
              })}
            >
              <p>{'Add a tag'}</p>
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(TagsPreview));
