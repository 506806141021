import { ChakraProvider } from '@chakra-ui/react';
import './index.css';
import './theme/task.css';
import 'viewerjs/dist/viewer.css';
import App from './App';

const ChakraUIWrapper = () => {
  return (
    <ChakraProvider>
      <App />
    </ChakraProvider>
  );
};

export default ChakraUIWrapper;
