import cx from 'classnames';
import styles from './PersonalSpace.module.scss';
import CustomButton from 'src/components/button';
import i18n from 'src/locales';
import { Link } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const PersonalSpace = (props) => {
  const { isThemeDarkMode } = props;

  return (
    <div
      className={cx(styles.flex_row_xy, styles.personal_space, {
        [styles.personal_space_dark]: isThemeDarkMode,
      })}
    >
      <div className={cx(styles.flex_row_xy, styles.left)}>
        <div
          className={cx(styles.label, { [styles.label_dark]: isThemeDarkMode })}
        >
          <h2>{'Personal Space'}</h2>{' '}
        </div>
      </div>
      <div className={cx(styles.flex_row_xy, styles.right)}>
        <div className={styles.create}>
          <CustomButton
            isThemeDarkMode={isThemeDarkMode}
            className={styles.create_button}
            label={i18n('common_create')}
            variant={'primaryBlue'}
          />
          <div className={cx(styles.cover, styles.link)}>
            <Link to={'/note'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(PersonalSpace);
