const AlignRightIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1066_20173)">
        <path
          d="M2.5 3.33301H17.5V4.99967H2.5V3.33301ZM5.83333 15.833H17.5V17.4997H5.83333V15.833ZM2.5 11.6663H17.5V13.333H2.5V11.6663ZM5.83333 7.49967H17.5V9.16634H5.83333V7.49967Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1066_20173">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlignRightIcon;
