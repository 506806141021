import styles from './Tag.module.scss';
import cx from 'classnames';
import RemoveTag from 'src/components/remove-tag';
import { HashTagIcon, PencilEditIcon, DeleteIcon } from 'src/icons';
import { TextInput, Button } from 'evergreen-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFunction, toString } from 'src/helpers/utils';
import { withModalPromptSettings } from 'src/managers/modal';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { isEmpty, size, trim } from 'lodash';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import {
  editPersonalTagRequest,
  editSpaceTagRequest,
} from 'src/managers/api/tags';
import { useAuth } from 'src/hooks';
import { Tooltip, useToast } from '@chakra-ui/react';

const Tag = (props) => {
  const {
    tagInfo,
    tagSpaceId,
    isSelected,
    isThemeDarkMode,
    setActiveModalDom,
    editSpaceTag,
    editPersonalTag,
    allowedToEditTags,
  } = props;
  const domRef = useRef();
  const tagId = useMemo(() => tagInfo?.id || '', [tagInfo]);
  const tagName = useMemo(() => tagInfo?.name || tagInfo?.tag || '', [tagInfo]);
  const [editMode, setEditMode] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [tagNameInput, setTagNameInput] = useState(tagName);
  const tagItemSelectClassname = 'tagItemSelectClassnameUnique';
  const tagEditModeClassname = `tagEditModeClassnameUnique${tagId}`;

  useEffect(() => {
    const dom = domRef?.current;
    const hideActions = () => {
      setShowActions(false);
    };
    const onShowActions = () => {
      setShowActions(true);
    };

    if (dom) {
      dom.addEventListener('mouseover', onShowActions, false);
      dom.addEventListener('mouseenter', onShowActions, false);
      dom.addEventListener('mouseleave', hideActions, false);
      dom.addEventListener('mouseout', hideActions, false);
    }

    return () => {
      dom.removeEventListener('mouseover', onShowActions, false);
      dom.removeEventListener('mouseenter', onShowActions, false);
      dom.removeEventListener('mouseleave', hideActions, false);
      dom.removeEventListener('mouseout', hideActions, false);
    };
  }, [showActions]);

  const promptRemoveTag = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(
        <RemoveTag tagInfo={tagInfo} tagSpaceId={tagSpaceId} />
      );
    }
  };

  const promptEditTag = () => {
    setEditMode(!editMode);
  };

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({ id: 'chakraToast', position: 'top' });

  const submitEdited = useCallback(async () => {
    if (!tagId) {
      return;
    }

    const headers = getAuthenticatedHeaders();
    const sanitizedTagInput = trim(tagNameInput);

    if (sanitizedTagInput !== tagName) {
      if (tagSpaceId && tagSpaceId !== 'personal') {
        // edit space tag

        if (isFunction(editSpaceTag)) {
          editSpaceTag(tagId, tagSpaceId, {
            name: sanitizedTagInput,
            tag: sanitizedTagInput,
          });
        }

        const { success } = await editSpaceTagRequest(
          tagId,
          sanitizedTagInput,
          tagSpaceId,
          headers
        );

        if (!success) {
          toast({
            status: 'error',
            title: 'Something went wrong. Try again.',
            isClosable: true,
            duration: 2_000,
          });
        } else {
          toast({
            status: 'success',
            title: 'Updated!',
            isClosable: true,
            duration: 2_000,
          });
        }
      } else {
        // edit personal tag

        if (isFunction(editPersonalTag)) {
          editPersonalTag(tagId, { name: sanitizedTagInput });
        }

        const { success } = await editPersonalTagRequest(
          tagId,
          sanitizedTagInput,
          headers
        );

        if (!success) {
          toast({
            status: 'error',
            title: 'Something went wrong. Try again.',
            isClosable: true,
            duration: 2_000,
          });
        } else {
          toast({
            status: 'success',
            title: 'Updated!',
            isClosable: true,
            duration: 2_000,
          });
        }
      }
    }
  }, [
    tagSpaceId,
    tagNameInput,
    tagName,
    tagId,
    toast,
    editPersonalTag,
    editSpaceTag,
    getAuthenticatedHeaders,
  ]);

  /**
   * No edit when selected
   */
  useEffect(() => {
    if (isSelected) {
      setEditMode(false);
    }
  }, [isSelected]);

  useEffect(() => {
    const onDocumentClick = async (evt) => {
      if (
        tagId &&
        !isEmpty(evt?.target?.classList) &&
        evt?.target?.classList?.contains(tagEditModeClassname)
      ) {
        return;
      }

      if (editMode) {
        setEditMode(false);
        if (
          tagNameInput &&
          tagNameInput?.length > 1 &&
          tagName !== tagNameInput
        ) {
          submitEdited();
        } else {
          setTagNameInput(tagName);
        }
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [
    tagId,
    tagName,
    tagNameInput,
    tagSpaceId,
    isSelected,
    editMode,
    tagEditModeClassname,
    submitEdited,
  ]);

  /**
   * Submit edited tag on keydown enter
   */
  useEffect(() => {
    const onKeyDown = (evt) => {
      if (evt?.keyCode === 13) {
        if (tagName && size(tagNameInput) > 2 && tagNameInput !== tagName) {
          setShowActions(false);

          if (!editMode) {
            return;
          }

          setEditMode(false);
          submitEdited();
        }
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [
    isSelected,
    editMode,
    tagId,
    tagSpaceId,
    tagName,
    tagNameInput,
    submitEdited,
  ]);

  return (
    <div
      ref={domRef}
      className={cx(styles.flex_row_xy, styles.tag, {
        [styles.tag_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(styles.flex_row_xy, styles.tag_icon, {
          [styles.tag_icon_selected]: isSelected,
          [styles.tag_icon_dark]: !isSelected && isThemeDarkMode,
          [styles.tag_icon_light]: !isSelected && !isThemeDarkMode,
        })}
      >
        <HashTagIcon />
        <div className={cx(styles.cover, tagItemSelectClassname, tagId)}> </div>
      </div>
      <div
        className={cx(
          styles.flex_row_xy,
          styles.input,
          {
            [styles.input_with_actions]: showActions,
            [styles.input_selected]: isSelected,
            [styles.input_dark]: !isSelected && isThemeDarkMode,
          },
          tagItemSelectClassname,
          tagId
        )}
      >
        <p>{tagName}</p>
        <div className={cx(styles.cover, tagItemSelectClassname, tagId)}> </div>
      </div>

      {showActions && (
        <div
          className={cx(styles.flex_row_xy, styles.actions, {
            [styles.actions_dark]: isThemeDarkMode,
          })}
        >
          <div>
            <Tooltip className={styles.custom_tooltip} label="Edit">
              <Button
                appearance="minimal"
                className={cx(
                  styles.flex_row_xy,
                  { [styles.hide_element]: isSelected || !allowedToEditTags },
                  tagEditModeClassname
                )}
                onClick={promptEditTag}
              >
                <PencilEditIcon height={16} width={16} />
                <div className={cx(styles.cover, tagEditModeClassname)}></div>
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip className={styles.custom_tooltip} label="Delete">
              <Button
                appearance="minimal"
                className={cx(styles.flex_row_xy, {
                  [styles.hide_element]: isSelected || !allowedToEditTags,
                })}
                onClick={promptRemoveTag}
              >
                <DeleteIcon height={16} width={16} />
              </Button>
            </Tooltip>
          </div>
        </div>
      )}
      {editMode && (
        <div
          className={cx(
            styles.flex_row_xy,
            styles.edit_tag,
            { [styles.edit_tag_dark]: isThemeDarkMode },
            tagEditModeClassname
          )}
        >
          <TextInput
            value={tagNameInput}
            className={cx(styles.flex_row_xy, tagEditModeClassname)}
            maxLength={40}
            onChange={(evt) =>
              setTagNameInput(toString(evt?.target?.value || ''))
            }
          />
        </div>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(Tag))
);
