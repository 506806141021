import cx from 'classnames';
import styles from './ReadOnly.module.scss';
import { WarningIcon } from 'src/icons';

const ReadOnly = () => {
  return (
    <div className={cx(styles.flex_row_xy, styles.read_only)}>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        <div className={cx(styles.flex_row_xy, styles.icon)}>
          <WarningIcon height={20} width={20} />
        </div>
        <p>Read only</p>
      </div>
    </div>
  );
};

export default ReadOnly;
