import cx from 'classnames';
import styles from './NotesList.module.scss';
import EmptyList from './task-views/empty-list';
import NoteItem from './NoteItem';
import k from 'src/constants/k';
import { withNoteDataSettings } from 'src/managers/notes';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Skeleton } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Pagination } from 'evergreen-ui';
import {
  ceil,
  filter,
  includes,
  isEmpty,
  isFunction,
  isNumber,
  map,
  size,
} from 'lodash';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { getDataRefIdValue } from 'src/helpers/utils';
import { isMobileViewScreen } from '.';

const NotesList = (props) => {
  const {
    fetchingNotes,
    deletedNotes,
    personalNotesList,
    personalNotesPage,
    // personalNotesHasNext,
    personalNotesTotalCount,

    // for changing list
    // for fetching with filter
    // on either personal or custom space
    dynamicNotesList,
    dynamicNotesListTotalCount,
    // dynamicNotesHasNext,
    dynamicNotesPage,
    showDynamicNotesList,
    isThemeDarkMode,
    tagsFilterApplied,
    navToNewPage,
    navToNextPage,
    navToPrevPage,
  } = props;

  const notes = useMemo(() => {
    const list = filter(
      showDynamicNotesList ? dynamicNotesList : personalNotesList
    );

    return filter(list, (info) => {
      const targetNoteId = info?.noteId || info?.task_id || '';
      const targetNoteRefId = info?.noteRefId || info?.universal_ref_map;

      return (
        !isEmpty(info) &&
        !info?.deleted &&
        !includes(deletedNotes, targetNoteId) &&
        !includes(deletedNotes, targetNoteRefId)
      );
    });
  }, [deletedNotes, personalNotesList, dynamicNotesList, showDynamicNotesList]);

  const hasFilterApplid = useMemo(
    () => !isEmpty(tagsFilterApplied),
    [tagsFilterApplied]
  );

  const onSelectPage = (page) => {
    if (isNumber(page)) {
      if (isFunction(navToNewPage)) {
        navToNewPage(page);
      }
    }
  };

  const onNavigatePage = (type = '') => {
    if (type === 'next') {
      if (isFunction(navToNextPage)) {
        navToNextPage();
      }
    } else if (type === 'prev') {
      if (isFunction(navToPrevPage)) {
        navToPrevPage();
      }
    }
  };

  const currentPage = useMemo(
    () => (showDynamicNotesList ? dynamicNotesPage : personalNotesPage),
    [dynamicNotesPage, personalNotesPage, showDynamicNotesList]
  );

  const totalCount = useMemo(
    () =>
      showDynamicNotesList
        ? dynamicNotesListTotalCount
        : personalNotesTotalCount,
    [personalNotesTotalCount, dynamicNotesListTotalCount, showDynamicNotesList]
  );

  const currentTotalPages = useMemo(
    () =>
      ceil(
        (showDynamicNotesList
          ? dynamicNotesListTotalCount
          : personalNotesTotalCount) / 20
      ),
    [personalNotesTotalCount, showDynamicNotesList, dynamicNotesListTotalCount]
  );

  const isEmptyList = useMemo(() => isEmpty(notes), [notes]);

  return (
    <div className={styles.notes_list}>
      {fetchingNotes && (
        <>
          <div className={styles.notes_loading}>
            <div className={styles.flex_row_xy}>
              <Skeleton
                height="20px"
                width={'170px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
                {...(!isThemeDarkMode && {
                  startColor: '#f1f2f2',
                  endColor: '#d6d4d4',
                })}
              />{' '}
            </div>
            <div>
              <Skeleton
                height="20px"
                width={'100%'}
                marginTop={'36px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
                {...(!isThemeDarkMode && {
                  startColor: '#f1f2f2',
                  endColor: '#d6d4d4',
                })}
              />{' '}
            </div>
            <div>
              <Skeleton
                height="20px"
                width={'60%'}
                marginTop={'36px'}
                borderRadius={'4px'}
                {...(isThemeDarkMode && {
                  startColor: '#252A30',
                  endColor: '#1C2127',
                })}
                {...(!isThemeDarkMode && {
                  startColor: '#f1f2f2',
                  endColor: '#d6d4d4',
                })}
              />{' '}
            </div>
          </div>
        </>
      )}

      {!fetchingNotes && (
        <div
          className={cx(styles.list_container, {
            [styles.list_container_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.flex_row_xy, styles.page_info, {
              [styles.page_info_dark]: isThemeDarkMode,
            })}
          >
            {!isEmptyList && (
              <div className={styles.page}>
                <p>{`Showing ${
                  (currentPage - 1) * k.USER_TASKS_MAX_BATCH || 1
                }-${
                  totalCount < k.USER_TASKS_MAX_BATCH && currentPage <= 1
                    ? totalCount
                    : (currentPage - 1) * k.USER_TASKS_MAX_BATCH + size(notes)
                } of ${totalCount}`}</p>
              </div>
            )}
            {!isEmptyList && (
              <Pagination
                onPageChange={onSelectPage}
                onPreviousPage={() => onNavigatePage('prev')}
                onNextPage={() => onNavigatePage('next')}
                page={currentPage}
                {...(!hasFilterApplid
                  ? {
                      totalPages: currentTotalPages,
                    }
                  : {})}
              />
            )}
          </div>
          <div
            className={cx(styles.list_label, styles.flex_row_xy, {
              [styles.list_label_dark]: isThemeDarkMode,
            })}
          >
            {!isEmptyList && (
              <>
                <div className={cx(styles.flex_row_xy, styles.note_id)}>
                  <p>ID</p>
                </div>
                <div className={cx(styles.flex_row_xy, styles.note_priority)}>
                  <p>Priority</p>
                </div>
                <div className={cx(styles.flex_row_xy, styles.note_author)}>
                  <p> </p>
                </div>{' '}
                <div className={cx(styles.flex_row_xy, styles.note_title)}>
                  <p> </p>
                </div>{' '}
                <div
                  className={cx(styles.flex_row_xy, styles.note_label)}
                ></div>
                <div className={cx(styles.flex_row_xy, styles.note_copy_link)}>
                  <p> </p>
                </div>{' '}
                <div className={cx(styles.flex_row_xy, styles.note_actions)}>
                  <p> </p>
                </div>
              </>
            )}
          </div>
          {!isEmptyList && (
            <div className={styles.list_content}>
              <ul>
                {map(notes, (noteData) => (
                  <li
                    key={`notesList${
                      getDataRefIdValue(noteData) ||
                      noteData?.universal_ref_map ||
                      noteData?.universal_map_ref ||
                      noteData?.noteId
                    }`}
                  >
                    <NoteItem noteData={noteData} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {isEmptyList && <EmptyList />}
          {!isEmptyList && isMobileViewScreen() && (
            <div
              className={cx(styles.flex_row_xy, styles.mobile_page_info, {
                [styles.page_info_dark]: isThemeDarkMode,
              })}
            >
              <Pagination
                onPageChange={onSelectPage}
                onPreviousPage={() => onNavigatePage('prev')}
                onNextPage={() => onNavigatePage('next')}
                page={currentPage}
                {...(!hasFilterApplid
                  ? {
                      totalPages: currentTotalPages,
                    }
                  : {})}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withNoteDataSettings(NotesList))
);
