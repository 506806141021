import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Organize.module.scss';

const Organize = () => {
  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <h2>{contents.content[0]}</h2>
        <br />
        <p>{contents.content[1]}</p>
        <br />
        <br />
        <h2>{contents.content[2]}</h2>
        <br />
        <br />
        <img alt={'Define your space'} src={'/docs-images/create-tag.png'} />
        <br />
        <br />
        <ol>
          <li>
            On the left side panel of your screen, click on the Add New Tag
            button.
          </li>
          <br />
          <li>Enter the name of the new tag.</li>
          <br />
          <li>Click on the Create button.</li>
        </ol>
        <br />
        <br />
        <h2>{contents.content[3]}</h2>
        <br />
        <br />
        <img alt={'Define your space'} src={'/docs-images/edit-tag.png'} />
        <br />
        <br />
        <ol>
          <li>
            On the left side panel of your screen, hover over the tag you wish
            to edit.
          </li>
          <br />
          <li>Click on the Edit (pencil) button.</li>
          <br />
          <li>Click on the Save button.</li>
        </ol>
        <br />
        <br />
        <h2>{contents.content[4]}</h2>
        <br />
        <br />
        <img alt={'Define your space'} src={'/docs-images/delete-tag.png'} />
        <br />
        <br />
        <ol>
          <li>
            On the left side panel of your screen, hover over the tag you wish
            to delete.
          </li>
          <br />
          <li>Click on the Delete (trash) button.</li>
          <br />
          <li>Click on the Delete button to confirm. </li>
        </ol>
      </div>
    </div>
  );
};

export default Organize;
