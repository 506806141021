const ActivityIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_740_21995)">
        <path
          d="M7.99992 1.33301C11.6819 1.33301 14.6666 4.31767 14.6666 7.99967C14.6666 11.6817 11.6819 14.6663 7.99992 14.6663C4.31792 14.6663 1.33325 11.6817 1.33325 7.99967H2.66659C2.66659 10.945 5.05459 13.333 7.99992 13.333C10.9453 13.333 13.3333 10.945 13.3333 7.99967C13.3333 5.05434 10.9453 2.66634 7.99992 2.66634C6.16659 2.66634 4.54925 3.59101 3.58992 4.99967H5.33325V6.33301H1.33325V2.33301H2.66659V3.99967C3.88259 2.37967 5.81925 1.33301 7.99992 1.33301ZM8.66659 4.66634V7.72301L10.8286 9.88501L9.88525 10.8283L7.33325 8.27501V4.66634H8.66659Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_21995">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ActivityIcon;
