const EyeCloseIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1409_26604)">
        <path
          d="M7.78449 15.6511L6.17533 15.2195L6.83116 12.7703C5.84947 12.4083 4.93709 11.8806 4.13366 11.2103L2.33949 13.0053L1.16033 11.8261L2.95533 10.032C1.94206 8.81845 1.26123 7.36261 0.979492 5.80698L2.61949 5.50781C3.25199 9.00948 6.31533 11.6661 9.99949 11.6661C13.6828 11.6661 16.747 9.00948 17.3795 5.50781L19.0195 5.80615C18.7381 7.36199 18.0576 8.81812 17.0445 10.032L18.8387 11.8261L17.6595 13.0053L15.8653 11.2103C15.0619 11.8806 14.1495 12.4083 13.1678 12.7703L13.8237 15.2203L12.2145 15.6511L11.5578 13.2011C10.5265 13.3779 9.47253 13.3779 8.44116 13.2011L7.78449 15.6511Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1409_26604">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeCloseIcon;
