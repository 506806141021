const ItalicIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8 4H11C11.5 4 12 3.6 12 3C12 2.4 11.6 2 11 2H7C6.5 2 6 2.4 6 3C6 3.6 6.4 4 7 4H7.8L6.2 12H5C4.5 12 4 12.4 4 13C4 13.6 4.4 14 5 14H9C9.5 14 10 13.6 10 13C10 12.4 9.6 12 9 12H8.2L9.8 4Z"
        fill="#718096"
      />
    </svg>
  );
};

export default ItalicIcon;
