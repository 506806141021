import { useEffect, useMemo, useRef } from 'react';
import styles from './Mention.module.scss';
import cx from 'classnames';
import {
  isFunction,
  isNumber,
  isArray,
  toLower,
  descriptionTextContents,
  head,
  isEmpty,
  isMobileView,
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
} from 'src/helpers/utils';
import { Avatar } from '@chakra-ui/react';
import { Spinner } from 'evergreen-ui';
import { fromEvent } from 'rxjs';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'src/lib/bodyScrollLock.min';

const Mention = (props) => {
  const listRef = useRef();
  const bodyScrollDisabled = useRef(false);
  const {
    users = [],
    show = false,
    cursorBounds = null,
    getQuillEditor,
    startRange = null,
    getDescriptionDom,
  } = props;
  const startRangeOnClick = useRef(startRange || null);
  const hide = useMemo(() => !show || !cursorBounds, [show, cursorBounds]);
  const mentionListUniqueClassname = 'mentionListUniqueClassname';
  const QuillEditor = useMemo(() => {
    return getQuillEditor ? getQuillEditor() : null;

    // eslint-disable-next-line
  }, [getQuillEditor, hide]);
  const descriptionContents = QuillEditor.getContents();
  const searchInput = useMemo(() => {
    if (
      !hide &&
      startRange &&
      isNumber(startRange.index) &&
      descriptionContents
    ) {
      const range = QuillEditor.getSelection();
      if (range) {
        const fullTextOnly = descriptionTextContents(descriptionContents);
        const searchText = fullTextOnly.substring(
          startRange.index + 1,
          range.index
        );

        if (range && isNumber(range.index)) {
          return searchText;
        }
      }
    }

    return '';

    // eslint-disable-next-line
  }, [QuillEditor, show, hide, startRange, descriptionContents]);

  const descriptionDom = useMemo(() => {
    if (isFunction(getDescriptionDom)) {
      return getDescriptionDom();
    }

    return null;

    // eslint-disable-next-line
  }, [getDescriptionDom, users, QuillEditor]);

  const filtered = useMemo(
    () =>
      !searchInput
        ? users.filter((user) => user?.firstName || user?.lastName)
        : isArray(users)
        ? users.filter((user) => {
            return toLower(`${user?.firstName} ${user.lastName}`).includes(
              toLower(searchInput)
            );
          })
        : [],

    // eslint-disable-next-line
    [users, startRange, searchInput]
  );
  const stillLoading =
    users?.length && !isEmpty(users) && head(users) && !head(users)?.firstName;

  useEffect(() => {
    if (startRange) {
      startRangeOnClick.current = startRange;
    } else if (QuillEditor && !startRangeOnClick.current) {
      startRangeOnClick.current = QuillEditor.getSelection();
    }
  }, [QuillEditor, filtered, startRange, searchInput, users]);

  useEffect(() => {
    let subscriber = null;

    if (!listRef.current) {
      return;
    }

    const close = props?.close;
    const Quill = window.Quill;
    const Delta = Quill.import('delta');

    const insertMention = (range, startIndex, props, startIndexAdd = 1) => {
      if (!range || !props) {
        return;
      }

      const newRange = startIndex + startIndexAdd;
      const update = new Delta()
        .retain(startIndex)
        .delete(
          (isNumber(range?.index) ? range.index : startIndex) - startIndex
        );

      QuillEditor.updateContents(
        update.insert(''),
        update.insert(props),
        Quill.sources.USER
      );
      QuillEditor.setSelection(newRange, Quill.sources.SILENT);

      if (isFunction(close)) {
        close();
      }
    };

    const onKeyDown = (evt) => {
      // auto select if filtered lengtth === 1
      if (
        (evt.keyCode === 9 || evt.key === 'Tab') &&
        ((show && users?.length === 1) || filtered?.length === 1) &&
        !isMobileView()
      ) {
        const targetUser = head(users?.length === 1 ? users : filtered);
        const range = QuillEditor.getSelection(true);

        if (!range || !targetUser) {
          return;
        }

        const userId = targetUser?.id || targetUser?.profile_id;
        const text = `@${targetUser.firstName} ${targetUser.lastName}`.trim();
        const name = `${targetUser.firstName} ${targetUser.lastName}`.trim();
        const startIndex = range?.index > 1 ? range?.index - 2 : range.index;

        if (!name) {
          return;
        }

        insertMention(
          range,
          startIndex,
          {
            atinfo: {
              userId,
              text,
              name,
              showHoverBottom: isMobileView(),
              description: '',
            },
          },
          2
        );
      }
    };

    const keydownSubscriber = fromEvent(document, 'keydown').subscribe(
      onKeyDown
    );

    subscriber = fromEvent(listRef.current, 'click').subscribe((evt) => {
      const target = evt?.target;
      const range = QuillEditor.getSelection(true);

      if (
        target &&
        target.classList &&
        target.classList.length > 1 &&
        target.classList.contains(mentionListUniqueClassname) &&
        isArray(users)
      ) {
        const userId = `${
          target.classList[target.classList.length - 1] || ''
        }`.trim();
        const find = userId
          ? users.filter(
              (u) => u && (u.id === userId || u.profile_id === userId)
            )
          : [];
        const targetUser = head(find);
        if (targetUser) {
          const startIndex = startRangeOnClick?.current
            ? startRangeOnClick.current.index
            : range?.index;
          const text = `@${targetUser.firstName} ${targetUser.lastName}`.trim();
          const name = `${targetUser.firstName} ${targetUser.lastName}`.trim();

          if (!name) {
            return;
          }

          insertMention(range, startIndex, {
            atinfo: {
              userId,
              text,
              name,
              showHoverBottom: isMobileView(),
              description: '',
            },
          });
        }
      }

      startRangeOnClick.current = null;
    });

    return () => {
      subscriber.unsubscribe();
      keydownSubscriber.unsubscribe();
    };
  }, [
    show,
    users,
    props?.close,
    QuillEditor,
    hide,
    startRange,
    filtered,
    searchInput,
  ]);

  const positionStyle = {
    right: cursorBounds
      ? cursorBounds.left > descriptionDom.clientWidth - 250
        ? '8px'
        : `calc(100% - ${
            (cursorBounds.left > 0 ? cursorBounds.left : 0) + 250
          }px)`
      : '0px',
    bottom: `calc(100% - ${
      isNumber(cursorBounds?.top) ? cursorBounds?.top : 0
    }px)`,
  };

  useEffect(() => {
    if (!listRef?.current) {
      return;
    }

    const dom = listRef.current;
    const noMention =
      (!filtered?.length && !stillLoading) ||
      !users?.length ||
      hide ||
      stillLoading;

    if (!isMobileView()) {
      return;
    }

    if (noMention && bodyScrollDisabled.current) {
      enableBodyScroll(dom);
      bodyScrollDisabled.current = false;
    } else if (!bodyScrollDisabled.current) {
      disableBodyScroll(dom);
      bodyScrollDisabled.current = true;
    }

    return () => {
      if (isMobileView() && dom) {
        enableBodyScroll(dom);
      }
    };
  }, [hide, searchInput, filtered, users, stillLoading]);

  return (
    <div
      id="comment-mention-wrap"
      className={cx(styles.mention, {
        [styles.mention_hide]: hide,
        [styles.mention_show]: !hide,
        [styles.hide_element]:
          (searchInput && !filtered?.length && !stillLoading) ||
          (!searchInput && !users?.length),
      })}
      style={positionStyle}
    >
      <div
        className={cx(styles.mention_loading, {
          [styles.hide_element]: !stillLoading,
        })}
      >
        <div className={styles.flex_row_xy}>
          <Spinner height={20} width={20} />
        </div>
      </div>
      <ul ref={listRef} className={cx({ [styles.hide_element]: stillLoading })}>
        {filtered.map((user) => {
          const userId =
            getUserIdFromObject(user) || getUserProfileIdFromObject(user);
          const displayName = getUserDisplayName(user);
          const image = user?.image;

          return (
            <li key={userId}>
              <div className={styles.avatar}>
                <Avatar
                  src={image}
                  name={getUserDisplayName(user)}
                  background={'#000'}
                  backgroundColor={'#000'}
                  height={'40px'}
                  width={'30px'}
                />
              </div>
              <p>{displayName}</p>
              <div
                className={cx(styles.cover, mentionListUniqueClassname, userId)}
              ></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Mention;
