import { Component, createContext, forwardRef, useMemo } from 'react';
import { withUserDataAndProfileSettings } from '../profile';
import {
  filter,
  head,
  includes,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  isString,
  map,
  size,
} from 'lodash';
import { getAuthenticatedHeaders } from 'src/helpers/utils';
import {
  getSpaceMembersByPageRequest,
  getSpaceTagsByPageRequest,
  getUserJoinedSpacesByPageRequest,
} from '../api/spaces';

const { Consumer, Provider } = createContext();

export function withSpacesAndUserSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

export const personalSpace = 'personal';

class SpacesAndUserManager extends Component {
  state = {
    userSpaces: [],
    userSpacesPage: 1,
    userSpacesFetching: false,
    userSpacesHasNext: false,
    initialFetchDone: false,
    fetchingUserSpaces: false,
    selectedSpace: null,
    storedSpaces: [],
  };

  constructor(props) {
    super(props);
    this.setStateAsync = (obj) =>
      new Promise((resolve) => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps) {
    const { userIsPremium, isLoggedIn } = this.props;
    const { initialFetchDone } = this.state;

    if (
      !initialFetchDone &&
      isLoggedIn &&
      !prevProps?.userIsPremium &&
      userIsPremium
    ) {
      this.check();
    }

    if (prevProps?.isLoggedIn && !isLoggedIn) {
      console.log('reset spaces');

      this.setState({
        userSpaces: [],
        userSpacesPage: 1,
        userSpacesFetching: false,
        userSpacesHasNext: false,
        initialFetchDone: false,
        selectedSpace: null,
        storedSpaces: [],
      });
    }
  }

  selectSpace = (spaceId = '') => {
    const { userSpaces } = this.state;

    if (!spaceId || spaceId === 'personal') {
      this.setState({ selectedSpace: null });
      return;
    }

    const targetSpace = head(
      filter(
        userSpaces,
        (spaceInfo) =>
          spaceInfo?.id === spaceId || spaceInfo?.space_id === spaceId
      )
    );

    if (!isEmpty(targetSpace)) {
      this.setState(
        {
          selectedSpace: targetSpace,
        },
        () => this.getSpaceMembersByPage(spaceId)
      );
    } else {
      this.setState({ selectedSpace: null });
    }
  };

  check = () => {
    const { initialFetchDone } = this.state;
    const { userIsPremium, user, isLoggedIn } = this.props;

    if (isLoggedIn && !isEmpty(user) && userIsPremium) {
      // fetch joined space first page

      if (initialFetchDone) {
        return;
      }

      this.setState(
        {
          initialFetchDone: true,
        },
        this.getJoinedSpacesByPage
      );
    } else {
      this.setState({ fetchingUserSpaces: false });
    }
  };

  clearSpacesStored = () => {
    this.setState({
      userSpaces: [],
      userSpacesPage: 1,
      userSpacesHasNext: false,
      fetchingUserSpaces: true,
    });
  };

  getJoinedSpacesByPage = async () => {
    const { user } = this.props;
    const { userSpaces, userSpacesPage, fetchingUserSpaces } = this.state;

    if (fetchingUserSpaces) {
      return;
    }

    const headers = getAuthenticatedHeaders(user);
    const { spaces, errorMessage, hasNext, networkError } =
      await getUserJoinedSpacesByPageRequest(userSpacesPage, headers);

    if (errorMessage || networkError) {
      this.setState({ fetchingUserSpaces: false });
      return;
    }

    const storedSpacesId = filter(
      userSpaces,
      (spaceInfo) => spaceInfo?.id || spaceInfo?.space_id
    );

    if (isArray(spaces)) {
      this.setState(
        {
          fetchingUserSpaces: false,
          userSpacesHasNext: hasNext,
          userSpaces: [
            ...userSpaces,
            ...map(
              filter(
                spaces,
                (spaceInfo) =>
                  !isEmpty(spaceInfo?.id) &&
                  !includes(storedSpacesId, spaceInfo?.id)
              ),
              (info) => ({ ...info, members: info?.members || [] })
            ),
          ],
        },
        async () => {
          for (let i = 0; i < size(spaces); i++) {
            const spaceId = spaces[i]?.id;

            if (spaceId && !includes(storedSpacesId, spaceId)) {
              await this.getSpaceTagsByPage(spaceId);
            }
          }
        }
      );
    }
  };

  getSpaceTagsByPage = async (spaceId = '') => {
    const { userSpaces } = this.state;
    const { user } = this.props;
    const headers = getAuthenticatedHeaders(user);
    let targetSpace = head(
      filter(userSpaces, (spaceInfo) => spaceInfo?.id === spaceId)
    );
    let canFetch = true;
    let currentPage = 1;

    if (!targetSpace) {
      return;
    } else {
      targetSpace = { ...targetSpace, tags: [] };
    }

    while (canFetch) {
      try {
        const { tags, hasNext, errorMessage, networkError } =
          await getSpaceTagsByPageRequest(currentPage, spaceId, headers);

        if (networkError || errorMessage) {
          break;
        }

        if (isArray(tags)) {
          targetSpace = {
            ...targetSpace,
            tags: [...targetSpace.tags, ...tags],
          };
        }

        if (!hasNext) {
          break;
        }

        currentPage += 1;
        canFetch = hasNext;
      } catch {
        break;
      }
    }

    await this.updateSpaceInfoProps(spaceId, targetSpace);
  };

  updateSpaceInfoProps = async (spaceId = '', params = {}) => {
    const { userSpaces, selectedSpace } = this.state;

    await this.setStateAsync({
      userSpaces: map(userSpaces, (spaceInfo) => {
        if (spaceInfo?.id === spaceId) {
          return { ...spaceInfo, ...params };
        }

        return { ...spaceInfo };
      }),
      ...(selectedSpace?.id === spaceId &&
        spaceId !== 'personal' && {
          selectedSpace: { ...selectedSpace, ...params },
        }),
    });
  };

  addSpaceTag = (tagInfo = null, spaceId = '') => {
    const { userSpaces, selectedSpace } = this.state;
    let nameAlreadyExists = false;

    if (!isEmpty(tagInfo)) {
      for (let i = 0; i < size(userSpaces); i++) {
        const spaceInfo = userSpaces[i];

        if (spaceInfo?.id === spaceId) {
          const spaceTags = spaceInfo?.tags || [];

          for (let y = 0; y < size(spaceTags); y++) {
            const spaceTagInfo = spaceTags[y];

            nameAlreadyExists =
              spaceTagInfo?.name === tagInfo?.name ||
              spaceTagInfo?.tag === tagInfo?.name;

            if (nameAlreadyExists) {
              break;
            }
          }
        }
      }

      if (nameAlreadyExists || !tagInfo?.name) {
        return;
      }

      const updatedUserSpaces = map(userSpaces, (spaceInfo) => {
        if (spaceInfo?.id === spaceId) {
          if (isArray(spaceInfo.tags)) {
            spaceInfo.tags = [tagInfo, ...spaceInfo.tags];
          } else {
            spaceInfo.tags = [tagInfo];
          }
        }

        return { ...spaceInfo };
      });

      this.setState({
        userSpaces: [...updatedUserSpaces],
        ...(selectedSpace?.id === spaceId
          ? {
              selectedSpace: {
                ...selectedSpace,
                tags: isArray(selectedSpace?.tags)
                  ? [tagInfo, ...selectedSpace.tags]
                  : [tagInfo],
              },
            }
          : {}),
      });
    }
  };

  editSpaceTag = (tagId = '', spaceId = '', params = {}) => {
    const { userSpaces } = this.state;

    if (!isEmpty(tagId)) {
      for (let i = 0; i < size(userSpaces); i++) {
        const spaceInfo = userSpaces[i];

        if (spaceInfo?.id === spaceId) {
          const updatedSpaceTags = map(spaceInfo?.tags || [], (tagInfo) => {
            if (tagInfo?.id === tagId) {
              return { ...tagInfo, ...params };
            }

            return { ...tagInfo };
          });

          userSpaces[i].tags = [...updatedSpaceTags];
        }
      }

      this.setState({ userSpaces: [...userSpaces] });
    }
  };

  removeSpaceTag = (tagId = '', spaceId = '') => {
    const { userSpaces, selectedSpace } = this.state;

    if (!isEmpty(tagId)) {
      for (let i = 0; i < size(userSpaces); i++) {
        const spaceInfo = userSpaces[i];

        if (spaceInfo?.id === spaceId) {
          const updatedSpaceTags = filter(
            spaceInfo?.tags || [],
            (tagInfo) => tagInfo?.id !== tagId
          );

          userSpaces[i].tags = updatedSpaceTags;
        }
      }

      this.setState({
        userSpaces: [...userSpaces],
        ...(selectedSpace?.id === spaceId
          ? {
              selectedSpace: {
                ...selectedSpace,
                tags: filter(
                  selectedSpace?.tags || [],
                  (tagInfo) => tagInfo?.id !== tagId
                ),
              },
            }
          : {}),
      });
    }
  };

  addNewSpace = (spaceInfo) => {
    const { userSpaces } = this.state;
    const spaceId = spaceInfo?.id;
    const spaceName = spaceInfo?.name;
    const find = filter(userSpaces, (space) => space?.name === spaceName);

    if (!isEmpty(head(find))) {
      // space name already exists
      return;
    }

    if (!isEmpty(spaceId)) {
      this.setState(
        {
          userSpaces: [spaceInfo, ...userSpaces],
        },
        () => {
          this.selectSpace(spaceId);
        }
      );
    }
  };

  isSpaceNameAlreadyExist = (name = '') => {
    const { userSpaces } = this.state;
    const find = filter(
      userSpaces,
      (space) => !isEmpty(name) && isString(name) && space?.name === name
    );

    if (!isEmpty(head(find))) {
      // space name already exists
      return true;
    }

    return false;
  };

  getSpaceMembersByPage = async (spaceId = '') => {
    const { userSpaces } = this.state;
    const { user } = this.props;
    const spaceInfo = head(
      filter(userSpaces, (spaceInfo) => spaceInfo?.id === spaceId)
    );

    if (!spaceId) {
      return;
    }

    if (!isEmpty(spaceInfo)) {
      //
      const headers = getAuthenticatedHeaders(user);
      const targetPage =
        !spaceInfo?.membersPage ||
        !isNumber(spaceInfo?.membersPage) ||
        spaceInfo?.membersPage < 1
          ? 1
          : spaceInfo?.membersPage;

      if (
        !isEmpty(spaceInfo?.members) &&
        isArray(spaceInfo?.members[targetPage - 1]) &&
        !isEmpty(spaceInfo?.members[targetPage - 1])
      ) {
        // no need to fetch again
        return;
      }

      const { hasNext, members, totalMembers, errorMessage, networkError } =
        await getSpaceMembersByPageRequest(targetPage, spaceId, headers);
      const { userSpaces: updatedUserSpaces, selectedSpace } = this.state;

      if (!errorMessage && !networkError) {
        const updatedSpaceInfo = head(
          filter(updatedUserSpaces, (spaceInfo) => spaceInfo?.id === spaceId)
        );
        const currentMembers = updatedSpaceInfo?.members || [];

        if (isObject(updatedSpaceInfo) && !isEmpty(updatedSpaceInfo)) {
          currentMembers[targetPage - 1] = filter(members);
          updatedSpaceInfo.members = [...currentMembers];
          updatedSpaceInfo.membersPage = hasNext ? targetPage + 1 : targetPage;

          if (isNumber(totalMembers)) {
            updatedSpaceInfo.totalMembers = totalMembers;
          }

          this.setState({
            userSpaces: map(updatedUserSpaces, (spaceInfo) => {
              if (spaceInfo?.id === spaceId) {
                return updatedSpaceInfo;
              }

              return spaceInfo;
            }),
            ...(selectedSpace?.id === spaceId && {
              selectedSpace: { ...updatedSpaceInfo },
            }),
          });
        }
      }
    }
  };

  render() {
    const { children, user } = this.props;
    const {
      fetchingUserSpaces,
      userSpaces,
      userSpacesPage,
      userSpacesHasNext,
      selectedSpace,
    } = this.state;
    const selectedSpaceId = selectedSpace?.id || personalSpace;
    const isPersonalSpaceSelected =
      selectedSpaceId === personalSpace || !selectedSpaceId;
    const props = {
      isPersonalSpaceSelected,
      fetchingUserSpaces,
      userSpaces,
      userSpacesPage,
      userSpacesHasNext,
      selectedSpace,
      selectedSpaceId,
      isSpaceNameAlreadyExist: this.isSpaceNameAlreadyExist,
      addNewSpace: this.addNewSpace,
      addSpaceTag: this.addSpaceTag,
      removeSpaceTag: this.removeSpaceTag,
      editSpaceTag: this.editSpaceTag,
      selectSpace: this.selectSpace,
      getSpaceMembersByPage: this.getSpaceMembersByPage,
      getJoinedSpacesByPage: this.getJoinedSpacesByPage,
      updateSpaceInfoProps: this.updateSpaceInfoProps,
    };

    return (
      <SpacesManagerContextMemoized user={user} {...props}>
        {children || null}
      </SpacesManagerContextMemoized>
    );
  }
}

const SpacesManagerContextMemoized = ({ children, ...props }) => {
  const {
    isPersonalSpaceSelected,
    fetchingUserSpaces,
    userSpaces,
    userSpacesPage,
    userSpacesHasNext,
    selectedSpace,
    selectedSpaceId,
    user,
  } = props;

  const propsValueMemoized = useMemo(
    () => ({ ...props }),
    // eslint-disable-next-line
    [
      children,
      user,
      isPersonalSpaceSelected,
      fetchingUserSpaces,
      userSpaces,
      userSpaces?.length,
      userSpacesPage,
      userSpacesHasNext,
      selectedSpace,
      selectedSpaceId,
    ]
  );

  return <Provider value={propsValueMemoized}>{children}</Provider>;
};

export default withUserDataAndProfileSettings(SpacesAndUserManager);
