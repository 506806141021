import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './CommentInput.module.scss';
import QuillTemplate from 'src/lib/quill-template';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import MessageHover from 'src/components/message-hover';
import Emojis from './emojis';
import Gifs from './gifs';
import GifPreview from './gif-preview';
import Mention from './mention';
import {
  isWhitespace,
  includes,
  isEmpty,
  isString,
  isFunction,
  toString,
  isNumber,
  isMobileView,
  isValidObject,
  head,
  descriptionTextContents,
  getAuthenticatedHeaders,
} from 'src/helpers/utils';
import { getMatchWhitelistUrls } from 'src/helpers/urls';
import { PencilEditIcon as EditIcon, SmileIcon } from 'src/icons';
import { IconButton, Button } from 'evergreen-ui';
import { fromEvent } from 'rxjs';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'src/lib/bodyScrollLock.min';
import {
  updateCommentFromNoteRequest,
  writeCommentInNoteRequest,
} from 'src/managers/api/noteComments';
import { cleanCommentEditor } from './helpers';

export const Delta =
  window && window.Quill ? window.Quill.import('delta') : null;

const CommentInput = ({
  setEditingId = function () {},
  commentId = '',
  connectedUsers = [],
  allowMention = true,
  editingId = '',
  mobileView = false,
  forceEdit = false,
  showEdit = false,
  autoEdit = false,
  hideEditButton = false,
  placeholder = '',
  version = '',
  text = '',
  delta = '',
  mode = '',
  className = '',
  taskId = '',
  refId = '',
  uRef = '',
  gif = '',
  id = '',
  user = null,
  isThemeDarkMode = false,
  onCancel = function () {},
  onSubmit = function () {},
  onSubmitCallback = function () {},
}) => {
  const storedDelta = useRef(delta || '');
  const isCreateMode = mode === 'create';
  const isEditMode = mode === 'edit';
  const v4Id = useRef(commentId || '');
  const domIdRef = useRef(`comment-${commentId}-${Date.now()}`);
  const toolbarIdRef = useRef(`toolbar-${commentId}-${Date.now()}`);
  const editableRef = useRef(null);
  const [Quill, setQuill] = useState(null);
  const [init, setInit] = useState(false);
  const [initEvents, setInitEvents] = useState(false);
  const [ready, setReady] = useState(false);
  const [initialValue, setInitialValue] = useState(false);
  const [editing, setEditing] = useState(forceEdit || autoEdit || false);
  const [submitted, setSubmitted] = useState(false);
  const [focused, setFocused] = useState(false);
  const [openEmojis, setOpenEmojis] = useState(false);
  const [openGifs, setOpenGifs] = useState(false);
  const [edited, setEdited] = useState(false);
  const [showMention, setShowMention] = useState(false);
  const [cursorBounds, setCursorBounds] = useState(null);
  const [showMentionRange, setShowMentionRange] = useState(null);
  const isV1 = version === 'v1' || !isEmpty(text);
  const [activeGif, setActiveGif] = useState(gif || '');
  const isGif =
    activeGif && !isEmpty(activeGif) && includes(activeGif, 'https://');

  useEffect(() => {
    if (!init) {
      setInit(true);
      const domId = domIdRef.current;
      const currentQuill = new QuillTemplate(domId);
      currentQuill.create('', toolbarIdRef.current, {
        modules: { syntax: true },
        placeholder: forceEdit
          ? placeholder || i18n('task_view_comments_placeholder_for_author')
          : '',
        handlers: {
          ...(typeof imageHandler === 'function' && {
            image: (evt) => {
              if (evt?.preventDefault) {
                evt.preventDefault();
              }
            },
          }),
        },
      });
      currentQuill.setName(`${domIdRef.current}`);
      currentQuill.setNotAllowedHTMLs([
        'IFRAME',
        'iframe',
        'PICTURE',
        'picture',
        'VIDEO',
        'video',
        'SOURCE',
        'source',
        'H3',
        'h3',
        'H4',
        'h4',
        'H5',
        'h5',
        'HTML',
        'html',
        'BODY',
        'body',
      ]);
      setQuill(currentQuill);
      setReady(true);
    }

    // eslint-disable-next-line
  }, [init]);

  const onEdit = useCallback(() => {
    if (isFunction(setEditingId)) {
      setEditingId(commentId || refId || v4Id.current);
    }

    if (isMobileView()) {
      setEditing(false);
      return;
    }

    setEditing(true);
  }, [refId, commentId, setEditingId, setEditing]);

  const promptMentions = (range = null) => {
    if (!Quill?.get || (!editing && !isCreateMode) || !allowMention) {
      return;
    }

    const QuillEditor = Quill.get();

    if (!range) {
      range = QuillEditor.getSelection();
    }

    setShowMention(true);
    setShowMentionRange(range);
  };

  const hideMentions = () => {
    if (!allowMention) {
      return;
    }

    setShowMention(false);
    setShowMentionRange(null);
  };

  const onSelectionChange = useCallback(
    (range) => {
      if (!range || range === null) {
        if (!Quill.get().hasFocus()) {
          setFocused(false);
        }
        setCursorBounds(null);
        hideMentions();

        return;
      }

      if (!Quill) {
        setShowMention(false);
        return;
      }

      if (!focused && (!editing || Quill.get().hasFocus())) {
        setFocused(true);
      }

      const currentCursorBounds = Quill.get().getBounds(range?.index);
      const prevCursorBounds = cursorBounds;
      const descriptionContents = Quill.get().getContents();
      const fullTextOnly = toString(
        descriptionTextContents(descriptionContents)
      );
      const lastIndexOfAtKey = fullTextOnly.lastIndexOf('@');
      const substringCheckForAtMention =
        fullTextOnly && lastIndexOfAtKey < range.index
          ? fullTextOnly.substring(lastIndexOfAtKey, range.index)
          : '';

      if (isNumber(range?.index) && editing) {
        setCursorBounds(currentCursorBounds);
      }

      if (
        ((isValidObject(showMentionRange) &&
          showMentionRange.index >= range.index) ||
          prevCursorBounds?.bottom !== currentCursorBounds?.bottom ||
          prevCursorBounds?.top !== currentCursorBounds?.top) &&
        showMention
      ) {
        hideMentions();
      } else if (
        !isMobileView() &&
        isNumber(lastIndexOfAtKey) &&
        lastIndexOfAtKey > -1 &&
        !showMention &&
        !showMentionRange &&
        substringCheckForAtMention &&
        !substringCheckForAtMention.includes(' ') &&
        substringCheckForAtMention.length < 16
      ) {
        promptMentions({ index: lastIndexOfAtKey });
      }
    },
    // eslint-disable-next-line
    [
      Quill,
      // eslint-disable-next-line
      Quill?.get(),
      focused,
      isGif,
      isCreateMode,
      isEditMode,
      editing,
      cursorBounds,
      showMentionRange,
      showMention,
      hideMentions,
      promptMentions,
      setCursorBounds,
    ]
  );

  // eslint-disable-next-line
  const onEditorChange = useCallback(
    (name, ...args) => {
      if (ready) {
        const dom = editableRef.current;
        cleanCommentEditor(dom);

        if (!focused) {
          setFocused(true);
        }

        if (!Quill?.get) {
          return;
        }

        const range = Quill.get().getSelection();
        const descriptionContents = Quill.get().getContents();
        const fullTextOnly = toString(
          descriptionTextContents(descriptionContents)
        );

        if (range) {
          const lastIndexOfAtKey = fullTextOnly.lastIndexOf('@');
          const substringCheckForAtMention =
            fullTextOnly && lastIndexOfAtKey < range.index
              ? fullTextOnly.substring(lastIndexOfAtKey, range.index)
              : '';

          if (
            isMobileView() &&
            isNumber(lastIndexOfAtKey) &&
            lastIndexOfAtKey > -1 &&
            substringCheckForAtMention &&
            !substringCheckForAtMention.includes(' ') &&
            substringCheckForAtMention.length < 16
          ) {
            promptMentions({ index: lastIndexOfAtKey });
          }
        }

        if (name === 'selection-change') {
          if (
            (isCreateMode || !range || range === null) &&
            !Quill.get().hasFocus()
          ) {
            setFocused(false);
            hideMentions();
          }
        } else if (name === 'text-change') {
          if (range) {
            const currentCursorBounds = Quill.get().getBounds(range?.index);

            if (isNumber(range?.index) && editing) {
              setCursorBounds(currentCursorBounds);
            }
          }
        }

        if (dom) {
          const textContent = `${dom?.textContent}`.replace(/[\s]/gi, '');

          if (dom && textContent) {
            setEdited(true);
          } else if (isEmpty(textContent)) {
            setEdited(false);
          }
        } else {
          setEdited(false);
        }

        // do not remove
        // console.log(Quill.getDelta());
      }
    },

    // eslint-disable-next-line
    [
      isCreateMode,
      ready,
      showMention,
      Quill,
      // eslint-disable-next-line
      Quill?.get(),
      focused,
      isGif,
      editing,
      cursorBounds,
      setEdited,
      promptMentions,
    ]
  );

  useEffect(() => {
    const onDomDrop = (evt) => {
      if (evt) {
        evt.preventDefault();
      }

      return false;
    };
    const dom = editableRef?.current;

    if (Quill && ready) {
      if (!initEvents) {
        if (dom) {
          dom.addEventListener('drop', onDomDrop, false);
        }

        setInitEvents(true);

        if (Quill?.get) {
          if (isMobileView() && editing) {
            Quill.get().focus();
          }

          Quill.get().clipboard.addMatcher('IMG', function (node, delta) {
            const Delta = window.Quill.import('delta');
            const currentDelta = new Delta([{ insert: '' }]);
            return currentDelta;
          });
          Quill.get().clipboard.addMatcher(
            Node.TEXT_NODE,
            function (node, delta) {
              try {
                const Delta = window.Quill.import('delta');
                const text = toString(node?.textContent || '');
                const regex = /https?:\/\/[^\s]+/g;
                const matches = text.match(regex);
                const ops = [];

                if (matches?.length) {
                  let str = text;

                  for (let i = 0; i < matches.length; i++) {
                    const match = matches[i];
                    const split = str.split(match);
                    const beforeLink = split.shift();
                    ops.push({ insert: beforeLink });
                    ops.push({ insert: match, attributes: { link: match } });
                    str = split.join(match);
                  }

                  ops.push({ insert: str });
                  const currentDelta = new Delta(ops);
                  return currentDelta;
                }

                return delta;
              } catch {}
            }
          );

          Quill.get().on('text-change', function (delta) {
            // params delta, prevDelta, source

            const firstOp = head(delta?.ops);

            if (
              firstOp &&
              delta?.ops?.length &&
              delta.ops.length === 2 &&
              delta.ops[0].retain &&
              isWhitespace(delta.ops[1].insert)
            ) {
              // check if user is typing in a valid url
              const regex = /https?:\/\/[^\s]+$/g;
              const endRetain = firstOp.retain;
              const text = Quill.get().getText().substr(0, endRetain);
              const match = text.match(regex);
              const whitelistUrlMatches = getMatchWhitelistUrls(text);

              if (match !== null || whitelistUrlMatches?.length) {
                const url =
                  match !== null
                    ? toString(head(match))
                    : toString(head(whitelistUrlMatches));
                const ops = [];

                if (endRetain > url.length) {
                  ops.push({ retain: endRetain - url.length });
                }

                ops.push({ delete: url.length });
                ops.push({
                  insert: url,
                  attributes: {
                    link:
                      includes(url, 'https') || includes(url, 'http')
                        ? url
                        : `https://${url}`,
                  },
                });
                Quill.get().updateContents({
                  ops,
                });
              }
            }
          });
        }

        if (editing) {
          Quill.enable();
        } else {
          Quill.disable();
        }
      }

      Quill.onSelectionChange(onSelectionChange);
      Quill.onEditorChange(onEditorChange);
    }

    return () => {
      if (Quill) {
        Quill.unsubscribeSelectionChange(onSelectionChange);
        Quill.unsubscribeEditorChange(onEditorChange);
      }

      if (dom) {
        dom.removeEventListener('drop', onDomDrop, false);
      }
    };
  }, [
    initEvents,
    editing,
    Quill,
    // eslint-disable-next-line
    Quill?.get(),
    ready,
    isV1,
    text,
    version,
    focused,
    editableRef,
    activeGif,
    cursorBounds,
    onEdit,
    setActiveGif,
    setCursorBounds,
    setInitEvents,
    setReady,
    setFocused,
    onEditorChange,
    onSelectionChange,
  ]);

  /**
   * First load set values
   */
  useEffect(() => {
    if (Quill && ready && !initialValue) {
      setInitialValue(true);
      // set contents manually

      if (!isCreateMode) {
        if (isV1) {
          Quill.get().setContents({ ops: [{ insert: text }] });
        } else {
          try {
            const parsedDelta = JSON.parse(delta);
            Quill.get().setContents(parsedDelta);
          } catch {}
        }
      }
    }
  }, [delta, text, isCreateMode, ready, isV1, initialValue, Quill]);

  /**
   * Enable editor if set to edit mode
   */
  useEffect(() => {
    if (initEvents && ready && Quill) {
      if (editing) {
        Quill.enable();
      } else {
        Quill.disable();
      }
    }
  }, [initEvents, ready, editing, Quill, onEditorChange, onSelectionChange]);

  const revert = () => {
    try {
      if (isCreateMode) {
        Quill.get().setContents({ ops: [] });
      } else {
        if (isV1) {
          Quill.get().setContents({ ops: [{ insert: text }] });
        } else {
          if (gif) {
            Quill.get().setContents({ ops: [] });
          } else {
            const parsedDelta = JSON.parse(delta);
            Quill.get().setContents(parsedDelta);
          }
        }
      }
    } catch {}
  };

  // eslint-disable-next-line
  const discard = () => {
    setEditing(false);
    setEdited(false);
    setOpenEmojis(false);
    setOpenGifs(false);

    if (!isCreateMode) {
      if (delta) {
        setActiveGif('');
      } else {
        setActiveGif(gif);
      }
    }

    if (Quill) {
      revert();
    }
  };

  // eslint-disable-next-line
  const submit = async () => {
    if (submitted) {
      return;
    }

    setSubmitted(true);

    try {
      if (!Quill) {
        throw new Error('Empty Quill');
      }

      if (!uRef) {
        throw new Error('Empty uRef task');
      }

      if (isCreateMode) {
        const deltaJson = Quill.getDelta();
        const totalOps = deltaJson?.ops?.length;

        if (isNumber(totalOps) && totalOps > 0) {
          const lastOp = deltaJson.ops[totalOps - 1];

          if (isString(lastOp?.insert)) {
            const text = lastOp.insert;
            const indexEscape = text.lastIndexOf('\n');

            if (text === '\n' || text === '\t') {
              deltaJson.ops[totalOps - 1].insert = '';
            } else if (text && indexEscape + 1 >= text.length) {
              let newText = text.substring(0, indexEscape);

              if (newText.lastIndexOf('\n') + 1 >= newText) {
                newText = text.substring(0, newText.lastIndexOf('\n'));
              }

              deltaJson.ops[totalOps - 1] = {
                insert: text.substring(0, indexEscape),
              };
            }
          }
        }

        if (isFunction(onSubmit)) {
          onSubmit();
        }

        // const res = await ProfileAPI.USER_TASKS.writeComment()
        const delta = activeGif ? '' : JSON.stringify(deltaJson);
        const headers = getAuthenticatedHeaders(user);
        const { success } = await writeCommentInNoteRequest(
          uRef,
          delta,
          activeGif,
          headers
        );

        if (success) {
          if (Quill) {
            Quill.get().setContents({ ops: [] });
            setEdited(false);
            setActiveGif('');
            setOpenGifs(false);
          }

          if (isFunction(onSubmitCallback)) {
            onSubmitCallback(true);
          }
        } else if (isFunction(onSubmitCallback)) {
          onSubmitCallback(false);
        }
      } else if (isEditMode) {
        // update
        if (!refId && !commentId) {
          throw new Error('ref id required');
        }

        if (isFunction(onSubmit)) {
          onSubmit(refId);
        }

        const deltaJson = Quill.getDelta();
        const totalOps = deltaJson?.ops?.length;

        if (isNumber(totalOps) && totalOps > 0) {
          const lastOp = deltaJson.ops[totalOps - 1];

          if (isString(lastOp?.insert)) {
            const text = lastOp.insert;
            const indexEscape = text.lastIndexOf('\n');

            if (text === '\n' || text === '\t') {
              deltaJson.ops[totalOps - 1].insert = '';
            } else if (text && indexEscape + 1 >= text.length) {
              let newText = text.substring(0, indexEscape);

              if (newText.lastIndexOf('\n') + 1 >= newText) {
                newText = text.substring(0, newText.lastIndexOf('\n'));
              }

              deltaJson.ops[totalOps - 1] = {
                insert: text.substring(0, indexEscape),
              };
            }
          }
        }

        const currentDeltaInString = activeGif ? '' : JSON.stringify(deltaJson);
        const headers = getAuthenticatedHeaders(user);
        const { success } = await updateCommentFromNoteRequest(
          commentId || refId,
          uRef,
          currentDeltaInString,
          activeGif,
          headers
        );

        if (!success) {
          revert();
        }

        if (isFunction(onSubmitCallback)) {
          onSubmitCallback(success);
        }

        setEditing(false);
        setEdited(false);

        if (isFunction(setEditingId)) {
          setEditingId('');
        }

        if (isMobileView() && mobileView) {
          discard();
        }
      }

      setSubmitted(false);
    } catch (err) {
      setSubmitted(false);

      if (isFunction(onSubmitCallback)) {
        onSubmitCallback(false);
      }
    } finally {
      setSubmitted(false);
    }
  };

  useEffect(() => {
    if (
      (!editingId || !mobileView) &&
      isEditMode &&
      editing &&
      editingId !== commentId
    ) {
      discard();
    }
  }, [editingId, commentId, mobileView, id, editing, isEditMode, discard]);

  /**
   * Update local delta state when 'delta' prop changes
   */
  useEffect(() => {
    try {
      if (
        editingId &&
        mobileView &&
        editing &&
        isEditMode &&
        id !== v4Id.current
      ) {
        if (gif) {
          setActiveGif(gif);
        } else {
          const parsedDelta = JSON.parse(delta);
          Quill.get().setContents(parsedDelta);
          Quill.get().focus();
          setActiveGif('');
          setFocused(true);
        }
      }

      if (!isEmpty(id) && id !== v4Id.current) {
        v4Id.current = id;
      }

      if (!editing && delta !== storedDelta.current) {
        const parsedDelta = JSON.parse(delta);
        Quill.get().setContents(parsedDelta);
        storedDelta.current = delta;
      }

      if (!editing && gif !== activeGif) {
        setActiveGif(gif);
      }
    } catch {}
  }, [
    id,
    mobileView,
    delta,
    gif,
    editingId,
    Quill,
    editing,
    isEditMode,
    activeGif,
    setActiveGif,
  ]);

  const onRemoveGif = () => {
    setActiveGif('');

    if (Quill) {
      if (!isCreateMode) {
        Quill.get().setContents({ ops: [] });
      }

      Quill.get().focus();
      setFocused(true);
    }
  };

  const onSelectGif = (val = '') => {
    setActiveGif(val || '');

    if (!isCreateMode && Quill) {
      // clear
      Quill.get().setContents({ ops: [] });
    }
  };

  const isFocused = (focused || isEditMode) && editing;

  useEffect(() => {
    const subscriber = fromEvent(document, 'click').subscribe((evt) => {
      if (!evt) {
        return;
      }
      const target = evt.target ? evt.target : evt.srcElement;

      if (target && Quill && Quill.get && !activeGif && isCreateMode) {
        if (Quill.get().hasFocus()) {
          setFocused(true);

          if (!focused) {
            setOpenGifs(false);
            setOpenEmojis(false);
          }
        } else {
          setFocused(false);
        }
      }
    });

    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, [Quill, ready, activeGif, focused, isCreateMode, setFocused]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (submitted || (!editing && !isCreateMode) || activeGif) {
        return;
      }

      if (isMobileView()) {
        return;
      }

      if (e.shiftKey && e.key === '@' && isFocused) {
        promptMentions();
      } else if (e.keyCode === 27 && showMention) {
        // esc character
        hideMentions();
      }

      if (e.key === 'Escape' && (isFocused || Quill.get().hasFocus())) {
        if (Quill?.get) {
          setFocused(false);
          Quill.get().blur();
          hideMentions();
        }
      } else if (
        e.key === 'Enter' &&
        e.ctrlKey &&
        !e.shiftKey &&
        edited &&
        editing &&
        (isFocused || Quill.get().hasFocus())
      ) {
        if (Quill?.get) {
          setFocused(false);
          Quill.get().blur();
        }

        // post comment
        e.preventDefault();
        submit();
      }
    };

    const subscriber = fromEvent(document, 'keydown').subscribe(onKeyDown);

    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };

    // eslint-disable-next-line
  }, [
    cursorBounds,
    submitted,
    activeGif,
    edited,
    isCreateMode,
    isFocused,
    editing,
    Quill,
    showMention,
    // eslint-disable-next-line
    Quill?.get(),
    setFocused,
    submit,
  ]);

  /**
   * Address scroll issue ios mobile
   */
  useEffect(() => {
    const isMobile = isMobileView();
    const parent = editableRef?.current;
    const dom = parent?.firstElementChild;

    if (isMobile && dom) {
      if (editing && !openGifs) {
        disableBodyScroll(dom);
      } else {
        enableBodyScroll(dom);
      }
    }

    return () => {
      try {
        enableBodyScroll(dom);
      } catch {}
    };
  }, [editing, openGifs, isEditMode, mobileView, isThemeDarkMode]);

  return (
    <div
      className={cx(styles.comment_input, {
        [styles.comment_input_blur]: !focused && editing && !isEditMode,
        [styles.comment_input_focus]: isFocused,
        [styles.comment_input_blur_dark]:
          !focused && editing && isThemeDarkMode,
        [styles.comment_input_focus_dark]: isFocused && isThemeDarkMode,
        [styles.comment_input_not_editing]: !editing,
        [styles.comment_input_editing]: editing,
        [className]: isString(className) && !isEmpty(className),
      })}
    >
      <div className={styles.scrolling_container}>
        <div
          className={cx(styles.editable, {
            [styles.editable_edit_mode]: editing,
            [styles.editable_edit_mode_dark]: editing && isThemeDarkMode,
            [styles.editable_not_edit_mode]: !editing,
            [styles.editable_gif]: isGif,
            [styles.editable_edit_mode_mobile]: editing && mobileView,
          })}
          ref={editableRef}
          id={domIdRef.current}
        ></div>
      </div>

      {Quill?.get &&
      allowMention &&
      ((editing && isEditMode) || isCreateMode) &&
      connectedUsers?.length &&
      !isGif ? (
        <Mention
          editing
          getDescriptionDom={() => editableRef?.current}
          close={() => hideMentions()}
          open={() => setShowMention(true)}
          getQuillEditor={() => Quill?.get() || null}
          users={connectedUsers}
          show={showMention}
          cursorBounds={cursorBounds}
          startRange={showMentionRange}
        />
      ) : (
        <></>
      )}
      {isGif && (
        <GifPreview
          isThemeDarkMode={isThemeDarkMode}
          gif={activeGif}
          id={v4Id.current}
          remove={isCreateMode || (isEditMode && editing)}
          onRemove={onRemoveGif}
        />
      )}
      <div
        id={toolbarIdRef.current}
        className={cx(styles.formats, styles.flex_row_xy, {
          [styles.hide_element]: !editing,
        })}
      >
        <div
          className={cx(styles.left_actions, {
            [styles.left_actions_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.emoji, { [styles.hide_element]: activeGif })}
          >
            <IconButton
              icon={SmileIcon}
              disabled={!!activeGif}
              appearance="minimal"
              className={'emojiButton'}
              onClick={() => {
                setOpenEmojis(!openEmojis);
                setOpenGifs(false);
              }}
            />

            <MessageHover
              message={i18n('task_view_comments_insert_emoji')}
              className={cx(styles.action_tip, {
                [styles.hide_element]: openEmojis || activeGif,
              })}
            />

            {openEmojis && (
              <Emojis
                isThemeDarkMode={isThemeDarkMode}
                Quill={Quill}
                v4Id={v4Id.current}
                close={() => {
                  setOpenEmojis(false);
                }}
              />
            )}
          </div>
          <div
            className={cx(styles.gif, { [styles.gif_dark]: isThemeDarkMode })}
          >
            <Button
              appearance="minimal"
              onClick={() => {
                setOpenGifs(!openGifs);
                setOpenEmojis(false);
              }}
              className={'gifButton'}
            >
              <p className={'gifButton'}>GIF</p>
              <div className={cx(styles.cover, 'gifButton')}></div>
            </Button>
            <MessageHover
              message={i18n('task_view_comments_send_gif')}
              className={cx(styles.action_tip, {
                [styles.hide_element]: openGifs || openEmojis,
              })}
            />

            {openGifs && (
              <Gifs
                isThemeDarkMode={isThemeDarkMode}
                onSelect={onSelectGif}
                gif={activeGif}
                close={() => {
                  setOpenGifs(false);
                }}
                id={v4Id.current}
              />
            )}
          </div>
        </div>
        <div className={styles.right_actions}>
          {!submitted && !isCreateMode && (
            <CustomButton
              isThemeDarkMode={isThemeDarkMode}
              className={cx(styles.discard, {
                [styles.discard_dark]: isThemeDarkMode,
              })}
              variant={'primaryDiscard'}
              label={i18n('common_discard')}
              loading={submitted}
              onClick={() => {
                if (isFunction(onCancel)) {
                  onCancel();
                }

                if (isFunction(setEditingId)) {
                  setEditingId('');
                }

                discard();
              }}
            />
          )}

          <CustomButton
            isThemeDarkMode={isThemeDarkMode}
            className={styles.submit}
            variant={'primaryPurple'}
            label={!isCreateMode ? i18n('common_update') : i18n('common_post')}
            loading={submitted}
            disabled={!edited && !activeGif}
            onClick={submit}
          />
        </div>
      </div>
      {showEdit && !editing && !hideEditButton && (
        <div className={cx(styles.edit, styles.flex_row_xy)}>
          <Button
            appearance="minimal"
            className={cx(styles.flex_row_xy, styles.edit_button, {
              [styles.edit_button_dark]: isThemeDarkMode,
            })}
            onClick={onEdit}
          >
            <EditIcon />
            <p>{i18n('task_view_comments_edit_comment')}</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CommentInput;
