import { useRef } from 'react';

export const useDebounce = (cb = () => {}, delay = 100) => {
  const timeoutId = useRef(null);

  return function debounce(...args) {
    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      cb(...args);
      clearTimeout(timeoutId.current);
    }, delay);
  };
};

export default useDebounce;
