import cx from 'classnames';
import styles from './Properties.module.scss';
import Checkbox from 'src/components/checkbox';
import Reminders from './Reminders';
import k from 'src/constants/k';
import { CrossIcon, Button, TextInput, Spinner } from 'evergreen-ui';
import { withNoteViewContext } from '../Context';
import { NoteViewModalType } from 'src/types';
import { useEffect, useMemo, useState } from 'react';
import {
  head,
  isFunction,
  isNumber,
  toNumber,
  toString,
} from 'src/helpers/utils';
import {
  CalendarIcon,
  PrivacyLockIcon,
  SpacesRocketIcon,
  DeleteIcon,
  EyeCloseIcon,
  EyeIcon,
} from 'src/icons';
import { useDebounce } from 'src/hooks';
import { calendarInputUniqueClassname } from './Reminders';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { filter, isEmpty, last } from 'lodash';

export const PropertiesNoteViewContentContainerId =
  'PropertiesNoteViewContentContainerId';

const Properties = (props) => {
  const PropertiesSpacesListClassname = 'PropertiesSpacesListClassnameUnique';
  const PropertiesPrivacyListClassname = 'PropertiesPrivacyListClassnameUnique';
  const {
    closeModal,
    isEditMode,
    profileLoaded,
    setTargetSpace,
    spaceInfo,
    exposure,
    setPrivacy,
    exposurePasscode,
    remindersInfo,
    fetchingRemindersInfo,
    showModalType,
    updateAdvancedOptions,
    fetchingAdvancedOptions,
    advancedOptions,
    subscriberEditing,
    setSpaceTags,
    isThemeDarkMode,
    userSpaces,
    canUnsubscribe,
    canDelete,
  } = props;
  const spaces = useMemo(() => userSpaces || [], [userSpaces]);
  const space = useMemo(
    () => (profileLoaded ? spaceInfo : null),
    [spaceInfo, profileLoaded]
  );
  const spaceName = useMemo(() => space?.name || '', [space]);

  const [showSpacesList, setShowSpacesList] = useState(false);
  const [showPrivacyList, setShowPrivacyList] = useState(false);
  const [showPasscode, setShowPasscode] = useState(false);
  const [showReminders, setShowReminders] = useState(false);
  const [passcodeInput, setPasscodeInput] = useState(exposurePasscode);
  const reminderInfo = useMemo(() => {
    return remindersInfo?.enabled && remindersInfo?.reminders?.length > 0
      ? head(remindersInfo?.reminders)
      : null;
    // eslint-disable-next-line
  }, [remindersInfo, remindersInfo?.reminders]);
  const reminderDueInString = useMemo(() => {
    if (reminderInfo?.dueString) {
      const due = reminderInfo.dueString;
      const utcDate = new Date(due);
      const fullYear = utcDate.getFullYear();

      return `${
        k.MONTHS_INDEX[utcDate?.getMonth()]?.abv
      } ${utcDate.getDate()}, ${toString(fullYear)
        .split('')
        .slice(fullYear.length - 2, fullYear.length)
        .join('')}`;
    }

    return '';
  }, [reminderInfo]);

  /**
   * Close on outside area click
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (
        isFunction(closeModal) &&
        evt?.target?.classList?.contains(styles.properties)
      ) {
        closeModal();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [closeModal]);

  /**
   * Close spaces/privacy list on outside click
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (evt?.target?.classList?.contains(PropertiesSpacesListClassname)) {
        if (!isEmpty(evt?.target?.classList)) {
          const targetSpaceId = last(evt.target.classList);

          if (targetSpaceId !== PropertiesSpacesListClassname) {
            // select space
            const find = head(
              filter(
                spaces,
                (spaceInfo) =>
                  spaceInfo?.id === targetSpaceId ||
                  spaceInfo?.space_id === targetSpaceId
              )
            );

            if (
              isFunction(setTargetSpace) &&
              (!targetSpaceId || targetSpaceId === 'personal')
            ) {
              setTargetSpace('');

              if (isFunction(setSpaceTags)) {
                setSpaceTags([]);
              }
            } else if (find && isFunction(setTargetSpace)) {
              // set as space
              setTargetSpace(targetSpaceId);

              if (isFunction(setSpaceTags)) {
                setSpaceTags([]);
              }
            }

            setShowSpacesList(false);
          }
        }
      } else if (showSpacesList) {
        setShowSpacesList(false);
      }

      if (evt?.target?.classList?.contains(PropertiesPrivacyListClassname)) {
        if (!isEmpty(evt?.target?.classList)) {
          const targetExposure = toNumber(last(evt.target.classList));

          if (
            targetExposure &&
            targetExposure !== PropertiesPrivacyListClassname
          ) {
            if (isNumber(targetExposure) && isFunction(setPrivacy)) {
              setPrivacy(targetExposure, passcodeInput);

              if (targetExposure < 3) {
                setPasscodeInput('');
              }
            }

            setShowPrivacyList(false);
          }
        }

        return;
      } else if (showPrivacyList) {
        setShowPrivacyList(false);
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [
    setSpaceTags,
    showSpacesList,
    showPrivacyList,
    passcodeInput,
    setTargetSpace,
    setPrivacy,
    spaces,
  ]);

  const toggleShowSpaces = () => {
    setShowReminders(false);

    if (isEditMode || subscriberEditing) {
      return;
    } else {
      setShowSpacesList(!showSpacesList);
    }
  };

  const toggleShowPrivacyList = () => {
    setShowPrivacyList(!showPrivacyList);
    setShowReminders(false);
  };

  const updatePrivacyPasscode = useDebounce((exposure, exposurePasscode) => {
    if (isFunction(setPrivacy)) {
      setPrivacy(exposure, exposurePasscode);
    }
  }, 500);

  const onPrivacyPasscodeInput = (evt) => {
    const value = toString(evt?.target?.value);
    setPasscodeInput(value);
    updatePrivacyPasscode(exposure, value);
  };

  const promptDelete = () => {
    if (isFunction(showModalType)) {
      showModalType(NoteViewModalType.delete);
    }
  };

  return (
    <div className={styles.properties}>
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
        id={PropertiesNoteViewContentContainerId}
      >
        <div
          className={cx(styles.title, { [styles.title_dark]: isThemeDarkMode })}
        >
          <h1>{'Properties'}</h1>
        </div>
        <div
          className={cx(styles.space, styles.property_item, {
            [styles.space_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.flex_row_xy, styles.icon_label, {
              [styles.icon_label_dark]: isThemeDarkMode,
            })}
          >
            <SpacesRocketIcon height={20} width={20} />
            <p
              onClick={toggleShowSpaces}
              className={PropertiesSpacesListClassname}
            >
              {'Space'}
            </p>
          </div>
          <div
            className={cx(
              styles.flex_row_xy,
              styles.property_item_content,
              { [styles.property_item_content_dark]: isThemeDarkMode },
              PropertiesSpacesListClassname
            )}
          >
            <Button
              className={cx(styles.flex_row_xy, PropertiesSpacesListClassname)}
              onClick={toggleShowSpaces}
              appearance="minimal"
            >
              <p>{spaceName || 'Personal'}</p>
              <div
                className={cx(styles.cover, PropertiesSpacesListClassname)}
              ></div>
            </Button>
          </div>
          {showSpacesList && (
            <div
              className={cx(
                styles.spaces_list,
                { [styles.spaces_list_dark]: isThemeDarkMode },
                PropertiesSpacesListClassname
              )}
            >
              <ul
                className={cx(
                  styles.flex_column_xy,
                  PropertiesSpacesListClassname
                )}
              >
                <li
                  className={cx(
                    styles.flex_row_xy,
                    PropertiesSpacesListClassname
                  )}
                  key={`noteViewSpaceSelect${'personal'}`}
                >
                  <div>
                    <div
                      className={cx(styles.flex_row_xy, styles.default_space)}
                    >
                      <p>{'P'}</p>
                    </div>
                  </div>
                  <p>{'Personal'}</p>
                  <div
                    className={cx(
                      styles.cover,
                      PropertiesSpacesListClassname,
                      'personal'
                    )}
                  ></div>
                </li>
                {spaces
                  .filter((space) => space?.id || space?.space_id)
                  .map((spaceInfo) => {
                    const targetSpaceId = spaceInfo?.id || spaceInfo?.space_id;
                    const targetSpaceName = spaceInfo?.name || '';
                    const targetSpaceImage = spaceInfo?.image;

                    return (
                      <li
                        className={cx(
                          styles.flex_row_xy,
                          PropertiesSpacesListClassname
                        )}
                        key={`noteViewSpaceSelect${targetSpaceId}`}
                      >
                        <div>
                          {!targetSpaceImage && (
                            <div
                              className={cx(
                                styles.flex_row_xy,
                                styles.default_space
                              )}
                            >
                              <p>{head(targetSpaceName)}</p>
                            </div>
                          )}

                          {targetSpaceImage && (
                            <img
                              src={targetSpaceImage}
                              alt={`${targetSpaceName} space avatar`}
                            />
                          )}
                        </div>
                        <p>{targetSpaceName}</p>
                        <div
                          className={cx(
                            styles.cover,
                            PropertiesSpacesListClassname,
                            targetSpaceId
                          )}
                        ></div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
        <div
          className={cx(styles.reminder, styles.property_item, {
            [styles.reminder_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.flex_row_xy, styles.icon_label, {
              [styles.icon_label_dark]: isThemeDarkMode,
            })}
          >
            <CalendarIcon height={20} width={20} />
            <p>{'Reminder'}</p>
          </div>
          <div
            className={cx(styles.flex_row_xy, styles.property_item_content, {
              [styles.property_item_content_dark]: isThemeDarkMode,
            })}
          >
            <Button
              appearance="minimal"
              onClick={() => {
                if (fetchingRemindersInfo) {
                  return;
                }

                setShowReminders(!showReminders);
              }}
              className={styles.flex_row_xy}
            >
              {!fetchingRemindersInfo && (
                <p className={styles.reminder_placeholder}>
                  {!reminderInfo ? 'Set a reminder' : reminderDueInString}
                </p>
              )}
              {fetchingRemindersInfo && <Spinner height={16} width={16} />}
              <div
                className={cx(styles.cover, calendarInputUniqueClassname)}
              ></div>
            </Button>
          </div>
          {showReminders && <Reminders close={() => setShowReminders(false)} />}
        </div>
        <div className={cx(styles.privacy, styles.property_item)}>
          <div
            className={cx(styles.flex_row_xy, styles.icon_label, {
              [styles.icon_label_dark]: isThemeDarkMode,
            })}
          >
            <PrivacyLockIcon height={20} width={20} />
            <p onClick={toggleShowPrivacyList}>{'Privacy'}</p>
          </div>
          <div
            className={cx(
              styles.flex_row_xy,
              styles.property_item_content,
              { [styles.property_item_content_dark]: isThemeDarkMode },
              PropertiesPrivacyListClassname
            )}
          >
            <Button
              className={cx(styles.flex_row_xy, PropertiesPrivacyListClassname)}
              onClick={toggleShowPrivacyList}
              appearance="minimal"
            >
              <p>
                {exposure === 2
                  ? 'Public'
                  : exposure === 3
                  ? 'Password protected'
                  : 'Private'}
              </p>
              <div
                className={cx(styles.cover, PropertiesPrivacyListClassname)}
              ></div>
            </Button>
          </div>
          {showPrivacyList && (
            <div
              className={cx(
                styles.privacy_list,
                { [styles.privacy_list_dark]: isThemeDarkMode },
                PropertiesPrivacyListClassname
              )}
            >
              <ul
                className={cx(
                  styles.flex_column_xy,
                  PropertiesPrivacyListClassname
                )}
              >
                <li>
                  <h5>Private</h5>
                  <p>Only you and your subscribers can view this note</p>
                  <div
                    className={cx(
                      styles.cover,
                      PropertiesPrivacyListClassname,
                      '1'
                    )}
                  ></div>
                </li>
                <li>
                  <h5>Public</h5>
                  <p>Anyone can see this note</p>
                  <div
                    className={cx(
                      styles.cover,
                      PropertiesPrivacyListClassname,
                      '2'
                    )}
                  ></div>
                </li>
                <li>
                  <h5>Password protect</h5>
                  <div
                    className={cx(
                      styles.cover,
                      PropertiesPrivacyListClassname,
                      '3'
                    )}
                  ></div>
                </li>
              </ul>
            </div>
          )}
        </div>

        {exposure > 2 && (
          <div
            className={cx(styles.flex_column_xy, styles.passcode, {
              [styles.passcode_dark]: isThemeDarkMode,
            })}
          >
            <div className={styles.passcode_tip}>
              <p>Must be at least 5 characters to work.</p>
            </div>
            <div
              className={cx(styles.passcode_input, {
                [styles.passcode_input_dark]: isThemeDarkMode,
              })}
            >
              <TextInput
                placeholder="Passcode here"
                value={passcodeInput}
                onChange={onPrivacyPasscodeInput}
                maxLength={40}
                type={showPasscode ? 'text' : 'password'}
              />
              <Button
                onClick={() => setShowPasscode(!showPasscode)}
                className={styles.flex_row_xy}
                appearance="minimal"
              >
                {showPasscode ? <EyeCloseIcon /> : <EyeIcon />}{' '}
              </Button>
            </div>
          </div>
        )}

        <div className={cx(styles.flex_column_xy, styles.advanced_options)}>
          <div
            className={cx(styles.advanced_options_title, {
              [styles.advanced_options_title_dark]: isThemeDarkMode,
            })}
          >
            <h2>{'Advanced Options'} </h2>
          </div>

          {fetchingAdvancedOptions && (
            <div
              className={cx(styles.flex_row_xy, styles.loading, {
                [styles.loading_dark]: isThemeDarkMode,
              })}
            >
              <Spinner height={20} width={20} />
            </div>
          )}
          <div
            className={cx(
              styles.flex_row_xy,
              styles.allow_comments,
              styles.advanced_option_item,
              {
                [styles.advanced_option_item_dark]: isThemeDarkMode,
                [styles.hide_element]: fetchingAdvancedOptions,
              }
            )}
          >
            <Checkbox
              isThemeDarkMode={isThemeDarkMode}
              value={advancedOptions?.allowComments}
              onChange={() => {
                if (isFunction(updateAdvancedOptions)) {
                  updateAdvancedOptions({
                    allowComments: !advancedOptions?.allowComments,
                  });
                }
              }}
            />
            <p>{'Allow comments'}</p>
          </div>
          <div
            className={cx(styles.flex_row_xy, styles.advanced_option_item, {
              [styles.advanced_option_item_dark]: isThemeDarkMode,
              [styles.hide_element]: fetchingAdvancedOptions,
            })}
          >
            <Checkbox
              isThemeDarkMode={isThemeDarkMode}
              value={advancedOptions?.allowSubsEdit}
              onChange={() => {
                if (isFunction(updateAdvancedOptions)) {
                  updateAdvancedOptions({
                    allowSubsEdit: !advancedOptions?.allowSubsEdit,
                  });
                }
              }}
            />
            <p>{'Subscribers can edit'}</p>
          </div>
        </div>
        {isEditMode ? (
          <div
            className={cx(styles.flex_row_xy, styles.delete, {
              [styles.delete_dark]: isThemeDarkMode,
            })}
          >
            <Button
              onClick={promptDelete}
              appearance="minimal"
              className={cx(styles.flex_row_xy)}
            >
              <DeleteIcon />
              <p>{canDelete && !canUnsubscribe ? 'Delete' : 'Unsubscribe'}</p>
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(styles.flex_row_xy, styles.close, {
            [styles.close_dark]: isThemeDarkMode,
          })}
        >
          <Button
            onClick={() => {
              if (isFunction(closeModal)) {
                closeModal();
              }
            }}
            appearance="minimal"
            className={styles.flex_row_xy}
          >
            <CrossIcon />{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withNoteViewContext(Properties))
);
