import styles from './Navigation.module.scss';
import Sidebar from '../sidebar';

const Navigation = () => {
  return (
    <div className={styles.navigation}>
      <div className={styles.content}>
        <Sidebar />
      </div>
    </div>
  );
};

export default Navigation;
