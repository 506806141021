import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Overview.module.scss';

const paragraphUnpacker = (toUnPack, key = '') => {
  const paragraphs = [];

  for (let i = 0; i < toUnPack.length; i++) {
    const content = toUnPack[i];

    paragraphs.push(<p key={`paragraphUnpacker${key}${i}`}>{content}</p>);
  }

  return paragraphs;
};

const Overview = (props) => {
  const paragraphs = [];
  const contentSanitized = contents.content.slice(1);
  const startContentSanitized = contents.content.slice(0, 1);

  for (let i = 0; i < contentSanitized.length; i++) {
    const content = contentSanitized[i];
    paragraphs.push(<p key={`content${i}`}>{content}</p>);
  }

  const startCont = paragraphUnpacker(startContentSanitized, 'startCont');

  return (
    <div className={styles.content_wrapper}>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <br />
        {startCont}
        <br />
        {paragraphs}
        <br />
        <div className={innerStyles.img_wrapper}>
          <img src={'/docs-images/overview-img.png'} alt="overview-img" />
        </div>
        <br />
        <p>{contents.footer_content}</p>
      </div>
    </div>
  );
};

export default Overview;
