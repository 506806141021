const NotificationBellIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_309_8063)">
        <path
          d="M13.3334 11.3334H14.6667V12.6667H1.33337V11.3334H2.66671V6.66671C2.66671 5.25222 3.22861 3.89567 4.2288 2.89547C5.229 1.89528 6.58555 1.33337 8.00004 1.33337C9.41453 1.33337 10.7711 1.89528 11.7713 2.89547C12.7715 3.89567 13.3334 5.25222 13.3334 6.66671V11.3334ZM12 11.3334V6.66671C12 5.60584 11.5786 4.58843 10.8285 3.83828C10.0783 3.08813 9.06091 2.66671 8.00004 2.66671C6.93918 2.66671 5.92176 3.08813 5.17161 3.83828C4.42147 4.58843 4.00004 5.60584 4.00004 6.66671V11.3334H12ZM6.00004 14H10V15.3334H6.00004V14Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_8063">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotificationBellIcon;
