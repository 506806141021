const VipCrownIcon = (p) => {
  return (
    <svg
      width='8'
      height='9'
      viewBox='0 0 8 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...p}
    >
      <g clipPath='url(#clip0_1896_13532)'>
        <path
          d='M0.666992 6.83569H7.33375V7.50236H0.666992V6.83569ZM0.666992 2.16896L2.33368 3.16897L4.00037 1.16895L5.66706 3.16897L7.33375 2.16896V6.16901H0.666992V2.16896Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1896_13532'>
          <rect
            width='8.0001'
            height='8.0001'
            fill='white'
            transform='translate(0 0.501953)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VipCrownIcon;
