import { Component, createContext, forwardRef } from 'react';
import { withUserDataAndProfileSettings } from '../profile';
import {
  ceil,
  filter,
  head,
  includes,
  isEmpty,
  isFunction,
  isNil,
  isNumber,
  isObject,
  isString,
  pick,
  size,
  toLower,
} from 'lodash';
import {
  getNoteDataRequest,
  getNotesListByPageRequest,
  getUserNoteDraftRequest,
} from '../api/notes';
import {
  getAuthenticatedHeaders,
  getUserIdFromObject,
} from 'src/helpers/utils';
import { withSpacesAndUserSettings } from '../spaces';
import { withOfflineStorageAndUserSettings } from '../storage';
import { withNetworkSettings } from '../network';
import k from 'src/constants/k';

const { Consumer, Provider } = createContext();

export function withNoteDataSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class NotesDataManager extends Component {
  state = {
    // no filters
    personalNotesList: [],
    personalNotesPage: 1,
    personalNotesHasNext: false,
    personalNotesTotalCount: -1,

    dynamicNotesList: [],
    dynamicNotesPage: 1,
    dynamicNotesHasNext: false,
    dynamicNotesListTotalCount: -1,
    initialFetch: false,
    fetching: false,

    noteState: 'active',
    noteDraft: { fetching: true },
    tagsFilterApplied: [],
    deletedNotes: [],
    fetchingNotesData: [],
  };

  constructor(props) {
    super(props);
    this.setStateAsync = (obj) =>
      new Promise((resolve) => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps) {
    const { profileLoaded, isLoggedIn } = this.props;
    const { initialFetch } = this.state;

    if (prevProps?.isLoggedIn && initialFetch && !isLoggedIn) {
      // user logs out
      // so reset
      console.log('Reset notes storage');
      this.setState({
        personalNotesList: [],
        personalNotesPage: 1,
        personalNotesHasNext: false,
        personalNotesTotalCount: -1,

        dynamicNotesList: [],
        dynamicNotesPage: 1,
        dynamicNotesHasNext: false,
        dynamicNotesListTotalCount: -1,
        initialFetch: false,
        fetching: false,

        noteState: 'active',
        noteDraft: { fetching: true },
        tagsFilterApplied: [],
      });

      return;
    }

    if (
      !initialFetch &&
      ((profileLoaded && !prevProps?.profileLoaded) ||
        (isLoggedIn && !prevProps?.isLoggedIn))
    ) {
      this.check();
    }

    const { selectedSpace, selectedSpaceId } = this.props;

    if (
      selectedSpaceId !== prevProps?.selectedSpaceId ||
      (!isEmpty(selectedSpace) && isEmpty(prevProps?.selectedSpace))
    ) {
      // user switches space and we need to clear filters and fetch new list of notes
      this.setState(
        {
          noteState: 'active',
          tagsFilterApplied: [],
          dynamicNotesList: [],
          dynamicNotesPage: 1,
          dynamicNotesHasNext: false,
          fetching: false,
        },
        () => {
          // fetch here
          this.getNotesListByPage();
        }
      );
    }
  }

  clearStoredUserNoteDraft = async () => {
    const { user, profileLoaded, isLoggedIn, deleteStorageItem } = this.props;
    const userId = getUserIdFromObject(user);
    const cacheKey = `noteDraftUser${userId}`;

    if (isLoggedIn && profileLoaded && isFunction(deleteStorageItem)) {
      await deleteStorageItem(cacheKey);
    }
  };

  storeUserNoteDraft = async (noteDraftProperties = {}) => {
    const { user, profileLoaded, isLoggedIn, setStorageItem } = this.props;
    const { noteDraft } = this.state;
    if (
      !isLoggedIn ||
      !profileLoaded ||
      isNil(noteDraftProperties) ||
      !noteDraftProperties
    ) {
      return;
    }

    const userId = getUserIdFromObject(user);
    const cacheKey = `noteDraftUser${userId}`;
    await setStorageItem(cacheKey, { ...noteDraft, ...noteDraftProperties });
  };

  getUserNoteDraft = async (forceRefresh = false) => {
    const { user, profileLoaded, isLoggedIn, getStorageItem } = this.props;

    if (!isLoggedIn || !profileLoaded) {
      return;
    }

    const userId = getUserIdFromObject(user);
    const cacheKey = `noteDraftUser${userId}`;
    const cachedNoteDraft = await getStorageItem(cacheKey);
    const timestampNow = Date.now();
    const timestampNowSeconds = ceil(timestampNow / 1000);
    const headers = getAuthenticatedHeaders(user);
    // console.log('cache draft', cachedNoteDraft);

    if (
      !forceRefresh &&
      !isEmpty(cachedNoteDraft) &&
      isString(cachedNoteDraft?.noteId) &&
      !isEmpty(cachedNoteDraft?.noteId) &&
      size(cachedNoteDraft?.noteId) < 12 &&
      isObject(cachedNoteDraft)
    ) {
      const modifiedSeconds = cachedNoteDraft?.modifiedSeconds;
      if (
        timestampNowSeconds > modifiedSeconds &&
        // if stored last 2 minutes ago, then use cache data
        timestampNowSeconds - modifiedSeconds < 120
      ) {
        const noteDraftProperties = {
          ...cachedNoteDraft,
          fetching: false,
        };
        this.setState({
          noteDraft: noteDraftProperties,
        });
        return;
      }
    }

    const { draft } = await getUserNoteDraftRequest(headers);

    if (!isEmpty(draft)) {
      const noteDraftProperties = {
        ...draft,
        fetching: false,
        modifiedSeconds: timestampNowSeconds,
      };
      this.storeUserNoteDraft(noteDraftProperties);
      this.setState({
        noteDraft: noteDraftProperties,
      });
    }
  };

  storeUserInitialList = async (params = {}) => {
    const { profileLoaded, user, isLoggedIn, setStorageItem } = this.props;

    if (!profileLoaded || !isLoggedIn) {
      return;
    }

    const userId = getUserIdFromObject(user);
    const cacheKey = `userInitialList${userId}`;
    const initialData = pick(params, [
      'personalNotesList',
      'personalNotesPage',
      'personalNotesTotalCount',
      'personalNotesHasNext',
    ]);

    if (
      !isEmpty(initialData?.personalNotesList) &&
      isFunction(setStorageItem)
    ) {
      await setStorageItem(cacheKey, initialData);
    }
  };

  getUserInitialList = async () => {
    const { profileLoaded, user, isLoggedIn, getStorageItem } = this.props;
    if (!profileLoaded || !isLoggedIn) {
      return;
    }
    const userId = getUserIdFromObject(user);
    const cacheKey = `userInitialList${userId}`;

    if (isFunction(getStorageItem)) {
      const res = await getStorageItem(cacheKey);

      try {
        if (isString(res)) {
          return JSON.parse(res);
        } else {
          return res;
        }
      } catch {}
    }

    return null;
  };

  check = () => {
    const { profileLoaded, isLoggedIn } = this.props;
    const { initialFetch } = this.state;

    if (initialFetch || !profileLoaded || !isLoggedIn) {
      return;
    }

    this.setState(
      {
        initialFetch: true,
        fetching: true,
      },
      async () => {
        try {
          const { user, isLoggedIn, markUserIsOffline } = this.props;

          if (!isLoggedIn) {
            return;
          }

          // fetch user draft
          this.getUserNoteDraft();

          const initialListData = await this.getUserInitialList();

          if (!isEmpty(initialListData?.personalNotesList)) {
            await this.setStateAsync({
              ...pick(initialListData, [
                'personalNotesList',
                'personalNotesPage',
                'personalNotesTotalCount',
                'personalNotesHasNext',
              ]),
              fetching: false,
            });
          }

          const { noteState } = this.state;
          const headers = getAuthenticatedHeaders(user);
          const { list, hasNext, errorMessage, networkError, totalCount } =
            await getNotesListByPageRequest(1, noteState, '', '', '', headers);

          if (!errorMessage && !networkError) {
            const { personalNotesPage: updatedPersonalNotesPage } = this.state;

            const noteListParams = {
              personalNotesList: [...list],
              personalNotesPage: 1,
              personalNotesTotalCount: totalCount,
              personalNotesHasNext: hasNext,
            };

            if (updatedPersonalNotesPage === 1) {
              this.setState({
                ...noteListParams,
                fetching: false,
              });
            }

            this.storeUserInitialList(noteListParams);
          } else if (networkError) {
            // mark user offline
            if (isFunction(markUserIsOffline)) {
              markUserIsOffline();
            }
          }
        } catch {
          this.setState({ fetching: false });
        }
      }
    );
  };

  storeNoteData = async (noteId = '', noteData = null) => {
    // store via localforage
    const { setStorageItem, getStorageItem } = this.props;
    const cacheKey = `noteById${toLower(noteId)}`;

    if (!noteId || isEmpty(noteId) || !noteData || isNil(noteData)) {
      return;
    }

    let cachedNoteData = await getStorageItem(cacheKey);

    if (isString(cachedNoteData) && !isEmpty(cachedNoteData)) {
      try {
        cachedNoteData = JSON.parse(cachedNoteData);
      } catch {
        cachedNoteData = null;
      }
    }

    const timestampNow = Date.now();
    const timestampNowSeconds = ceil(timestampNow / 1000);

    if (!isEmpty(cachedNoteData)) {
      cachedNoteData = {
        ...cachedNoteData,
        ...noteData,
        modifiedSeconds: timestampNowSeconds,
      };
    } else {
      cachedNoteData = { ...noteData, modifiedSeconds: timestampNowSeconds };
    }

    await setStorageItem(cacheKey, cachedNoteData);

    return cachedNoteData;
  };

  clearTagsApplied = () => {
    const showDynamicNotesList = this.checkIfToShowDynamicList();

    this.setState({ tagsFilterApplied: [] }, () => {
      if (showDynamicNotesList) {
        this.getNotesListByPage();
      }
    });
  };

  getStoredNoteData = async (noteId = '') => {
    const { getStorageItem } = this.props;
    const timestampNow = Date.now();
    const timestampNowSeconds = ceil(timestampNow / 1000);
    const cacheKey = `noteById${toLower(noteId)}`;

    if (!noteId) {
      return {};
    }

    const cachedNoteData = await getStorageItem(cacheKey);
    const cacheModifiedSeconds = cachedNoteData?.modifiedSeconds;
    const canUseNoteData =
      isNumber(cacheModifiedSeconds) &&
      // if less than 10 minutes ago
      timestampNowSeconds - cacheModifiedSeconds < 600 &&
      !isEmpty(cachedNoteData?.descData?.desc);
    return { canUseNoteData, noteData: cachedNoteData };
  };

  getLatestNoteEnrichedData = async (
    noteId = '',
    noteRefId = '',
    passcode = ''
  ) => {
    const { fetchingNotesData } = this.state;
    const { user, markUserIsOffline } = this.props;
    const headers = getAuthenticatedHeaders(user);

    if (!includes(fetchingNotesData, noteRefId)) {
      await this.setStateAsync({
        fetchingNotesData: [...fetchingNotesData, noteRefId],
      });
    }

    const { noteData, notFound, errorMessage, passcodeRequired, networkError } =
      await getNoteDataRequest(noteRefId, noteId, passcode, headers);
    const { fetchingNotesData: updatedFetchingNotes } = this.state;

    await this.setStateAsync({
      fetchingNotesData: filter(
        updatedFetchingNotes,
        (id) => id !== noteRefId && !isEmpty(id) && isString(id)
      ),
    });

    if (networkError && isFunction(markUserIsOffline)) {
      markUserIsOffline();
    }

    if (notFound || toLower(errorMessage)?.includes('private')) {
      return { notFound: true };
    }

    if (passcodeRequired) {
      return { passcodeRequired: true };
    }

    if (errorMessage || networkError) {
      return { networkError, error: errorMessage || 'Network offline' };
    }

    const modifiedNoteData = await this.storeNoteData(
      toLower(noteId),
      noteData
    );

    return modifiedNoteData;
  };

  getNotesListByPage = async (fromNoteState = false) => {
    try {
      const {
        selectedSpaceId,
        user,
        isLoggedIn,
        markUserIsOffline,
        profileLoaded,
      } = this.props;
      const {
        tagsFilterApplied,
        noteState,
        dynamicNotesPage,
        personalNotesPage,
      } = this.state;
      const filterId = head(tagsFilterApplied);
      const dynamicList = this.checkIfToShowDynamicList();
      const headers = getAuthenticatedHeaders(user);

      if (!isLoggedIn || !profileLoaded || (fromNoteState && !dynamicList)) {
        return;
      }

      this.setState({ fetching: true });
      const { list, hasNext, totalCount, errorMessage, networkError } =
        await getNotesListByPageRequest(
          dynamicList ? dynamicNotesPage : personalNotesPage,
          noteState,
          selectedSpaceId || 'personal',
          filterId,
          '',
          headers
        );

      if (errorMessage || networkError) {
        // do not store

        if (networkError && isFunction(markUserIsOffline)) {
          markUserIsOffline();
        }

        return;
      }

      if (dynamicList) {
        this.setState({
          dynamicNotesList: [...list],
          dynamicNotesListTotalCount: totalCount,
          dynamicNotesHasNext: hasNext,
          fetching: false,
        });
      } else {
        const notesListData = {
          personalNotesList: [...list],
          personalNotesTotalCount: totalCount,
          personalNotesHasNext: hasNext,
        };

        this.setState(
          {
            ...notesListData,
            fetching: false,
          },
          () => {
            if (personalNotesPage < 2 && !isEmpty(list)) {
              this.storeUserInitialList(notesListData);
            }
          }
        );
      }
    } catch {
      this.setState({ fetching: false });
    }
  };

  applyTagFilter = (id = '') => {
    if (!isEmpty(id)) {
      this.setState({ tagsFilterApplied: [id] }, this.getNotesListByPage);
    }
  };

  navToPrevPage = () => {
    const { dynamicNotesPage, personalNotesPage, fetching, initialFetch } =
      this.state;
    const showDynamicNotesList = this.checkIfToShowDynamicList();

    if (
      !initialFetch ||
      fetching ||
      (showDynamicNotesList && dynamicNotesPage < 2) ||
      (!showDynamicNotesList && personalNotesPage < 2)
    ) {
      return;
    }

    const page = showDynamicNotesList
      ? dynamicNotesPage - 1
      : personalNotesPage - 1;
    this.navToNewPage(page);
  };

  navToNextPage = () => {
    const {
      dynamicNotesPage,
      dynamicNotesHasNext,
      personalNotesPage,
      personalNotesHasNext,
      fetching,
      initialFetch,
    } = this.state;
    const showDynamicNotesList = this.checkIfToShowDynamicList();

    if (
      !initialFetch ||
      fetching ||
      (showDynamicNotesList && !dynamicNotesHasNext) ||
      (!showDynamicNotesList && !personalNotesHasNext)
    ) {
      return;
    }

    const page = showDynamicNotesList
      ? dynamicNotesPage + 1
      : personalNotesPage + 1;

    this.navToNewPage(page);
  };

  navToNewPage = (page) => {
    const showDynamicNotesList = this.checkIfToShowDynamicList();
    const { dynamicNotesHasNext, personalNotesHasNext } = this.state;

    if (
      (showDynamicNotesList && !dynamicNotesHasNext) ||
      (!showDynamicNotesList && !personalNotesHasNext)
    ) {
      return;
    }

    if (isNumber(page) && page > 0) {
      this.setState(
        {
          ...(showDynamicNotesList
            ? { dynamicNotesPage: page }
            : { personalNotesPage: page }),
        },
        this.getNotesListByPage
      );
    }
  };

  applyNoteState = (noteState = '') => {
    const { initialFetch } = this.state;
    const { selectedSpaceId, selectedSpace, profileLoaded, isLoggedIn } =
      this.props;
    const { tagsFilterApplied } = this.state;
    const showDynamicNotesList =
      !noteState ||
      noteState !== 'active' ||
      !isEmpty(tagsFilterApplied) ||
      (!isEmpty(selectedSpace) && !isEmpty(selectedSpaceId));

    if (!profileLoaded || !initialFetch || !isLoggedIn) {
      return;
    }

    this.setState(
      {
        noteState,
        ...(showDynamicNotesList && { dynamicNotesPage: 1 }),
        ...(!showDynamicNotesList && { personalNotesPage: 1 }),
      },
      () => this.getNotesListByPage(true)
    );
  };

  checkIfToShowDynamicList = () => {
    try {
      const { selectedSpaceId, selectedSpace } = this.props;
      const { tagsFilterApplied, noteState } = this.state;
      const showDynamicNotesList =
        !noteState ||
        noteState !== 'active' ||
        !isEmpty(tagsFilterApplied) ||
        (!isEmpty(selectedSpace) && !isEmpty(selectedSpaceId));
      return showDynamicNotesList;
    } catch {
      return false;
    }
  };

  resetNoteState = () => this.setState({ noteState: 'active' });

  refetchNotesList = () => {
    const {
      // personalNotesPage,
      // personalNotesList,
      // dynamicNotesPage,
      // dynamicNotesList,
      fetching,
      initialFetch,
    } = this.state;

    if (fetching || !initialFetch) {
      return;
    }

    this.getNotesListByPage();
  };

  resetPersonalNotestList = async (newNoteData = null) => {
    const { noteState, personalNotesList, personalNotesPage } = this.state;
    const { user, isUserNetworkOffline } = this.props;
    const headers = getAuthenticatedHeaders(user);

    if (
      !isEmpty(newNoteData) &&
      (isUserNetworkOffline ||
        (personalNotesPage < 2 &&
          size(personalNotesList) < k.USER_TASKS_MAX_BATCH))
    ) {
      const newPersonalNotesList = [
        { ...newNoteData, authorInfo: user, canEdit: true, canDelete: true },
        ...personalNotesList,
      ];

      this.setState({
        fetching: false,
        personalNotesList: newPersonalNotesList,
      });

      return;
    }

    const { list, hasNext, errorMessage, networkError, totalCount } =
      await getNotesListByPageRequest(
        personalNotesPage,
        noteState,
        '',
        '',
        '',
        headers
      );

    if (errorMessage && networkError) {
      return;
    }

    this.setState(
      {
        personalNotesList: [...list],
        personalNotesTotalCount: totalCount,
        personalNotesHasNext: hasNext,
        fetching: false,
      },
      () => {
        const showDynamicNotesList = this.checkIfToShowDynamicList();

        if (showDynamicNotesList) {
          this.getNotesListByPage();
        }
      }
    );
  };

  markDeletedNote = (noteId = '', noteRefId = '') => {
    const ids = [];
    const { deletedNotes } = this.state;

    if (noteId) {
      ids.push(noteId);
    }

    if (noteRefId) {
      ids.push(noteRefId);
    }

    if (!isEmpty(ids)) {
      this.setState(
        { deletedNotes: [...deletedNotes, ...ids] },
        this.refetchNotesList
      );
    }
  };

  render() {
    const { children } = this.props;
    const {
      personalNotesList,
      deletedNotes,
      personalNotesPage,
      personalNotesHasNext,
      personalNotesTotalCount,
      dynamicNotesList,
      dynamicNotesHasNext,
      dynamicNotesPage,
      dynamicNotesListTotalCount,
      fetching,
      initialFetch,
      tagsFilterApplied,
      noteState,
      noteDraft,
      fetchingNotesData,
    } = this.state;
    const fetchingNotes = fetching || !initialFetch;
    const showDynamicNotesList = this.checkIfToShowDynamicList();
    const props = {
      fetchingNotesData,
      fetchingNotes,
      personalNotesList,
      personalNotesPage,
      personalNotesHasNext,
      personalNotesTotalCount,

      // for changing list
      // for fetching with filter
      // on either personal or custom space
      dynamicNotesList,
      dynamicNotesHasNext,
      dynamicNotesPage,
      dynamicNotesListTotalCount,

      // tag filter id
      tagsFilterApplied,
      noteState,
      showDynamicNotesList,
      deletedNotes,
      userNoteDraftProperties: noteDraft,
      markDeletedNote: this.markDeletedNote,
      navToNewPage: this.navToNewPage,
      navToNextPage: this.navToNextPage,
      navToPrevPage: this.navToPrevPage,
      getStoredNoteData: this.getStoredNoteData,
      getLatestNoteEnrichedData: this.getLatestNoteEnrichedData,
      applyNoteState: this.applyNoteState,
      resetNoteState: this.resetNoteState,
      getNotesListByPage: this.getNotesListByPage,
      clearTagsApplied: this.clearTagsApplied,
      applyTagFilter: this.applyTagFilter,
      getUserNoteDraft: this.getUserNoteDraft,
      storeNoteData: this.storeNoteData,
      storeUserNoteDraft: this.storeUserNoteDraft,
      refetchNotesList: this.refetchNotesList,
      resetPersonalNotestList: this.resetPersonalNotestList,
    };

    return <Provider value={props}> {children}</Provider>;
  }
}

export default withOfflineStorageAndUserSettings(
  withNetworkSettings(
    withUserDataAndProfileSettings(withSpacesAndUserSettings(NotesDataManager))
  )
);
