const FilePaperIcon = (p) => {
  return (
    <svg 
      width="18" 
      height="18" 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path 
        d="M15 0.5C15.5967 0.5 16.169 0.737053 16.591 1.15901C17.0129 1.58097 17.25 2.15326 17.25 2.75V4.25H15.75V13.25C15.75 13.8467 15.5129 14.419 15.091 14.841C14.669 15.2629 14.0967 15.5 13.5 15.5H3C2.40326 15.5 1.83097 15.2629 1.40901 14.841C0.987053 14.419 0.75 13.8467 0.75 13.25V11.75H12.75V13.25C12.75 13.4337 12.8175 13.611 12.9395 13.7483C13.0616 13.8856 13.2298 13.9733 13.4122 13.9948L13.5 14C13.6837 14 13.861 13.9325 13.9983 13.8105C14.1356 13.6884 14.2233 13.5202 14.2448 13.3378L14.25 13.25V2H4.5C4.3163 2.00002 4.139 2.06747 4.00172 2.18954C3.86444 2.31161 3.77674 2.47981 3.75525 2.66225L3.75 2.75V10.25H2.25V2.75C2.25 2.15326 2.48705 1.58097 2.90901 1.15901C3.33097 0.737053 3.90326 0.5 4.5 0.5H15Z" 
        fill="#718096"
      />
    </svg>
  )
}

export default FilePaperIcon;