import Mention from '@tiptap/extension-mention';

const MentionExtended = Mention.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      description: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-description'),
        renderHTML: (attributes) => {
          if (!attributes.description) {
            return {};
          }

          return {
            'data-description': attributes.description,
          };
        },
      },
      userId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-userId'),
        renderHTML: (attributes) => {
          if (!attributes.userId) {
            return {};
          }

          return {
            'data-userId': attributes.userId,
          };
        },
      },
    };
  },
});

export default MentionExtended;
