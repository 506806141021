import { useEffect, useRef, useState } from 'react';
import styles from './Gifs.module.scss';
import cx from 'classnames';
import i18n from 'src/locales';
import k from 'src/constants/k';
import { isMobileView } from 'src/helpers/utils';
import { isFunction, isEmpty, toString, toLower } from 'lodash';
import { fromEvent } from 'rxjs';
import { TextInput, SearchIcon } from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'src/lib/bodyScrollLock.min';

const availableGIFs = k.availableGIFs;

const Gifs = ({
  gif = '',
  isThemeDarkMode = false,
  onSelect,
  close,
  id = '',
}) => {
  const uniqueClassName = `comment-input-gif-${id}`;
  const gifListRef = useRef(null);
  const gifWrapperRef = useRef(null);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  /**
   * Fix mobile browser issue of scroll not working
   */
  useEffect(() => {
    const dom = gifListRef?.current;

    if (dom && isMobileView()) {
      disableBodyScroll(dom);
    }

    return () => {
      if (dom) {
        enableBodyScroll(dom);
      }
    };
  }, [gif, id, onSelect]);

  useEffect(() => {
    const subscriber = fromEvent(document, 'click').subscribe((evt) => {
      if (!evt) {
        return;
      }

      const target = evt?.target;

      if (
        target?.classList?.length &&
        target.classList.contains(uniqueClassName) &&
        isFunction(onSelect)
      ) {
        const selectedKey = `${target.classList[target.classList.length - 1]}`;

        if (selectedKey === uniqueClassName || target?.nodeName === 'input') {
          return;
        }

        if (selectedKey) {
          const find = availableGIFs.filter(
            (gif) => gif && gif.key === selectedKey
          );
          const gif = find[0];

          if (gif && gif.link) {
            onSelect(gif.link);

            if (isFunction(close)) {
              close();
            }
          }
        }
      } else if (
        (target?.classList?.length && target.classList.contains(styles.gifs)) ||
        (target?.classList?.length &&
          target?.classList.contains('gifButton')) ||
        (target?.parentElement &&
          target?.parentElement?.classList?.length &&
          target?.parentElement?.classList.contains('gifButton')) ||
        gifWrapperRef?.current?.contains(target) ||
        target?.nodeName === 'path' ||
        target?.nodeName === 'svg' ||
        target?.nodeName === 'input'
      ) {
        return;
      } else if (isFunction(close)) {
        close();
      }
    });

    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, [gif, onSelect, close, uniqueClassName, gifListRef]);

  const goSearchGif = (key = '') => {
    if (!key) {
      setFiltered([]);
    }
    const newFiltered = [];
    key = toLower(key);

    for (let i = availableGIFs.length - 1; i >= 0; i--) {
      const gifInfo = availableGIFs[i];
      if (gifInfo && gifInfo.searchKey) {
        const str = `${gifInfo.searchKey}`;

        if (str.includes(key)) {
          newFiltered.push({ ...gifInfo });
        }
      }
    }

    setFiltered(newFiltered);
  };

  return (
    <div
      className={cx(
        styles.gifs,
        { [styles.gifs_dark]: isThemeDarkMode },
        uniqueClassName
      )}
      ref={gifWrapperRef}
    >
      <div
        className={cx(
          styles.search_gif,
          { [styles.search_gif_dark]: isThemeDarkMode },
          styles.flex_row_xy,
          uniqueClassName
        )}
      >
        <TextInput
          onChange={(evt) => {
            if (!evt) {
              return;
            }

            const target = evt.target ? evt.target : evt.srcElement;
            if (!target) {
              return;
            }
            const value = toString(target.value);
            setSearch(value);
            goSearchGif(value);
          }}
          value={search}
          className={cx(uniqueClassName)}
          maxLength={40}
          placeholder={'Search'}
        />{' '}
        <div>
          <SearchIcon />
          <div className={cx(styles.cover, uniqueClassName)}></div>
        </div>
      </div>
      {isEmpty(filtered) && !isEmpty(search) && (
        <div
          className={cx(
            styles.empty,
            { [styles.empty_dark]: isThemeDarkMode },
            styles.flex_row_xy
          )}
        >
          <h5>{i18n('common_no_results')}</h5>
        </div>
      )}
      <ul ref={gifListRef} className={cx(uniqueClassName)}>
        {(search ? filtered : availableGIFs).map((gif, idx) => (
          <li
            key={`gif-${idx}-${gif.key}`}
            className={cx(uniqueClassName, gif.key)}
          >
            <img src={gif.link} alt={'tenor gif'} className={uniqueClassName} />
            <div className={cx(styles.cover, uniqueClassName, gif.key)}></div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Gifs;
