const NonePriorityIcon = (p) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M0.75 0.75H9.35017C9.52036 0.750091 9.68716 0.79756 9.8319 0.88709C9.97664 0.97662 10.0936 1.10468 10.1697 1.25692L10.8333 2.58333H16.3333C16.5764 2.58333 16.8096 2.67991 16.9815 2.85182C17.1534 3.02373 17.25 3.25689 17.25 3.5V13.5833C17.25 13.8264 17.1534 14.0596 16.9815 14.2315C16.8096 14.4034 16.5764 14.5 16.3333 14.5H10.4832C10.313 14.4999 10.1462 14.4524 10.0014 14.3629C9.8567 14.2734 9.73974 14.1453 9.66367 13.9931L9 12.6667H2.58333V18.1667H0.75V0.75Z"
        fill="#D8DAE5"
      />
    </svg>
  );
};

export default NonePriorityIcon;
