export const applyDarkModeTheme = () => {
  const html = document.querySelector('html');
  const body = document.querySelector('body');
  const root = document.getElementById('root');
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');

  if (html && !html?.classList?.contains('darkmode_background')) {
    html.classList.add('darkmode_background');
  }

  if (body && !body?.classList?.contains('darkmode_background')) {
    body.classList.add('darkmode_background');
  }

  if (root && !root?.classList?.contains('darkmode_background')) {
    root.classList.add('darkmode_background');
  }

  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', '#13171c');
  }
};

export const applyLightThemeColor = (withModal = false) => {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');

  if (metaThemeColor) {
    if (withModal) {
      metaThemeColor.setAttribute('content', '#858585');
    } else {
      metaThemeColor.setAttribute('content', '#ffffff');
    }
  }
};

export const applyDarkThemeColor = (withModal = false) => {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  if (metaThemeColor) {
    if (withModal) {
      metaThemeColor.setAttribute('content', '#0A0C0F');
    } else {
      metaThemeColor.setAttribute('content', '#13171c');
    }
  }
};

export const removeDarkModeTheme = () => {
  const html = document.querySelector('html');
  const body = document.querySelector('body');
  const root = document.getElementById('root');
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');

  if (html) {
    html.classList.remove('darkmode_background');
  }

  if (body) {
    body.classList.remove('darkmode_background');
  }

  if (root) {
    root.classList.remove('darkmode_background');
  }

  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', '#ffffff');
  }
};
