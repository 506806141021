import styles from './SignUp.module.scss';
import cx from 'classnames';
import LogoIcon from '../logo-icon';
import i18n from 'src/locales';
import Checkbox from 'src/components/checkbox';
import Button from 'src/components/button';
import k from 'src/constants/k';
import UserInputs, { isCorrectEmailFormat } from 'src/lib/UserInputs';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';
import { withModalPromptSettings } from 'src/managers/modal';
import { useState } from 'react';
import { TextInputField, TickCircleIcon } from 'evergreen-ui';
import { isEmpty, isFunction, size, toLower, toString } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { isMobileView, timeout } from 'src/helpers/utils';

const SignUp = (props) => {
  const {
    showLoginPrompt,
    isThemeDarkMode,
    signUpUser,
    closeModal,
    blockClosingOfModal,
    unBlockClosingOfModal,
  } = props;
  const [success, setSuccess] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const submit = async () => {
    try {
      const usernameIsValid = UserInputs.validUsername(username);

      if (size(username) < k.USERNAME_PROPERTIES.minLength) {
        setUsernameError(
          `Username must be at least ${k.USERNAME_PROPERTIES.minLength} characters.`
        );
        return;
      }

      if (!usernameIsValid) {
        if (username?.includes('.')) {
          setUsernameError('Must not contain dot character.');
        } else if (username?.includes(',')) {
          setUsernameError('Must not contain comma.');
        } else {
          setUsernameError('Username must not contain special characters.');
        }

        return;
      }

      if (size(password) < k.PASSWORD_PROPERTIES.minLength) {
        setPasswordError(
          `Password must have at least ${k.PASSWORD_PROPERTIES.minLength} characters`
        );
        return;
      }

      if (!isCorrectEmailFormat(email)) {
        setEmailError('Invalid email');
        return;
      }

      if (size(fullName) < 2) {
        setErrorMessage('Display name must have at least 2 characters.');
        return;
      }

      if (!agreeToTerms) {
        setErrorMessage('You must agree to our Privacy Policy and Terms');
        return;
      }

      if (submitted) {
        return;
      }

      setSubmitted(true);
      setErrorMessage('');

      if (isFunction(blockClosingOfModal) && !isMobileView()) {
        blockClosingOfModal();
      }

      if (isFunction(signUpUser)) {
        await signUpUser(
          username,
          password,
          email,
          fullName,
          async () => {
            setSuccess(true);

            await timeout(1_500);

            if (isFunction(closeModal)) {
              closeModal(true);
            }

            if (isMobileView() && isFunction(props?.history?.push)) {
              props.history.push('/user');
            }
          },
          (params = {}) => {
            const { errorMessage, networkError } = params;
            const sanitizedErrorMessage = toLower(toString(errorMessage || ''));

            if (networkError) {
              // toast
            } else if (sanitizedErrorMessage?.includes('taken')) {
              if (sanitizedErrorMessage?.includes('email')) {
                setErrorMessage('Email is already taken!');
                return;
              } else if (sanitizedErrorMessage?.includes('username')) {
                setErrorMessage('Username is already taken!');
                return;
              }
            }

            if (
              sanitizedErrorMessage?.includes('invalid') &&
              sanitizedErrorMessage?.includes('username')
            ) {
              setErrorMessage('');
              setUsernameError('Username must not contain special characters.');
              return;
            }

            setErrorMessage('Something went wrong. Try again.');
          }
        );
      }

      if (isFunction(unBlockClosingOfModal) && !isMobileView()) {
        unBlockClosingOfModal();
      }
    } catch {
    } finally {
      setSubmitted(false);
    }
  };

  const clearErrors = () => {
    setErrorMessage('');
    setUsernameError('');
    setPasswordError('');
    setEmailError('');
  };

  const onLogin = () => {
    if (isMobileView() && isFunction(props?.history?.push)) {
      props.history.push('/login');
      return;
    }

    if (isFunction(showLoginPrompt)) {
      showLoginPrompt();
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.signup)}>
      <div className={styles.content}>
        {!success && (
          <>
            <div className={cx(styles.logo, styles.flex_row_xy)}>
              <LogoIcon darkMode={isThemeDarkMode} />
            </div>
            <div
              className={cx(styles.message, {
                [styles.message_dark]: isThemeDarkMode,
              })}
            >
              <h2>{i18n('sign_up_message')} </h2>
            </div>

            <div
              className={cx(styles.username, styles.flex_row_xy, {
                [styles.username_dark]: isThemeDarkMode,
              })}
            >
              <TextInputField
                placeholder={'Your username here'}
                label={'Username'}
                onChange={(evt) => {
                  setUsername(evt?.target?.value || '');
                  clearErrors();
                }}
                maxLength={50}
                value={username}
                validationMessage={
                  isEmpty(usernameError) || !usernameError
                    ? false
                    : usernameError
                }
              />
            </div>
            <div
              className={cx(styles.password, styles.flex_row_xy, {
                [styles.password_dark]: isThemeDarkMode,
              })}
            >
              <TextInputField
                type="password"
                placeholder={'Your password here'}
                label={'Password'}
                maxLength={k.PASSWORD_PROPERTIES.maxLength}
                onChange={(evt) => {
                  setPassword(evt?.target?.value || '');
                  clearErrors();
                }}
                value={password}
                validationMessage={
                  isEmpty(passwordError) || !passwordError
                    ? false
                    : passwordError
                }
              />
            </div>
            <div
              className={cx(styles.email, styles.flex_row_xy, {
                [styles.email_dark]: isThemeDarkMode,
              })}
            >
              <TextInputField
                placeholder={'youremail@domain.com'}
                label={'E-mail'}
                maxLength={250}
                onChange={(evt) => {
                  setEmail(evt?.target?.value || '');
                  clearErrors();
                }}
                value={email}
                validationMessage={
                  isEmpty(emailError) || !emailError ? false : emailError
                }
              />
            </div>
            <div
              className={cx(styles.email, styles.flex_row_xy, {
                [styles.email_dark]: isThemeDarkMode,
              })}
            >
              <TextInputField
                placeholder={'Pick your display name'}
                label={'Full name'}
                maxLength={50}
                onChange={(evt) => {
                  setFullName(evt?.target?.value || '');
                  clearErrors();
                }}
                value={fullName}
              />
            </div>

            <div className={styles.cta}>
              <div
                className={cx(styles.agree_terms, styles.flex_row_xy, {
                  [styles.agree_terms_dark]: isThemeDarkMode,
                  [styles.agree_terms_active_dark]:
                    isThemeDarkMode && agreeToTerms,
                })}
              >
                <Checkbox
                  label={''}
                  isThemeDarkMode={isThemeDarkMode}
                  value={agreeToTerms}
                  onChange={() => {
                    setAgreeToTerms(!agreeToTerms);
                  }}
                />
                <p onClick={() => setAgreeToTerms(!agreeToTerms)}>
                  By creating an account you agree with our{' '}
                  <Link
                    onClick={() => {
                      if (isFunction(closeModal)) {
                        closeModal();
                      }
                    }}
                    to="/documentation/privacy"
                  >
                    Privacy Policy{' '}
                  </Link>
                  and{' '}
                  <Link
                    onClick={() => {
                      if (isFunction(closeModal)) {
                        closeModal();
                      }
                    }}
                    to="/documentation/terms"
                  >
                    Terms of Service
                  </Link>
                </p>
              </div>

              {!isEmpty(errorMessage) && (
                <div className={cx(styles.status)}>
                  <div>
                    <h5>{errorMessage}</h5>
                  </div>
                </div>
              )}

              <Button
                label={'Submit'}
                variant={'primaryPurple'}
                onClick={submit}
                loading={submitted}
                disabled={submitted}
                isThemeDarkMode={isThemeDarkMode}
                className={cx(styles.flex_center_all, styles.confirm)}
              />
              <div
                className={cx(styles.flex_row_xy, styles.redirect_login, {
                  [styles.redirect_login_dark]: isThemeDarkMode,
                })}
              >
                <p>
                  Already have an account?{' '}
                  <span onClick={onLogin}> Log in</span>
                </p>
              </div>
            </div>
          </>
        )}

        {success && (
          <div className={cx(styles.flex_row_xy, styles.success_created)}>
            <TickCircleIcon color="success" />
            <p>Successfully created!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withMobileToastSettings(withRouter(SignUp)))
);
