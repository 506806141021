import styles from './Notes.module.scss';
import Filters from './Filters';
import PersonalSpace from './PersonalSpace';
import SpaceSelected from './SpaceSelected';
import NotesList from './NotesList';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { isMobileViewScreen } from '.';

const Notes = (props) => {
  const { isPersonalSpaceSelected } = props;

  return (
    <div className={styles.notes}>
      <div className={styles.space_info}>
        {isPersonalSpaceSelected && <PersonalSpace />}
        {!isPersonalSpaceSelected && <SpaceSelected />}
      </div>
      {!isMobileViewScreen() && <Filters />}
      <NotesList />
    </div>
  );
};

export default withUserDataAndProfileSettings(withSpacesAndUserSettings(Notes));
