const SpacesRocketIcon = (p) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_27558)">
        <path
          d="M18.4854 23.3336C17.7136 25.1796 16.2458 26.6474 14.3997 27.4193C12.5537 26.6474 11.0859 25.1796 10.3141 23.3336H12.9507C13.3311 23.9041 13.8222 24.3976 14.3997 24.7838C14.9772 24.3988 15.4684 23.9041 15.8499 23.3336H18.4854ZM21.3997 17.2728L23.7331 19.9188V22.1669H5.06641V19.9188L7.39974 17.2728V10.5003C7.39974 6.43675 10.3211 2.97875 14.3997 1.69775C18.4784 2.97875 21.3997 6.43675 21.3997 10.5003V17.2728ZM20.5481 19.8336L19.0664 18.1536V10.5003C19.0664 7.79592 17.2347 5.33192 14.3997 4.17692C11.5647 5.33192 9.73307 7.79475 9.73307 10.5003V18.1536L8.25141 19.8336H20.5481ZM14.3997 12.8336C13.7809 12.8336 13.1874 12.5878 12.7498 12.1502C12.3122 11.7126 12.0664 11.1191 12.0664 10.5003C12.0664 9.88142 12.3122 9.28792 12.7498 8.85034C13.1874 8.41275 13.7809 8.16692 14.3997 8.16692C15.0186 8.16692 15.6121 8.41275 16.0497 8.85034C16.4872 9.28792 16.7331 9.88142 16.7331 10.5003C16.7331 11.1191 16.4872 11.7126 16.0497 12.1502C15.6121 12.5878 15.0186 12.8336 14.3997 12.8336Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_27558">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.399902)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SpacesRocketIcon;
