import cx from 'classnames';
import styles from './Delete.module.scss';
import { withNoteViewContext } from '../Context';
import { Button, CrossIcon, Spinner } from 'evergreen-ui';
import { isFunction } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const Delete = (props) => {
  const { closeModal, isThemeDarkMode, taskId, deleteNote, deletingNote } =
    props;
  const [deleting, setDeleting] = useState(false);

  const onClose = () => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  };

  const confirm = () => {
    try {
      setDeleting(true);

      if (isFunction(deleteNote)) {
        deleteNote();
      }
    } finally {
      setDeleting(false);
    }
  };

  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (deleting || deletingNote) {
        return;
      }

      if (evt?.target?.classList?.contains(styles.delete_note)) {
        if (isFunction(closeModal)) {
          closeModal();
        }
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [closeModal, deleting, deletingNote, taskId]);

  return (
    <div className={cx(styles.flex_row_xy, styles.delete_note)}>
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        {deleting || deletingNote ? (
          <div
            className={cx(styles.flex_row_xy, styles.loading, {
              [styles.loading_dark]: isThemeDarkMode,
            })}
          >
            <Spinner height={24} width={24} />
          </div>
        ) : (
          <></>
        )}

        {!deleting && !deletingNote ? (
          <>
            <div
              className={cx(styles.title, {
                [styles.title_dark]: isThemeDarkMode,
              })}
            >
              <h3>Delete Note</h3>
            </div>
            <div
              className={cx(styles.message, {
                [styles.message_dark]: isThemeDarkMode,
              })}
            >
              <p>
                Are you sure you want to delete this note? This cannot be
                undone.
              </p>
            </div>

            <div className={cx(styles.flex_row_xy, styles.actions)}>
              <Button
                appearance="minimal"
                className={cx(
                  styles.cancel,
                  { [styles.cancel_dark]: isThemeDarkMode },
                  styles.flex_row_xy
                )}
                onClick={onClose}
              >
                <p>Cancel</p>
              </Button>
              <Button
                onClick={confirm}
                appearance="minimal"
                className={cx(styles.confirm, styles.flex_row_xy)}
              >
                <p>Delete</p>
              </Button>
            </div>
            <div
              className={cx(styles.flex_row_xy, styles.close, {
                [styles.close_dark]: isThemeDarkMode,
              })}
            >
              <Button
                onClick={onClose}
                appearance="minimal"
                className={styles.flex_row_xy}
              >
                <CrossIcon />
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Delete));
