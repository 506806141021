import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './Dashboard.module.scss';
import BottomBar from 'src/components/bottom-bar';
import Spaces from './spaces';
import SidePanel from './side-panel';
import HeaderMobile from 'src/layout/header-mobile';
import Filters from './Filters';
import Notes from './Notes';
import { MobileDashboardView } from 'src/types';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withNoteDataSettings } from 'src/managers/notes';
import { useWindowResize } from 'src/hooks';
import { clearAllBodyScrollLocks } from 'src/lib/bodyScrollLock.min';
import { withModalPromptSettings } from 'src/managers/modal';
import { isIosMobile } from 'src/helpers/utils';

export function isMobileViewScreen() {
  return window.innerWidth <= 800;
}

export const Dashboard = (props) => {
  const containerDom = useRef();
  const panelContainerDom = useRef();
  const notesListContainerDom = useRef();
  const {
    isLoggedIn,
    isThemeDarkMode,
    modalActive,
    profileLoaded,
    fetchingNotes,
  } = props;
  const [panelExpand, setPanelExpand] = useState(true);

  const [mobileDashboardView, setMobileDashboardView] = useState(
    MobileDashboardView.home
  );

  const { availableHeight, availableWidth } = useWindowResize();

  useEffect(() => {
    const dom = containerDom?.current;
    const panelDom = panelContainerDom?.current;
    const newHeightInPixels = `${availableHeight}px`;

    if (dom) {
      if (isMobileViewScreen()) {
        dom.style.height = newHeightInPixels;
        dom.style.minHeight = '';
      } else {
        dom.style.minHeight = newHeightInPixels;
      }
    }

    if (panelDom) {
      panelDom.style.height = newHeightInPixels;
    }
  }, [availableHeight]);

  useEffect(() => {
    if (!isMobileViewScreen()) {
      setMobileDashboardView(MobileDashboardView.home);
    }
  }, [availableWidth]);

  /**
   * Clear scroll locks on unmount
   */
  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  /**
   * Scroll top when fetching new notes
   */
  useEffect(() => {
    const dom = notesListContainerDom?.current;

    if (isMobileViewScreen() && fetchingNotes) {
      if (dom?.scrollTop && dom?.scrollTo) {
        dom.scrollTo(0, 0);
      }
    }
  }, [fetchingNotes]);

  if (!isLoggedIn && profileLoaded) {
    return <Redirect to={'/'} />;
  }

  return (
    <div
      ref={containerDom}
      className={cx(styles.dashboard, styles.flex_row_xy)}
    >
      {(!mobileDashboardView ||
        mobileDashboardView === MobileDashboardView.home) && (
        <>
          <div className={cx(styles.flex_row_xy, styles.content)}>
            <div className={cx(styles.flex_row_xy, styles.header_mobile)}>
              {isMobileViewScreen() && <HeaderMobile />}
              {isMobileViewScreen() && <Filters />}
            </div>

            <div
              ref={panelContainerDom}
              className={cx(styles.flex_row_xy, styles.panel, {
                [styles.panel_expand]: panelExpand,
                [styles.panel_shrink]: !panelExpand,
              })}
            >
              <SidePanel
                expand={panelExpand}
                togglePanel={() => setPanelExpand(!panelExpand)}
              />
            </div>
            <div
              ref={notesListContainerDom}
              className={cx(styles.flex_row_xy, styles.right, {
                [styles.right_expand]: panelExpand,
                [styles.right_shrink]: !panelExpand,
                [styles.right_dark]: isThemeDarkMode,
                [styles.right_light]: !isThemeDarkMode,
                [styles.right_mobile_no_modal]:
                  isIosMobile() && isMobileViewScreen() && modalActive,
              })}
            >
              <Notes />
            </div>
          </div>
        </>
      )}
      {mobileDashboardView === MobileDashboardView.spaces && (
        <>
          <HeaderMobile />
          <Spaces />
        </>
      )}
      {isMobileViewScreen() && (
        <BottomBar
          mobileDashboardView={mobileDashboardView}
          setMobileDashboardView={(val) => setMobileDashboardView(val)}
        />
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(
    withModalPromptSettings(withNoteDataSettings(withRouter(Dashboard)))
  )
);
