import TimePicker from 'react-time-picker';
import Calendar from 'react-calendar';
import cx from 'classnames';
import Checkbox from 'src/components/checkbox';
import styles from './Reminders.module.scss';
import './Reminders.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withNoteViewContext } from '../Context';
import {
  head,
  isFunction,
  isString,
  isNumber,
  hoursToHHMMAFormat,
  correctHHMMAFormat,
  getHoursAndMinutesFromHHMMA,
} from 'src/helpers/utils';
import { useAuth, useDebounce } from 'src/hooks';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { setNoteRemindersRequest } from 'src/managers/api/notes';

export const calendarInputUniqueClassname = 'calendarInputUniqueClassname';

const Reminders = (props) => {
  const wrapRef = useRef(null);
  const maxDate = useRef(new Date('2050-12-30T16:00:00.000Z'));
  const minDate = useRef(new Date('2021-12-30T16:00:00.000Z'));
  const currentDateRef = useRef(new Date());
  const { remindersInfo, updateRemindersInfo, uRef, close, isThemeDarkMode } =
    props;
  const { getAuthenticatedHeaders } = useAuth(props);
  const enabled = Boolean(remindersInfo?.enabled);
  const reminderInfo = useMemo(() => {
    return remindersInfo?.reminders?.length > 0
      ? head(remindersInfo?.reminders)
      : null;
  }, [remindersInfo]);
  const [currentDate, setCurrentDate] = useState(
    reminderInfo?.dueString
      ? new Date(reminderInfo?.dueString)
      : currentDateRef.current
  );
  const [currentDateUtc, setCurrentDateUtc] = useState(
    currentDateRef.current.toISOString()
  );
  const [hoursInStr, setHoursInStr] = useState(
    reminderInfo?.hours &&
      isString(reminderInfo?.hours) &&
      correctHHMMAFormat(reminderInfo?.hours)
      ? reminderInfo?.hours
      : isNumber(reminderInfo?.hours) && reminderInfo?.hours < 24
      ? hoursToHHMMAFormat(reminderInfo?.hours)
      : '00:00'
  );

  const onTimeChange = (value) => {
    if (!value) {
      return;
    }

    const updateCurrentDate = currentDate;
    const [hh, mm] = getHoursAndMinutesFromHHMMA(value);

    if (isNumber(hh) && isNumber(mm)) {
      updateCurrentDate.setHours(hh);
      updateCurrentDate.setMinutes(mm);
    }

    const newCurrentDateUtc = updateCurrentDate.toISOString();

    setHoursInStr(value);
    setCurrentDate(updateCurrentDate);
    setCurrentDateUtc(newCurrentDateUtc);
    updateReminders(value, newCurrentDateUtc);
  };

  const onDateChange = (utcInString) => {
    if (!utcInString) {
      return;
    } else {
      const valueDate = new Date(utcInString);
      const [hh, mm] = getHoursAndMinutesFromHHMMA(hoursInStr);

      if (isNumber(hh) && isNumber(mm)) {
        valueDate.setHours(hh);
        valueDate.setMinutes(mm);
      }

      const newCurrentDateUtc = valueDate.toISOString();

      setCurrentDate(valueDate);
      setCurrentDateUtc(newCurrentDateUtc);
      updateReminders(hoursInStr, newCurrentDateUtc);
    }
  };

  const updateEnableReminder = useDebounce(async (updatedReminders) => {
    try {
      const headers = getAuthenticatedHeaders();

      await setNoteRemindersRequest(
        uRef,
        [],
        updatedReminders?.enabled,
        headers
      );

      if (updatedReminders?.enabled && currentDateUtc) {
        updateReminders(hoursInStr, currentDateUtc);
      }
    } catch {}
  }, 500);

  const updateReminders = useDebounce(
    async (paramHoursInStr = '', paramCurrentDateUtc = '') => {
      if (!paramCurrentDateUtc) {
        return;
      }

      const updated = {
        ...remindersInfo,
        enabled: true,
        reminders: [{ dueString: paramCurrentDateUtc, hours: paramHoursInStr }],
      };
      const reminder = {
        hours: paramHoursInStr,
        due: paramCurrentDateUtc,
        minutesBefore: [30, 60],
        remindSubscribers: true,
      };
      const headers = getAuthenticatedHeaders();

      if (isFunction(updateRemindersInfo)) {
        updateRemindersInfo(updated);
      }

      await setNoteRemindersRequest(
        uRef,
        [reminder],
        remindersInfo?.enabled,
        headers
      );
    },
    300
  );

  const toggleEnabled = () => {
    const newValue = !enabled;
    const updated = {
      ...remindersInfo,
      enabled: newValue,
    };

    if (isFunction(updateRemindersInfo)) {
      updateRemindersInfo(updated);
      updateEnableReminder(updated);
    }
  };

  /**
   * Close reminder drop on outside click
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      const target = evt?.target;
      if (target && wrapRef?.current) {
        if (
          target.parentElement === wrapRef.current ||
          target?.classList?.contains(calendarInputUniqueClassname) ||
          wrapRef.current.contains(target)
        ) {
          return;
        }
      }

      if (isFunction(close)) {
        close();
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [remindersInfo, close]);

  return (
    <div
      ref={wrapRef}
      className={cx(
        styles.reminders,
        { [styles.reminders_dark]: isThemeDarkMode },
        calendarInputUniqueClassname
      )}
    >
      <div
        className={cx(
          styles.flex_row_xy,
          styles.check,
          calendarInputUniqueClassname
        )}
      >
        <Checkbox
          isThemeDarkMode={isThemeDarkMode}
          className={calendarInputUniqueClassname}
          value={enabled}
          onChange={toggleEnabled}
        />
        <p onClick={toggleEnabled} className={calendarInputUniqueClassname}>
          {'Enable reminder'}
        </p>
      </div>
      <div
        className={cx(
          styles.time,
          { [styles.time_dark]: isThemeDarkMode },
          styles.flex_row_xy,
          calendarInputUniqueClassname
        )}
      >
        <p className={calendarInputUniqueClassname}> {'Pick a time'}</p>
        <TimePicker
          onChange={onTimeChange}
          isOpen={false}
          value={hoursInStr}
          format={'hh:mm a'}
          disableClock
          clearIcon={<></>}
          className={calendarInputUniqueClassname}
        />
      </div>
      <div
        className={cx(
          styles.calendar,
          { [styles.calendar_dark]: isThemeDarkMode },
          calendarInputUniqueClassname
        )}
      >
        <p className={calendarInputUniqueClassname}>{'Pick a date:'}</p>
        <Calendar
          className={calendarInputUniqueClassname}
          maxDate={maxDate.current}
          minDate={minDate.current}
          onChange={onDateChange}
          value={currentDate}
          tileDisabled={({ activeStartDate, date, view }) => {
            if (view === 'decade') {
              return currentDateRef.current.getFullYear() >= 2023
                ? date.getFullYear() < currentDateRef.current.getFullYear()
                : date.getFullYear() < 2023;
            } else if (view === 'month') {
              return date.getTime() - currentDateRef.current.getTime() < -8.6e7; // 24 hours
            }
          }}
        />
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Reminders));
