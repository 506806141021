import { Network } from '@capacitor/network';
import { isFunction } from 'lodash';
import { useEffect } from 'react';
import { isUserNetworkOnline, timeout } from 'src/helpers/utils';

const NetworkStatusHelper = (props) => {
  const { children, markUserIsOffline, markUserIsOnline } = props;

  /**
   * Listen to network status changes
   */
  useEffect(() => {
    Network.addListener('networkStatusChange', (status) => {
      const check = async () => {
        const isOnline = await isUserNetworkOnline();

        if (isOnline) {
          if (isFunction(markUserIsOnline)) {
            timeout(500).then(() => markUserIsOnline());
          }
        } else if (isFunction(markUserIsOffline)) {
          timeout(500).then(() => markUserIsOffline());
        }
      };

      check();
    });

    return () => {
      Network.removeAllListeners();
    };
  }, [markUserIsOnline, markUserIsOffline]);

  return <>{children} </>;
};

export default NetworkStatusHelper;
