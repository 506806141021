import cx from 'classnames';
import k from 'src/constants/k';
import styles from './SearchUnit.module.scss';
import PriorityIcon from 'src/components/priority-icon';
import {
  getUserDisplayName,
  head,
  isNumber,
  last,
  toString,
} from 'src/helpers/utils';
import { withRouter } from 'react-router-dom';
import {
  isDocsOrWordFile,
  isExcelFile,
  isTextFile,
  isZipArchiveFile,
} from 'src/helpers/files';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { useMemo } from 'react';
import { withModalPromptSettings } from 'src/managers/modal';
import { isEmpty, isFunction, toUpper } from 'lodash';
import { Avatar } from '@chakra-ui/react';
import { withSpacesAndUserSettings } from 'src/managers/spaces';

const SearchUnit = (props) => {
  const { searchUnit = {}, closeModal, isThemeDarkMode, selectSpace } = props;
  const {
    title = '',
    type = '',
    priority = -1,
    authorInfo,
    space = null,
    createdInMins = 0,
    totalMembers = 0,
    link = '',
    image = '',
    id,
  } = searchUnit;
  const taskId = useMemo(
    () => searchUnit?.noteId || searchUnit?.task_id,
    [searchUnit]
  );
  const uRef = useMemo(
    () => searchUnit?.noteRefId || searchUnit?.uRef,
    [searchUnit]
  );

  const createdTimestamp = createdInMins * 1000 * 60;
  const createdDate = new Date(createdTimestamp);
  const isFile = type === 'file';
  const isTask = type === 'task';
  const isSpace = type === 'space';

  // files
  const filePreviewImages = k.FILE_PREVIEW_IMAGES;
  const fileName = isFile ? toString(title).replace(/\s/g, '').trim() : '';
  const urlSanitized = toString(link).replace(/\s/g, '').trim();

  // types
  const isText = useMemo(() => isTextFile(fileName), [fileName]);
  const isPdfFile = useMemo(
    () =>
      (fileName.indexOf('.pdf') > -1 &&
        fileName.indexOf('.pdf') + '.pdf'.length >= fileName.length) ||
      (urlSanitized.indexOf('.pdf') > -1 &&
        urlSanitized.indexOf('.pdf') + '.pdf'.length >= urlSanitized.length),
    [fileName, urlSanitized]
  );

  const isDocsOrWord = useMemo(
    () => isDocsOrWordFile(fileName, link),
    [fileName, link]
  );

  const isZipArchive = useMemo(
    () => isZipArchiveFile(fileName, link),
    [fileName, link]
  );
  const isExcel = useMemo(() => isExcelFile(fileName, link), [fileName, link]);
  const fileIconSrc = useMemo(
    () =>
      isText
        ? filePreviewImages.text
        : isPdfFile
        ? filePreviewImages.pdf
        : isDocsOrWord
        ? filePreviewImages.word
        : isZipArchive
        ? filePreviewImages.zip
        : isExcel
        ? filePreviewImages.excel
        : filePreviewImages.general,
    [filePreviewImages, isText, isExcel, isDocsOrWord, isZipArchive, isPdfFile]
  );

  const navigateToTask = () => {
    if (isSpace) {
      if (id) {
        navigateToSpace(id);
      }

      return;
    }

    if (taskId && uRef) {
      const uppercaseTaskId = toUpper(toString(taskId));

      if (props?.history?.push && uppercaseTaskId) {
        props.history.push(`/note/${uppercaseTaskId}?u=${uRef}`);
      }

      if (isFunction(closeModal)) {
        closeModal();
      }
    }
  };

  const navigateToSpace = (spaceId) => {
    if (isFunction(closeModal)) {
      closeModal();
    }

    if (isFunction(selectSpace)) {
      selectSpace(spaceId);
    }
  };

  return (
    <div
      className={cx(styles.flex_row_xy, styles.search_unit)}
      onClick={navigateToTask}
    >
      <div
        className={cx(styles.flex_row_xy, styles.icon, {
          [styles.icon_light]: !isThemeDarkMode && !isSpace,
          [styles.icon_dark]: isThemeDarkMode,
        })}
      >
        {!isFile && !isSpace && isTask ? (
          <PriorityIcon
            noHover
            priority_key={
              isNumber(priority) && k.TASK_PRIORITIES[priority - 1]
                ? k.TASK_PRIORITIES[priority - 1].key
                : ''
            }
          />
        ) : isFile && !isSpace ? (
          <img
            className={styles.file_icon}
            src={fileIconSrc}
            alt={'file icon'}
          />
        ) : (
          <></>
        )}

        {isSpace ? (
          <div className={cx(styles.flex_row_xy, styles.space_logo)}>
            {image ? (
              <img src={image} alt={'space logo '} />
            ) : (
              <div
                className={cx(styles.flex_row_xy, styles.space_logo_default)}
              >
                <h5>{toUpper(`${head(title)}${last(title)}`)}</h5>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.info}>
        <div
          className={cx(styles.flex_row_xy, styles.title, {
            [styles.title_dark]: isThemeDarkMode,
          })}
        >
          <h5>{title}</h5>{' '}
        </div>
        <div className={styles.flex_row_xy}>
          {!isEmpty(authorInfo?.image) ? (
            <div className={cx(styles.flex_row_xy, styles.author_avatar)}>
              <Avatar
                height={'30px'}
                width={'30px'}
                background={'#000'}
                backgroundColor={'#000'}
                name={getUserDisplayName(authorInfo)}
                src={authorInfo?.image}
              />
            </div>
          ) : (
            <></>
          )}

          <div className={cx(styles.flex_row_xy, styles.texts)}>
            {isSpace ? (
              <h5
                className={cx(styles.space, {
                  [styles.space_dark]: isThemeDarkMode,
                })}
              >{`${totalMembers} members`}</h5>
            ) : (
              <></>
            )}
            {isTask || isFile ? (
              <>
                <h5
                  className={cx(styles.author, {
                    [styles.author_dark]: isThemeDarkMode,
                  })}
                >{`${authorInfo?.firstName} ${authorInfo?.lastName} /`}</h5>
                {space?.name ? (
                  <h5
                    className={cx(styles.space, {
                      [styles.space_dark]: isThemeDarkMode,
                    })}
                  >
                    {' '}
                    {space.name ? ` ${space.name} /` : ''}
                  </h5>
                ) : (
                  <></>
                )}
                <h5
                  className={cx(styles.date, {
                    [styles.date_dark]: isThemeDarkMode,
                  })}
                >{`${isFile ? 'Uploaded on' : 'Last updated on'} ${
                  k.MONTHS_INDEX[createdDate.getMonth()].abv
                } ${createdDate.getDate()}, ${createdDate.getFullYear()}`}</h5>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(withRouter(SearchUnit)))
);
