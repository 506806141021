const NoteListCtaIconDark = (p) => {
  return (
    <svg
      width='203'
      height='203'
      viewBox='0 0 203 203'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='101.5' cy='101.5' r='101.5' fill='#191F26' />
      <rect
        x='34.7947'
        y='58.5662'
        width='93.6905'
        height='121.762'
        rx='10.5'
        transform='rotate(-12.7842 34.7947 58.5662)'
        fill='#222A34'
        stroke='#2E3745'
        strokeWidth='3'
      />
      <path
        d='M152 159.5H79C73.201 159.5 68.5 154.799 68.5 149V47C68.5 41.201 73.201 36.5 79 36.5H127.988C130.79 36.5 133.476 37.6199 135.448 39.6105L159.46 63.8498C161.407 65.816 162.5 68.4717 162.5 71.2393V149C162.5 154.799 157.799 159.5 152 159.5Z'
        fill='#5F7088'
        stroke='#2E3745'
        strokeWidth='3'
      />
      <path
        d='M127 117C125.525 121.626 120.935 125 115.5 125C110.065 125 105.475 121.626 104 117'
        stroke='#2E3745'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <circle cx='92.5' cy='89.5' r='5.5' fill='#2E3745' />
      <circle cx='137.5' cy='89.5' r='5.5' fill='#2E3745' />
      <path
        d='M134 65V38L160 65H134Z'
        fill='#505D72'
        stroke='#2E3745'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='171'
        cy='22'
        r='18.5'
        fill='#5F7088'
        stroke='#2E3745'
        strokeWidth='3'
      />
      <path
        d='M171 14V30M163 22H179'
        stroke='#2E3745'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default NoteListCtaIconDark;
