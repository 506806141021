import cx from 'classnames';
import styles from './SavedSearchUnit.module.scss';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { toString } from 'lodash';

const SavedSearchUnit = (props) => {
  const savedSearchUnitUniqueClassname = 'savedSearchUnitUniqueClassname';
  const { isThemeDarkMode } = props;

  return (
    <div
      className={cx(
        styles.flex_row_xy,
        styles.saved_search_unit,
        {
          [styles.saved_search_unit_dark]: isThemeDarkMode,
        },
        savedSearchUnitUniqueClassname
      )}
      data-searchkey={props?.searchKey}
    >
      <p
        data-searchkey={props?.searchKey}
        className={savedSearchUnitUniqueClassname}
      >
        {toString(props?.searchKey || '')}
      </p>
    </div>
  );
};

export default withUserDataAndProfileSettings(SavedSearchUnit);
