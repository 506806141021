import cx from 'classnames';
import styles from './Filters.module.scss';
import i18n from 'src/locales';
import k from 'src/constants/k';
import { withNoteDataSettings } from 'src/managers/notes';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { FilterIcon } from 'src/icons';
import { Button } from 'evergreen-ui';
import { isFunction } from 'lodash';
import { isMobileViewScreen } from '.';
import { withModalPromptSettings } from 'src/managers/modal';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import MobileFilters from 'src/modals/mobile-filters';
import { useMemo } from 'react';

const NoteStates = (props) => {
  const {
    noteState,
    isThemeDarkMode,
    selectedSpace,
    selectedSpaceId,
    applyNoteState,
  } = props;

  const selectNoteState = (state = '') => {
    if (isFunction(applyNoteState)) {
      applyNoteState(state);
    }
  };

  const isNotPersonalSpace = useMemo(
    () => selectedSpace && selectedSpaceId !== 'personal' && selectedSpaceId,
    [selectedSpace, selectedSpaceId]
  );

  return (
    <div
      className={cx(styles.flex_row_xy, styles.note_states, {
        [styles.note_states_dark]: isThemeDarkMode,
      })}
    >
      <ul className={styles.flex_row_y}>
        <li
          className={cx(
            {
              [styles.is_active]: noteState === k.TASK_STATE_ACTIVE,
            },
            styles.flex_row_xy
          )}
        >
          <Button
            appearance="minimal"
            onClick={() => selectNoteState(k.TASK_STATE_ACTIVE)}
          >
            <h4>{i18n('user_task_view_active_title')}</h4>
          </Button>
        </li>
        <li
          className={cx(
            {
              [styles.is_active]: noteState === k.TASK_STATE_INACTIVE,
            },
            styles.flex_row_xy
          )}
        >
          <Button
            appearance="minimal"
            onClick={() => selectNoteState(k.TASK_STATE_INACTIVE)}
          >
            <h4>{i18n('user_task_view_complete_title')}</h4>
          </Button>
        </li>
        {!isNotPersonalSpace && (
          <li
            className={cx(
              {
                [styles.is_active]: noteState === k.TASK_STATE_CREATED,
              },
              styles.flex_row_xy
            )}
          >
            <Button
              appearance="minimal"
              onClick={() => selectNoteState(k.TASK_STATE_CREATED)}
            >
              <h4>{i18n('user_task_view_created_title')}</h4>
            </Button>
          </li>
        )}

        {/* <li
          className={cx(
            {
              [styles.is_active]: noteState === k.TASK_STATE_ARCHIVED,
            },
            styles.flex_row_xy
          )}
        >
          <Button
            appearance="minimal"
            onClick={() => selectNoteState(k.TASK_STATE_ARCHIVED)}
          >
            <h4>{i18n('user_task_view_archived_title')}</h4>
          </Button>
        </li> */}
      </ul>
    </div>
  );
};

const FilterButton = (props) => {
  const { setActiveModalDom, isThemeDarkMode } = props;

  const showFiltersForMobileScreen = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<MobileFilters />);
    }
  };

  return (
    <Button
      appearance="minimal"
      className={cx(styles.flex_row_xy, styles.filter_button, {
        [styles.filter_button_dark]: isThemeDarkMode,
      })}
      onClick={showFiltersForMobileScreen}
    >
      <FilterIcon height={'24px'} width={'24px'} />
    </Button>
  );
};

const Filters = (props) => {
  return (
    <div className={styles.filters}>
      <div className={cx(styles.flex_row_xy, styles.desktop)}>
        {!isMobileViewScreen() && <NoteStates {...props} />}
      </div>
      <div className={cx(styles.flex_row_xy, styles.mobile)}>
        {isMobileViewScreen() && <NoteStates {...props} />}
        <FilterButton {...props} />
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(
    withModalPromptSettings(withNoteDataSettings(Filters))
  )
);
