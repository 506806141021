const ListUnorderedIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M6.66667 3.33268H17.5V4.99935H6.66667V3.33268ZM3.75 5.41602C3.41848 5.41602 3.10054 5.28432 2.86612 5.0499C2.6317 4.81548 2.5 4.49754 2.5 4.16602C2.5 3.83449 2.6317 3.51655 2.86612 3.28213C3.10054 3.04771 3.41848 2.91602 3.75 2.91602C4.08152 2.91602 4.39946 3.04771 4.63388 3.28213C4.8683 3.51655 5 3.83449 5 4.16602C5 4.49754 4.8683 4.81548 4.63388 5.0499C4.39946 5.28432 4.08152 5.41602 3.75 5.41602ZM3.75 11.2493C3.41848 11.2493 3.10054 11.1177 2.86612 10.8832C2.6317 10.6488 2.5 10.3309 2.5 9.99935C2.5 9.66783 2.6317 9.34989 2.86612 9.11546C3.10054 8.88104 3.41848 8.74935 3.75 8.74935C4.08152 8.74935 4.39946 8.88104 4.63388 9.11546C4.8683 9.34989 5 9.66783 5 9.99935C5 10.3309 4.8683 10.6488 4.63388 10.8832C4.39946 11.1177 4.08152 11.2493 3.75 11.2493ZM3.75 16.9993C3.41848 16.9993 3.10054 16.8677 2.86612 16.6332C2.6317 16.3988 2.5 16.0809 2.5 15.7493C2.5 15.4178 2.6317 15.0999 2.86612 14.8655C3.10054 14.631 3.41848 14.4993 3.75 14.4993C4.08152 14.4993 4.39946 14.631 4.63388 14.8655C4.8683 15.0999 5 15.4178 5 15.7493C5 16.0809 4.8683 16.3988 4.63388 16.6332C4.39946 16.8677 4.08152 16.9993 3.75 16.9993ZM6.66667 9.16602H17.5V10.8327H6.66667V9.16602ZM6.66667 14.9993H17.5V16.666H6.66667V14.9993Z"
        fill="#718096"
      />
    </svg>
  );
};

export default ListUnorderedIcon;
