const NoteViewIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_850_11656)">
        <path
          d="M12.6667 14.6663H3.33337C2.80294 14.6663 2.29423 14.4556 1.91916 14.0806C1.54409 13.7055 1.33337 13.1968 1.33337 12.6663V1.99967C1.33337 1.82286 1.40361 1.65329 1.52864 1.52827C1.65366 1.40325 1.82323 1.33301 2.00004 1.33301H11.3334C11.5102 1.33301 11.6798 1.40325 11.8048 1.52827C11.9298 1.65329 12 1.82286 12 1.99967V9.99967H14.6667V12.6663C14.6667 13.1968 14.456 13.7055 14.0809 14.0806C13.7058 14.4556 13.1971 14.6663 12.6667 14.6663ZM12 11.333V12.6663C12 12.8432 12.0703 13.0127 12.1953 13.1377C12.3203 13.2628 12.4899 13.333 12.6667 13.333C12.8435 13.333 13.0131 13.2628 13.1381 13.1377C13.2631 13.0127 13.3334 12.8432 13.3334 12.6663V11.333H12ZM10.6667 13.333V2.66634H2.66671V12.6663C2.66671 12.8432 2.73695 13.0127 2.86197 13.1377C2.98699 13.2628 3.15656 13.333 3.33337 13.333H10.6667ZM4.00004 4.66634H9.33337V5.99967H4.00004V4.66634ZM4.00004 7.33301H9.33337V8.66634H4.00004V7.33301ZM4.00004 9.99967H7.33337V11.333H4.00004V9.99967Z"
          fill="#2D3748"
        />
      </g>
      <defs>
        <clipPath id="clip0_850_11656">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoteViewIcon;
