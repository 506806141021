export const cleanCommentEditor = (target) => {
  if (target && target.querySelectorAll) {
    try {
      const pArray = target.querySelectorAll('p');

      for (let i = 0; i < pArray.length; i++) {
        const p = pArray[i];
        if (p && p.hasChildNodes && p.hasChildNodes()) {
          const spans = document.querySelectorAll('span');
          const anchors = document.querySelectorAll('a');

          for (const child of spans) {
            if (
              child &&
              (child.style ||
                (child.hasAttribute && child.hasAttribute('style'))) &&
              !!child.removeAttribute
            ) {
              child.removeAttribute('style');
            } else if (child && child.style) {
              child.style = '';
            }
          }

          for (const child of anchors) {
            if (
              child &&
              (child.style ||
                (child.hasAttribute && child.hasAttribute('style'))) &&
              !!child.removeAttribute
            ) {
              child.removeAttribute('style');
            } else if (child && child.style) {
              child.style = '';
            }
          }
        }
      }
    } catch {}
  }

  const removeAll = (doms) => {
    if (doms.length > 0) {
      let i = 0;

      while (doms.length > i) {
        const dom = doms[i];
        dom.remove();

        if (i === doms.length - 1) {
          break;
        }

        i += 1;
      }
    }
  };

  if (target) {
    const iframes = target.querySelectorAll('iframe');
    const objects = target.querySelectorAll('object');
    const h3s = target.querySelectorAll('h3');
    const h4s = target.querySelectorAll('h4');

    removeAll(objects);
    removeAll(h3s);
    removeAll(h4s);
    removeAll(iframes);
  }
};
