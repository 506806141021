import cx from 'classnames';
import styles from './HeaderUserOptions.module.scss';
import k from 'src/constants/k';
import {
  isCapacitorMobileApp,
  isEmpty,
  isFunction,
  isString,
} from 'src/helpers/utils';
import {
  NotificationBellIcon,
  BugReportIcon,
  NoteViewPropertiesIcon,
  ChatSmileIcon,
  SignOutIcon,
  HomeIcon,
} from 'src/icons';
import { Button } from 'evergreen-ui';
import { useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withNoteViewContext } from './Context';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withModalPromptSettings } from 'src/managers/modal';
import { fromHomepage } from 'src/helpers/urls';
import { toString } from 'lodash';

export const HeaderUserOptionsClassname = 'HeaderUserOptionsClassname';

const HeaderUserOptions = (props) => {
  const {
    reset: resetNote,
    clearConnectedUsers,
    isUserNetworkOffline,
    clearStorage,
    // setActiveModalDom,
    isThemeDarkMode,
    logoutUser,
  } = props;

  /**
   * Listen to document click and close if evt target is outside of area
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (evt?.target?.classList?.contains(HeaderUserOptionsClassname)) {
        return;
      } else if (isFunction(props?.close)) {
        props.close();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [props]);

  const showNotifications = () => {
    if (isFunction(props?.close)) {
      props.close();
    }
  };

  const logout = async () => {
    const isOnline = !isUserNetworkOffline;

    if (!isOnline) {
      return;
    }

    if (isFunction(logoutUser)) {
      logoutUser();
    }

    if (props?.history) {
      props.history.push('/');
    }

    if (isFunction(clearStorage)) {
      clearStorage();
    }

    if (isFunction(props?.close)) {
      props.close();
    }

    if (isFunction(resetNote)) {
      resetNote();
    }

    if (isFunction(clearConnectedUsers)) {
      clearConnectedUsers();
    }
  };

  const showUserAccountSettings = () => {
    if (isFunction(props?.close)) {
      props.close();
    }
  };

  const report = () => {
    if (isCapacitorMobileApp()) {
      // open default/native browser
    }
  };

  const viewingHomepage = fromHomepage(
    toString(window.location.pathname || '')
  );

  return (
    <div
      className={cx(styles.header_user_options, HeaderUserOptionsClassname, {
        [props?.className]:
          isString(props?.className) && !isEmpty(props?.className),
        [styles.header_user_options_dark]: isThemeDarkMode,
      })}
    >
      <div
        className={cx(
          styles.flex_column_xy,
          styles.content,
          HeaderUserOptionsClassname
        )}
      >
        {viewingHomepage && (
          <div
            className={cx(
              styles.item,
              styles.dashboard,
              { [styles.item_dark]: isThemeDarkMode },
              HeaderUserOptionsClassname
            )}
          >
            <Button appearance="minimal" className={styles.flex_row_xy}>
              <HomeIcon />
              <p>{'Dashboard'}</p>
              <div className={cx(styles.cover, HeaderUserOptionsClassname)}>
                <div
                  className={cx(
                    styles.cover,
                    styles.link,
                    HeaderUserOptionsClassname
                  )}
                >
                  <Link to={'/user'} />
                </div>
              </div>
            </Button>
          </div>
        )}
        {!viewingHomepage && (
          <>
            <div
              className={cx(
                styles.item,
                styles.notifications,
                { [styles.item_dark]: isThemeDarkMode },
                HeaderUserOptionsClassname
              )}
            >
              <Button
                appearance="minimal"
                className={styles.flex_row_xy}
                onClick={showNotifications}
              >
                <NotificationBellIcon />
                <p>{'Notifications'}</p>
                <div className={cx(styles.cover, HeaderUserOptionsClassname)}>
                  <div
                    className={cx(
                      styles.cover,
                      styles.link,
                      HeaderUserOptionsClassname
                    )}
                  >
                    <Link to={'/user/notifications'} />
                  </div>
                </div>
              </Button>
            </div>

            <div
              className={cx(
                styles.item,
                { [styles.item_dark]: isThemeDarkMode },
                HeaderUserOptionsClassname
              )}
            >
              <Button
                appearance="minimal"
                className={styles.flex_row_xy}
                onClick={showUserAccountSettings}
              >
                <NoteViewPropertiesIcon />
                <p>{'Account Settings'}</p>
                <div className={cx(styles.cover, HeaderUserOptionsClassname)}>
                  <div
                    className={cx(
                      styles.cover,
                      styles.link,
                      HeaderUserOptionsClassname
                    )}
                  >
                    <Link to={'/user/account'} />
                  </div>
                </div>
              </Button>
            </div>

            <div
              className={cx(
                styles.item,
                { [styles.item_dark]: isThemeDarkMode },
                HeaderUserOptionsClassname
              )}
            >
              <Button
                appearance="minimal"
                className={styles.flex_row_xy}
                onClick={report}
              >
                <BugReportIcon />
                <p>{'Report a bug'}</p>
                <div
                  className={cx(
                    styles.cover,
                    styles.link,
                    HeaderUserOptionsClassname
                  )}
                >
                  {!isCapacitorMobileApp() && (
                    <a href="https://discord.gg/Xr9uawD3KB" rel="noreferrer">
                      {' '}
                    </a>
                  )}
                </div>
              </Button>
            </div>

            <div
              className={cx(
                styles.item,
                { [styles.item_dark]: isThemeDarkMode },
                HeaderUserOptionsClassname
              )}
            >
              <Button appearance="minimal" className={styles.flex_row_xy}>
                <ChatSmileIcon />
                <p>{'Send feedback'}</p>
                <div
                  className={cx(
                    styles.cover,
                    styles.link,
                    HeaderUserOptionsClassname
                  )}
                >
                  <a href={k.FEEDBACK_URL} target="_blank" rel="noreferrer">
                    {' '}
                  </a>
                </div>
              </Button>
            </div>
          </>
        )}

        <div
          className={cx(
            styles.item,
            {
              [styles.item_dark]: isThemeDarkMode,
            },
            HeaderUserOptionsClassname
          )}
        >
          <Button
            appearance="minimal"
            className={cx(styles.flex_row_xy, HeaderUserOptionsClassname)}
            onClick={logout}
          >
            <SignOutIcon />
            <p>{'Sign out'}</p>
            <div className={cx(styles.cover, HeaderUserOptionsClassname)}></div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withNoteViewContext(withRouter(HeaderUserOptions)))
);
