import { toString } from './utils';

export const isExcelFile = (fileName = '', url = '') => {
  const urlSanitized = toString(url).replace(/\s/g, '').trim();

  return (
    (fileName.indexOf('.xlsx') > -1 &&
      fileName.indexOf('.xlsx') + '.xlsx'.length >= fileName.length) ||
    (fileName.indexOf('.excel') > -1 &&
      fileName.indexOf('.excel') + '.excel'.length >= fileName.length) ||
    (fileName.indexOf('.sheets') > -1 &&
      fileName.indexOf('.sheets') + '.sheets'.length >= fileName.length) ||
    (urlSanitized.indexOf('.excel') > -1 &&
      urlSanitized.indexOf('.excel') + '.excel'.length >=
        urlSanitized.length) ||
    (urlSanitized.indexOf('.xlsx') > -1 &&
      urlSanitized.indexOf('.xlsx') + '.xlsx'.length >= urlSanitized.length)
  );
};

export const isZipArchiveFile = (fileName = '', url = '') => {
  const urlSanitized = toString(url).replace(/\s/g, '').trim();

  return (
    (fileName.indexOf('.zip') > -1 &&
      fileName.indexOf('.zip') + '.zip'.length >= fileName.length) ||
    (fileName.indexOf('.rar') > -1 &&
      fileName.indexOf('.rar') + '.rar'.length >= fileName.length) ||
    (urlSanitized.indexOf('.zip') > -1 &&
      urlSanitized.indexOf('.zip') + '.zip'.length >= urlSanitized.length) ||
    (urlSanitized.indexOf('.rar') > -1 &&
      urlSanitized.indexOf('.rar') + '.rar'.length >= urlSanitized.length)
  );
};

export const isDocsOrWordFile = (fileName = '', url = '') => {
  const urlSanitized = toString(url).replace(/\s/g, '').trim();

  return (
    (fileName.indexOf('.docx') > -1 &&
      fileName.indexOf('.docx') + '.docx'.length >= fileName.length) ||
    (fileName.indexOf('.docs') > -1 &&
      fileName.indexOf('.docs') + '.docs'.length >= fileName.length) ||
    (fileName.indexOf('.document') > -1 &&
      fileName.indexOf('.document') + '.document'.length >= fileName.length) ||
    (urlSanitized.indexOf('.docs') > -1 &&
      urlSanitized.indexOf('.docs') + '.docs'.length >= urlSanitized.length) ||
    (urlSanitized.indexOf('.docx') > -1 &&
      urlSanitized.indexOf('.docx') + '.docx'.length >= urlSanitized.length)
  );
};

export const isTextFile = (fileName = '') => {
  return (
    (fileName.indexOf('.txt') > -1 &&
      fileName.indexOf('.txt') + '.txt'.length >= fileName.length) ||
    (fileName.indexOf('.text') > -1 &&
      fileName.indexOf('.text') + '.text'.length >= fileName.length)
  );
};
