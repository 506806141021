import { useEffect, useState } from 'react';
import Store from 'src/lib/store';
import LogoLoadV2 from 'src/components/logo-load-v2';
import { Redirect } from 'react-router-dom';
import { toString } from 'src/helpers/utils';

/**
 * To redirect old /view/* pages to the new editor with /note/* prefix
 * @returns
 */
const TaskNavigate = () => {
  const [query, setQuery] = useState();
  const [taskId, setTaskId] = useState('');
  const [uRef, setuRef] = useState('');
  const [toRedirect, setToRedirect] = useState(false);

  useEffect(() => {
    Store.CONFIGS.getQueryJSON();
    setQuery(Store.CONFIGS.getQuery());
  }, []);

  useEffect(() => {
    const currentURef = toString(query?.t).trim();
    const { pathname } = window.location;
    const maxIs3 = pathname.indexOf('/user/view') === 0;
    const maxIs2 = pathname.indexOf('/view') === 0;

    if ((maxIs2 || maxIs3) && currentURef) {
      const pathArr = pathname.trim().split('/');

      if (pathArr[0].length <= 0) {
        pathArr.shift();
        const currentTaskId = pathArr[pathArr.length - 1];

        setTaskId(currentTaskId);
        setuRef(currentURef);
        setToRedirect(true);
      }
    }
  }, [query]);

  if (taskId && toRedirect && uRef) {
    return <Redirect to={`/note/${taskId}?u=${uRef}`} />;
  }

  return (
    <>
      <LogoLoadV2 />
    </>
  );
};

export default TaskNavigate;
