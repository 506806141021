import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Privacy.module.scss';
import { paragraphUnpacker } from '../helpers';

const listItemUnpacker = (toUnPack, key = '') => {
  const listItems = [];

  for (let i = 0; i < toUnPack.length; i++) {
    const content = toUnPack[i];
    listItems.push(<li key={`listItemUnpacker${key}${i}`}>{content}</li>);
  }

  return listItems;
};

const Privacy = (props) => {
  const introContent = paragraphUnpacker(
    contents.content.intro.slice(1, 2),
    'introContent'
  );
  const introContentCont = paragraphUnpacker(
    contents.content.intro.slice(2, 3),
    'introContentCont'
  );
  const introContentLast = paragraphUnpacker(
    contents.content.intro.slice(3),
    'introContentLast'
  );
  const collInfoLog = paragraphUnpacker(
    contents.content.collection_of_info.slice(3, 4),
    'collInfoLog'
  );
  const collInfoPersonal = paragraphUnpacker(
    contents.content.collection_of_info.slice(5, 6),
    'collInfoPersonal'
  );
  const collInfoListItems = listItemUnpacker(
    contents.content.collection_of_info.slice(6, 8),
    'collInfoListItems'
  );
  const collInfoLast = paragraphUnpacker(
    contents.content.collection_of_info.slice(8),
    'collInfoLast'
  );
  const accessParagraphs = paragraphUnpacker(
    contents.content.access_and_use_of_info.slice(1, 2),
    'accessParagraphs'
  );
  const accessLists = listItemUnpacker(
    contents.content.access_and_use_of_info.slice(2),
    'accessLists'
  );
  const secContent = paragraphUnpacker(
    contents.content.security_information.slice(1),
    'secContent'
  );
  const disclosureCont = paragraphUnpacker(
    contents.content.disclosure.slice(1, 2),
    'disclosureCont'
  );
  const disclosureLast = paragraphUnpacker(
    contents.content.disclosure.slice(7),
    'disclosureLast'
  );
  const rightsIntroCont = paragraphUnpacker(
    contents.content.your_rights.slice(1, 2),
    'rightsIntroCont'
  );
  const cookiesCont = paragraphUnpacker(
    contents.content.cookies.slice(1),
    'cookiesCont'
  );
  const policyCont = paragraphUnpacker(
    contents.content.change_to_policy.slice(1),
    'policyCont'
  );
  const contactUsCont = paragraphUnpacker(
    contents.content.contact_us.slice(1),
    'contactUsCont'
  );

  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <p className={innerStyles.last_updated}>
          {contents.content.last_updated}
        </p>
        <br />
        <h4 className={innerStyles.title}>{contents.content.intro[0]}</h4>
        <br />
        {introContent}
        <br />
        <h4> {introContentCont}</h4>
        <br />
        {introContentLast}
        <br />
        <br />
        <h4 className={innerStyles.title}>
          {contents.content.collection_of_info[0]}
        </h4>
        <br />
        <p>{contents.content.collection_of_info[1]}</p>
        <br />
        <h4>{contents.content.collection_of_info[2]}</h4>
        <br />
        {collInfoLog}
        <br />
        <h4>{contents.content.collection_of_info[4]}</h4>
        <br />
        {collInfoPersonal}
        <br />
        <ul className={innerStyles.unordered_list}>{collInfoListItems}</ul>
        <br />
        {collInfoLast}
        <br />
        <br />
        <h4 className={innerStyles.title}>
          {contents.content.access_and_use_of_info[0]}
        </h4>
        <br />
        {accessParagraphs}
        <br />
        <ul className={innerStyles.unordered_list}>{accessLists}</ul>
        <br />
        <br />
        <h4 className={innerStyles.title}>
          {contents.content.security_information[0]}
        </h4>
        <br />
        {secContent}
        <br />
        <br />
        <h4 className={innerStyles.title}>{contents.content.disclosure[0]}</h4>
        <br />
        {disclosureCont}
        <br />
        <ul className={innerStyles.unordered_list}>
          <li>{contents.content.disclosure[2]}</li>
          <li>{contents.content.disclosure[3]}</li>
          <li>{contents.content.disclosure[4]}</li>
          <li>{contents.content.disclosure[5]}</li>
          <li>{contents.content.disclosure[6]}</li>
        </ul>
        <br />
        {disclosureLast}
        <br />
        <br />
        <h4 className={innerStyles.title}>{contents.content.your_rights[0]}</h4>
        <br />
        {rightsIntroCont}
        <br />
        <ul className={innerStyles.unordered_list}>
          <li>{contents.content.your_rights[2]}</li>
          <li>{contents.content.your_rights[3]}</li>
          <li>{contents.content.your_rights[4]}</li>
          <li>{contents.content.your_rights[5]}</li>
          <li>{contents.content.your_rights[6]}</li>
        </ul>
        <br />
        <br />
        <h4 className={innerStyles.title}>{contents.content.cookies[0]}</h4>
        <br />
        {cookiesCont}
        <br />
        <br />
        <h4 className={innerStyles.title}>
          {contents.content.change_to_policy[0]}
        </h4>
        <br />
        {policyCont}
        <br />
        <br />
        <h4 className={innerStyles.title}>{contents.content.contact_us[0]}</h4>
        <br />
        {contactUsCont}
      </div>
    </div>
  );
};

export default Privacy;
