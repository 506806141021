import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import styles from './FormatsToolbar.module.scss';
import CustomButton from 'src/components/button';
import i18n from 'src/locales';
import { ChevronDownIcon, Button, TextInput } from 'evergreen-ui';
import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  StrikeThroughIcon,
  LinkIcon,
  QuoteIcon,
  CodeBlockIcon,
  ListOrderedIcon,
  ListUnorderedIcon,
  ListCheckIcon,
  FileAttachmentIcon,
  ImageFileIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignJustifyIcon,
  AlignCenterIcon,
  TableIcon,
  FolderIcon,
} from 'src/icons';
import { useForceUpdate } from 'src/hooks';
import { filter, isMobileView, toString } from 'src/helpers/utils';
import { withNoteViewContext } from './Context';
import './FormatsToolbar.scss';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const formatKeys = [
  'heading1',
  'heading2',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'codeBlock',
  'taskList',
  'bulletList',
  'orderedList',
];

const FormatsToolbar = (props) => {
  const FormatHeaderOptionsClassname = 'FormatHeaderOptionsClassname';
  const {
    isEditMode,
    isCreateMode,
    EditorInstance,
    onFileUpload,
    requirePasscode,
    showFilesView,
    loading,
    isThemeDarkMode,
  } = props;
  const [stickyScrollbar, setStickScrollbar] = useState(false);
  const [dropHeaderOptions, setDropHeaderOptions] = useState(false);
  const [showSetLink, setShowSetLink] = useState(false);
  const [showAlignOptions, setShowAlignOptions] = useState(false);
  const [targetLink, setTargetLink] = useState('');
  const canModifyEditor = isEditMode || isCreateMode;
  const [activeFormats, setActiveFormats] = useState([]);
  const { update: forceUpdate, updatedValue } = useForceUpdate();

  const onDropHeaderOptions = () => {
    setDropHeaderOptions(!dropHeaderOptions);
  };

  /**
   * Move formats bar up top when on user scroll
   */
  useEffect(() => {
    const onScroll = () => {
      if (isMobileView() || !canModifyEditor) {
        if (stickyScrollbar) {
          setStickScrollbar(false);
        }

        return;
      }

      if (window?.scrollY >= 100) {
        setStickScrollbar(true);
      } else if (stickyScrollbar) {
        setStickScrollbar(false);
      }
    };

    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [canModifyEditor, stickyScrollbar]);

  useEffect(() => {
    if (!canModifyEditor) {
      setDropHeaderOptions(false);
      setShowAlignOptions(false);
      setShowSetLink(false);
    }
  }, [canModifyEditor]);

  /**
   * hide header picker options on outside area click
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (evt?.target?.classList?.contains(FormatHeaderOptionsClassname)) {
        return;
      } else if (dropHeaderOptions) {
        setDropHeaderOptions(false);
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [dropHeaderOptions]);

  /**
   * Listen to selection update and check active formats
   */
  useEffect(() => {
    const editor = EditorInstance;

    if (!editor) {
      return;
    }

    const onSelectionUpdate = (evt) => {
      let hasChange = false;
      let currentActiveFormats = [...activeFormats];

      if (!editor) {
        return;
      }

      for (const formatKey of formatKeys) {
        if (formatKey === 'heading1' || formatKey === 'heading2') {
          if (
            editor?.isActive('heading', {
              level: formatKey === 'heading1' ? 1 : 2,
            })
          ) {
            if (!currentActiveFormats.includes(formatKey)) {
              currentActiveFormats.push(formatKey);
            }

            hasChange = true;
          } else if (
            !editor?.isActive('heading', {
              level: formatKey === 'heading1' ? 1 : 2,
            })
          ) {
            currentActiveFormats = filter(
              currentActiveFormats,
              (format) => format !== formatKey
            );
            hasChange = true;
          }

          continue;
        }

        if (editor?.isActive(formatKey)) {
          if (!currentActiveFormats.includes(formatKey)) {
            currentActiveFormats.push(formatKey);
          }

          hasChange = true;
        } else if (!editor?.isActive(formatKey)) {
          currentActiveFormats = filter(
            currentActiveFormats,
            (format) => format !== formatKey
          );
          hasChange = true;
        }
      }

      if (
        (showSetLink && !evt?.target && !editor.getAttributes('link')?.href) ||
        (evt?.target &&
          !evt?.target?.classList?.contains(styles.set_link_button_cover) &&
          !evt?.target?.classList?.contains(styles.set_link_button))
      ) {
        const setLinkDropdownDom = document.querySelector(
          `.${styles.set_link}`
        );

        if (!setLinkDropdownDom?.contains(evt?.target)) {
          setShowSetLink(false);
          setTargetLink('');
        }
      } else if (editor.getAttributes('link')?.href && showSetLink) {
        setTargetLink(editor.getAttributes('link').href);
      }

      if (
        evt?.target?.classList &&
        !evt?.target?.classList?.contains(styles.align) &&
        showAlignOptions
      ) {
        setShowAlignOptions(false);
      }

      if (!hasChange) {
        return;
      }

      setActiveFormats(currentActiveFormats);
    };

    editor.on('selectionUpdate', onSelectionUpdate);

    if (isMobileView()) {
      editor.on('update', onSelectionUpdate);
    }

    document.addEventListener('click', onSelectionUpdate, false);

    return () => {
      if (editor) {
        editor.off('selectionUpdate', onSelectionUpdate);
        document.removeEventListener('click', onSelectionUpdate, false);

        if (isMobileView()) {
          editor.off('update', onSelectionUpdate);
        }
      }
    };
  }, [
    EditorInstance,
    showSetLink,
    targetLink,
    activeFormats,
    showAlignOptions,
    dropHeaderOptions,
  ]);

  const [
    isHeading1Active,
    isHeading2Active,
    isBoldActive,
    isItalicActive,
    isUnderlineActive,
    isStrikeActive,
    isBlockQuoteActive,
    isCodeBlockActive,
    isTaskListActive,
    isBulletListActive,
    isOrderedListActive,
    isTableFormatActive,
  ] = useMemo(() => {
    return !EditorInstance
      ? []
      : [
          activeFormats.includes('heading1'),
          activeFormats.includes('heading2'),
          activeFormats.includes('bold'),
          activeFormats.includes('italic'),
          activeFormats.includes('underline'),
          activeFormats.includes('strike'),
          activeFormats.includes('blockquote'),
          activeFormats.includes('codeBlock'),
          activeFormats.includes('taskList'),
          activeFormats.includes('bulletList'),
          activeFormats.includes('orderedList'),
          false,
        ];

    // eslint-disable-next-line
  }, [activeFormats, EditorInstance, updatedValue]);

  const [
    isAlignLeftActive,
    isAlignCenterActive,
    isAlignRightActive,
    isAlignJustifyActive,
  ] = useMemo(() => {
    return !EditorInstance && !showAlignOptions
      ? []
      : [
          EditorInstance.isActive({ textAlign: 'left' }),
          EditorInstance.isActive({ textAlign: 'center' }),
          EditorInstance.isActive({ textAlign: 'right' }),
          EditorInstance.isActive({ textAlign: 'justify' }),
        ];

    // eslint-disable-next-line
  }, [showAlignOptions, EditorInstance, activeFormats?.length, updatedValue]);

  useEffect(() => {
    const editor = EditorInstance;
    const onBlur = () => {
      setShowAlignOptions(false);
      cancelSetLink();
    };

    if (editor) {
      editor.on('blur', onBlur);
    }

    return () => {
      if (editor) {
        editor.off('blur', onBlur);
      }
    };
  }, [EditorInstance, canModifyEditor, activeFormats]);

  const repaintMobile = async () => {
    if (isMobileView()) {
      if (EditorInstance) {
        EditorInstance?.commands?.focus();
      }

      forceUpdate();
    }
  };

  const toggleBold = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleBold().run();

    if (isBoldActive) {
      setActiveFormats(filter(activeFormats, (format) => format !== 'bold'));
    } else if (!activeFormats.includes('bold')) {
      setActiveFormats([...activeFormats, 'bold']);
    }

    repaintMobile();
  };

  const toggleItalic = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleItalic().run();

    if (isItalicActive) {
      setActiveFormats(filter(activeFormats, (format) => format !== 'italic'));
    } else if (!activeFormats.includes('italic')) {
      setActiveFormats([...activeFormats, 'italic']);
    }

    repaintMobile();
  };

  const toggleStrike = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleStrike().run();

    if (isStrikeActive) {
      setActiveFormats(filter(activeFormats, (format) => format !== 'strike'));
    } else if (!activeFormats.includes('strike')) {
      setActiveFormats([...activeFormats, 'strike']);
    }

    repaintMobile();
  };

  const toggleUnderline = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleUnderline().run();

    if (isUnderlineActive) {
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'underline')
      );
    } else if (!activeFormats.includes('underline')) {
      setActiveFormats([...activeFormats, 'underline']);
    }
  };

  const toggleBlockQuote = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleBlockquote().run();

    if (isBlockQuoteActive) {
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'blockquote')
      );
    } else if (!activeFormats.includes('blockquote')) {
      setActiveFormats([...activeFormats, 'blockquote']);
    }
  };

  const toggleCodeblock = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleCodeBlock().run();

    if (isCodeBlockActive) {
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'codeBlock')
      );
    } else if (!activeFormats.includes('codeBlock')) {
      setActiveFormats([
        ...filter(
          activeFormats,
          (format) =>
            format !== 'strike' && format !== 'bold' && format !== 'blockquote'
        ),
        'codeBlock',
      ]);
    }
  };

  const toggleHeading1 = () => {
    if (!EditorInstance) {
      return;
    }

    setDropHeaderOptions(false);

    if (!isHeading1Active) {
      EditorInstance.chain().focus().toggleHeading({ level: 1 }).run();

      if (activeFormats.includes('heading2')) {
        const idx = activeFormats.indexOf('heading2');
        activeFormats.splice(idx, 1);
      }

      if (!activeFormats.includes('heading1')) {
        setActiveFormats([...activeFormats, 'heading1']);
      }
    }
  };

  const toggleHeading2 = () => {
    if (!EditorInstance) {
      return;
    }

    setDropHeaderOptions(false);

    if (!isHeading2Active) {
      EditorInstance.chain().focus().toggleHeading({ level: 2 }).run();

      if (activeFormats.includes('heading1')) {
        const idx = activeFormats.indexOf('heading1');
        activeFormats.splice(idx, 1);
      }

      if (!activeFormats.includes('heading2')) {
        setActiveFormats([...activeFormats, 'heading2']);
      }
    }
  };

  const onSelectNormal = () => {
    if (!EditorInstance) {
      return;
    }

    if (isHeading1Active) {
      EditorInstance.chain().focus().toggleHeading({ level: 1 }).run();
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'heading1')
      );
    }

    if (isHeading2Active) {
      EditorInstance.chain().focus().toggleHeading({ level: 2 }).run();
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'heading2')
      );
    }

    setDropHeaderOptions(false);
  };

  const toggleTaskList = () => {
    if (!EditorInstance) {
      return;
    }

    if (isBulletListActive) {
      EditorInstance.chain().focus().toggleBulletList().run();
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'bulletList')
      );
    }

    EditorInstance.chain().focus().toggleTaskList().run();

    if (isTaskListActive) {
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'taskList')
      );
    } else {
      setActiveFormats([
        ...filter(
          activeFormats,
          (format) => format !== 'orderedList' && format !== 'bulletList'
        ),
        'taskList',
      ]);
    }
  };

  const toggleBulletList = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleBulletList().run();

    if (isBulletListActive) {
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'bulletList')
      );
    } else {
      setActiveFormats([
        ...filter(
          activeFormats,
          (format) => format !== 'orderedList' && format !== 'taskList'
        ),
        'bulletList',
      ]);
    }
  };

  const toggleOrderedList = () => {
    if (!EditorInstance) {
      return;
    }

    EditorInstance.chain().focus().toggleOrderedList().run();

    if (isOrderedListActive) {
      setActiveFormats(
        filter(activeFormats, (format) => format !== 'orderedList')
      );
    } else {
      setActiveFormats([
        ...filter(
          activeFormats,
          (format) => format !== 'bulletList' && format !== 'taskList'
        ),
        'orderedList',
      ]);
    }
  };

  const setLink = () => {
    if (!EditorInstance) {
      return;
    }
    const editor = EditorInstance;
    const previousUrl = editor.getAttributes('link').href;
    const url =
      targetLink.indexOf('http') !== 0 ? `https://${targetLink}` : targetLink;

    if (!url) {
      cancelSetLink();
      return;
    }

    if (url === '' && previousUrl) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      cancelSetLink();
      return;
    }

    if (url) {
      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }

    cancelSetLink();
  };

  const cancelSetLink = () => {
    setShowSetLink(false);
    setTargetLink('');
  };

  const applyTextAlign = (type = '') => {
    const editor = EditorInstance;

    if (!editor || !type) {
      return;
    }

    if (type === 'left') {
      editor.chain().focus().setTextAlign('left').run();
    } else if (type === 'right') {
      editor.chain().focus().setTextAlign('right').run();
    } else if (type === 'center') {
      editor.chain().focus().setTextAlign('center').run();
    } else if (type === 'justify') {
      editor.chain().focus().setTextAlign('justify').run();
    }

    setShowAlignOptions(false);
  };

  return (
    <div
      className={cx(styles.formats_toolbar, styles.flex_row_xy, {
        [styles.formats_toolbar_scroll]: stickyScrollbar,
        [styles.formats_toolbar_inline]: !stickyScrollbar,
        [styles.formats_toolbar_dark]: isThemeDarkMode,
        [styles.hide_element]:
          !canModifyEditor || requirePasscode || showFilesView || loading,
      })}
      id={'formatsToolbarIdWrapper'}
    >
      <div
        className={cx(styles.flex_row_xy, styles.content)}
        id={'formatsToolbarId'}
      >
        <div className={cx(styles.first)}>
          <div
            className={cx(styles.flex_row_xy, styles.header_picker, {
              [styles.header_picker_dark]: isThemeDarkMode,
            })}
          >
            <div
              className={cx(
                styles.flex_row_xy,
                styles.header_picker_selected,
                FormatHeaderOptionsClassname
              )}
              onClick={onDropHeaderOptions}
            >
              <p>
                {isHeading1Active
                  ? 'Heading 1'
                  : isHeading2Active
                  ? 'Heading 2'
                  : 'Normal'}
              </p>
              <div
                className={cx(styles.cover, FormatHeaderOptionsClassname)}
              ></div>
            </div>
            <button
              type="button"
              className={cx(styles.flex_row_xy, styles.icon, {
                [styles.icon_active]: dropHeaderOptions,
                [styles.icon_inactive]: !dropHeaderOptions,
                [styles.icon_dark]: isThemeDarkMode,
              })}
              onClick={onDropHeaderOptions}
            >
              <ChevronDownIcon />{' '}
              <div
                className={cx(styles.cover, FormatHeaderOptionsClassname)}
              ></div>
            </button>
          </div>
        </div>
        <div className={cx(styles.flex_row_xy, styles.second)}>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]: isBoldActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isBoldActive && isThemeDarkMode,
              [styles.icon_button_dark_active]: isBoldActive && isThemeDarkMode,
            })}
            onClick={toggleBold}
          >
            <BoldIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(
              cx(styles.icon_button, {
                [styles.icon_button_active]: isItalicActive && !isThemeDarkMode,
                [styles.icon_button_dark]: !isItalicActive && isThemeDarkMode,
                [styles.icon_button_dark_active]:
                  isItalicActive && isThemeDarkMode,
              })
            )}
            onClick={toggleItalic}
          >
            <ItalicIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, styles.underline_icon, {
              [styles.icon_button_active]:
                isUnderlineActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isUnderlineActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isUnderlineActive && isThemeDarkMode,
            })}
            onClick={toggleUnderline}
          >
            <UnderlineIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]: isStrikeActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isStrikeActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isStrikeActive && isThemeDarkMode,
            })}
            onClick={toggleStrike}
          >
            <StrikeThroughIcon />
          </Button>
        </div>
        <div className={cx(styles.flex_row_xy, styles.third)}>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, styles.set_link_button, {
              [styles.icon_button_dark]: isThemeDarkMode,
            })}
            onClick={() => {
              setShowSetLink(!showSetLink);
              setShowAlignOptions(false);

              if (EditorInstance) {
                setTargetLink(EditorInstance.getAttributes('link').href || '');
              }
            }}
          >
            <LinkIcon />
            <div
              className={cx(styles.cover, styles.set_link_button_cover)}
            ></div>
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]:
                isBlockQuoteActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isBlockQuoteActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isBlockQuoteActive && isThemeDarkMode,
            })}
            onClick={toggleBlockQuote}
          >
            <QuoteIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]:
                isCodeBlockActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isCodeBlockActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isCodeBlockActive && isThemeDarkMode,
            })}
            onClick={toggleCodeblock}
          >
            <CodeBlockIcon />
          </Button>
        </div>
        <div className={cx(styles.flex_row_xy, styles.fourth)}>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_dark]: isThemeDarkMode,
            })}
            onClick={() => {
              cancelSetLink();

              setShowAlignOptions(!showAlignOptions);
            }}
          >
            <AlignLeftIcon />
            <div className={cx(styles.cover, styles.align)}></div>
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, styles.tables, {
              [styles.icon_button_active]:
                isTableFormatActive && !isThemeDarkMode,
              [styles.icon_button_dark]:
                !isTableFormatActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isTableFormatActive && isThemeDarkMode,
            })}
            onClick={() => {
              EditorInstance.chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run();
            }}
          >
            <TableIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]:
                isOrderedListActive && !isThemeDarkMode,
              [styles.icon_button_dark]:
                !isOrderedListActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isOrderedListActive && isThemeDarkMode,
            })}
            onClick={toggleOrderedList}
          >
            <ListOrderedIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]:
                isBulletListActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isBulletListActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isBulletListActive && isThemeDarkMode,
            })}
            onClick={toggleBulletList}
          >
            <ListUnorderedIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_active]: isTaskListActive && !isThemeDarkMode,
              [styles.icon_button_dark]: !isTaskListActive && isThemeDarkMode,
              [styles.icon_button_dark_active]:
                isTaskListActive && isThemeDarkMode,
            })}
            onClick={toggleTaskList}
          >
            <ListCheckIcon />
          </Button>
        </div>
        <div className={cx(styles.flex_row_xy, styles.fifth)}>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_dark]: isThemeDarkMode,
            })}
            onClick={() => onFileUpload('file')}
          >
            <FileAttachmentIcon />
          </Button>
          <Button
            appearance="minimal"
            className={cx(styles.icon_button, {
              [styles.icon_button_dark]: isThemeDarkMode,
            })}
            onClick={() => onFileUpload('image')}
          >
            <ImageFileIcon />
          </Button>
          {false && (
            <Button
              appearance="minimal"
              className={cx(styles.icon_button, styles.hide_element, {
                [styles.icon_button_dark]: isThemeDarkMode,
              })}
            >
              <FolderIcon />
            </Button>
          )}
        </div>
      </div>
      <div
        className={cx(styles.header_options, FormatHeaderOptionsClassname, {
          [styles.hide_element]: !dropHeaderOptions,
          [styles.header_options_dark]: isThemeDarkMode,
        })}
      >
        <div
          className={cx(FormatHeaderOptionsClassname, styles.flex_column_xy)}
        >
          <div
            className={cx(FormatHeaderOptionsClassname)}
            datavalue="heading1"
            onClick={toggleHeading1}
          >
            Heading 1
          </div>
          <div
            className={cx(FormatHeaderOptionsClassname)}
            datavalue="heading2"
            onClick={toggleHeading2}
          >
            Heading 2
          </div>
          <div
            className={cx(FormatHeaderOptionsClassname)}
            datavalue="normal"
            onClick={onSelectNormal}
          >
            Normal
          </div>
        </div>
      </div>

      <div
        className={cx(styles.flex_row_xy, styles.set_link, {
          [styles.hide_element]: !showSetLink,
          [styles.set_link_dark]: isThemeDarkMode,
          [styles.set_link_scroll]: stickyScrollbar,
        })}
      >
        <TextInput
          value={targetLink}
          onChange={(evt) => setTargetLink(toString(evt?.target?.value))}
        />
        <div
          className={cx(styles.flex_row_xy, styles.actions, {
            [styles.actions_dark]: isThemeDarkMode,
          })}
        >
          <CustomButton
            isThemeDarkMode={isThemeDarkMode}
            label={i18n('common_confirm')}
            variant={'primaryPurple'}
            onClick={setLink}
          />
          <CustomButton
            isThemeDarkMode={isThemeDarkMode}
            label={i18n('common_cancel_title')}
            variant={'secondary'}
            onClick={cancelSetLink}
          />
        </div>
      </div>
      <div
        className={cx(styles.flex_row_xy, styles.align_options, {
          [styles.hide_element]: !showAlignOptions,
          [styles.align_options_dark]: isThemeDarkMode,
        })}
      >
        <div
          className={cx(styles.flex_row_xy, {
            [styles.align_option_active]: isAlignLeftActive,
            [styles.align_option_active_dark]:
              isAlignLeftActive && isThemeDarkMode,
            [styles.align_option_inactive]: !isAlignLeftActive,
          })}
          onClick={() => applyTextAlign('left')}
        >
          <AlignLeftIcon />
        </div>
        <div
          className={cx(styles.flex_row_xy, {
            [styles.align_option_active]: isAlignCenterActive,
            [styles.align_option_active_dark]:
              isAlignCenterActive && isThemeDarkMode,
            [styles.align_option_inactive]: !isAlignCenterActive,
          })}
          onClick={() => applyTextAlign('center')}
        >
          <AlignCenterIcon />
        </div>
        <div
          className={cx(styles.flex_row_xy, {
            [styles.align_option_active]: isAlignRightActive,
            [styles.align_option_active_dark]:
              isAlignRightActive && isThemeDarkMode,
            [styles.align_option_inactive]: !isAlignRightActive,
          })}
          onClick={() => applyTextAlign('right')}
        >
          <AlignRightIcon />
        </div>
        <div
          className={cx(styles.flex_row_xy, {
            [styles.align_option_active]: isAlignJustifyActive,
            [styles.align_option_active_dark]:
              isAlignJustifyActive && isThemeDarkMode,
            [styles.align_option_inactive]: !isAlignJustifyActive,
          })}
          onClick={() => applyTextAlign('justify')}
        >
          <AlignJustifyIcon />
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(FormatsToolbar)
);
