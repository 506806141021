import cx from 'classnames';
import styles from './Header.module.scss';
import LogoIcon from 'src/components/logo-icon';
import AvatarPremium from 'src/components/avatar-premium';
import HeaderUserOptions, {
  HeaderUserOptionsClassname,
} from 'src/pages/note-view/HeaderUserOptions';
import { useEffect, useMemo, useState } from 'react';
import {
  isMobileView,
  isNumber,
  isIosMobileApp,
  getUserDisplayName,
} from 'src/helpers/utils';
import { Button, CaretDownIcon } from 'evergreen-ui';
import { withRouter, Link } from 'react-router-dom';
import { useWindowScroll } from 'src/hooks';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { Avatar } from '@chakra-ui/react';
import { ArrowUpIcon } from 'src/icons';

const Header = (props) => {
  const [showUserOptions, setShowUserOptions] = useState(false);
  const {
    user,
    profileLoaded,
    isThemeDarkMode,
    userIsPremium: userPremiumSpaces,
    selectedSpace: space,
    isLoggedIn,
  } = props;
  const spaceName = useMemo(() => space?.name || 'Personal Space', [space]);
  const spaceId = useMemo(() => space?.id || space?.space_id || '', [space]);
  const fullName = getUserDisplayName(user);
  const userImage = useMemo(() => user?.image, [user]);
  const { scrollY } = useWindowScroll();

  /** Hide user options when user scrolls */
  useEffect(() => {
    if (isNumber(scrollY) && scrollY > 10) {
      setShowUserOptions(false);
    }
  }, [scrollY]);

  const onReturn = async () => {
    if (space && props?.history) {
      props.history.push('/user');
    }
  };

  const showHeaderUserOptions = () => {
    setShowUserOptions(!showUserOptions);
  };

  return (
    <div
      className={cx(styles.header, styles.flex_row_xy, {
        [styles.header_dark]: isThemeDarkMode,
        [styles.header_ios_app]: isIosMobileApp(),
      })}
    >
      <div className={cx(styles.flex_row_xy, styles.left)}>
        <Button
          appearance="minimal"
          className={cx(styles.flex_row_xy, styles.logo, {
            [styles.logo_dark]: isThemeDarkMode,
          })}
          onClick={onReturn}
        >
          {(!isLoggedIn || !isMobileView()) && (
            <LogoIcon darkMode={isThemeDarkMode} height={35} width={35} />
          )}

          {isLoggedIn && isMobileView() && (
            <div
              className={cx(styles.arrow_back, {
                [styles.arrow_back_dark]: isThemeDarkMode,
                [styles.arrow_back_light]: !isThemeDarkMode,
              })}
            >
              <ArrowUpIcon />
            </div>
          )}
          <div className={styles.logo_texts}>
            <h5>{'Back to'}</h5>
            <h5>{space ? spaceName : 'Personal Space'}</h5>
          </div>
          {!spaceId && (
            <div className={cx(styles.cover, styles.link)}>
              <Link to={'/user'} />
            </div>
          )}
        </Button>
      </div>
      <div className={styles.right}>
        <div
          className={cx(styles.flex_row_xy, styles.user)}
          onClick={() => showHeaderUserOptions()}
        >
          <div className={cx(styles.flex_row_xy, styles.avatar)}>
            <Avatar
              height={'38px'}
              width={'38px'}
              name={fullName}
              src={userImage || ''}
              backgroundColor={'#000'}
            />
            {profileLoaded && userPremiumSpaces ? <AvatarPremium /> : <></>}
          </div>
          <div className={styles.name}>
            <p>{fullName}</p>
          </div>
          <div
            className={cx(styles.arrow, styles.flex_row_xy, {
              [styles.arrow_active]: showUserOptions,
              [styles.hide_element]: isMobileView(),
            })}
          >
            <CaretDownIcon />
          </div>
          <div className={cx(styles.cover, HeaderUserOptionsClassname)}></div>
        </div>
        {showUserOptions && (
          <HeaderUserOptions
            className={styles.user_options}
            close={() => setShowUserOptions(false)}
          />
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withRouter(Header))
);
