import {
  filter,
  head,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isFunction,
  isObject,
  isString,
  last,
  map,
  pick,
  size,
  split,
  toLower,
  toNumber,
  toString,
} from 'lodash';
import { Component, createContext, forwardRef, useMemo } from 'react';
import {
  getMetadataEncryptionForLogin,
  getMetadataEncryptionForSignup,
  getPersonalTagsByPageRequest,
  getSavedUsersByPageRequest,
  getUserBasicInfo,
  getUserNotificationsByPage,
  markNotificationsReadRequest,
  probeUser,
  saveUserForAccessRequest,
  sendUserLoginRequest,
  sendUserLogoutRequest,
  sendUserSignupRequest,
} from '../api';
import {
  encryptCredientialInput,
  getAuthenticatedHeaders,
  getDataRefIdValue,
  getUserIdFromObject,
  isMobileView,
} from 'src/helpers/utils';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';
import SystemThemeHelperComponent, {
  isSystemThemeDarkMode,
} from './SystemThemeHelperComponent';
import { applyDarkModeTheme, removeDarkModeTheme } from 'src/helpers/theme';
import { fromAuthPage, isPathnameLightModeOnly } from 'src/helpers/urls';
import { withRouter } from 'react-router-dom';
import { withOfflineStorageAndUserSettings } from '../storage';
import { withNetworkSettings } from '../network';

const { Consumer, Provider } = createContext();

export function withUserDataAndProfileSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class UserProfileManager extends Component {
  state = {
    mounted: false,
    user: null,
    profile: {
      country: '',
      region: '',
      rememberLogin: true,
      doneWithAfterSignupOnboarding: false,
      cookiesAccepted: false,
      lastSignupRecordedInSeconds: 0,
      systemThemeIsDark: isSystemThemeDarkMode(),
      theme: 'system', // light / dark / system
    },
    userPersonalTags: [],
    userPersonalTagsPage: 1,
    userPersonalTagsPageHasNext: false,
    userPersonalTagsFetching: false,
    userNotifications: [],
    userNotificationsPage: 1,
    userNotificationsFetching: false,
    userNotificationsHasNext: false,
    isLoggedIn: false,
    profileLoaded: false,
    metadataForEncrypting: {
      signup: null,
      login: null,
    },
    storedUsers: [],
    savedUsers: [],
    savedUsersPage: 1,
    savedUsersHasNext: false,
  };

  constructor(props) {
    super(props);
    this.setStateAsync = (obj) =>
      new Promise((resolve) => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.getMetadataSecretsForEncrypting();
    this.checkUserProbe();
    this.setState(
      {
        mounted: true,
      },
      this.check
    );

    document.addEventListener('visibilitychange', this.onAppUnload, false);
  }

  componentDidUpdate(prevProps) {
    const pathname = window.location.pathname;

    if (pathname !== prevProps?.location?.pathname) {
      this.checkTheme();
    }
  }

  removeLogoLoading = () => {
    const dom = document.getElementById('logoLoading');

    if (dom) {
      dom.remove();
    }
  };

  getMetadataSecretsForEncrypting = async () => {
    const { userSecretsData: userSecretsDataForLogin } =
      await getMetadataEncryptionForLogin();
    const { userSecretsData: userSecretsDataForSignup } =
      await getMetadataEncryptionForSignup();
    const { metadataForEncrypting } = this.state;

    this.setState({
      metadataForEncrypting: {
        ...metadataForEncrypting,
        login: { ...userSecretsDataForLogin },
        signup: { ...userSecretsDataForSignup },
      },
    });
  };

  checkUserProbe = async () => {
    // check country
    const { country, region, errorMessage, networkError } = await probeUser();

    if (errorMessage || networkError) {
      return;
    }

    const { profile } = this.state;
    this.setState({ profile: { ...profile, country, region } });
  };

  checkTheme = () => {
    const { profile } = this.state;
    const pathname = toString(window.location.pathname || '');
    const systemThemeIsDark = isSystemThemeDarkMode();
    const isThemeDarkMode =
      (!isPathnameLightModeOnly(pathname) ||
        (isMobileView() && fromAuthPage(pathname))) &&
      (profile?.theme === 'dark' ||
        (profile?.theme === 'system' && systemThemeIsDark));

    if (isThemeDarkMode) {
      applyDarkModeTheme();
    } else {
      removeDarkModeTheme();
    }
  };

  check = () => {
    // @todo fetch saved users
    const { user, token, profile } = this.getUserConfigInLocalStorage();
    const { profile: currentProfile, user: currentUser } = this.state;
    const isLoggedIn =
      !isEmpty(token) &&
      isString(token) &&
      !isEmpty(user) &&
      !isEmpty(user?.id);

    this.removeLogoLoading();
    this.setState(
      {
        isLoggedIn,
        user: { ...currentUser, ...user, auth: { token } },
        profile: {
          ...currentProfile,
          ...(isObject(profile) && !isEmpty(profile) ? { ...profile } : {}),
        },
        profileLoaded: true,
      },
      () => {
        const { isLoggedIn } = this.state;

        if (isLoggedIn) {
          this.getCurrentUser();
          this.getUserNotifications();
          this.getPersonalTagsCreated();
          this.getSavedUsers();
        }

        this.checkTheme();
      }
    );
  };

  markNotificationsAsRead = async (notificationIds = []) => {
    const { user, userNotifications } = this.state;
    const headers = getAuthenticatedHeaders(user);

    this.setState({
      userNotifications: map(userNotifications, (notif) => {
        const notifId = notif?.id;
        const notifRefId = getDataRefIdValue(notif);

        if (
          includes(notificationIds, notifRefId) ||
          includes(notificationIds, notifId)
        ) {
          return { ...notif, unread: false };
        }

        return notif;
      }),
    });

    await markNotificationsReadRequest(notificationIds, headers);
  };

  saveUser = async (targetUser = null) => {
    const { user } = this.props;
    const { savedUsers } = this.state;
    const userId = getUserIdFromObject(targetUser);
    const headers = getAuthenticatedHeaders(user);
    const alreadyExists = !isEmpty(
      head(
        filter(savedUsers, (userInfo) => {
          const currentUserId = getUserIdFromObject(userInfo);

          if (userId === currentUserId && !isEmpty(currentUserId)) {
            return true;
          }

          return false;
        })
      )
    );

    if (!alreadyExists) {
      this.setState({ savedUsers: [targetUser, ...savedUsers] });
    }

    await saveUserForAccessRequest(userId, headers);
  };

  getSavedUsers = async () => {
    const { user, savedUsers, savedUsersPage } = this.state;
    const headers = getAuthenticatedHeaders(user);
    const storedSavedUserIds = [];
    const {
      savedUsers: list,
      errorMessage,
      networkError,
      hasNext,
    } = await getSavedUsersByPageRequest(savedUsersPage, headers);

    if (errorMessage || networkError) {
      return;
    }

    for (let i = 0; i < size(savedUsers); i++) {
      const savedUserInfo = savedUsers[i];

      if (savedUserInfo?.userId && isString(savedUserInfo?.userId)) {
        storedSavedUserIds.push(savedUserInfo?.userId);
      }

      if (savedUserInfo?.profile_id && isString(savedUserInfo?.profile_id)) {
        storedSavedUserIds.push(savedUserInfo?.profile_id);
      }
    }

    // make sure no duplicates
    const newSavedUsers = filter(
      list,
      (userInfo) =>
        !isEmpty(userInfo) &&
        !includes(storedSavedUserIds, userInfo?.userId) &&
        !includes(storedSavedUserIds, userInfo?.profile_id) &&
        !includes(storedSavedUserIds, userInfo?.userProfileId)
    );

    this.setState({
      savedUsers: [...savedUsers, ...newSavedUsers],
      savedUsersHasNext: hasNext,
    });
  };

  getUserInitialPersonalTags = async () => {
    const { getStorageItem } = this.props;
    const { user } = this.state;
    const userId = getUserIdFromObject(user);
    const cacheKey = `userPersonalTagsInitialList${userId}`;

    if (!userId) {
      return null;
    }
    if (isFunction(getStorageItem)) {
      try {
        const res = await getStorageItem(cacheKey);

        if (isString(res)) {
          return JSON.parse(res);
        } else {
          return res;
        }
      } catch {}
    }

    return null;
  };

  storeUserInitialPersonalTags = async (params = {}) => {
    try {
      const { setStorageItem } = this.props;
      const { isLoggedIn, user } = this.state;
      const userId = getUserIdFromObject(user);
      const cacheKey = `userPersonalTagsInitialList${userId}`;
      const initialData = pick(params, [
        'userPersonalTags',
        'userPersonalTagsPage',
      ]);

      if (!isEmpty(initialData) && isFunction(setStorageItem) && isLoggedIn) {
        await setStorageItem(cacheKey, initialData);
      }
    } catch (err) {
      console.log('storeUserInitialPersonalTags err', err?.message);
    }
  };

  getPersonalTagsCreated = async () => {
    const { userPersonalTagsFetching, userPersonalTagsPage } = this.state;

    if (userPersonalTagsFetching) {
      return;
    }

    this.setState({ userPersonalTagsFetching: true });

    try {
      const initialPersonalTagsListData =
        await this.getUserInitialPersonalTags();

      if (
        userPersonalTagsPage === 1 &&
        initialPersonalTagsListData?.userPersonalTagsPage === 1 &&
        isArray(initialPersonalTagsListData?.userPersonalTags) &&
        !isEmpty(initialPersonalTagsListData?.userPersonalTags)
      ) {
        await this.setStateAsync({
          ...pick(initialPersonalTagsListData || {}, [
            'userPersonalTags',
            'userPersonalTagsPage',
          ]),
        });
      }

      const { userPersonalTags, user } = this.state;
      const headers = getAuthenticatedHeaders(user);
      const { tags, hasNext, errorMessage, networkError } =
        await getPersonalTagsByPageRequest(userPersonalTagsPage, headers);

      const currentPersonalTagIdsStored = filter(
        map(userPersonalTags, (tagInfo) => tagInfo?.id || tagInfo?.tagId)
      );

      if (!errorMessage && !networkError) {
        if (userPersonalTagsPage === 1) {
          this.storeUserInitialPersonalTags({
            userPersonalTagsPage,
            userPersonalTags: tags,
          });
        }

        this.setState(
          {
            userPersonalTags: [
              ...userPersonalTags,
              ...filter(
                tags,
                (tagInfo) =>
                  !includes(currentPersonalTagIdsStored, tagInfo?.id) &&
                  !isEmpty(tagInfo?.id)
              ),
            ],
            userPersonalTagsPageHasNext: !!hasNext,
          },
          () => {
            const { userPersonalTagsPageHasNext } = this.state;

            if (userPersonalTagsPageHasNext) {
              this.setState(
                {
                  userPersonalTagsPage: userPersonalTagsPage + 1,
                  userPersonalTagsFetching: false,
                },
                this.getPersonalTagsCreated
              );
            }
          }
        );
      }
    } catch {
    } finally {
      this.setState({ userPersonalTagsFetching: false });
    }
  };

  // get user notifications
  getUserNotifications = async () => {
    const {
      userNotificationsPage,
      user,
      userNotifications,
      userNotificationsFetching,
    } = this.state;
    const storedNotificationsId = filter(
      userNotifications,
      (notificationInfo) =>
        toString(notificationInfo?.id || notificationInfo?.notifId || '')
    );

    if (userNotificationsFetching) {
      return;
    }

    this.setState({ userNotificationsFetching: true });

    try {
      const headers = getAuthenticatedHeaders(user);
      const { notifications, hasNext, errorMessage, networkError } =
        await getUserNotificationsByPage(userNotificationsPage, headers);

      if (!errorMessage && !networkError && isArray(notifications)) {
        this.setState({
          userNotifications: [
            ...userNotifications,
            ...filter(
              notifications,
              (notif) =>
                !isEmpty(notif) &&
                !isEmpty(notif?.id) &&
                !includes(storedNotificationsId, notif?.id)
            ),
          ],
          userNotificationsHasNext: hasNext,
        });
      }
    } catch {
    } finally {
      this.setState({ userNotificationsFetching: false });
    }
  };

  getNextListOfNotifications = () => {
    const {
      userNotificationsPage,
      userNotificationsFetching,
      userNotificationsHasNext,
    } = this.state;

    if (userNotificationsFetching || !userNotificationsHasNext) {
      return;
    }

    this.setState(
      {
        userNotificationsPage: userNotificationsPage + 1,
      },
      this.getUserNotifications
    );
  };

  // get logged in user info
  getCurrentUser = async () => {
    const { markUserIsOffline } = this.props;
    const { user: currentUser } = this.state;
    const headers = getAuthenticatedHeaders(currentUser);
    const { user, userId, errorMessage, networkError } = await getUserBasicInfo(
      headers
    );

    if (networkError && isFunction(markUserIsOffline)) {
      markUserIsOffline();
    }

    if (!isEmpty(user) && isObject(user)) {
      const { user: currentUser } = this.state;
      this.setState({
        user: { ...currentUser, ...user, userId },
      });
    } else if (currentUser && toLower(errorMessage)?.includes('unauthorized')) {
      // force log out user
      this.logoutUser();
    }
  };

  consumeUserForLogin = (token = '', user = {}) => {
    if (token && user) {
      this.setState(
        {
          user: { ...user, auth: { ...this.state.user?.auth, token } },
          isLoggedIn: true,
        },
        () => {
          const { profile, isLoggedIn } = this.state;
          if (profile?.rememberLogin) {
            this.storeConfigInLocalStorage();
          }

          if (isLoggedIn) {
            this.getCurrentUser();
          }

          this.getUserNotifications();
          this.getPersonalTagsCreated();
        }
      );
    }
  };

  loginUser = async (
    username = '',
    password = '',
    onSuccess = null,
    onError = null
  ) => {
    const { metadataForEncrypting } = this.state;

    const loginSecretsData = metadataForEncrypting?.login;
    const x = split(loginSecretsData?.id, '-');
    const arr1 = loginSecretsData?.k1;
    const arr2p = head(x);
    const arr2u = last(x);
    const isUsingEmail = isCorrectEmailFormat(username);
    const usernameEncryptRes = isUsingEmail
      ? { value: username }
      : encryptCredientialInput(username, arr1, arr2u);
    const passwordEncryptRes = encryptCredientialInput(password, arr1, arr2p);

    if (!isUsingEmail && usernameEncryptRes?.err) {
      if (isFunction(onError)) {
        onError();
      }

      throw new Error('Failed to encrypt username');
    }

    if (passwordEncryptRes?.err) {
      if (isFunction(onError)) {
        onError();
      }
      throw new Error('Failed to encrypt password');
    }

    const encryptedUsername = usernameEncryptRes?.value;
    const encryptedPassword = passwordEncryptRes?.value;

    const { user, token, userId, success } = await sendUserLoginRequest(
      encryptedUsername,
      encryptedPassword
    );

    if (success) {
      this.consumeUserForLogin(token, { ...user, userId, id: userId });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } else if (isFunction(onError)) {
      onError();
    }
  };

  signUpUser = async (
    username = '',
    password = '',
    email = '',
    fullName = '',
    onSuccess = null,
    onError = null
  ) => {
    const { metadataForEncrypting, profile } = this.state;
    const signUpSecretsData = metadataForEncrypting?.signup;
    const x = split(signUpSecretsData?.id, '-');
    const arr1 = signUpSecretsData?.k1;
    const arr2p = head(x);
    const arr2u = last(x);
    const isUsingEmail = isCorrectEmailFormat(username);
    const usernameEncryptRes = isUsingEmail
      ? { value: username }
      : encryptCredientialInput(username, arr1, arr2u);
    const passwordEncryptRes = encryptCredientialInput(password, arr1, arr2p);

    if (!isUsingEmail && usernameEncryptRes?.err) {
      throw new Error('Failed to encrypt username');
    }

    if (passwordEncryptRes?.err) {
      throw new Error('Failed to encrypt password');
    }

    const encryptedUsername = usernameEncryptRes?.value;
    const encryptedPassword = passwordEncryptRes?.value;
    const { user, token, userId, success, errorMessage, networkError } =
      await sendUserSignupRequest(
        encryptedUsername,
        email,
        encryptedPassword,
        fullName
      );

    if (success) {
      this.setState({ profile: { ...profile, rememberLogin: true } }, () => {
        this.consumeUserForLogin(token, { ...user, userId, id: userId });
      });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } else if (isFunction(onError)) {
      onError({ errorMessage, networkError });
    }
  };

  logoutUser = () => {
    // send logout request first

    const { user } = this.state;
    const headers = getAuthenticatedHeaders(user);

    sendUserLogoutRequest(headers);
    this.clearUserConfigInLocalStorage();
  };

  clearUserConfigInLocalStorage = () => {
    localStorage.removeItem(`chamuT1Set`);
    localStorage.removeItem(`chamuT2Set`);
    localStorage.removeItem(`chamuT3Set`);
    localStorage.removeItem(`chamuT4Set`);
    localStorage.removeItem(`chamuT5Set`);
    localStorage.removeItem(`chamuT6Set`);
    localStorage.removeItem('chamuTTotal');
    localStorage.removeItem('chamuUser');

    this.setState({
      user: null,
      isLoggedIn: false,
      profileLoaded: true,
      userPersonalTags: [],
      userPersonalTagsPage: 1,
      userPersonalTagsPageHasNext: false,
      userPersonalTagsFetching: false,
      userNotifications: [],
      userNotificationsPage: 1,
      userNotificationsFetching: false,
      userNotificationsHasNext: false,
      storedUsers: [],
      savedUsers: [],
      savedUsersPage: 1,
      savedUsersHasNext: false,
    });
  };

  storeConfigInLocalStorage = () => {
    const { user, profile } = this.state;
    const token = toString(user?.auth?.token || '');
    const userStringify = JSON.stringify({ ...user, auth: '' });
    const profileStringify = JSON.stringify(profile);
    const splitToken = split(token, '.');

    for (let i = 0; i < size(splitToken); i++) {
      const str = splitToken[i];

      if (str) {
        localStorage.setItem(`chamuT${i + 1}Set`, str);
      }
    }

    if (profile?.rememberLogin) {
      localStorage.setItem('chamuTTotal', size(splitToken));
      localStorage.setItem('chamuUser', userStringify);
    }

    localStorage.setItem('chamuProfile', profileStringify);
  };

  onAppUnload = () => {
    const { profileLoaded, profile } = this.state;

    if (!profileLoaded) {
      return;
    }

    if (document?.hidden && profile?.rememberLogin) {
      this.storeConfigInLocalStorage();
    }
  };

  getUserConfigInLocalStorage = () => {
    let user = null;
    let profile = {};
    let token = '';
    let totalTokenSplit = toNumber(localStorage.getItem('chamuTTotal'));

    try {
      if (!isNaN(totalTokenSplit) && totalTokenSplit) {
        for (let i = 0; i < totalTokenSplit; i++) {
          const tokenPartition =
            localStorage.getItem(`chamuT${i + 1}Set`) || '';

          if (tokenPartition) {
            if (i <= 0) {
              token = tokenPartition;
            } else {
              token = `${token}.${tokenPartition}`;
            }
          }
        }
      }
    } catch {
      token = '';
    }

    try {
      user = JSON.parse(localStorage.getItem('chamuUser'));
    } catch {
      user = {};
    }

    try {
      profile = JSON.parse(localStorage.getItem('chamuProfile'));
    } catch {
      profile = {};
    }

    return { profile, token, user };
  };

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onAppUnload, false);
  }

  applyLightMode = () => {
    const { profile } = this.state;

    this.setState(
      {
        profile: { ...profile, theme: 'light' },
      },
      this.storeConfigInLocalStorage
    );

    // apply light mode css
    removeDarkModeTheme();
  };

  applyDarkMode = () => {
    const { profile } = this.state;

    this.setState(
      {
        profile: { ...profile, theme: 'dark' },
      },
      this.storeConfigInLocalStorage
    );

    applyDarkModeTheme();
  };

  applySystemTheme = () => {
    const { profile } = this.state;
    const systemThemeIsDark = profile?.systemThemeIsDark;
    const isThemeDarkMode = systemThemeIsDark;

    this.setState(
      {
        profile: { ...profile, theme: 'system' },
      },
      this.storeConfigInLocalStorage
    );

    if (isThemeDarkMode) {
      // apply css
      applyDarkModeTheme();
    } else {
      // apply light mode css
      removeDarkModeTheme();
    }
  };

  setSystemThemeIsDark = (val) => {
    const { profile } = this.state;

    if (isBoolean(val)) {
      this.setState({
        profile: { ...profile, systemThemeIsDark: val },
      });
    }
  };

  toggleRememberLogin = () => {
    const { profile } = this.state;

    this.setState({
      profile: { ...profile, rememberLogin: !profile?.rememberLogin },
    });
  };

  updateUserProps = (params = {}) => {
    const { user } = this.state;

    this.setState(
      {
        user: {
          ...user,
          ...pick(params, [
            'fullName',
            'username',
            'email',
            'password',
            'settings',
            'notifications',
            'plan',
            'image',
          ]),
        },
      },
      this.storeConfigInLocalStorage
    );
  };

  addPersonalTag = (tagInfo = null) => {
    const { userPersonalTags } = this.state;
    const find = filter(userPersonalTags, (tag) => {
      const tagName = tag?.name || tag?.tag || '';

      if (tagName === tagInfo?.name) {
        return true;
      }

      return false;
    });
    const nameAlreadyExists = !isEmpty(find);

    if (nameAlreadyExists || !tagInfo?.name) {
      return;
    }

    if (!isEmpty(tagInfo)) {
      this.setState({
        userPersonalTags: [tagInfo, ...userPersonalTags],
      });
    }
  };

  editPersonalTag = (tagId = '', params = {}) => {
    const { userPersonalTags } = this.state;

    if (!isEmpty(tagId) && !isEmpty(params)) {
      this.setState({
        userPersonalTags: map(userPersonalTags, (tagInfo) => {
          if (tagId && tagInfo?.id === tagId) {
            return { ...tagInfo, ...params };
          }

          return tagInfo;
        }),
      });
    }
  };

  removePersonalTag = (tagId = '') => {
    const { userPersonalTags } = this.state;

    if (!isEmpty(tagId)) {
      this.setState({
        userPersonalTags: filter(
          userPersonalTags,
          (tagInfo) => tagId && tagInfo?.id !== tagId
        ),
      });
    }
  };

  render() {
    const { children } = this.props;
    const {
      user,
      profileLoaded,
      profile,
      isLoggedIn,
      metadataForEncrypting,
      userNotifications,
      userNotificationsPage,
      userNotificationsHasNext,
      userNotificationsFetching,

      userPersonalTags,
      userPersonalTagsFetching,
      userPersonalTagsPage,
      userPersonalTagsPageHasNext,
      savedUsers,
    } = this.state;
    const userIsPremium = Boolean(user?.plan?.is_premium_w_group);
    const systemThemeIsDark = profile?.systemThemeIsDark;
    const pathname = toString(window?.location?.pathname || '');
    const isThemeDarkMode =
      (!isPathnameLightModeOnly(pathname) ||
        (isMobileView() && fromAuthPage(pathname))) &&
      (profile?.theme === 'dark' ||
        (profile?.theme === 'system' && systemThemeIsDark));
    const userHasNewNotifications = !isEmpty(
      head(
        filter(userNotifications, (notif) => !isEmpty(notif) && notif?.unread)
      )
    );

    const props = {
      user,
      userIsPremium,
      // notifications
      userHasNewNotifications,
      userNotificationsPage,
      userNotifications,
      userNotificationsHasNext,
      userNotificationsFetching,
      // tags
      userPersonalTags,
      userPersonalTagsFetching,
      userPersonalTagsPage,
      userPersonalTagsPageHasNext,

      profile,
      profileLoaded,
      isLoggedIn,
      metadataForEncrypting,
      isThemeDarkMode,
      systemThemeIsDark,
      savedUsers,
      rememberLogin: profile?.rememberLogin,
      addPersonalTag: this.addPersonalTag,
      editPersonalTag: this.editPersonalTag,
      removePersonalTag: this.removePersonalTag,
      checkTheme: this.checkTheme,
      updateUserProps: this.updateUserProps,
      setSystemThemeIsDark: this.setSystemThemeIsDark,
      getNextListOfNotifications: this.getNextListOfNotifications,
      toggleRememberLogin: this.toggleRememberLogin,
      getPersonalTagsCreated: this.getPersonalTagsCreated,
      applyDarkMode: this.applyDarkMode,
      applyLightMode: this.applyLightMode,
      applySystemTheme: this.applySystemTheme,
      logoutUser: this.logoutUser,
      loginUser: this.loginUser,
      signUpUser: this.signUpUser,
      saveUser: this.saveUser,
      markNotificationsAsRead: this.markNotificationsAsRead,
    };

    return (
      <ProfileManagerContextMemoized {...props}>
        <ProfileManagerWrapper>{children}</ProfileManagerWrapper>{' '}
      </ProfileManagerContextMemoized>
    );
  }
}

const ProfileManagerContextMemoized = ({ children, ...props }) => {
  const {
    user,
    userIsPremium,
    // notifications
    userHasNewNotifications,
    userNotificationsPage,
    userNotifications,
    userNotificationsHasNext,
    userNotificationsFetching,
    // tags
    userPersonalTags,
    userPersonalTagsFetching,
    userPersonalTagsPage,
    userPersonalTagsPageHasNext,

    profile,
    profileLoaded,
    isLoggedIn,
    metadataForEncrypting,
    isThemeDarkMode,
    systemThemeIsDark,
    savedUsers,
    rememberLogin,
  } = props;

  const propsValue = useMemo(
    () => ({ ...props }),
    // eslint-disable-next-line
    [
      user,
      user?.plan,
      userIsPremium,
      userHasNewNotifications,
      userNotificationsPage,
      userNotifications,
      userNotificationsHasNext,
      userNotificationsFetching,
      userPersonalTags,
      userPersonalTagsFetching,
      userPersonalTagsPage,
      userPersonalTagsPageHasNext,
      profile,
      profileLoaded,
      isLoggedIn,
      metadataForEncrypting,
      isThemeDarkMode,
      systemThemeIsDark,
      savedUsers,
      rememberLogin,
    ]
  );

  return <Provider value={propsValue}>{children || null}</Provider>;
};

const ProfileManagerWrapper = (props) => {
  const { children } = props;

  return <SystemThemeHelperComponent>{children}</SystemThemeHelperComponent>;
};

export default withOfflineStorageAndUserSettings(
  withNetworkSettings(withRouter(UserProfileManager))
);
