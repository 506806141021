const NoteListCtaIconLight = (p) => {
  return (
    <svg
      width='180'
      height='180'
      viewBox='0 0 180 180'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='90' cy='90' r='90' fill='#F2F5FB' />
      <rect
        x='31.0558'
        y='52.0588'
        width='82.7354'
        height='107.626'
        rx='10.5'
        transform='rotate(-12.7842 31.0558 52.0588)'
        fill='#C3CFE3'
        stroke='#95A7C0'
        strokeWidth='3'
      />
      <path
        d='M133.419 141.259H71.4089C65.6099 141.259 60.9089 136.558 60.9089 130.759V43.0345C60.9089 37.2355 65.6099 32.5345 71.4089 32.5345H112.92C115.722 32.5345 118.408 33.6544 120.38 35.6449L140.878 56.3378C142.826 58.304 143.919 60.9597 143.919 63.7273V130.759C143.919 136.558 139.218 141.259 133.419 141.259Z'
        fill='white'
        stroke='#95A7C0'
        strokeWidth='3'
      />
      <path
        d='M112.611 103.744C111.303 107.846 107.233 110.837 102.414 110.837C97.5949 110.837 93.5245 107.846 92.2167 103.744'
        stroke='#95A7C0'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <circle cx='82.0197' cy='79.3596' r='4.87685' fill='#95A7C0' />
      <circle cx='121.921' cy='79.3596' r='4.87685' fill='#95A7C0' />
      <path
        d='M120 58V35.5L142 58H120Z'
        fill='#E1E7F0'
        stroke='#95A7C0'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='151.626'
        cy='19.5074'
        r='16.234'
        fill='#E1E7F0'
        stroke='#95A7C0'
        strokeWidth='3'
      />
      <path
        d='M151.626 12.4138V26.601M144.532 19.5074H158.719'
        stroke='#95A7C0'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default NoteListCtaIconLight;
