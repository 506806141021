import styles from './NoteItem.module.scss';
import cx from 'classnames';
import PriorityIcon from 'src/components/priority-icon';
import moment from 'moment/moment';
import DeleteNote from 'src/modals/delete-note';
import CustomBadge from 'src/components/custom-badge';
import { Link } from 'react-router-dom';
import { ceil, filter, isEmpty, isFunction, map, toUpper } from 'lodash';
import { useMemo, useState } from 'react';
import { isMobileViewScreen } from '.';
import {
  Avatar,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import {
  copyTextToClipboard,
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
} from 'src/helpers/utils';
import { LinkIcon, MoreDotIcon } from 'src/icons';
import { Button } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import i18n from 'src/locales';
import { withModalPromptSettings } from 'src/managers/modal';
import { isAllowedToEditTags } from 'src/helpers/spaces';

const NoteItem = (props) => {
  const {
    noteData,
    user,
    isThemeDarkMode,
    setActiveModalDom,
    selectedSpace,
    selectedSpaceId,
  } = props;

  const noteId = useMemo(
    () =>
      toUpper(noteData?.noteId || noteData?.task_id || noteData?.taskId || ''),
    [noteData]
  );

  const canEdit = useMemo(() => {
    const currentUserId = getUserIdFromObject(user);
    const currentUserProfileId = getUserProfileIdFromObject(user);
    const noteDataOwnerUserId = noteData?.ownerUserId;

    if (noteData?.isGettingStartedNote || noteData?.getting_started_task) {
      return false;
    }

    return (
      (currentUserId && currentUserId === noteDataOwnerUserId) ||
      (currentUserId && currentUserId === noteData?.owner) ||
      (currentUserProfileId && currentUserProfileId === noteData?.owner) ||
      (!isEmpty(noteId) && noteData?.canEdit)
    );
  }, [noteId, user, noteData]);

  const canDelete = useMemo(() => {
    const currentUserId = getUserIdFromObject(user);
    const currentUserProfileId = getUserProfileIdFromObject(user);
    const noteDataOwnerUserId = noteData?.ownerUserId;

    return (
      (currentUserId && currentUserId === noteDataOwnerUserId) ||
      (currentUserId && currentUserId === noteData?.owner) ||
      (currentUserProfileId && currentUserProfileId === noteData?.owner) ||
      Boolean(!isEmpty(noteId) && noteData?.canDelete)
    );
  }, [noteId, user, noteData]);

  const canUnsubscribe = useMemo(() => {
    if (noteData?.isGettingStartedNote || noteData?.getting_started_task) {
      return false;
    }

    return Boolean(!isEmpty(noteId) && noteData?.canUnsubscribe);
  }, [noteId, noteData]);

  const noteTitle = useMemo(() => noteData?.title, [noteData?.title]);

  const [showMenu, setShowMenu] = useState(false);

  const prioKey = useMemo(() => {
    if (noteData?.priority === 1) {
      return 'low';
    } else if (noteData?.priority === 2) {
      return 'normal';
    } else if (noteData?.priority === 3) {
      return 'high';
    }

    return '';
  }, [noteData]);

  const toast = useToast({ position: 'top' });

  const noteUrlBasePathname = useMemo(
    () =>
      `/note/${toUpper(noteId)}?u=${noteData?.noteRefId || noteData?.refId}`,
    [noteData, noteId]
  );

  const noteUrl = useMemo(() => {
    return `${window.location.origin}${noteUrlBasePathname}`;
  }, [noteUrlBasePathname]);

  const onCopyLink = () => {
    toast({
      status: 'success',
      title: 'Copied to clipboard',
      isClosable: false,
      duration: 1_500,
    });

    if (isFunction(copyTextToClipboard)) {
      copyTextToClipboard(noteUrl);
    }
  };

  const onShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const onDeletePrompt = () => {
    // confirm

    if (canUnsubscribe || canDelete) {
      if (isFunction(setActiveModalDom)) {
        setActiveModalDom(<DeleteNote noteDataToRemove={{ ...noteData }} />);
      }
    }
  };

  const tags = useMemo(() => {
    if (selectedSpaceId && selectedSpaceId !== 'personal') {
      return filter(noteData?.spaceTags || []);
    }

    return filter(noteData?.personal_tags || []);
  }, [selectedSpaceId, noteData?.spaceTags, noteData?.personal_tags]);

  const createdDateFormatted = useMemo(() => {
    const timestampNow = Date.now();
    const timestampInSeconds = ceil(timestampNow / 1000);
    let createdInSeconds = noteData?.createdSeconds || noteData?.created;

    if (timestampInSeconds - createdInSeconds <= 60) {
      return 'Just now';
    }

    if (createdInSeconds * 1000 <= timestampNow) {
      createdInSeconds = createdInSeconds * 1000;
    }

    const createdDateObject = new Date(createdInSeconds);
    return moment(createdDateObject).fromNow();
  }, [noteData]);

  const onShowTags = () => {};

  const canAttachTags = useMemo(
    () =>
      // disable for now
      false &&
      (!selectedSpaceId ||
        selectedSpaceId === 'personal' ||
        (selectedSpaceId && isAllowedToEditTags(selectedSpace))),
    [selectedSpaceId, selectedSpace]
  );

  return (
    <div className={styles.note_item}>
      {!isMobileViewScreen() && (
        <div className={cx(styles.flex_row_xy, styles.desktop)}>
          <div className={styles.note_id}>
            <p>{noteId}</p>
          </div>

          <div className={cx(styles.flex_row_xy, styles.note_priority)}>
            <PriorityIcon priority_key={prioKey} />{' '}
          </div>
          <div className={cx(styles.flex_row_xy, styles.note_author)}>
            <Avatar
              name={getUserDisplayName(noteData?.authorInfo)}
              src={noteData?.authorInfo?.image || ''}
              height={'40px'}
              width={'40px'}
              background={'#000'}
              backgroundColor={'#000'}
            />
          </div>

          <div
            className={cx(styles.note_title, styles.desktop_title, {
              [styles.note_title_dark]: isThemeDarkMode,
            })}
          >
            <p>{noteTitle}</p>
            <div className={cx(styles.flex_row_xy, styles.date)}>
              <p>{createdDateFormatted}</p>
            </div>
            {!isEmpty(noteUrlBasePathname) && !isEmpty(noteId) && (
              <div className={cx(styles.cover, styles.note_link)}>
                <Link to={noteUrlBasePathname} />
              </div>
            )}
          </div>
          <div
            className={cx(
              styles.flex_row_xy,
              styles.note_label,
              styles.desktop_label
            )}
          >
            <ul>
              {noteData?.shared && (
                <li>
                  <CustomBadge
                    name={i18n('task_view_is_shared_to_you')}
                    type={'subscribed'}
                  />
                </li>
              )}

              {noteData?.space?.name && (
                <li>
                  <CustomBadge name={noteData?.space?.name} type={'space'} />
                </li>
              )}
              {map(tags, (tagInfo) => (
                <li key={`noteItemTag${tagInfo?.id}`}>
                  <CustomBadge
                    name={tagInfo?.name || tagInfo?.tag}
                    type={'personal tag'}
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className={cx(styles.flex_row_xy, styles.note_copy_link)}>
            <Button
              className={cx(styles.flex_row_xy, styles.copy_link_button, {
                [styles.copy_link_button_dark]: isThemeDarkMode,
              })}
              appearance="minimal"
              onClick={onCopyLink}
            >
              <LinkIcon />
            </Button>
          </div>
          <div className={cx(styles.flex_row_xy, styles.note_actions)}>
            <Menu isOpen={showMenu} onClose={() => setShowMenu(false)}>
              <MenuButton
                variant={'ghost'}
                as={IconButton}
                onClick={onShowMenu}
                className={cx(styles.flex_row_xy, styles.show_more, {
                  [styles.show_more_dark]: isThemeDarkMode,
                })}
                icon={<MoreDotIcon height={'20px'} width={'20px'} />}
              ></MenuButton>
              <MenuList
                minWidth={isMobileViewScreen() ? '200px' : '100px'}
                className={cx({ [styles.menu_list_dark]: isThemeDarkMode })}
              >
                {canEdit && (
                  <MenuItem
                    minWidth={'100px'}
                    className={cx(styles.flex_row_xy, styles.menu_item, {
                      [styles.menu_item_dark]: isThemeDarkMode,
                    })}
                  >
                    <Link to={noteUrlBasePathname}>Edit</Link>
                  </MenuItem>
                )}

                {canAttachTags && (
                  <MenuItem
                    minWidth={'100px'}
                    className={cx(styles.flex_row_xy, styles.menu_item, {
                      [styles.menu_item_dark]: isThemeDarkMode,
                    })}
                    onClick={onShowTags}
                  >
                    Tags
                  </MenuItem>
                )}
                <MenuItem
                  minWidth={'100px'}
                  className={cx(styles.flex_row_xy, styles.menu_item, {
                    [styles.menu_item_dark]: isThemeDarkMode,
                  })}
                  onClick={onCopyLink}
                >
                  Copy Link
                </MenuItem>
                {(canDelete || canUnsubscribe) && (
                  <MenuItem
                    minWidth={'100px'}
                    className={cx(
                      styles.flex_row_xy,
                      styles.menu_item,
                      styles.note_item_delete,
                      {
                        [styles.menu_item_dark]: isThemeDarkMode,
                      }
                    )}
                    onClick={onDeletePrompt}
                    color={'#df3e4a'}
                  >
                    {canUnsubscribe ? 'Unsubscribe' : 'Delete'}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </div>
        </div>
      )}
      {isMobileViewScreen() && (
        <div className={cx(styles.flex_row_xy, styles.mobile)}>
          <div className={cx(styles.flex_row_xy, styles.note_author)}>
            <Avatar
              name={getUserDisplayName(noteData?.authorInfo)}
              src={noteData?.authorInfo?.image || ''}
              height={'44px'}
              width={'44px'}
            />
          </div>
          <div
            className={cx(styles.flex_row_xy, styles.note_title, {
              [styles.note_title_dark]: isThemeDarkMode,
            })}
          >
            <p>{noteTitle}</p>
            <div className={cx(styles.flex_row_xy, styles.note_label)}>
              <ul>
                {map(tags, (tagInfo) => (
                  <li key={`noteItemTag${tagInfo?.id}`}>
                    <CustomBadge
                      name={tagInfo?.name || tagInfo?.tag}
                      type={'personal tag'}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className={cx(styles.flex_row_xy, styles.date)}>
              <p>{createdDateFormatted}</p>
            </div>
            {!isEmpty(noteUrlBasePathname) && !isEmpty(noteId) && (
              <div className={cx(styles.cover, styles.note_link)}>
                <Link to={noteUrlBasePathname} />
              </div>
            )}
          </div>
          <div className={cx(styles.flex_row_xy, styles.note_actions)}>
            <Menu isOpen={showMenu} onClose={() => setShowMenu(false)}>
              <MenuButton
                variant={'ghost'}
                as={IconButton}
                onClick={onShowMenu}
                className={cx(styles.flex_row_xy, styles.show_more, {
                  [styles.show_more_dark]: isThemeDarkMode,
                })}
                icon={<MoreDotIcon height={'20px'} width={'20px'} />}
              ></MenuButton>
              <MenuList
                minWidth={'100px'}
                className={cx({ [styles.menu_list_dark]: isThemeDarkMode })}
              >
                {canEdit && (
                  <MenuItem
                    minWidth={'100px'}
                    className={cx(styles.flex_row_xy, styles.menu_item, {
                      [styles.menu_item_dark]: isThemeDarkMode,
                    })}
                  >
                    <Link to={noteUrlBasePathname}>Edit</Link>
                  </MenuItem>
                )}

                {canAttachTags && (
                  <MenuItem
                    minWidth={'100px'}
                    className={cx(styles.flex_row_xy, styles.menu_item, {
                      [styles.menu_item_dark]: isThemeDarkMode,
                    })}
                    onClick={onShowTags}
                  >
                    Tags
                  </MenuItem>
                )}

                <MenuItem
                  minWidth={'100px'}
                  className={cx(styles.flex_row_xy, styles.menu_item, {
                    [styles.menu_item_dark]: isThemeDarkMode,
                  })}
                  onClick={onCopyLink}
                >
                  Copy Link
                </MenuItem>
                {(canDelete || canUnsubscribe) && (
                  <MenuItem
                    minWidth={'100px'}
                    className={cx(
                      styles.flex_row_xy,
                      styles.menu_item,
                      styles.note_item_delete,
                      {
                        [styles.menu_item_dark]: isThemeDarkMode,
                      }
                    )}
                    onClick={onDeletePrompt}
                    color={'#df3e4a'}
                  >
                    {canUnsubscribe ? 'Unsubscribe' : 'Delete'}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(NoteItem))
);
