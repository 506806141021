import { useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './SpaceMembers.module.scss';
import i18n from 'src/locales';
import SpaceMemberWithRole from 'src/components/space-member-role';
import k from 'src/constants/k';
// import {
//   disableBodyScroll,
//   enableBodyScroll,
// } from 'src/lib/bodyScrollLock.min';
import {
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
  timeout,
} from 'src/helpers/utils';
import {
  filter,
  // isObject,
  head,
  isArray,
  // toLower,
  trim,
  isEmpty,
  toString,
  reduce,
  includes,
  size,
  map,
} from 'lodash';
import {
  IconButton,
  CrossIcon,
  TextInput,
  Button,
  Spinner,
} from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { useDebounce } from 'src/hooks';
import { Avatar } from '@chakra-ui/react';
import {
  isAllowedToAddMembers,
  isMemberAdmin,
  isUserCreator,
} from 'src/helpers/spaces';
import { getUserInfoByIdOrUsernameOrEmailRequest } from 'src/managers/api';

const SpaceMembers = (props) => {
  const newMemberResultsListUnique = 'newMemberResultsListUnique';
  const {
    isThemeDarkMode,
    savedUsers: connectedUsers = [],
    selectedSpace,
    selectedSpaceId: spaceId,
    userSpaces,
  } = props;

  const spaceInfo = useMemo(
    () =>
      selectedSpace ||
      head(filter(userSpaces, (spaceInfo) => spaceInfo?.id === spaceId)),
    [userSpaces, selectedSpace, spaceId]
  );

  const spaceMembers = useMemo(() => {
    const find = spaceInfo;

    if (!isEmpty(find)) {
      const membersNotFormatted = find?.members || [];

      return reduce(
        membersNotFormatted,
        (acc, members) => {
          if (isArray(members)) {
            acc = [...acc, ...members];
          }

          return acc;
        },
        []
      );
    }

    return [];
  }, [spaceInfo]);

  const noMembers = useMemo(
    () => !spaceMembers || isEmpty(spaceMembers),
    [spaceMembers]
  );

  const addMemberRef = useRef(null);
  const membersListRef = useRef(null);

  const [userNotFound, setUserNotFound] = useState(false);
  const [searching, setSearching] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [newMemberAboutToBe, setNewMemberAboutTobe] = useState(null);

  const [newMemberInputFocus, setNewMemberInputFocus] = useState(false);
  const [newMemberInput, setNewMemberInput] = useState('');

  const membersWithoutCreator = useMemo(
    () =>
      filter(
        spaceMembers,
        (memberInfo) =>
          !isEmpty(memberInfo) &&
          !includes(memberInfo?.role, k.SPACES_ROLES_ENUM.creator) &&
          !includes(memberInfo?.roles, k.SPACES_ROLES_ENUM.creator)
      ),
    [spaceMembers]
  );

  const creator = useMemo(
    () =>
      head(
        filter(
          spaceMembers,
          (memberInfo) =>
            !isEmpty(memberInfo) &&
            (includes(memberInfo?.role, k.SPACES_ROLES_ENUM.creator) ||
              includes(memberInfo?.roles, k.SPACES_ROLES_ENUM.creator))
        )
      ),
    [spaceMembers]
  );

  const totalMembers = useMemo(
    () => spaceInfo?.totalMembers || size(spaceMembers),
    [spaceInfo, spaceMembers]
  );

  const allowedToAddMembers = useMemo(
    () =>
      false &&
      (isUserCreator(spaceInfo) ||
        isMemberAdmin(spaceInfo) ||
        isAllowedToAddMembers(spaceInfo)),
    [spaceInfo]
  );

  const availableRoles = useMemo(
    () => spaceInfo?.settings?.availableRoles || [],
    [spaceInfo]
  );

  const allowedToAssignRoles = false;

  const onNewMemberInput = (evt) => {
    const newVal = toString(evt?.target?.value || '');
    setNewMemberInput(newVal);
    search(newVal);
  };

  const search = useDebounce(async (input = '') => {
    const sanitizedInput = trim(input || newMemberInput);
    const { user: targetUser } = await getUserInfoByIdOrUsernameOrEmailRequest(
      sanitizedInput
    );

    const targetUserId = getUserIdFromObject(targetUser);

    if (
      input?.includes('gmai.co') ||
      input?.includes('gma.co') ||
      input?.endsWith('yaho.co')
    ) {
      // catch incorrect formats
      await timeout(400);
      setSearchResults([]);
      return;
    }

    if (!isEmpty(targetUser) && !targetUser?.deleted && targetUserId) {
      setSearchResults([targetUser]);
    }
  }, 300);

  const onNewMemberInputFocus = () => {
    setNewMemberInputFocus(true);
  };

  const close = () => {};

  const confirmAdd = () => {};

  return (
    <div
      className={cx(styles.space_members, styles.flex_row_xy, {
        [styles.space_members_dark]: isThemeDarkMode,
      })}
    >
      <div className={styles.space_members_raw}>
        <div
          className={cx(styles.title, {
            [styles.title_dark]: isThemeDarkMode,
          })}
        >
          <h1>
            {`${i18n('space_members')}`}
            <span>{`${totalMembers ? `( ${totalMembers} total )` : ''}`}</span>
          </h1>
        </div>
        <div
          ref={addMemberRef}
          className={cx(styles.add_member, {
            [styles.hide_element]: !allowedToAddMembers,
          })}
        >
          <div
            className={cx(styles.clear_input, {
              [styles.clear_input_dark]: isThemeDarkMode,
            })}
          >
            <IconButton
              icon={CrossIcon}
              appearance={'minimal'}
              onClick={() => {
                setNewMemberInput('');
                setSearching('');
                setSearchResults([]);
                setNewMemberInputFocus(false);
                setNewMemberAboutTobe(null);
              }}
            />
          </div>
          <TextInput
            className={cx(styles.add_member_input, newMemberResultsListUnique, {
              [styles.add_member_input_dark]: isThemeDarkMode,
            })}
            placeholder={i18n('space_members_add_member')}
            maxLength={80}
            onChange={onNewMemberInput}
            value={newMemberInput}
            onFocus={onNewMemberInputFocus}
          />
          <div
            className={cx(styles.search_results, newMemberResultsListUnique, {
              [styles.search_results_dark]: isThemeDarkMode,
              [styles.hide_element]: !newMemberInputFocus,
            })}
          >
            {userNotFound && (
              <div className={styles.search_results_empty}>
                <p>{i18n('space_members_empty_results')}</p>
              </div>
            )}
            {searching && (
              <div
                className={cx(
                  styles.search_results_loading,
                  styles.flex_row_xy
                )}
              >
                <Spinner height={18} width={18} />
              </div>
            )}

            {!userNotFound && !searching && (
              <ul className={newMemberResultsListUnique}>
                {map(
                  isEmpty(searchResults) ? connectedUsers : searchResults,
                  (targetUser) => {
                    const targetUserDisplayName =
                      getUserDisplayName(targetUser);
                    const previewUserId = getUserIdFromObject(targetUser);
                    const previewUserProfileId =
                      getUserProfileIdFromObject(targetUser);

                    return (
                      <li
                        key={`space-members-user${previewUserId}`}
                        className={cx(
                          styles.flex_row_xy,
                          newMemberResultsListUnique,
                          `${previewUserId || previewUserProfileId}`
                        )}
                      >
                        <div className={cx(styles.flex_row_xy, styles.avatar)}>
                          <Avatar
                            name={targetUserDisplayName}
                            src={targetUser?.image}
                            height={'30px'}
                            width={'30px'}
                          />
                        </div>
                        <div className={styles.name}>
                          <h5>{targetUserDisplayName}</h5>
                        </div>
                        <div
                          className={cx(
                            styles.cover,
                            newMemberResultsListUnique,
                            `${previewUserId || previewUserProfileId}`
                          )}
                        ></div>
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </div>
        </div>
        {!isEmpty(newMemberAboutToBe?.userId) && (
          <div
            className={cx(styles.add_confirmation, {
              [styles.add_confirmation_dark]: isThemeDarkMode,
            })}
          >
            <p>{i18n('space_members_add_message')}</p>
            <div className={styles.flex_row_xy}>
              <div className={styles.flex_row_xy}>
                <div className={cx(styles.flex_row_xy, styles.avatar)}>
                  <Avatar
                    name={getUserDisplayName(newMemberAboutToBe)}
                    src={newMemberAboutToBe?.image}
                    height={'30px'}
                    width={'30px'}
                    backgroundColor={'#000'}
                    background={'#000'}
                  />
                </div>
                <div className={cx(styles.flex_row_xy, styles.name)}>
                  <h5>{getUserDisplayName(newMemberAboutToBe)}</h5>
                </div>
              </div>
              <div className={styles.actions}>
                <Button
                  className={styles.confirm}
                  onClick={confirmAdd}
                  appearance="minimal"
                >
                  <p>{i18n('space_members_add_confirm')}</p>
                </Button>
              </div>
            </div>
          </div>
        )}

        {noMembers && (
          <div
            className={cx(styles.no_members, {
              [styles.no_members_dark]: isThemeDarkMode,
            })}
          >
            <h5>{i18n('space_info_no_members')}</h5>
          </div>
        )}

        <div
          className={cx(styles.list, styles.with_actions, {
            [styles.hide_element]: noMembers,
            [styles.list_dark]: isThemeDarkMode,
          })}
          ref={membersListRef}
        >
          <ul>
            {membersWithoutCreator.map((member) => {
              return (
                <li key={`space-member-${member.profile_id}-${member.idx}`}>
                  <SpaceMemberWithRole
                    memberInfo={member}
                    spaceId={spaceId}
                    role={member.role || []}
                    roleId={member?.roleId || ''}
                    roleIds={member?.roleIds}
                    close={close}
                    allowedToAssignRoles={allowedToAssignRoles}
                    availableRoles={availableRoles}
                  />
                </li>
              );
            })}
            {!isEmpty(creator) && (
              <li>
                <SpaceMemberWithRole
                  memberInfo={creator}
                  spaceId={spaceId}
                  role={creator.role || []}
                  close={close}
                  allowedToAssignRoles={allowedToAssignRoles}
                  availableRoles={availableRoles}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(SpaceMembers)
);
