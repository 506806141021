import { useState } from 'react';
import cx from 'classnames';
import styles from './FAQ.module.scss';
import i18n from 'src/locales';
import { IconButton, CaretDownIcon } from 'evergreen-ui';

const FAQItem = (props) => {
  const [expand, setExpand] = useState(false);

  if (!props.title) {
    return;
  }

  return (
    <div className={styles.item}>
      <div className={styles.item_title}>
        <h1 onClick={() => setExpand(!expand)}>{`${props.title}`}</h1>
        <div
          className={cx(styles.icon_expand, {
            [styles.icon_expand_active]: expand,
          })}
        >
          <IconButton
            appearance="minimal"
            onClick={() => setExpand(!expand)}
            icon={CaretDownIcon}
          />{' '}
        </div>
      </div>
      {expand && props.message && (
        <div className={styles.answer}>
          <h5>{props.message}</h5>
        </div>
      )}
    </div>
  );
};

const FAQs = () => {
  return (
    <div className={styles.faqs_wrap}>
      <div className={styles.title}>
        <h1>{i18n('pricing_faq_title')}</h1>
      </div>
      <div className={styles.list}>
        <ul>
          <li>
            <FAQItem
              title={'Can I use Chamu for free?'}
              message={
                'Yes, we have a generous benefits provided for the free tier. For starters, you can create unlimited unit notes in your personal space.'
              }
            />
          </li>
          <li>
            <FAQItem
              title={'What is a unit note?'}
              message={
                'A single unit that you can create from the app dashboard and can contain any digital information may it be notes and files.'
              }
            />
          </li>
          <li>
            <FAQItem
              title={'Is Chamu a note taking app?'}
              message={`Technically, Chamu is an information management software,\
                  we manage and secure any digital information you decide to save, may it be your notes, files, or tasks.\
                  Chamu is great at handling lots of notes and files for you and your team.`}
            />
          </li>

          <li>
            <FAQItem
              title={'What are Spaces?'}
              message={`Chamu spaces or Spaces is a concept that lets you organize your notes and files at scale, could be for you or your organization. \
                    It's like a separate virtual notebook that's outside of your personal space.`}
            />
          </li>

          <li>
            <FAQItem
              title={'Are my personal notes reflected in custom spaces?'}
              message={`No, every note created under your personal space won't be shown in another space's list.\
                    If you opt to share notes, feel free to set the privacy property as public then share the link.`}
            />
          </li>

          <li>
            <FAQItem
              title={'How do I subscribe to a premium plan?'}
              message={`From the pricing page, select and get started with Premium with Spaces`}
            />
          </li>

          <li>
            <FAQItem
              title={'Do you directly store customer credit card details?'}
              message={`No, we don't. We partnered with Stripe as our payment processor. 
              They securely manage all payment information as well as customer invoice for your subscription.\
              Stripe is compliant with PCI.`}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FAQs;
