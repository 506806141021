import cx from 'classnames';
import styles from './LifetimeCode.module.scss';
import k from 'src/constants/k';
import CustomButton from 'src/components/button';
import LogoIconWord from 'src/components/logo-icon-word';
import { useAuth } from 'src/hooks';
import { TextInputField } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { isEmpty, isFunction, toLower, toString } from 'src/helpers/utils';
import { CheckSuccessIcon } from 'src/icons';
import { withRouter } from 'react-router-dom';
import { verifyUserLifetimeCode } from 'src/managers/api/payments';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { omit } from 'lodash';

const LifetimeCode = (props) => {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const onCodeInputChange = (evt) => {
    setCode(toString(evt?.target?.value || ''));

    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const { profileLoaded, updateUserProps, isLoggedIn, user, history } = props;

  useEffect(() => {
    if (profileLoaded && (!isLoggedIn || !user) && history) {
      history.push('/');
    }
  }, [profileLoaded, isLoggedIn, history, user]);

  const { getAuthenticatedHeaders } = useAuth(props);

  const onConfirm = async () => {
    if (submitting) {
      return;
    }

    try {
      if (!code) {
        setErrorMessage('Invalid code');
      }

      setSubmitting(true);

      const headers = getAuthenticatedHeaders();
      const { success, updatedUser, errorMessage, networkError } =
        await verifyUserLifetimeCode(code, headers);
      const sanitizedErrorMessage = toLower(errorMessage);

      if (success) {
        setSuccess(true);

        if (history?.push) {
          history.push('/user');
        }

        if (isFunction(updateUserProps)) {
          updateUserProps({
            ...omit(updatedUser, [
              'auth',
              'userId',
              'id',
              'userProfileId',
              'email',
            ]),
          });
        }

        return;
      }

      if (networkError) {
        setErrorMessage('Check your network connection. Try again.');
      } else if (sanitizedErrorMessage) {
        setErrorMessage(sanitizedErrorMessage);
      } else {
        setErrorMessage('Invalid code');
      }
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={cx(styles.lifetimecode, styles.flex_row_xy)}>
      <div className={styles.lifetimecode_background}>
        <img
          src={k.CHAMU_PAGE_NOT_FOUND_BACKGROUND}
          alt={'not found page background'}
        />
      </div>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        <div
          className={styles.logo}
          onClick={() => {
            if (isFunction(props?.history?.push)) {
              props.history.push('/user');
            }
          }}
        >
          <LogoIconWord size="small" />{' '}
        </div>

        {success && (
          <div className={cx(styles.flex_row_xy, styles.success_redeem)}>
            <CheckSuccessIcon height={30} width={30} />
            <p>Successfully redeemed code!</p>
          </div>
        )}

        {!success && (
          <>
            <div className={styles.texts}>
              <h2>Redeem Lifetime License</h2>
            </div>
            <div className={cx(styles.flex_row_xy, styles.input)}>
              <TextInputField
                placeholder={'e.g. DEALXXXX'}
                label={''}
                onChange={onCodeInputChange}
                value={code}
                width="100%"
                validationMessage={
                  !isEmpty(errorMessage) ? errorMessage : false
                }
              />
            </div>
            <div className={cx(styles.flex_row_xy, styles.cta)}>
              <CustomButton
                onClick={onConfirm}
                label={'Submit'}
                variant={'primaryPurple'}
                className={styles.confirm}
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withRouter(LifetimeCode));
