import cx from 'classnames';
import styles from './DeleteNote.module.scss';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import { withNetworkSettings } from 'src/managers/network';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Button, Spinner } from 'evergreen-ui';
import { useMemo, useState } from 'react';
import { withModalPromptSettings } from 'src/managers/modal';
import { isFunction, toLower } from 'lodash';
import {
  deleteNoteDataRequest,
  unsubscribeNoteRequest,
} from 'src/managers/api/notes';
import { useAuth } from 'src/hooks';
import { useToast } from '@chakra-ui/react';
import { withNoteDataSettings } from 'src/managers/notes';

const DeleteNote = (props) => {
  const {
    isThemeDarkMode,
    isUserNetworkOffline,
    noteDataToRemove = null,
    closeModal,
    markDeletedNote,
  } = props;
  const [submitted, setSubmitted] = useState(false);

  const canDelete = useMemo(
    () => noteDataToRemove?.canDelete,
    [noteDataToRemove]
  );

  const canUnsubscribe = useMemo(
    () => noteDataToRemove?.canUnsubscribe,
    [noteDataToRemove]
  );

  const onCancel = () => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  };

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({ position: 'top' });

  const confirm = async () => {
    try {
      if (isUserNetworkOffline) {
        toast({
          title: 'Check your network connection.',
          duration: 3_500,
          isClosable: true,
        });

        if (submitted) {
          setSubmitted(false);
        }

        return;
      }

      setSubmitted(true);

      const { noteRefId } = noteDataToRemove;
      const headers = getAuthenticatedHeaders();
      const noteId = toLower(
        noteDataToRemove?.noteId || noteDataToRemove?.task_id
      );
      let res = null;

      if (canDelete) {
        res = await deleteNoteDataRequest(noteRefId, noteId, headers);
      } else if (canUnsubscribe) {
        res = await unsubscribeNoteRequest(noteRefId, headers);
      }

      if (res?.success) {
        toast({
          title: canUnsubscribe
            ? 'Successfully Removed!'
            : 'Successfully Deleted!',
          duration: 3_500,
          isClosable: true,
          status: 'success',
        });

        if (isFunction(markDeletedNote)) {
          markDeletedNote(noteId, noteRefId);
        }

        if (isFunction(closeModal)) {
          closeModal();
        }
      } else {
        toast({
          status: 'error',
          title: 'Something went wrong. Try again.',
          duration: 3_500,
          isClosable: true,
        });
        setSubmitted(false);
      }
    } catch (err) {
      setSubmitted(false);
    }
  };

  return (
    <div
      className={cx(styles.flex_row_xy, styles.delete_note, {
        [styles.delete_note_dark]: isThemeDarkMode,
      })}
    >
      <div className={styles.actual}>
        <h4>
          {canUnsubscribe || !canDelete
            ? 'This action cannot be undone. Confirm unsubscribe?'
            : i18n('user_edit_task_delete_msg')}
        </h4>
        <div
          className={cx(styles.spinner_wrap, {
            [styles.hide_element]: !submitted,
            [styles.spinner_wrap_dark]: isThemeDarkMode,
          })}
        >
          <div className={cx(styles.raw, styles.flex_row_xy)}>
            <Spinner size={24} />
          </div>
        </div>
        <div
          className={cx(styles.flex_row_x, styles.actions, {
            [styles.hide_element]: submitted,
          })}
        >
          <Button
            appearance="minimal"
            className={styles.cancel}
            onClick={onCancel}
          >
            <div className={cx(styles.flex_row_xy, styles.cancel_raw)}>
              <p>{i18n('user_edit_task_delete_cancel')}</p>
            </div>
          </Button>{' '}
          <CustomButton
            onClick={confirm}
            label={i18n('common_confirm')}
            className={styles.confirm}
            variant={'primaryDanger'}
          />
        </div>
      </div>
    </div>
  );
};

export default withNetworkSettings(
  withUserDataAndProfileSettings(
    withNoteDataSettings(withModalPromptSettings(DeleteNote))
  )
);
