const UndoIcon = (p) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M2.88531 4.66686L4.57598 6.35753L3.63331 7.3002L0.333313 4.0002L3.63331 0.700195L4.57598 1.64286L2.88531 3.33353H7.66665C9.08113 3.33353 10.4377 3.89543 11.4379 4.89563C12.4381 5.89582 13 7.25237 13 8.66686C13 10.0814 12.4381 11.4379 11.4379 12.4381C10.4377 13.4383 9.08113 14.0002 7.66665 14.0002H1.66665V12.6669H7.66665C8.72751 12.6669 9.74493 12.2454 10.4951 11.4953C11.2452 10.7451 11.6666 9.72773 11.6666 8.66686C11.6666 7.606 11.2452 6.58858 10.4951 5.83844C9.74493 5.08829 8.72751 4.66686 7.66665 4.66686H2.88531Z"
        fill="#718096"
      />
    </svg>
  );
};

export default UndoIcon;
