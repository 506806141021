import styles from './Comparison.module.scss';
import i18n from 'src/locales';
import { SmallTickIcon, HelpIcon } from 'evergreen-ui';

const Comparison = () => {
  return (
    <div className={styles.compare_plans}>
      <div className={styles.title}>
        <h1>{i18n('pricing_compare_plans_title')}</h1>
      </div>
      <div className={styles.content}>
        <ul>
          <li></li>
          <li>
            <h5>{'PERSONAL'}</h5>
          </li>
          <li>
            <h5>{'PREMIUM WITH SPACES'}</h5>
          </li>
          <li>
            <h5>{'ENTERPRISE'}</h5>
          </li>
        </ul>
        <div className={styles.label}>
          <h4>{'Usage'}</h4>
        </div>
        <ul className={styles.tasks}>
          <li>
            <h5>{'Notes'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
        </ul>{' '}
        <ul className={styles.tasks}>
          <li>
            <h5>{'Members'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>{'Just you'}</h5>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
        </ul>{' '}
        <ul className={styles.fileuploads}>
          <li>
            <h5>{'File uploads'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>{'50 MB'}</h5>
          </li>
          <li>
            <h5>{'2 GB'}</h5>
          </li>
          <li>
            <h5>{'10GB'}</h5>
          </li>
        </ul>
        <ul className={styles.subscribers}>
          <li>
            <h5>{'File uploads'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
        <div className={styles.label}>
          <h4>{'Collaboration'}</h4>
        </div>
        <ul className={styles.linksharing}>
          <li>
            <h5>{'Link sharing'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
        <ul className={styles.collabspaces}>
          <li>
            <h5>{'Collaborative spaces'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li></li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
        <div className={styles.label}>
          <h4>{'Features'}</h4>
        </div>
        <ul className={styles.webresponsive}>
          <li>
            <h5>{'Web and mobile responsive'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
        <ul className={styles.richeditor}>
          <li>
            <h5>{'Rich text editor'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
        <ul className={styles.tags}>
          <li>
            <h5>{'Tags'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
          <li>
            <h5>{'Unlimited'}</h5>
          </li>
        </ul>{' '}
        <ul className={styles.activitylog}>
          <li>
            <h5>{'Activity log'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li></li>
          <li>
            <h5>
              <SmallTickIcon />
            </h5>
          </li>
          <li>
            <h5>
              <SmallTickIcon />
            </h5>
          </li>
        </ul>
        <ul className={styles.api}>
          <li>
            <h5>{'API'}</h5>
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li></li>
          <li></li>
          <li>
            <h5>
              <SmallTickIcon />
            </h5>
          </li>
        </ul>
        <div className={styles.label}>
          <h4>{'Support'}</h4>
        </div>{' '}
        <ul className={styles.priority}>
          <li>
            <h5>{'Priority Support'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
          <li>
            <h5>
              {' '}
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
        <ul className={styles.priority}>
          <li>
            <h5>{'Custom contract & invoicing'}</h5>{' '}
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5> </h5>
          </li>
          <li>
            <h5> </h5>
          </li>
          <li>
            <h5>
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>{' '}
        <ul className={styles.priority}>
          <li>
            <h5>{'Early access new features'}</h5>
            <div className={styles.help_icon}>
              <HelpIcon />
            </div>
          </li>
          <li>
            <h5> </h5>
          </li>
          <li>
            <h5> </h5>
          </li>
          <li>
            <h5>
              <SmallTickIcon />{' '}
            </h5>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Comparison;
