import styles from './Activity.module.scss';
import k from 'src/constants/k';
import { Markdown } from 'src/locales';
import { getHHMMAMPMFormat } from 'src/helpers/utils';

const supportedCodes = [
  k.TASK_ACTIVITY_TYPES.CREATE.code,
  k.TASK_ACTIVITY_TYPES.UPDATE_TITLE.code,
  k.TASK_ACTIVITY_TYPES.UPDATE_DESCRIPTION.code,
  k.TASK_ACTIVITY_TYPES.UPDATE_ACTIVE_TO_INACTIVE.code,
  k.TASK_ACTIVITY_TYPES.UPDATE_INACTIVE_TO_ACTIVE.code,
  k.TASK_ACTIVITY_TYPES.UPDATE_PUBLIC_TO_PRIVATE.code,
  k.TASK_ACTIVITY_TYPES.UPDATE_PRIVATE_TO_PUBLIC.code,
];

const ActivityItem = (props) => {
  const { activity = null } = props || {};
  const {
    code,
    type,
    created,
    prev_title: previousTitle = '',
  } = activity || null;

  if (
    !created ||
    (typeof code === 'undefined' && typeof type === 'undefined')
  ) {
    return <></>;
  }
  const finalCode = typeof code !== 'undefined' ? code : type?.code;
  const createdDate = new Date(created * 1000);
  let mm = '';
  let yy = '';
  let dd = '';
  let hhmm = '';

  if (typeof finalCode === 'undefined' || !supportedCodes.includes(finalCode)) {
    return <></>;
  }

  if (createdDate && k.MONTHS_INDEX[createdDate.getMonth()]) {
    mm = k.MONTHS_INDEX[createdDate.getMonth()].name;
    yy = createdDate.getFullYear();
    dd = createdDate.getDate();
    hhmm = getHHMMAMPMFormat(createdDate.getHours(), createdDate.getMinutes());
  }

  return (
    <div className={styles.activity_item}>
      {finalCode === k.TASK_ACTIVITY_TYPES.CREATE.code && (
        <div className={styles.created_code}>
          {' '}
          {Markdown('task_view_activities_task_created', {
            date1: `${mm} ${dd}, ${yy}`,
          })}
        </div>
      )}{' '}
      {finalCode === k.TASK_ACTIVITY_TYPES.UPDATE_TITLE.code &&
        previousTitle && (
          <div className={styles.update_title}>
            {' '}
            {Markdown('task_view_activities_title_updated', {
              prev: `${previousTitle}`,
            })}
          </div>
        )}
      {finalCode === k.TASK_ACTIVITY_TYPES.UPDATE_DESCRIPTION.code && (
        <div className={styles.update_desc}>
          {Markdown('task_view_activities_description_updated', {
            date1: `${mm} ${dd}, ${yy}${hhmm ? ` ${hhmm}` : ''}`,
          })}
        </div>
      )}
      {finalCode === k.TASK_ACTIVITY_TYPES.UPDATE_ACTIVE_TO_INACTIVE.code && (
        <div className={styles.state_change}>
          {Markdown('task_view_activities_active_to_inactive', {
            state1: null,
            state2: null,
          })}
        </div>
      )}
      {finalCode === k.TASK_ACTIVITY_TYPES.UPDATE_INACTIVE_TO_ACTIVE.code && (
        <div className={styles.state_change}>
          {Markdown('task_view_activities_inactive_to_active', {
            state1: null,
            state2: null,
          })}
        </div>
      )}
      {finalCode === k.TASK_ACTIVITY_TYPES.UPDATE_PUBLIC_TO_PRIVATE.code && (
        <div className={styles.exp_change}>
          {Markdown('task_view_activities_public_to_private', {
            exp1: null,
            exp2: null,
          })}
        </div>
      )}
      {finalCode === k.TASK_ACTIVITY_TYPES.UPDATE_PRIVATE_TO_PUBLIC.code && (
        <div className={styles.exp_change}>
          {Markdown('task_view_activities_private_to_public', {
            exp1: null,
            exp2: null,
          })}
        </div>
      )}
    </div>
  );
};

export default ActivityItem;
