import cx from 'classnames';
import styles from './NetworkOffline.module.scss';
import { WarningIcon } from 'src/icons';

const NetworkOffline = () => {
  return (
    <div className={cx(styles.flex_row_xy, styles.network_offline)}>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        <WarningIcon height={24} width={24} />
        <p>No internet connection</p>
      </div>
    </div>
  );
};

export default NetworkOffline;
