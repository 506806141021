import ModalPromptManager from './managers/modal';
import MobileToastManager from './managers/mobile-toaster';
import NotesDataManager from './managers/notes';
import UserProfileManager from './managers/profile';
import SpacesAndUserManager from './managers/spaces';
import NetworkManager from './managers/network';
import StorageManager from './managers/storage';
import NoteViewContext from './pages/note-view/Context';
import Pages from './pages';
import './App.scss';
import { Worker } from '@react-pdf-viewer/core';
import { useWindowResize } from './hooks';
import { useEffect } from 'react';

const App = () => {
  const { availableHeight } = useWindowResize();

  useEffect(() => {
    const handleFontSize = () => {
      try {
        const { innerWidth } = window;
        const BASE_FONT_SIZE = 14.5;
        const BASE_WIDTH_FONT_SIZE = 1530;
        const r = document.getElementById('root');
        const cal = Math.floor(
          innerWidth * (BASE_FONT_SIZE / BASE_WIDTH_FONT_SIZE)
        );
        let fontSizeVal = `${
          cal > 16 ? (innerWidth > 1900 ? 15.7 : 15.5) : cal
        }px`;

        if (!r) {
          return;
        } else if (innerWidth <= 900) {
          fontSizeVal = '13.8px';
        } else if (innerWidth <= 1480) {
          fontSizeVal = '14.7px';
        } else if (innerWidth <= 1200) {
          fontSizeVal = '14.2px';
        } else {
          fontSizeVal = '15.3px';
        }

        if (r) {
          r.style.fontSize = fontSizeVal;
        }

        if (document && document.body) {
          document.body.style.fontSize = fontSizeVal;
        }
      } catch (err) {
        console.log(`handleFontSize err ${err?.message}`);
      }
    };

    handleFontSize();
  }, [availableHeight]);

  return (
    <StorageManager>
      <NetworkManager>
        <UserProfileManager>
          <SpacesAndUserManager>
            <Worker workerUrl="https://storage.googleapis.com/chamu-store-s1/files/app-static/pdf.worker.min.js">
              <NotesDataManager>
                <ModalPromptManager>
                  <MobileToastManager>
                    <NoteViewContext>
                      <Pages />
                    </NoteViewContext>
                  </MobileToastManager>
                </ModalPromptManager>
              </NotesDataManager>
            </Worker>
          </SpacesAndUserManager>
        </UserProfileManager>
      </NetworkManager>
    </StorageManager>
  );
};

export default App;
