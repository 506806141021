const SidePanelMenuExpandIcon = (p) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1217_22814)">
        <path
          d="M21 18V20H3V18H21ZM17.05 3.54999L22 8.49999L17.05 13.45V3.54999ZM12 11V13H3V11H12ZM12 3.99999V5.99999H3V3.99999H12Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1217_22814">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SidePanelMenuExpandIcon;
