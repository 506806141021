import { isNil, isObject, trim } from 'lodash';
import { getTaskApiPathname } from './urls';
import { axiosGetRequest, axiosPostRequest } from './axios';
import { isNetworkError } from '.';

export async function writeCommentInNoteRequest(
  noteRefId = '',
  delta = '',
  gif = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let success = false;

  // noteRefId: { type: 'string', maxLength: 50 },
  // commentId: { type: 'string', maxLength: 50 },
  // delta: { type: 'string', maxLength: 300_100 },
  // gif: { type: 'string', maxLength: 300 }

  try {
    let deltaSanitized = '';

    if (isObject(delta) && !isNil(delta)) {
      deltaSanitized = JSON.stringify(delta);
    } else {
      deltaSanitized = delta;
    }

    const url = getTaskApiPathname() + '/comment';
    const res = await axiosPostRequest(url, headers, {
      delta: deltaSanitized,
      gif: trim(gif),
      noteRefId: trim(noteRefId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    success,
  };
}

export async function updateCommentFromNoteRequest(
  commentId = '',
  noteRefId = '',
  delta = '',
  gif = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let success = false;
  let updatedProps = null;

  try {
    let deltaSanitized = '';

    if (isObject(delta) && !isNil(delta)) {
      deltaSanitized = JSON.stringify(delta);
    } else {
      deltaSanitized = delta;
    }

    const url = getTaskApiPathname() + '/update-comment';
    const res = await axiosPostRequest(url, headers, {
      commentId,
      delta: deltaSanitized,
      gif: trim(gif),
      noteRefId: trim(noteRefId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
      updatedProps = resData?.updatedProps;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    updatedProps,
    errorMessage,
    networkError,
    success,
  };
}

export async function getNoteCommentsListByPageRequest(
  pageNumber = 1,
  noteRefId = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let allowComments = false;
  let hasNext = false;
  let comments = [];

  try {
    const url =
      getTaskApiPathname() +
      `/list-comment/${pageNumber}?noteRefId=${trim(noteRefId)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && resData?.value) {
      comments = resData?.value;
      allowComments = resData?.allowComments;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { hasNext, errorMessage, networkError, allowComments, comments };
}
