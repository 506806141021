import cx from 'classnames';
import styles from './SavingInProgress.module.scss';
import { WarningIcon } from 'src/icons';
import { useWindowResize } from 'src/hooks';
import { useEffect, useRef } from 'react';

const SavingInProgress = () => {
  const domWrap = useRef(null);
  const { availableHeight } = useWindowResize();

  useEffect(() => {
    if (availableHeight && domWrap?.current) {
      domWrap.current.style.height = `${availableHeight}px`;
    }
  }, [availableHeight]);

  return (
    <div
      ref={domWrap}
      className={cx(styles.flex_row_xy, styles.saving_progress)}
    >
      <div className={cx(styles.flex_row_xy, styles.content)}>
        <div className={cx(styles.flex_row_xy, styles.icon)}>
          <WarningIcon height={20} width={20} />
        </div>
        <p>Saving is in progress...</p>
      </div>
    </div>
  );
};

export default SavingInProgress;
