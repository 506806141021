import { Node, mergeAttributes } from '@tiptap/core';
import { renderToStaticMarkup } from 'react-dom/server';
import k from 'src/constants/k';
import { Spinner } from 'evergreen-ui';
import { isNumber, isValidObject, toNumber, toString } from 'src/helpers/utils';
import { DownloadIcon, DeleteIcon } from 'src/icons';
import {
  isDocsOrWordFile,
  isExcelFile,
  isTextFile,
  isZipArchiveFile,
} from 'src/helpers/files';

const filePreviewImages = k.FILE_PREVIEW_IMAGES;

const FileExtension = Node.create({
  name: 'file',

  addOptions() {
    return {
      nested: false,
      width: '100%',
      HTMLAttributes: {
        file: null,
      },
    };
  },

  addAttributes() {
    return {
      type: {
        default: '',
        parseHTML: () => 'file',
        renderHTML: () => ({
          'data-type': 'file',
        }),
      },
      name: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-name') || '',
        renderHTML: (attributes) => ({
          'data-name': attributes.name,
        }),
      },
      refId: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-refId') || '',
        renderHTML: (attributes) => ({
          'data-refId': attributes.refId,
        }),
      },
      size: {
        default: 0,
        parseHTML: (element) =>
          toNumber(element?.getAttribute('data-size')) || 0,
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      url: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-url') || '',
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-type': 'file' }, HTMLAttributes),
      ['div', ['div', ['img']], ['div', ['p']]],
      ['div'],
    ];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  draggable: true,

  // add to 'block' group
  group: 'block',

  inline: false,

  // non editable
  atom: true,

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const divFile = document.createElement('div');
      const divFileInfos = document.createElement('div');
      const divFileIcon = document.createElement('div');
      const divFileActions = document.createElement('div');
      const divFileTexts = document.createElement('div');
      const imgIcon = document.createElement('img');
      const pFileName = document.createElement('p');
      const pFileSize = document.createElement('p');
      const url = HTMLAttributes ? HTMLAttributes['data-url'] : '';
      const toUpload = !HTMLAttributes || !HTMLAttributes['data-url'];
      const divLoading = document.createElement('div');
      const expandButton = document.createElement('button');
      const deleteButton = document.createElement('button');
      const fileName = HTMLAttributes['data-name'];
      const urlSanitized = toString(url).trim();
      const isText = isTextFile(fileName);
      const isPdfFile =
        (fileName.indexOf('.pdf') > -1 &&
          fileName.indexOf('.pdf') + '.pdf'.length >= fileName.length) ||
        (urlSanitized.indexOf('.pdf') > -1 &&
          urlSanitized.indexOf('.pdf') + '.pdf'.length >= urlSanitized.length);
      const isDocsOrWord = isDocsOrWordFile(fileName, url);
      const isZipArchive = isZipArchiveFile(fileName, url);
      const isExcel = isExcelFile(fileName, url);
      const fileIconSrc = isText
        ? filePreviewImages.text
        : isPdfFile
        ? filePreviewImages.pdf
        : isDocsOrWord
        ? filePreviewImages.word
        : isZipArchive
        ? filePreviewImages.zip
        : isExcel
        ? filePreviewImages.excel
        : filePreviewImages.general;

      expandButton.type = 'button';
      deleteButton.type = 'button';
      deleteButton.innerHTML = `${renderToStaticMarkup(
        <DeleteIcon height={20} width={20} />
      )}<div></div>`;
      expandButton.innerHTML = `${renderToStaticMarkup(
        <DownloadIcon height={20} width={20} />
      )}${url ? `<a href="${url}" target="_blank"></a>` : '<div></div>'}`;
      divLoading.innerHTML = renderToStaticMarkup(
        <Spinner height={20} width={20} />
      );
      divLoading.setAttribute('data-fileloading', 'fileloading');

      divFileTexts.append(pFileName);
      divFileTexts.append(pFileSize);

      divFileActions.append(expandButton);
      divFileActions.append(deleteButton);

      expandButton.addEventListener('click', (evt) => {});
      deleteButton.addEventListener('click', (evt) => {
        if (editor?.isEditable && node && divFile?.remove) {
          divFile.remove();
        }
      });
      deleteButton.setAttribute('data-filedelete', 'filedelete');

      divFileIcon.append(divLoading);
      divFileInfos.append(divFileIcon);
      divFileInfos.append(divFileTexts);
      divFile.append(divFileInfos);
      divFile.append(divFileActions);
      imgIcon.src = fileIconSrc;
      imgIcon.alt = 'File icon';

      if (isValidObject(HTMLAttributes)) {
        Object.entries(HTMLAttributes).forEach(([key, value]) => {
          divFile.setAttribute(key, value);
        });
        const size = HTMLAttributes['data-size'];
        const sizeCanBeKB = isNumber(size) && size > 100 && size < 100_000;
        const sizeCanBeMB = isNumber(size) && size > 100_000;

        pFileName.textContent = HTMLAttributes['data-name'];
        pFileSize.textContent = isNumber(size)
          ? sizeCanBeKB
            ? `${(size / 1000).toFixed(1)}KB`
            : sizeCanBeMB
            ? `${(size / 1_000_000).toFixed(2)}MB`
            : `${size} Bytes`
          : 0;
      }

      if (isValidObject(this.options.HTMLAttributes)) {
        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          divFile.setAttribute(key, value);
        });
      }

      if (!toUpload) {
        divLoading.remove();
        divFileIcon.append(imgIcon);
      } else {
        // @todo upload progress and upload request
        const timeoutId = setTimeout(() => {
          divLoading.remove();
          divFileIcon.append(imgIcon);

          clearTimeout(timeoutId);
        }, 5_000);
      }

      return {
        dom: divFile,
      };
    };
  },
});

export default FileExtension;
