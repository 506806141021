import cx from 'classnames';
import styles from './Auth.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { isMobileView } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { toString } from 'lodash';
import Login from 'src/components/login';
import SignUp from 'src/components/sign-up';
import ForgotPassword from 'src/components/forgot-password';
import { AppRootContainerId } from '..';

const AuthPage = (props) => {
  const { isLoggedIn, profileLoaded } = props;

  const pathname = useMemo(
    () => toString(window.location.pathname || ''),
    // eslint-disable-next-line
    [props]
  );

  const isLoginPage = useMemo(() => pathname?.startsWith('/login'), [pathname]);

  const isSignupPage = useMemo(
    () => pathname?.startsWith('/sign-up'),
    [pathname]
  );

  const isForgotPasswordPage = useMemo(
    () => pathname?.startsWith('/forgot-password'),
    [pathname]
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    const dom = document.getElementById(AppRootContainerId);

    if (dom) {
      dom.scrollTo(0, 0);
    }

    return () => {
      if (dom) {
        dom.scrollTo(0, 0);
      }
    };
  }, []);

  if (profileLoaded && isLoggedIn) {
    return <Redirect to={'/user'} />;
  }

  if (!isMobileView()) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className={cx(styles.flex_row_xy, styles.auth_page)}>
      <div className={styles.content}>
        {isLoginPage && <Login />}
        {isSignupPage && <SignUp />}
        {isForgotPasswordPage && <ForgotPassword />}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withRouter(AuthPage));
