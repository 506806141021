const CommentCtaIconDark = (p) => {
  return (
    <svg
      width="204"
      height="182"
      viewBox="0 0 204 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <circle cx="100.906" cy="92" r="90" fill="#191F26" />
      <rect
        x="1.5"
        y="84.1896"
        width="62.6158"
        height="38.6749"
        rx="8.5"
        fill="#222A34"
        stroke="#2E3745"
        strokeWidth="3"
      />
      <rect
        x="129.185"
        y="62.0222"
        width="72.3695"
        height="38.6749"
        rx="8.5"
        fill="#222A34"
        stroke="#2E3745"
        strokeWidth="3"
      />
      <rect
        x="153.192"
        y="71"
        width="42"
        height="6.2069"
        rx="3.10345"
        fill="#374151"
      />
      <rect
        x="9.61328"
        y="107.576"
        width="45"
        height="6.2069"
        rx="3.10345"
        fill="#374151"
      />
      <circle cx="13.6034" cy="97.3202" r="3.99015" fill="#374151" />
      <rect
        x="147.192"
        y="84.5"
        width="48"
        height="6.2069"
        rx="3.10345"
        fill="#374151"
      />
      <path
        d="M45.2217 69.6404C45.2217 59.3101 45.2217 54.1449 47.3156 50.1993C49.1575 46.7286 52.0966 43.9069 55.7115 42.1385C59.8212 40.1281 65.201 40.1281 75.9606 40.1281H129.754C140.513 40.1281 145.893 40.1281 150.003 42.1385C153.618 43.9069 156.557 46.7286 158.399 50.1993C160.493 54.1449 160.493 59.3101 160.493 69.6404V102.842C160.493 113.172 160.493 118.337 158.399 122.283C156.557 125.753 153.618 128.575 150.003 130.344C145.893 132.354 140.513 132.354 129.754 132.354H88.024C84.0276 132.354 82.0294 132.354 80.1181 132.731C78.4224 133.065 76.7816 133.617 75.2401 134.373C73.5026 135.226 71.9422 136.424 68.8216 138.821L53.5452 150.555C50.8807 152.601 49.5484 153.624 48.4271 153.626C47.452 153.627 46.5295 153.201 45.9212 152.469C45.2217 151.628 45.2217 149.99 45.2217 146.714V69.6404Z"
        fill="#5F7088"
        stroke="#2E3745"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.406 30.8C171.371 30.8 176.206 25.9647 176.206 20C176.206 14.0353 171.371 9.2 165.406 9.2C159.442 9.2 154.606 14.0353 154.606 20C154.606 25.9647 159.442 30.8 165.406 30.8Z"
        fill="#5F7088"
      />
      <path
        d="M161.806 30.1855V34.4C161.806 36.3882 163.418 38 165.406 38C167.395 38 169.006 36.3882 169.006 34.4V30.1855M165.406 2V3.8M149.206 20H147.406M153.706 8.3L152.626 7.21982M177.106 8.3L178.187 7.21982M183.406 20H181.606M176.206 20C176.206 25.9647 171.371 30.8 165.406 30.8C159.442 30.8 154.606 25.9647 154.606 20C154.606 14.0353 159.442 9.2 165.406 9.2C171.371 9.2 176.206 14.0353 176.206 20Z"
        stroke="#2E3745"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="71.3793" cy="84.0197" r="5.76355" fill="#56667D" />
      <circle
        cx="5.76355"
        cy="5.76355"
        r="5.76355"
        transform="matrix(-1 0 0 1 140.985 78.2562)"
        fill="#56667D"
      />
      <path
        d="M122.365 77.3694V77.3694C126.27 81.2747 132.646 81.2747 136.552 77.3694V77.3694"
        stroke="#2E3745"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.838 103.084C109.7 107.186 106.161 110.177 101.971 110.177C97.7802 110.177 94.2407 107.186 93.1035 103.084"
        stroke="#2E3745"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="77.143" cy="78.2562" r="5.3202" fill="#2E3745" />
    </svg>
  );
};

export default CommentCtaIconDark;
