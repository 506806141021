const RedoIcon = (p) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M10.1147 4.66686H5.33333C4.27247 4.66686 3.25505 5.08829 2.50491 5.83844C1.75476 6.58858 1.33333 7.606 1.33333 8.66686C1.33333 9.72773 1.75476 10.7451 2.50491 11.4953C3.25505 12.2454 4.27247 12.6669 5.33333 12.6669H11.3333V14.0002H5.33333C3.91885 14.0002 2.56229 13.4383 1.5621 12.4381C0.561903 11.4379 0 10.0814 0 8.66686C0 7.25237 0.561903 5.89582 1.5621 4.89563C2.56229 3.89543 3.91885 3.33353 5.33333 3.33353H10.1147L8.424 1.64286L9.36667 0.700195L12.6667 4.0002L9.36667 7.3002L8.424 6.35753L10.1147 4.66686Z"
        fill="#718096"
      />
    </svg>
  );
};

export default RedoIcon;
