import styles from './Payment.module.scss';
import PaymentSuccess from './success';
import PaymentFailed from './failed';
import NotFound from '../not-found';
import { Switch, Route } from 'react-router-dom';

const Payment = () => {
  return (
    <div className={styles.payment}>
      <Switch>
        <Route path={'/payment/success'}>
          <PaymentSuccess />
        </Route>
        <Route path={'/payment/failed'}>
          <PaymentFailed />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default Payment;
