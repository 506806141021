import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Create.module.scss';

const Create = () => {
  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <h2>{'Create a note'}</h2>
        <br />
        <img alt={'Define your space'} src={'/docs-images/create-img.png'} />
        <br />
        <br />
        <ol>
          <li>
            On the upper right corner of your screen, click on the create
            button.
          </li>
          <br />
          <li>On the new note (in Edit mode) screen, add a title.</li>
          <br />
          <li>
            Add your content using the wide variety of formatting from our
            editor.
          </li>
          <br />
          <li>Click on the Edit switch. Doing so would do the following:</li>
          <br />
          <li>Save your changes.</li>
          <br />
          <li>Switch the note to View mode.</li>
          <br />
        </ol>
      </div>
    </div>
  );
};

export default Create;
