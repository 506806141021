import cx from 'classnames';
import SidebarLayout from 'src/layout/docs/Sidebar';
import styles from './Sidebar.module.scss';
import { Link } from 'react-router-dom';
import {
  HomeIcon,
  UserSmileIcon,
  ShieldKeyholeIcon,
  FilePaperIcon,
} from 'src/icons';
import { isCapacitorMobileApp, isMobileView } from 'src/helpers/utils';
import { withModalPromptSettings } from 'src/managers/modal';
import { isFunction } from 'lodash';

const Sidebar = (props) => {
  const { closeModal } = props;
  const termsStyles = cx(
    styles.bottom_item,
    styles.sidebar_item,
    styles.sidebar_item_documentation
  );
  const shareYourNotesStyles = cx(
    styles.sidebar_item,
    styles.bottom_item,
    styles.sidebar_item_getting_started
  );
  const gettingStarteditemStyles = cx(
    styles.sidebar_item,
    styles.sidebar_item_getting_started
  );
  const documentationAreaItemStyles = cx(styles.sidebar_item);
  const iconHeaderWrapperStyles = cx(
    styles.icon_header_wrapper,
    styles.icon_header_wrapper_documentation
  );
  const closeNav = () => {
    if (isMobileView() && isFunction(closeModal)) {
      closeModal();
    }
  };

  return (
    <SidebarLayout
      className={cx(styles.sidebar, {
        [styles.sidebar_remove_borders]:
          isMobileView() || isCapacitorMobileApp(),
      })}
    >
      <h4 className={styles.sidebar_title}> DOCUMENTATION </h4>
      <Link
        className={documentationAreaItemStyles}
        to={'/documentation'}
        onClick={closeNav}
      >
        <div className={iconHeaderWrapperStyles}>
          <HomeIcon height={20} width={20} className={styles.icon} />
          <h4>Overview</h4>
        </div>
      </Link>
      <Link
        className={documentationAreaItemStyles}
        to={'/documentation/about'}
        onClick={closeNav}
      >
        <div className={iconHeaderWrapperStyles}>
          <UserSmileIcon className={styles.icon} />
          <h4>About Us</h4>
        </div>
      </Link>
      <Link
        className={documentationAreaItemStyles}
        to={'/documentation/privacy'}
        onClick={closeNav}
      >
        <div className={iconHeaderWrapperStyles}>
          <ShieldKeyholeIcon className={styles.icon} />
          <h4>Privacy Policy</h4>
        </div>
      </Link>
      <Link
        className={termsStyles}
        to={'/documentation/terms'}
        onClick={closeNav}
      >
        <div className={iconHeaderWrapperStyles}>
          <FilePaperIcon className={styles.icon} />
          <h4>Terms of Service</h4>
        </div>
      </Link>
      <h4 className={styles.sidebar_title}> GETTING STARTED</h4>
      <Link
        className={gettingStarteditemStyles}
        to={'/documentation/intro'}
        onClick={closeNav}
      >
        <h4>Introduction</h4>
      </Link>
      <Link
        className={gettingStarteditemStyles}
        to={'/documentation/define'}
        onClick={closeNav}
      >
        <h4>Define your space</h4>
      </Link>
      <Link
        className={gettingStarteditemStyles}
        to={'/documentation/create'}
        onClick={closeNav}
      >
        <h4>Create your first note</h4>
      </Link>
      <Link
        className={gettingStarteditemStyles}
        to={'/documentation/organize'}
        onClick={closeNav}
      >
        <h4>Organize using tags</h4>
      </Link>
      <Link
        className={shareYourNotesStyles}
        to={'/documentation/share-notes'}
        onClick={closeNav}
      >
        <h4>Share your notes</h4>
      </Link>
      <h4 className={styles.sidebar_title}> API</h4>
      <Link
        className={gettingStarteditemStyles}
        to={'/documentation/api-docs'}
        onClick={closeNav}
      >
        <h4>chamu API</h4>
      </Link>
    </SidebarLayout>
  );
};

export default withModalPromptSettings(Sidebar);
