const ListCheckIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M6.66667 3.33398H17.5V5.00065H6.66667V3.33398ZM6.66667 9.16732H17.5V10.834H6.66667V9.16732ZM6.66667 15.0007H17.5V16.6673H6.66667V15.0007Z"
        fill="#718096"
      />
      <path
        d="M3.43748 16.2746L4.79156 15L5 15.196L3.43748 16.6667L2.5 15.7843L2.7083 15.5883L3.43748 16.2746Z"
        fill="#718096"
        stroke="#718096"
        strokeWidth="0.833333"
      />
      <path
        d="M3.43748 10.4406L4.79156 9.16602L5 9.36206L3.43748 10.8327L2.5 9.95034L2.7083 9.75429L3.43748 10.4406Z"
        fill="#718096"
        stroke="#718096"
        strokeWidth="0.833333"
      />
      <path
        d="M3.43748 4.60856L4.79156 3.33398L5 3.53003L3.43748 5.00065L2.5 4.11831L2.7083 3.92226L3.43748 4.60856Z"
        fill="#718096"
        stroke="#718096"
        strokeWidth="0.833333"
      />
    </svg>
  );
};

export default ListCheckIcon;
