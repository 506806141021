const MoreDotIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_725_15556)">
        <path
          d="M4.28568 8.85693C3.65711 8.85693 3.14282 9.37122 3.14282 9.99979C3.14282 10.6284 3.65711 11.1426 4.28568 11.1426C4.91425 11.1426 5.42854 10.6284 5.42854 9.99979C5.42854 9.37122 4.91425 8.85693 4.28568 8.85693ZM15.7143 8.85693C15.0857 8.85693 14.5714 9.37122 14.5714 9.99979C14.5714 10.6284 15.0857 11.1426 15.7143 11.1426C16.3428 11.1426 16.8571 10.6284 16.8571 9.99979C16.8571 9.37122 16.3428 8.85693 15.7143 8.85693ZM9.99997 8.85693C9.37139 8.85693 8.85711 9.37122 8.85711 9.99979C8.85711 10.6284 9.37139 11.1426 9.99997 11.1426C10.6285 11.1426 11.1428 10.6284 11.1428 9.99979C11.1428 9.37122 10.6285 8.85693 9.99997 8.85693Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_725_15556">
          <rect
            width="18.2857"
            height="18.2857"
            fill="white"
            transform="translate(0.857117 0.856934)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoreDotIcon;
