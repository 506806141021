import styles from './Modal.module.scss';
import cx from 'classnames';
import PdfViewer from './PdfViewer';
import SavedChanges from './modals/SavedChanges';
import Properties from './modals/Properties';
import Tags from './modals/Tags';
import Subscribers from './modals/Subscribers';
import DeleteNote from './modals/Delete';
import Activities from './modals/Activities';
import Comments from './Comments';
import { useWindowResize } from 'src/hooks';
import { NoteViewModalType } from 'src/types';
import { withNoteViewContext } from './Context';
import { useEffect, useMemo, useRef } from 'react';
import { isMobileView } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const Modal = (props) => {
  const modalWrapRef = useRef();
  const { showModal: type, isThemeDarkMode } = props;
  const { availableHeight } = useWindowResize();

  useEffect(() => {
    const dom = modalWrapRef?.current;

    if (dom) {
      dom.style.height = `${availableHeight}px`;
    }

    return () => {
      if (dom?.style?.height) {
        dom.style.height = '';
      }
    };
  }, [type, availableHeight]);

  const withBackground = useMemo(
    () =>
      type !== NoteViewModalType.savedChanges &&
      type !== NoteViewModalType.savingInProgress &&
      type !== NoteViewModalType.readOnly,
    [type]
  );

  return (
    <div
      ref={modalWrapRef}
      className={cx(styles.note_view_modal, {
        [styles.note_view_modal_with_background]: withBackground,
        [styles.note_view_modal_with_background_dark]:
          withBackground && isThemeDarkMode,
      })}
    >
      {type === NoteViewModalType.pdfViewer ? <PdfViewer /> : <></>}
      {type === NoteViewModalType.savedChanges ? <SavedChanges /> : <></>}
      {type === NoteViewModalType.properties ? <Properties /> : <></>}
      {type === NoteViewModalType.tags ? <Tags /> : <></>}
      {type === NoteViewModalType.subscribers ? <Subscribers /> : <></>}
      {type === NoteViewModalType.delete ? <DeleteNote /> : <></>}
      {type === NoteViewModalType.activities ? <Activities /> : <></>}
      {type === NoteViewModalType.comments && isMobileView() ? (
        <Comments />
      ) : (
        <></>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Modal));
