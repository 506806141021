const BugReportIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_309_8082)">
        <path
          d="M8.66671 13.2667C9.4195 13.1128 10.096 12.7036 10.5819 12.1084C11.0678 11.5132 11.3333 10.7684 11.3334 10V8.00004C11.3343 7.54125 11.2401 7.08726 11.0567 6.66671H4.94337C4.76002 7.08726 4.66581 7.54125 4.66671 8.00004V10C4.6668 10.7684 4.93224 11.5132 5.41814 12.1084C5.90404 12.7036 6.58058 13.1128 7.33337 13.2667V9.33337H8.66671V13.2667ZM3.69071 11.7934C3.45414 11.2251 3.33269 10.6156 3.33337 10H1.33337V8.66671H3.33337V8.00004C3.33337 7.57137 3.39137 7.15671 3.49937 6.76271L2.02404 5.91071L2.69071 4.75604L4.03737 5.53337C4.07988 5.46558 4.12411 5.39889 4.17004 5.33337H11.83C11.876 5.39871 11.92 5.46604 11.9627 5.53337L13.3094 4.75604L13.976 5.91071L12.5007 6.76271C12.6087 7.15604 12.6667 7.57137 12.6667 8.00004V8.66671H14.6667V10H12.6667C12.6667 10.6354 12.54 11.2414 12.3094 11.7934L13.976 12.756L13.3094 13.9107L11.6254 12.9387C11.1883 13.479 10.6358 13.9146 10.0085 14.2136C9.38123 14.5126 8.69496 14.6674 8.00004 14.6667C7.30512 14.6674 6.61885 14.5126 5.99155 14.2136C5.36424 13.9146 4.81179 13.479 4.37471 12.9387L2.69071 13.9107L2.02404 12.756L3.69071 11.7934ZM5.33337 4.00004C5.33337 3.2928 5.61433 2.61452 6.11442 2.11442C6.61452 1.61433 7.2928 1.33337 8.00004 1.33337C8.70728 1.33337 9.38556 1.61433 9.88566 2.11442C10.3858 2.61452 10.6667 3.2928 10.6667 4.00004H5.33337Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_8082">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BugReportIcon;
