import cx from 'classnames';
import styles from './ViewAndEditSwitch.module.scss';
import { withNoteViewContext } from './Context';
import { NoteViewIcon, NoteEditIcon } from 'src/icons';
import { timeout } from 'src/helpers/utils';
import { isFunction } from 'lodash';
import { withNoteDataSettings } from 'src/managers/notes';

const ViewAndEditSwitch = (props) => {
  const {
    readOnly,
    viewOnly,
    toggleMode,
    closeModal,
    fetchingAdvancedOptions,
    advancedOptions,
    isOwner,
    setShowReadOnly,
    targetNote,
  } = props;
  const isEditMode = props?.isEditMode && !readOnly && !viewOnly;

  const onToggle = async () => {
    // @todo prompt ask for write access
    if ((fetchingAdvancedOptions && !isOwner) || targetNote?.revalidating) {
      return;
    }

    if (readOnly || viewOnly || (!isOwner && !advancedOptions?.allowSubsEdit)) {
      if (readOnly && isFunction(setShowReadOnly)) {
        if (isFunction(closeModal)) {
          closeModal();
        }

        setShowReadOnly(true);
        await timeout(2000);
        setShowReadOnly(false);
      }

      return;
    }

    if (isFunction(toggleMode)) {
      toggleMode();
    }
  };

  return (
    <div className={cx(styles.flex_row_xy, styles.view_edit_switch)}>
      <div
        className={cx(styles.flex_row_xy, styles.track, {
          [styles.track_edit]: isEditMode,
          [styles.track_view]: !isEditMode,
        })}
        onClick={onToggle}
      >
        <div
          className={cx(styles.circle, styles.flex_row_xy, {
            [styles.circle_edit]: isEditMode,
            [styles.circle_view]: !isEditMode,
          })}
        >
          {isEditMode ? <NoteEditIcon /> : <NoteViewIcon />}
        </div>
        <div
          className={cx(styles.flex_row_xy, styles.text, {
            [styles.text_edit]: isEditMode,
            [styles.text_view]: !isEditMode,
          })}
        >
          <p>{isEditMode ? 'Edit' : 'View'}</p>
        </div>
      </div>
    </div>
  );
};

export default withNoteDataSettings(withNoteViewContext(ViewAndEditSwitch));
