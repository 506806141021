const TableIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M10.8333 8.33333V11.6667H15.8333V8.33333H10.8333ZM9.16667 8.33333H4.16667V11.6667H9.16667V8.33333ZM10.8333 15.8333H15.8333V13.3333H10.8333V15.8333ZM9.16667 15.8333V13.3333H4.16667V15.8333H9.16667ZM10.8333 4.16667V6.66667H15.8333V4.16667H10.8333ZM9.16667 4.16667H4.16667V6.66667H9.16667V4.16667ZM3.33333 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5Z"
        fill="#718096"
      />
    </svg>
  );
};

export default TableIcon;
