import cx from 'classnames';
import styles from './InitUser.module.scss';
import LogoIcon from '../logo-icon';
import { Spinner } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const InitUserLoading = (props) => {
  const { isThemeDarkMode } = props;

  return (
    <div
      className={cx(styles.flex_column_xy, styles.init_user_wrap, {
        [styles.init_user_wrap_dark]: isThemeDarkMode,
      })}
    >
      <div className={styles.logo}>
        <LogoIcon darkMode={isThemeDarkMode} className={styles.logo_raw} />{' '}
      </div>
      <div
        className={cx(styles.spinner, styles.flex_row_xy, {
          [styles.spinner_dark]: isThemeDarkMode,
        })}
      >
        <Spinner height={25} width={25} />{' '}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(InitUserLoading);
