import cx from 'classnames';
import styles from './LogoIcon.module.scss';
import { CHAMU_ICON_BLACK, CHAMU_ICON_WHITE } from 'src/constants/icons';
import { isString, isNumber } from 'src/helpers/utils';

const LogoIcon = (props) => {
  const height = isNumber(props?.height) ? `${props?.height}px` : '';
  const width = isNumber(props?.width) ? `${props?.width}px` : '';

  return (
    <div
      className={cx(styles.logo_icon, styles.flex_row_xy, {
        [props?.className]: isString(props?.className) && props?.className,
      })}
      style={{ ...(height && { height }), ...(width && { width }) }}
    >
      <img
        className={styles.flex_row_xy}
        src={props?.darkMode ? CHAMU_ICON_WHITE : CHAMU_ICON_BLACK}
        alt={'Logo icon'}
      />
    </div>
  );
};

export default LogoIcon;
