const ListOrderedIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M6.66667 3.33333H17.5V5H6.66667V3.33333ZM4.16667 2.5V5H5V5.83333H2.5V5H3.33333V3.33333H2.5V2.5H4.16667ZM2.5 11.6667V9.58333H4.16667V9.16667H2.5V8.33333H5V10.4167H3.33333V10.8333H5V11.6667H2.5ZM4.16667 16.25H2.5V15.4167H4.16667V15H2.5V14.1667H5V17.5H2.5V16.6667H4.16667V16.25ZM6.66667 9.16667H17.5V10.8333H6.66667V9.16667ZM6.66667 15H17.5V16.6667H6.66667V15Z"
        fill="#718096"
      />
    </svg>
  );
};

export default ListOrderedIcon;
