const StrikeThroughIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7H8.65C8.27 6.91 7.92 6.82 7.61 6.74C7.3 6.66 7.12 6.61 7.07 6.6C6.64 6.49 6.28 6.31 6.02 6.08C5.75 5.85 5.62 5.53 5.62 5.13C5.62 4.84 5.69 4.6 5.83 4.41C5.97 4.22 6.15 4.07 6.37 3.95C6.59 3.84 6.83 3.76 7.09 3.71C7.35 3.66 7.61 3.64 7.86 3.64C8.6 3.64 9.22 3.79 9.7 4.1C10.02 4.3 10.25 4.6 10.38 5H12.6C12.54 4.67 12.43 4.36 12.28 4.08C12.03 3.63 11.69 3.24 11.26 2.93C10.83 2.62 10.33 2.39 9.77 2.23C9.21 2.07 8.59 2 7.95 2C7.4 2 6.85 2.07 6.32 2.2C5.78 2.33 5.3 2.54 4.87 2.82C4.45 3.1 4.11 3.45 3.85 3.87C3.59 4.29 3.46 4.79 3.46 5.37C3.46 5.67 3.5 5.96 3.59 6.25C3.67 6.51 3.8 6.76 3.98 7H2C1.45 7 1 7.45 1 8C1 8.55 1.45 9 2 9H9.13C9.38 9.07 9.62 9.14 9.84 9.22C10.09 9.31 10.32 9.45 10.54 9.66C10.75 9.87 10.86 10.19 10.86 10.63C10.86 10.84 10.81 11.06 10.72 11.26C10.63 11.47 10.48 11.65 10.27 11.81C10.06 11.97 9.79 12.1 9.46 12.2C9.13 12.3 8.73 12.35 8.26 12.35C7.82 12.35 7.42 12.3 7.05 12.21C6.68 12.12 6.35 11.97 6.06 11.78C5.77 11.58 5.55 11.33 5.39 11.02C5.38 11.02 5.38 11.01 5.37 11H3.14C3.21 11.27 3.31 11.52 3.43 11.75C3.7 12.27 4.07 12.69 4.53 13.03C4.99 13.37 5.53 13.61 6.15 13.77C6.76 13.92 7.42 14 8.12 14C8.73 14 9.32 13.93 9.91 13.8C10.49 13.67 11.02 13.46 11.47 13.17C11.93 12.88 12.3 12.51 12.58 12.06C12.86 11.61 13 11.06 13 10.42C13 10.12 12.95 9.82 12.85 9.52C12.8 9.33 12.72 9.16 12.63 9H14C14.55 9 15 8.55 15 8C15 7.45 14.55 7 14 7Z"
        fill="#718096"
      />
    </svg>
  );
};

export default StrikeThroughIcon;
