import cx from 'classnames';
import styles from './RemoveTag.module.scss';
import CustomButton from 'src/components/button';
import i18n, { Markdown } from 'src/locales';
import { useMemo, useState } from 'react';
import { Button, Spinner } from 'evergreen-ui';
import { withModalPromptSettings } from 'src/managers/modal';
import { isFunction } from 'lodash';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import {
  removePersonalTagRequest,
  removeSpaceTagRequest,
} from 'src/managers/api/tags';
import { useAuth } from 'src/hooks';
import { useToast } from '@chakra-ui/react';

const RemoveTag = (props) => {
  const {
    tagInfo,
    tagSpaceId,
    isThemeDarkMode,
    closeModal,
    removeSpaceTag,
    removePersonalTag,
    blockClosingOfModal,
    unBlockClosingOfModal,
  } = props;
  const [submitted, setSubmitted] = useState(false);

  const tagName = useMemo(() => tagInfo?.name || tagInfo?.tag || '', [tagInfo]);

  const cancel = () => {
    if (isFunction(closeModal)) {
      closeModal(true);
    }
  };

  const toast = useToast({ id: 'chakraToast', position: 'top' });

  const { getAuthenticatedHeaders } = useAuth(props);

  const confirm = async () => {
    try {
      if (submitted) {
        return;
      }

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      const headers = getAuthenticatedHeaders();

      setSubmitted(true);

      if (tagSpaceId && tagSpaceId !== 'personal') {
        // remove space tag

        const { success, networkError, errorMessage } =
          await removeSpaceTagRequest(tagInfo?.id, tagSpaceId, headers);

        if (success) {
          if (isFunction(removeSpaceTag)) {
            removeSpaceTag(tagInfo?.id, tagSpaceId);
          }

          toast({
            status: 'success',
            title: 'Successfully Deleted!',
            duration: 1_500,
            isClosable: true,
          });
        }

        if (networkError) {
          toast({
            status: 'error',
            title: 'Check your network connection.',
            duration: 1_500,
            isClosable: true,
          });
        }

        if (errorMessage) {
          toast({
            status: 'error',
            title: 'Something went wrong. Try again.',
            duration: 1_500,
            isClosable: true,
          });
        }

        if (isFunction(closeModal)) {
          closeModal(true);
        }
      } else {
        // remove personal tag
        const { success, networkError, errorMessage } =
          await removePersonalTagRequest(tagInfo?.id, headers);

        if (success) {
          if (isFunction(removePersonalTag)) {
            removePersonalTag(tagInfo?.id);
          }

          toast({
            status: 'success',
            title: 'Successfully Deleted!',
            duration: 1_500,
            isClosable: true,
          });
        }

        if (networkError) {
          toast({
            status: 'error',
            title: 'Check your network connection.',
            duration: 1_500,
            isClosable: true,
          });
        }

        if (errorMessage) {
          toast({
            status: 'error',
            title: 'Something went wrong. Try again.',
            duration: 1_500,
            isClosable: true,
          });
        }

        if (isFunction(closeModal)) {
          closeModal(true);
        }
      }
    } catch {
    } finally {
      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  };

  return (
    <div
      className={cx(styles.remove_tag, {
        [styles.remove_tag_dark]: isThemeDarkMode,
      })}
    >
      <div>
        {Markdown('user_tags_list_remove_confirm_title', {
          tag1: tagName,
        })}

        {submitted && (
          <div
            className={cx(styles.flex_row_xy, styles.loading, {
              [styles.loading_dark]: isThemeDarkMode,
            })}
          >
            <Spinner height={20} width={20} />
          </div>
        )}

        {!submitted && (
          <div className={cx(styles.flex_row_xy, styles.actions)}>
            <Button
              appearance="minimal"
              className={cx(styles.cancel, {
                [styles.cancel_dark]: isThemeDarkMode,
              })}
              onClick={cancel}
            >
              <h4>{i18n('user_tags_list_remove_cancel')}</h4>
            </Button>
            <CustomButton
              isThemeDarkMode={isThemeDarkMode}
              label={i18n('user_tags_list_remove_yes')}
              className={styles.confirm}
              onClick={confirm}
              variant={'primaryDanger'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(RemoveTag))
);
