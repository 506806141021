const SubscriberSettingsIcon = (p) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_33772)">
        <path
          d="M9 10.5V12C7.80653 12 6.66193 12.4741 5.81802 13.318C4.97411 14.1619 4.5 15.3065 4.5 16.5H3C3 14.9087 3.63214 13.3826 4.75736 12.2574C5.88258 11.1321 7.4087 10.5 9 10.5V10.5ZM9 9.75C6.51375 9.75 4.5 7.73625 4.5 5.25C4.5 2.76375 6.51375 0.75 9 0.75C11.4863 0.75 13.5 2.76375 13.5 5.25C13.5 7.73625 11.4863 9.75 9 9.75ZM9 8.25C10.6575 8.25 12 6.9075 12 5.25C12 3.5925 10.6575 2.25 9 2.25C7.3425 2.25 6 3.5925 6 5.25C6 6.9075 7.3425 8.25 9 8.25ZM10.9463 14.109C10.8512 13.7088 10.8512 13.2919 10.9463 12.8917L10.2022 12.462L10.9522 11.163L11.6963 11.5927C11.9949 11.3099 12.3558 11.1013 12.75 10.9838V10.125H14.25V10.9838C14.649 11.1023 15.009 11.3137 15.3038 11.5927L16.0477 11.163L16.7977 12.462L16.0538 12.8917C16.1487 13.2917 16.1487 13.7083 16.0538 14.1083L16.7977 14.538L16.0477 15.837L15.3038 15.4072C15.0051 15.6901 14.6442 15.8987 14.25 16.0162V16.875H12.75V16.0162C12.3558 15.8987 11.9949 15.6901 11.6963 15.4072L10.9522 15.837L10.2022 14.538L10.9463 14.109V14.109ZM13.5 14.625C13.7984 14.625 14.0845 14.5065 14.2955 14.2955C14.5065 14.0845 14.625 13.7984 14.625 13.5C14.625 13.2016 14.5065 12.9155 14.2955 12.7045C14.0845 12.4935 13.7984 12.375 13.5 12.375C13.2016 12.375 12.9155 12.4935 12.7045 12.7045C12.4935 12.9155 12.375 13.2016 12.375 13.5C12.375 13.7984 12.4935 14.0845 12.7045 14.2955C12.9155 14.5065 13.2016 14.625 13.5 14.625Z"
          fill="#2D3748"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_33772">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubscriberSettingsIcon;
