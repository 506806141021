import k from 'src/constants/k';
import { isArray, isEmpty } from 'lodash';
import i18n from 'src/locales';

export function isMemberOnly(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return (
    isArray(role) &&
    !role.includes(k.SPACES_ROLES_ENUM.creator) &&
    !role.includes(k.SPACES_ROLES_ENUM.admin) &&
    role.includes(k.SPACES_ROLES_ENUM.member)
  );
}

export function isMemberAdmin(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return (
    isArray(role) &&
    !role.includes(k.SPACES_ROLES_ENUM.creator) &&
    role.includes(k.SPACES_ROLES_ENUM.admin)
  );
}

export function isUserCreator(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return role && isArray(role) && role.includes(k.SPACES_ROLES_ENUM.creator);
}

export function isAllowedToEditSpaceInfo(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return Boolean(
    isArray(role) &&
      (role.includes(k.SPACES_ROLES_ENUM.editSpace) ||
        role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin))
  );
}

export function isAllowedToRemoveMembers(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return Boolean(
    role &&
      isArray(role) &&
      (role.includes(k.SPACES_ROLES_ENUM.removeMembers) ||
        role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin))
  );
}

export function isAllowedToAddMembers(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return Boolean(
    isArray(role) &&
      (role.includes(k.SPACES_ROLES_ENUM.addMembers) ||
        role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin))
  );
}

export function isAllowedToCreateTags(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return Boolean(
    isArray(role) &&
      (role.includes(k.SPACES_ROLES_ENUM.createTag) ||
        role.includes(k.SPACES_ROLES_ENUM.editTag) ||
        role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin))
  );
}

export function isAllowedToEditTags(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return Boolean(
    isArray(role) &&
      (role.includes(k.SPACES_ROLES_ENUM.editTag) ||
        role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin))
  );
}

export function isAllowedToAssignRoles(memberInfo = {}) {
  const role = memberInfo?.role || memberInfo?.roles || [];

  return Boolean(
    isArray(role) &&
      (role.includes(k.SPACES_ROLES_ENUM.creator) ||
        role.includes(k.SPACES_ROLES_ENUM.admin) ||
        role.includes(k.SPACES_ROLES_ENUM.assignRoles) ||
        role.includes(k.SPACES_ROLES_ENUM.createRoles))
  );
}

export function getSpaceRoleNameFormat(role = []) {
  if (isArray(role) && !isEmpty(role)) {
    if (role.includes(k.SPACES_ROLES_ENUM.creator)) {
      return i18n('space_role_creator');
    } else if (role.includes(k.SPACES_ROLES_ENUM.admin)) {
      return i18n('space_role_admin');
    } else if (role.includes(k.SPACES_ROLES_ENUM.member)) {
      return i18n('space_role_member');
    } else if (role.includes(k.SPACES_ROLES_ENUM.viewOnly)) {
      return i18n('space_role_view_only');
    }
  }

  if (!role) {
    return i18n('space_role_member');
  }

  return '';
}
