import { Node, mergeAttributes } from '@tiptap/core';
import { renderToStaticMarkup } from 'react-dom/server';
import { isNumber, isValidObject, toNumber } from 'src/helpers/utils';
import { Spinner } from 'evergreen-ui';
import { ExpandIcon, DeleteIcon } from 'src/icons';

export const PDFViewerExtensionClass = 'PDFViewerExtensionClass';

const PDFViewerExtension = Node.create({
  name: 'pdfviewer',

  addOptions() {
    return {
      nested: false,
      draggable: false,
      width: '100%',
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      type: {
        default: '',
        parseHTML: () => 'pdfviewer',
        renderHTML: () => ({
          'data-type': 'pdfviewer',
        }),
      },
      name: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-name') || '',
        renderHTML: (attributes) => ({
          'data-name': attributes.name,
        }),
      },
      refId: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-refId') || '',
        renderHTML: (attributes) => ({
          'data-refId': attributes.refId,
        }),
      },
      size: {
        default: 0,
        parseHTML: (element) =>
          toNumber(element?.getAttribute('data-size')) || 0,
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      url: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-url') || '',
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-type': 'pdfviewer' }, HTMLAttributes),
      ['div', ['p']],
      ['div'],
    ];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="pdfviewer"]`,
      },
    ];
  },

  draggable: true,

  group: 'block',

  inline: false,

  atom: true,

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const divPdfViewer = document.createElement('div');
      const divTexts = document.createElement('div');
      const divActions = document.createElement('div');
      const aTexts = document.createElement('a');
      const expandButton = document.createElement('button');
      const deleteButton = document.createElement('button');
      const divLoading = document.createElement('div');
      const size = HTMLAttributes['data-size'];
      const name = HTMLAttributes['data-name'];
      const url = HTMLAttributes['data-url'];

      divLoading.innerHTML = renderToStaticMarkup(
        <Spinner height={20} width={20} />
      );

      expandButton.type = 'button';
      deleteButton.type = 'button';

      const onRemovePdfFile = (evt) => {
        if (editor?.isEditable && node && divPdfViewer?.remove) {
          divPdfViewer.remove();
        }
      };
      deleteButton.setAttribute('data-filedelete', 'filedelete');
      deleteButton.addEventListener('click', onRemovePdfFile);

      expandButton.innerHTML = `${renderToStaticMarkup(
        <ExpandIcon height={20} width={20} />
      )}<div></div>`;
      expandButton.children[1].classList.add(PDFViewerExtensionClass);
      expandButton.children[1].setAttribute('data-pdf', url);
      expandButton.setAttribute('data-pdf', url);
      expandButton.classList.add(PDFViewerExtensionClass);
      deleteButton.innerHTML = renderToStaticMarkup(
        <DeleteIcon height={20} width={20} />
      );
      divActions.append(expandButton);
      divActions.append(deleteButton);
      divTexts.append(aTexts);
      divPdfViewer.append(divTexts);
      divPdfViewer.append(divActions);

      const sizeCanBeKB = isNumber(size) && size > 100 && size < 100_000;
      const sizeCanBeMB = isNumber(size) && size > 100_000;

      aTexts.href = url;
      aTexts.target = '_blank';
      aTexts.textContent = `${name} ${
        isNumber(size)
          ? sizeCanBeKB
            ? `${(size / 1000).toFixed(1)}KB`
            : sizeCanBeMB
            ? `${(size / 1_000_000).toFixed(2)}MB`
            : `${size} Bytes`
          : 0
      }`;

      if (isValidObject(HTMLAttributes)) {
        Object.entries(HTMLAttributes).forEach(([key, value]) => {
          divPdfViewer.setAttribute(key, value);
        });
      }

      if (isValidObject(this.options.HTMLAttributes)) {
        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          divPdfViewer.setAttribute(key, value);
        });
      }

      return {
        dom: divPdfViewer,
      };
    };
  },
});

export default PDFViewerExtension;
