import cx from 'classnames';
import MessageHover from 'src/components/message-hover';
import i18n from 'src/locales';
import styles from './PriorityIcon.module.scss';
import k from 'src/constants/k';
import {
  HighPriorityIcon,
  MediumPriorityIcon,
  LowPriorityIcon,
  NonePriorityIcon,
} from 'src/icons';

const PriorityIcon = (props) => {
  const { priority_key = '', noHover = false, priority } = props || {};
  const isLowPrio = Boolean(priority_key === 'low' || priority === 1);
  const isMediumPrio = Boolean(priority_key === 'normal' || priority === 2);
  const isHighPrio = Boolean(priority_key === 'high' || priority === 3);
  const h = 20;
  const w = 20;

  return (
    <div
      className={cx(styles.flex_row_xy, styles.prio_icon)}
      style={{ height: `${h}px`, width: `${w}px` }}
    >
      <div className={styles.prio_icon_raw}>
        {isLowPrio ? <LowPriorityIcon height={h} width={w} /> : <></>}
        {isMediumPrio ? <MediumPriorityIcon height={h} width={w} /> : <></>}
        {isHighPrio ? <HighPriorityIcon height={h} width={w} /> : <></>}
        {!isHighPrio && !isMediumPrio && !isLowPrio ? (
          <NonePriorityIcon />
        ) : (
          <></>
        )}
      </div>
      <ul className={styles.hide_element}>
        <li
          className={cx({
            [styles.low_prio]: isLowPrio,
            [styles.medium_prio]: isMediumPrio,
            [styles.high_prio]: isHighPrio,
            [styles.is_default]: !isLowPrio && !isHighPrio && !isMediumPrio,
          })}
        ></li>
        <li
          className={cx({
            [styles.medium_prio]: isMediumPrio,
            [styles.high_prio]: isHighPrio,
            [styles.is_default]: !isHighPrio && !isMediumPrio,
          })}
        ></li>
        <li
          className={cx({
            [styles.high_prio]: isHighPrio,
            [styles.is_default]: !isHighPrio,
          })}
        ></li>
      </ul>
      {priority_key && !noHover && (
        <MessageHover
          className={styles.priority_box_tip}
          message={i18n(k.TASK_PRIORITIES_OBJ[priority_key].i18n)}
        />
      )}
    </div>
  );
};

export default PriorityIcon;
