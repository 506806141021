import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Define.module.scss';

const Define = (props) => {
  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <br />
        <h2>{contents.content[0]}</h2>
        <br />
        <p>
          A <strong>Personal Space</strong> is your own.
        </p>
        <br />
        <p>
          Each Essential account comes with a Personal Space for your use. You
          can compiles notes and segregate them into tags.
        </p>
        <br />
        <p>
          On the other hand, Premium accounts can create or join a Custom Space.
        </p>
        <br />
        <br />
        <h2>{contents.content[1]}</h2>
        <br />
        <br />
        <p>
          Although a <strong>Custom Space</strong> is typically shared, you can
          create another one just for yourself too. Available in the Premium
          plan, custom spaces can be used to keep everyone that matters in the
          loop.
        </p>
        <br />
        <br />
        <h2>{contents.content[2]}</h2>
        <br />
        <br />
        <img alt={'Define your space'} src={'/docs-images/define-img.png'} />
        <br />
        <br />
        <br />
        <ol>
          <li>
            On the left side panel of your screen, click on the name of the
            current space.
          </li>
          <br />
          <li>Click on Create a Space</li>
          <br />
          <li>Enter the name of the Space</li>
          <br />
          <li>Enter the name of the Space</li>
          <br />
          <li>Click on the Create button.</li>
        </ol>
      </div>
    </div>
  );
};

export default Define;
