import cx from 'classnames';
import styles from './Sidebar.module.scss';
import { useWindowDims } from 'src/hooks';
import { isString } from 'src/helpers/utils';

const Sidebar = (props) => {
  const { children, className } = props;
  const { height } = useWindowDims();
  const fixedHeightOfHeader = 72;
  const actualHeight = height - fixedHeightOfHeader + 'px';
  const componentStyles = cx(styles.wrapper, styles.col_16, {
    [className]: isString(className) && className,
  });

  return (
    <div className={componentStyles} style={{ height: actualHeight }}>
      {children}
    </div>
  );
};

export default Sidebar;
