const FilterIcon = (p) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_27587)">
        <path
          d="M13.3333 24H18.6667V21.3333H13.3333V24ZM4 8V10.6667H28V8H4ZM8 17.3333H24V14.6667H8V17.3333Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_27587">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;
