import styles from './NoteView.module.scss';
import cx from 'classnames';
import Header from './Header';
import Comments from './Comments';
import TitleContent from './TitleContent';
import Modal from './Modal';
import EditorHelperComponent from './EditorHelperComponent';
import FilesUploading from './FilesUploading';
import PasswordProtect from './PasswordProtect';
import LeftPanel from './LeftPanel';
import SavingInProgress from './modals/SavingInProgress';
import ImageViewer from './modals/ImageViewer';
import MobileFloat from './MobileFloat';
import FilesView from './FilesView';
import NotFound from '../not-found';
import ReadOnly from './modals/ReadOnly';
import Subscribers from './Subscribers';
import { useWindowResize, useWindowScroll } from 'src/hooks';
import { PDFViewerExtensionClass } from './extensions/PDFViewerExtension';
import { useEffect, useRef, useState } from 'react';
import {
  isMobileView,
  isIosMobile,
  noUIOverflow,
  applyUIOverflow,
} from 'src/helpers/utils';
import { isEmpty, isFunction, isNumber } from 'lodash';
import { clearAllBodyScrollLocks } from 'src/lib/bodyScrollLock.min';
import { withNoteViewContext } from './Context';
import { withRouter } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withNoteDataSettings } from 'src/managers/notes';
import { getQueryStringInJsonFormat } from 'src/helpers/urls';
import { AppRootContainerId } from '..';

export const NoteViewContainerId = 'NoteViewContainerId';

const NoteView = (props) => {
  const evalStarted = useRef(false);
  const {
    isCreateMode,
    isEditMode,
    showModal,
    expandPdf,
    EditorInstance,
    pdf,
    requirePasscode,
    showSavingInProgress,
    showImageViewer,
    showFilesView,
    startNoteCreateMode,
    startNoteEditMode,
    loading,
    reset: resetNote,
    showNotFound,
    showReadOnly,
    user,
    profileLoaded,
    isLoggedIn,
    history,
    location,
    userNoteDraftProperties,
  } = props;
  const noteViewWrap = useRef();
  const [query, setQuery] = useState(null);
  const { availableHeight, availableWidth, initialAvailableHeight } =
    useWindowResize();
  const { scrollY } = useWindowScroll();

  useEffect(() => {
    setQuery(getQueryStringInJsonFormat());
  }, [location]);

  /**
   * Check to start in create or edit mode
   */
  useEffect(() => {
    const onProfileLoad = () => {
      if (!EditorInstance || !profileLoaded || evalStarted?.current) {
        return;
      }

      if (!evalStarted?.current) {
        evalStarted.current = true;
      }

      const { pathname } = window.location;
      const createMode =
        pathname === '/user/note' ||
        pathname === '/note/' ||
        pathname === '/note' ||
        pathname.includes('/note?') ||
        pathname.includes('/note/?');

      if (createMode && !query?.u) {
        // create mode
        if (!isEmpty(user?.auth?.token) && isFunction(startNoteCreateMode)) {
          if (!userNoteDraftProperties?.fetching) {
            startNoteCreateMode();
            evalStarted.current = true;
          } else {
            evalStarted.current = false;
          }
        } else if (history?.push) {
          history.push('/not-found');
        }
      } else if (createMode) {
        // redirect not found
        if (isFunction(history?.push)) {
          history.push('/not-found');
        }
      } else if (isFunction(startNoteEditMode)) {
        startNoteEditMode();
      }
    };

    onProfileLoad();
  }, [
    userNoteDraftProperties?.fetching,
    query,
    history,
    EditorInstance,
    isLoggedIn,
    startNoteCreateMode,
    startNoteEditMode,
    profileLoaded,
    user,
  ]);

  /**
   * Update height on window resize
   */
  useEffect(() => {
    const onResize = () => {
      const dom = noteViewWrap?.current;

      if (!dom) {
        return;
      }

      const h = availableHeight;
      const toolbarWrapper = document.getElementById('formatsToolbarIdWrapper');
      const newHeight = `${h}px`;

      if (isMobileView()) {
        dom.style.height = newHeight;

        if (isIosMobile()) {
          // address auto scroll on focus with ios
          if (window.scrollY) {
            window.scrollTo(0, 0);
          }

          if (toolbarWrapper && h < initialAvailableHeight) {
            // scroll height issue with ios
            toolbarWrapper.style.bottom = 'unset';
            toolbarWrapper.style.top = `${
              h - (toolbarWrapper?.clientHeight || 0)
            }px`;
          } else if (toolbarWrapper) {
            toolbarWrapper.style.bottom = '0';
            toolbarWrapper.style.top = '';
          }
        }
      } else if (dom?.style?.height?.includes('px')) {
        dom.style.height = '';

        if (document.body.style?.height) {
          document.body.style.height = '';
        }
      }
    };

    onResize();
  }, [availableHeight, availableWidth, initialAvailableHeight]);

  /**
   * Block scroll on pages/app dom container
   */
  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);
    const onScroll = () => {
      if (
        (availableWidth < 900 || isMobileView()) &&
        isNumber(dom?.scrollTop) &&
        dom?.scrollTop > 0
      ) {
        if (dom?.scrollTo) {
          dom.scrollTo(0, 0);
        }
      }
    };

    if (dom) {
      dom.addEventListener('scroll', onScroll, false);
    }

    return () => {
      if (dom) {
        dom.removeEventListener('scroll', onScroll, false);
      }
    };
  }, [availableWidth]);

  /**
   * Block overflow when input is focused
   */
  useEffect(() => {
    if (availableHeight < initialAvailableHeight && availableWidth < 900) {
      noUIOverflow();
    } else if (
      availableHeight >= initialAvailableHeight &&
      availableWidth < 900
    ) {
      applyUIOverflow();
    }
  }, [scrollY, availableHeight, availableWidth, initialAvailableHeight]);

  /**
   * No scroll Y for mobile screen
   */
  useEffect(() => {
    const toolbarWrapper = document.getElementById('formatsToolbarIdWrapper');
    const dom = document.getElementById(AppRootContainerId);

    if (
      isMobileView() &&
      (scrollY > 0 || toolbarWrapper.style.top?.includes('px'))
    ) {
      window.scrollTo(0, 0);

      if (dom?.scrollTo) {
        dom.scrollTo(0, 0);
      }
    }
  }, [scrollY]);

  /**
   * Clear scroll locks on unmount
   */
  useEffect(() => {
    clearAllBodyScrollLocks();

    return clearAllBodyScrollLocks;
  }, []);

  /**
   * Scroll to top on exit
   */
  useEffect(() => {
    return () => window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => resetNote,
    // eslint-disable-next-line
    []
  );

  /**
   * Show modal for:
   * - pdf viewer if expand button is clicked
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (!evt?.target) {
        return;
      }

      if (evt?.target?.classList?.contains(PDFViewerExtensionClass)) {
        const url = evt?.target?.dataset?.pdf;

        if (pdf || !url) {
          return;
        }

        if (isFunction(expandPdf)) {
          expandPdf(url);
        }
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [pdf, expandPdf, showModal]);

  return (
    <>
      {showNotFound && (
        <NotFound
          onSuccess={() => {
            if (isFunction(startNoteEditMode) && loading) {
              startNoteEditMode();
            }
          }}
        />
      )}
      <div
        ref={noteViewWrap}
        id={NoteViewContainerId}
        className={cx(styles.note_view, {
          [styles.hide_element]: showNotFound,
        })}
      >
        <div className={cx(styles.flex_row_xy, styles.note_view_content)}>
          <div className={styles.note_view_content_left}>
            <LeftPanel />
          </div>
          <div className={styles.note_view_content_middle}>
            {(isCreateMode || isEditMode) && !loading ? <Subscribers /> : <></>}
            <TitleContent />
            <EditorHelperComponent />
            {!isMobileView() && !isCreateMode && !isEditMode && !loading && (
              <Comments />
            )}
            {showFilesView && <FilesView />}
          </div>
          <div className={styles.note_view_content_right} />
        </div>
        <FilesUploading />
        <Header />
        {showSavingInProgress && <SavingInProgress />}
        {!loading && isMobileView() && (
          <MobileFloat noteViewDom={noteViewWrap.current} />
        )}
        {showModal && <Modal />}
        {showImageViewer && <ImageViewer />}
        {requirePasscode && <PasswordProtect />}
        {showReadOnly && <ReadOnly />}
      </div>
    </>
  );
};

export default withUserDataAndProfileSettings(
  withNoteDataSettings(withNoteViewContext(withRouter(NoteView)))
);
