import cx from 'classnames';
import styles from './TablesMenu.module.scss';
import { withNoteViewContext } from './Context';
import { isMobileView } from 'src/helpers/utils';
import { isFunction } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { PlusIcon } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

export const TablesMenuContainerId = 'TablesMenuContainerId';

const TablesMenuOptionsContainerId = 'TablesMenuOptionsContainerId';

const TablesMenu = (props) => {
  const { show, close, getRef, isThemeDarkMode, EditorInstance } = props;

  const [showOptions, setShowOptions] = useState(false);

  const closeMenu = useCallback(() => {
    if (isFunction(close)) {
      close();
    }
  }, [close]);

  useEffect(() => {
    if (!show) {
      setShowOptions(false);
    }
  }, [show]);

  /**
   * Toggle expand options if clicked outside
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      const optionsDom = document.getElementById(TablesMenuOptionsContainerId);
      const ctaDom = document.querySelector(
        `.${styles.table_formats_menu_cta}`
      );

      if (
        !ctaDom?.contains(evt?.target) &&
        !optionsDom?.contains(evt?.target) &&
        evt?.target?.id !== TablesMenuOptionsContainerId &&
        showOptions
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [show, showOptions]);

  /**
   * Close if editor is blurred
   */
  useEffect(() => {
    const editor = EditorInstance;
    const onSelectionUpdate = () => {
      if (editor && !editor?.isFocused && show && isMobileView()) {
        closeMenu();
      }
    };

    if (editor) {
      editor.on('selectionUpdate', onSelectionUpdate);
    }

    return () => {
      if (editor) {
        editor.off('selectionUpdate', onSelectionUpdate);
      }
    };
  }, [show, showOptions, EditorInstance, closeMenu]);

  return (
    <div
      className={cx(styles.table_formats_menu, styles.flex_row_xy, {
        [styles.table_formats_menu_hidden]: !show,
        [styles.table_formats_menu_no_cta]: !showOptions,
      })}
      id={TablesMenuContainerId}
      ref={getRef()}
    >
      <div
        className={cx(styles.table_formats_menu_cta, styles.flex_row_xy, {
          [styles.hide_element]: showOptions,
        })}
      >
        <button
          type="button"
          className={styles.flex_row_xy}
          onClick={() => setShowOptions(true)}
        >
          <PlusIcon />
        </button>
      </div>

      <div
        className={cx(styles.flex_row_xy, styles.table_formats_menu_options, {
          [styles.table_formats_menu_options_dark]: isThemeDarkMode,
          [styles.table_formats_menu_options_hidden]: !showOptions,
        })}
        id={TablesMenuOptionsContainerId}
      >
        <div className={styles.flex_column_xy}>
          <div
            onClick={() => {
              EditorInstance.chain().focus().addColumnBefore().run();
              closeMenu();
            }}
          >
            <p>Add column before</p>
          </div>
          <div
            onClick={() => {
              EditorInstance.chain().focus().addColumnAfter().run();
              closeMenu();
            }}
          >
            <p>Add column after</p>
          </div>
          <div
            onClick={() => {
              EditorInstance.chain().focus().addRowBefore().run();
              closeMenu();
            }}
          >
            <p>Add row before</p>
          </div>
          <div
            onClick={() => {
              EditorInstance.chain().focus().addRowAfter().run();
              closeMenu();
            }}
          >
            <p>Add row after</p>
          </div>
          <div
            onClick={() => {
              EditorInstance.chain().focus().deleteColumn().run();
              closeMenu();
            }}
          >
            <p>Delete column</p>
          </div>
          <div
            onClick={() => {
              EditorInstance.chain().focus().deleteRow().run();
              closeMenu();
            }}
          >
            <p>Delete row</p>
          </div>

          <div
            onClick={() => {
              EditorInstance.chain().focus().deleteTable().run();
              closeMenu();
            }}
          >
            <p>Delete table</p>
          </div>

          {EditorInstance &&
          EditorInstance?.can &&
          EditorInstance.can().mergeCells() ? (
            <div
              onClick={() => {
                EditorInstance.chain().focus().mergeCells().run();
                closeMenu();
              }}
            >
              <p>Merge cells</p>
            </div>
          ) : (
            <></>
          )}
          {EditorInstance &&
          EditorInstance?.can &&
          EditorInstance.can().splitCell() ? (
            <div
              onClick={() => {
                EditorInstance.chain().focus().splitCell().run();
                closeMenu();
              }}
            >
              <p>Split cell</p>
            </div>
          ) : (
            <></>
          )}

          <div
            onClick={() => {
              closeMenu();
            }}
          >
            <p>Close</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(TablesMenu));
