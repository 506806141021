export enum MobileDashboardView {
  'spaces' = 'spaces',
  'home' = 'home',
}

export enum mobileViewUnitTaskOptions {
  'updatingState' = 'updatingState',
  'deleting' = 'deleting',
  'archiving' = 'archiving',
  'recover' = 'recover',
}

export enum NoteViewMode {
  'view' = 'view',
  'edit' = 'edit',
  'create' = 'create',
}

export enum NoteViewModalType {
  'pdfViewer' = 'pdfViewer',
  'properties' = 'properties',
  'mobileComments' = 'mobileComments',
  'savedChanges' = 'savedChanges',
  'savingInProgress' = 'savingInProgress',
  'tags' = 'tags',
  'readOnly' = 'readOnly',
  'subscribers' = 'subscribers',
  'delete' = 'delete',
  'activities' = 'activities',
  'comments' = 'comments'
}
