const ImageFileIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M4.02333 17.5L4.00667 17.5167L3.98917 17.5H2.49333C2.27401 17.4998 2.06375 17.4125 1.90874 17.2573C1.75374 17.1022 1.66667 16.8918 1.66667 16.6725V3.3275C1.66819 3.10865 1.75575 2.89918 1.91043 2.74435C2.06511 2.58951 2.27448 2.50175 2.49333 2.5H17.5067C17.9633 2.5 18.3333 2.87083 18.3333 3.3275V16.6725C18.3318 16.8914 18.2442 17.1008 18.0896 17.2556C17.9349 17.4105 17.7255 17.4983 17.5067 17.5H4.02333ZM16.6667 12.5V4.16667H3.33333V15.8333L11.6667 7.5L16.6667 12.5ZM16.6667 14.8567L11.6667 9.85667L5.69 15.8333H16.6667V14.8567ZM6.66667 9.16667C6.22464 9.16667 5.80072 8.99107 5.48816 8.67851C5.1756 8.36595 5 7.94203 5 7.5C5 7.05797 5.1756 6.63405 5.48816 6.32149C5.80072 6.00893 6.22464 5.83333 6.66667 5.83333C7.10869 5.83333 7.53262 6.00893 7.84518 6.32149C8.15774 6.63405 8.33333 7.05797 8.33333 7.5C8.33333 7.94203 8.15774 8.36595 7.84518 8.67851C7.53262 8.99107 7.10869 9.16667 6.66667 9.16667Z"
        fill="#718096"
      />
    </svg>
  );
};

export default ImageFileIcon;
