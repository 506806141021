const SignOutIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_309_8083)">
        <path
          d="M8.00004 14.6667C4.31804 14.6667 1.33337 11.682 1.33337 8.00004C1.33337 4.31804 4.31804 1.33338 8.00004 1.33338C9.03515 1.3326 10.0562 1.57322 10.982 2.03614C11.9078 2.49905 12.7129 3.17149 13.3334 4.00004H11.5267C10.7569 3.32122 9.80749 2.87895 8.79252 2.72631C7.77754 2.57367 6.74008 2.71715 5.80463 3.13952C4.86918 3.56189 4.07547 4.24522 3.51875 5.1075C2.96203 5.96978 2.66595 6.97439 2.66604 8.00078C2.66612 9.02717 2.96237 10.0317 3.51924 10.8939C4.0761 11.7561 4.86992 12.4393 5.80544 12.8615C6.74097 13.2837 7.77845 13.427 8.7934 13.2742C9.80834 13.1214 10.7576 12.679 11.5274 12H13.334C12.7135 12.8287 11.9083 13.5012 10.9824 13.9641C10.0564 14.427 9.03526 14.6676 8.00004 14.6667ZM12.6667 10.6667V8.66671H7.33337V7.33338H12.6667V5.33338L16 8.00004L12.6667 10.6667Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_8083">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignOutIcon;
