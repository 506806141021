const ChatSmileIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_309_8062)">
        <path
          d="M1.33338 8.00004C1.33338 4.31804 4.31805 1.33337 8.00005 1.33337C11.682 1.33337 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8.00005 14.6667H1.33338L3.28605 12.714C2.66607 12.0957 2.1744 11.3609 1.8393 10.5519C1.5042 9.74291 1.33226 8.87568 1.33338 8.00004V8.00004ZM4.55205 13.3334H8.00005C9.05488 13.3334 10.086 13.0206 10.9631 12.4345C11.8401 11.8485 12.5237 11.0156 12.9274 10.041C13.3311 9.06648 13.4367 7.99412 13.2309 6.95956C13.0251 5.92499 12.5172 4.97468 11.7713 4.2288C11.0254 3.48292 10.0751 2.97497 9.04053 2.76919C8.00596 2.5634 6.93361 2.66902 5.95907 3.07268C4.98453 3.47635 4.15158 4.15994 3.56554 5.037C2.97951 5.91406 2.66671 6.94521 2.66671 8.00004C2.66671 9.43471 3.23405 10.7767 4.22871 11.7714L5.17138 12.714L4.55205 13.3334V13.3334ZM5.33338 8.66671H10.6667C10.6667 9.37395 10.3858 10.0522 9.88566 10.5523C9.38557 11.0524 8.70729 11.3334 8.00005 11.3334C7.2928 11.3334 6.61453 11.0524 6.11443 10.5523C5.61433 10.0522 5.33338 9.37395 5.33338 8.66671V8.66671Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_8062">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatSmileIcon;
