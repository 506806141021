import cx from 'classnames';
import styles from './FasterSearch.module.scss';

const imageLink =
  'https://storage.googleapis.com/chamu-store-s1/images/app-static/homepage-search.png';

const FasterSearch = () => {
  return (
    <div className={cx(styles.flex_row_xy, styles.faster_search)}>
      <div className={cx(styles.flex_row_xy, styles.faster_search_raw)}>
        <div className={cx(styles.flex_row_xy, styles.content)}>
          <div className={styles.texts}>
            <h1>Faster Search</h1>
            <p>
              Color-coded labels categorize your search easier. Type in a
              keyword and it shows every available tag, file, person, or space
              in an organized way.
            </p>
          </div>
          <div className={styles.image}>
            <img src={imageLink} alt={'Homepage faster search'} />
            <div className={styles.cover}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FasterSearch;
