import styles from './Activities.module.scss';
import cx from 'classnames';
import ActivityItem from './activity-item';
import k from 'src/constants/k';
import { withNoteViewContext } from '../Context';
import { ActivityIcon } from 'src/icons';
import { useCallback, useEffect, useMemo } from 'react';
import { Button, CrossIcon } from 'evergreen-ui';
import { getUserDisplayName } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar } from '@chakra-ui/react';
import { isEmpty, isFunction } from 'lodash';

const Activities = (props) => {
  const {
    closeModal,
    activities,
    activitiesFetching,
    user,
    profileLoaded,
    allowedToToggleModify,
    authorId,
    isThemeDarkMode,
  } = props;

  const onClose = useCallback(() => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  }, [closeModal]);

  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (evt?.target?.classList?.contains(styles.activities)) {
        onClose();
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [onClose]);

  const userDisplayName = useMemo(() => getUserDisplayName(user), [user]);

  return (
    <div className={cx(styles.flex_row_xy, styles.activities)}>
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        <div
          className={cx(styles.flex_row_xy, styles.title, {
            [styles.title_dark]: isThemeDarkMode,
          })}
        >
          <ActivityIcon height={20} width={20} />
          <h3>{'Activity Log'}</h3>
        </div>
        {activitiesFetching && (
          <div className={cx(styles.flex_row_xy, styles.loading)}></div>
        )}
        <div
          className={cx(styles.list, {
            [styles.hide_element]:
              activitiesFetching || !activities || isEmpty(activities),
            [styles.list_dark]: isThemeDarkMode,
          })}
        >
          <ul>
            {activities.map((activity) => {
              return (
                <li key={`NoteViewActivity${activity.ref_id}`}>
                  <div className={cx(styles.flex_row_xy, styles.author)}>
                    {!isEmpty(activity.authorInfo) && (
                      <Avatar
                        height={'30px'}
                        width={'30px'}
                        background={'#000'}
                        backgroundColor={'#000'}
                        src={activity?.authorInfo?.image}
                        name={getUserDisplayName(activity?.authorInfo || {})}
                      />
                    )}

                    {!activity?.authorInfo &&
                      !isEmpty(userDisplayName) &&
                      profileLoaded &&
                      allowedToToggleModify &&
                      authorId === user?.profileID &&
                      activity.code === k.TASK_ACTIVITY_TYPES.CREATE.code && (
                        <Avatar
                          src={user?.image}
                          height={'30px'}
                          width={'30px'}
                          background={'#000'}
                          backgroundColor={'#000'}
                          name={userDisplayName}
                        />
                      )}
                  </div>
                  <div className={styles.item}>
                    <ActivityItem activity={activity} />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div
          className={cx(styles.flex_row_xy, styles.close, {
            [styles.close_dark]: isThemeDarkMode,
          })}
        >
          <Button
            onClick={onClose}
            appearance="minimal"
            className={styles.flex_row_xy}
          >
            <CrossIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Activities));
