import styles from './FilePreview.module.scss';
import k from 'src/constants/k';
import axios from 'axios';
import Logger from 'src/lib/Logger';
import { getAuthHeaders } from 'src/helpers/utils';
import { getApiDashboardBaseUrl } from 'src/helpers/urls';
import { isValidFileLink, toString, isNumber } from 'src/helpers/utils';

const Quill = window.Quill;
const filePreviewImages = k.FILE_PREVIEW_IMAGES;
const OldVideo = Quill.import('formats/video');

class FilePreview extends OldVideo {
  static create(value = {}) {
    super.create(value);
    const isThemeDarkMode = window?.isThemeDarkMode;
    const div = document.createElement('div');
    // size in bytes
    const {
      taskId = '',
      uRef = '',
      contentType = '',
      file = null,
      url = '',
      name = '',
      size = 0,
      refId = '',
      edit = true,
    } = value || {};
    // check if value is already valid url
    const fileName = toString(name).replace(/\s/g, '').trim();
    const urlSanitized = toString(url).replace(/\s/g, '').trim();

    // types
    const isText =
      (fileName.indexOf('.txt') > -1 &&
        fileName.indexOf('.txt') + '.txt'.length >= fileName.length) ||
      (fileName.indexOf('.text') > -1 &&
        fileName.indexOf('.text') + '.text'.length >= fileName.length);
    const isMusic =
      (fileName.indexOf('.mp3') > -1 &&
        fileName.indexOf('.mp3') + '.mp3'.length >= fileName.length) ||
      (fileName.indexOf('.wav') > -1 &&
        fileName.indexOf('.wav') + '.wav'.length >= fileName.length) ||
      (fileName.indexOf('.ogg') > -1 &&
        fileName.indexOf('.ogg') + '.ogg'.length >= fileName.length) ||
      (urlSanitized.indexOf('.pdf') > -1 &&
        urlSanitized.indexOf('.pdf') + '.pdf'.length >= urlSanitized.length);
    const isPdfFile =
      (fileName.indexOf('.pdf') > -1 &&
        fileName.indexOf('.pdf') + '.pdf'.length >= fileName.length) ||
      (urlSanitized.indexOf('.pdf') > -1 &&
        urlSanitized.indexOf('.pdf') + '.pdf'.length >= urlSanitized.length);
    const isDocsOrWordFile =
      (fileName.indexOf('.docx') > -1 &&
        fileName.indexOf('.docx') + '.docx'.length >= fileName.length) ||
      (fileName.indexOf('.docs') > -1 &&
        fileName.indexOf('.docs') + '.docs'.length >= fileName.length) ||
      (fileName.indexOf('.document') > -1 &&
        fileName.indexOf('.document') + '.document'.length >=
          fileName.length) ||
      (urlSanitized.indexOf('.docs') > -1 &&
        urlSanitized.indexOf('.docs') + '.docs'.length >=
          urlSanitized.length) ||
      (urlSanitized.indexOf('.docx') > -1 &&
        urlSanitized.indexOf('.docx') + '.docx'.length >= urlSanitized.length);
    const isPptFile =
      (fileName.indexOf('.ppt') > -1 &&
        fileName.indexOf('.ppt') + '.ppt'.length >= fileName.length) ||
      (fileName.indexOf('.pptx') > -1 &&
        fileName.indexOf('.pptx') + '.pptx'.length >= fileName.length) ||
      (urlSanitized.indexOf('.ppt') > -1 &&
        urlSanitized.indexOf('.ppt') + '.ppt'.length >= urlSanitized.length);
    const isZipArchive =
      (fileName.indexOf('.zip') > -1 &&
        fileName.indexOf('.zip') + '.zip'.length >= fileName.length) ||
      (fileName.indexOf('.rar') > -1 &&
        fileName.indexOf('.rar') + '.rar'.length >= fileName.length) ||
      (urlSanitized.indexOf('.zip') > -1 &&
        urlSanitized.indexOf('.zip') + '.zip'.length >= urlSanitized.length) ||
      (urlSanitized.indexOf('.rar') > -1 &&
        urlSanitized.indexOf('.rar') + '.rar'.length >= urlSanitized.length);
    const isExcel =
      (fileName.indexOf('.xlsx') > -1 &&
        fileName.indexOf('.xlsx') + '.xlsx'.length >= fileName.length) ||
      (fileName.indexOf('.excel') > -1 &&
        fileName.indexOf('.excel') + '.excel'.length >= fileName.length) ||
      (fileName.indexOf('.sheets') > -1 &&
        fileName.indexOf('.sheets') + '.sheets'.length >= fileName.length) ||
      (urlSanitized.indexOf('.excel') > -1 &&
        urlSanitized.indexOf('.excel') + '.excel'.length >=
          urlSanitized.length) ||
      (urlSanitized.indexOf('.xlsx') > -1 &&
        urlSanitized.indexOf('.xlsx') + '.xlsx'.length >= urlSanitized.length);
    const isPhotoshopFile =
      (fileName.indexOf('.psd') > -1 &&
        fileName.indexOf('.psd') + '.psd'.length >= fileName.length) ||
      (urlSanitized.indexOf('.psd') > -1 &&
        urlSanitized.indexOf('.psd') + '.psd'.length >= urlSanitized.length);
    const isAdobeIllustration =
      (fileName.indexOf('.ai') > -1 &&
        fileName.indexOf('.ai') + '.ai'.length >= fileName.length) ||
      (urlSanitized.indexOf('.ai') > -1 &&
        urlSanitized.indexOf('.ai') + '.ai'.length >= urlSanitized.length);
    // end of types
    const user = null;
    let doneUploading =
      isValidFileLink(url) &&
      !toString(url).includes('data:') &&
      url !== 'upload' &&
      url !== 'error';
    let isErrorUpload =
      url === 'error' ||
      !user?.auth ||
      (!doneUploading && !file) ||
      (!doneUploading && (!taskId || !uRef)) ||
      (!doneUploading && !user?.auth?.token);
    const id = !doneUploading || !refId ? `FilePreview${Date.now()}` : refId;
    const sizeCanBeKB = isNumber(size) && size > 1000 && size < 100_000;
    const sizeCanBeMB = isNumber(size) && size > 100_000;
    div.setAttribute('data-url', url);
    div.setAttribute('data-name', name);
    div.setAttribute('data-refid', refId);
    div.setAttribute('data-size', `${size}`);
    div.setAttribute('id', id);
    div.classList.add(styles.file_preview);

    const divInfo = document.createElement('div');
    const divClose = document.createElement('div');
    const divIcon = document.createElement('div');
    const divTexts = document.createElement('div');
    const divLoading = document.createElement('div');
    const divSpinner = document.createElement('div');
    const divHover = document.createElement('div');
    const imgIcon = document.createElement('img');
    const divErrorIcon = document.createElement('div');
    const errorIcon = document.createElement('img');
    const divAnchor = document.createElement('div');
    const anchorLink = document.createElement('a');
    const domSize = document.createElement('h6');
    const domProgress = document.createElement('div');
    const divPercentage = document.createElement('div');
    const domFileName = document.createElement('h6');
    const hoverFileName = document.createElement('h6');

    domFileName.textContent = `${name || ''}`;

    // hover filename
    divHover.classList.add(styles.file_preview_hover);
    divHover.append(hoverFileName);
    hoverFileName.textContent = `${name || ''}`;

    // progress
    domProgress.append(divPercentage);
    domProgress.classList.add(styles.file_preview_progress);

    // error image
    errorIcon.setAttribute('src', k.FILE_PREVIEW_IMAGES.error);
    errorIcon.setAttribute('alt', 'upload error');

    // anchor link children
    divAnchor.classList.add(styles.cover);
    divAnchor.classList.add(styles.file_preview_link);
    divAnchor.append(anchorLink);
    anchorLink.target = '_blank';
    anchorLink.href = url;
    // end of anchor link

    // info children
    divInfo.append(divIcon);
    divInfo.append(divTexts);
    divInfo.append(divHover);
    divInfo.classList.add(styles.file_preview_info);

    // close children
    divClose.classList.add(styles.file_preview_close);
    if (!doneUploading && !isErrorUpload) {
      divClose.classList.add(styles.hide_element);
    }

    // icon trash/delete/remove
    divClose.innerHTML = `<div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1082 4.69439H1.87293C1.6094 4.69439 1.40234 4.90145 1.40234 5.16498C1.40234 5.42851 1.6094 5.63557 1.87293 5.63557H2.34352V15.0473C2.34352 15.565 2.76705 15.9885 3.2847 15.9885H12.6965C13.2141 15.9885 13.6376 15.565 13.6376 15.0473V5.63557H14.1082C14.3718 5.63557 14.5788 5.42851 14.5788 5.16498C14.5788 4.90145 14.3718 4.69439 14.1082 4.69439ZM6.10823 13.165C6.10823 13.6826 5.6847 14.1062 5.16705 14.1062C4.6494 14.1062 4.22587 13.6826 4.22587 13.165V7.51792C4.22587 7.00028 4.6494 6.57675 5.16705 6.57675C5.6847 6.57675 6.10823 7.00028 6.10823 7.51792V13.165ZM8.93176 13.165C8.93176 13.6826 8.50823 14.1062 7.99058 14.1062C7.47293 14.1062 7.0494 13.6826 7.0494 13.165V7.51792C7.0494 7.00028 7.47293 6.57675 7.99058 6.57675C8.50823 6.57675 8.93176 7.00028 8.93176 7.51792V13.165ZM11.7553 13.165C11.7553 13.6826 11.3318 14.1062 10.8141 14.1062C10.2965 14.1062 9.87293 13.6826 9.87293 13.165V7.51792C9.87293 7.00028 10.2965 6.57675 10.8141 6.57675C11.3318 6.57675 11.7553 7.00028 11.7553 7.51792V13.165ZM13.6376 1.87086H9.87293C9.87293 1.35322 9.4494 0.929688 8.93176 0.929688H7.0494C6.53176 0.929688 6.10823 1.35322 6.10823 1.87086H2.34352C1.82587 1.87086 1.40234 2.29439 1.40234 2.81204V3.75322H14.5788V2.81204C14.5788 2.29439 14.1553 1.87086 13.6376 1.87086Z" fill="#8F95B2"/>
    </svg></div>
    `;

    if (isThemeDarkMode) {
      divClose.classList.add(styles.file_preview_close_dark);
    } else {
      divClose.classList.add(styles.file_preview_close_light);
    }

    if (!edit) {
      divClose.classList.add(styles.hide_element);
    }
    // end of close children

    // icon children
    imgIcon.src = isExcel
      ? filePreviewImages.excel
      : isPdfFile
      ? filePreviewImages.pdf
      : isPptFile
      ? filePreviewImages.presentation
      : isDocsOrWordFile
      ? filePreviewImages.word
      : isZipArchive
      ? filePreviewImages.zip
      : isMusic
      ? filePreviewImages.music
      : isPhotoshopFile
      ? filePreviewImages.photoshop
      : isText
      ? filePreviewImages.text
      : isAdobeIllustration
      ? filePreviewImages.adobeIllustration
      : filePreviewImages.general;
    imgIcon.setAttribute('alt', 'File type');
    divLoading.classList.add(styles.file_preview_info_icon_loading);
    divErrorIcon.classList.add(styles.file_preview_info_icon_error);
    divIcon.contentEditable = false;
    divIcon.classList.add(styles.file_preview_info_icon);

    // loading chldren
    divSpinner.classList.add(styles.file_preview_spinner);
    divLoading.append(divSpinner);

    // texts children
    divTexts.contentEditable = false;
    divTexts.append(domFileName);
    divTexts.classList.add(styles.file_preview_info_texts);
    domSize.textContent = `${
      isNumber(size)
        ? sizeCanBeKB
          ? (size / 1000).toFixed(2)
          : sizeCanBeMB
          ? (size / 1_000_000).toFixed(2)
          : size
        : 0
    } ${sizeCanBeKB ? ' KB' : sizeCanBeMB ? ' MB' : ' B'}`;

    if (doneUploading) {
      divTexts.append(domSize);
    } else {
      // progress % dom wrap
      if (!isErrorUpload) {
        divTexts.append(domProgress);
      }
    }
    // end of texts children

    div.contentEditable = false;
    div.append(divInfo);
    div.append(divClose);

    const applyTheme = () => {
      const dom = div;

      if (dom?.id) {
        if (!window?.isThemeDarkMode) {
          div.classList.remove(styles.file_preview_dark);
          div.classList.add(styles.file_preview_light);
          divLoading.classList.add(styles.file_preview_info_icon_loading_light);
          divLoading.classList.remove(
            styles.file_preview_info_icon_loading_dark
          );
        } else {
          div.classList.add(styles.file_preview_dark);
          div.classList.remove(styles.file_preview_light);
          divLoading.classList.add(styles.file_preview_info_icon_loading_dark);
          divLoading.classList.remove(
            styles.file_preview_info_icon_loading_light
          );
        }
      }
    };

    divClose.addEventListener('click', (evt) => {
      const dom = div;

      if (dom && edit) {
        dom.remove();
      }

      if (evt) {
        evt.preventDefault();
      }
    });

    if (!doneUploading) {
      // if not then we upload and show progress
      // add loading icon

      if (!isErrorUpload) {
        div.setAttribute('data-uploading', 'true');
        divIcon.append(divLoading);
        const { id, lb, n } = user.auth;
        const headers = getAuthHeaders(user);
        const baseURL = getApiDashboardBaseUrl();
        const userIsPremium = false; //   ProfileAPI.SPACES.isUserPremiumWithSpace();

        if (userIsPremium) {
          axios({
            baseURL,
            headers,
            method: 'POST',
            data: {
              fileName,
              contentType,
              taskId,
              uRef,
              inline: true,
            },
            url: `/v1/task/file/upload?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            },
          })
            .catch((err) => {
              if (err?.message) {
                Logger.log(`FilePreviewBlot(): Upload err: ${err.message}`);
              }

              // show error icon
              divLoading.remove();
              divErrorIcon.append(errorIcon);
              divIcon.append(divErrorIcon);
              div.removeAttribute('data-uploading');
            })
            .then((res) => {
              if (
                res?.data?.max_size_reached ||
                res?.data?.maxSize ||
                res?.data?.max_size_task
              ) {
                // todo  toaster
              } else if (res?.data?.notAllowedPlan) {
                // todo
              } else if (res?.data?.notAllowedFile) {
                // todo
              } else if (res?.data?.success && res?.data?.url) {
                const signedUrl = res.data.url;
                const upload = async () => {
                  try {
                    await axios({
                      method: 'PUT',
                      url: signedUrl,
                      headers: {
                        'Content-Type': contentType,
                      },
                      data: file,
                      onUploadProgress: (e) => {
                        // show progress bar
                        if (e && isNumber(e.loaded) && isNumber(e.total)) {
                          const percentCompleted = Math.round(
                            (e.loaded * 100) / e.total
                          );

                          if (isNumber(percentCompleted)) {
                            // update percentage bar
                            divPercentage.style.width = `calc(${percentCompleted}% + 4px)`;
                          }
                        }
                      },
                    });

                    const fileRefId = res.data.refId;
                    let completeRes = null;
                    //  await ProfileAPI.TASKS.uploadFileComplete(
                    //   fileRefId,
                    //   taskId,
                    //   uRef
                    // );

                    domProgress.remove();
                    divLoading.remove();
                    divTexts.append(domSize);
                    divClose.classList.remove(styles.hide_element);

                    if (completeRes && !completeRes.err) {
                      // new url
                      const newUrl = completeRes.publicUrl;
                      divIcon.append(imgIcon);
                      // click link
                      divInfo.append(divAnchor);
                      anchorLink.setAttribute('href', newUrl);
                      div.setAttribute('data-url', newUrl);
                      div.setAttribute('data-refid', fileRefId);
                      div.setAttribute('id', fileRefId);
                    } else {
                      throw new Error(
                        'uploadFileComplete():something went wrong'
                      );
                    }
                  } catch (err) {
                    // show error icon
                    divErrorIcon.append(errorIcon);
                    divIcon.append(divErrorIcon);
                  } finally {
                    div.removeAttribute('data-uploading');
                  }
                };

                upload();
              } else {
                // show error icon
                // upload error
                divLoading.remove();
                divErrorIcon.append(errorIcon);
                divIcon.append(divErrorIcon);
                div.removeAttribute('data-uploading');

                // ModalAPI.toaster(
                //   'danger',
                //   i18n('common_something_wrong_w_try'),
                //   4500
                // );
              }
            });
        } else {
          const formData = new FormData();
          formData.append('file', file, fileName);

          axios({
            baseURL,
            method: 'POST',
            headers: {
              ...headers,
              'content-type': 'multipart/form-data',
            },
            data: formData,
            url: `/v1/file/upload/file?auth_token_id=${id}&lb=${lb}&n=${n}&u_ref=${uRef}&inline=true${
              fileName ? `&fileName=${fileName}` : ''
            }`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            },
            onUploadProgress: (e) => {
              // show progress bar
              if (e && isNumber(e.loaded) && isNumber(e.total)) {
                const percentCompleted = Math.round((e.loaded * 100) / e.total);

                if (isNumber(percentCompleted)) {
                  // update percentage bar
                  divPercentage.style.width = `calc(${percentCompleted}% + 4px)`;
                }
              }
            },
          })
            .catch((err) => {
              if (err?.message) {
                Logger.log(`FilePreviewBlot(): Upload err: ${err.message}`);
              }

              // show error icon
              divLoading.remove();
              divErrorIcon.append(errorIcon);
              divIcon.append(divErrorIcon);
              divClose.classList.remove(styles.hide_element);
              div.removeAttribute('data-uploading');
            })
            .then((res) => {
              try {
                domProgress.remove();
                divLoading.remove();
                divTexts.append(domSize);
                divClose.classList.remove(styles.hide_element);

                if (res?.data?.success && res?.data?.file && res?.data?.url) {
                  // new url
                  const newUrl = res?.data?.url;
                  const fileRefId = res?.data?.file?.ref_id;
                  divIcon.append(imgIcon);
                  // click link
                  divInfo.append(divAnchor);
                  anchorLink.setAttribute('href', newUrl);
                  div.setAttribute('data-url', newUrl);
                  div.setAttribute('data-refid', fileRefId);
                  div.setAttribute('id', fileRefId);
                } else {
                  if (
                    res?.data?.max_size_reached ||
                    res?.data?.maxSize ||
                    res?.data?.max_size_task
                  ) {
                    // ModalAPI.toaster(
                    //   'danger',
                    //   i18n('user_create_task_file_attached_max_size_reached'),
                    //   4500
                    // );
                  } else {
                    // ModalAPI.toaster(
                    //   'danger',
                    //   i18n('common_something_wrong_w_try'),
                    //   4500
                    // );
                  }

                  throw new Error(
                    'uploadFileCompleteNonPremium():something went wrong'
                  );
                }
              } catch {
                // show error icon
                divErrorIcon.append(errorIcon);
                divIcon.append(divErrorIcon);
                divClose.classList.remove(styles.hide_element);

                // ModalAPI.toaster(
                //   'danger',
                //   i18n('common_something_wrong_w_try'),
                //   4500
                // );
              } finally {
                div.removeAttribute('data-uploading');
              }
            });
        }
      } else {
        // show error icon
        // upload error
        divErrorIcon.append(errorIcon);
        divIcon.append(divErrorIcon);
      }
    } else {
      if (!isErrorUpload) {
        // show file icon
        divIcon.append(imgIcon);
        // click link
        divInfo.append(divAnchor);
      } else {
        divErrorIcon.append(errorIcon);
        divIcon.append(divErrorIcon);
      }
    }

    applyTheme();

    return div;
  }

  static value(domNode) {
    if (domNode && domNode.dataset) {
      const url = domNode.dataset?.url || '';
      const name = domNode.dataset?.name || '';
      const refId = domNode.dataset?.refid || '';
      const size = isNumber(domNode.dataset?.size) ? domNode.dataset?.size : 0;
      const uploading = !!(`${domNode.dataset?.uploading || ''}` === 'true');

      return {
        name,
        refId,
        size,
        url: !url || url === 'error' || uploading ? 'upload' : url,
      };
    }

    return { url: '', name: '', refId: '', size: 0 };
  }
}

FilePreview.blotName = 'file';
FilePreview.className = 'ql-file-custom';
FilePreview.tagName = 'div';

export default function setupFilePreview() {
  Quill.register('formats/file', FilePreview, true);
}
