import { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Pricing.module.scss';
import Comaprison from './comparison';
import FAQ from './FAQ';
import Header from '../layout/header';
import Footer from '../layout/footer';
import k from 'src/constants/k';
import i18n, { Markdown } from 'src/locales';
import { isCapacitorMobileApp, isDev } from 'src/helpers/utils';
import { AdjustableAbsolutWrap } from '../layout/commons';
import { TickCircleIcon, Button, Spinner } from 'evergreen-ui';
import { Link, Redirect } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { useAuth, useWindowScroll } from 'src/hooks';
import { createCheckoutSessionRequest } from 'src/managers/api/payments';
import { isEmpty, isString } from 'lodash';

const PricingCard = (props) => {
  return (
    <div
      className={cx(styles.pricing_card, {
        [props.className]:
          isString(props.className) && !isEmpty(props.className),
      })}
    >
      {!isEmpty(props.title) && isString(props.title) && (
        <h1 className={styles.title}> {props.title}</h1>
      )}
      {!isEmpty(props.description) && isString(props.description) && (
        <h5 className={styles.desc}>{props.description}</h5>
      )}

      {props.children}
    </div>
  );
};

const Pricing = (props) => {
  const { isLoggedIn, profileLoaded, userIsPremium } = props;

  const [redirectingToUpgrade, setRedirectingToUpgrade] = useState(false);

  const { scrollToTop } = useWindowScroll();

  const { getAuthenticatedHeaders } = useAuth(props);

  useEffect(() => {
    scrollToTop();

    // eslint-disable-next-line
  }, []);

  if (isCapacitorMobileApp()) {
    return <Redirect to={'/user'} />;
  }

  const onPremiumGetStartedClick = async () => {
    if (!userIsPremium) {
      try {
        if (!profileLoaded) {
          return;
        }

        setRedirectingToUpgrade(true);
        const product =
          k.PRICES[isDev() ? 'development' : 'production'].spacesMonthly?.key;
        const headers = getAuthenticatedHeaders();
        const { checkoutUrl } = await createCheckoutSessionRequest(
          product,
          headers
        );

        if (checkoutUrl) {
          window.location.replace(checkoutUrl);
        }
      } finally {
        setRedirectingToUpgrade(false);
      }
    }
  };

  return (
    <AdjustableAbsolutWrap>
      <div className={styles.wrapper}>
        <Header />
        <div className={styles.pricing_wrap}>
          <div className={styles.pricing_wrap_gradient_background}>
            <img
              src={k.CHAMU_HOME_GRADIENT_MIN}
              alt={'Gradient background homepage'}
            />
          </div>
          <div className={styles.pricing_title}>
            <h1>{'Simple pricing plans fit for your needs'}</h1>
          </div>
          <div className={styles.list}>
            <ul>
              <li>
                <PricingCard
                  title={i18n('pricing_premium_individual')}
                  description={i18n('pricing_premium_individual_desc')}
                >
                  <div className={styles.price_value}>
                    <div className={cx(styles.rate)}>
                      <h4>{'$ 0'}</h4>
                    </div>
                  </div>

                  <div className={styles.card_action}>
                    <Button
                      className={cx(
                        styles.card_action_button,
                        styles.card_action_personal
                      )}
                      type="button"
                      appearance="minimal"
                    >
                      <Link to={'/sign-up'}>{i18n('pricing_get_started')}</Link>
                    </Button>
                  </div>

                  <div className={styles.benefits}>
                    <ul>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_1')}
                        </p>
                        <h5>
                          {i18n('pricing_premium_individual_max_task_size')}
                        </h5>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_2')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_3')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_4')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_realtime_draft')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_reminders')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </PricingCard>
              </li>
              <li>
                <PricingCard
                  title={i18n('pricing_premium_teams')}
                  description={i18n('pricing_premium_teams_desc')}
                >
                  <div className={styles.price_value}>
                    <div className={styles.rate}>
                      <h4>
                        {i18n('pricing_premium_teams_price')}
                        <span>{`${i18n('pricing_premium_per_member')} / ${i18n(
                          'common_month'
                        )}`}</span>
                      </h4>
                    </div>
                    <div className={styles.rate_desc}>
                      {Markdown('pricing_plan_annually_or_month', {
                        price1: i18n('pricing_premium_teams_price_month'),
                      })}
                    </div>
                  </div>

                  <div className={styles.card_action}>
                    <Button
                      className={cx(
                        styles.card_action_button,
                        styles.card_action_premium
                      )}
                      appearance="minimal"
                    >
                      {(!isLoggedIn || userIsPremium) &&
                      !redirectingToUpgrade ? (
                        <Link
                          to={
                            isLoggedIn
                              ? '/user/account?section=plan&pay=premiumWithSpaces'
                              : '/sign-up?pay=premiumWithSpaces'
                          }
                        >
                          {i18n('pricing_get_started')}
                        </Link>
                      ) : (
                        <></>
                      )}
                      {!userIsPremium && isLoggedIn && !redirectingToUpgrade ? (
                        <p onClick={onPremiumGetStartedClick}>
                          {i18n('pricing_get_started')}{' '}
                        </p>
                      ) : (
                        <></>
                      )}
                      {redirectingToUpgrade && (
                        <Spinner height={16} width={16} />
                      )}
                    </Button>
                  </div>

                  <div className={styles.benefits}>
                    <ul>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_1')}
                        </p>
                        <h5>{i18n('pricing_premium_teams_max_task_size')}</h5>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_2')}
                        </p>
                      </li>

                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_5')}
                        </p>
                      </li>

                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_3')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_individual_benefit_4')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_teams_benefit_4')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_teams_benefit_5')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_premium_teams_benefit_6')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_realtime_draft')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_reminders')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_no_image_scaledown')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </PricingCard>
              </li>
              <li>
                <PricingCard
                  title={i18n('pricing_enterprise')}
                  description={i18n('pricing_enterprise_desc')}
                  className={styles.override_enterprise_card}
                >
                  <div className={styles.pricing_custom}>
                    <h5>{i18n('pricing_enterprise_custom_pricing')}</h5>
                    <h3>
                      {i18n('pricing_enterprise_custom_pricing_lets_talk')}{' '}
                    </h3>
                  </div>
                  <div className={styles.card_action}>
                    <Button
                      appearance="minimal"
                      className={cx(
                        styles.card_action_button,
                        styles.card_action_soon
                      )}
                    >
                      <p>{i18n('pricing_coming_soon')}</p>
                    </Button>
                  </div>

                  <div className={styles.benefits}>
                    <ul>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_enterprise_benefit_1')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_enterprise_benefit_2')}
                        </p>
                      </li>{' '}
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_enterprise_benefit_3')}
                        </p>
                      </li>
                      <li>
                        <div className={styles.check}>
                          <TickCircleIcon color="success" />
                        </div>
                        <p className={styles.benefit_desc}>
                          {i18n('pricing_enterprise_benefit_4')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </PricingCard>
              </li>
            </ul>
          </div>
          <Comaprison />
          <FAQ />
        </div>
        <Footer />
      </div>
    </AdjustableAbsolutWrap>
  );
};

export default withUserDataAndProfileSettings(Pricing);
