import cx from 'classnames';
import styles from './Checkbox.module.scss';
import { isBoolean, isFunction, isNumber, isString } from 'src/helpers/utils';

const Checkbox = (props) => {
  const {
    value,
    className = '',
    label = '',
    height,
    width,
    isThemeDarkMode,
  } = props;

  const validValue = isBoolean(value);
  const hasLabel = !!label;
  const onChange = (evt) => {
    if (isFunction(props?.onChange)) {
      props.onChange(evt);
    }
  };

  const onClick = (evt) => {
    if (isFunction(props?.onClick)) {
      props.onClick(evt);
    }
  };

  const applyHeight = isNumber(height) && height ? `${height}px` : '';
  const applyWidth = isNumber(width) && width ? `${width}px` : '';

  return (
    <div
      className={cx(styles.checkbox, styles.flex_row_xy, {
        [styles.checkbox_with_label]: hasLabel,
        [className]: isString(className) && className,
        [styles.checkbox_dark]: isThemeDarkMode,
        [styles.checkbox_light]: !isThemeDarkMode,
      })}
    >
      {validValue ? (
        <input
          onClick={onClick}
          type="checkbox"
          checked={value}
          onChange={onChange}
          {...(applyHeight && applyWidth
            ? {
                height: applyHeight,
                width: applyWidth,
              }
            : {})}
        />
      ) : (
        <></>
      )}
      {!validValue ? (
        <input
          type="checkbox"
          onClick={onClick}
          {...(applyHeight && applyWidth
            ? {
                height: applyHeight,
                width: applyWidth,
              }
            : {})}
        />
      ) : (
        <></>
      )}

      {hasLabel ? (
        isString(label) ? (
          <p onClick={onChange}> {label}</p>
        ) : (
          <h5 onClick={onChange}>{label}</h5>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Checkbox;
