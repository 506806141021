import cx from 'classnames';
import styles from './Editor.module.scss';
import './EditorComponent.scss';
import DragAndDropFile from './DragAndDropFile';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import ImageTiptap from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import YoutubeExtension from '@tiptap/extension-youtube';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import i18n from 'src/locales';
import TextAlign from '@tiptap/extension-text-align';
import FileExtension from './extensions/FileExtension';
import PDFViewerExtension from './extensions/PDFViewerExtension';
import TwitterExtension from './extensions/TwitterExtension';
import VideoExtension from './extensions/VideoExtension';
import LoomVideoExtension from './extensions/LoomVideoExtension';
import MentionExtended from './extensions/MentionExtended';
import EmptyContent from './EmptyContent';
import CodeBlockComponent from './extensions/CodeBlockComponent';

// tables related
import Table from '@tiptap/extension-table';
import TableCellExtended from './extensions/TableCellExtended';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TablesMenu, { TablesMenuContainerId } from './TablesMenu';
import { useDebounce, useWindowScroll } from 'src/hooks';
import { Editor as TipTapEditor } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { withNoteViewContext } from './Context';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  isEmpty,
  isFunction,
  isMobileView,
  isNumber,
  timeout,
  toLower,
  toString,
} from 'src/helpers/utils';

import css from 'highlight.js/lib/languages/css';
import js from 'highlight.js/lib/languages/javascript';
import ts from 'highlight.js/lib/languages/typescript';
import html from 'highlight.js/lib/languages/xml';

import { lowlight } from 'lowlight';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

lowlight.registerLanguage('html', html);
lowlight.registerLanguage('css', css);
lowlight.registerLanguage('js', js);
lowlight.registerLanguage('ts', ts);

// tests
// import { quillFormatToTiptap } from './helpers';
// import { testFileContent } from './constants';

const Editor = (props) => {
  const {
    isEditMode,
    isCreateMode,
    EditorInstance,
    title,
    storeEditorInstance,
    saveDraft: requestSaveDraft,
    saveUpdatedNote: requestSaveUpdatedNote,
    isForGettingStartedNote,
    loading,
    openImageViewer,
    showImageViewer,
    mode,
    descriptionUpdated,
    uploadFiles,
    isThemeDarkMode,
  } = props;
  const canModifyEditor = useMemo(
    () => isEditMode || isCreateMode,
    [isCreateMode, isEditMode]
  );
  const noteViewEditorId = 'noteViewEditorId';
  const editorWrapRef = useRef(null);
  const editorRef = useRef(null);
  const tablesMenuRef = useRef(null);
  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [showTablesMenu, setShowTablesMenu] = useState(false);
  const [tablesMenuView, setTablesMenuView] = useState({ x: 0, y: 0 });
  const { scrollY } = useWindowScroll();

  const onEditorFocus = async () => {
    if (isMobileView()) {
      await timeout(300);
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (!EditorInstance) {
      if (isFunction(storeEditorInstance)) {
        const currentEditor = new TipTapEditor({
          element: document.getElementById(noteViewEditorId),
          extensions: [
            // codeblock custom
            CodeBlockLowlight.extend({
              addNodeView() {
                return ReactNodeViewRenderer(CodeBlockComponent);
              },
            }).configure({ lowlight }),
            StarterKit.configure({
              code: false,
              codeBlock: false,
              heading: { levels: [1, 2, 3] },
              blockquote: {
                content: 'paragraph*',
              },
            }),
            // extend
            PDFViewerExtension,
            TwitterExtension,
            LoomVideoExtension,
            TaskList,
            TaskItem.configure({
              nested: true,
            }),
            Underline,
            TextAlign.configure({
              types: ['heading', 'paragraph'],
            }),
            YoutubeExtension.extend({
              addOptions() {
                return {
                  ...this.parent?.(),
                  height: '220px',
                  width: '320px',
                };
              },
            }),
            Link.configure({
              openOnClick: false,
            }),
            Placeholder.configure({
              placeholder: i18n('user_create_task_description_placeholder'),
            }),
            ImageTiptap,
            // custom
            VideoExtension,
            FileExtension,
            MentionExtended,

            // tables

            Table.configure({
              resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCellExtended,
          ],
          content: `<p></p>`,
          editable: canModifyEditor,
          autofocus: false,
          injectCSS: false,
        });

        currentEditor.setEditable(canModifyEditor);
        storeEditorInstance(currentEditor);
      }
    }

    if (!window?.EditorInstance && EditorInstance) {
      window.EditorInstance = EditorInstance;
    }
  }, [EditorInstance, mode, canModifyEditor, storeEditorInstance]);

  const saveDraft = useDebounce(() => {
    requestSaveDraft();
  }, 500);

  const saveUpdatedNote = useDebounce(() => {
    requestSaveUpdatedNote();
  }, 500);

  const onEditorUpdate = useCallback(
    (param) => {
      if (!isCreateMode && !isEditMode) {
        return;
      } else if (isCreateMode && isFunction(saveDraft)) {
        saveDraft();
      } else if (isEditMode && isFunction(saveUpdatedNote)) {
        if (param?.transaction?.meta?.history$?.redo) {
          requestSaveUpdatedNote(true);
        } else {
          saveUpdatedNote();
        }
      }
    },
    // eslint-disable-next-line
    [
      EditorInstance,
      isCreateMode,
      isEditMode,
      title,
      requestSaveUpdatedNote,
      saveDraft,
      saveUpdatedNote,
    ]
  );

  /**
   * Listen to editor changes/updates and save draft for create mode-
   * or save updated for edit mode
   */
  useEffect(() => {
    const editor = EditorInstance;

    if (editor) {
      editor.on('update', onEditorUpdate);
    }

    return () => {
      if (editor) {
        editor.off('update', onEditorUpdate);
      }
    };
  }, [
    EditorInstance,
    title,
    canModifyEditor,
    isCreateMode,
    isEditMode,
    saveDraft,
    saveUpdatedNote,
    onEditorUpdate,
  ]);

  /**
   * Track if content's empty
   */
  useEffect(() => {
    const editor = EditorInstance;
    const onEditorUpdate = () => {
      if (editorRef?.current) {
        const textContent = toString(editorRef?.current?.textContent)
          .trim()
          .replace(/\n/g, '');
        let description = null;
        let descrtipionInString = '';

        try {
          if (editor?.getJSON) {
            description = editor.getJSON();
            descrtipionInString = JSON.stringify(description);
          }
        } catch {}

        if (
          !textContent &&
          (!description ||
            !descrtipionInString ||
            !descrtipionInString?.length ||
            (descrtipionInString &&
              !descrtipionInString?.includes('img') &&
              !descrtipionInString?.includes('video') &&
              !descrtipionInString?.includes('loomPreview') &&
              !descrtipionInString?.includes('loom') &&
              !descrtipionInString?.includes('tiktok') &&
              !descrtipionInString?.includes('pdfViewer') &&
              !descrtipionInString?.includes('file') &&
              !descrtipionInString?.includes('twitter') &&
              !descrtipionInString?.includes('image') &&
              !descrtipionInString?.includes('youtube') &&
              !descrtipionInString?.includes('table'))) &&
          !editor?.storage?.characterCount?.characters()
        ) {
          setIsEmptyContent(true);
        } else {
          setIsEmptyContent(false);
        }
      }
    };

    if (editor) {
      editor.on('update', onEditorUpdate);
      onEditorUpdate();
    }

    return () => {
      if (editor) {
        editor.off('update', onEditorUpdate);
      }
    };
  }, [EditorInstance, title, canModifyEditor, isCreateMode, isEditMode]);

  /**
   * Save on page hide
   * - when user switches to a different tab
   * - when user closes the tab
   * - when user closes the window
   */
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document?.hidden && isMobileView()) {
        if (isCreateMode && isFunction(requestSaveDraft)) {
          requestSaveDraft(true);
        } else if (isEditMode && isFunction(requestSaveUpdatedNote)) {
          requestSaveUpdatedNote();
        }
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [
    descriptionUpdated,
    EditorInstance,
    title,
    canModifyEditor,
    isCreateMode,
    isEditMode,
    requestSaveDraft,
    requestSaveUpdatedNote,
  ]);

  /**
   * Safely save on unmount
   */
  useEffect(() => {
    return () => {
      requestSaveDraft(true);
      requestSaveUpdatedNote(true);
    };

    // eslint-disable-next-line
  }, []);

  const hideTablesMenuDebounced = useDebounce(() => {
    setShowTablesMenu(false);
    setTablesMenuView({ x: 0, y: 0 });

    if (tablesMenuRef?.current) {
      tablesMenuRef.current.style.top = '';
      tablesMenuRef.current.style.left = '';
    }
  }, 100);

  const showTablesMenuDebounced = useDebounce(() => {
    if (!canModifyEditor) {
      setShowTablesMenu(false);
    }

    setShowTablesMenu(true);
  }, 300);

  /**
   * Check if can show tables menu
   */
  const checkIfCanShowTablesMenu = useCallback(
    (editor) => {
      if (!editor && EditorInstance) {
        editor = EditorInstance;
      }

      if (!editor) {
        setShowTablesMenu(false);

        return;
      }

      if (
        editor.can().addColumnBefore() ||
        editor.can().addColumnAfter() ||
        editor.can().addRowBefore() ||
        editor.can().addRowAfter()
      ) {
        showTablesMenuDebounced();
      } else {
        hideTablesMenuDebounced();
      }
    },

    // eslint-disable-next-line
    [
      canModifyEditor,
      EditorInstance,
      showTablesMenu,
      tablesMenuView,
      hideTablesMenuDebounced,
      showTablesMenuDebounced,
    ]
  );

  /**
   * Zoom in images clicked, and hide table menu when needed
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      const target = evt?.target;

      if (
        target &&
        toLower(toString(target?.nodeName)) === 'img' &&
        editorRef?.current?.contains(target)
      ) {
        // expand image
        if (isFunction(openImageViewer) && !showImageViewer) {
          openImageViewer(evt?.target?.src || evt?.target?.url);
          hideTablesMenuDebounced();
        }

        return;
      }
      const tablesMenuDom = document.getElementById(TablesMenuContainerId);

      if (
        tablesMenuDom &&
        (target?.id === TablesMenuContainerId ||
          tablesMenuDom?.contains(target))
      ) {
        return;
      }

      if (
        target &&
        editorWrapRef?.current &&
        editorWrapRef?.current?.contains(target)
      ) {
        checkIfCanShowTablesMenu();
      } else {
        hideTablesMenuDebounced();
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [
    EditorInstance,
    showImageViewer,
    openImageViewer,
    showTablesMenu,
    canModifyEditor,
    checkIfCanShowTablesMenu,
    showTablesMenuDebounced,
    hideTablesMenuDebounced,
  ]);

  /**
   * When scrolling
   */
  useEffect(() => {
    if (!isMobileView()) {
      setShowTablesMenu(false);
    }
  }, [scrollY]);

  /**
   * On click show cta for table cells
   */
  useEffect(() => {
    const tableItemDoms =
      EditorInstance && !loading
        ? [
            ...editorRef?.current?.querySelectorAll('th'),
            ...editorRef?.current?.querySelectorAll('tr'),
          ]
        : null;

    const onFocusIn = (evt) => {
      const target = evt?.target;
      if (target) {
        const res = target?.getBoundingClientRect();
        // const targetClientWidth = target?.clientWidth;
        let resX = res?.x;
        let resY = res?.y;

        checkIfCanShowTablesMenu();

        if (isNumber(resY)) {
          setTablesMenuView({ x: resX, y: resY });

          const tablesMenuContainerHeight =
            tablesMenuRef?.current?.clientHeight;

          if (resX + 80 > window?.innerWidth && isMobileView()) {
            resX -= 82;
          }

          if (tablesMenuContainerHeight && resY > tablesMenuContainerHeight) {
            resY = resY - 30;

            if (!isMobileView()) {
              resY -= 8;
            }
          } else if (
            tablesMenuContainerHeight &&
            resY < tablesMenuContainerHeight &&
            isMobileView()
          ) {
            resY = 0;
          } else if (resY > 60) {
            resY -= 60;
          }

          const newTopValue = resY;
          const newLeftValue = resX;
          tablesMenuRef.current.style.top = `${newTopValue}px`;
          tablesMenuRef.current.style.left = `${newLeftValue}px`;
        }
      }
    };

    if (!isEmpty(tableItemDoms)) {
      for (let i = 0; i < tableItemDoms.length; i++) {
        const dom = tableItemDoms[i];

        if (dom) {
          dom.addEventListener('click', onFocusIn, false);
        }
      }
    }

    return () => {
      if (!isEmpty(tableItemDoms)) {
        for (let i = 0; i < tableItemDoms.length; i++) {
          const dom = tableItemDoms[i];

          if (dom) {
            dom.removeEventListener('click', onFocusIn, false);
          }
        }
      }
    };
  }, [
    canModifyEditor,
    EditorInstance,
    loading,
    showTablesMenu,
    tablesMenuView,
    showTablesMenuDebounced,
    hideTablesMenuDebounced,
    checkIfCanShowTablesMenu,
  ]);

  return (
    <div className={styles.editor} ref={editorWrapRef}>
      {!loading &&
      isEmptyContent &&
      !canModifyEditor &&
      !isForGettingStartedNote ? (
        <EmptyContent />
      ) : (
        <></>
      )}
      <div
        className={cx(styles.scrolling_container, {
          [styles.invisible]: loading,
          [styles.scrolling_container_view]: !canModifyEditor,
        })}
        id="noteViewEditorScrollingContainerId"
      >
        <div
          ref={editorRef}
          onFocus={onEditorFocus}
          onClick={onEditorFocus}
          className={cx(styles.editor_raw, 'editorRaw', {
            [styles.editor_raw_view]: !canModifyEditor,
            [styles.editing]: canModifyEditor,
            [styles.viewing]:
              !canModifyEditor && EditorInstance && !EditorInstance?.isEditable,
            [styles.editor_raw_dark]: isThemeDarkMode,
            editorRawDark: isThemeDarkMode,
          })}
          id={noteViewEditorId}
        ></div>
        <TablesMenu
          show={showTablesMenu && canModifyEditor}
          close={() => {
            setShowTablesMenu(false);
          }}
          getRef={() => tablesMenuRef}
        />
      </div>
      <div
        className={cx(styles.editor_loading, styles.flex_row_xy, {
          [styles.hide_element]: !loading,
        })}
      >
        <div
          className={cx(styles.skeleton, styles.skeleton_one, {
            [styles.skeleton_dark]: isThemeDarkMode,
          })}
        ></div>
        <div
          className={cx(styles.skeleton, styles.skeleton_two, {
            [styles.skeleton_dark]: isThemeDarkMode,
          })}
        ></div>
        <div
          className={cx(styles.skeleton, styles.skeleton_three, {
            [styles.skeleton_dark]: isThemeDarkMode,
          })}
        ></div>
      </div>
      <DragAndDropFile
        uploadFiles={uploadFiles}
        getEditorContainerDom={() => editorWrapRef?.current}
      />
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Editor));
