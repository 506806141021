import cx from 'classnames';
import styles from './EmptyContent.module.scss';
import ViewAndEditSwitch from './ViewAndEditSwitch';
import { WritingHandIcon } from 'src/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const EmptyContent = (props) => {
  const { targetNote, isThemeDarkMode } = props;

  return (
    <div
      className={cx(styles.flex_row_xy, styles.empty_content, {
        [styles.hide_element]: targetNote?.revalidating,
      })}
    >
      <div className={cx(styles.flex_column_xy, styles.content)}>
        <div
          className={cx(styles.flex_row_xy, styles.icon, {
            [styles.icon_dark]: isThemeDarkMode,
          })}
        >
          <WritingHandIcon />
        </div>
        <h3
          className={cx(styles.message, {
            [styles.message_dark]: isThemeDarkMode,
          })}
        >
          No content added
        </h3>
        <div className={cx(styles.flex_row_xy, styles.cta)}>
          <h5 className={styles.flex_row_xy}>
            To add content, click on{' '}
            <span>
              <ViewAndEditSwitch viewOnly />
            </span>
            to switch to Edit mode
          </h5>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(EmptyContent);
