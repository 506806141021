import styles from './MobileFloat.module.scss';
import cx from 'classnames';
import { ArrowUpIcon, DiscussionIcon } from 'src/icons';
import { Button } from 'evergreen-ui';
import { withNoteViewContext } from './Context';
import { NoteViewModalType } from 'src/types';
import { useWindowScroll, useWindowResize } from 'src/hooks';
import { useEffect, useState } from 'react';
import { isMobileView, isNumber } from 'src/helpers/utils';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const MobileFloat = (props) => {
  const {
    isViewMode,
    showModalType,
    noteViewDom,
    isForGettingStartedNote,
    commentsView,
    isThemeDarkMode,
  } = props;
  const { scrollY } = useWindowScroll();
  const { availableHeight } = useWindowResize();
  const [noteViewScrollTop, setNoteViewScrollTop] = useState(
    window.scrollY || 0
  );

  const showComments = () => {
    window.scrollTo(0, 0);

    showModalType(NoteViewModalType.comments);
  };

  const scrollToTop = () => {
    try {
      if (window.scrollY) {
        window.scrollTo(0, 0);
      }

      if (noteViewDom?.scrollTo) {
        noteViewDom.scrollTo(0, 0);
      }
    } catch {}
  };

  useEffect(() => {
    const onNoteViewScroll = () => {
      const scrollTop = noteViewDom?.scrollTop;

      if (isMobileView()) {
        setNoteViewScrollTop(scrollTop);
      } else {
        setNoteViewScrollTop(0);
      }
    };

    if (noteViewDom?.addEventListener) {
      // noteViewDom
      noteViewDom.addEventListener('scroll', onNoteViewScroll, false);
    }

    return () => {
      if (noteViewDom?.addEventListener) {
        noteViewDom.addEventListener('scroll', onNoteViewScroll, false);
      }
    };
  }, [availableHeight, isViewMode, noteViewDom]);

  const canCommentView = commentsView;

  return (
    <div className={cx(styles.mobile_float)}>
      <div className={cx(styles.content, styles.flex_column_xy)}>
        <Button
          className={cx(styles.flex_row_xy, styles.show_comments, {
            [styles.hide_element]:
              !isViewMode || isForGettingStartedNote || !canCommentView,
            [styles.show_comments_dark]: isThemeDarkMode,
          })}
          appearance="minimal"
          onClick={showComments}
        >
          <DiscussionIcon height={24} width={24} />
        </Button>
        {((availableHeight && scrollY > 20) ||
          (isNumber(noteViewScrollTop) && noteViewScrollTop > 60)) && (
          <Button
            className={cx(styles.flex_row_xy, styles.back_top, {
              [styles.back_top_dark]: isThemeDarkMode,
            })}
            appearance="minimal"
            onClick={scrollToTop}
          >
            <ArrowUpIcon height={16} width={16} />
            <p>{'Back to top'}</p>
          </Button>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(MobileFloat));
