import cx from 'classnames';
import styles from './LeftPanel.module.scss';
import { NoteViewModalType } from 'src/types';
import { withNoteViewContext } from './Context';
import { useEffect, useState } from 'react';
import {
  filter,
  isArray,
  isEmpty,
  isFunction,
  isMobileView,
  size,
} from 'src/helpers/utils';
import { CommentsContainerId } from './Comments';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withNoteDataSettings } from 'src/managers/notes';

const LeftPanel = (props) => {
  const {
    EditorInstance,
    title,
    uploadingFiles,
    taskId,
    uRef,
    loading,
    isCreateMode,
    isEditMode,
    showModalType,
    commentsHasNext,
    commentsCurrentPagesWithComments,
    isLoggedIn,
    isForGettingStartedNote,
    isThemeDarkMode,
  } = props;
  const canModifyTitle = isEditMode || isCreateMode;
  const commentsFormatted = filter(
    commentsCurrentPagesWithComments.reduce((acc, comments) => {
      if (isArray(comments)) {
        acc = [...acc, ...comments];
      }

      return acc;
    }, []),
    (comment) => !isEmpty(comment?.ref_id)
  );
  const totalComments = size(commentsFormatted);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (title && taskId && EditorInstance && uRef && !loading) {
      const description = EditorInstance.getJSON();
      const allFiles = [];

      if (description?.content?.length) {
        for (let i = 0; i < description.content.length; i++) {
          const currentNode = description.content[i];

          if (
            currentNode?.type === 'file' ||
            currentNode?.type === 'pdfviewer' ||
            currentNode?.type === 'image'
          ) {
            allFiles.push({ ...currentNode });
          }
        }

        setFiles(allFiles);
      } else {
        setFiles([]);
      }
    }
  }, [uploadingFiles, EditorInstance, taskId, uRef, loading, title]);

  const showActivities = () => {
    if (isFunction(showModalType)) {
      showModalType(NoteViewModalType.activities);
    }
  };

  const scrollToComments = () => {
    if (!isMobileView()) {
      const dom = document.getElementById(CommentsContainerId);

      if (dom) {
        dom.scrollIntoView();
      }
    }
  };

  /**
   * Todo files view
   */
  const showFiles = () => {};

  return (
    <div
      className={cx(styles.flex_column_xy, styles.left_panel, {
        [styles.hide_element]: loading || canModifyTitle,
      })}
    >
      <div
        className={cx(styles.flex_row_xy, styles.item_info, {
          [styles.hide_element]: !files?.length || isForGettingStartedNote,
        })}
        onClick={showFiles}
      >
        <div className={styles.item_info_label}>
          <p>{'Files'}</p>
        </div>
        <div
          className={cx(styles.item_info_content, {
            [styles.item_info_content_dark]: isThemeDarkMode,
          })}
        >
          <p>{files?.length}</p>
        </div>
      </div>

      <div
        className={cx(styles.flex_row_xy, styles.item_info, {
          [styles.hide_element]:
            isCreateMode || !isLoggedIn || isForGettingStartedNote,
        })}
      >
        <div className={styles.item_info_label} onClick={showActivities}>
          <p>{'Activity Log'}</p>
        </div>
      </div>
      <div
        className={cx(styles.flex_row_xy, styles.item_info, {
          [styles.hide_element]:
            canModifyTitle || !isLoggedIn || isForGettingStartedNote,
          [styles.item_info_content_dark]: isThemeDarkMode,
        })}
        onClick={scrollToComments}
      >
        <div className={styles.item_info_label}>
          <p>{'Comments'}</p>
        </div>
        <div
          className={cx(styles.item_info_content, {
            [styles.item_info_content_dark]: isThemeDarkMode,
          })}
        >
          <p>
            {totalComments
              ? `${totalComments}${commentsHasNext ? '+' : ''}`
              : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteDataSettings(withNoteViewContext(LeftPanel))
);
