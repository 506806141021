const BoldIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 9.16732H10.4167C10.9692 9.16732 11.4991 8.94782 11.8898 8.55712C12.2805 8.16642 12.5 7.63652 12.5 7.08398C12.5 6.53145 12.2805 6.00155 11.8898 5.61085C11.4991 5.22014 10.9692 5.00065 10.4167 5.00065H6.66667V9.16732ZM15 12.9173C15 13.4098 14.903 13.8974 14.7145 14.3524C14.5261 14.8073 14.2499 15.2207 13.9016 15.569C13.5534 15.9172 13.14 16.1934 12.6851 16.3819C12.2301 16.5703 11.7425 16.6673 11.25 16.6673H5V3.33398H10.4167C11.1508 3.33402 11.8688 3.54953 12.4816 3.9538C13.0944 4.35807 13.575 4.93332 13.8639 5.60821C14.1528 6.2831 14.2373 7.02795 14.1068 7.75039C13.9764 8.47284 13.6367 9.1411 13.13 9.67232C13.6989 10.0017 14.1711 10.475 14.4993 11.0445C14.8276 11.6141 15.0002 12.2599 15 12.9173ZM6.66667 10.834V15.0007H11.25C11.8025 15.0007 12.3324 14.7812 12.7231 14.3905C13.1138 13.9998 13.3333 13.4699 13.3333 12.9173C13.3333 12.3648 13.1138 11.8349 12.7231 11.4442C12.3324 11.0535 11.8025 10.834 11.25 10.834H6.66667Z"
        fill="#718096"
      />
    </svg>
  );
};

export default BoldIcon;
