import { Component, createContext, forwardRef } from 'react';
import { isFunction } from 'lodash';
import { withModalPromptSettings } from '../modal';
import { withUserDataAndProfileSettings } from '../profile';

import Toast from './Toast';
import Login from 'src/components/login';
import SignUp from 'src/components/sign-up';
import { isMobileView } from 'src/helpers/utils';

const { Consumer, Provider } = createContext();

export function withMobileToastSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class MobileToastManager extends Component {
  state = {
    dom: null,
    active: false,
    blockClosingMobileToast: false,
  };

  setActiveDomForMobileToast = (dom) => {
    if (dom) {
      const { closeModal } = this.props;

      if (!isMobileView()) {
        return;
      }

      this.setState(
        {
          dom,
          active: true,
        },
        () => {
          if (isFunction(closeModal)) {
            closeModal();
          }
        }
      );
    }
  };

  closeMobileToast = () => {
    const { blockClosingMobileToast } = this.state;

    if (blockClosingMobileToast) {
      return;
    }

    this.setState({
      dom: null,
      active: false,
    });
  };

  blockMobileToastClosing = () => {
    this.setState({
      blockClosingMobileToast: true,
    });
  };

  unblockMobileToastClosing = (close = false) => {
    this.setState(
      {
        blockClosingMobileToast: false,
      },
      () => {
        if (close) {
          this.closeMobileToast();
        }
      }
    );
  };

  showLoginPrompt = () => {
    const { setActiveModalDom } = this.props;

    if (isMobileView()) {
      this.setActiveDomForMobileToast(
        <Login
          showSignupPrompt={this.showSignupPrompt}
          closeMobileToast={this.closeMobileToast}
          blockMobileToastClosing={this.blockMobileToastClosing}
          unblockMobileToastClosing={this.unblockMobileToastClosing}
        />
      );
    } else if (isFunction(setActiveModalDom)) {
      setActiveModalDom(
        <Login
          showSignupPrompt={this.showSignupPrompt}
          closeMobileToast={this.closeMobileToast}
          blockMobileToastClosing={this.blockMobileToastClosing}
          unblockMobileToastClosing={this.unblockMobileToastClosing}
        />
      );
    }
  };

  showSignupPrompt = () => {
    const { setActiveModalDom } = this.props;

    if (window?.innerWidth < 800 || window?.screen?.availWidth < 800) {
      this.setActiveDomForMobileToast(
        <SignUp
          showLoginPrompt={this.showLoginPrompt}
          closeMobileToast={this.closeMobileToast}
          blockMobileToastClosing={this.blockMobileToastClosing}
          unblockMobileToastClosing={this.unblockMobileToastClosing}
        />
      );
    } else if (isFunction(setActiveModalDom)) {
      setActiveModalDom(
        <SignUp
          showLoginPrompt={this.showLoginPrompt}
          closeMobileToast={this.closeMobileToast}
          blockMobileToastClosing={this.blockMobileToastClosing}
          unblockMobileToastClosing={this.unblockMobileToastClosing}
        />
      );
    }
  };

  render() {
    const { children } = this.props;
    const { dom, active, blockClosingMobileToast } = this.state;
    const isMobileToastActive =
      (blockClosingMobileToast || active) && isMobileView();

    return (
      <Provider
        value={{
          isMobileToastActive,
          mobileToastDom: dom,
          showSignupPrompt: this.showSignupPrompt,
          showLoginPrompt: this.showLoginPrompt,
          blockMobileToastClosing: this.blockMobileToastClosing,
          unblockMobileToastClosing: this.unblockMobileToastClosing,
          setActiveDomForMobileToast: this.setActiveDomForMobileToast,
          closeMobileToast: this.closeMobileToast,
        }}
      >
        {children}
        {isMobileToastActive && (
          <Toast
            close={this.closeMobileToast}
            blockClosingMobileToast={blockClosingMobileToast}
            dom={dom}
          />
        )}
      </Provider>
    );
  }
}

export default withUserDataAndProfileSettings(
  withModalPromptSettings(MobileToastManager)
);
