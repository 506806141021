import { isFunction } from 'lodash';
import { useEffect } from 'react';

export const isSystemThemeDarkMode = () => {
  try {
    return (
      isFunction(window?.matchMedia) &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    );
  } catch {
    return false;
  }
};

const SystemThemeHelperComponent = (props) => {
  const { setSystemThemeIsDark, children, profileLoaded } = props;

  useEffect(() => {
    const onSystemThemeChange = () => {
      if (!profileLoaded) {
        return;
      }

      if (isFunction(setSystemThemeIsDark)) {
        setSystemThemeIsDark(isSystemThemeDarkMode());
      }
    };

    const canListenToSystemThemeChange = Boolean(
      isFunction(window?.matchMedia) &&
        isFunction(
          window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener
        )
    );

    if (canListenToSystemThemeChange) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', onSystemThemeChange);
    }

    return () => {
      if (canListenToSystemThemeChange) {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .removeEventListener('change', onSystemThemeChange);
      }
    };
  }, [setSystemThemeIsDark, profileLoaded]);

  return children || null;
};

export default SystemThemeHelperComponent;
