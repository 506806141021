const LinkIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M15.3033 12.9475L14.125 11.7675L15.3033 10.5892C15.693 10.2029 16.0026 9.74342 16.2142 9.23717C16.4259 8.73092 16.5354 8.18786 16.5366 7.63915C16.5378 7.09044 16.4307 6.5469 16.2212 6.03973C16.0118 5.53255 15.7043 5.07174 15.3163 4.68375C14.9283 4.29575 14.4675 3.98821 13.9603 3.77878C13.4531 3.56936 12.9096 3.46217 12.3609 3.46337C11.8122 3.46457 11.2691 3.57414 10.7628 3.78578C10.2566 3.99743 9.79713 4.30699 9.41083 4.69668L8.2325 5.87584L7.05333 4.69751L8.23333 3.51918C9.32735 2.42516 10.8112 1.81055 12.3583 1.81055C13.9055 1.81055 15.3893 2.42516 16.4833 3.51918C17.5773 4.6132 18.192 6.097 18.192 7.64418C18.192 9.19135 17.5773 10.6752 16.4833 11.7692L15.3033 12.9475ZM12.9467 15.3042L11.7675 16.4825C10.6735 17.5765 9.18967 18.1911 7.6425 18.1911C6.09532 18.1911 4.61152 17.5765 3.5175 16.4825C2.42348 15.3885 1.80887 13.9047 1.80887 12.3575C1.80887 10.8103 2.42348 9.32653 3.5175 8.23251L4.69667 7.05418L5.875 8.23418L4.69667 9.41251C4.30697 9.7988 3.99742 10.2583 3.78577 10.7645C3.57413 11.2708 3.46456 11.8138 3.46336 12.3625C3.46215 12.9112 3.56934 13.4548 3.77877 13.962C3.9882 14.4691 4.29574 14.9299 4.68373 15.3179C5.07173 15.7059 5.53254 16.0135 6.03971 16.2229C6.54689 16.4323 7.09043 16.5395 7.63914 16.5383C8.18784 16.5371 8.73091 16.4276 9.23716 16.2159C9.74341 16.0043 10.2029 15.6947 10.5892 15.305L11.7675 14.1267L12.9467 15.3042ZM12.3567 6.46501L13.5358 7.64418L7.64333 13.5358L6.46417 12.3575L12.3567 6.46501Z"
        fill="#718096"
      />
    </svg>
  );
};

export default LinkIcon;
