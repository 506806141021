import { useEffect, useState } from 'react';
import { isMobileView, isNumber } from 'src/helpers/utils';
import { AppRootContainerId } from 'src/pages';

export const useWindowScroll = () => {
  const [scrollY, setScrollY] = useState(window?.scrollY);

  const scrollToTop = () => {
    if (isMobileView()) {
      const dom = document.getElementById(AppRootContainerId);

      if (dom) {
        if (dom?.scroll) {
          dom.scroll({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          dom.scrollTo(0, 0);
        }
      }
    }

    if (window?.scrollY > 0 || scrollY > 0) {
      if (window?.scroll) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  useEffect(() => {
    const onScroll = () => {
      setScrollY(isNumber(window?.scrollY) ? window.scrollY : 0);
    };

    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [setScrollY]);

  return { scrollToTop, scrollY };
};

export default useWindowScroll;
