import { useState, useMemo } from 'react';
import cx from 'classnames';
import styles from './Failed.module.scss';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import k from 'src/constants/k';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, ArrowRightIcon, CrossIcon } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { createCheckoutSessionRequest } from 'src/managers/api/payments';
import { useAuth } from 'src/hooks';
import { Browser } from '@capacitor/browser';
import { isCapacitorMobileApp, isDev } from 'src/helpers/utils';

const PaymentFailed = (props) => {
  const { profileLoaded, isLoggedIn, userIsPremium, isThemeDarkMode } = props;
  const [tryAgain, setTryAgain] = useState(false);
  const [toRedirect, setToRedirect] = useState(false);

  const userIsLoggedIn = useMemo(
    () => profileLoaded && isLoggedIn,
    [profileLoaded, isLoggedIn]
  );

  const { getAuthenticatedHeaders } = useAuth(props);

  const onUpgrade = async () => {
    if (tryAgain) {
      return;
    } else {
      setTryAgain(true);

      if (!userIsLoggedIn || userIsPremium) {
        // already premium
        // or not logged in we redirect user
        setToRedirect(true);
      } else {
        const product =
          k.PRICES[isDev() ? 'development' : 'production'].spacesMonthly?.key;
        const headers = getAuthenticatedHeaders();
        const { checkoutUrl } = await createCheckoutSessionRequest(
          product,
          headers
        );

        if (checkoutUrl) {
          if (isCapacitorMobileApp()) {
            Browser.open({ url: checkoutUrl });
          } else {
            window.location.replace(checkoutUrl);
          }
        }
      }
    }
  };

  if (toRedirect && isLoggedIn) {
    return <Redirect to={'/user'} />;
  } else if (toRedirect && !isLoggedIn && profileLoaded) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className={cx(styles.payment_failed, styles.flex_row_xy)}>
      <div className={cx(styles.raw, { [styles.raw_dark]: isThemeDarkMode })}>
        <div className={cx(styles.failed)}>
          {!profileLoaded && (
            <div
              className={cx(styles.loading, styles.flex_row_xy, {
                [styles.loading_dark]: isThemeDarkMode,
              })}
            >
              <Spinner />
            </div>
          )}
          {profileLoaded && (
            <>
              <div className={cx(styles.failed_ic, styles.flex_row_xy)}>
                <div className={styles.flex_row_xy}>
                  <CrossIcon />
                </div>
              </div>
              <div className={styles.title}>
                <h3>{i18n('payment_failed')}</h3>
              </div>
              <div className={styles.message}>
                <h5>{i18n('payment_failed_message')}</h5>{' '}
              </div>
              <div className={styles.try_again}>
                <CustomButton
                  isThemeDarkMode={isThemeDarkMode}
                  onClick={onUpgrade}
                  loading={tryAgain}
                  className={styles.try_again_button}
                  variant={'primaryPurple'}
                  label={i18n('payment_failed_try_again')}
                />{' '}
              </div>
              {isLoggedIn && (
                <div className={styles.redirect}>
                  <Link to={'/user/account'}>
                    <h5> {i18n('payment_redirect_account_settings')}</h5>
                    <span>
                      <ArrowRightIcon />
                    </span>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(PaymentFailed);
