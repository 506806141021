import styles from './Tags.module.scss';
import cx from 'classnames';
import TagUnit from '../TagUnit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, CrossIcon } from 'evergreen-ui';
import { filter, head, isEmpty, isFunction, map } from 'lodash';
import { Spinner } from 'evergreen-ui';
import { withNoteViewContext } from '../Context';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import {
  attachTagsNoteRequest,
  removeTagsNoteRequest,
} from 'src/managers/api/notes';
import { useAuth } from 'src/hooks';
import { withSpacesAndUserSettings } from 'src/managers/spaces';

const Tags = (props) => {
  const {
    isCreateMode,
    closeModal,
    spaceId,
    uRef,
    personalTags: personalTagsSelected,
    spaceTags: spaceTagsSelected,
    userPersonalTags: personalTags,
    setSpaceTags,
    setPersonalTags,
    isThemeDarkMode,
    userSpaces,
    selectedSpaceId,
  } = props;
  const noteViewTagsListSelectClassname =
    'noteViewTagsListSelectClassnameUnique';

  const spaceTags = useMemo(() => {
    const find = head(
      filter(userSpaces, (spaceInfo) => spaceInfo?.id === selectedSpaceId)
    );

    if (!isEmpty(find)) {
      return map(find?.tags || [], (tag) => ({
        ...tag,
        spaceId: find?.id,
        spaceName: find?.name,
      }));
    }

    return [];
  }, [selectedSpaceId, userSpaces]);

  const personalTagIdsSelected = personalTagsSelected
    .map((tagInfo) => tagInfo?.id)
    .filter((tagId) => !isEmpty(tagId));
  const spaceTagIdsSelected = spaceTagsSelected
    .map((tagInfo) => tagInfo?.id)
    .filter((tagId) => !isEmpty(tagId));
  const tagsListRef = useRef(null);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [saving, setSaving] = useState(false);
  const { getAuthenticatedHeaders } = useAuth(props);
  const onClose = useCallback(async () => {
    try {
      setSaving(true);

      const headers = getAuthenticatedHeaders();
      const spaceTagsToAdd = tagsToAdd.filter(
        (tagInfo) => tagInfo?.id && (tagInfo?.space || tagInfo?.spaceId)
      );
      const spaceTagsToRemove = tagsToRemove.filter(
        (tagInfo) => tagInfo?.id && (tagInfo?.space || tagInfo?.spaceId)
      );
      const personalTagsToAdd = tagsToAdd.filter(
        (tagInfo) => tagInfo?.id && !tagInfo?.space && !tagInfo?.spaceId
      );
      const personalTagsToRemove = tagsToRemove.filter(
        (tagInfo) => tagInfo?.id && !tagInfo?.space && !tagInfo?.spaceId
      );
      const tagIdsToRemove = tagsToRemove
        .map((tagInfo) => tagInfo?.id || '')
        .filter((tagId) => !isEmpty(tagId));
      const newUpdatedPersonalTags = [
        ...personalTagsSelected,
        ...personalTagsToAdd,
      ];
      const newUpdatedSpaceTags = [...spaceTagsSelected, ...spaceTagsToAdd];

      if (!isEmpty(personalTagsToAdd) && !isCreateMode) {
        await attachTagsNoteRequest(uRef, newUpdatedPersonalTags, '', headers);
      }

      if (personalTagsToRemove?.length && !isCreateMode) {
        await removeTagsNoteRequest(uRef, personalTagsToRemove, '', headers);
      }

      if (
        isFunction(setPersonalTags) &&
        (!isEmpty(personalTagsToAdd) || !isEmpty(personalTagsToRemove))
      ) {
        // update local copy
        setPersonalTags(
          newUpdatedPersonalTags.filter(
            (tagInfo) => tagInfo?.id && !tagIdsToRemove?.includes(tagInfo?.id)
          )
        );
      }

      if (
        spaceTagsToAdd?.length &&
        spaceId &&
        spaceId !== 'personal' &&
        !isCreateMode
      ) {
        await attachTagsNoteRequest(
          uRef,
          newUpdatedSpaceTags,
          spaceId,
          headers
        );
      }

      if (
        spaceTagsToRemove?.length &&
        spaceId &&
        spaceId !== 'personal' &&
        !isCreateMode
      ) {
        await removeTagsNoteRequest(uRef, spaceTagsToRemove, spaceId, headers);
      }

      if (
        isFunction(setSpaceTags) &&
        (!isEmpty(spaceTagsToAdd) || !isEmpty(spaceTagsToRemove))
      ) {
        setSpaceTags(
          newUpdatedSpaceTags.filter(
            (tagInfo) => tagInfo?.id && !tagIdsToRemove?.includes(tagInfo?.id)
          )
        );
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      setSaving(false);
      if (isFunction(closeModal)) {
        closeModal();
      }
    }
  }, [
    getAuthenticatedHeaders,
    uRef,
    spaceId,
    tagsToAdd,
    tagsToRemove,
    closeModal,
    personalTagsSelected,
    spaceTagsSelected,
    setPersonalTags,
    setSpaceTags,
    isCreateMode,
  ]);

  const onToggleTargetId = useCallback(
    (targetTagId = '') => {
      const findPersonalTag = personalTags.filter(
        (tagInfo) => tagInfo?.id === targetTagId
      );
      const findSpaceTag = spaceTags.filter(
        (tagInfo) => tagInfo?.id === targetTagId
      );
      const tagInfo = head(findPersonalTag) || head(findSpaceTag);

      if (!tagInfo) {
        return;
      }

      const tagIdsToAdd = tagsToAdd
        .map((tagInfo) => tagInfo?.id)
        .filter((tagId) => !isEmpty(tagId));
      const tagIdsToRemove = tagsToRemove
        .map((tagInfo) => tagInfo?.id)
        .filter((tagId) => !isEmpty(tagId));
      const alreadyAttached =
        personalTagIdsSelected.includes(targetTagId) ||
        spaceTagIdsSelected.includes(targetTagId);
      const max = 8;

      if (!tagIdsToAdd.includes(targetTagId) && !alreadyAttached) {
        if (
          tagsToAdd?.length +
            personalTagsSelected?.length +
            spaceTagsSelected?.length >=
          max
        ) {
          tagsToAdd.shift();
        }

        setTagsToAdd([...tagsToAdd, tagInfo]);

        if (tagIdsToRemove.includes(targetTagId)) {
          setTagsToRemove(
            filter(tagsToRemove, (tagInfo) => tagInfo?.id !== targetTagId)
          );
        }

        return;
      }

      if (tagIdsToAdd.includes(targetTagId) && !alreadyAttached) {
        const updated = filter(
          tagsToAdd,
          (tagInfo) => tagInfo?.id !== targetTagId
        );
        const sanitized = [];

        for (let i = 0; i < updated.length; i++) {
          const tagInfo = updated[i];

          if (sanitized?.length < max) {
            sanitized.push(tagInfo);
          }
        }

        setTagsToAdd(sanitized);

        return;
      }

      if (tagIdsToRemove.includes(targetTagId) && alreadyAttached) {
        setTagsToRemove(
          filter(tagsToRemove, (tagInfo) => tagInfo?.id !== targetTagId)
        );

        return;
      }

      if (!tagIdsToRemove.includes(targetTagId) && alreadyAttached) {
        setTagsToRemove([...tagsToRemove, tagInfo]);

        if (tagIdsToAdd.includes(targetTagId)) {
          setTagsToAdd(
            filter(tagsToAdd, (tagInfo) => tagInfo?.id !== targetTagId)
          );
        }

        return;
      }
    },
    [
      personalTags,
      spaceTags,
      tagsToAdd,
      tagsToRemove,
      personalTagsSelected,
      spaceTagsSelected,
      personalTagIdsSelected,
      spaceTagIdsSelected,
    ]
  );

  /**
   * Close on oustide click
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (
        evt?.target?.classList?.length &&
        evt?.target?.classList?.contains(styles.tags)
      ) {
        if (tagsToRemove?.length || tagsToAdd?.length) {
          onClose();
        } else if (isFunction(closeModal)) {
          closeModal();
        }
      } else if (
        evt?.target?.classList?.length > 1 &&
        evt?.target?.classList?.contains(noteViewTagsListSelectClassname)
      ) {
        const targetTagId =
          evt.target.classList[evt.target.classList.length - 1];

        if (targetTagId && targetTagId !== noteViewTagsListSelectClassname) {
          // toggle
          onToggleTargetId(targetTagId);
        }
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [
    personalTags,
    closeModal,
    spaceTags,
    tagsToAdd,
    tagsToRemove,
    onClose,
    onToggleTargetId,
  ]);

  return (
    <div className={cx(styles.flex_row_xy, styles.tags)}>
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        {!saving ? (
          <div
            className={cx(styles.title, {
              [styles.title_dark]: isThemeDarkMode,
            })}
          >
            <h2>{'Tags'}</h2>
          </div>
        ) : (
          <></>
        )}
        {saving ? (
          <div
            className={cx(styles.flex_row_xy, styles.saving, {
              [styles.saving_dark]: isThemeDarkMode,
            })}
          >
            <Spinner height={24} width={24} />
          </div>
        ) : (
          <></>
        )}
        {!saving ? (
          <div
            className={cx(styles.list, { [styles.list_dark]: isThemeDarkMode })}
          >
            <ul ref={tagsListRef}>
              {personalTags.map((tagInfo) => {
                const tagId = tagInfo?.id;
                const isActive =
                  (personalTagIdsSelected.includes(tagId) ||
                    spaceTagIdsSelected.includes(tagId) ||
                    tagsToAdd.filter((tagInfo) => tagInfo?.id === tagId)
                      ?.length > 0) &&
                  !tagsToRemove.filter((tagInfo) => tagInfo?.id === tagId)
                    ?.length;

                return (
                  <li key={`noteViewSelectTagUnit${tagId}`}>
                    <TagUnit isActive={isActive} tag={tagInfo} />
                    <div
                      className={cx(
                        styles.cover,
                        noteViewTagsListSelectClassname,
                        tagId
                      )}
                    ></div>
                  </li>
                );
              })}

              {spaceTags.map((tagInfo) => {
                const tagId = tagInfo?.id;
                const isActive =
                  (personalTagIdsSelected.includes(tagId) ||
                    spaceTagIdsSelected.includes(tagId) ||
                    tagsToAdd.filter((tagInfo) => tagInfo?.id === tagId)
                      ?.length > 0) &&
                  !tagsToRemove.filter((tagInfo) => tagInfo?.id === tagId)
                    ?.length;

                return (
                  <li key={`noteViewSelectTagUnit${tagId}`}>
                    <TagUnit isActive={isActive} tag={tagInfo} />
                    <div
                      className={cx(
                        styles.cover,
                        noteViewTagsListSelectClassname,
                        tagId
                      )}
                    ></div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(styles.flex_row_xy, styles.close, {
            [styles.hide_element]: saving,
            [styles.close_dark]: isThemeDarkMode,
          })}
        >
          <Button
            onClick={() => {
              if (tagsToRemove?.length || tagsToAdd?.length) {
                onClose();
              } else if (isFunction(closeModal)) {
                closeModal();
              }
            }}
            appearance="minimal"
            className={styles.flex_row_xy}
          >
            <CrossIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withNoteViewContext(Tags))
);
