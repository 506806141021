const ArrowUpIcon = (p) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M6.69103 3.82058V13.0967H5.16687V3.82058L1.07905 7.90839L0.00146484 6.8308L5.92895 0.90332L11.8564 6.8308L10.7788 7.90839L6.69103 3.82058Z"
        fill="#718096"
      />
    </svg>
  );
};

export default ArrowUpIcon;
