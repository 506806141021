import { Component, createContext, forwardRef } from 'react';
import NetworkStatusHelper from './NetworkStatusHelper';

const { Consumer, Provider } = createContext();

export function withNetworkSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class NetworkManager extends Component {
  state = {
    isUserNetworkOffline: false,
  };

  markUserIsOffline = () => {
    this.setState({
      isUserNetworkOffline: true,
    });
  };

  markUserIsOnline = () => {
    this.setState({
      isUserNetworkOffline: false,
    });
  };

  render() {
    const { isUserNetworkOffline } = this.state;
    const props = {
      isUserNetworkOffline,
      markUserIsOnline: this.markUserIsOnline,
      markUserIsOffline: this.markUserIsOffline,
    };
    const { children } = this.props;

    return (
      <Provider value={props}>
        <NetworkStatusHelper {...props}>{children}</NetworkStatusHelper>
      </Provider>
    );
  }
}

export default NetworkManager;
