import { Component, createContext, forwardRef } from 'react';
import { isNil } from 'lodash';
import ModalWrapper from './ModalWrapper';
import { withUserDataAndProfileSettings } from '../profile';
import { withNoteDataSettings } from '../notes';
import { applyDarkThemeColor, applyLightThemeColor } from 'src/helpers/theme';

const { Consumer, Provider } = createContext({
  closeModal: () => null,
  setActiveModalDom: () => null,
  dom: null,
  domAction: null,
  domTitle: '',
});

export function withModalPromptSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class ModalPromptManager extends Component {
  state = {
    dom: null,
    domTitle: null,
    domAction: null,
    active: false,
    blockClosing: false,
  };

  checkTheme = () => {
    const { active, blockClosing } = this.state;
    const { isThemeDarkMode } = this.props;
    const withModal = active || blockClosing;

    if (isThemeDarkMode) {
      applyDarkThemeColor(withModal);
    } else {
      applyLightThemeColor(withModal);
    }
  };

  /**
   * Set react doms to be contained in a modal wrapper
   * @param dom
   * @param domAction
   * @param domTitle
   * @returns
   */
  setActiveModalDom = (dom = null, domAction = null, domTitle = '') => {
    if (isNil(dom)) {
      return;
    }

    this.setState(
      {
        active: true,
        dom,
        domAction: domAction || null,
        domTitle: domTitle || null,
      },
      this.checkTheme
    );
  };

  /**
   * Close modal
   */
  closeModal = (forceClose = false) => {
    this.setState(
      {
        active: false,
        dom: null,
        domAction: null,
        domTitle: '',
        ...(forceClose && { blockClosing: false }),
      },
      this.checkTheme
    );
  };

  blockClosingOfModal = () => {
    this.setState(
      {
        blockClosing: true,
      },
      this.checkTheme
    );
  };

  unBlockClosingOfModal = () => {
    this.setState({
      blockClosing: false,
    });
  };

  render() {
    const { children } = this.props;
    const { active, dom, domAction, domTitle, blockClosing } = this.state;
    const props = {
      dom,
      domAction,
      domTitle,
      blockClosing,
      modalActive: active,
      blockClosingOfModal: this.blockClosingOfModal,
      unBlockClosingOfModal: this.unBlockClosingOfModal,
      setActiveModalDom: this.setActiveModalDom,
      closeModal: this.closeModal,
    };

    return (
      <Provider value={props}>
        <ModalWrapper {...props}>{children}</ModalWrapper>
      </Provider>
    );
  }
}

export default withUserDataAndProfileSettings(
  withNoteDataSettings(ModalPromptManager)
);
