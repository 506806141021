import cx from 'classnames';
import styles from './SavedChanges.module.scss';
import { CheckSuccessIcon } from 'src/icons';

const SavedChanges = () => {
  return (
    <div className={cx(styles.flex_row_xy, styles.saved_changes)}>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        <div className={cx(styles.flex_row_xy, styles.icon)}>
          <CheckSuccessIcon height={20} width={20} />
        </div>
        <p>Changes saved.</p>
      </div>
    </div>
  );
};

export default SavedChanges;
