import { isEmpty, trim } from 'lodash';
import { isNetworkError } from '.';
import { axiosDeleteRequest, axiosPostRequest, axiosPutRequest } from './axios';
import { getSpacesApiPathname, getUsersApiPathname } from './urls';

export async function createNewPersonalTagRequest(name = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let tagInfo = null;
  let tagId = '';

  try {
    const url = getUsersApiPathname() + '/personal/tag/new';
    const res = await axiosPostRequest(url, headers, { tag: trim(name) });
    const resData = res?.data;

    if (
      (res?.status === 200 || res?.status === 201) &&
      !isEmpty(resData?.tagInfo)
    ) {
      success = true;
      tagInfo = resData?.tagInfo;
      tagId = resData?.id;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    tagId,
    success,
    tagInfo,
    networkError,
    errorMessage,
  };
}

export async function editPersonalTagRequest(
  tagId = '',
  newTagName = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let tagInfo = null;

  try {
    const id = trim(tagId);
    const newName = trim(newTagName);
    const url = getUsersApiPathname() + '/personal/tag';
    const res = await axiosPutRequest(url, headers, { id, newName });
    const resData = res?.data;

    if (
      (res?.status === 200 || res?.status === 201) &&
      !isEmpty(resData?.tagInfo)
    ) {
      success = true;
      tagInfo = resData?.tagInfo;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    success,
    tagInfo,
    networkError,
    errorMessage,
  };
}

export async function removePersonalTagRequest(tagId = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getUsersApiPathname() + '/personal/tag';
    const res = await axiosDeleteRequest(url, headers, { tag: trim(tagId) });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, success, errorMessage };
}

export async function createNewSpaceTagRequest(
  name = '',
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let tagInfo = null;

  try {
    const url = getSpacesApiPathname() + '/tags/new';
    const res = await axiosPostRequest(url, headers, {
      tags: [{ name: trim(name) }],
      id: trim(spaceId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.tagInfo) {
      tagInfo = resData.tagInfo;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    success,
    networkError,
    errorMessage,
    tagInfo,
  };
}

export async function editSpaceTagRequest(
  tagId = '',
  newTagName = '',
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/tags/edit';
    const res = await axiosPostRequest(url, headers, {
      tags: [{ name: trim(newTagName), id: trim(tagId) }],
      id: spaceId,
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    success,
    errorMessage,
    networkError,
  };
}

export async function removeSpaceTagRequest(
  tagId = '',
  spaceId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getSpacesApiPathname() + '/tags';
    const res = await axiosDeleteRequest(url, headers, {
      tags: [{ id: trim(tagId) }],
      id: trim(spaceId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    success,
    errorMessage,
    networkError,
  };
}
