import { Component, createContext, forwardRef } from 'react';
import { isEmpty, isString, isUndefined, toString } from 'lodash';
import * as localForage from 'localforage';

const { Consumer, Provider } = createContext();

export function withOfflineStorageAndUserSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class StorageManager extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    try {
      localForage.config({
        driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name: 'chamuAppOffline',
        storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
        description: 'chamuAppOfflineStorage',
      });
    } catch (err) {
      console.log(`localForage.config on mount err ${err?.message}`);
    } finally {
      this.setState({
        mounted: true,
      });
    }
  }

  setStorageItem = async (key = '', value = {}) => {
    if (!isUndefined(value) && !isEmpty(key) && isString(key)) {
      await localForage.setItem(key, value);
    }
  };

  deleteStorageItem = async (key = '') => {
    if (!isEmpty(key)) {
      await localForage.removeItem(toString(key || ''));
    }
  };

  getStorageItem = async (key = '') => {
    return await localForage.getItem(key);
  };

  render() {
    const { children } = this.props;
    const { mounted } = this.state;
    const props = {
      storageMounted: mounted,
      setStorageItem: this.setStorageItem,
      getStorageItem: this.getStorageItem,
      deleteStorageItem: this.deleteStorageItem,
    };

    return <Provider value={props}>{children}</Provider>;
  }
}

export default StorageManager;
