import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core';
import {
  LOOM_SHARE_REGEX_GLOBAL,
  appendHttpsToUrl,
  isValidSharedLoomVideo,
} from 'src/helpers/urls';
import { isValidObject, toString } from 'src/helpers/utils';

const LoomVideoExtension = Node.create({
  name: 'loomPreview',

  addOptions() {
    return {
      nested: false,
      draggable: false,
      addPasteHandler: true,
      width: '100%',
      HTMLAttributes: {},
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-type': 'loomPreview' }, HTMLAttributes),
      ['iframe'],
    ];
  },

  addCommands() {
    return {
      setLoomShare:
        (options) =>
        ({ commands }) => {
          const src = options?.src || options?.url;

          if (!isValidSharedLoomVideo(src)) {
            return false;
          }

          if (commands) {
            return commands.insertContent({
              type: 'loomPreview',
              attrs: { url: src, type: 'loomPreview' },
            });
          }

          return false;
        },
    };
  },

  addAttributes() {
    return {
      type: {
        default: '',
        parseHTML: () => 'loomPreview',
        renderHTML: () => ({
          'data-type': 'loomPreview',
        }),
      },
      url: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-url') || '',
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="loomPreview"]`,
      },
    ];
  },

  draggable: false,

  group: 'block',

  inline: false,

  atom: true,

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: LOOM_SHARE_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          const url = toString(match?.input);
          let urlSanitized = url.substring(
            0,
            url.includes('?') ? url.indexOf('?') : url.length
          );
          urlSanitized = urlSanitized.replace(/\/share\//, '/embed/');
          urlSanitized = `${urlSanitized}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`;
          urlSanitized = appendHttpsToUrl(urlSanitized);

          return { url: urlSanitized, type: 'loomPreview' };
        },
      }),
    ];
  },

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const divLoom = document.createElement('div');
      const url = HTMLAttributes['data-url'];
      const { innerWidth } = window;

      divLoom.innerHTML = `
      <iframe
      src=${url}
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      style="position: relative; width: ${
        innerWidth > 415 ? '410px' : '310px'
      }; height: ${innerWidth > 415 ? '270px' : '205px'}"
    ></iframe>
      `;
      divLoom.setAttribute('data-type', 'loomPreview');

      if (isValidObject(HTMLAttributes)) {
        Object.entries(HTMLAttributes).forEach(([key, value]) => {
          divLoom.setAttribute(key, value);
        });
      }

      if (isValidObject(this.options.HTMLAttributes)) {
        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          divLoom.setAttribute(key, value);
        });
      }

      return {
        dom: divLoom,
      };
    };
  },
});

export default LoomVideoExtension;
