import { lazy, Suspense, useMemo } from 'react';
import styles from './User.module.scss';
import LogoLoadV2 from 'src/components/logo-load-v2';
import Header from 'src/layout/header';
import InitUserLoading from 'src/components/init-user';
import Dashboard from './dashboard';
import UserNotifications from './notifications';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { isMobileView } from 'src/helpers/utils';

const UserAccountSettingsV2 = lazy(() => import('./account-settings-v2'));
const NotFound = lazy(() => import('../not-found'));

const UserDefault = (props) => {
  const { profileLoaded, isLoggedIn } = props;

  const userIsLoggedIn = useMemo(
    () => isLoggedIn && profileLoaded,
    [profileLoaded, isLoggedIn]
  );

  if (!profileLoaded) {
    return <InitUserLoading />;
  }

  if (!userIsLoggedIn) {
    if (isMobileView()) {
      return <Redirect to={'/login'} />;
    }

    return <Redirect to={'/'} />;
  }

  return (
    <Suspense fallback={<LogoLoadV2 />}>
      <Switch>
        <Route exact path={'/user'}>
          <Dashboard />
        </Route>
        <Route path={'/user/account'}>
          <div className={styles.user_main_wrap}>
            <Header />
            <UserAccountSettingsV2 />
          </div>
        </Route>
        <Route path={'/user/notifications'}>
          <div className={styles.user_main_wrap}>
            <Header />
            <UserNotifications />
          </div>
        </Route>
        <Route path={'/not-found'}>
          <div className={styles.user_main_wrap}>
            <Header />
            <NotFound hideLogin />
          </div>
        </Route>{' '}
        <Route>
          <div className={styles.user_main_wrap}>
            <Header />
            <NotFound hideLogin />
          </div>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withUserDataAndProfileSettings(UserDefault);
