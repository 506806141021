import cx from 'classnames';
import styles from './NotFound.module.scss';
import k from 'src/constants/k';
import i18n from 'src/locales';
import LogoIconWord from 'src/components/logo-icon-word';
import { Button, ArrowRightIcon } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withModalPromptSettings } from 'src/managers/modal';
import Login from 'src/components/login';

const NotFound = (props) => {
  const { setActiveModalDom, isThemeDarkMode, hideLogin, isLoggedIn } = props;

  const showLoginModal = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<Login />);
    }
  };

  return (
    <div className={cx(styles.not_found, styles.flex_row_xy)}>
      <div
        className={cx(styles.not_found_background, {
          [styles.not_found_background_dark]: isThemeDarkMode,
        })}
      >
        <img
          className={cx({ [styles.hide_element]: isThemeDarkMode })}
          src={k.CHAMU_PAGE_NOT_FOUND_BACKGROUND}
          alt={'not found page background'}
        />
      </div>
      <div
        className={cx(styles.content, {
          [styles.content_dark]: isThemeDarkMode,
        })}
      >
        <div className={styles.logo}>
          <LogoIconWord darkMode={isThemeDarkMode} size="small" />{' '}
        </div>
        <div
          className={cx(styles.texts, { [styles.texts_dark]: isThemeDarkMode })}
        >
          <h2>Page Not Found</h2>
          <p>Error 404</p>
          <section>
            <p>This is due to any of the following:</p>
            <ul>
              <li>The page does not exist.</li>
              <li>The page has been moved to another link.</li>
              <li>You do not have access to this page.</li>
            </ul>
            <p>
              We recommend logging in to your account when accessing the link
              just in case.{' '}
            </p>
          </section>

          <div className={styles.cta}>
            <Button
              className={cx({
                [styles.hide_element]: hideLogin || isLoggedIn,
              })}
              appearance="minimal"
              onClick={showLoginModal}
            >
              <p>{i18n('common_login_verb')} </p>
            </Button>
            <div className={cx(styles.flex_row_xy, styles.return)}>
              <Link to={'/'}>Back to Home</Link>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(NotFound)
);
