const MediumPriorityIcon = (p) => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M0.25 0.75H8.85017C9.02036 0.750091 9.18716 0.79756 9.3319 0.88709C9.47664 0.97662 9.59359 1.10468 9.66967 1.25692L10.3333 2.58333H15.8333C16.0764 2.58333 16.3096 2.67991 16.4815 2.85182C16.6534 3.02373 16.75 3.25689 16.75 3.5V13.5833C16.75 13.8264 16.6534 14.0596 16.4815 14.2315C16.3096 14.4034 16.0764 14.5 15.8333 14.5H9.98317C9.81298 14.4999 9.64617 14.4524 9.50143 14.3629C9.3567 14.2734 9.23974 14.1453 9.16367 13.9931L8.5 12.6667H2.08333V18.1667H0.25V0.75Z"
        fill="#F58108"
      />
    </svg>
  );
};

export default MediumPriorityIcon;
