import cx from 'classnames';
import styles from './TitleContent.module.scss';
import Priority from './Priority';
import TagsPreview from './TagsPreview';
import SubscribersPreview from './SubscribersPreview';
import MessageHover from 'src/components/message-hover';
import k from 'src/constants/k';
import { withNoteViewContext } from './Context';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'src/hooks';
import {
  getUserDisplayName,
  getUserIdFromObject,
  isMobileView,
} from 'src/helpers/utils';
import { isEmpty, isFunction, toString } from 'lodash';
import { VipCrownIcon } from 'src/icons';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar } from '@chakra-ui/react';

const TitleContent = (props) => {
  const {
    isCreateMode,
    isEditMode,
    loading,
    authorId = '',
    author = {},
    authorInfo,
    views,
    updatedDateTime,
    showFilesView,
    profileLoaded,
    authorIsPremium,
    EditorInstance,
    isThemeDarkMode,
    userIsPremium,
    user,
  } = props;
  const userAuthorIsPremium = useMemo(() => {
    return (
      authorIsPremium ||
      ((getUserIdFromObject(user) === authorId ||
        getUserIdFromObject(authorInfo) === getUserIdFromObject(user)) &&
        profileLoaded &&
        userIsPremium)
    );
  }, [
    userIsPremium,
    profileLoaded,
    authorInfo,
    authorId,
    authorIsPremium,
    user,
  ]);
  const authorDisplayName = useMemo(() => getUserDisplayName(author), [author]);
  const [title, setTitle] = useState(props?.title || '');
  const canModifyTitle = isEditMode || isCreateMode;
  const lastModifiedFormatted = useMemo(() => {
    if (updatedDateTime) {
      let utcDate = new Date(updatedDateTime);
      let fullYear = utcDate.getFullYear();

      if (fullYear < 2000) {
        utcDate = new Date(updatedDateTime * 1000);
        fullYear = utcDate.getFullYear();
      }

      return `${
        k.MONTHS_INDEX[utcDate?.getMonth()]?.abv
      } ${utcDate.getDate()}, ${toString(fullYear)
        .split('')
        .slice(fullYear.length - 2, fullYear.length)
        .join('')}`;
    }

    return '';
  }, [updatedDateTime]);

  const updateGlobalTitleCopy = useDebounce((latestTitle = '') => {
    if (isFunction(props?.setTitle)) {
      props.setTitle(latestTitle);
    }
  }, 400);

  const onUpdateTitle = (evt) => {
    if (!evt?.target) {
      return;
    }

    const value = evt?.target?.value;
    setTitle(value);
    updateGlobalTitleCopy(value);
  };

  /**
   * Set title on first load
   */
  useEffect(() => {
    if (!loading) {
      setTitle(toString(props?.title));
    }
    // eslint-disable-next-line
  }, [loading]);

  /**
   * Keydown evt
   * - On enter key on mobile, move the focus to the body/description
   */
  useEffect(() => {
    const canModify = isCreateMode || isEditMode;
    const onKeydown = (evt) => {
      if (
        evt?.keyCode === 13 &&
        isMobileView() &&
        EditorInstance &&
        canModify
      ) {
        EditorInstance.commands.focus();
      }
    };

    window.addEventListener('keydown', onKeydown, false);

    return () => {
      window.removeEventListener('keydown', onKeydown, false);
    };
  }, [loading, EditorInstance, isCreateMode, isEditMode]);

  return (
    <div
      className={cx(styles.flex_column_xy, styles.title_content, {
        [styles.hide_element]: showFilesView,
      })}
    >
      <div
        className={cx(styles.title_content_loading, {
          [styles.hide_element]: !loading,
        })}
      >
        <div
          className={cx(styles.skeleton, styles.skeleton_one, {
            [styles.skeleton_dark]: isThemeDarkMode,
          })}
        ></div>
        <div
          className={cx(styles.skeleton, styles.skeleton_two, {
            [styles.skeleton_dark]: isThemeDarkMode,
          })}
        ></div>
      </div>
      {loading ? (
        <></>
      ) : (
        <>
          <div className={cx(styles.flex_row_xy, styles.priority)}>
            <div
              className={cx(styles.flex_row_xy, styles.left, {
                [styles.left_expand]: canModifyTitle,
              })}
            >
              <Priority />
              <div className={styles.tags}>
                <TagsPreview />
              </div>
            </div>
            <div
              className={cx(styles.flex_row_xy, styles.right, {
                [styles.hide_element]: canModifyTitle,
              })}
            >
              {canModifyTitle ? (
                <></>
              ) : (
                <div
                  className={cx(styles.views, {
                    [styles.views_dark]: isThemeDarkMode,
                  })}
                >
                  <p>{`${views} ${views === 1 ? 'view' : 'views'}`}</p>
                </div>
              )}
            </div>
          </div>
          <div
            className={cx(styles.title, {
              [styles.title_view]: !canModifyTitle,
            })}
          >
            {canModifyTitle ? (
              <input
                onChange={onUpdateTitle}
                type="text"
                value={title}
                placeholder={'Add your title here'}
                maxLength={80}
              />
            ) : (
              <></>
            )}
            {!canModifyTitle ? (
              <p
                className={cx(styles.read_only, {
                  [styles.read_only_light]: !isThemeDarkMode,
                  [styles.read_only_dark]: isThemeDarkMode,
                })}
              >
                {title}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div
            className={cx(styles.flex_row_xy, styles.users, {
              [styles.hide_element]: canModifyTitle,
            })}
          >
            <div
              className={cx(styles.flex_row_xy, styles.author, {
                [styles.hide_element]: canModifyTitle,
              })}
            >
              {!isEmpty(authorDisplayName) ? (
                <div className={cx(styles.flex_row_xy, styles.avatar)}>
                  <Avatar
                    src={author?.image || ''}
                    name={authorDisplayName}
                    background={'#000'}
                    backgroundColor={'#000'}
                  />
                  <div
                    className={cx(styles.avatar_premium, styles.flex_row_xy, {
                      [styles.hide_element]: !userAuthorIsPremium,
                    })}
                  >
                    <VipCrownIcon height={14} width={14} />
                  </div>
                  {userAuthorIsPremium && (
                    <MessageHover
                      message={'Premium'}
                      className={styles.avatar_premium_tip}
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className={cx(styles.flex_column_xy, styles.texts)}>
                <div
                  className={cx(styles.name, {
                    [styles.name_dark]: isThemeDarkMode,
                  })}
                >
                  <p> {authorDisplayName} </p>
                </div>
                <div
                  className={cx(styles.date_created, {
                    [styles.date_created_dark]: isThemeDarkMode,
                  })}
                >
                  {lastModifiedFormatted && <p>{lastModifiedFormatted}</p>}
                </div>
              </div>
            </div>
            <div
              className={cx(styles.flex_row_xy, styles.subscribers, {
                [styles.subscribers_expand]: canModifyTitle,
                [styles.hide_element]: isMobileView() && canModifyTitle,
              })}
            >
              <SubscribersPreview />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(TitleContent)
);
