import cx from 'classnames';
import styles from './Footer.module.scss';
import LogoIcon from 'src/components/logo-icon';
import i18n, { Markdown } from 'src/locales';
import { Link, withRouter } from 'react-router-dom';
import { withModalPromptSettings } from 'src/managers/modal';
import { isFunction } from 'lodash';
import { withMobileToastSettings } from 'src/managers/mobile-toaster';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const Footer = (props) => {
  const { showSignupPrompt, isLoggedIn, showLoginPrompt } = props;

  const onSignup = () => {
    if (isLoggedIn) {
      if (props?.history) {
        props.history.push('/user');
      }

      return;
    }

    if (isFunction(showSignupPrompt)) {
      showSignupPrompt();
    }
  };

  const onLogin = () => {
    if (isLoggedIn) {
      if (props?.history) {
        props.history.push('/user');
      }

      return;
    }

    if (isFunction(showLoginPrompt)) {
      showLoginPrompt();
    }
  };

  return (
    <div className={styles.footer_wrap}>
      <div className={styles.footer_raw}>
        <div className={styles.footer_raw_first}>
          <div className={styles.footer_raw_first_logo}>
            <div className={styles.footer_raw_first_logo_actual}>
              <LogoIcon className={styles.footer_raw_first_logo_actual_raw} />
            </div>
            <div className={styles.footer_raw_first_logo_msg}>
              <p>{i18n('footer_app_logo_msg')}</p>
            </div>
          </div>

          <div className={styles.footer_raw_first_quick_link}>
            <div className={styles.quick_link_title}>
              <h1>{i18n('footer_quick_links')}</h1>
            </div>
            <div className={styles.links}>
              <ul>
                <li>
                  <h5>{i18n('footer_plans_and_pricing')}</h5>
                  <Link to={'/pricing'}></Link>
                </li>

                <li>
                  <h5 onClick={onLogin}>{i18n('footer_login')}</h5>
                </li>
                <li>
                  <h5 onClick={onSignup}>{i18n('footer_signup')}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.footer_raw_resources}>
          <div className={styles.soc_med}>
            <ul>
              <li>
                <div className={cx(styles.soc_med_ic, styles.fb)}></div>
                <a
                  href="https://www.facebook.com/getchamu"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p></p>
                </a>
              </li>
              <li>
                <div className={cx(styles.soc_med_ic, styles.twitter)}></div>
                <a
                  href="https://twitter.com/getchamu"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p></p>{' '}
                </a>
              </li>
              <li>
                <div className={cx(styles.soc_med_ic, styles.insta)}></div>
                <a
                  href="https://www.instagram.com/getchamu/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p></p>
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.credits}>
            <ul>
              <li>
                <Link to={'/documentation/terms'}>
                  {i18n('footer_resources_terms_service_title')}
                </Link>
              </li>
              <li>
                <Link to={'/documentation/privacy'}>
                  {i18n('footer_resources_privacy_policy_title')}
                </Link>
              </li>
            </ul>
            <div className={styles.rights}>
              <h5>
                {Markdown('footer_resources_copyright', {
                  year1: 2023,
                })}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withModalPromptSettings(withMobileToastSettings(withRouter(Footer)))
);
