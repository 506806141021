import cx from 'classnames';
import styles from './Comments.module.scss';
import CommentEntity from './comment-entity';
import CommentInput from './comment-input';
import CustomButton from 'src/components/button';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ChatIcon, CommentCtaIconDark, CommentCtaIconLight } from 'src/icons';
import { withNoteViewContext } from './Context';
import {
  filter,
  getDataRefIdValue,
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
  head,
  isArray,
  isEmpty,
  isFunction,
  isMobileView,
  toLower,
} from 'src/helpers/utils';
import { Button, CrossIcon, Spinner } from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'src/lib/bodyScrollLock.min';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar } from '@chakra-ui/react';
import { includes, isNil } from 'lodash';

export const CommentsContainerId = 'NoteViewCommentsContainerId';

const Comments = (props) => {
  const [editingCommentIds, setEditingCommentIds] = useState([]);
  const [editSubmittedIds, setEditSubmittedIds] = useState([]);
  const listRef = useRef(null);
  const titleRef = useRef(null);
  const commentInputRef = useRef(null);
  const {
    commentsCurrentPagesWithComments = [],
    commentsFetching,
    commentsHasNext,
    commentsCurrentPage,
    fetchCommentsByPage,
    advancedOptions,
    user,
    taskId,
    uRef,
    connectedUsers,
    commentsBlocked,
    commentsView,
    closeModal,
    isLoggedIn,
    markBlockCommentsFetchRealtime,
    isEditMode,
    isForGettingStartedNote,
    isThemeDarkMode,
    fetchingAdvancedOptions,
    authorInfo,
  } = props;

  const isLoading = useMemo(
    () => commentsFetching || fetchingAdvancedOptions,
    [commentsFetching, fetchingAdvancedOptions]
  );
  const canComment = useMemo(
    () =>
      !fetchingAdvancedOptions &&
      isLoggedIn &&
      !commentsBlocked &&
      commentsView &&
      advancedOptions?.allowComments,
    [
      isLoggedIn,
      commentsBlocked,
      commentsView,
      advancedOptions,
      fetchingAdvancedOptions,
    ]
  );

  const taskIdLowerCase = toLower(taskId);
  const userId = useMemo(
    () => getUserIdFromObject(user) || getUserProfileIdFromObject(user),
    [user]
  );
  const userProfileId = useMemo(() => getUserProfileIdFromObject(user), [user]);
  const userDisplayName = useMemo(() => getUserDisplayName(user), [user]);
  const commentsFormatted = useMemo(
    () =>
      filter(
        commentsCurrentPagesWithComments.reduce((acc, comments) => {
          if (isArray(comments)) {
            acc = [...acc, ...comments];
          }

          return acc;
        }, []),
        (commentInfo) => commentInfo && !commentInfo?.deleted
      ),
    [commentsCurrentPagesWithComments]
  );

  const onSubmit = () => {
    if (isEditMode && isFunction(markBlockCommentsFetchRealtime)) {
      markBlockCommentsFetchRealtime(true);
    }
  };

  const onSubmitCallback = (success = false, params = {}) => {
    if (isFunction(markBlockCommentsFetchRealtime)) {
      markBlockCommentsFetchRealtime(false);
    }

    if (success && params?.mode === 'edit' && params?.updatedProps) {
      // update local copy
    } else if (success && params?.mode === 'success' && params?.props) {
      // insert comment
    }
  };

  const onEditCancel = () => {
    setEditSubmittedIds(
      editSubmittedIds.filter((id) => !editingCommentIds.includes(id))
    );
    setEditingCommentIds([]);
  };

  const setEditingId = (commentId = '') => {
    if (!commentId) {
      setEditSubmittedIds(
        editSubmittedIds.filter((id) => !editingCommentIds.includes(id))
      );

      setEditingCommentIds([]);
      return;
    }

    setEditingCommentIds([commentId]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (evt?.target?.classList?.contains(styles.comments)) {
        if (isMobileView() && isFunction(closeModal)) {
          closeModal();
        }
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [commentsFormatted, closeModal]);

  const editingComment = useMemo(() => {
    const editingCommentId = head(editingCommentIds);

    if (!isEmpty(editingCommentId)) {
      const find = head(
        filter(commentsFormatted, (commentInfo) => {
          const commentRefId = getDataRefIdValue(commentInfo);
          const commentId = commentInfo?.id || commentRefId;

          if (editingCommentId === commentId) {
            return true;
          }

          return false;
        })
      );

      if (!isEmpty(find)) {
        return find;
      }
    }

    return null;
  }, [editingCommentIds, commentsFormatted]);

  const showMoreComments = () => {
    if (isFunction(fetchCommentsByPage)) {
      fetchCommentsByPage(commentsCurrentPage + 1, true);
    }
  };

  useEffect(() => {
    const dom = listRef?.current;

    if (dom && isMobileView()) {
      disableBodyScroll(dom);
    }

    return () => {
      if (dom) {
        enableBodyScroll(dom);
      }
    };
  }, [commentsFormatted, editingComment, user]);

  /**
   * Clear scroll locks on unmount
   */
  useEffect(() => {
    return clearAllBodyScrollLocks;
  }, []);

  return (
    <div className={styles.comments} id={CommentsContainerId}>
      <div
        className={cx(styles.flex_column_xy, styles.content, {
          [styles.content_dark]: isThemeDarkMode,
          [styles.hide_element]: isForGettingStartedNote,
        })}
      >
        <div
          ref={titleRef}
          className={cx(styles.flex_row_xy, styles.title, {
            [styles.title_dark]: isThemeDarkMode,
          })}
        >
          <ChatIcon height={20} width={20} />
          <h1>{'Comments'}</h1>
        </div>
        {isLoading && (
          <div
            className={cx(styles.flex_row_xy, styles.loading, {
              [styles.loading_dark]: isThemeDarkMode,
            })}
          >
            <Spinner height={30} width={30} />
          </div>
        )}

        <div
          ref={commentInputRef}
          className={cx(styles.flex_row_xy, styles.comment_input, {
            [styles.hide_element]: isLoading || !canComment,
          })}
        >
          <div className={cx(styles.flex_row_xy, styles.author)}>
            {isLoggedIn && !isEmpty(user) ? (
              <>
                <Avatar
                  src={user?.image}
                  name={userDisplayName}
                  height={'40px'}
                  width={'40px'}
                  background={'#000'}
                  backgroundColor={'#000'}
                />
                <p>{userDisplayName}</p>
              </>
            ) : (
              <></>
            )}
          </div>{' '}
          {!isEmpty(editingComment) && user && isLoggedIn && isMobileView() && (
            <CommentInput
              mobileView
              showEdit
              autoEdit
              allowMention
              user={user}
              isThemeDarkMode={isThemeDarkMode}
              mode={'edit'}
              connectedUsers={connectedUsers}
              version={
                editingComment?.delta || editingComment?.gif ? 'v2' : 'v1'
              }
              taskId={taskIdLowerCase}
              text={editingComment?.text}
              editingId={editingComment?.ref_id || editingComment?.refId}
              uRef={uRef}
              refId={editingComment?.ref_id || editingComment?.refId}
              id={editingComment?.ref_id || editingComment?.refId}
              delta={editingComment?.gif ? '' : editingComment?.delta}
              gif={editingComment?.gif}
              setEditingId={setEditingId}
            />
          )}
          {(isEmpty(editingComment) ||
            isNil(editingComment) ||
            !isMobileView()) && (
            <CommentInput
              user={user}
              isThemeDarkMode={isThemeDarkMode}
              forceEdit
              allowMention
              mode={'create'}
              taskId={taskIdLowerCase}
              uRef={uRef}
              connectedUsers={connectedUsers}
            />
          )}
        </div>
        <div
          className={cx(styles.flex_row_xy, styles.empty_comments, {
            [styles.hide_element]: !isEmpty(commentsFormatted) || isLoading,
            [styles.empty_comments_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.empty_comments_icon, styles.flex_row_xy, {
              [styles.empty_comments_icon_dark]: isThemeDarkMode,
            })}
          >
            {isThemeDarkMode && <CommentCtaIconDark />}
            {!isThemeDarkMode && <CommentCtaIconLight />}
          </div>
          {!canComment && <h5>{'No comments.'}</h5>}
          {canComment && (
            <>
              <h5>{'Share what you think.'}</h5>
              <p>
                {
                  'Add a comment for yourself or invite others to share their thoughts.'
                }
              </p>
            </>
          )}
        </div>
        <div
          className={cx(styles.list, {
            [styles.hide_element]: isLoading || !commentsFormatted?.length,
            [styles.list_no_comment]: !canComment && commentsHasNext,
            [styles.list_no_comment_no_more]: !canComment && !commentsHasNext,
            [styles.list_with_comment_no_more]: canComment && !commentsHasNext,
            [styles.list_dark]: isThemeDarkMode,
          })}
          ref={listRef}
        >
          <div>
            {commentsFormatted.map((comment, idx) => {
              const commentAuthor = comment?.user;
              const commentAuthorUserId = getUserIdFromObject(commentAuthor);
              const commentAuthorUserProfileId =
                getUserProfileIdFromObject(commentAuthor);
              const commentRefId = getDataRefIdValue(comment);
              const commentId = comment?.id || commentRefId;
              const isAuthor =
                commentAuthorUserProfileId ===
                  getUserProfileIdFromObject(authorInfo) ||
                commentAuthorUserId === getUserIdFromObject(authorInfo);
              const hideEditButton =
                !canComment ||
                !commentsView ||
                (commentAuthorUserId !== userId &&
                  commentAuthorUserProfileId !== userProfileId) ||
                commentsBlocked ||
                (isMobileView() && includes(editingCommentIds, commentId));
              const opts = !isEmpty(commentId) && isAuthor;

              return (
                <div key={`Comment${commentId}`}>
                  <CommentEntity
                    commentId={commentId}
                    authorInfo={commentAuthor}
                    authorUserId={commentAuthorUserId}
                    authorProfileId={commentAuthorUserProfileId}
                    isThemeDarkMode={isThemeDarkMode}
                    idx={idx}
                    loadAction={includes(editSubmittedIds, commentId)}
                    editingId={head(editingCommentIds)}
                    opts={opts}
                    hideEditButton={hideEditButton}
                    isAuthor={isAuthor}
                    connectedUsers={connectedUsers}
                    users={connectedUsers}
                    ref_id={commentRefId}
                    text={comment?.text}
                    profile_id={commentAuthorUserId}
                    created={comment?.created}
                    edited={comment?.edited}
                    deleted={comment?.deleted}
                    delta={comment?.delta}
                    text_raw={comment?.text_raw}
                    gif={comment?.gif}
                    spaceMember={false}
                    taskId={taskIdLowerCase}
                    uRef={uRef}
                    setEditingId={(...args) => setEditingId(...args)}
                    onCancel={(...args) => onEditCancel(...args)}
                    onSubmit={(...args) => onSubmit(...args)}
                    onSubmitCallback={onSubmitCallback}
                    showEdit
                  />
                </div>
              );
            })}
          </div>
        </div>
        {commentsHasNext && (
          <div className={cx(styles.flex_row_xy, styles.show_more)}>
            <CustomButton
              isThemeDarkMode={isThemeDarkMode}
              className={styles.show_more_button}
              label={'Show more'}
              variant={'primaryPurple'}
              onClick={showMoreComments}
            />
          </div>
        )}

        <div
          className={cx(styles.flex_row_xy, styles.close, {
            [styles.close_dark]: isThemeDarkMode,
          })}
        >
          <Button
            appearance="minimal"
            className={styles.flex_row_xy}
            onClick={() => {
              if (isFunction(closeModal) && isMobileView()) {
                closeModal();
              }
            }}
          >
            <CrossIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Comments));
