import { useState } from 'react';
import cx from 'classnames';
import styles from './CreateSpace.module.scss';
import i18n from 'src/locales';
import { Button, TextInput, Spinner } from 'evergreen-ui';
import { withModalPromptSettings } from 'src/managers/modal';
import { isEmpty, isFunction, size, toString } from 'lodash';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { createNewSpaceRequest } from 'src/managers/api/spaces';
import { useAuth } from 'src/hooks';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { useToast } from '@chakra-ui/react';

const CreateSpace = (props) => {
  const { isThemeDarkMode, closeModal, addNewSpace, isSpaceNameAlreadyExist } =
    props;
  const [spaceInput, setSpaceInput] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const toast = useToast({ id: 'chakraToast', position: 'top' });

  const onNewSpaceInput = (evt) => {
    setSpaceInput(toString(evt?.target?.value || ''));
  };

  const close = () => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  };

  const { getAuthenticatedHeaders } = useAuth(props);

  const onProceed = async () => {
    try {
      const nameAlreadyTaken = isFunction(isSpaceNameAlreadyExist)
        ? isSpaceNameAlreadyExist(spaceInput)
        : false;

      if (nameAlreadyTaken) {
        toast({
          status: 'error',
          title: 'Space name already exist.',
          duration: 1_500,
          isClosable: false,
        });
        return;
      }

      if (submitting) {
        return;
      }

      setSubmitting(true);
      const headers = getAuthenticatedHeaders();
      const { spaceInfo, success } = await createNewSpaceRequest(
        spaceInput,
        headers
      );

      if (!isEmpty(spaceInfo) && success) {
        if (isFunction(addNewSpace)) {
          addNewSpace(spaceInfo);
        }

        if (isFunction(closeModal)) {
          closeModal();
        }

        return;
      }

      toast({
        status: 'error',
        title: 'Something went wrong. Try again.',
        duration: 1_500,
        isClosable: false,
      });
    } catch {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={cx(styles.create_space, styles.flex_row_xy)}>
      <div className={styles.raw}>
        <div
          className={cx(styles.space, {
            [styles.space_dark]: isThemeDarkMode,
          })}
        >
          <div
            className={cx(styles.space_title, {
              [styles.space_title_dark]: isThemeDarkMode,
            })}
          >
            <h5>{i18n('user_create_space_name_of_the_space')}</h5>
          </div>
          <TextInput
            onChange={onNewSpaceInput}
            value={spaceInput}
            className={styles.space_input}
            placeholder={i18n('user_create_space_name_placeholder')}
          />
        </div>
        <div className={styles.buttons}>
          {!submitting && (
            <>
              <Button
                appearance={'minimal'}
                className={cx(styles.cancel, {
                  [styles.cancel_dark]: isThemeDarkMode,
                })}
                onClick={close}
              >
                <h4>{i18n('common_cancel_title')}</h4>
              </Button>
              <Button
                onClick={onProceed}
                className={styles.proceed_enable}
                appearance={'minimal'}
                disabled={size(spaceInput) < 2}
              >
                <h4>{i18n('common_proceed_title')}</h4>
              </Button>{' '}
            </>
          )}
          {submitting && (
            <div
              className={cx(styles.flex_row_xy, styles.loading, {
                [styles.loading_dark]: isThemeDarkMode,
              })}
            >
              <Spinner height={20} width={20} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(CreateSpace))
);
