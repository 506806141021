import cx from 'classnames';
import styles from './CommentEntity.module.scss';
import k from 'src/constants/k';
import CustomBadge from 'src/components/custom-badge';
import CommentInput from '../comment-input';
import i18n, { Markdown } from 'src/locales';
import {
  daysToSeconds,
  getHHMMAMPMFormat,
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
} from 'src/helpers/utils';
import { Spinner } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';

export function CommentDateFormat(created = 0) {
  const createdUTC = new Date(created * 1000);
  const localUTC = new Date();
  const timestamp_now = Math.ceil(Date.now() / 1000);
  const currentYear = localUTC.getFullYear();
  const isSameYear = createdUTC.getFullYear() === currentYear;

  if (isSameYear && timestamp_now > created) {
    const isSameMonth = createdUTC.getMonth() === localUTC.getMonth();
    const isYesterday =
      isSameMonth && createdUTC.getDate() + 1 === localUTC.getDate();

    if (isYesterday) {
      return i18n('common_yesterday_title');
    } else if (
      isSameMonth &&
      localUTC.getDate() > createdUTC.getDate() &&
      localUTC.getDate() - createdUTC.getDate() < 7
    ) {
      return Markdown('task_view_comments_date_days_ago', {
        day1: `${localUTC.getDate() - createdUTC.getDate()}`,
      });
    }

    const isSameDay =
      isSameMonth && createdUTC.getDate() === localUTC.getDate();
    const diff = timestamp_now - created;
    const isLessThan12Hrs =
      isSameDay && timestamp_now > created && diff < daysToSeconds(0.5);

    if (isLessThan12Hrs) {
      if (diff < 3600) {
        let min = Math.ceil(diff * (1 / 60));
        min = min < 2 ? 1 : min;

        if (min === 60) {
          return Markdown('task_view_comments_date_hr_ago', {
            hr1: '1',
          });
        }

        return Markdown(
          min < 2
            ? 'task_view_comments_date_min'
            : 'task_view_comments_date_mins',
          {
            min1: `${min}`,
          }
        );
      } else {
        let hr = Math.round(Number((diff * (1 / 60) * (1 / 60)).toFixed(2)));
        hr = hr < 2 ? 1 : hr;

        return hr < 2
          ? Markdown('task_view_comments_date_hr_ago', {
              hr1: `${hr}`,
            })
          : Markdown('task_view_comments_date_hrs_ago', {
              hr1: `${hr}`,
            });
      }
    } else if (isSameDay) {
      return getHHMMAMPMFormat(createdUTC.getHours(), createdUTC.getMinutes());
    }
  }

  return `${
    k.MONTHS_INDEX[createdUTC.getMonth()].abv
  } ${createdUTC.getDate()}, ${createdUTC.getFullYear()}`;
}

const CommentEntity = ({
  commentId = '',
  authorInfo = {},
  authorUserId = '',
  authorProfileId = '',
  text = '',
  created = 0,
  ref_id = '',
  setEditingId = function () {},
  onSubmit = function () {},
  onSubmitCallback = function () {},
  onCancel = function () {},
  editingId = '',
  loadAction = false,
  toEdit = false,
  isAuthor = false,
  edited = false,
  deleted = false,
  spaceMember = false,
  text_raw = false,
  delta = '',
  showEdit = false,
  hideEditButton = false,
  taskId = '',
  uRef = '',
  gif = '',
  connectedUsers = [],
  isThemeDarkMode = false,
  isLoggedIn = false,
  user = {},
}) => {
  const userId = useMemo(() => getUserIdFromObject(user), [user]);
  const userProfileId = useMemo(() => getUserProfileIdFromObject(user), [user]);
  const isUser = useMemo(
    () =>
      isLoggedIn &&
      (authorProfileId === userProfileId ||
        authorUserId === userProfileId ||
        authorUserId === userId),
    [authorUserId, isLoggedIn, authorProfileId, userId, userProfileId]
  );
  const authorImage = useMemo(
    () => (isUser ? user?.image : authorInfo?.image),
    [authorInfo, user, isUser]
  );

  const displayName = useMemo(
    () => (isUser ? getUserDisplayName(user) : getUserDisplayName(authorInfo)),
    [isUser, authorInfo, user]
  );

  return (
    <div className={cx(styles.flex_row_xy, styles.comment_entity)}>
      <Tooltip
        className={styles.custom_tooltip}
        label={authorInfo?.decryptedUsername || displayName}
      >
        <Avatar
          backgroundColor={'#000'}
          color={'#fff'}
          src={authorImage}
          size={'md'}
          name={displayName}
          className={cx(styles.flex_row_xy, styles.comment_avatar)}
        />
      </Tooltip>
      <div className={styles.content}>
        <div className={styles.name}>
          <h5>{displayName}</h5>{' '}
          {isAuthor && (
            <div>
              <CustomBadge name={i18n('common_author_title')} type={'author'} />
            </div>
          )}{' '}
          {spaceMember && (
            <div>
              <CustomBadge
                name={i18n('common_space_member_title')}
                type={'author'}
              />
            </div>
          )}
        </div>
        <h5 className={styles.created}>{CommentDateFormat(created)}</h5>
        <h5 className={cx(styles.edited, { [styles.hide_element]: deleted })}>
          <i>{`${edited ? `(${i18n('common_edited_title')})` : ''}`}</i>
        </h5>

        {(text || delta || gif) && !deleted && (
          <CommentInput
            allowMention
            user={user}
            commentId={commentId}
            isThemeDarkMode={isThemeDarkMode}
            hideEditButton={hideEditButton}
            showEdit={isUser && showEdit}
            text={text}
            version={text_raw ? 'v1' : 'v2'}
            delta={gif ? '' : delta}
            mode={'edit'}
            className={styles.comment_input}
            taskId={taskId}
            uRef={uRef}
            refId={ref_id}
            editingId={editingId}
            id={ref_id}
            gif={gif}
            setEditingId={setEditingId}
            onSubmitCallback={onSubmitCallback}
            onSubmit={onSubmit}
            onCancel={onCancel}
            connectedUsers={connectedUsers}
          />
        )}
      </div>
      <div
        className={cx(styles.spinner_wrap, {
          [styles.spinner_wrap_dark]: isThemeDarkMode,
          [styles.hide_element]: !loadAction,
        })}
      >
        <div className={styles.raw}>
          <Spinner size={25} />
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(CommentEntity);
