import styles from './ProductHunt.module.scss';
import cx from 'classnames';

const ProductHunt = () => {
  return (
    <div className={cx(styles.product_hunt_show, styles.flex_row_xy)}>
      <a
        href="https://www.producthunt.com/posts/chamu?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chamu"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=393529&theme=dark"
          alt="chamu - Sharing&#0032;files&#0032;and&#0032;notes&#0032;made&#0032;easy | Product Hunt"
          style={{ width: '250px', height: '54px' }}
          width="250"
          height="54"
        />
      </a>
    </div>
  );
};

export default ProductHunt;
