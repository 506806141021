import cx from 'classnames';
import styles from './AddTag.module.scss';
// import CreateTag from 'src/modals/create-tag';
// import CreateSpaceTag from 'src/modals/create-space-tag';
import { Button, PlusIcon } from 'evergreen-ui';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withModalPromptSettings } from 'src/managers/modal';
import { isFunction } from 'lodash';
import CreateTag from 'src/components/create-tag';

const AddTag = (props) => {
  const { spaceId, selectedSpace, isThemeDarkMode, setActiveModalDom } = props;

  const onNewTag = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(
        <CreateTag tagSpaceId={spaceId} selectedSpace={selectedSpace} />
      );
    }
  };

  return (
    <div
      className={cx(styles.flex_row_xy, styles.add_tag, {
        [styles.add_tag_dark]: isThemeDarkMode,
      })}
    >
      <Button
        appearance="minimal"
        className={styles.flex_row_xy}
        onClick={onNewTag}
      >
        <PlusIcon height={16} width={16} />
        <p>{'New Tag'}</p>
      </Button>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(AddTag))
);
