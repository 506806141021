const AlignCenterIcon = (p) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1066_20170)">
        <path
          d="M2.5 3.33301H17.5V4.99967H2.5V3.33301ZM4.16667 15.833H15.8333V17.4997H4.16667V15.833ZM2.5 11.6663H17.5V13.333H2.5V11.6663ZM4.16667 7.49967H15.8333V9.16634H4.16667V7.49967Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1066_20170">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlignCenterIcon;
