import { axiosGetRequest, axiosPostRequest, axiosPutRequest } from './axios';
import {
  filter,
  isArray,
  isEmpty,
  isNumber,
  isString,
  pick,
  toString,
  trim,
} from 'lodash';
import { isNetworkError } from '.';
import {
  getFileApiPathname,
  getPublicApiPathname,
  getTaskApiPathname,
  getUsersApiPathname,
} from './urls';

export async function sendUserLoginRequest(
  emailOrUsername = '',
  password = ''
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let user = null;
  let userId = '';
  let token = '';

  try {
    const url = getUsersApiPathname() + '/login';
    const res = await axiosPostRequest(
      url,
      {},
      { username: emailOrUsername, password: password }
    );
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData)) {
      user = resData?.user;
      userId = resData?.userId;
      token = resData?.token;
      success = true;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return { token, user, userId, success, networkError, errorMessage };
}

export async function sendUserSignupRequest(
  username = '',
  email = '',
  password = '',
  fullName = ''
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let user = null;
  let userId = '';
  let token = '';

  try {
    const url = getUsersApiPathname() + '/sign-up';
    const res = await axiosPostRequest(
      url,
      {},
      { fullName, username, password, email }
    );
    const resData = res?.data;

    if ((res?.status === 200 || res?.status < 300) && !isEmpty(resData?.user)) {
      user = resData?.user;
      userId = resData?.userId;
      token = resData?.token;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, errorMessage, networkError, token, userId, user };
}

export async function getMetadataEncryptionForSignup() {
  let networkError = false;
  let errorMessage = '';
  let userSecretsData = null;

  try {
    const url = getPublicApiPathname() + '/script-encrypt/client-signup';
    const res = await axiosGetRequest(url);
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData?.secrets)) {
      userSecretsData = resData?.secrets;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return { userSecretsData, errorMessage, networkError };
}

export async function getMetadataEncryptionForLogin() {
  let networkError = false;
  let errorMessage = '';
  let userSecretsData = null;

  try {
    const url = getPublicApiPathname() + '/script-encrypt/client-login';
    const res = await axiosGetRequest(url);
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData?.secrets)) {
      userSecretsData = resData?.secrets;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return { userSecretsData, errorMessage, networkError };
}

export async function getPersonalTagsByPage(pageNumber = 1, headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let tags = [];
  let hasNext = false;

  try {
    const url = getUsersApiPathname() + `/personal-tags/${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && resData?.value) {
      hasNext = resData?.hasNext;
      tags = resData?.value || [];
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return { hasNext, tags, networkError, errorMessage };
}

export async function getUserBasicInfo(headers = {}) {
  // basic-info
  let networkError = false;
  let errorMessage = '';
  let user = null;
  let userId = '';

  try {
    const url = getUsersApiPathname() + '/basic-info';
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status >= 200 && res?.status < 300 && !isEmpty(resData)) {
      user = resData?.user;
      userId = resData?.userId;
    } else if (!isEmpty(resData?.message)) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    user,
    userId,
    networkError,
    errorMessage,
  };
}

export async function markNotificationsReadRequest(
  notificationIds = [],
  headers = {}
) {
  let success = false;
  let errorMessage = '';
  let networkError = false;

  try {
    const url = getUsersApiPathname() + '/notifications/mark-read';
    const res = await axiosPostRequest(url, headers, {
      ids: filter(notificationIds, (id) => isString(id) && !isEmpty(id)),
    });
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
      success = true;
    } else if (!isEmpty(resData?.message)) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
  }

  return { errorMessage, networkError, success };
}

export async function getUserNotificationsByPage(pageNumber = 1, headers = {}) {
  //notifications
  let notifications = [];
  let hasNext = false;
  let networkError = false;
  let errorMessage = '';

  try {
    if (!pageNumber || !isNumber(pageNumber)) {
      pageNumber = 1;
    }

    const url = getUsersApiPathname() + `/notifications/${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && isArray(resData?.value)) {
      hasNext = resData?.hasNext;
      notifications = resData?.value;
    } else if (isString(resData?.message)) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return { errorMessage, networkError, hasNext, notifications };
}

export async function probeUser() {
  let country = '';
  let region = '';
  let networkError = false;
  let errorMessage = '';

  try {
    const res = await axiosPutRequest(
      `https://us-central1-chamu-cloud3.cloudfunctions.net/UserPublic/_p/probe`
    );
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData)) {
      const dataJSON = isString(resData) ? JSON.parse(res.data) : resData;
      const [responseCountry, responseRegion] = dataJSON?.l.split(',');
      country = trim(toString(responseCountry));
      region = trim(toString(responseRegion));
    } else if (res?.data?.message) {
      throw new Error(res.data.message);
    } else {
      throw new Error(`${res?.statusText} ${res?.status}`);
    }
  } catch (err) {
    networkError = isNetworkError(err);
    errorMessage = err?.message;
  }

  return { region, country, networkError, errorMessage, err: errorMessage };
}

export async function getPersonalTagsByPageRequest(
  pageNumber = 1,
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let tags = [];
  let hasNext = false;

  if (!pageNumber || pageNumber < 1) {
    pageNumber = 1;
  }

  try {
    const url = getUsersApiPathname() + `/personal-tags/${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && !isEmpty(resData)) {
      tags = resData?.value;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    networkError = isNetworkError(err);
    errorMessage = err?.message;
  }

  return { tags, hasNext, networkError, errorMessage };
}

export async function sendResetPasswordFromForgotPasswordEmailReq(
  newPassword = '',
  code = ''
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getPublicApiPathname() + '/reset-password';
    const res = await axiosPostRequest(url, {}, { newPassword, code });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { networkError, errorMessage, success };
}

export async function sendUserForgotPasswordRequest(email = '') {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let tokenId = '';

  try {
    const origin = trim(window.location.origin);
    const url = getUsersApiPathname() + '/forgot-password';
    const res = await axiosPostRequest(url, {}, { email, origin });
    const resData = res?.data;

    if (res?.status === 200 && resData?.tokenId) {
      success = true;
      tokenId = resData?.tokenId;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    networkError = isNetworkError(err);
    errorMessage = err?.message;
  }

  return { success, tokenId, errorMessage, networkError };
}

export async function sendUserLogoutRequest(headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getUsersApiPathname() + '/logout';
    const res = await axiosPostRequest(url, headers);

    const resData = res?.data;

    if (res?.status === 200) {
      success = true;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    networkError = isNetworkError(err);
    errorMessage = err?.message;
  }

  return { errorMessage, success, networkError };
}

export async function updateUserPropertiesRequest(params = {}, headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let updatedUser = {};
  let success = false;
  try {
    const sanitizedParams = pick(params, [
      'fullName',
      'username',
      'password',
      'email',
      'settings',
    ]);
    const url = getUsersApiPathname() + '/update/info';
    const res = await axiosPostRequest(url, headers, sanitizedParams);
    const resData = res?.data;

    if (
      (res?.status === 200 || res?.status === 201) &&
      !isEmpty(resData?.user)
    ) {
      updatedUser = resData?.user;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    networkError = isNetworkError(err);
    errorMessage = err?.message;
  }

  return { success, networkError, errorMessage, updatedUser };
}

export async function updateUserImageFileRequest(
  file = null,
  fileName = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let image = '';

  try {
    const url =
      getFileApiPathname() + `/upload/image/user?fileName=${trim(fileName)}`;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const res = await axiosPostRequest(
      url,
      { ...headers, 'content-type': 'multipart/form-data' },
      formData
    );
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.image) {
      image = resData?.image;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    image,
  };
}

export async function getSavedUsersByPageRequest(pageNumber = 1, headers = {}) {
  let errorMessage = '';
  let networkError = false;
  let savedUsers = [];
  let hasNext = false;

  // instance.get(
  //   '/saved-users/:page',
  //   { schema: getSavedUsersSchema },
  //   getSavedUsersByPageHandler
  // );

  try {
    const url = getTaskApiPathname() + `/saved-users/${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && resData?.value) {
      savedUsers = resData?.value || [];
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { hasNext, errorMessage, networkError, savedUsers };
}

export async function saveUserForAccessRequest(
  userIdOrProfileId = '',
  headers = {}
) {
  let success = false;
  let networkError = false;
  let errorMessage = '';
  let savedUser = null;

  try {
    const url = getTaskApiPathname() + '/save-user';
    const res = await axiosPostRequest(url, headers, {
      userIdOrProfileId: trim(userIdOrProfileId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.savedUser) {
      savedUser = resData?.savedUser;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { savedUser, success, networkError, errorMessage };
}

export async function unsaveUserForAccessRequest(
  userIdOrProfileId = '',
  headers = {}
) {
  let success = false;
  let networkError = false;
  let errorMessage = '';

  try {
    const url = getTaskApiPathname() + '/unsave-user';
    const res = await axiosPostRequest(url, headers, {
      userIdOrProfileId: trim(userIdOrProfileId),
    });
    const resData = res?.data;

    if ((res?.status === 200 || res?.status === 201) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, networkError, errorMessage };
}

export async function getUserInfoByIdOrUsernameOrEmailRequest(
  idOrEmailOrUsername = ''
) {
  let errorMessage = '';
  let networkError = false;
  let user = null;

  try {
    const url = getUsersApiPathname() + `/info/${trim(idOrEmailOrUsername)}`;
    const res = await axiosGetRequest(url);
    const resData = res?.data;

    if (res?.status === 200 && resData?.user) {
      user = resData?.user;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { user, errorMessage, networkError };
}

// saved-search
export async function getSavedSearchInfoRequest(headers = {}) {
  let errorMessage = '';
  let networkError = false;
  let accessed = [];
  let searched = [];

  try {
    const url = getUsersApiPathname() + '/saved-search';
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && isArray(resData?.accessed)) {
      accessed = resData?.accessed || [];
      searched = resData?.searched || [];
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { accessed, searched, errorMessage, networkError };
}

export async function saveRecenSearchRequest(
  searched = '',
  accessed = [],
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let success = false;

  try {
    const url = getUsersApiPathname() + '/notes/save-search';
    const res = await axiosPostRequest(url, headers, {
      searched: trim(searched),
      accessed,
    });
    const resData = res?.data;

    if (resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { errorMessage, networkError, success };
}

export async function searchUserNotesRequest(querySearch = '', headers = {}) {
  let notes = [];
  let errorMessage = '';
  let networkError = false;

  try {
    const url =
      getUsersApiPathname() + `/notes/search?q=${encodeURI(trim(querySearch))}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (res?.status === 200 && resData?.valid) {
      notes = resData?.notes || [];
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { notes, networkError, errorMessage };
}

export async function confirmUserVerifyEmailRequest(token = '') {
  let errorMessage = '';
  let networkError = false;
  let success = false;
  try {
    const url = getUsersApiPathname() + '/confirm-verify-email';
    const res = await axiosPostRequest(url, {}, { token: trim(token) });
    const resData = res?.data;

    if (res?.status === 200 && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, errorMessage, networkError };
}
