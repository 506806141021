import styles from './Priority.module.scss';
import cx from 'classnames';
import PriorityIcon from 'src/components/priority-icon';
import { withNoteViewContext } from './Context';
import { Button } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { isFunction, isNumber } from 'lodash';
import { useDebounce } from 'src/hooks';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const Priority = (props) => {
  const PriorityOptionsClassname = 'PriorityOptionsClassnameUnique';
  const { priority, isCreateMode, isEditMode, isThemeDarkMode } = props;
  const canModifyTitle = isEditMode || isCreateMode;
  const [showPriority, setShowPriority] = useState(false);

  const updatePriority = useDebounce((key = 0) => {
    setShowPriority(false);
    if (!key || !isNumber(key)) {
      return;
    } else if (isFunction(props?.setPriority)) {
      props.setPriority(key);
    }
  }, 100);

  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (
        evt?.target?.classList?.length &&
        evt?.target?.classList?.contains(PriorityOptionsClassname)
      ) {
        return;
      } else {
        setShowPriority(false);
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [priority, showPriority]);

  return (
    <div className={cx(styles.flex_row_xy, styles.priority)}>
      <Button
        onClick={() => {
          if (!canModifyTitle) {
            return;
          }

          setShowPriority(!showPriority);
        }}
        appearance="minimal"
        className={cx(styles.flex_row_xy, {
          [styles.priority_button_dark]: isThemeDarkMode,
        })}
      >
        <PriorityIcon noHover priority={priority} />
        <div className={cx(styles.cover, PriorityOptionsClassname)}> </div>
      </Button>
      {!showPriority || !canModifyTitle ? (
        <></>
      ) : (
        <div
          className={cx(
            styles.priority_options,
            { [styles.priority_options_dark]: isThemeDarkMode },
            PriorityOptionsClassname
          )}
        >
          <div
            className={cx(
              styles.priority_options_content,
              styles.flex_column_xy,
              { [styles.priority_options_content_dark]: isThemeDarkMode },
              PriorityOptionsClassname
            )}
          >
            <Button
              onClick={() => updatePriority(1)}
              appearance="minimal"
              className={styles.flex_row_xy}
            >
              <PriorityIcon noHover priority={1} />
              <p>Low Priority</p>
              <div className={cx(styles.cover, PriorityOptionsClassname)}>
                {' '}
              </div>
            </Button>
            <Button
              onClick={() => updatePriority(2)}
              appearance="minimal"
              className={styles.flex_row_xy}
            >
              <PriorityIcon noHover priority={2} />
              <p>Normal Priority</p>
              <div className={cx(styles.cover, PriorityOptionsClassname)}>
                {' '}
              </div>
            </Button>
            <Button
              onClick={() => updatePriority(3)}
              appearance="minimal"
              className={styles.flex_row_xy}
            >
              <PriorityIcon noHover priority={3} />
              <p>High Priority</p>
              <div className={cx(styles.cover, PriorityOptionsClassname)}>
                {' '}
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(withNoteViewContext(Priority));
