import { useState } from 'react';
import useDebounce from './useDebounce';

const useForceUpdate = () => {
  const [value, setValue] = useState(0);

  const update = useDebounce(() => {
    setValue((value) => value + 1);
  }, 200);

  return { updatedValue: value, update };
};

export default useForceUpdate;
