import Config from './Config';
import OS from './OS';

class Store {
  static CONFIGS = null;
  static OS = null;

  constructor() {
    this.CONFIGS = new Config();
    this.OS = new OS();
  }

  start() {
    this.OS.start();
    this.CONFIGS.start();
  }
}

export default new Store();
