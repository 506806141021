import { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Success.module.scss';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, ArrowRightIcon, TickCircleIcon } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { timeout } from 'src/helpers/utils';

const PaymentSuccess = (props) => {
  const { isThemeDarkMode, profileLoaded, isLoggedIn } = props;
  const [toRedirect, setToRedirect] = useState(false);

  useEffect(() => {
    timeout(2_500).then(() => {
      setToRedirect(true);
    });
  }, []);

  if (toRedirect) {
    return <Redirect to={'/user'} />;
  } else if (toRedirect && !isLoggedIn) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className={cx(styles.payment_success, styles.flex_row_xy)}>
      <div className={cx(styles.raw, { [styles.raw_dark]: isThemeDarkMode })}>
        <div className={styles.success}>
          {!profileLoaded && (
            <div
              className={cx(styles.loading, styles.flex_row_xy, {
                [styles.loading_dark]: isThemeDarkMode,
              })}
            >
              <div>
                <Spinner />
              </div>
            </div>
          )}
          {profileLoaded && (
            <>
              <div className={cx(styles.success_ic, styles.flex_row_xy)}>
                <TickCircleIcon color="success" />
              </div>
              <div className={styles.title}>
                <h3>{i18n('payment_successful')}</h3>
              </div>
              <div className={styles.message}>
                <h5>{i18n('payment_success_message')}</h5>{' '}
              </div>

              <div className={styles.to_dashboard}>
                <CustomButton
                  isThemeDarkMode={isThemeDarkMode}
                  onClick={() => setToRedirect(true)}
                  className={styles.to_dashboard_button}
                  variant={'primaryPurple'}
                  label={i18n('payment_succes_to_dashboard')}
                />{' '}
              </div>
              <div className={styles.redirect}>
                <Link to={'/user/account'}>
                  <h5> {i18n('payment_redirect_account_settings')}</h5>
                  <span>
                    <ArrowRightIcon />
                  </span>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(PaymentSuccess);
