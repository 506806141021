const WritingHandIcon = (p) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M13.3469 15.885C12.5489 17.0657 11.417 17.6952 10.8185 17.2903C10.2201 16.8854 10.3826 15.6013 11.1806 14.4206C11.9785 13.24 13.1104 12.6104 13.7089 13.0153C14.3073 13.4202 14.1448 14.7043 13.3469 15.885Z"
        fill="url(#paint0_linear_1429_26481)"
      />
      <path
        d="M13.3469 15.885C12.5489 17.0657 11.417 17.6952 10.8185 17.2903C10.2201 16.8854 10.3826 15.6013 11.1806 14.4206C11.9785 13.24 13.1104 12.6104 13.7089 13.0153C14.3073 13.4202 14.1448 14.7043 13.3469 15.885Z"
        fill="url(#paint1_linear_1429_26481)"
      />
      <path
        d="M23.0304 22.7469C21.4344 22.7469 21.3665 23.9689 19.4647 23.9689C17.5629 23.9689 16.5448 23.1872 14.7789 22.1351C13.3057 21.2573 11.5753 21.4391 10.6665 21.2559L11.8871 21.739C11.8871 21.739 12.6141 23.3557 14.5307 24.3191C17.3088 25.717 20.5996 24.1078 22.03 24.2053C23.8106 24.328 26.0138 24.7166 26.4231 24.6856C26.2207 24.5245 26.0523 22.7469 23.0304 22.7469Z"
        fill="url(#paint2_linear_1429_26481)"
      />
      <path
        d="M23.0304 22.7469C21.4344 22.7469 21.3665 23.9689 19.4647 23.9689C17.5629 23.9689 16.5448 23.1872 14.7789 22.1351C13.3057 21.2573 11.5753 21.4391 10.6665 21.2559L11.8871 22.2814C11.8871 22.2814 12.6141 23.3542 14.5307 24.3191C17.3088 25.717 20.5996 24.1078 22.03 24.2053C23.8106 24.328 26.0138 24.7166 26.4231 24.6856C26.2207 24.5245 26.0523 22.7469 23.0304 22.7469Z"
        fill="url(#paint3_linear_1429_26481)"
      />
      <path
        d="M23.0304 22.7476C21.4344 22.7476 21.3665 23.9697 19.4647 23.9697C17.5629 23.9697 16.5448 23.188 14.7789 22.1359C13.3057 21.2581 11.5753 21.4399 10.6665 21.8004L11.8871 22.2836C11.8871 22.2836 12.3215 22.9175 13.3899 23.6475C13.5185 23.5515 13.5805 23.9076 13.548 23.5973C13.2673 20.9035 16.0719 24.3022 18.584 24.2342C21.4049 24.1618 23.0304 22.7476 23.0304 22.7476Z"
        fill="url(#paint4_linear_1429_26481)"
      />
      <path
        d="M6.74606 18.9785L3.78476 23.2535C3.52766 23.6244 2.75778 25.5469 2.27457 26.7807C2.15931 27.0763 2.47407 27.2935 2.70902 27.0807C3.67839 26.2104 5.18565 24.8494 5.54617 24.4726L8.6922 19.9331C7.79374 19.9154 7.03125 19.6834 6.74606 18.9785Z"
        fill="url(#paint5_linear_1429_26481)"
      />
      <path
        d="M2.30127 27.0628C2.3855 27.1795 2.56283 27.2135 2.70912 27.082C3.67849 26.2116 5.18574 24.8506 5.54631 24.4738L8.69229 19.9344C8.41596 19.9285 8.15736 19.8989 7.91649 19.8457L5.18871 23.7764C5.00544 23.9581 3.89274 25.5201 2.30127 27.0628Z"
        fill="url(#paint6_linear_1429_26481)"
      />
      <path
        d="M7.01041 19.4159C6.80502 19.2741 6.84638 19.2283 6.74588 18.9785L3.78462 23.2535C3.52749 23.6244 2.75761 25.5469 2.2744 26.7807C2.15914 27.0763 3.93976 23.8461 4.16584 23.518L7.01041 19.4159Z"
        fill="url(#paint7_linear_1429_26481)"
      />
      <path
        d="M10.6592 23.5966C11.8369 24.9103 12.8772 25.2486 13.851 25.2486C14.8248 25.2486 15.0287 24.7728 14.8248 24.4226C14.6208 24.0724 14.0327 23.3705 13.1048 23.2345C12.1767 23.0986 11.3847 20.9929 11.3847 20.9929L10.2306 20.9707L9.14453 22.3287L10.6592 23.5966Z"
        fill="url(#paint8_linear_1429_26481)"
      />
      <path
        d="M10.6592 23.5966C11.8369 24.9103 12.8772 25.2486 13.851 25.2486C14.8248 25.2486 15.0287 24.7728 14.8248 24.4226C14.6208 24.0724 14.0327 23.3705 13.1048 23.2345C12.1767 23.0986 11.3847 20.9929 11.3847 20.9929L10.2306 20.9707L9.14453 22.3287L10.6592 23.5966Z"
        fill="url(#paint9_linear_1429_26481)"
      />
      <path
        d="M11.1688 23.9261C10.7653 23.1681 11.3638 22.5903 12.1751 22.4972C11.7037 21.8425 11.383 20.9929 11.383 20.9929L10.2289 20.9707L9.63641 21.711L9.5625 21.9223C9.5625 21.9223 11.6328 24.798 11.1688 23.9261Z"
        fill="url(#paint10_linear_1429_26481)"
      />
      <path
        d="M11.3833 20.9941C11.6035 21.4493 11.8236 21.9029 12.1118 22.3152C12.397 22.7098 12.7265 23.1486 13.2274 23.19C13.7388 23.2935 14.2012 23.5831 14.5426 23.9658C14.6992 24.1653 14.8914 24.3559 14.9268 24.6352C14.9431 24.9278 14.6771 25.114 14.4421 25.1716C14.6756 25.0933 14.9002 24.8879 14.8603 24.6411C14.8219 24.4106 14.6254 24.217 14.4658 24.0382C14.1214 23.685 13.6841 23.4117 13.2009 23.323C12.9482 23.2979 12.6837 23.1546 12.5049 22.9743C12.3172 22.794 12.1591 22.5901 12.0246 22.3714C11.8902 22.1541 11.7705 21.9295 11.6626 21.7005C11.5606 21.4685 11.4764 21.2276 11.3833 20.9941Z"
        fill="#804B24"
      />
      <path
        d="M8.64319 23.8001C8.98309 24.9779 10.3869 25.8142 11.3592 25.7241C11.968 25.6679 12.1291 24.7281 11.405 24.0041C10.6809 23.28 10.2258 22.0299 10.2258 22.0299L9.5712 19.998L8.55307 22.6017L7.89697 24.0055L8.64319 23.8001Z"
        fill="url(#paint11_linear_1429_26481)"
      />
      <path
        d="M8.64319 23.8001C8.98309 24.9779 10.3869 25.8142 11.3592 25.7241C11.968 25.6679 12.1291 24.7281 11.405 24.0041C10.6809 23.28 10.2258 22.0299 10.2258 22.0299L9.5712 19.998L8.55307 22.6017L7.89697 24.0055L8.64319 23.8001Z"
        fill="url(#paint12_linear_1429_26481)"
      />
      <path
        d="M10.7684 23.1491C10.427 22.5713 10.226 22.029 10.226 22.029L9.89356 20.9961L9.1562 21.0597L8.55331 22.6009L8.20898 23.3368L8.43803 23.8569L8.64346 23.8008C8.7986 24.3372 9.17836 24.7967 9.63203 25.1351C9.15473 24.2662 9.90245 23.2703 10.7684 23.1491Z"
        fill="url(#paint13_linear_1429_26481)"
      />
      <path
        d="M11.8826 25.115C11.8752 24.7204 11.6742 24.3687 11.4127 24.0983C11.129 23.822 10.9103 23.4821 10.7196 23.1407C10.3384 22.4536 10.0266 21.7325 9.77539 20.9922C10.1123 21.697 10.4478 22.4019 10.832 23.0757C11.0329 23.4067 11.2236 23.7437 11.4984 24.017C11.7688 24.3141 11.9417 24.7219 11.8826 25.115Z"
        fill="#804B24"
      />
      <path
        d="M6.20508 23.4806C6.20508 24.5327 7.53352 25.7252 8.32702 25.7252C9.12055 25.7252 9.14272 24.6613 9.00678 24.2534C8.8708 23.8456 8.67134 22.9486 8.67134 22.9486L7.98865 22.5098L6.20508 23.4806Z"
        fill="url(#paint14_linear_1429_26481)"
      />
      <path
        d="M6.20508 23.4806C6.20508 24.5327 7.53352 25.7252 8.32702 25.7252C9.12055 25.7252 9.14272 24.6613 9.00678 24.2534C8.8708 23.8456 8.67134 22.9486 8.67134 22.9486L7.98865 22.5098L6.20508 23.4806Z"
        fill="url(#paint15_linear_1429_26481)"
      />
      <path
        d="M8.80582 23.5249C8.73044 23.2161 8.66984 22.9486 8.66984 22.9486L7.98715 22.5098L6.20508 23.4806C6.20508 24.1249 6.70304 24.8194 7.27195 25.2627C7.11235 24.3702 8.20439 23.7776 8.80582 23.5249Z"
        fill="url(#paint16_linear_1429_26481)"
      />
      <path
        d="M8.96418 25.2873C9.03659 25.0242 9.0307 24.7553 8.99669 24.4967C8.95386 24.2499 8.84151 23.9825 8.79276 23.7194C8.66271 23.1934 8.55783 22.6614 8.47656 22.125C8.63909 22.6422 8.77949 23.1653 8.91542 23.6884C8.97602 23.9559 9.07649 24.1923 9.11493 24.4819C9.14005 24.7597 9.09865 25.0479 8.96418 25.2873Z"
        fill="#804B24"
      />
      <path
        d="M4.81761 21.8778C4.84566 23.0717 5.94951 23.5638 6.8317 23.5638C7.71388 23.5638 9.58018 22.5058 10.2245 22.03C10.8702 21.5541 13.0114 21.0843 14.7772 22.1364C16.5431 23.1885 17.5612 23.9702 19.463 23.9702C21.3648 23.9702 21.4328 22.7481 23.0287 22.7481C26.3904 22.7481 26.2205 24.9558 26.4924 24.6839C26.7643 24.412 27.7484 22.1378 27.7484 19.9641C27.7484 18.5263 27.4056 17.7639 27.1751 17.0472C27.0731 16.731 26.7628 16.5448 26.4333 16.5847C24.069 16.8699 20.5669 13.9647 18.173 12.7988C15.5929 11.5428 13.4872 10.2188 11.9253 10.2188C10.3634 10.2188 5.30376 13.0367 4.65801 13.6825C4.01226 14.3282 3.74184 15.0745 3.74184 15.9921C3.74184 16.9098 3.16406 19.082 3.16406 20.9498C3.16406 22.4437 3.53348 23.0023 4.06251 22.6816C4.19548 22.6003 4.33881 22.4644 4.48806 22.2738C4.53535 22.2191 4.81907 21.9664 4.81761 21.8778C4.81761 21.164 5.37175 20.2434 6.32927 19.4174C6.46671 19.2992 6.57902 18.9667 6.6736 18.7081C6.6736 18.7081 6.94547 17.4181 7.21738 16.8285C7.48926 16.2404 7.89711 15.8783 8.53105 15.7424C10.3368 15.3552 11.7007 13.0028 12.6065 13.5465C13.5124 14.0903 12.5607 16.1754 10.7491 16.8093C8.9374 17.4432 7.98726 18.1643 6.76521 19.0701C5.54316 19.976 4.81761 21.0621 4.81761 21.8778Z"
        fill="url(#paint17_linear_1429_26481)"
      />
      <path
        d="M26.4332 16.5851C24.0689 16.8703 20.5668 13.9652 18.1729 12.7993C16.2372 11.8565 14.5688 10.8768 13.1975 10.4453L12.605 13.547C12.7882 13.6563 12.8872 13.8056 12.9183 13.977C12.9448 14.0745 12.9508 14.1824 12.9463 14.2962C12.9463 14.308 12.9448 14.3198 12.9448 14.3316C12.9375 14.4469 12.9153 14.5681 12.8784 14.6937C14.8836 12.5081 16.1455 12.3175 17.304 12.7904C19.2782 13.5958 21.4815 16.8999 24.1635 16.8999C26.8337 16.8999 27.54 18.478 27.5459 18.4884C27.4321 17.9017 27.2903 17.4038 27.1765 17.0491C27.1248 16.891 27.0228 16.7654 26.8913 16.6841C26.7628 16.6058 26.6061 16.5703 26.4451 16.5866C26.4406 16.5866 26.4377 16.5851 26.4332 16.5851Z"
        fill="url(#paint18_radial_1429_26481)"
      />
      <path
        d="M18.1747 12.7977C16.7132 12.0869 15.4054 11.354 14.2602 10.8516L13.0264 13.5942C13.4697 13.2129 14.377 12.9617 16.2389 13.4434C20.0425 14.4276 22.1807 19.6911 24.1165 19.6128C25.8616 19.5419 25.7922 18.3449 27.6275 18.9552C27.5063 18.1647 27.3201 17.4923 27.1768 17.046C27.1265 16.8879 27.0231 16.7623 26.8916 16.681C26.7601 16.5998 26.6005 16.5643 26.435 16.5835C24.0692 16.8687 20.5671 13.9636 18.1747 12.7977Z"
        fill="url(#paint19_linear_1429_26481)"
      />
      <path
        d="M12.6053 13.5457C13.5111 14.0895 12.5595 16.1745 10.7478 16.8085C9.38098 17.2872 8.50322 17.8162 7.62988 18.4354C8.80318 17.735 10.1405 17.6434 11.1571 17.1468C13.5894 15.9603 13.6131 13.2871 13.6131 13.2871L12.6053 13.5457Z"
        fill="url(#paint20_linear_1429_26481)"
      />
      <path
        d="M11.9253 10.2188C10.3634 10.2188 5.30376 13.0367 4.65801 13.6825C4.01226 14.3282 3.74184 15.0745 3.74184 15.9921C3.74184 16.9098 3.16406 19.082 3.16406 20.9498C3.16406 21.1744 3.17293 21.3753 3.18919 21.5586C3.57339 19.5962 3.92507 14.8883 4.99048 13.9795C6.30268 12.8594 10.854 10.2099 12.8563 10.5158C13.0543 10.5453 13.2065 10.5306 13.338 10.4936C12.8282 10.3207 12.3553 10.2188 11.9253 10.2188Z"
        fill="url(#paint21_linear_1429_26481)"
      />
      <path
        d="M27.1767 17.0475C27.0747 16.7313 26.7644 16.5451 26.4349 16.585C24.4193 16.8273 21.5792 14.6063 19.3021 13.3828C19.2946 14.8162 19.4217 16.282 19.206 17.578C18.2603 20.6515 15.9772 21.4614 13.2065 21.5751C13.7326 21.6623 14.2705 21.8352 14.7788 22.1381C16.5447 23.1903 17.5628 23.9719 19.4646 23.9719C21.3664 23.9719 21.4343 22.7499 23.0303 22.7499C26.392 22.7499 26.2221 24.9576 26.494 24.6857C26.7659 24.4138 27.75 22.1396 27.75 19.9659C27.75 18.5266 27.4072 17.7642 27.1767 17.0475Z"
        fill="url(#paint22_radial_1429_26481)"
      />
      <path
        d="M12.8477 13.7814C12.8684 13.8154 12.8816 13.8524 12.8935 13.8893L13.0265 13.5938C12.957 13.6543 12.8964 13.7164 12.8477 13.7814Z"
        fill="url(#paint23_linear_1429_26481)"
      />
      <path
        d="M6.67357 18.7069C6.67357 18.7069 6.94545 17.4169 7.21736 16.8273C7.48923 16.2392 7.89708 15.8771 8.53102 15.7412C8.83987 15.6747 9.13391 15.5491 9.41617 15.3925C10.6796 14.132 12.274 11.1914 11.779 11.7189C8.63445 15.0644 8.64626 14.3167 7.32225 15.6407C5.99823 16.9647 6.38835 18.4424 5.69238 19.1384C5.08653 19.7443 3.92651 21.188 4.06395 22.6775C4.19696 22.5977 4.34028 22.4632 4.491 22.2726C4.53682 22.2179 4.82201 21.9667 4.81905 21.8766C4.81905 21.8766 4.76733 21.4214 5.18553 20.7388C5.1885 20.7343 5.19292 20.7299 5.19588 20.7254C5.45448 20.291 5.83867 19.8418 6.33075 19.4177C6.37653 19.3541 6.4209 19.2862 6.46668 19.2197C6.54352 19.0675 6.61297 18.8739 6.67357 18.7069Z"
        fill="url(#paint24_linear_1429_26481)"
      />
      <path
        d="M26.4941 24.6823C26.5991 24.5774 26.8103 24.171 27.0305 23.5903C24.8583 20.858 22.2398 21.5644 21.4567 22.2382C20.641 22.9401 19.2608 23.7543 17.563 23.0539C15.8651 22.352 15.277 19.998 12.9452 19.998C10.6134 19.998 8.9377 21.458 7.85158 22.0018C6.90141 22.4776 5.96605 22.4466 4.84888 21.4905L4.81787 21.8777C4.84742 23.1396 6.01922 23.5637 6.83196 23.5637C7.71415 23.5637 9.58048 22.5057 10.2247 22.0299C10.8705 21.554 13.0117 21.0841 14.7775 22.1363C16.5433 23.1884 17.5615 23.9701 19.4633 23.9701C21.3651 23.9701 21.433 22.748 23.029 22.748C26.3922 22.7465 26.2222 24.9542 26.4941 24.6823Z"
        fill="url(#paint25_radial_1429_26481)"
      />
      <path
        d="M26.4939 24.6842C26.584 24.5941 26.7525 24.2749 26.9372 23.8213C26.7038 23.3913 26.2693 23.0337 25.5556 22.8061C21.718 21.5841 21.718 23.6218 18.8321 23.7045C15.9462 23.7887 15.0285 20.7344 13.3986 20.7344C9.8034 20.7344 7.98285 23.3765 5.46045 23.1341C5.86976 23.4356 6.39877 23.5612 6.83175 23.5612C7.71394 23.5612 9.58024 22.5032 10.2245 22.0273C10.8703 21.5515 13.0114 21.0816 14.7773 22.1337C16.5431 23.1859 17.5613 23.9675 19.4631 23.9675C21.3649 23.9675 21.4328 22.7455 23.0288 22.7455C26.392 22.7485 26.222 24.9561 26.4939 24.6842Z"
        fill="url(#paint26_linear_1429_26481)"
      />
      <path
        d="M6.32962 19.416C6.32816 19.4175 6.32666 19.419 6.3252 19.419C6.32812 19.4175 6.32816 19.4175 6.32962 19.416Z"
        fill="url(#paint27_linear_1429_26481)"
      />
      <path
        d="M13.7593 15.7268C14.609 15.5687 15.0449 15.1949 15.7335 15.0678C16.4221 14.9407 19.5238 14.6482 19.7869 16.1377C20.0499 17.6272 17.8806 18.4783 16.6113 19.1078C15.3419 19.7373 12.0821 19.4122 11.6654 18.2301C11.2487 17.0479 12.4235 15.9751 13.7593 15.7268Z"
        fill="url(#paint28_radial_1429_26481)"
      />
      <path
        d="M8.17351 14.9508C7.75827 15.4103 7.08297 15.3379 6.74753 15.7089C6.4121 16.0798 5.26688 19.7237 4.46892 18.979C3.67098 18.2342 4.4246 15.6394 4.87235 14.7277C5.3201 13.8159 9.13992 11.3718 9.89206 11.8713C10.6427 12.3707 8.82518 14.2297 8.17351 14.9508Z"
        fill="url(#paint29_radial_1429_26481)"
      />
      <path
        d="M19.7321 3.57881C19.6212 3.38966 19.4321 3.12959 19.1558 2.93749C18.8809 2.74687 18.578 2.6656 18.3652 2.63013C18.2071 2.60353 18.0387 2.68185 17.9367 2.82962L6.74463 18.9793C6.75498 19.0059 6.77122 19.0266 6.78303 19.0517C6.85398 18.9985 6.919 18.9409 6.98699 18.8862L6.95002 18.9306C8.08492 18.0897 9.03063 17.4085 10.7477 16.8071C10.8024 16.7879 10.8526 16.7598 10.9058 16.7391L11.5885 15.7535L19.6966 4.05315C19.8015 3.90536 19.8148 3.71771 19.7321 3.57881Z"
        fill="url(#paint30_linear_1429_26481)"
      />
      <path
        d="M8.02428 17.9523L18.0992 3.39557C18.38 2.93158 18.5026 2.65229 18.3667 2.63013C18.2086 2.60353 18.0401 2.68185 17.9382 2.82962L6.74609 18.9793C6.75941 19.0118 6.76529 19.034 6.77564 19.0606C7.22339 18.7281 7.61647 18.3409 8.02428 17.9523Z"
        fill="url(#paint31_radial_1429_26481)"
      />
      <path
        d="M19.6983 4.0527C19.7101 4.03496 19.7175 4.01426 19.7278 3.99506C19.7588 3.93596 19.781 3.8739 19.7855 3.81184C19.7913 3.72908 19.7736 3.64781 19.7322 3.57836C19.7308 3.5754 19.7293 3.57244 19.7278 3.57097C19.6658 3.46458 19.5771 3.33897 19.4648 3.21484L9.75488 17.2056C10.0593 17.0697 10.3844 16.9367 10.7493 16.8096C10.804 16.7904 10.8543 16.7623 10.9075 16.7416L11.5902 15.756L19.6983 4.0527Z"
        fill="url(#paint32_linear_1429_26481)"
      />
      <path
        d="M23.0304 22.7478C22.5295 22.7478 22.0403 22.9104 21.634 23.1985C21.2276 23.4778 20.8109 23.7807 20.3159 23.9019C19.3303 24.1206 18.2929 23.9802 17.359 23.6197C16.4251 23.2443 15.5947 22.6754 14.7361 22.1774C13.8864 21.6765 12.8683 21.5021 11.8915 21.5938C11.4039 21.6381 10.9118 21.7415 10.4744 21.9543C10.0666 22.2026 9.64101 22.504 9.20215 22.7316C8.76329 22.9709 8.31258 23.1911 7.84416 23.3699C7.3831 23.5502 6.86001 23.6803 6.35909 23.5606C5.86551 23.4645 5.3764 23.2222 5.07644 22.801C4.77794 22.3843 4.7026 21.8198 4.87401 21.3425C5.21534 20.3806 5.97486 19.658 6.76544 19.0684C5.98818 19.6742 5.24785 20.4175 4.93754 21.3617C4.77794 21.8272 4.85478 22.3592 5.13996 22.7537C5.42665 23.1512 5.89803 23.3818 6.37533 23.4704C6.86148 23.5813 7.34766 23.4556 7.80722 23.2739C8.26825 23.0951 8.71304 22.8719 9.14748 22.6311C9.59076 22.3961 9.97941 22.1124 10.4183 21.842C10.8778 21.6159 11.3773 21.5051 11.8797 21.4578C12.8816 21.3706 13.9234 21.5568 14.7937 22.0784C15.6523 22.5853 16.4754 23.1572 17.393 23.534C18.3062 23.8975 19.3361 24.0393 20.2996 23.8354C20.7858 23.7275 21.201 23.4409 21.6177 23.1675C22.0315 22.8897 22.5295 22.736 23.0304 22.7478Z"
        fill="#804B24"
      />
      <path
        d="M12.852 13.9334C12.8919 14.0516 12.9126 14.1757 12.9067 14.3014C12.8269 15.0653 12.3097 15.7288 11.7172 16.1957C11.1305 16.7041 10.3325 16.8518 9.63066 17.2065C8.91692 17.5331 8.25197 17.9572 7.63135 18.4345C8.28152 18.0015 8.95829 17.6084 9.6676 17.2892C10.0237 17.1311 10.3872 16.9907 10.7552 16.8651C11.1305 16.7366 11.4866 16.5474 11.7999 16.3021C12.4057 15.8042 12.9244 15.1185 12.9953 14.3058C13.0013 14.1491 12.9791 13.9881 12.9141 13.8418L12.852 13.9334Z"
        fill="#804B24"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1429_26481"
          x1="13.779"
          y1="16.7428"
          x2="5.85264"
          y2="8.44469"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1429_26481"
          x1="11.6181"
          y1="24.701"
          x2="12.5859"
          y2="10.3881"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1429_26481"
          x1="18.6149"
          y1="24.693"
          x2="18.7041"
          y2="26.8358"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1429_26481"
          x1="19.6849"
          y1="29.9607"
          x2="18.188"
          y2="20.7987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1429_26481"
          x1="17.3616"
          y1="30.0109"
          x2="16.8168"
          y2="21.8075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1429_26481"
          x1="1.10867"
          y1="19.5463"
          x2="5.62905"
          y2="23.6194"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#0097D4" />
          <stop offset="0.2368" stopColor="#0294D6" />
          <stop offset="0.4442" stopColor="#0989DB" />
          <stop offset="0.6411" stopColor="#1477E4" />
          <stop offset="0.8304" stopColor="#245EF0" />
          <stop offset="1" stopColor="#3640FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1429_26481"
          x1="2.09096"
          y1="20.1279"
          x2="5.37399"
          y2="23.3781"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#0097D4" />
          <stop offset="0.2368" stopColor="#0294D6" />
          <stop offset="0.4442" stopColor="#0989DB" />
          <stop offset="0.6411" stopColor="#1477E4" />
          <stop offset="0.8304" stopColor="#245EF0" />
          <stop offset="1" stopColor="#3640FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1429_26481"
          x1="3.41859"
          y1="22.1588"
          x2="5.51911"
          y2="23.5641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0097D4" stopOpacity="0" />
          <stop offset="0.3342" stopColor="#029AD7" stopOpacity="0.3342" />
          <stop offset="0.6287" stopColor="#08A3E2" stopOpacity="0.6287" />
          <stop offset="0.9072" stopColor="#13B2F4" stopOpacity="0.9072" />
          <stop offset="1" stopColor="#17B9FC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1429_26481"
          x1="12.0949"
          y1="25.3299"
          x2="12.1855"
          y2="27.5032"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1429_26481"
          x1="12.2789"
          y1="32.3496"
          x2="11.9167"
          y2="20.0345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1429_26481"
          x1="11.5059"
          y1="26.1099"
          x2="10.9286"
          y2="22.5783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1429_26481"
          x1="9.92872"
          y1="25.4198"
          x2="10.0193"
          y2="27.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1429_26481"
          x1="10.933"
          y1="29.9211"
          x2="8.98612"
          y2="19.734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1429_26481"
          x1="9.65641"
          y1="26.0004"
          x2="9.48661"
          y2="23.2159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1429_26481"
          x1="7.69642"
          y1="25.5139"
          x2="7.78694"
          y2="27.6872"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1429_26481"
          x1="8.49445"
          y1="27.6834"
          x2="6.45704"
          y2="19.1036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1429_26481"
          x1="7.79729"
          y1="28.437"
          x2="7.50299"
          y2="23.4793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#C86F34" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1429_26481"
          x1="28.7466"
          y1="29.2893"
          x2="4.02587"
          y2="11.7222"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <radialGradient
          id="paint18_radial_1429_26481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.2666 3.62317) scale(38.0437 38.0438)"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </radialGradient>
        <linearGradient
          id="paint19_linear_1429_26481"
          x1="14.5711"
          y1="13.0252"
          x2="23.7169"
          y2="17.3717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBB47" stopOpacity="0.9686" />
          <stop offset="0.0026513" stopColor="#FFBA47" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1429_26481"
          x1="10.5499"
          y1="14.9691"
          x2="11.0254"
          y2="20.8097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBB47" stopOpacity="0.9686" />
          <stop offset="0.0026513" stopColor="#FFBA47" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1429_26481"
          x1="9.30775"
          y1="17.2884"
          x2="3.50109"
          y2="10.2593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBB47" stopOpacity="0.9686" />
          <stop offset="0.0026513" stopColor="#FFBA47" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint22_radial_1429_26481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.2473 19.1784) rotate(-72.5489) scale(11.7496 8.35282)"
        >
          <stop stopColor="#FFBB47" stopOpacity="0.9686" />
          <stop offset="0.0026513" stopColor="#FFBA47" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint23_linear_1429_26481"
          x1="18.9408"
          y1="20.2625"
          x2="14.051"
          y2="14.9314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBB47" stopOpacity="0.9686" />
          <stop offset="0.0026513" stopColor="#FFBA47" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1429_26481"
          x1="5.05601"
          y1="1.20577"
          x2="9.90052"
          y2="27.7828"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <radialGradient
          id="paint25_radial_1429_26481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.1783 10.2865) scale(41.8031 41.8031)"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </radialGradient>
        <linearGradient
          id="paint26_linear_1429_26481"
          x1="20.6932"
          y1="11.2849"
          x2="14.1735"
          y2="28.9765"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1429_26481"
          x1="6.32894"
          y1="19.4179"
          x2="6.32786"
          y2="19.4171"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <radialGradient
          id="paint28_radial_1429_26481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.2018 16.7912) rotate(169.5) scale(2.98495 1.51634)"
        >
          <stop stopColor="#FBE07A" />
          <stop offset="0.2654" stopColor="#FCDF73" stopOpacity="0.7346" />
          <stop offset="0.6548" stopColor="#FDDB5F" stopOpacity="0.3452" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_1429_26481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.69552 14.3197) rotate(-47.8592) scale(2.99339 1.5323)"
        >
          <stop stopColor="#FBE07A" />
          <stop offset="0.2654" stopColor="#FCDF73" stopOpacity="0.7346" />
          <stop offset="0.6548" stopColor="#FDDB5F" stopOpacity="0.3452" />
          <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint30_linear_1429_26481"
          x1="8.78174"
          y1="8.19394"
          x2="16.3655"
          y2="13.2649"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#0097D4" />
          <stop offset="0.2368" stopColor="#0294D6" />
          <stop offset="0.4442" stopColor="#0989DB" />
          <stop offset="0.6411" stopColor="#1477E4" />
          <stop offset="0.8304" stopColor="#245EF0" />
          <stop offset="1" stopColor="#3640FF" />
        </linearGradient>
        <radialGradient
          id="paint31_radial_1429_26481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.6635 7.90102) rotate(-55.4788) scale(10.4549 0.223931)"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint32_linear_1429_26481"
          x1="8.06078"
          y1="5.7459"
          x2="16.6632"
          y2="11.6091"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#0097D4" />
          <stop offset="0.2368" stopColor="#0294D6" />
          <stop offset="0.4442" stopColor="#0989DB" />
          <stop offset="0.6411" stopColor="#1477E4" />
          <stop offset="0.8304" stopColor="#245EF0" />
          <stop offset="1" stopColor="#3640FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WritingHandIcon;
