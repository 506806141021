const Quill = window.Quill;
const OldVideo = Quill.import('formats/video');

export default function BetterVideoModule() {
  class BetterVideo extends OldVideo {
    static create(value) {
      super.create(value);
      const video = document.createElement('video');
      const div = document.createElement('div');
      video.loop = false;
      video.autoplay = false;
      video.controls = true;
      video.src = value;
      video.playsInline = true;
      div.classList.add('ql-video');
      div.append(video);

      return div;
    }

    static value(domNode) {
      if (domNode && domNode.getAttribute && domNode.getAttribute('src')) {
        return domNode.getAttribute('src');
      } else if (domNode && domNode.querySelector) {
        const video = domNode.querySelector('video');

        if (video && video.src) {
          return video.src;
        }
      }

      return '';
    }
  }

  Quill.register('formats/video', BetterVideo, true);
}
