import styles from './YoutubeBlot.module.scss';

let ytblot = null;

if (window.Quill) {
  const Quill = window.Quill;
  const EmbedBlock = Quill.import('blots/embed');

  ytblot = class YoutubeBlot extends EmbedBlock {
    static blotName = 'youtube';
    static create(value) {
      const vidID = value
        .match(
          //eslint-disable-next-line
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/
        )
        .pop();

      value = `https://www.youtube.com/watch?v=${vidID}`;
      const node = super.create(value);
      const div = document.createElement('div');
      const div1 = document.createElement('div');
      const img = document.createElement('img');
      const a = document.createElement('a');
      const imgURL = `https://img.youtube.com/vi/${vidID}/maxresdefault.jpg`;

      a.setAttribute('href', value);
      a.setAttribute('target', '_blank');
      img.setAttribute('src', imgURL);
      img.setAttribute('alt', `Preview for Youtube: ${vidID}`);
      node.setAttribute('data-url', value);
      div.classList.add(styles.ql_youtube_placeholder);
      div1.classList.add(styles.ql_youtube_play);
      a.classList.add(styles.ql_youtube_redirect_link);

      div.append(img);
      div.append(div1);
      div.append(a);
      node.append(div);
      node.setAttribute('content-editable', false);

      return node;
    }

    static value(domNode) {
      if (domNode && domNode.dataset) {
        return domNode.dataset.url;
      }

      return '';
    }

    static formats() {
      return true;
    }

    update(mutations, context) {
      for (const mutation of mutations) {
        if (mutation.type !== 'childList') continue;
        if (toString(mutation.removedNodes.length) === '0') continue;

        const nodeType = mutation.removedNodes[0].nodeType;
        setTimeout(() => this._remove(nodeType), 0);
        return;
      }

      const unhandledMutations = mutations.filter(m => m.type !== 'childList');
      super.update(unhandledMutations, context);
    }
  };

  ytblot.blotName = 'youtube';
  ytblot.className = 'ql-youtube';
  ytblot.tagName = 'P';
}

export function destroyYTBlot() {
  if (ytblot) {
    ytblot = null;
  }
}

export default function YoutubeBlotModule() {
  return ytblot;
}
