import styles from './AvatarPremium.module.scss';
import cx from 'classnames';
import { isEmpty, isNumber, isString } from 'src/helpers/utils';
import { VipCrownIcon } from 'src/icons';

const AvatarPremium = (props) => {
  const { height, width } = props;
  return (
    <div
      className={cx(styles.avatar_premium, styles.flex_row_xy, {
        [props?.className]:
          isString(props?.className) && !isEmpty(props?.className),
      })}
    >
      <VipCrownIcon
        height={isNumber(height) && height ? height : 14}
        width={isNumber(width) && width ? width : 14}
      />
    </div>
  );
};

export default AvatarPremium;
