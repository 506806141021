import { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './SpaceMemberRole.module.scss';
import i18n from 'src/locales';
// import LeaveSpace from 'src/modals/leave-space';
// import k from 'src/constants/k';
import RadioButton from '../radio-button';
import { DeleteIcon as TrashIcon } from 'src/icons';
import {
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
  isMobileView,
} from 'src/helpers/utils';
import {
  ChevronDownIcon,
  Button,
  IconButton,
  WarningSignIcon,
  Spinner,
} from 'evergreen-ui';
import { filter, head, isArray, isEmpty } from 'lodash';
import MessageHover from '../message-hover';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withModalPromptSettings } from 'src/managers/modal';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import {
  getSpaceRoleNameFormat,
  isMemberAdmin,
  isMemberOnly,
  isUserCreator,
} from 'src/helpers/spaces';

const SpaceMemberWithRole = (props) => {
  const [showMore, setShowMore] = useState(false);
  const [removingMember, setRemovingMember] = useState(false);
  const [leavingSpace, setLeavingSpace] = useState(false);
  const [updatingMemberRole, setUpdatingMemberRole] = useState(false);
  const avatarImageRef = useRef();
  const {
    user,
    role = [],
    roleId: currentRoleId = '',
    roleIds: currentRoleIds = [],
    allowedToAssignRoles = false,
    availableRoles = [],
    isThemeDarkMode,
    memberInfo = null,
    selectedSpace: space,
  } = props || {};

  const memberUserId = useMemo(
    () => getUserIdFromObject(memberInfo),
    [memberInfo]
  );
  const memberProfileId = useMemo(
    () => getUserProfileIdFromObject(memberInfo),
    [memberInfo]
  );
  const uniqueMoreActions = useMemo(
    () =>
      `${'uniqueSpaceMemberMoreActions'}_${memberUserId || memberProfileId}`,
    [memberUserId, memberProfileId]
  );
  const isLoading = useMemo(
    () => !memberInfo || isEmpty(memberInfo),
    [memberInfo]
  );

  const image = useMemo(() => memberInfo?.image, [memberInfo]);

  const memberDisplayName = useMemo(
    () => getUserDisplayName(memberInfo),
    [memberInfo]
  );

  const initials = useMemo(
    () => `${head(memberDisplayName)}${memberDisplayName?.charAt(1)}`,
    [memberDisplayName]
  );

  const spaceRoles = useMemo(
    () => space?.settings?.availableRoles || [],
    [space]
  );

  const memberSpaceRole = head(
    filter(spaceRoles, (currentRole) => currentRole?.id === currentRoleId) || []
  );

  const isUser = useMemo(() => {
    const currentUserId = getUserIdFromObject(user);
    const currentUserProfileId = getUserProfileIdFromObject(user);

    return (
      currentUserId === memberUserId || currentUserProfileId === memberProfileId
    );
  }, [user, memberUserId, memberProfileId]);

  const spaceId = useMemo(() => space?.id, [space]);

  const roleInString = useMemo(
    () =>
      memberInfo?.roleName ||
      getSpaceRoleNameFormat(
        memberSpaceRole || memberInfo?.role || memberInfo?.roles || []
      ),
    [memberInfo, memberSpaceRole]
  );

  const isMemberCreator = useMemo(
    () => isUserCreator(memberInfo),
    [memberInfo]
  );
  const canLeaveSpace = useMemo(
    () => isUser && !isMemberCreator,
    [isUser, isMemberCreator]
  );
  const canRemoveMember = useMemo(() => {
    return !canLeaveSpace && isUserCreator(memberInfo);
  }, [memberInfo, canLeaveSpace]);

  useEffect(() => {
    if (avatarImageRef?.current?.setAttribute && image) {
      avatarImageRef.current.setAttribute('src', image);
    }
  }, [image, avatarImageRef]);

  const removeMember = async () => {
    if (removingMember) {
      return;
    }

    try {
      setRemovingMember(true);
      setShowMore(false);
      // await ProfileAPI.SPACES.removeMembers([profileId], spaceId);
    } catch {
    } finally {
      setRemovingMember(false);
    }
  };

  const leaveSpace = async () => {
    if (leavingSpace) {
      return;
    }

    try {
      // setLeavingSpace(true);
      // await ProfileAPI.SPACES.leaveAsMember(spaceId);
      // ModalAPI.goDeactivate();
    } catch {
      setLeavingSpace(false);
    }
  };

  const promptLeaveSpace = () => {
    // ModalAPI.goDeactivate();
    // ModalAPI.setDOM(<LeaveSpace confirm={() => leaveSpace()} />);
    // ModalAPI.goActivate();
  };

  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (!evt) {
        return;
      }

      const target = evt.target;

      if (
        !(
          target?.classList?.length > 1 &&
          target.classList.contains(uniqueMoreActions)
        ) &&
        showMore
      ) {
        if (
          target?.classList?.length &&
          (target?.nodeName === 'svg' || target?.nodeName === 'path')
        ) {
          return;
        }

        setShowMore(false);
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [showMore, uniqueMoreActions]);

  const withActions = useMemo(
    () =>
      (canRemoveMember || canLeaveSpace) &&
      !isMemberCreator &&
      // block actions for mobile screen
      !isMobileView(),
    [canLeaveSpace, isMemberCreator, canRemoveMember]
  );

  const updateMemberRole = async (roleId = '') => {
    if (updatingMemberRole) {
      return;
    }

    try {
      setUpdatingMemberRole(true);

      // await ProfileAPI.SPACES.updateMembersRole(
      //   [{ roleId, userId: profileId }],
      //   spaceId
      // );
    } catch {
    } finally {
      setUpdatingMemberRole(false);
    }
  };

  const showMoreIcon = useMemo(
    () => withActions && !isLoading && !removingMember && !leavingSpace,
    [withActions, isLoading, removingMember, leavingSpace]
  );

  return (
    <div
      className={cx(styles.member, { [styles.member_dark]: isThemeDarkMode })}
    >
      <div className={styles.avatar}>
        <div
          className={cx(styles.avatar_image, {
            [styles.hide_element]: isLoading,
          })}
        >
          {!isEmpty(image) && (
            <img ref={avatarImageRef} alt={'space member avatar'} />
          )}

          <div
            className={cx(styles.initials, styles.flex_row_xy, {
              [styles.hide_element]: isLoading || !isEmpty(image),
            })}
          >
            <h5>{`${initials}`}</h5>
          </div>
        </div>
        {isLoading && (
          <div className={styles.avatar_placeholder}>
            <div></div>
            <div></div>
          </div>
        )}

        {!isLoading && (
          <div className={styles.name}>
            <h5>
              {memberDisplayName}
              {isUser && <i>{`(${i18n('common_you')})`}</i>}
            </h5>
          </div>
        )}
      </div>
      <div className={cx(styles.flex_row_xy, styles.role)}>
        {isLoading && <div className={styles.role_placeholder}></div>}

        <div
          className={cx(styles.role_raw, {
            [styles.hide_element]: isLoading || !roleInString,
          })}
        >
          {!removingMember && <h5>{`${roleInString}`}</h5>}
          <MessageHover
            className={styles.role_raw_tip}
            message={roleInString}
          />
        </div>
        <div
          className={cx(styles.more, uniqueMoreActions, {
            [styles.more_expand]: showMore,
            [styles.more_dark]: isThemeDarkMode,
            [styles.hide_element]: isMobileView() || true,
          })}
        >
          <>
            <IconButton
              icon={ChevronDownIcon}
              appearance="minimal"
              className={cx(
                styles.flex_row_xy,
                { [styles.invi]: !showMoreIcon },
                uniqueMoreActions
              )}
              onClick={() => {
                if (showMoreIcon) {
                  setShowMore(!showMore);
                }
              }}
            />
          </>
        </div>
        {(removingMember || leavingSpace) && (
          <div className={styles.loading}>
            <div className={styles.flex_row_xy}>
              <Spinner height={18} width={18} />
            </div>
          </div>
        )}
      </div>
      {withActions && false && showMore && !removingMember && !leavingSpace && (
        <div
          className={cx(
            styles.role_actions,
            {
              [styles.role_actions_dark]: isThemeDarkMode,
            },
            uniqueMoreActions
          )}
        >
          {!isUser && allowedToAssignRoles && isArray(availableRoles) && (
            <div
              className={cx(styles.assign_roles, {
                [styles.hide_element]: updatingMemberRole,
              })}
            >
              <div className={styles.assign_roles_title}>
                <h5>{i18n('space_roles_caps')}</h5>
              </div>
              {availableRoles
                .filter(
                  (r) =>
                    r &&
                    r?.from &&
                    r.i18n !== 'common_view_only' &&
                    r.i18n !== 'common_creator'
                )
                .map((roleInfo) => {
                  const isUserMemberOnly =
                    (!currentRoleId && isMemberOnly({ role }, spaceId)) ||
                    (roleInfo?.id === currentRoleId &&
                      roleInfo?.i18n === 'common_member');
                  const isUserAdmin =
                    (!currentRoleId && isMemberAdmin({ role }, spaceId)) ||
                    (roleInfo?.id === currentRoleId &&
                      roleInfo?.i18n === 'common_admin');
                  const roleId = `${roleInfo.id}`;
                  const isChecked =
                    currentRoleId === roleId ||
                    (isArray(currentRoleIds) &&
                      currentRoleIds.includes(roleId)) ||
                    (isUserMemberOnly && roleInfo.i18n === 'common_member') ||
                    (isUserAdmin && roleInfo.i18n === 'common_admin');
                  const roleName =
                    roleInfo?.from === 'default'
                      ? i18n(roleInfo.i18n)
                      : roleInfo?.roleName;

                  return (
                    <Button
                      onClick={() => {
                        if (!isChecked) {
                          updateMemberRole(roleId);
                        }
                      }}
                      key={roleId}
                      disabled={updatingMemberRole}
                      appearance={'minimal'}
                    >
                      <div className={styles.radio}>
                        <RadioButton
                          value={!!isChecked}
                          isThemeDarkMode={isThemeDarkMode}
                        />
                      </div>
                      <h5>{roleName}</h5>
                    </Button>
                  );
                })}
            </div>
          )}

          {canLeaveSpace && (
            <Button
              iconBefore={WarningSignIcon}
              appearance={'minimal'}
              className={cx(
                styles.leave,
                { [styles.leave_dark]: isThemeDarkMode },
                uniqueMoreActions
              )}
              onClick={() => promptLeaveSpace()}
            >
              <h5>{i18n('common_leave')}</h5>
            </Button>
          )}
          {canRemoveMember && (
            <Button
              iconBefore={TrashIcon}
              className={cx(
                styles.remove,
                { [styles.remove_dark]: isThemeDarkMode },
                uniqueMoreActions
              )}
              appearance={'minimal'}
              onClick={() => removeMember()}
            >
              <h5>{i18n('common_remove_title')}</h5>
              <div className={cx(styles.cover, uniqueMoreActions)}></div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(withModalPromptSettings(SpaceMemberWithRole))
);
