import cx from 'classnames';
import styles from './SubscribersPreview.module.scss';
import { withNoteViewContext } from './Context';
import { NoteViewModalType } from 'src/types';
import {
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
  isMobileView,
} from 'src/helpers/utils';
import { isArray, isEmpty, isFunction, size } from 'lodash';
import { MoreDotIcon } from 'src/icons';
import { Avatar, Tooltip } from '@chakra-ui/react';
import { Button, CrossIcon } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { useMemo } from 'react';
import { useWindowResize } from 'src/hooks';

const SubscribersPreview = (props) => {
  const {
    subscribers = [],
    showModalType,
    canModify,
    removeSubscriber,
    user,
    isThemeDarkMode,
  } = props;
  const { availableHeight } = useWindowResize();
  const max = useMemo(
    () => (availableHeight && isMobileView() ? 4 : 6),
    [availableHeight]
  );
  const showMoreButton = useMemo(
    () => size(subscribers) > max,
    [subscribers, max]
  );
  const preview = useMemo(() => {
    const val = [];
    if (isArray(subscribers)) {
      for (let i = 0; i < subscribers.length; i++) {
        const subscriber = subscribers[i];
        if ((i < max || canModify) && !isEmpty(subscriber)) {
          val.push(subscribers[i]);
        }
      }
    }

    return val;
  }, [subscribers, max, canModify]);

  const onShowMoreSubscribersList = () => {
    if (isFunction(showModalType)) {
      showModalType(NoteViewModalType.subscribers);
    }
  };

  const onRemoveSubscriber = (userId = '') => {
    if (isFunction(removeSubscriber)) {
      removeSubscriber(userId);
    }
  };

  return (
    <div className={cx(styles.flex_row_xy, styles.subscribers_preview)}>
      <ul className={cx({ [styles.can_modify]: canModify })}>
        {preview.map((userInfo) => {
          const { loading, image, nonUser, email } = userInfo;
          const userId = getUserIdFromObject(userInfo) || userInfo?.id;
          const userProfileId = getUserProfileIdFromObject(userInfo);
          const subscriberDisplayName = nonUser
            ? email
            : getUserDisplayName(userInfo);
          const key = nonUser
            ? `subscriberPreviewUserIdNonUser${email}`
            : `subscriberPreviewUserId${userId}`;

          return (
            <li key={key}>
              {loading || (!subscriberDisplayName && !nonUser) ? (
                <div className={cx(styles.flex_row_xy, styles.default_avatar)}>
                  <MoreDotIcon />
                </div>
              ) : (
                <div className={cx(styles.flex_row_xy, styles.avatar)}>
                  <Tooltip
                    label={`${subscriberDisplayName}${
                      nonUser ? ' (external)' : ''
                    }`}
                    className={styles.custom_tooltip}
                  >
                    <Avatar
                      height={'30px'}
                      width={'30px'}
                      src={nonUser ? '' : image}
                      name={subscriberDisplayName}
                      background={'#000'}
                      backgroundColor={'#000'}
                    />
                  </Tooltip>
                </div>
              )}
              {!loading &&
              canModify &&
              getUserIdFromObject(user) !== userId &&
              getUserProfileIdFromObject(user) !== userProfileId ? (
                <div className={cx(styles.flex_row_xy, styles.remove)}>
                  <Button
                    appearance="minimal"
                    onClick={() => onRemoveSubscriber(nonUser ? email : userId)}
                  >
                    <CrossIcon height={10} width={10} />
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </li>
          );
        })}
      </ul>
      {showMoreButton ? (
        <div
          className={cx(styles.flex_row_xy, styles.more, {
            [styles.more_dark]: isThemeDarkMode,
          })}
        >
          <Button
            appearance="minimal"
            className={styles.flex_row_xy}
            onClick={onShowMoreSubscribersList}
          >
            <MoreDotIcon height={20} width={20} />
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(SubscribersPreview)
);
