import contents from './content.json';
import styles from '../Docs.module.scss';
import innerStyles from './Share.module.scss';

const Share = () => {
  return (
    <div>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.title}>{contents.title}</h4>
      </div>
      <div className={innerStyles.wrapper}>
        <br />
        <br />
        <h2>{contents.content[0]}</h2>
        <br />
        <p>{contents.content[1]}</p>
        <br />
        <br />
        <h2>{contents.content[2]}</h2>
        <br />
        <br />
        <img alt={'Define your space'} src={'/docs-images/share-notes.png'} />
      </div>
    </div>
  );
};

export default Share;
