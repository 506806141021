import styles from './Docs.module.scss';
import Header from 'src/layout/docs/Header';
import Content from 'src/layout/docs/Content';

import Overview from './overview';
import About from './about';
import Privacy from './privacy';
import Terms from './terms';
import Intro from './intro';
import Define from './define';
import Create from './create';
import Organize from './organize';
import Share from './share';
import API from './chamu-api';
import Sidebar from './sidebar';
import NotFound from '../not-found';

import { withRouter, Switch, Route } from 'react-router-dom';
import { useWindowResize } from 'src/hooks';
import { useMemo } from 'react';

const Documentation = () => {
  const { availableWidth } = useWindowResize();

  const isMobileViewSize = useMemo(() => {
    return availableWidth <= 900;
  }, [availableWidth]);

  return (
    <div className={styles.docs_main_wrap}>
      <Header />
      <div className={styles.content}>
        {!isMobileViewSize && <Sidebar />}
        <Content>
          <Switch>
            <Route exact path={'/documentation'}>
              <Overview />
            </Route>
            <Route exact path={'/documentation/about'}>
              <About />
            </Route>
            <Route exact path={'/documentation/privacy'}>
              <Privacy />
            </Route>
            <Route exact path={'/documentation/terms'}>
              <Terms />
            </Route>
            <Route exact path={'/documentation/intro'}>
              <Intro />
            </Route>
            <Route exact path={'/documentation/define'}>
              <Define />
            </Route>
            <Route exact path={'/documentation/create'}>
              <Create />
            </Route>
            <Route exact path={'/documentation/organize'}>
              <Organize />
            </Route>
            <Route exact path={'/documentation/share-notes'}>
              <Share />
            </Route>
            <Route exact path={'/documentation/api-docs'}>
              <API />
            </Route>
            <Route path={'/documentation/*'}>
              <NotFound />
            </Route>
          </Switch>
        </Content>
      </div>
    </div>
  );
};

export default withRouter(Documentation);
