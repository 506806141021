import cx from 'classnames';
import styles from './MobileFilters.module.scss';
import CustomButton from 'src/components/button';
import TagsEmpty from 'src/components/tags-empty';
import TagUnit from 'src/pages/note-view/TagUnit';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withSpacesAndUserSettings } from 'src/managers/spaces';
import { FilterIcon } from 'src/icons';
import { useEffect, useMemo, useRef } from 'react';
import { filter, head, includes, isEmpty, isFunction, last, map } from 'lodash';
import { withNoteDataSettings } from 'src/managers/notes';
import { timeout } from 'src/helpers/utils';
import { withModalPromptSettings } from 'src/managers/modal';
import { isAllowedToCreateTags } from 'src/helpers/spaces';
import CreateTag from 'src/components/create-tag';

const MobileFilters = (props) => {
  const mobileFiltersItemSelectUnique = 'mobileFiltersItemSelectUnique';
  const tagsListRef = useRef();
  const {
    userPersonalTags = [],
    isThemeDarkMode,
    userSpaces,
    selectedSpace,
    selectedSpaceId,
    isPersonalSpaceSelected,
    tagsFilterApplied = [],
    applyTagFilter,
    profileLoaded,
    clearTagsApplied,
    closeModal,
    setActiveModalDom,
  } = props;

  const spaceTags = useMemo(() => {
    if (selectedSpace?.tags) {
      return selectedSpace?.tags;
    }

    const targetSpace = head(
      filter(userSpaces || [], (spaceInfo) => spaceInfo?.id === selectedSpaceId)
    );

    return targetSpace?.tags || [];
  }, [userSpaces, selectedSpaceId, selectedSpace]);

  const tags = useMemo(
    () => filter(isPersonalSpaceSelected ? userPersonalTags : spaceTags),
    [spaceTags, userPersonalTags, isPersonalSpaceSelected]
  );

  const canAddTags = useMemo(
    () =>
      selectedSpaceId === 'personal' ||
      !selectedSpaceId ||
      (!selectedSpace?.archived &&
        !isEmpty(selectedSpace) &&
        isAllowedToCreateTags(selectedSpace || {})),
    [selectedSpaceId, selectedSpace]
  );

  useEffect(() => {
    const dom = tagsListRef?.current;
    const onDocumentClick = (evt) => {
      if (!evt) {
        return;
      }

      const target = evt?.target || evt?.srcElement;

      if (
        !isEmpty(target?.classList) &&
        target?.classList?.contains(mobileFiltersItemSelectUnique)
      ) {
        const targetTagId = last(target?.classList);

        if (
          !isEmpty(targetTagId) &&
          targetTagId !== mobileFiltersItemSelectUnique
        ) {
          if (includes(tagsFilterApplied, targetTagId)) {
            if (isFunction(clearTagsApplied)) {
              clearTagsApplied();

              timeout(200).then(() => {
                if (isFunction(closeModal)) {
                  closeModal();
                }
              });
            }
          } else if (isFunction(applyTagFilter)) {
            applyTagFilter(targetTagId);
          }
        }
      }
    };

    dom.addEventListener('click', onDocumentClick, false);

    return () => {
      dom.removeEventListener('click', onDocumentClick, false);
    };
  }, [
    tags,
    clearTagsApplied,
    tagsFilterApplied,
    userSpaces,
    closeModal,
    applyTagFilter,
    profileLoaded,
  ]);

  const emptyTags = useMemo(() => !tags || isEmpty(tags), [tags]);

  const createNewNote = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(
        <CreateTag tagSpaceId={selectedSpaceId} selectedSpace={selectedSpace} />
      );
    }
  };

  return (
    <div className={styles.mobile_filters}>
      <div
        className={cx(styles.flex_row_xy, styles.title, {
          [styles.title_dark]: isThemeDarkMode,
        })}
      >
        <h5>Filter</h5>
        <FilterIcon />
      </div>

      {emptyTags && (
        <div className={cx(styles.flex_row_xy, styles.empty_tags)}>
          <TagsEmpty />
        </div>
      )}

      <div
        className={cx(styles.tags, {
          [styles.tags_dark]: isThemeDarkMode,
          [styles.hide_element]: emptyTags,
        })}
      >
        <ul ref={tagsListRef}>
          {map(tags, (tagInfo) => {
            const tagId = tagInfo?.id || tagInfo?.tagId;
            const isSelected = includes(tagsFilterApplied, tagId);
            const key = `mobileFiltersTag${tagId}`;

            return (
              <li
                key={key}
                className={cx(mobileFiltersItemSelectUnique, tagId)}
              >
                <TagUnit isActive={isSelected} tag={tagInfo} />
                <div
                  className={cx(
                    styles.cover,
                    mobileFiltersItemSelectUnique,
                    tagId
                  )}
                ></div>
              </li>
            );
          })}
        </ul>
      </div>
      {canAddTags && (
        <div className={cx(styles.cta, styles.flex_row_xy)}>
          <CustomButton
            className={styles.create_button}
            label={'Add new tag'}
            variant={'primaryBlue'}
            onClick={createNewNote}
            isThemeDarkMode={isThemeDarkMode}
          />
        </div>
      )}
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withSpacesAndUserSettings(
    withNoteDataSettings(withModalPromptSettings(MobileFilters))
  )
);
