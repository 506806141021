export const compressBase64FJPEG = (base64 = '', quality = 0.5) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      try {
        const h =
          typeof this.naturalHeight === 'number' && this.naturalHeight > 0
            ? this.naturalHeight
            : this.height;
        const w =
          typeof this.naturalWidth === 'number' && this.naturalWidth > 0
            ? this.naturalWidth
            : this.width;
        const canvas = document.createElement('canvas');
        canvas.width = Math.ceil(w * quality);
        canvas.height = Math.ceil(h * quality);
        canvas
          .getContext('2d')
          .drawImage(img, 0, 0, canvas.width, canvas.height);
        const value = canvas.toDataURL('image/jpeg', quality);

        resolve({ value, err: false });
        canvas.remove();
      } catch (err) {
        resolve({ err: true });
      }
    };

    img.crossOrigin = 'Anonymous';
    img.onerror = function () {
      resolve({ err: true });
    };

    img.src = base64;
  });
};

export const srcToBase64JPEG = (src = '', anonymous = true, quality = 1) => {
  return new Promise(async (resolve) => {
    const img = new Image();

    img.onload = function () {
      try {
        const h =
          typeof this.naturalHeight === 'number' && this.naturalHeight > 0
            ? this.naturalHeight
            : this.height;
        const w =
          typeof this.naturalWidth === 'number' && this.naturalWidth > 0
            ? this.naturalWidth
            : this.width;
        const canvas = document.createElement('canvas');
        canvas.width = Math.ceil(w * quality);
        canvas.height = Math.ceil(h * quality);
        canvas
          .getContext('2d')
          .drawImage(img, 0, 0, canvas.width, canvas.height);
        const value = canvas.toDataURL('image/jpeg', quality);

        resolve({ value, err: false });

        canvas.remove();
      } catch (err) {
        resolve({ err });
      } finally {
        img.remove();
      }
    };

    if (anonymous) {
      img.crossOrigin = 'Anonymous';
    }

    img.onerror = function (err) {
      img.remove();
      resolve({ err });
    };
    img.src = src;
  });
};

export const base64ToBlob = (b64Data = '', type = '') => {
  try {
    if (b64Data.indexOf('data:image') >= 0) {
      b64Data = b64Data.substring(
        b64Data.indexOf('base64,') + 'base64,'.length,
        b64Data.length
      );
    }

    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: type ? type : 'image/jpeg' });

    return blob;
  } catch (err) {
    console.error(err);

    return null;
  }
};
