const SidePanelMenuIcon = (p) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_25296)">
        <path
          d="M21 18V20H3V18H21ZM6.95 3.54999V13.45L2 8.49999L6.95 3.54999ZM21 11V13H12V11H21ZM21 3.99999V5.99999H12V3.99999H21Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_25296">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SidePanelMenuIcon;
