import { Node, mergeAttributes } from '@tiptap/core';
import { isValidObject } from 'src/helpers/utils';

const VideoExtension = Node.create({
  name: 'video',

  addOptions() {
    return {
      nested: false,
      width: '100%',
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      type: {
        default: '',
        parseHTML: () => 'video',
        renderHTML: () => ({
          'data-type': 'video',
        }),
      },
      src: {
        default: '',
        parseHTML: (element) => element?.getAttribute('data-src') || '',
        renderHTML: (attributes) => ({
          'data-src': attributes.src,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-type': 'video' }, HTMLAttributes),
      ['video', 0],
    ];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  draggable: false,

  // add to 'block' group
  group: 'block',

  inline: false,

  // non editable
  atom: true,

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const divVideo = document.createElement('div');
      const video = document.createElement('video');
      const src = HTMLAttributes['data-src'];

      video.autoplay = false;
      video.muted = false;
      video.controls = true;
      video.setAttribute('src', src);

      divVideo.append(video);

      if (isValidObject(HTMLAttributes)) {
        Object.entries(HTMLAttributes).forEach(([key, value]) => {
          divVideo.setAttribute(key, value);
        });
      }

      if (isValidObject(this.options.HTMLAttributes)) {
        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          divVideo.setAttribute(key, value);
        });
      }

      return {
        dom: divVideo,
      };
    };
  },
});

export default VideoExtension;
