import { lazy, Suspense, useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './Pages.module.scss';
import LogoLoadV2 from 'src/components/logo-load-v2';
import Documentation from './documentation';
import { Switch, Route, withRouter } from 'react-router-dom';
import {
  isCapacitorIos,
  isCapacitorMobileApp,
  isIosMobile,
  isMobileView,
} from 'src/helpers/utils';

import Payment from './payment';
import Pricing from './home/pricing';
import Landing from './home/landing';
import NotFound from './not-found';
import NetworkError from './network-error';
import TaskNavigate from './task-navigate';
import LifetimeCode from './lifetime-code';
import AuthPage from './auth';
import User from './user';
import NoteView from './note-view';
import { useWindowResize, useWindowScroll } from 'src/hooks';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { withNetworkSettings } from 'src/managers/network';
import NetworkOffline from 'src/components/network-offline';
import { withModalPromptSettings } from 'src/managers/modal';
import { isNumber } from 'lodash';

const ResetPassword = lazy(() => import('./reset-password'));
const VerifyEmail = lazy(() => import('./verify-email'));

export const AppRootContainerId = 'AppRootContainerId1';

const Pages = (props) => {
  const containerRef = useRef();
  const { scrollY } = useWindowScroll();
  const { availableHeight, availableWidth } = useWindowResize();
  const { isThemeDarkMode, isUserNetworkOffline } = props;

  /**
   * Set height
   */
  useEffect(() => {
    const dom = containerRef?.current;
    const body = document.body;
    const newH = `${availableHeight}px`;

    if (dom) {
      dom.style.height = newH;

      if (isMobileView()) {
        dom.style.minHeight = newH;
        dom.style.maxHeight = newH;
        dom.style.overflow = 'hidden';
        dom.style.overflowY = 'scroll';
      } else if (dom?.style.overflow) {
        dom.style.minHeight = '';
        dom.style.maxHeight = '';
        dom.style.overflow = '';
        dom.style.overflowY = '';
      }
    }

    if (body) {
      if (isMobileView()) {
        body.style.height = newH;
        body.style.overflow = 'hidden';
      } else {
        body.style.height = '';
        body.style.maxHeight = '';
        body.style.overflow = '';
      }
    }
  }, [availableHeight]);

  /**
   * Redirect user to domain without 'www' subdomain
   */
  useEffect(() => {
    try {
      if (!isCapacitorMobileApp()) {
        const origin = `${window.location.origin}`;

        if (
          origin &&
          (origin?.startsWith('www.') || origin?.startsWith('https://www.'))
        ) {
          // redirect
          window.location.replace(
            `https://getchamu.com${window.location.pathname || '/'}`
          );
        }
      }
    } catch {}
  }, []);

  // init
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * No scrollY for mobile screens
   */
  useEffect(() => {
    if (isNumber(availableWidth) && availableWidth < 900 && scrollY) {
      window.scrollTo(0, 0);
    }
  }, [scrollY, availableWidth]);

  return (
    <div
      id={AppRootContainerId}
      ref={containerRef}
      className={cx(styles.container, {
        // for ios mobile
        [styles.container_ios]: isIosMobile(),
        [styles.container_ios_mobile_app]: isCapacitorIos(),
        [styles.darkmode_theme]: isThemeDarkMode,
      })}
    >
      <Suspense fallback={<LogoLoadV2 />}>
        <Switch>
          <Route path={'/verify-email'}>
            <VerifyEmail />
          </Route>
          <Route path={'/login'}>
            <AuthPage />
          </Route>
          <Route path={'/forgot-password'}>
            <AuthPage />
          </Route>
          <Route path={'/sign-up'}>
            <AuthPage />
          </Route>
          <Route path={'/documentation'}>
            <Documentation />
          </Route>
          <Route path={'/pricing'}>
            <Pricing />{' '}
          </Route>
          <Route path={'/network-error'}>
            <NetworkError />
          </Route>
          <Route path={'/reset-password'}>
            <ResetPassword />
          </Route>
          <Route path={'/view'}>
            <TaskNavigate />
          </Route>
          <Route path={'/payment'}>
            <Payment />
          </Route>
          <Route path={'/lifetime-code'}>
            <LifetimeCode />
          </Route>
          <Route path={'/note'}>
            <NoteView />
          </Route>
          <Route path={['/user']}>
            <User />
          </Route>
          <Route path={'/not-found'}>
            <NotFound />
          </Route>
          <Route exact path={['/', '/index', '/home']}>
            <Landing />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
      {isUserNetworkOffline && <NetworkOffline />}
    </div>
  );
};

export default withNetworkSettings(
  withUserDataAndProfileSettings(withModalPromptSettings(withRouter(Pages)))
);
