import styles from './AddSubscribers.module.scss';
import cx from 'classnames';
import { withNoteViewContext } from './Context';
import { Button, CrossIcon, TextInput } from 'evergreen-ui';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getUserDisplayName,
  getUserIdFromObject,
  getUserProfileIdFromObject,
  isMobileView,
  timeout,
} from 'src/helpers/utils';
import {
  toLower,
  toString,
  trim,
  filter,
  head,
  isEmpty,
  isFunction,
  isNumber,
  includes,
  last,
  size,
} from 'lodash';
import { useDebounce, useWindowScroll } from 'src/hooks';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';
import { NoteViewContainerId } from '.';
import { Spinner } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { Avatar } from '@chakra-ui/react';
import { getUserInfoByIdOrUsernameOrEmailRequest } from 'src/managers/api';

const AddSubscribers = (props) => {
  const wrapRef = useRef(null);
  const searchInputRef = useRef(null);
  const AddSubscribersSelectClassName = 'AddSubscribersSelectClassNameUnique';
  const AddSubscribersSelectRemoveClassName =
    'AddSubscribersSelectRemoveClassNameUnique';
  const {
    connectedUsers,
    subscribers,
    // updateConnectedUsers,
    addSubscriber,
    removeSavedUser,
    isCreateMode,
    authorId,
    isThemeDarkMode,
    // spaceInfo,
    user: currentUser,
  } = props;
  const currentUserId = useMemo(
    () => getUserIdFromObject(currentUser),
    [currentUser]
  );
  const currentUserProfileId = useMemo(
    () => getUserProfileIdFromObject(currentUser),
    [currentUser]
  );
  const [searchInput, setSearchInput] = useState('');
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const { scrollY } = useWindowScroll();
  // const spaceMembers = useMemo(() => spaceInfo?.members || [], [spaceInfo]);
  const availableUsers = useMemo(() => [...connectedUsers], [connectedUsers]);

  const onInput = (evt) => {
    const value = toString(evt?.target?.value);
    setSearchInput(value);

    if (!value) {
      setResults([]);
      setSearching(false);
      return;
    }

    if (!searching) {
      setSearching(true);
    }

    search(value);
  };

  const sanitizedSearchInput = useMemo(
    () => toLower(trim(searchInput)),
    [searchInput]
  );

  const search = useDebounce(async (input = '') => {
    try {
      input = input || searchInput;
      const sanitizedSearchInput = toLower(trim(input));
      const find = availableUsers.filter((userInfo) => {
        const fullName = getUserDisplayName(userInfo);
        const fullEmail = toLower(toString(userInfo?.email));
        const fullNameSanitized = toLower(trim(fullName));
        const targetUserId = getUserIdFromObject(userInfo);
        const targetUserProfileId = getUserProfileIdFromObject(userInfo);

        const notUser =
          targetUserId !== currentUserId &&
          targetUserProfileId !== currentUserId &&
          targetUserProfileId !== currentUserProfileId;

        if (
          notUser &&
          (includes(fullNameSanitized, sanitizedSearchInput) ||
            sanitizedSearchInput === fullEmail ||
            fullEmail?.includes(sanitizedSearchInput))
        ) {
          return true;
        }

        return false;
      });

      if (!isEmpty(find)) {
        setResults(find);
        setSearching(false);
        return;
      } else {
        setSearching(true);
      }

      if (
        input?.includes('gmai.co') ||
        input?.includes('gma.co') ||
        input?.endsWith('yaho.co')
      ) {
        // catch incorrect formats
        await timeout(400);

        setResults([{ nonUser: true, email: sanitizedSearchInput }]);
      } else {
        const { user: targetUser } =
          await getUserInfoByIdOrUsernameOrEmailRequest(sanitizedSearchInput);
        const targetUserId = getUserIdFromObject(targetUser);

        if (!isEmpty(targetUser) && !targetUser?.deleted && targetUserId) {
          setResults([targetUser]);
        } else if (isCorrectEmailFormat(sanitizedSearchInput)) {
          setResults([{ nonUser: true, email: sanitizedSearchInput }]);
        }
      }
    } catch {
    } finally {
      setSearching(false);
    }
  }, 300);

  const preview = useMemo(() => {
    if (!isEmpty(sanitizedSearchInput)) {
      return results;
    }

    return filter(availableUsers, (userInfo) => {
      const fullName = getUserDisplayName(userInfo);
      const targetUserId = getUserIdFromObject(userInfo);
      const targetUserProfileId = getUserProfileIdFromObject(userInfo);
      const userEmail = userInfo?.email || '';

      return (
        (!isEmpty(fullName) || !isEmpty(userEmail)) &&
        ((isCreateMode &&
          currentUserId !== targetUserId &&
          currentUserId !== targetUserProfileId) ||
          (!isCreateMode &&
            authorId !== targetUserId &&
            authorId !== targetUserProfileId))
      );
    });
  }, [
    sanitizedSearchInput,
    results,
    currentUserId,
    authorId,
    isCreateMode,
    availableUsers,
  ]);

  /**
   * Close on outside area click
   */
  useEffect(() => {
    const onDocumentClick = (evt) => {
      if (
        (evt?.target && wrapRef?.current?.contains(evt?.target)) ||
        evt?.target?.classList?.contains(AddSubscribersSelectClassName)
      ) {
        if (evt?.target?.classList?.contains(AddSubscribersSelectClassName)) {
          const targetUserId = last(evt.target.classList);

          if (
            !isEmpty(targetUserId) &&
            targetUserId !== AddSubscribersSelectClassName
          ) {
            if (
              evt?.target?.classList?.contains(
                AddSubscribersSelectRemoveClassName
              )
            ) {
              // remove from saved users
              if (isFunction(removeSavedUser)) {
                removeSavedUser(targetUserId);
              }
            } else {
              // add as sub
              const user = head(
                filter(preview || [], (user) => {
                  const userId = getUserIdFromObject(user);
                  const userProfileId = getUserProfileIdFromObject(user);

                  if (
                    user?.email === targetUserId ||
                    userId === targetUserId ||
                    userProfileId === targetUserId
                  ) {
                    return true;
                  }

                  return false;
                })
              );

              if (user && !isEmpty(user) && isFunction(addSubscriber)) {
                addSubscriber(user);

                if (size(results) === 1) {
                  setSearchInput('');
                }

                if (searchInputRef?.current) {
                  searchInputRef.current.blur();
                }
              }
            }
          }
        }

        return;
      }

      setShowResults(false);
      if (searchInputRef?.current) {
        searchInputRef.current.blur();
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [
    preview,
    addSubscriber,
    results,
    removeSavedUser,
    subscribers,
    showResults,
  ]);

  const onInputFocus = () => {
    setShowResults(true);
  };

  /**
   * When dropdown is active and user scrolls down, hide it
   */
  useEffect(() => {
    if (!isMobileView() && scrollY > 80) {
      setShowResults(false);

      if (searchInputRef?.current?.blur) {
        searchInputRef?.current.blur();
      }
    }
  }, [scrollY]);

  /**
   * When dropdown is active and user scrolls down in mobile screen, hide it
   */
  useEffect(() => {
    const dom = document.getElementById(NoteViewContainerId);
    const onScroll = () => {
      if (!isMobileView()) {
        return;
      }

      const targetDom = document.getElementById(NoteViewContainerId);

      if (isNumber(targetDom?.scrollTop) && targetDom?.scrollTop > 60) {
        setShowResults(false);

        if (searchInputRef?.current?.blur) {
          searchInputRef?.current.blur();
        }
      }
    };

    if (dom) {
      dom.addEventListener('scroll', onScroll, false);
    }

    return () => {
      if (dom) {
        dom.removeEventListener('scroll', onScroll, false);
      }
    };
  }, []);

  const onClear = () => {
    setShowResults(false);
    setSearchInput('');
    setResults([]);

    if (searchInputRef?.current) {
      searchInputRef?.current?.blur();
    }
  };

  return (
    <div ref={wrapRef} className={styles.add_subscribers}>
      <div
        className={cx(
          styles.input,
          {
            [styles.input_dark]: isThemeDarkMode,
            [styles.input_filled]: searchInput,
          },
          AddSubscribersSelectClassName
        )}
        onClick={() => {
          setShowResults(true);

          if (searchInputRef?.current) {
            searchInputRef?.current.focus();
          }
        }}
      >
        <TextInput
          ref={searchInputRef}
          className={AddSubscribersSelectClassName}
          placeholder="Add people via email"
          value={searchInput}
          onChange={onInput}
          onFocus={() => onInputFocus()}
        />
        <div
          className={cx(
            styles.results,
            {
              [styles.results_dark]: isThemeDarkMode,
              [styles.hide_element]: !showResults,
            },
            AddSubscribersSelectClassName
          )}
        >
          <div
            className={cx(
              styles.flex_row_xy,
              styles.results_content,
              AddSubscribersSelectClassName
            )}
          >
            <ul
              className={cx(
                { [styles.hide_element]: !preview?.length || searching },
                AddSubscribersSelectClassName
              )}
            >
              {preview.map((userInfo) => {
                const { image, blockRemove, email, nonUser } = userInfo;
                const userId = getUserIdFromObject(userInfo);
                const userDisplayName = nonUser
                  ? email
                  : getUserDisplayName(userInfo);
                const key = nonUser
                  ? `AddSubscriberPreviewNonUser${email}`
                  : `AddSubscriberPreview${userId}`;

                return (
                  <li
                    key={key}
                    className={cx(
                      styles.flex_row_xy,
                      AddSubscribersSelectClassName,
                      nonUser ? email : userId
                    )}
                  >
                    <div
                      className={cx(
                        styles.flex_row_xy,
                        {
                          [styles.result_content_expand]:
                            !userInfo?.saved || sanitizedSearchInput,
                          [styles.result_content_min]: userInfo?.saved,
                        },
                        AddSubscribersSelectClassName,
                        nonUser ? email : userId
                      )}
                    >
                      <Avatar
                        className={styles.avatar}
                        name={userDisplayName}
                        src={nonUser ? '' : image}
                        height={'30px'}
                        width={'30px'}
                        backgroundColor={'#000'}
                        background={'#000'}
                      />
                      <p className={AddSubscribersSelectClassName}>
                        {userDisplayName}
                      </p>
                      <div
                        className={cx(
                          styles.cover,
                          AddSubscribersSelectClassName,
                          nonUser ? email : userId
                        )}
                      ></div>{' '}
                    </div>

                    {userInfo?.saved &&
                    !sanitizedSearchInput &&
                    !blockRemove ? (
                      <div
                        className={cx(
                          styles.preview_remove,
                          { [styles.preview_remove_dark]: isThemeDarkMode },
                          AddSubscribersSelectClassName,
                          AddSubscribersSelectRemoveClassName,
                          nonUser ? email : userId
                        )}
                      >
                        <Button
                          appearance="minimal"
                          className={cx(
                            styles.flex_row_xy,
                            AddSubscribersSelectClassName,
                            AddSubscribersSelectRemoveClassName,
                            nonUser ? email : userId
                          )}
                        >
                          <CrossIcon />
                        </Button>
                        <div
                          className={cx(
                            styles.cover,
                            AddSubscribersSelectClassName,
                            AddSubscribersSelectRemoveClassName,
                            nonUser ? email : userId
                          )}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </li>
                );
              })}
            </ul>
            {!preview?.length && !searching && (
              <div
                className={cx(
                  styles.flex_column_xy,
                  styles.results_content_empty,
                  { [styles.results_content_empty_dark]: isThemeDarkMode }
                )}
              >
                {!isEmpty(sanitizedSearchInput) && <h3>{'No results.'}</h3>}
                <p>
                  {!isEmpty(sanitizedSearchInput)
                    ? 'Make sure email or user id is correct.'
                    : 'Empty search history.'}
                </p>
              </div>
            )}
            {searching && (
              <div
                className={cx(
                  styles.loading,
                  { [styles.loading_dark]: isThemeDarkMode },
                  styles.flex_row_xy
                )}
              >
                <Spinner height={18} width={18} />
              </div>
            )}
          </div>
        </div>

        <Button
          appearance="minimal"
          className={cx(styles.flex_row_xy, styles.clear, {
            [styles.clear_dark]: isThemeDarkMode,
            [styles.hide_element]: !searchInput || searching,
          })}
          onClick={onClear}
        >
          <CrossIcon />
        </Button>
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(AddSubscribers)
);
