import { isNil } from 'src/helpers/utils';
import k from 'src/constants/k';
class Config {
  query = { err: false };
  app = { clientVersion: '', rememberLogin: true };
  verbose = true;
  onBlur = false;
  IS_DEV = false;
  DEBUG_MODE = false;
  HAS_NEW_VERSION_DETECTED = false;
  HAS_LOCAL_STORAGE_AVAILABLE = true;
  HAS_WINDOW_SELECTION = true;
  HAS_WINDOW_CLIPBOARD = true;
  HAS_CONTENT_EDITABLE = false;

  setApp(new_app) {
    if (!isNil(new_app)) {
      this.app = { ...this.app, ...new_app };
    }
  }

  getQuery() {
    const cp = { ...this.query };

    delete cp['err'];
    return cp;
  }

  isDebugMode() {
    return this.DEBUG_MODE;
  }

  isDev() {
    return this.IS_DEV;
  }

  start() {
    this.IS_DEV = !!(
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_ENVIRONMENT === 'development' ||
      process.env.REACT_APP_ENVIRONMENT === 'staging'
    );

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      this.IS_DEV = false;
    }

    this.DEBUG_MODE =
      !!process.env.REACT_APP_DEBUG_MODE ||
      process.env.REACT_APP_ENVIRONMENT === 'debug';

    this.IS_STAGING = Boolean(
      `${window.location.origin}`.includes('stage.getchamu.com') &&
        `${window.location.origin}`.includes('https://')
    );

    this.checkMachineCompatible();
    this.getQueryJSON();
    this.getStoredConfigs();
  }

  checkMachineCompatible() {
    console.log(`DASHBOARD: Checking machine compatability`);

    this.HAS_LOCAL_STORAGE_AVAILABLE = !!(
      localStorage &&
      typeof localStorage === 'object' &&
      !!localStorage.getItem
    );

    if (this.HAS_LOCAL_STORAGE_AVAILABLE) {
      console.log(`DASHBOARD: User has localstorage`);
    }

    this.HAS_WINDOW_CLIPBOARD = !!(window.Clipboard || window.clipboardData);

    if (this.HAS_WINDOW_CLIPBOARD) {
      console.log(`DASHBOARD: User has window clipboard`);
    }

    this.HAS_WINDOW_SELECTION = !!window.getSelection;
    if (this.HAS_WINDOW_SELECTION)
      console.log(`DASHBOARD: User has 'getSelection'`);

    try {
      const element = document.createElement('div');
      element.setAttribute('contentEditable', true);

      if (element.isContentEditable) {
        this.HAS_CONTENT_EDITABLE = true;
        console.log(`DASHBOARD: Machine has contentEditable attribute`);
      }
    } catch (err) {
      console.error(
        `DASHBOARD: Failed in checking machine compatability: ${err.message}`
      );
    }
  }

  getStoredConfigs() {
    try {
      if (this.HAS_LOCAL_STORAGE_AVAILABLE) {
        const storedSTR = localStorage.getItem('__CHAMU__LOCAL__');

        if (storedSTR && typeof storedSTR === 'string') {
          this.app = { ...this.app, ...JSON.parse(storedSTR) };
        } else {
          this.HAS_NEW_VERSION_DETECTED = true;
        }

        if (
          !this.app.clientVersion ||
          this.app.clientVersion.length < k.REACT_APP_CLIENT_VERSION.length ||
          this.app.clientVersion !== k.REACT_APP_CLIENT_VERSION
        ) {
          this.app.clientVersion = k.REACT_APP_CLIENT_VERSION;
          this.HAS_NEW_VERSION_DETECTED = true;
        }

        if (this.verbose) {
          console.log(
            `DASHBOARD: Got stored configs: ${JSON.stringify({
              clientVersion: this.app.clientVersion,
            })}`
          );
        }
      }
    } catch (err) {
      if (this.verbose) {
        console.log(`DASHBOARD: Failed getting stored config`, err);
      }
    }
  }

  storedConfigs() {
    try {
      localStorage.setItem('__CHAMU__LOCAL__', JSON.stringify(this.app));
    } catch {}
  }

  getQueryJSON() {
    try {
      const queryString = String(
        window.decodeURI(window.location.search)
      ).substring(1);

      if (queryString.length > 0) {
        this.query = queryString.split('&').reduce((accumulator, current) => {
          const keyValuePair = String(current).split('=');

          return {
            ...accumulator,
            [`${keyValuePair[0]}`]: keyValuePair[1],
          };
        }, {});
      } else {
        this.query = { err: false };
      }
    } catch (err) {
      /**
       * When error's raised due to unavailable API,
       * prompt error that the application is not available for the user's device
       */
      if (!this.query) {
        this.query = { err: false };
      }

      this.query.err = true;

      if (this.verbose)
        console.log(
          `DASHBOARD: Error: Parsing query parameters: ${err.message}`
        );
    }
  }

  removeSearchFromQuerySTR() {
    const queryString = String(
      window.decodeURI(window.location.search)
    ).substring(1);
    const startIdx = queryString.indexOf('q=');
    const endIdx = queryString
      .substring(startIdx, queryString.length)
      .indexOf('&');
    const newQueryString = `${queryString.substring(
      0,
      startIdx -
        (startIdx > 0 && queryString.charAt(startIdx - 1) === '&' ? 1 : 0)
    )}${endIdx > -1 ? queryString.substring(startIdx + endIdx) : ''}`;

    return `/user${
      newQueryString
        ? `${
            newQueryString.charAt(0) === '&'
              ? `?${newQueryString.substring(1)}`
              : `?${newQueryString}`
          }`
        : ''
    }`;
  }
}

export default Config;
