const HashTagIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_22_1934)">
        <path
          d="M5.18917 9.33333L5.46917 6.66667H2.6665V5.33333H5.60984L5.95984 2H7.3005L6.9505 5.33333H9.60984L9.95984 2H11.3005L10.9505 5.33333H13.3332V6.66667H10.8105L10.5305 9.33333H13.3332V10.6667H10.3898L10.0398 14H8.69917L9.04917 10.6667H6.38984L6.03984 14H4.69917L5.04917 10.6667H2.6665V9.33333H5.18917ZM6.52984 9.33333H9.18984L9.46984 6.66667H6.80984L6.52984 9.33333Z"
          fill="#2D3748"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1934">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HashTagIcon;
