import cx from 'classnames';
import styles from './EmptyList.module.scss';
import CustomButton from 'src/components/button';
import i18n from 'src/locales';
import { withRouter } from 'react-router-dom';
import { NoteListCtaIconDark, NoteListCtaIconLight } from 'src/icons';
import { Button, PlusIcon } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { isFunction } from 'lodash';

function isMobileView() {
  return window.innerWidth <= 800;
}

const EmptyList = (props) => {
  const { isThemeDarkMode } = props;

  const onCreateNewNote = () => {
    if (isFunction(props?.history?.push)) {
      props?.history.push('/note');
    }
  };

  return (
    <div className={cx(styles.flex_colmn_xy, styles.empty_list)}>
      <div className={cx(styles.flex_row_xy, styles.icon)}>
        {isThemeDarkMode && <NoteListCtaIconDark />}
        {!isThemeDarkMode && <NoteListCtaIconLight />}
        <h3>Create your first note!</h3>
      </div>

      <div className={cx(styles.message, styles.flex_row_xy)}>
        <p>
          {!isMobileView()
            ? `To add content, click on`
            : 'To add content, press'}
        </p>

        {!isMobileView() && (
          <CustomButton
            isThemeDarkMode={isThemeDarkMode}
            className={styles.create_button}
            label={i18n('common_create')}
            variant={'primaryBlue'}
            onClick={onCreateNewNote}
          />
        )}

        {isMobileView() && (
          <Button
            appearance="minimal"
            className={styles.create_button}
            onClick={onCreateNewNote}
          >
            <PlusIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

export default withUserDataAndProfileSettings(withRouter(EmptyList));
