import cx from 'classnames';
import styles from './FilesUploading.module.scss';
import { withNoteViewContext } from './Context';
import { filter, isArray, isEmpty, isMobileView } from 'src/helpers/utils';
import { Spinner } from 'evergreen-ui';
import { withUserDataAndProfileSettings } from 'src/managers/profile';

const FilesUploading = ({
  uploadingFiles = [],
  loading,
  isCreateMode,
  isEditMode,
  isThemeDarkMode,
}) => {
  const canModifyEditor = isCreateMode || isEditMode;
  const uploadingFilesPreview = [];
  const max = isMobileView() ? 5 : 7;

  if (isArray(uploadingFiles) && uploadingFiles?.length) {
    for (let i = 0; i < uploadingFiles.length; i++) {
      if (uploadingFiles?.length > max) {
        if (i >= uploadingFiles.length - max) {
          uploadingFilesPreview.push(uploadingFiles[i]);
        }
      } else {
        uploadingFilesPreview.push(uploadingFiles[i]);
      }
    }
  }

  return (
    <div
      className={cx(styles.flex_column_xy, styles.files_uploading, {
        [styles.files_uploading_dark]: isThemeDarkMode,
        [styles.hide_element]: loading || !canModifyEditor,
      })}
    >
      <ul>
        {filter(
          uploadingFilesPreview,
          (fileInfo) =>
            !isEmpty(fileInfo?.file?.name) && (fileInfo?.id || fileInfo?.refId)
        ).map((fileInfo) => {
          const fileId = fileInfo?.refId || fileInfo?.id;
          const fileName = fileInfo?.file?.name;

          return (
            <li
              key={`uploadingFile${fileId}`}
              className={cx(styles.flex_row_xy)}
            >
              <div
                className={cx(styles.flex_row_xy, styles.icon, {
                  [styles.icon_dark]: isThemeDarkMode,
                })}
              >
                <Spinner height={16} width={16} />
              </div>
              <p>{fileName}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(FilesUploading)
);
