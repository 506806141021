import Logger from 'src/lib/Logger';
import { isDev } from './utils';
import { includes, toLower } from 'lodash';

export function scaleImageToJpeg(
  src,
  type = 'jpeg',
  quality = 0.5,
  maxW = 0,
  maxH = 0
) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      try {
        let h =
          typeof this.naturalHeight === 'number' && this.naturalHeight > 0
            ? this.naturalHeight
            : this.height;
        let w =
          typeof this.naturalWidth === 'number' && this.naturalWidth > 0
            ? this.naturalWidth
            : this.width;

        if (w > maxW && maxW > 0) {
          const ratio = maxW / w;

          w = Math.ceil(ratio * w);

          if (maxH <= 0) {
            h = Math.ceil(ratio * h);
          }
        }

        if (h > maxH && maxH > 0) {
          const ratio = maxH / h;

          h = Math.ceil(ratio * h);
        }

        const fileType = type.indexOf('jpeg') > -1 ? 'image/jpeg' : 'image/png';
        const canvas = document.createElement('canvas');
        canvas.width = Math.ceil(w);
        canvas.height = Math.ceil(h);
        canvas
          .getContext('2d')
          .drawImage(img, 0, 0, canvas.width, canvas.height);
        const value = canvas.toDataURL(fileType, quality);
        resolve({ value, err: false });
      } catch (err) {
        if (err.message) {
          Logger.log(err.message);
        }

        if (err.stack) {
          Logger.log(err.stack);
        }

        resolve({ err: true });
      }

      img.remove();
    };

    if (isDev()) {
      img.crossOrigin = 'Anonymous';
    }

    img.onerror = function (err) {
      if (err.message) {
        Logger.log(err.message);
      }

      if (err.stack) {
        Logger.log(err.stack ? err.stack : '');
      }

      resolve({ err: true });

      img.remove();
    };

    img.src = src;
  });
}

export const getImageBase64 = (file) =>
  new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = async function () {
      resolve(this.result);
    };
    fr.onerror = () => {
      resolve('');
    };
    fr.readAsDataURL(file);
  });

export const isTypeJpeg = (type = '') => {
  return includes(type, 'image/jpeg') || includes(type, 'image/jpg');
};

export const isTypePng = (type = '') => {
  return includes(toLower(type), 'image/png');
};
