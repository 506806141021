import cx from 'classnames';
import styles from './MessageHover.module.scss';

const MessageHover = (props) => {
  return (
    <div
      className={cx(styles.message_hover, {
        [`${props.className}`]:
          typeof props.className === 'string' && !!props.className,
        [styles.hide_element]: !props.message,
      })}
    >
      <div className={styles.flex_row_xy}>
        <h5>{props.message || ''}</h5>
      </div>
    </div>
  );
};

export default MessageHover;
