const SearchIcon = (p) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_1205_27564)">
        <path
          d="M21.6359 19.3867L26.6328 24.3823L24.9819 26.0332L19.9863 21.0363C18.1275 22.5264 15.8154 23.3369 13.4331 23.3335C7.63711 23.3335 2.93311 18.6295 2.93311 12.8335C2.93311 7.0375 7.63711 2.3335 13.4331 2.3335C19.2291 2.3335 23.9331 7.0375 23.9331 12.8335C23.9365 15.2158 23.126 17.5279 21.6359 19.3867ZM19.2956 18.521C20.7762 16.9984 21.6031 14.9573 21.5998 12.8335C21.5998 8.32083 17.9446 4.66683 13.4331 4.66683C8.92044 4.66683 5.26644 8.32083 5.26644 12.8335C5.26644 17.345 8.92044 21.0002 13.4331 21.0002C15.5569 21.0035 17.598 20.1766 19.1206 18.696L19.2956 18.521Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_1205_27564">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.599609)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
