import { useEffect } from 'react';
import styles from './PdfViewer.module.scss';
import cx from 'classnames';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { withNoteViewContext } from './Context';
import { CrossIcon, IconButton } from 'evergreen-ui';
import {
  applyUIOverflow,
  isFunction,
  isMobileView,
  noUIOverflow,
} from 'src/helpers/utils';
import { NoteViewContainerId } from 'src/pages/note-view';

const PdfViewer = (props) => {
  const closeModal = props?.closeModal;

  /**
   * Close on outside area click
   */
  useEffect(() => {
    const onClick = (evt) => {
      if (
        isFunction(closeModal) &&
        evt?.target?.classList?.contains(styles.pdf_viewer)
      ) {
        closeModal();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [closeModal]);

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    bookmarkPluginInstance: null,
  });

  /**
   * No background scroll when PDF viewer is active
   */
  useEffect(() => {
    const dom = document.getElementById(NoteViewContainerId);

    noUIOverflow();

    if (dom && isMobileView()) {
      dom.style.overflow = 'hidden';
    }

    return () => {
      if (!isMobileView()) {
        applyUIOverflow();
      }

      if (dom?.style?.overflow) {
        dom.style.overflow = '';
      }
    };
  }, []);

  return (
    <div className={cx(styles.flex_row_xy, styles.pdf_viewer)}>
      <div className={cx(styles.flex_row_xy, styles.content)}>
        {props?.pdf && (
          <Viewer
            fileUrl={props?.pdf}
            plugins={[defaultLayoutPluginInstance]}
          />
        )}
      </div>

      <div className={cx(styles.flex_row_xy, styles.close)}>
        <IconButton
          icon={CrossIcon}
          appearance="minimal"
          onClick={() => {
            if (isFunction(closeModal)) {
              closeModal();
            }
          }}
        />
      </div>
    </div>
  );
};

export default withNoteViewContext(PdfViewer);
