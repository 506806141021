import FormatsToolbar from './FormatsToolbar';
import Editor from './Editor';
import i18n from 'src/locales';
import k from 'src/constants/k';
import { base64ToBlob } from 'src/lib/ImageFile';
import { withNoteViewContext } from './Context';
import { v4 as uuidV4 } from 'uuid';
import {
  isSupportedVideoFileFormat,
  isFileAllowed,
  getDataRefIdValue,
} from 'src/helpers/utils';
import {
  isNil,
  isNumber,
  isFunction,
  toString,
  filter,
  isEmpty,
  head,
  size,
  trim,
} from 'lodash';
import { scaleImageToJpeg } from 'src/helpers/images';
import { withUserDataAndProfileSettings } from 'src/managers/profile';
import { useToast } from '@chakra-ui/react';
import { uploadFileInNoteRequest } from 'src/managers/api/notes';
import { useAuth } from 'src/hooks';

const editorComponentFileInputUniqueId = 'editorComponentFileInputUniqueId';

const EditorHelperComponent = (props) => {
  const {
    children,
    EditorInstance,
    uRef,
    updateUploadingFilesList = null,
    userIsPremium,
  } = props;

  const toast = useToast({ position: 'top' });

  const { getAuthenticatedHeaders } = useAuth(props);

  const checkToUpload = async (uploadingFiles = []) => {
    let i = 0;

    if (!EditorInstance) {
      return;
    }

    const headers = getAuthenticatedHeaders();
    const totalCountFilesToUpload = size(uploadingFiles);

    while (i < uploadingFiles?.length) {
      try {
        const currentContent = EditorInstance.getJSON();
        const fileNames = currentContent?.content?.length
          ? filter(
              currentContent.content.map((content) =>
                content?.type === 'file' ? content.attrs.name : ''
              ),
              (fileName) => !isEmpty(fileName)
            )
          : [];
        const fileInfo = uploadingFiles[i];
        const file = fileInfo?.file;
        const size = isNumber(file?.size) ? file?.size : 0;
        const isVideoFile = fileInfo?.isVideoFile;
        const fileType = file?.type;
        const isImage = fileInfo?.isImage;
        let fileName = toString(file?.name);
        let uploadRes = null;
        const fileNameMatchCount = fileNames?.length
          ? fileNames.filter((currentName) => currentName.includes(fileName))
              ?.length
          : 0;
        const selection = EditorInstance?.view?.state?.selection;
        const ranges = selection?.ranges ? [...selection?.ranges] : [];
        const activeRange = head(ranges);
        const rangeFrom = activeRange?.$from?.pos || activeRange?.$to?.pos;

        if (!fileInfo) {
          break;
        }

        if (fileNameMatchCount > 0) {
          // modify filename
          const idxLastDotFileExtension = fileName.lastIndexOf('.');
          fileName =
            fileName.substring(
              0,
              idxLastDotFileExtension
                ? idxLastDotFileExtension
                : fileName.length
            ) +
            `(${fileNameMatchCount + 1})` +
            (idxLastDotFileExtension
              ? `.${fileName.substring(idxLastDotFileExtension + 1)}`
              : '');
        }

        if (isImage) {
          const getImageBlob = () =>
            new Promise((resolve) => {
              const fr = new FileReader();
              fr.onload = async function () {
                resolve(this.result);
              };
              fr.onerror = () => {
                resolve('');
              };
              fr.readAsDataURL(file);
            });
          const scaledown =
            // true
            file?.type &&
            (file?.type === 'image/svg+xml' ||
              file?.type === 'image/webp' ||
              file?.type === 'image/png' ||
              file?.type === 'image/gif' ||
              file?.type === 'image/avif') &&
            userIsPremium
              ? false
              : true;
          const type = !scaledown && fileType ? fileType : 'image/jpeg';
          let b64 = await getImageBlob();
          let imageBlob = null;

          if (scaledown) {
            const { value, err: scaleError } = await scaleImageToJpeg(
              b64,
              'image/jpeg',
              0.9,
              k.USER_TASK_DESCRIPTION_PROPERTIES.maxImageWidth
            );

            if (value && !scaleError) {
              b64 = value;
            }
          }

          imageBlob = base64ToBlob(b64);

          if (imageBlob) {
            uploadRes = await uploadFileInNoteRequest(
              imageBlob,
              fileName,
              uRef,
              type,
              headers
            );
          }
        } else {
          uploadRes = await uploadFileInNoteRequest(
            file,
            fileName,
            uRef,
            fileType,
            headers
          );
        }

        if (uploadRes && uploadRes?.fileUrl) {
          const url = uploadRes?.fileUrl;
          const refId = getDataRefIdValue(uploadRes?.props);
          const sanitizedUrl = trim(toString(url));
          const name = toString(fileName);
          const isPdf =
            name.indexOf('.pdf') + '.pdf'.length >= name.length &&
            sanitizedUrl &&
            sanitizedUrl.indexOf('.pdf') + '.pdf'.length >= sanitizedUrl.length;
          const totalNodeCount = currentContent?.content?.length + 1;
          const node = isImage
            ? {
                type: 'image',
                attrs: { type: 'image', src: url },
              }
            : isVideoFile
            ? {
                type: 'video',
                attrs: { type: 'video', src: url },
              }
            : {
                type: isPdf ? 'pdfviewer' : 'file',
                attrs: {
                  refId,
                  url,
                  size,
                  name: fileName,
                  type: 'file',
                },
              };

          if (
            totalCountFilesToUpload > 1 &&
            EditorInstance?.view?.state?.selection?.ranges?.length
          ) {
            EditorInstance.commands.insertContentAt(
              rangeFrom || totalNodeCount,
              node,
              {
                updateSelection: true,
              }
            );
          } else {
            try {
              EditorInstance.commands.insertContentAt(
                rangeFrom || totalNodeCount,
                node,
                {
                  updateSelection: true,
                }
              );
            } catch {
              EditorInstance.commands.insertContent(node);
            }
          }

          uploadingFiles.splice(i, 1);

          if (isFunction(updateUploadingFilesList)) {
            updateUploadingFilesList([...uploadingFiles]);
          }

          continue;
        } else if (uploadRes?.notAllowedFile) {
          toast({
            title: i18n('user_create_task_file_upload_status_not_allowed'),
            status: 'error',
            duration: 3_500,
            isClosable: true,
          });

          throw new Error('File not allowed');
        } else if (uploadRes?.notAllowedPlan) {
          toast({
            title: i18n(
              'user_create_task_file_attached_max_size_reached_upgrade'
            ),
            status: 'error',
            duration: 3_500,
            isClosable: true,
          });

          throw new Error('Upload blocked by plan');
        } else if (uploadRes?.maxSize) {
          toast({
            title: i18n('user_create_task_file_attached_max_size_reached'),
            status: 'warning',
            duration: 3_500,
            isClosable: true,
          });

          throw new Error('Max size reached');
        } else {
          toast({
            title: i18n('common_something_wrong_w_try'),
            status: 'error',
            duration: 3_500,
            isClosable: true,
          });

          throw new Error('Something went wrong');
          // something went wrong
        }
      } catch (err) {
        uploadingFiles.splice(i, 1);

        if (isFunction(updateUploadingFilesList)) {
          updateUploadingFilesList(uploadingFiles);
        }

        continue;
      }
    }
  };

  const uploadFiles = (files = []) => {
    const allowed = checkFiles(files);
    let showToasterError = false;
    let errorMessage = '';

    if (!allowed) {
      return;
    }

    for (let i = 0; i < files?.length; i++) {
      const targetFile = files[i];
      const sizeInBytes = targetFile?.size;
      const isImage = toString(targetFile?.type).includes('image/');

      if (targetFile?.name) {
        if (isImage) {
          if (
            (!userIsPremium && sizeInBytes > 25e6) ||
            (userIsPremium && sizeInBytes > 150e6)
          ) {
            errorMessage = userIsPremium
              ? `${i18n('user_create_task_status_exceed_image_premium')}${
                  targetFile?.name ? `: ${targetFile.name}` : ''
                }`
              : `${i18n('user_create_task_status_exceed_image_upgrade')}`;
            showToasterError = true;
            break;
          }
        } else if (sizeInBytes > 2540e6) {
          errorMessage = i18n(
            'user_create_task_file_attached_max_size_reached_premium'
          );
          showToasterError = true;
        }
      } else {
        showToasterError = true;
        errorMessage = i18n('user_create_task_file_upload_status_invalid_file');
        break;
      }
    }

    if (files?.length && showToasterError) {
      if (!isEmpty(errorMessage)) {
        toast({
          title: errorMessage,
          status: 'error',
          duration: 3_500,
          isClosable: true,
        });
      }

      return;
    }

    const filesToUpload = [];
    // cont. upload
    for (let i = 0; i < files?.length; i++) {
      const targetFile = files[i];
      const sizeInBytes = targetFile?.size;
      const isImage = toString(targetFile?.type).includes('image/');

      if (filesToUpload?.length > 10 && !isImage) {
        break;
      }

      if (targetFile?.name) {
        const isVideoFile = isSupportedVideoFileFormat(targetFile?.type);
        const id = uuidV4();

        filesToUpload.push({
          id,
          sizeInBytes,
          isImage,
          isVideoFile,
          file: targetFile,
        });
      }
    }

    updateUploadingFilesList(filesToUpload);
    checkToUpload(filesToUpload);
  };

  const checkFiles = (files = []) => {
    let errorMessage = '';
    let valid = true;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const sizeInBytes = file.size;
      const allowed = isFileAllowed(file?.name);

      if (!allowed) {
        // prompt error file not allowed
        errorMessage = i18n('user_create_task_file_upload_status_not_allowed');

        break;
      } else if (!userIsPremium && sizeInBytes > 25e6) {
        errorMessage = i18n(
          'user_create_task_file_attached_max_size_reached_upgrade'
        );

        break;
      } else if (sizeInBytes > 2540e6) {
        errorMessage = i18n(
          'user_create_task_file_attached_max_size_reached_premium'
        );

        break;
      }
    }

    if (errorMessage) {
      toast({
        title: errorMessage,
        status: 'error',
        duration: 3_500,
        isClosable: true,
      });

      valid = false;
    }

    return valid;
  };

  const fileUploadHandler = function (evt) {
    if (EditorInstance && this?.files?.length) {
      uploadFiles(this.files);
    }
  };

  const onFileUpload = (type = 'file') => {
    const lastUploaded = head(props?.uploadingFiles);

    if (
      props?.uploadingFiles?.length > 4 ||
      (type === 'image' && !isNil(lastUploaded) && !lastUploaded?.isImage)
    ) {
      toast({
        title: i18n('user_create_task_file_attached_please_wait'),
        status: 'error',
        duration: 2_500,
      });
      return;
    }

    let input = document.getElementById(editorComponentFileInputUniqueId);

    if (input?.remove) {
      input.remove();
      input = null;
    }

    if (!input) {
      input = document.createElement('input');

      input.setAttribute('type', 'file');
      input.setAttribute('id', editorComponentFileInputUniqueId);
      document.body.appendChild(input);
    }

    if (type === 'image') {
      input.setAttribute(
        'accept',
        'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/avif, image/svg+xml'
      );
    } else {
      input.setAttribute(
        'accept',
        '.txt, .pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .xls, .xlsx, .dmg, .app, ' +
          '.asm, .ica, .heic, .dng, .psd, .csv, .zip, .rar, .tar.gz, .7zip, .mkv, .wmv, .mp4, .mov, .avi, .mp3, .wav, .ogg, .py, .html, .xml, .js, .ts, .tsx, .json, .env, .css, .scss, .java, .cpp, .dwg, .dxf, .dws, .dwt, .sldprt, .sldasm, .slddrw, .catpart, .catproduct, .cgr'
      );
    }

    input.onchange = fileUploadHandler;
    input.setAttribute('multiple', true);
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'file');
    input.click();
  };

  // setup drag and drop
  return (
    <>
      <FormatsToolbar onFileUpload={onFileUpload} />{' '}
      <Editor uploadFiles={uploadFiles} />
      {children || null}
    </>
  );
};

export default withUserDataAndProfileSettings(
  withNoteViewContext(EditorHelperComponent)
);
