const NoteViewPropertiesIcon = (p) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g clipPath="url(#clip0_891_14204)">
        <path
          d="M5.79059 2.66659L7.52859 0.928589C7.65361 0.803609 7.82315 0.733398 7.99992 0.733398C8.1767 0.733398 8.34624 0.803609 8.47126 0.928589L10.2093 2.66659H12.6666C12.8434 2.66659 13.013 2.73683 13.138 2.86185C13.263 2.98688 13.3333 3.15645 13.3333 3.33326V5.79059L15.0713 7.52859C15.1962 7.65361 15.2664 7.82315 15.2664 7.99992C15.2664 8.1767 15.1962 8.34624 15.0713 8.47126L13.3333 10.2093V12.6666C13.3333 12.8434 13.263 13.013 13.138 13.138C13.013 13.263 12.8434 13.3333 12.6666 13.3333H10.2093L8.47126 15.0713C8.34624 15.1962 8.1767 15.2664 7.99992 15.2664C7.82315 15.2664 7.65361 15.1962 7.52859 15.0713L5.79059 13.3333H3.33326C3.15645 13.3333 2.98688 13.263 2.86185 13.138C2.73683 13.013 2.66659 12.8434 2.66659 12.6666V10.2093L0.928589 8.47126C0.803609 8.34624 0.733398 8.1767 0.733398 7.99992C0.733398 7.82315 0.803609 7.65361 0.928589 7.52859L2.66659 5.79059V3.33326C2.66659 3.15645 2.73683 2.98688 2.86185 2.86185C2.98688 2.73683 3.15645 2.66659 3.33326 2.66659H5.79059ZM3.99992 3.99992V6.34326L2.34326 7.99992L3.99992 9.65659V11.9999H6.34326L7.99992 13.6566L9.65659 11.9999H11.9999V9.65659L13.6566 7.99992L11.9999 6.34326V3.99992H9.65659L7.99992 2.34326L6.34326 3.99992H3.99992ZM7.99992 10.6666C7.29268 10.6666 6.6144 10.3856 6.11431 9.88554C5.61421 9.38544 5.33326 8.70717 5.33326 7.99992C5.33326 7.29268 5.61421 6.6144 6.11431 6.11431C6.6144 5.61421 7.29268 5.33326 7.99992 5.33326C8.70717 5.33326 9.38544 5.61421 9.88554 6.11431C10.3856 6.6144 10.6666 7.29268 10.6666 7.99992C10.6666 8.70717 10.3856 9.38544 9.88554 9.88554C9.38544 10.3856 8.70717 10.6666 7.99992 10.6666ZM7.99992 9.33326C8.35354 9.33326 8.69268 9.19278 8.94273 8.94273C9.19278 8.69268 9.33326 8.35354 9.33326 7.99992C9.33326 7.6463 9.19278 7.30716 8.94273 7.05711C8.69268 6.80707 8.35354 6.66659 7.99992 6.66659C7.6463 6.66659 7.30716 6.80707 7.05711 7.05711C6.80707 7.30716 6.66659 7.6463 6.66659 7.99992C6.66659 8.35354 6.80707 8.69268 7.05711 8.94273C7.30716 9.19278 7.6463 9.33326 7.99992 9.33326Z"
          fill="#718096"
        />
      </g>
      <defs>
        <clipPath id="clip0_891_14204">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoteViewPropertiesIcon;
