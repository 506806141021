import k from 'src/constants/k';

export function isAlphaNumeric(str) {
  /**
   * Test if string is alphanumeric type returns true if it is, otherwise false
   */
  // eslint-disable-next-line
  const match = /^[a-z0-9]+$/i;
  return typeof str === 'string' && str.length > 0 && match.test(str);
}

export function containsNumber(str) {
  // eslint-disable-next-line
  const match = /\d/g;

  return typeof str === 'string' && str.length > 0 && match.test(str);
}

export function isCorrectEmailFormat(email) {
  const match =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return typeof email === 'string' && email.length > 0 && match.test(email);
}

export function isStringNotEmpty(str) {
  /**
   * We test if type is string, should not be empty and most of all not escape characters only (i.e. spaces, tabs)
   */
  return typeof str === 'string' && str.length > 0 && !/^\s*$/.test(str);
}

class UserInputs {
  validUsername(uname = '') {
    if (typeof uname !== 'string') {
      return false;
    } else if (
      !uname ||
      uname.length < 1 ||
      uname.indexOf(' ') >= 0 ||
      uname.indexOf('/') >= 0 ||
      uname.indexOf('\n') >= 0
    ) {
      return false;
    }

    const firstCharacter = uname.charAt(0);
    const totalLength = uname.length;

    uname = uname
      .replace(/!/g, '')
      .replace(/@/g, '')
      .replace(/#/g, '')
      .replace(/\$/g, '')
      .replace(/%/g, '')
      .replace(/\^/g, '')
      .replace(/&/g, '')
      .replace(/\*/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\+/g, '')
      .replace(/-/g, '')
      .replace(/_/g, '')
      .replace(/</g, '')
      .replace(/>/g, '')
      .replace(/\{/g, '')
      .replace(/\}/g, '')
      .replace(/\[/g, '')
      .replace(/\]/g, '')
      .replace(/;/g, '')
      .replace(/:/g, '')
      .replace(/\?/g, '')
      // new
      .replace(/\|/g, '')
      .replace(/\\/g, '')
      .replace(/=/g, '')
      .replace(/~/g, '')
      .replace(/`/g, '')
      .replace(/"/g, '')
      .replace(/'/g, '')
      .toLowerCase();

    return Boolean(
      uname &&
        totalLength >= k.USERNAME_PROPERTIES.minLength &&
        isAlphaNumeric(firstCharacter) &&
        isAlphaNumeric(uname) &&
        k.USERNAME_PROPERTIES.maxLength >= totalLength
    );
  }

  validPassword(pw = '') {
    if (typeof pw !== 'string') {
      return false;
    } else if (
      pw.indexOf(' ') >= 0 ||
      pw.indexOf('/') >= 0 ||
      pw.indexOf('\n') >= 0
    ) {
      return false;
    }

    const totalLength = pw.length;
    pw = pw
      .replace(/!/g, '')
      .replace(/@/g, '')
      .replace(/#/g, '')
      .replace(/\$/g, '')
      .replace(/%/g, '')
      .replace(/\^/g, '')
      .replace(/&/g, '')
      .replace(/\*/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\+/g, '')
      .replace(/-/g, '')
      .replace(/_/g, '')
      .replace(/</g, '')
      .replace(/>/g, '')
      .replace(/\{/g, '')
      .replace(/\}/g, '')
      .replace(/\[/g, '')
      .replace(/\]/g, '')
      .replace(/;/g, '')
      .replace(/:/g, '')
      .replace(/\?/g, '')
      // new
      .replace(/\|/g, '')
      .replace(/\\/g, '')
      .replace(/=/g, '')
      .replace(/~/g, '')
      .replace(/`/g, '')
      .replace(/"/g, '')
      .replace(/'/g, '')
      .toLowerCase();

    return !!(
      totalLength >= k.PASSWORD_PROPERTIES.minLength &&
      (!pw || isAlphaNumeric(pw)) &&
      k.PASSWORD_PROPERTIES.maxLength >= totalLength
    );
  }

  validateTagName(tag = '') {
    let valid = true;
    tag = tag.trim().replace('\n', '');
    const tag_words = tag.split(' ');

    if (
      tag.indexOf('>') >= 0 ||
      tag.indexOf('<') >= 0 ||
      tag.indexOf('/') >= 0 ||
      tag.indexOf('_') >= 0 ||
      tag.indexOf("'") >= 0 ||
      tag.indexOf('"') >= 0 ||
      tag.indexOf('.') >= 0 ||
      k.USER_TAG_NAME_PROPERTIES.maxLength < tag.length ||
      k.USER_TAG_NAME_PROPERTIES.minLength > tag.length ||
      tag.toLowerCase() === 'none'
    ) {
      return false;
    }

    for (let i = 0; i < tag_words.length; i++) {
      const w = tag_words[i];

      //eslint-disable-next-line
      const wfiltered = w
        .replace(/!/g, '')
        .replace(/@/g, '')
        .replace(/#/g, '')
        .replace(/\$/g, '')
        .replace(/%/g, '')
        .replace(/\^/g, '')
        .replace(/&/g, '')
        .replace(/\*/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/-/g, '')
        .replace(/\?/g, '')
        .replace(/\+/g, '')
        .toLowerCase();

      if (
        (!wfiltered && tag_words.length < 2) ||
        (wfiltered && !isAlphaNumeric(wfiltered))
      ) {
        valid = false;
        break;
      }
    }

    return valid;
  }
}

export default new UserInputs();
