const WarningIcon = (p) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.682 0.333496 6.99998C0.333496 3.31798 3.31816 0.333313 7.00016 0.333313C10.6822 0.333313 13.6668 3.31798 13.6668 6.99998C13.6668 10.682 10.6822 13.6666 7.00016 13.6666ZM6.3335 8.99998V10.3333H7.66683V8.99998H6.3335ZM6.3335 3.66665V7.66665H7.66683V3.66665H6.3335Z"
        fill="#F37A2A"
      />
    </svg>
  );
};

export default WarningIcon;
